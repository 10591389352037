import React from 'react'
import './KCardInfo.css'
const KCardInfo = ({
    title,
    extra,
    children,
}) => {
  return (
    <div className="kcardinfo">
      <div className="header">
        <h3>{title}</h3>
        <span>
            {extra}
        </span>
      </div>
      {children}
    </div>
  )
}

export default KCardInfo
