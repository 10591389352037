import React, { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { GoCalendar } from "react-icons/go";
import { FaCheck } from "react-icons/fa";

import icon from "../../images/icons/calendar.png";
import userService from "../../services/user.service";

export default class State extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "Activo",
      period: "24 / 07",
      periodIni: "24 / 07",
      dateMonth: {
        first: "",
        last: "",
      },
    };
  }

  componentDidMount() {
    let dash = this.props.dash;

    // ciclo semanal
    let dateSem = new Date(dash.weeklyPeriod.endDate);
    let dformatSem =
      ("00" + dateSem.getDate()).slice(-2) +
      "/" +
      ("00" + (dateSem.getMonth() + 1)).slice(-2) +
      "/" +
      dateSem.getFullYear();

    dateSem = new Date(dash.weeklyPeriod.initialDate);
    let dformatSemIni =
      ("00" + dateSem.getDate()).slice(-2) +
      "/" +
      ("00" + (dateSem.getMonth() + 1)).slice(-2) +
      "/" +
      dateSem.getFullYear();

    // Ciclo mensual
    let dateMes = new Date(dash.monthlyPeriod.endDate);
    let dformatMes =
      ("00" + dateMes.getDate()).slice(-2) +
      "/" +
      ("00" + (dateMes.getMonth() + 1)).slice(-2) +
      "/" +
      dateMes.getFullYear();

    dateMes = new Date(dash.monthlyPeriod.initialDate);
    let dformatMesIni =
      ("00" + dateMes.getDate()).slice(-2) +
      "/" +
      ("00" + (dateMes.getMonth() + 1)).slice(-2) +
      "/" +
      dateMes.getFullYear();

    let dateMonth = {
      first: dformatMesIni,
      last: dformatMes,
    };

    // SESSION STORAGE Save state
    sessionStorage.setItem("stateuser", dash.stateUserName);

    this.setState({
      state: (this.state.state = dash.stateUserName),
      period: (this.state.period = dformatSem),
      periodIni: (this.state.periodIni = dformatSemIni),
      dateMonth: dateMonth,
    });

    this.getNotification();
  }
  async getNotification() {
    let response = await userService.getNotification();
    if (response !== undefined && response.status === 1) {
      let suscriptions = response.objModel.sort((a, b) => {
        return a.numberDaysExpiration - b.numberDaysExpiration;
      });
      if (suscriptions.length > 0) {
        this.setState({ notification: suscriptions[0] });
      }
    }
  }
  getCurrentMount() {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    let firstDayString =
      ("00" + firstDay.getDate()).slice(-2) +
      "/" +
      ("00" + (firstDay.getMonth() + 1)).slice(-2) +
      "/" +
      firstDay.getFullYear();
    let lastDayString =
      ("00" + lastDay.getDate()).slice(-2) +
      "/" +
      ("00" + (lastDay.getMonth() + 1)).slice(-2) +
      "/" +
      lastDay.getFullYear();

    let dateMonth = {
      first: firstDayString,
      last: lastDayString,
    };
    return dateMonth;
  }
  render() {
    const {
      state,
      dayPeriod,
      period,
      periodIni,
      notification,
      dateMonth,
    } = this.state;

    let color = "";
    if (notification && notification.enabledNotify) {
      if (notification.numberDaysExpiration < 0) {
        color = "#ff0000";
      } else if (
        notification.numberDaysExpiration > 0 &&
        notification.numberDaysExpiration < 5
      ) {
        color = "#f3b00c";
      } else if (notification.numberDaysExpiration >= 5) {
        color = "#7f9b34";
      }
    }

    return (
      <div>
        <Form.Label className="content-title">Estado del socio</Form.Label>
        <div
          className="box-container-active"
          style={color.length > 0 ? { background: color } : {}}
        >
          <div className="box-icon">
            <FaCheck size={20}></FaCheck>
          </div>
          <p className="class-state">
            <b>{state}</b>
          </p>
          {notification &&
            notification.enabledNotify > 0 &&
            notification.numberDaysExpiration < 0 && (
              <p className="class-state" style={{ fontSize: "10px" }}>
                Su pago venció hace{" "}
                {Math.abs(notification.numberDaysExpiration)} días
              </p>
            )}
          {notification &&
            notification.enabledNotify > 0 &&
            notification.numberDaysExpiration > 0 && (
              <p className="class-state" style={{ fontSize: "10px" }}>
                Próximo pago en {Math.abs(notification.numberDaysExpiration)}{" "}
                días
              </p>
            )}
        </div>
        <div className="box-container">
          <div className="box-icon-state">{/* <img src={icon}></img> */}</div>
          <div>
            <img src={icon}></img>
            <div>
              {/* <p className="title-state">{dayPeriod}</p> */}
              <p className="title-state">Ciclo semanal</p>
              <p style={{ fontWeight: "bold" }}>
                Del {periodIni} al {period}
              </p>
              {/* <p className="subtitle-state">Del {periodIni} al {period}</p> */}
            </div>
          </div>
        </div>

        <div className="box-container">
          <div className="box-icon-state">{/* <img src={icon}></img> */}</div>
          <div>
            <img src={icon}></img>
            {/* <p className="title-state">{dayPeriodN}</p>
                        <p className="subtitle-state">Ciclo de la empresa &nbsp;{periodN}</p> */}
            <p className="title-state">Ciclo mensual</p>
            <p style={{ fontWeight: "bold" }}>
              Del {dateMonth.first} al {dateMonth.last}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
