import React, { Component } from 'react';
import Account from '../../components/user/Account';

export default class PersonalAccountView extends Component {
    

    render() {
        return(
            <div className="container-fluid">
                <Account></Account>
            </div>
            
        );
    }
}