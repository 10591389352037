import "./TodayEvent.css";
import { OverlayTrigger, Popover } from "react-bootstrap";

function CardTodayEvent(props) {
  //console.log("haber props xd", props);
  return (
    <section className="card event-card-container h-100 text-dark tw-cursor-pointer">
      <img
        src={`data:image/*;base64,${props.item.picture?.base64}`}
        className="card-img-top event-card-img"
        alt={"flyer-" + props.item.id}
      />

      <div className="card-body card-event-body-title p-2">
        <h5 className="card-title card-title-event">
          {props.item.subMeetingType.name}
        </h5>
      </div>

      <div className="style-body-card-event pb-3 pt-1">
        <div>Presentador:{" " + props.item.speaker}</div>

        <div>
          <p className="tw-m-0">
            Inicio: {props.item.startDatePeru} <span>Perú </span>
            <img
              src={`https://flagcdn.com/16x12/pe.png`}
              srcSet={`https://flagcdn.com/32x24/pe.png 2x, https://flagcdn.com/48x36/pe.png 3x`}
              width="16"
              height="12"
              alt="bandera"
            />
          </p>

          <p className="tw-m-0">
            Fin: {props.item.endDatePeru} <span>Perú </span>
            <img
              src={`https://flagcdn.com/16x12/pe.png`}
              srcSet={`https://flagcdn.com/32x24/pe.png 2x, https://flagcdn.com/48x36/pe.png 3x`}
              width="16"
              height="12"
              alt="bandera"
            />
          </p>
        </div>

        {props.ipInfo?.country !== "PE" && (
          <div>
            <p className="tw-m-0">
              Inicio: {props.item.startDateLocale}{" "}
              <span>
                {props.ipInfo?.country}/{props.ipInfo?.city}{" "}
              </span>
              <img
                src={`https://flagcdn.com/16x12/${props.ipInfo.country.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/32x24/${props.ipInfo.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${props.ipInfo.country.toLowerCase()}.png 3x`}
                width="16"
                height="12"
                alt="bandera"
              />
            </p>

            <p className="tw-m-0">
              Fin: {props.item.endDateLocale}{" "}
              <span>
                {props.ipInfo?.country}/{props.ipInfo?.city}{" "}
              </span>
              <img
                src={`https://flagcdn.com/16x12/${props.ipInfo.country.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/32x24/${props.ipInfo.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${props.ipInfo.country.toLowerCase()}.png 3x`}
                width="16"
                height="12"
                alt="bandera"
              />
            </p>
          </div>
        )}
      </div>
    </section>
  );
}

function TodayEvent(props) {
  //console.log("props de TodayEvent =>", props);
  const enterMeeting = (item) => {
    if (item.recordedLink !== null) window.open(item.recordedLink);
    else {
      const urlapi = item.subMeetingType.landing.url;
      window.location = urlapi.split("/")[3] + "/" + urlapi.split("/")[4];
      sessionStorage.setItem("idmeeting", item.id);
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Esta reunión no está habilitada. Revise su horario
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="row row-cols-1 row-cols-md-3 p-3 mb-3">
      {/* <span>SECCION DE EVENTOS PANTALLA PRINCIPAL</span> */}

      {props.data.map((item, id) => (
        <div className="col p-3" key={id}>
          {item.showpopover === true ? (
            <OverlayTrigger
              trigger="click"
              placement="auto"
              rootClose
              overlay={popover}
            >
              <aside>
                <CardTodayEvent item={item} ipInfo={props.ipInfo} />
              </aside>
            </OverlayTrigger>
          ) : (
            <aside onClick={() => enterMeeting(item)}>
              <CardTodayEvent item={item} ipInfo={props.ipInfo} />
            </aside>
          )}
        </div>
      ))}
    </div>
  );
}

export default TodayEvent;
