import React, { Component } from "react";
import {
  Col,
  Form,
  Row,
  Spinner,
  Table,
  Button,
  Modal,
} from "react-bootstrap";
import { BsFillAwardFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
// import Validation from "../utils/Validation";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";

// import imgDefault from "../../images/assets/socio.png";
// import imgSocio from "../../images/assets/socio.png";
// import imgBronce from "../../images/assets/bronce.png";
import imgPlata from "../../images/assets/plata.png";
import imgOro from "../../images/assets/oro.png";
import imgZafiro from "../../images/assets/zafiro.png";
import imgRuby from "../../images/assets/ruby.png";
import imgEsmeralda from "../../images/assets/esmeralda.png";
import imgDiamante from "../../images/assets/diamante.png";
import imgDnegro from "../../images/assets/dnegro.png";
import imgDazul from "../../images/assets/dazul.png";
import imgDcorona from "../../images/assets/dcorona.png";
import imgDimperial from "../../images/assets/dimperial.png";
import imgDobleDiamante from '../../images/assets/DobleDiamante.png';
import imgTripleDiamante from '../../images/assets/TripleDiamante.png';
import imgPlatino from '../../images/assets/platino.png';
import imgDiamanteDorado from '../../images/assets/DiamanteDorado.png';

import dataKey from "../utils/dataKey.json";

export default class Achievement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadInformation: false,
      noData: false,
      message: "",
      showModalAward: false,
      award: undefined,
      showModalInformation: false,
      plataCalification: [],
      oroCalification: [],
      zafiroCalification: [],
      rubiCalification: [],
      esmeraldaCalification: [],
      diamanteCalification: [],
      diamenteNegroCalification: [],
      diamanteAzulCalification: [],
      diamanteCoronaCalification: [],
      diamanteImperialCalification: [],
      diamanteDoradoCalification: [],
      platinoCalification:[],
      dobleDiamanteCalification: [],
      tripleDiamanteCalification: [],
      califications: [1, 2, 3, 4, 5],
      arrayInformation: [
        // {
        //     id:1,
        //     src: imgSocio,
        //     name: "Socio"
        // },
        {
          id: 2,
          src: imgPlata,
          name: "Plata",
        },
        {
          id: 3,
          src: imgOro,
          name: "Oro",
        },
        {
          id: 4,
          src: imgZafiro,
          name: "Zafiro",
        },
        {
          id: 5,
          src: imgRuby,
          name: "Rubi",
        },
        {
          id: 6,
          src: imgEsmeralda,
          name: "Esmeralda",
        },
        {
          id: 7,
          src: imgPlatino,
          name: "Platino",
        },
        {
          id: 8,
          src: imgDiamante,
          name: "Diamante",
        },
        {
          id: 9,
          src: imgDobleDiamante,
          name: 'Diamante Doble'
        },
        {
          id: 14,
          src: imgTripleDiamante,
          name: 'Diamante Triple'
        },
        {
          id: 10,
          src: imgDnegro,
          name: "Diamante negro",
        },
        {
          id: 11,
          src: imgDazul,
          name: "Diamante azul",
        },
        {
          id: 16,
          src: imgDcorona,
          name: "Diamante corona",
        },
        {
          id: 13,
          src: imgDimperial,
          name: "diamante imperial",
        },
        {
          id: 15,
          src: imgDiamanteDorado,
          name: 'Diamante Dorado'
        }
      ],
    };
  }
  arrayInformation;

  componentDidMount() {
    this.getInformation();
  }

  getInformation = async () => {
    let id = AuthService.getCurrentIdUser();

    let data = {
      SizeList: 50,
      NroPage: 1,
      ParametersSearched: {
        IdSocioSearch: Number(id),
      },
    };
    let response = await UserService.getRangeByUser(data);

    console.log('range user', response);

    if (response !== undefined) {
      if (response.status === 1) {
        let array = this.state.arrayInformation;
        let acc = 0;
        for (let i = 9; i >= 0; i--) {
          // find
          let idx = response.objModel.listElements.findIndex(
            (item) => item.idrange === array[i].id
          );
          if (idx >= 0) {
            acc += response.objModel.listElements[idx].total;
            array[i].calificaciones = acc;
            array[
              i
            ].porcentage = `${response.objModel.listElements[idx].percents} %`;
            array[i].premio = response.objModel.listElements[idx].award;
            array[i].calificacion =
              response.objModel.listElements[idx].methodqualification;
            array[i].active = 1;
            //get awward
            let idxAward = dataKey.countries.findIndex(
              (item) =>
                item.id ===
                response.objModel.listElements[idx].idResidenceCountry
            );
            console.log('idx', idx, 'idxAward', idxAward)
            if (idxAward >= 0) {
              let itemCategory = response.objModel.listElements[idx];
              let data = dataKey.countries[idxAward];

              array[i].hasAward = true;
              let award = data[itemCategory.name.toLowerCase()];
              console.log('sdsd', data, itemCategory, award)
              if (award) {
                array[i].imgSrc = award;
                array[i].imgName = award.toUpperCase().replace("_", " ");
              }
            }
          } else {
            array[i].active = 0;
            array[i].calificaciones = acc;
          }
        }

        console.log('array', array)

        this.setState({
          arrayInformation: (this.state.arrayInformation = array),
        });
      }
    } else {
      this.setState({
        arrayInformation: (this.state.arrayInformation = []),
      });
    }
  };

  // Handle modal
  handleModalAward = (item) => {
    this.setState({
      showModalAward: true,
      award: item,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModalAward: false,
      award: undefined,
    });
  };

  handlesShowInformation = async () => {
    this.setState({
      showModalInformation: true,
    });
    const response = await UserService.getCalification();
    console.log('response calificacion', response);
    if (response && response.status) {
      const first = response.objModel.listElements.filter(
        (item) => item.idrange === 2
      );
      const second = response.objModel.listElements.filter(
        (item) => item.idrange === 3
      );
      const third = response.objModel.listElements.filter(
        (item) => item.idrange === 4
      );
      const fourth = response.objModel.listElements.filter(
        (item) => item.idrange === 5
      );
      const fifth = response.objModel.listElements.filter(
        (item) => item.idrange === 6
      );
      const diamante = response.objModel.listElements.filter(
        (item) => item.idrange === 8
      );
      const diamanteNegro = response.objModel.listElements.filter(
        (item) => item.idrange === 10
      );
      const diamanteAzul = response.objModel.listElements.filter(
        (item) => item.idrange === 11
      );
      const diamanteCorona = response.objModel.listElements.filter(
        (item) => item.idrange === 16
      );
      const diamanteImperial = response.objModel.listElements.filter(
        (item) => item.idrange === 13
      );

      const platino = response.objModel.listElements.filter(
        (item) => item.idrange === 7
      );

      const dobleDiamante = response.objModel.listElements.filter(
        (item) => item.idrange === 9
      );

      const diamanteDorado = response.objModel.listElements.filter(
        (item) => item.idrange === 15
      );

      const tripleDiamante = response.objModel.listElements.filter(
        (item) => item.idrange === 14
      );


      console.log(diamanteDorado, 'diamanteDorado');
      console.log(tripleDiamante, 'tripleDiamante');


      this.setState({
        plataCalification: (this.state.plataCalification = first),
        oroCalification: (this.state.oroCalification = second),
        zafiroCalification: (this.state.zafiroCalification = third),
        rubiCalification: (this.state.rubiCalification = fourth),
        esmeraldaCalification: (this.state.esmeraldaCalification = fifth),
        diamanteCalification: (this.state.diamanteCalification = diamante),
        diamenteNegroCalification: (this.state.diamenteNegroCalification = diamanteNegro),
        diamanteAzulCalification: (this.state.diamanteAzulCalification = diamanteAzul),
        diamanteCoronaCalification: (this.state.diamanteCoronaCalification = diamanteCorona),
        diamanteImperialCalification: (this.state.diamanteImperialCalification = diamanteImperial),
        platinoCalification: (this.state.platinoCalification = platino),
        dobleDiamanteCalification: (this.state.dobleDiamanteCalification = dobleDiamante),
        diamanteDoradoCalification: (this.state.diamanteDoradoCalification = diamanteDorado),
        tripleDiamanteCalification: (this.state.tripleDiamanteCalification = tripleDiamante),
      });
    }
  };
  handleCloseModalInfo = () => {
    this.setState({
      showModalInformation: false,
    });
  };

  render() {
    const { loadInformation, noData, arrayInformation, message } = this.state;

    return (
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <hr></hr>
        <Row>
          <Col sm={12}>
            <Form.Label className="content-subtitle">Mis logros</Form.Label>
          </Col>
        </Row>
        <Row style={{ textAlign: "right" }}>
          <Col sm={{ span: 4, offset: 8 }}>
            <div
              onClick={(e) => {
                this.handlesShowInformation();
              }}
            >
              <Form.Label style={{ cursor: "pointer" }}>
                Formas de calificación <FaCheckCircle />
              </Form.Label>
            </div>
          </Col>
        </Row>
        {loadInformation && (
          <div>
            <Spinner animation="border" variant="dark"></Spinner>
            <p>
              <Form.Label>Cargando información...</Form.Label>
            </p>
          </div>
        )}
        {!loadInformation && !noData && (
          <div className="box-container-wrap">
            {arrayInformation.map((item, id) => {
              let classCard = "card-range";
              //console.log('item recorrer', item)
              return (
                <div className={classCard} key={id}>
                  <div className="card-main-suscription">
                    <img
                      src={item.src}
                      style={{
                        width: "60%",
                        opacity: item.active === 1 ? "100%" : "5%",
                      }}
                    ></img>
                    {/* <p className="content-title">{item.name}</p> */}
                    <div className="description">
                      <b># Calificaciones</b> {item.calificaciones}
                    </div>
                    <div className="description">
                      <b>Porcentaje:</b> {item.porcentage}
                    </div>
                    <div className="description">
                      <b>Premio:</b> {item.premio}
                    </div>
                    <div className="description">
                      {item.calificacion && (
                        <b>{item.calificacion}º Forma de calificación</b>
                      )}
                    </div>
                    {item.hasAward && item.imgSrc && item.imgSrc.length > 0 && (
                      <div style={{ color: "#142d47", paddingTop: 20 }}>
                        <Button
                          variant="link"
                          size="sm"
                          style={{ color: "#142d47" }}
                          onClick={(e) => this.handleModalAward(item)}
                        >
                          Ver
                          <BsFillAwardFill color="#142d47" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {noData && (
          <div>
            <Form.Label>{message}</Form.Label>
          </div>
        )}
        <hr></hr>
        {/* Modal to show images */}
        <Modal
          dialogClassName="modal-90w"
          backdrop="static"
          show={this.state.showModalAward}
          onHide={this.handleCloseModal}
          style={{ fontSize: 12 }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Form.Group>
              {this.state.award && (
                <div style={{ textAlign: "center" }}>
                  {/* <img
                className="img-fluid"
                src={`/awards/${this.state.award.imgSrc}.jpg`}
              /> */}
                  <Form.Label>{this.state.award.imgName}</Form.Label>
                  <img
                    className="img-fluid"
                    src={`/awards/${this.state.award.imgSrc}.jpg`}
                  />
                </div>
              )}
            </Form.Group>

            <Modal.Footer>
              <Button
                variant="secondary"
                size="sm"
                onClick={this.handleCloseModal}
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          dialogClassName="modal-120w"
          size="lg"
          show={this.state.showModalInformation}
          onHide={this.handleCloseModalInfo}
          style={{ fontSize: 12 }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th colSpan={2}>1º Forma Calificación</th>
                      <th colSpan={2}>2º Forma Calificación</th>
                      <th colSpan={2}>3º Forma Calificación</th>
                      <th colSpan={2}>4º Forma Calificación</th>
                      <th colSpan={2}>5º Forma Calificación</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>Ciclos</th>
                      <th>Ptos. directos</th>
                      <th>Ciclos</th>
                      <th>Ptos. directos</th>
                      <th>Ciclos</th>
                      <th>Ptos. directos</th>
                      <th>Ciclos</th>
                      <th>Ptos. directos</th>
                      <th>Ciclos</th>
                      <th>Ptos. directos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <td>Triple Diamante Imperial</td>
                      <td>24</td>
                      <td>540</td>
                      <td>20</td>
                      <td>1080</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Doble Diamante Imperial</td>
                      <td>20</td>
                      <td>540</td>
                      <td>16</td>
                      <td>1080</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr> */}
                     <tr>
                      <td>Plata</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.plataCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Oro</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.oroCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Zafiro</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.zafiroCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Rubi</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.rubiCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Esmeralda</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.esmeraldaCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Platino</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.platinoCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];

                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Diamante</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.diamanteCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Doble Diamante</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.dobleDiamanteCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];

                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Diamante Dorado</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.diamanteDoradoCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Diamante Triple</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.tripleDiamanteCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Diamante Imperial</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.diamanteImperialCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Diamante Corona</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.diamanteCoronaCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>

                    <tr>
                      <td>Diamante Azul</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.diamanteAzulCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                    <tr>
                      <td>Diamante Negro</td>
                      {this.state.califications.map((item) => {
                        const calification = this.state.diamenteNegroCalification.find(
                          (obj) => obj.idawardmethod === item
                        );
                        const array = [];
                        if (calification) {
                          array.push(<td>{calification.cycles}</td>);
                          array.push(<td>{calification.points}</td>);
                        } else {
                          array.push(<td>-</td>);
                          array.push(<td>-</td>);
                        }
                        return array;
                      })}
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Modal.Footer>
              <Button
                variant="secondary"
                size="sm"
                onClick={this.handleCloseModalInfo}
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
