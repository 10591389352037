import {
    mekADD_TO_CART,
    mekGET_PRODUCTS,
    mekGET_DATA_STORE,
    mekGET_DATA_SCHEDULE_STORE,
    mekGET_ORDERS
} from '../reducer/types'
const urlStoreData = "https://api-dev.keola.club:2083/api/Stores/902";
const urlScheduleStore = "https://api-dev.keola.club:2083/api/DeliverySchedule/store/902"
const urlGetOrders = "https://api-dev.keola.club:2083/api/OrdersDetail/backOffice/"
/**
 * It takes a response from an API, and then maps through the response to get the data I need. 
 * 
 * I'm using the redux-thunk middleware to dispatch the action. 
 * 
 * The action is dispatched to the reducer, which then updates the state. 
 * 
 * The state is then passed to the component, which then renders the data. 
 * 
 * The problem is that the data is not being rendered. 
 * 
 * I've tried using the debugger to see if the data is being passed to the component, but it's not. 
 * 
 * I've also tried using the debugger to see if the data is being passed to the reducer, but it's not. 
 * 
 * 
 * I've also tried using the debugger to see if the data is being passed to the action, but it's not. 
 * 
 * I've also tried using
 * @returns <code>{
 *     "type": "GET_PRODUCTS_SUCCESS",
 *     "payload": [
 *         {
 *             "id": "5e9f8f8f8f8f8f8f8f8f8f8f",
 *             "name": "Sale 1",
 *             "description": "S
 */
export const LoadProducts = () => {
    return dispatch => {
        dispatch({ type: mekGET_PRODUCTS.GET_PRODUCTS_REQUEST })
        /* It's fetching data from an API. */
        return fetch('https://api-dev.keola.club:2083/api/categorySales/store/902') //902
            .then(res => res.json())
            .then(async (products) => {
                /* It's mapping through the response to get the data I need. */
                const sales = [];
                await products.objModel?.storeProducts.map(product => {
                    product?.subCategory.map(subCategory => {
                        subCategory?.sales.map(sale => {
                            sales.push(sale)
                        })
                    })
                })
                dispatch(getStoreData())
                dispatch(getScheduleStore())
                dispatch({ type: mekGET_PRODUCTS.GET_PRODUCTS_SUCCESS, payload: sales })
            }).catch(err => {
                dispatch({ type: mekGET_PRODUCTS.GET_PRODUCTS_FAILURE, payload: err })
            })
    }
}


export const getStoreData = () => {
    return dispatch => {
        dispatch({ type: mekGET_PRODUCTS.GET_PRODUCTS_REQUEST })
        return fetch(urlStoreData)
            .then(res => res.json())
            .then(async (store) => {
                dispatch({ type: mekGET_DATA_STORE.GET_DATA_STORE_SUCCESS, payload: store.objModel })
            }
            ).catch(err => {
                dispatch({ type: mekGET_DATA_STORE.GET_DATA_STORE_FAILURE, payload: err })
            }
            )
    }
}
export const getScheduleStore = () => {
    return dispatch => {
        dispatch({ type: mekGET_PRODUCTS.GET_PRODUCTS_REQUEST })
        return fetch(urlScheduleStore)
            .then(res => res.json())
            .then(async (schedule) => {

                dispatch({ type: mekGET_DATA_SCHEDULE_STORE.GET_DATA_SCHEDULE_SUCCESS, payload: schedule.objModel })
            }
            ).catch(err => {
                dispatch({ type: mekGET_DATA_SCHEDULE_STORE.GET_DATA_SCHEDULE_FAILURE, payload: err })
            }
            )
    }
}

/**
 * If the product quantity is 0, then add the product to the cart. If the product quantity is not 0,
 * then update the cart.
 * @param product - {
 * @returns A function that takes a dispatch function as a parameter.
 */
export const AddToCart = (product) => {
    return dispatch => {
        /* Checking if the product quantity is 0, then add the product to the cart. If the product
        quantity is not 0, then update the cart. */
        if (product.quantity == 0) {
            product.quantity = 1;
            dispatch({ type: mekADD_TO_CART.ADD_TO_CART, payload: product })
        } else {
            product.quantity++;
            dispatch({ type: mekADD_TO_CART.UPDATE_CART, payload: product })

        }
    }
}

/**
 * If the product quantity is 1, then set the quantity to 0 and dispatch the REMOVE_FROM_CART action.
 * Otherwise, decrement the quantity and dispatch the UPDATE_CART action.
 * @param product - {
 * @returns A function that takes dispatch as a parameter.
 */
export const RemoveFromCart = (product) => {
    return dispatch => {
        /* If the product quantity is 1, then set the quantity to 0 and dispatch the REMOVE_FROM_CART
        action. Otherwise, decrement the quantity and dispatch the UPDATE_CART action. */
        if (product.quantity === 1 || product.quantity < 1) {
            product.quantity = 0;
            dispatch({ type: mekADD_TO_CART.REMOVE_FROM_CART, payload: product })
        } else {
            product.quantity--;
            dispatch({ type: mekADD_TO_CART.UPDATE_CART, payload: product })
        }
    }
}


export const DeliveryType = (deliveryType) => {
    return dispatch => {
        dispatch({ type: mekADD_TO_CART.SET_DELIVERY_TYPE, payload: deliveryType })
    }
}




// SCHEDULE


export const setScheduleAction = (schedule) => {
    return dispatch => {
        dispatch({ type: mekGET_DATA_SCHEDULE_STORE.SET_SCHEDULE_STORE, payload: schedule })
    }
}



// order list

export const getOrdersIdSocio = (idSocio) => {
    return dispatch => {
        dispatch({ type: mekGET_ORDERS.GET_ORDERS_REQUEST })
        return fetch(urlGetOrders + idSocio)
            .then(res => res.json())
            .then(async (orders) => {
                dispatch({ type: mekGET_ORDERS.GET_ORDERS_SUCCESS, payload: orders.objModel })
            }
            ).catch(err => {
                dispatch({ type: mekGET_ORDERS.GET_ORDERS_FAILURE, payload: err })
            }
            )
    }
}