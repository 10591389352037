import React from 'react'
import { useDispatch } from 'react-redux';
import { AddToCart, RemoveFromCart } from 'services/meKeola.service';
import Swal from 'sweetalert2';
import './KCartItem.css'
const KCartItem = ({
    product,
    cart,
}) => {
    const dispatch = useDispatch();
    /**
     * AddToCart is a function that takes a parameter called pro and returns a dispatch function that
     * takes a parameter called AddToCart and returns a function that takes a parameter called pro.
     */
    const addToCart = (pro) => {
        if (pro?.quantity === pro?.stock) {
            return Swal.fire({
                title: 'Estas en el stock máximo',
                text: `
            El producto ${pro?.name} ya esta en el stock máximo.
          `,
                icon: 'info',
                confirmButtonText: 'Aceptar',
            })
        }
        dispatch(AddToCart(pro))
    }
    /**
     * RemoveCart is a function that takes a parameter called pro and returns a dispatch function that
     * takes a parameter called RemoveFromCart and returns a function that takes a parameter called pro.
     */
    const removeCart = (pro) => {
        dispatch(RemoveFromCart(pro))
    }
    return (
        <div className="kcart-item">
            <div className="img">
                <img src={product?.photos[0]?.photoSales} alt={product?.name} />
            </div>
            <div className="info">
                <div className="name">
                    <p>{product.name}</p>
                    <span>{product.description}</span>
                </div>
                <div className="price">
                    <p>S/.{(product?.price?.backOfficePrice * product.quantity)?.toFixed(2)}</p>
                </div>
                <div className="quantity">
                    <div className="addcart">
                        <button
                            onClick={() => addToCart(product)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" /></svg>
                        </button>
                        <span>
                            {product.quantity}
                        </span>
                        <button
                            className="min"
                            onClick={() => removeCart(product)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M19 13H5v-2h14v2z" /></svg>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default KCartItem
