import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import ChatBot, { Loading } from 'react-simple-chatbot';
import ChatBotService from "../../services/chatbot.service";
import Validation from "../utils/Validation";

export default class ChangeSus extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: true,
        result: '',
    };
  }

  componentDidMount(){
    this.ChangeSus();
  }

  async ChangeSus(){

        this.setState({
            loading: false,
            result: 'Has solicitado cambiar tu membresía '+ localStorage.getItem("membresia_actual") + ' a la membresía ' + localStorage.getItem("membresia_nueva") + ', ¿es correcta esta información?'
        })
        this.triggertNext();

    /// end
  }

  triggertNext() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "2f"});
    });
  }


  render() {
      const {loading, result} = this.state;

    return (
      <div className="dbpedia" style={{color: '#fff'}}>
        { loading ? <Loading /> : result }

      </div>
    );
  }

}