import KHeader from 'components/KHeader';
import ModalContent from 'components/ModalContent';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { DeliveryType, setScheduleAction } from 'services/meKeola.service';
import Deliveryimg1 from '../../assets/img/DeliveryMETODO1.png'
import Deliveryimg2 from '../../assets/img/DeliveryMETODO2.png'
import Deliveryimg3 from '../../assets/img/DeliveryMETODO3.png'
import './MEKeolaShopDelivery.css'
import ModalDays from './ModalDays';
const deliveryOpt = [
    // {
    //     id: 1,
    //     img: Deliveryimg1,
    //     title: 'La tienda cuenta con Delivery Propio',
    // },
    // {
    //     id: 2,
    //     img: Deliveryimg2,
    //     title: 'KeOla Drivers',
    // },
    {
        id: 3,
        img: Deliveryimg3,
        title: 'Recojo en puerta de tienda (Pick-up)',
    }

]

const MEKeolaShopDelivery = () => {
    const dispatch = useDispatch();
    const navigate = useHistory();

    const [isOpenHour, setIsOpenHour] = useState('');

    const [isOpen, setIsOpen] = useState(false)
    const [type, setType] = useState(null)

    const { mekproducts, cart, schedule } = useSelector((state) => state.shop);

    if (cart.length === 0) {
        return <Redirect to="/home" />
    }
    const handleOpen = (type) => {
        setIsOpen(true)
        setType(type)
    }
    const handleClose = () => {
        setIsOpen(false)
    }
    const handleSelecConfirm = (type = 0) => {
        setIsOpen(false)
        dispatch(DeliveryType(type))
        // redirect to payment
        navigate.push('/kshop/payment')
    }
    const toggleOpenHour = () => {
        setIsOpenHour(!isOpenHour)
    }
    const confirmSelectHour = (data) => {
        setIsOpenHour(false)
        dispatch(DeliveryType(
            deliveryOpt.find((del) => del.id === type)?.id || 0
        ))
        dispatch(setScheduleAction(data))
        navigate.push('/kshop/payment')
    }
    return (
        <>
            {
                cart.length === 0?
                    <Redirect to="/home" />
                    :
                    <main className="shopkeola-main">
                        <hr />
                        <KHeader
                            title="Escoge una opción de delivery"
                        />
                        <hr />
                        <div className="delivery-main">
                            <div className="deliveryItems">
                                {
                                    deliveryOpt.map((del, key) => {
                                        return (
                                            <div
                                                className="deliveryItem"
                                                onClick={() => handleOpen(del.id)}
                                            >
                                                <img src={del.img} alt={del.title} />
                                                <h3>{del.title}</h3>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <ModalContent
                            title={
                                deliveryOpt.find((del) => del.id === type)?.title || ''
                            }
                            isOpen={isOpen}
                            handleClose={handleClose}
                        >
                            <p>Elige la opción de entrega de pedido que mejor se te acomode.</p>
                            <div className="klistOptions">
                                <div className=""
                                    onClick={() => handleSelecConfirm(
                                        deliveryOpt.find((del) => del.id === type)?.id || 0
                                    )}
                                >
                                    Entrega inmediata
                                </div>
                                <div className=""
                                    onClick={() => {
                                        toggleOpenHour()
                                        setIsOpen(false)
                                    }}
                                >
                                    Entrega programada
                                </div>
                            </div>
                        </ModalContent>
                        <ModalContent
                            title="Elige un horario de entrega"
                            isOpen={isOpenHour}
                            handleClose={() => {
                                toggleOpenHour()
                                setIsOpen(true)
                            }}
                            size="xl"
                        >
                           <ModalDays
                                schedule={schedule}
                                navigate={navigate}
                                confirmSelectHour={confirmSelectHour}
                           />

                        </ModalContent>

                    </main>
            }
        </>
    )
}

export default MEKeolaShopDelivery
