import { useEffect, useRef, useState } from "react";
import "./ListCalendarEvents.css";
import LoadApi from "components/validators/LoadApi.js";

import TodayEvent from "./Filtered/Today/TodayEvent";
import WeekEvent from "./Filtered/Week/WeekEvent";
import MonthEvent from "./Filtered/Month/MonthEvent";
import YearEvent from "./Filtered/Year/YearEvent";
import Pagination from "./Filtered/Today/Pagination/Pagination";

export default function ListCalendarEvents({ filter, idsubtype, API_URL }) {
  const ipInfo = useRef(null);
  const [nroPage, setnroPage] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [datatoday, setdatatoday] = useState([]);
  const [dataweek, setdataweek] = useState([]);
  const [datamonth, setdatamonth] = useState([]);
  const [datayear, setdatayear] = useState([]);
  const [isloadedapidata, setisloadedapidata] = useState(true);
  const dataTimeZoneLocal = Intl.DateTimeFormat().resolvedOptions();

  const getlocaledate = (date) => {
    const localDateTime = new Date(`${date}-0500`);
    const dataTimeZoneLocal = Intl.DateTimeFormat().resolvedOptions();
    const dateOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      timeZone: ipInfo.current?.timezone || dataTimeZoneLocal.timeZone,
    };
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: ipInfo.current?.timezone || dataTimeZoneLocal.timeZone,
    };
    const respDate = new Intl.DateTimeFormat(
      dataTimeZoneLocal.locale,
      dateOptions
    ).format(localDateTime);
    const respTime = new Intl.DateTimeFormat(
      dataTimeZoneLocal.locale,
      timeOptions
    ).format(localDateTime);

    return `${respDate} ${respTime}`;
  };

  const getlocaledatePeru = (date) => {
    const localDateTime = new Date(`${date}-0500`);
    //const dataTimeZoneLocal = Intl.DateTimeFormat().resolvedOptions();
    const dateOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      timeZone: "America/Lima",
    };
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/Lima",
    };
    const respDate = new Intl.DateTimeFormat("es-PE", dateOptions).format(
      localDateTime
    );
    const respTime = new Intl.DateTimeFormat("es-PE", timeOptions).format(
      localDateTime
    );

    return `${respDate} ${respTime}`;
  };

  const getlocaldayofweek = (date) => {
    const getdayofweek = new Date(`${date}-0500`).toLocaleString(
      dataTimeZoneLocal.locale,
      {
        timeZone: dataTimeZoneLocal.timeZone,
        weekday: "long",
      }
    );
    return getdayofweek;
  };

  const selectfetch = (idsubtype, filter, nroPage) => {
    //console.log("ver idsubtype", idsubtype);
    // console.log("ver filter", filter);
    // console.log("ver nroPage", nroPage);
    if (filter === 2 || filter === 3) {
      // vista mensual y anual
      const currentmonth = new Date().getMonth() + 1;
      const currentyear = new Date().getFullYear();
      return fetch(
        `${API_URL}meetings/year/${currentyear}/month/${currentmonth}`
      );
    } else {
      //vista hoy y semanal
      const datatosend = Object.assign(
        {},
        {
          SizeList: filter === 1 ? 3 : 127, // muchos elementos vista semanal
          NroPage: nroPage,
          ParametersSearched: {
            FlagSearchMeetingFilterId: 1,
            IdMeetingFilter: filter,
            FlagSearchSubMeetingTypeId: idsubtype === -1 ? 0 : 1, //selecciona todos
            IdSubMeetingTypeId: idsubtype,
          },
        }
      );
      //console.log("ESTO SE ENVIA AL POST DE meetings/list", datatosend);
      return fetch(`${API_URL}meetings/list`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(datatosend),
      });
    }
  };

  const getdatafromfetchselected = (result1, filter) => {
    if (filter === 2 || filter === 3) return result1.objModel;
    else return result1.objModel.listElements;
  };

  const getdatamodifiedfromfetch = (datafetchselected, result2) => {
    console.log(
      "datafetchselected en getdatamodifiedfromfetch =>",
      datafetchselected
    );
    console.log("result2.objModel.length  =>", result2.objModel.length);
    datafetchselected.forEach((item1) => {
      if (result2.objModel.length === 0) {
        if (item1.recordedLink !== null) item1.showpopover = false;
        else item1.showpopover = true;
        //showpopover
      } else {
        result2.objModel.forEach((item2) => {
          if (item1.recordedLink !== null) item1.showpopover = false;
          else {
            if (item1.id === item2.id) item1.showpopover = false;
            else item1.showpopover = true;
          }
        });
      }
      // add date locale start and end
      item1.startDatePeru = getlocaledatePeru(item1.startDate);
      item1.endDatePeru = getlocaledatePeru(item1.endDate);
      item1.startDateLocale = getlocaledate(item1.startDate);
      item1.endDateLocale = getlocaledate(item1.endDate);
      item1.dayofweekstart = getlocaldayofweek(item1.startDate);
      item1.isUTCminus5 = new Date().getTimezoneOffset() === 300 ? true : false;
      item1.title = item1.subMeetingType.name;
    });
    return datafetchselected;
  };

  const promise_fetch_listevents_and_currentevent = ({
    idsubtype,
    filter,
    nroPage,
  }) => {
    setisloadedapidata(false);

    Promise.all([
      selectfetch(idsubtype, filter, nroPage).then((res) => res.json()),
      fetch(`${API_URL}meetings/current`).then((res) => res.json()),
      fetch(`https://ipinfo.io/?token=99b920e70eaec6`).then((res) =>
        res.json()
      ),
    ])
      .then(([result1, result2, result3]) => {
        // console.log("result1 =>", result1);
        console.log("RESULT2 =>", result2);
        // console.log("haber result3", result3);
        const datafetchselected = getdatafromfetchselected(result1, filter);

        let dataconvertfetch = getdatamodifiedfromfetch(
          datafetchselected,
          result2
        );

        ipInfo.current = result3;

        settotalPages(filter === 1 ? result1.objModel.totalPages : 1); //paginado vista hoy

        switch (filter) {
          case 1:
            setdatatoday(dataconvertfetch);
            break;
          case 4:
            setdataweek(dataconvertfetch);
            break;
          case 2:
            setdatamonth(dataconvertfetch);
            break;
          case 3:
            setdatayear(dataconvertfetch);
            break;
          default:
            break;
        }

        setisloadedapidata(true);
      })
      .catch((error) => {
        setisloadedapidata(true);
      });
  };

  useEffect(() => {
    if (idsubtype !== "def" && filter !== null) {
      promise_fetch_listevents_and_currentevent({
        idsubtype,
        filter,
        nroPage: 1,
      });
      setnroPage(1); // para mensual es despreciable
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, idsubtype]);

  useEffect(() => {
    if (idsubtype !== "def" && nroPage >= 1 && filter !== null) {
      promise_fetch_listevents_and_currentevent({ idsubtype, filter, nroPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nroPage]);

  return (
    <LoadApi indicatorLoad={!isloadedapidata} color="warning">
      {filter === 1 && (
        <div className="container">
          <TodayEvent data={datatoday} ipInfo={ipInfo.current} />
          <Pagination
            nroPage={nroPage}
            setnroPage={setnroPage}
            totalPages={totalPages}
            isloadedapidata={isloadedapidata}
          />
        </div>
      )}
      {filter === 4 && <WeekEvent data={dataweek} ipInfo={ipInfo.current} />}

      {filter === 2 && (
        <div className="container">
          <MonthEvent
            data={datamonth}
            ipInfo={ipInfo.current}
            API_URL={API_URL}
            // getdatamodifiedfromfetch={getdatamodifiedfromfetch}
          />
        </div>
      )}

      {filter === 3 && (
        <div className="container">
          <YearEvent
            data={datayear}
            ipInfo={ipInfo.current}
            API_URL={API_URL}
            // getdatamodifiedfromfetch={getdatamodifiedfromfetch}
          />
        </div>
      )}
    </LoadApi>
  );
}
