import ModalContent from 'components/ModalContent';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setScheduleAction } from 'services/meKeola.service';
import Hours from './Hours';

const ModalDays = ({
  schedule,
  navigate,
  confirmSelectHour
}) => {
  const dispatch = useDispatch()

  const [routes, setRoutes] = useState([]);
  const [scenes, setScenes] = useState({});
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [isToday, setIsToday] = useState(true);
  const [isOpenModalDays, setIsOpenModalDays] = useState(false);

  useEffect(() => {
    const minEstOrden = 0;
    const maxEstOrden = 48;
    const schedulesStoreTotal = schedule;

    const today = new Date();
    const nameToday = today.toLocaleDateString('es-ES', { weekday: 'long' }).toUpperCase();
    const numberDayCurrent = today.getDay();
    console.log(nameToday, numberDayCurrent);
    const dayYesWork = schedulesStoreTotal.find((e) => e.dayName === nameToday);

    const schedulesStoreAux1 = schedulesStoreTotal.filter(
      (e) =>
        e.idSystemDay >
        (isToday ? dayYesWork.idSystemDay - 1 : numberDayCurrent)
    );
    const schedulesStoreAux2 = [
      ...schedulesStoreAux1,
      ...schedulesStoreTotal.map((e) => {
        return {
          ...e,
          idSystemDay: e.idSystemDay + 7
        };
      })
    ];
    const schedulesStoreAux3 = schedulesStoreAux2.map((e) => {
      const startTimeAux = new Date(e.startTime + 'Z');
      const endTimeAux = new Date(e.endTime + 'Z');

      //En caso que la hora de cierre pase las 12 de la noche (si se ve desde otro país)
      const dateStartTimeAux = startTimeAux.getDate();
      const dateEndTimeAux = endTimeAux.getDate();
      let nextDay = 0;
      if (dateEndTimeAux > dateStartTimeAux) nextDay = 1;

      //Obteniendo mes del StartTime
      let monthOfStartTime = new Date();
      monthOfStartTime.setDate(
        today.getDate() + e.idSystemDay - numberDayCurrent
      );
      const numberMonthStart = monthOfStartTime.getMonth();
      //Estableciendo dia del mes
      startTimeAux.setDate(today.getDate() + e.idSystemDay - numberDayCurrent);
      //Establenciendo el número del mes
      startTimeAux.setMonth(numberMonthStart);

      //Obteniendo mes del StartTime~
      let monthOfEndTime = new Date();
      monthOfEndTime.setDate(
        today.getDate() + e.idSystemDay - numberDayCurrent + nextDay
      );
      const numberMonthEnd = monthOfEndTime.getMonth();
      //Estableciendo dia del mes
      endTimeAux.setDate(
        today.getDate() + e.idSystemDay - numberDayCurrent + nextDay
      );
      //Establenciendo el número del mes
      endTimeAux.setMonth(numberMonthEnd);

      return {
        ...e,
        startTime: startTimeAux,
        endTime: endTimeAux
      };
    });

    console.log('schedulesStoreAux3:', schedulesStoreAux3);

    let horasDisponibles = maxEstOrden - minEstOrden;
    const schedulesStoreAux4 = schedulesStoreAux3
      .map((e, ind) => {
        console.log('horasDisponibles:', horasDisponibles);
        if (horasDisponibles > 0) {
          const startTimeAux = e.startTime;
          const endTimeAux = e.endTime;

          if (ind === 0) {
            if (isToday) {
              const hourCurrent = today.getHours();
              const minCurrent = today.getMinutes();
              if (minCurrent > 30) {
                startTimeAux.setHours(hourCurrent + 1);
                startTimeAux.setMinutes(0);
              } else {
                startTimeAux.setHours(hourCurrent);
                startTimeAux.setMinutes(30);
              }
            }
            startTimeAux.setHours(startTimeAux.getHours() + minEstOrden);
          }
          if (
            endTimeAux.getHours() +
            endTimeAux.getMinutes() / 60 -
            (startTimeAux.getHours() + startTimeAux.getMinutes() / 60) >=
            horasDisponibles
          ) {
            endTimeAux.setHours(
              startTimeAux.getHours() +
              startTimeAux.getMinutes() / 60 +
              horasDisponibles
            );
            horasDisponibles = 0;
          } else {
            horasDisponibles =
              horasDisponibles -
              (endTimeAux.getHours() +
                endTimeAux.getMinutes() / 60 -
                (startTimeAux.getHours() + startTimeAux.getMinutes() / 60));
          }

          return {
            ...e,
            startTime: startTimeAux,
            endTime: endTimeAux
          };
        }
      })
      .filter((e) => e !== undefined);

    console.log('schedulesStoreAux4 EST:', schedulesStoreAux4);

    const schedulesStoreAux5 = schedulesStoreAux4.map((e, ind) => {
      return {
        key: ind,
        title: moment(e.startTime).format('DD/MM').toUpperCase(),
        nameday: e.dayName,
        n_day: e.startTime.getDate(),
        startTime: e.startTime,
        endTime: e.endTime,
        n_mount: e.startTime.getMonth() + 1,
        year: e.startTime.getFullYear()
      };
    });
    console.log('schedulesStoreAux5:', schedulesStoreAux5);
    setRoutes(schedulesStoreAux5);
  }, [])


  const changeSelectDay = (e) => {

    setSelectedRoute(e)
    setIsOpenModalDays(!isOpenModalDays)
    console.log(e)
  }
  // function convert hours to 12 hours format
  const convertTime = (time) => {
    let hours = time?.split(":")[0];
    let minutes = time?.split(":")[1];
    let newFormat = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${newFormat}`;
  }
  const acceptDay = () => {
    // dispatch(setScheduleAction(null))
    confirmSelectHour({
      startTime: moment(selectedRoute.startTime).format('HH:mm'),
      finalHour: moment(selectedRoute.endTime).format('HH:mm'),
      n_day: moment(selectedRoute?.startTime).format('DD'),
      nameday: selectedRoute?.nameday,
      n_mount: moment(selectedRoute?.startTime).format('MM'),
      year: moment(selectedRoute?.startTime).format('YYYY'),
      interval_schedule : selectedRoute?.startTime?.toLocaleTimeString() + ' - ' + selectedRoute?.endTime?.toLocaleTimeString()
    })
    setIsOpenModalDays(!isOpenModalDays)
    // setIsOpenModalHours(true)
    changeSelectDay(null)
  }
  return (
    <>
      {
        routes.length > 0 &&
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                {/* {
                  selectedRoute ?
                    <Hours
                      day={selectedRoute}
                      setSelectedRoute={setSelectedRoute}
                      confirmSelectHour={confirmSelectHour}
                      navigate={navigate}
                    />
                    : */}
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Día</th>
                      <th>Hora de inicio</th>
                      <th>Hora de fin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routes.map((e, ind) => (
                      <tr key={ind}>
                        <td>{e.nameday}({
                          e.n_day
                        })</td>
                        <td>{convertTime(moment(e.startTime).format('HH:mm'))}</td>
                        <td>{convertTime(moment(e.endTime).format('HH:mm'))}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              changeSelectDay(e)
                            }
                            }
                          >
                            Seleccionar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* } */}
              </div>
            </div>
          </div>
        </div>
      }
      <ModalContent
        title="Aviso importante"
        isOpen={isOpenModalDays}
        handleClose={() => {
          changeSelectDay(null)
        }}
        size="sm"

      >
        <p>
          ¿Está seguro de seleccionar el día {selectedRoute?.title} {selectedRoute?.nameday}?
          Las horas de atención son de
          <strong> {convertTime(moment(selectedRoute?.startTime).format('HH:mm'))} a {convertTime("13:00")} </strong>
          y de <strong>{convertTime("14:00")}a {convertTime(moment(selectedRoute?.endTime).format('HH:mm'))}</strong>
        </p>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            onClick={() => {
              acceptDay()
            }}
          >
            Aceptar
          </button>
        </div>
      </ModalContent>
    </>
  )
}

export default ModalDays
