import React, { useEffect } from "react";
import { useState } from "react";
import {
  Form,
  Row,
  Col,
  Image,
  Button,
  Table,
  BreadcrumbItem,
} from "react-bootstrap";
function PayVoucherBanCol({
  total,
  addVoucher,
  close,
  typeBank,
  exchange,
  nacionalidadUsuario,
  fontSize,
}) {

    const [typeExchange, setTypeExchange] = useState(1)
    const [typeExchangeDes, setTypeExchangeDes] = useState("Dólares")
    const [vouchersTable, setVouchersTable] = useState([
      {
        comision: 0,
        comisionExchange: 0,
        type: "0",
        typeDescription: "",
        code: "",
        titular: "",
        voucherBase: "",
        imageRaw: {},
        name: "",
        bank:"DAVIVIENDA",
        amount: "0.00",
      },
    ],)

    const [bank, setBank] = useState("BCP")

    const [idBank, setIdBank] = useState(6)

    const [typePays , setTypePays ] = useState([])
    const [typeDolars , setTypeDolars ] = useState([])
    const [typeSoles , setTypeSoles ] = useState([])
  return (
    <div>
      <Row>
        <Col sm={12}>
          <Form.Label>
            <b>Valle Encantado SAC </b>
          </Form.Label>
        </Col>
      </Row>
      <Row>
          <Col sm={6}>
            <Form.Label>
            <b>Cuenta Corriente en pesos: </b>
            </Form.Label>
            <br></br>
            <Form.Label>006890315630</Form.Label>
            <br></br>

            
          </Col>
          <Col sm={6}>
            <Form.Label>
              <b>Cuenta Corriente en dólares: </b>
            </Form.Label>
            <br></br>
            <Form.Label>000-000000-0-00</Form.Label>
            <br></br>

            <br></br>
          
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Label className="textAlert">
              * Los pagos a través de este medio tienen una COMISIÓN de acuerdo
              al tipo de operación.
            </Form.Label>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col sm={8}>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                <b>Moneda:</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Select
                  size="sm"
                  defaultValue={"1"}
                  style={{ fontSize: fontSize }}
                  onChange={(e) => this.handleExchange(e)}
                >
                  {/* <option value="0" disabled>Seleccione</option> */}
                  <option value="1">Dólares</option>
                  <option value="3">Pesos colombianos</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col
            sm={4}
            style={{ display: typeExchange === 2 ? "inline" : "none" }}
          >
            <Form.Label>
              <b>Tipo de Cambio:</b> {this.state.exchange}
            </Form.Label>
          </Col>
          <Col
            sm={4}
            style={{ display: typeExchange === 3 ? "inline" : "none" }}
          >
            <Form.Label>
              <b>Tipo de Cambio:</b> {4802.97}
            </Form.Label>
          </Col>
          <Col sm={4}>
              <Form.Label>
                <b>Monto DaVivienda $:</b> {this.state.mount}
              </Form.Label>
            </Col>

          <Col
            sm={4}
            style={{ display: typeExchange === 2 ? "inline" : "none" }}
          >
            <Form.Label>
              <b>Monto Bcp S/ :</b>{" "}
              {this.roundCalculate(this.state.mount * this.state.exchange)}
            </Form.Label>
          </Col>
          <Col
            sm={4}
            style={{ display: typeExchange === 3 ? "inline" : "none" }}
          >
            <Form.Label>
              <b>Monto DaVivienda :</b>{" "}
              {(this.state.mount * 4802.97).toLocaleString("en-US", {
                style: "currency",
                currency: "COL",
              })}
            </Form.Label>
          </Col>
        </Row>

        <Row>
          {typeExchange == 2 ? (
            <Form.Label>
              <b>Comision S/</b> {vouchersTable[0].comisionExchange}
            </Form.Label>
          ) : (
            <Form.Label>
              <b>Comision $:</b> {vouchersTable[0].comision}
            </Form.Label>
          )}
        </Row>
        <Row>
          {typeExchange == 2 ? (
            <Form.Label>
              <b>Monto total a pagar S/:</b>{" "}
              {(
                this.state.mount * this.state.exchange +
                vouchersTable[0].comisionExchange
              ).toFixed(2)}
            </Form.Label>
          ) : (
            <Form.Label>
              <b>Monto total a pagar $:</b>{" "}
              {this.state.mount + vouchersTable[0].comision}
            </Form.Label>
          )}
        </Row>

        <hr></hr>
        <Row>
          <Col sm={12}>
            <div className="main" style={{ fontSize: 12, color: "green" }}>
              (*) Después de agregar los comprobantes de pago no olvide
              REGISTRAR el formulario.
            </div>
          </Col>
        </Row>
    </div>
  );
}

export default PayVoucherBanCol;
