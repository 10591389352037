import React, { Component } from "react";
import LoadApi from "validators/LoadApi";
import {
  Form,
  Tabs,
  Tab,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FaEdit } from "react-icons/fa";
import { AiFillNotification } from "react-icons/ai";

import UserService from "../../services/user.service";
import Validation from "../utils/Validation";
import AuthService from "../../services/auth.service";
import MainView from "../../views/home/MainView";
import FlyerView from "../../views/message/FlyerView";

const SUS_END = 12;
const SUS_PEN = 10;
const SUS_PEN_INI = 2;

export default class AffiliateLevel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registers: [],
      emptyList: true,
      message: "No hay registros para mostrar.",
      loading: false,
      activePage: 1,
      levelTreePatrocinio: 0,
      txtSearch: "",
      FlagSearchState: 0,
      IsStateActivePartner: "-1",
      FlagSearchPartnersExpireCicle: 2,
      idState: "0",
      totalPages: 1,
      page: 1,
      size: 10,
      totalRegister: 0,
      showModal: false,
      suscriptionModal: undefined,
      userModal: undefined,
      checkCurrent: true,
      checkSponsor: false,
      checkOther: false,
      emailOther: "",
      showModalEdit: false,
      userCurrent: undefined,
      loadingSaveUSer: false,
      saveService: false,
      messageSave: "",
      index: -1,
      id: undefined,
      name: undefined,
      lastname: undefined,
      nroDocument: undefined,
      email: undefined,
      nroTelf: undefined,
      scheduleModal: [],
    };
  }

  componentDidMount() {}

  handlePageChange = (e, field) => {
    let value = Number(e.target.value);
    this.setState({
      [field]: (this.state[field] = value),
      loading: true,
      registers: [],
      emptyList: true,
    });

    // Call to  services

    let parameter = this.getParameter();
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };

  handleSizeChange = (e, field) => {
    let value = Number(e.target.value);

    this.setState({
      size: (this.state.size = value),
      page: (this.state.page = 1),
      loading: true,
      registers: [],
      emptyList: true,
    });

    // Call to  services

    let parameter = this.getParameter();
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };

  handleChange = (e, field) => {
    let value = e.target.value;

    this.setState({
      [field]: (this.state[field] = value),
    });
  };

  handleChangeCheck = (e, field) => {
    let value = e.target.value;

    this.setState({
      [field]: (this.state[field] = !this.state[field]),
    });
  };

  /**
   * Event to search
   * @param {*} e
   */
  searchAffiliate = (e) => {
    this.setState({
      loading: true,
      registers: [],
      emptyList: true,
    });
    let parameter = this.getParameter();
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };

  getParameter = () => {
    let data = {};
    let parameters = {};

    parameters.FlagSearchState = this.state.FlagSearchState;
    parameters.IsStateActivePartner = Number(this.state.IsStateActivePartner);
    if (
      this.state.FlagSearchPartnersExpireCicle === 2 ||
      this.state.FlagSearchPartnersExpireCicle === -1
    ) {
      parameters.FlagSearchPartnersExpireCicle = 0;
      parameters.FlagSearchPartnersExpireCycleDays = 0;
    } else {
      parameters.FlagSearchPartnersExpireCicle =
        this.state.FlagSearchPartnersExpireCicle === 0 ? 1 : 0;
      parameters.FlagSearchPartnersExpireCycleDays =
        this.state.FlagSearchPartnersExpireCicle === 0 ? 0 : 1;
    }

    if (Number(this.state.idState) > 0) {
      parameters.FlagSearchSuscription = 1;
      parameters.StateSuscriptionSearch = Number(this.state.idState);
    }

    parameters.IdSocioSearch = Number(AuthService.getCurrentIdUser());
    parameters.LevelTreePatrocinio = Number(this.state.levelTreePatrocinio);
    data.ParametersSearched = parameters;
    data.SizeList = this.state.size;
    data.NroPage = this.state.page;

    return data;
  };

  getSearchResults = async (parameter) => {
    this.setState({
      loading: (this.state.loading = true),
    });
    let response = await UserService.managementAffiliateSearchv2(parameter);

    if (response !== undefined && response !== null) {
      if (response.status !== 1) {
        //;
        this.setState({
          registers: (this.state.registers = []),
          emptyList: (this.state.emptyList = true),
          loading: (this.state.loading = false),
          totalRegister: (this.state.totalRegister = 0),
          message: (this.state.message =
            "Se ha producido un error. Inténtelo más tarde."),
          totalRegister: (this.state.totalRegister = 0),
        });
      } else {
        let object = response.objModel;
        if (object.listElements.length > 0) {
          object.listElements.map((item) => {
            item.currentSuscription = undefined;
          });

          this.setState({
            registers: (this.state.registers = object.listElements),
            emptyList: (this.state.emptyList = false),
            loading: (this.state.loading = false),
            message: (this.state.mesagge = ""),
            totalPages: (this.state.totalPages = object.totalPages),
            totalRegister: (this.state.totalRegister = object.totalElemList),
          });
        } else {
          this.setState({
            registers: (this.state.registers = []),
            emptyList: (this.state.emptyList = true),
            loading: (this.state.loading = false),
            message: (this.state.mesagge = "No hay registros para mostrar."),
            totalPages: (this.state.totalPages = 1),
            page: (this.state.page = 1),
            totalRegister: (this.state.totalRegister = 0),
          });
        }
      }
    } else {
      this.setState({
        registers: (this.state.registers = []),
        emptyList: (this.state.emptyList = true),
        loading: (this.state.loading = false),
        message: (this.state.message =
          "Se ha producido un error. Inténtelo más tarde."),
        totalRegister: (this.state.totalRegister = 0),
      });
    }
  };

  sendWelcome = async (e, item) => {
    e.preventDefault();
    if (item.currentSuscription !== undefined) {
      let data = {
        FlagEnvioPatrocinador: 0,
        IdSuscripcion: item.currentSuscription.id,
      };
      let response = await UserService.sendWelcomeMsg(data);

      if (response !== undefined && response.status === 1) {
        alert("Mensaje de bienvenida fue enviado con éxito.");
      } else {
        alert("Se ha producido un error. Inténtelo más tarde.");
      }
    } else {
      alert("Seleccione una suscripción.");
    }
  };

  /**
   *
   * @param {*} e : event
   * @param {*} item : item selected od the list users
   * @param {*} idx : index - position od user into list
   */
  getSuscription = async (e, item, idx) => {
    let idPackage = Number(e.target.value);
    let idxPackage = e.target.selectedIndex - 1;

    // modify current item
    let response = await UserService.getScheduleDatail(idPackage);
    if (response.status === 1) {
      item.currentSuscription = response.objModel;
      item.currentSuscription.id = idPackage;

      //update item in registers
      let listRegisters = this.state.registers;
      listRegisters[idx] = item;
      this.setState({
        registers: (this.state.registers = listRegisters),
      });
    }
  };

  // Handle modal alert
  handleClose = (e) => {
    this.setState({
      showModal: false,
      suscriptionModal: undefined,
      checkCurrent: true,
      checkSponsor: false,
      checkOther: false,
      emailOther: "",
    });
  };
  handleShow = (e, item) => {
    e.preventDefault();
    if (item.currentSuscription !== undefined) {
      this.setState({
        showModal: true,
        suscriptionModal: item.currentSuscription,
      });
    } else {
      alert("Seleccione una suscripción.");
    }
  };

  sendAlert = async (e) => {
    e.preventDefault();
    if (this.state.suscriptionModal !== undefined) {
      let checkSponsor = this.state.checkSponsor;
      let checkOther = this.state.checkOther;
      let emailOther = this.state.emailOther;

      let object = {
        FlagEnvioPatrocinador: checkSponsor ? 1 : 0,
        IdSuscripcion: this.state.suscriptionModal.id,
      };

      if (checkOther) {
        if (emailOther.length > 0) {
          object.OtroEmail = emailOther;
        } else {
          alert("Ingrese correo.");
          return;
        }
      }

      this.handleClose();

      let response = await UserService.sendAlertPayment(object);

      if (response !== undefined && response.status === 1) {
        alert("Alerta de pago fue enviada con éxito.");
      } else {
        alert("Se ha producido un error. Inténtelo más tarde.");
      }
    } else {
      alert("Seleccione una suscripción.");
    }
  };

  // Handle modal alert
  handleCloseEdit = (e) => {
    this.setState({
      showModalEdit: false,
      name: undefined,
      lastname: undefined,
      nroDocument: undefined,
      email: undefined,
      nroTelf: undefined,
      userCurrent: undefined,
      loadingSaveUSer: false,
      saveService: false,
      messageSave: "",
    });
  };
  handleShowEdit = (e, item, idx) => {
    e.preventDefault();
    if (item !== undefined) {
      this.setState({
        showModalEdit: true,
        userCurrent: item,
        index: idx,
        id: item.id,
        name: item.name,
        lastname: item.lastName,
        nroDocument: item.nroDocument,
        email: item.email,
        nroTelf: item.nroTelf,
      });
    } else {
      // TODO select user
    }
  };

  verifyField() {
    if (!(this.state.name !== null && this.state.name.length > 0)) {
      alert("Ingrese nombres.");
      return false;
    }

    if (!(this.state.lastname !== null && this.state.lastname.length > 0)) {
      alert("Ingrese apellidos.");
      return false;
    }

    if (
      !(this.state.nroDocument !== null && this.state.nroDocument.length > 0)
    ) {
      alert("Ingrese número de documento.");
      return false;
    }

    if (!(this.state.email !== null && this.state.email.length > 0)) {
      alert("Ingrese correo electrónico.");
      return false;
    }

    if (!(this.state.nroTelf !== null && this.state.nroTelf.length > 0)) {
      alert("Ingrese teléfono.");
      return false;
    }

    return true;
  }

  //Save user
  saveUser = async (e) => {
    if (this.verifyField()) {
      let data = {
        id: this.state.id,
        name: this.state.name,
        lastName: this.state.lastname,
        nroDocument: this.state.nroDocument,
        email: this.state.email,
        nroTelf: this.state.nroTelf,
      };

      this.setState({
        loadingSaveUSer: true,
        saveService: false,
        messageSave: "",
      });

      let response = await UserService.updateUser(data);

      if (response != undefined) {
        this.setState({
          loadingSaveUSer: false,
          saveService: true,
          messageSave: "",
          name: undefined,
          lastname: undefined,
          nroDocument: undefined,
          email: undefined,
          nroTelf: undefined,
          userCurrent: undefined,
        });
        if (response.status === 1) {
          this.setState({
            messageSave: "Información actualizada.",
          });
          this.updateItem(this.state.index, data);
        } else {
          this.setState({
            messageSave: "Tuvimos problemas al actualizar la información.",
          });
        }
      } else {
        this.setState({
          loadingSaveUSer: false,
          saveService: true,
          messageSave: "Ocurrió un problema al actualizar información.",
        });
      }
    }
  };

  // update index
  updateItem = (idx, item) => {
    if (idx > 0) {
      let registers = this.state.registers;
      registers[idx].name = item.name;
      registers[idx].lastName = item.lastName;
      registers[idx].nroDocument = item.nroDocument;
      registers[idx].email = item.email;
      registers[idx].nroTelf = item.nroTelf;

      this.setState({
        registers: (this.state.registers = registers),
      });
      console.log(this.state.registers);
    }
  };
  // Verify nro Document
  onBlurDocument = (e, field, fieldMessage) => {
    let nroDocument = this.state[field];
    if (
      this.state.userCurrent.nroDocument !== nroDocument &&
      nroDocument.length > 0
    ) {
      this.verifyDocument(field, fieldMessage);
    }
  };

  async verifyDocument(field, fieldMessage) {
    let data = {};
    data.nroDocument = this.state[field];

    let isRegister = await UserService.isDocumentRegistered(data);

    if (!isRegister) {
      this.setState({ [fieldMessage]: "" });
    } else {
      this.setState({
        [fieldMessage]: "Este documento ya ha sido registrado.",
      });
    }
  }

  onBlurEmail = (e, field, fieldMessage) => {
    let value = e.target.value.trim();
    if (value.length > 0) {
      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
        this.setState({
          [fieldMessage]: (this.state[fieldMessage] = ""),
        });

        if (value !== this.state.userCurrent.email) {
          this.verifyEmail(value, field, fieldMessage);
        }
      } else {
        this.setState({
          [field]: (this.state[field] = ""),
          [fieldMessage]: (this.state[fieldMessage] =
            "Ingrese un correo válido."),
        });
      }
    }
  };
  // Verify email
  async verifyEmail(email, field, fieldMessage) {
    let data = {};
    data.email = email;
    if (this.state.userCurrent.email !== email) {
      let isRegistered = await UserService.isEmailRegistered(data);
      if (isRegistered) {
        this.setState({
          [field]: (this.state[field] = ""),
          [fieldMessage]: (this.state[fieldMessage] =
            "Este correo ya está registrado."),
        });
      }
    }
  }

  handleSelectState = (e) => {
    const value = Number(e.target.value);
    const flag = value >= 0 ? 1 : 0;
    this.setState({
      IsStateActivePartner: (this.state.IsStateActivePartner = e.target.value),
      FlagSearchState: (this.state.FlagSearchState = flag),
    });
  };

  handleChangeCheck = (e, field) => {
    let value = e.target.value;

    this.setState({
      [field]: (this.state[field] = !this.state[field]),
    });
  };

  // Build rows
  getOptions = (item, idx) => {
    let rows = [];

    if (item.currentSuscription !== undefined) {
      rows.push(
        <td>
          <Button
            variant="warning"
            size="sm"
            variant="secondary"
            onClick={(e) => {
              this.handleShowSchedule(e, item);
            }}
          >
            Ver
          </Button>
        </td>
      );
    } else {
      rows.push(<td></td>);
    }

    // Alertayn)
    if (
      item.currentSuscription !== undefined &&
      item.currentSuscription.status !== SUS_END &&
      item.currentSuscription.status !== SUS_PEN &&
      item.currentSuscription.status !== SUS_PEN_INI
    ) {
      rows.push(
        <td>
          <Button
            variant="secondary"
            size="sm"
            onClick={(e) => {
              this.handleShow(e, item);
            }}
          >
            <AiFillNotification></AiFillNotification>
          </Button>
        </td>
      );
    } else {
      rows.push(<td></td>);
    }

    // Welcome
    if (
      item.currentSuscription !== undefined &&
      item.currentSuscription.descriptionPendingFee
        .toUpperCase()
        .includes("INICIAL")
    ) {
      rows.push(
        <td>
          <Button
            variant="secondary"
            size="sm"
            onClick={(e) => {
              this.sendWelcome(e, item);
            }}
          >
            Bienvenida
          </Button>
        </td>
      );
    } else {
      rows.push(<td></td>);
    }

    // Edit state 2 pago despues(4) y  pendiente de validacion inicial

    if (item.state === 2 || item.state === 4) {
      rows.push(
        <td>
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => {
              this.handleShowEdit(e, item, idx);
            }}
          >
            Editar
          </Button>
        </td>
      );
    } else {
      rows.push(<td></td>);
    }

    return rows;
  };

  // Show modal
  handleShowSchedule(e, item) {
    if (item.currentSuscription !== undefined) {
      this.setState({
        loadingSchedule: true,
        showModalSchedule: true,
      });
      this.getSchedule(item.currentSuscription.id);
    } else {
      alert("Seleccione una suscripcion");
    }
  }

  handleCloseSchedule = () => {
    this.setState({
      showModalSchedule: (this.state.showModalSchedule = false),
      scheduleModal: (this.state.scheduleModal = []),
    });
  };

  getSchedule = async (idSuscription) => {
    let schedule = await UserService.getScheduleWithImages(idSuscription);

    if (schedule !== undefined && schedule !== null) {
      if (schedule.status === 1) {
        this.setState({
          scheduleModal: (this.state.scheduleModal = schedule.objModel),
          loadingSchedule: false,
        });
      } else {
        this.setState({
          scheduleModal: (this.state.scheduleModal = []),
          loadingSchedule: false,
        });
        alert(
          "Tuvimos un error al obtener la información. Inténtelo más tarde."
        );
      }
    } else {
      this.setState({
        scheduleModal: (this.state.scheduleModal = []),
        loadingSchedule: false,
      });
      alert("Tuvimos un error al obtener la información. Inténtelo más tarde.");
    }
  };

  showDashBoard = (item) => {
    this.setState({
      showModal: true,
      item: item,
    });
  };

  handleClose = () => {
    this.setState({
      item: undefined,
      showModal: false,
    });
  };

  // Handle modal lto show image
  showFlyer = (item) => {
    this.setState({
      showModalFlyer: true,
      flyerItem: item,
    });
  };

  handleCloseFlyer = () => {
    this.setState({
      item: undefined,
      showModalFlyer: false,
    });
  };
  render() {
    const {
      registers,
      activePage,
      levelTreePatrocinio,
      page,
      size,
      totalPages,
      totalRegister,
      showModal,
      item,
      showModalFlyer,
      flyerItem,
      suscriptionModal,
      checkCurrent,
      checkSponsor,
      checkOther,
    } = this.state;

    let optionPages = [];
    for (let i = 0; i < totalPages; i++) {
      optionPages.push(<option value={i + 1}>{i + 1}</option>);
    }

    return (
      <div>
        <Form.Group>
          {/* <p>Información actualizada al: <b>{this.state.date}</b></p> */}
        </Form.Group>
        <div className="search">
          <h1 className="search-title">Buscador</h1>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label htmlFor="selectLevel">Nivel:</Form.Label>
                <Form.Select
                  id="selectLevel"
                  defaultValue={levelTreePatrocinio}
                  onChange={(e) => {
                    this.handleChange(e, "levelTreePatrocinio");
                  }}
                  size="sm"
                >
                  <option value="0" disabled>
                    Seleccione{" "}
                  </option>
                  <option value="2">Nivel 2</option>
                  <option value="3">Nivel 3</option>
                  <option value="4">Nivel 4</option>
                  <option value="5">Nivel 5</option>
                  <option value="6">Nivel 6</option>
                  <option value="7">Nivel 7</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Label>Estados: </Form.Label>
              <Form.Select
                as="select"
                defaultValue={this.state.IsStateActivePartner}
                onChange={(e) => {
                  this.handleSelectState(e);
                }}
                size="sm"
              >
                <option value="-1">Todos</option>
                <option value="1">Activos</option>
                <option value="0">Inactivos</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col sm={4} style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Form.Label>Próximos a vencer: </Form.Label>
              <Form.Select
                size="sm"
                defaultValue={2}
                onChange={(e) => {
                  this.handleChangeCheck(e, "FlagSearchPartnersExpireCicle");
                }}
              >
                <option value="-1" disabled>
                  Seleccione
                </option>
                <option value="2">No filtrar</option>
                <option value="0">Ciclo actual</option>
                {/* <option value="1">Todos los ciclos</option> */}
              </Form.Select>
            </Col>
          </Row>
          <Row style={{ textAlign: "right" }}>
            <Col sm={12}>
              <Form.Group>
                <Button
                  variant="secondary"
                  size="sm"
                  disabled={levelTreePatrocinio === 0}
                  onClick={(e) => {
                    this.searchAffiliate(e);
                  }}
                >
                  Buscar
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <hr></hr>
        {/* Paginador */}
        <Row>
          <Form.Label column xs={12} sm={12} md={10} xl={6}>
            Mostrando del {size * (page - 1) + 1} al&nbsp;
            {size * page > totalRegister ? totalRegister : size * page} de&nbsp;
            {totalRegister} registros. &nbsp;&nbsp;| Página:&nbsp;
            <Form.Select
              id="selecPages"
              size="sm"
              defaultValue={"1"}
              value={page}
              style={{ display: "inline", width: "auto" }}
              onChange={(e) => {
                this.handlePageChange(e, "page");
              }}
            >
              {optionPages}
            </Form.Select>{" "}
            de {totalPages}. Por página:{" "}
            <Form.Select
              id="selectCount"
              size="sm"
              defaultValue={"10"}
              style={{ display: "inline", width: "auto" }}
              onChange={(e) => {
                this.handleSizeChange(e, "size");
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </Form.Select>
          </Form.Label>
        </Row>
        {this.state.loading && (
          <LoadApi
            indicatorLoad={true}
            message="Cargando información ..."
            messageWarning="Este proceso puede demorar unos minutos."
          ></LoadApi>
        )}
        {this.state.emptyList && !this.state.loading && (
          <Form.Label>{this.state.message}</Form.Label>
        )}
        {!this.state.emptyList && (
          <div>
            <Row>
              <Col sm={12}>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th></th>
                      <th>F. Afiliación</th>
                      <th>Nombres y apellidos</th>
                      <th>Documento</th>
                      <th>Correo</th>
                      <th>Teléfono</th>
                      <th>Estado</th>
                      <th>Nivel Patrocinio</th>
                      <th>Suscripción</th>
                      <th>Estado</th>
                      <th>Próxima cuota</th>
                      <th>Fecha</th>
                      <th>Monto cuota</th>
                      <th>Cronograma</th>
                      <th>Not. de Pago</th>
                      <th>Not. bienvenida</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {registers.map((item, idx) => {
                      let date = "";
                      return (
                        <tr key={item.id}>
                          <td>{size * (page - 1) + 1 + idx}</td>
                          <td>
                            {Validation.convertDateToStringEx(item.createDate)}
                          </td>
                          <td>
                            {item.name} {item.lastName}
                          </td>
                          <td>
                            <div
                              style={{ width: "150px", wordBreak: "break-all" }}
                            >
                              {item.nroDocument}
                            </div>
                          </td>
                          <td>
                            <div
                              style={{ width: "150px", wordBreak: "break-all" }}
                            >
                              {item.email}
                            </div>
                          </td>
                          <td>{item.nroTelf}</td>
                          <td>{item.stateText}</td>
                          <td>{item.nivelPatrocinio}</td>
                          <td>
                            <div
                              style={{ width: "150px", wordBreak: "break-all" }}
                            >
                              <Form.Select
                                size="sm"
                                defaultValue={"DEFAULT"}
                                onChange={(e) => {
                                  this.getSuscription(e, item, idx);
                                }}
                              >
                                <option value="DEFAULT" disabled>
                                  Seleccione
                                </option>
                                {item.suscriptions.map((itemSus, ind) => {
                                  return (
                                    <option value={itemSus.id}>
                                      {itemSus.package.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </div>
                          </td>
                          {/* <td>
                                                    <Form.Control as="select"
                                                                id={item.id}
                                                                onChange={e => this.selectSuscription(e, item)}>
                                                                {item.suscription.map((itemSusc) => {
                                                                    <option value="10">{itemSusc.name}</option>
                                                                })
                                                                }
                                                    </Form.Control>
                                                </td> */}
                          {item.currentSuscription !== undefined && (
                            <td>{item.currentSuscription.statusName}</td>
                          )}
                          {item.currentSuscription !== undefined && (
                            <td>
                              {item.currentSuscription.descriptionPendingFee}
                            </td>
                          )}
                          {item.currentSuscription !== undefined && (
                            <td>
                              {Validation.convertDateToString(
                                item.currentSuscription.datePendingFee
                              )}
                            </td>
                          )}
                          {/* {item.currentSuscription !== undefined && item.currentSuscription.lastDatePaidFee === null &&
                                                     <td></td>
                                                } */}
                          {item.currentSuscription !== undefined && (
                            <td>USD {item.currentSuscription.pendingFee}</td>
                          )}
                          {item.currentSuscription === undefined && (
                            <td colSpan={4}></td>
                          )}
                          {/* { item.state === 1 &&
                                                    <td> 
                                                    <Button variant="secondary" size="sm" onClick={(e) => {this.sendWelcome(e, item)}}>Bienvenido</Button>
                                                    </td>
                                               } */}
                          {/* { item.state !== 1 &&
                                                    <td> 
                                                   </td>
                                               } */}
                          {item.suscriptions.length > 0 ? (
                            this.getOptions(item, idx)
                          ) : (
                            <td colSpan={3}>
                              <Form.Label>No hay suscripciones.</Form.Label>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        )}

        <Modal show={showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enviar alerta de pago</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {suscriptionModal !== undefined && (
              <div>
                <Row>
                  <Col sm={12}>
                    <Form.Label>Seleccione destinatarios:</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Check
                      custom
                      type={"checkbox"}
                      id={1}
                      label={"correo suscripcion"}
                      disabled
                      onChange={(e) =>
                        this.handleChangeCheck(e, "checkCurrent")
                      }
                      checked={checkCurrent}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Check
                      custom
                      type={"checkbox"}
                      id={2}
                      label={"Patrocinador"}
                      onChange={(e) =>
                        this.handleChangeCheck(e, "checkSponsor")
                      }
                      checked={checkSponsor}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Check
                      custom
                      type={"checkbox"}
                      id={3}
                      label={"Otro"}
                      onChange={(e) => this.handleChangeCheck(e, "checkOther")}
                      checked={checkOther}
                    />
                  </Col>
                </Row>
                <Row style={{ display: checkOther ? "inline" : "none" }}>
                  <Col sm={12}>
                    <Form.Control
                      placeholder="Ingrese correo electronico"
                      onChange={(e) => {
                        this.handleChange(e, "emailOther");
                      }}
                    ></Form.Control>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col sm={4}>
                    <Form.Label>Suscripcion: </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>{suscriptionModal.name}</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Form.Label>Descripcion: </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>
                      {suscriptionModal.descriptionPendingFee}
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Form.Label>Fecha de pago: </Form.Label>
                  </Col>
                  <Col sm={4}>
                    {suscriptionModal.datePendingFee === null && (
                      <Form.Label>/</Form.Label>
                    )}
                    {suscriptionModal.datePendingFee !== null && (
                      <Form.Label>
                        {Validation.convertDateToString(
                          suscriptionModal.datePendingFee
                        )}
                      </Form.Label>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col sm={4}>
                    <Form.Label>Monto USD: </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>{suscriptionModal.pendingFee}</Form.Label>
                  </Col>
                </Row>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={(e) => this.handleClose(e)}
            >
              Cerrar
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={(e) => this.sendAlert(e)}
            >
              Enviar
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Modal show dashboard */}
        {/* <Modal
          show={showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enviar alerta de pago</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                {item !== undefined && (
                  <p>
                    <b>Nombres: </b>
                    {item.name} {item.lastName}
                  </p>
                )}
              </Col>
              <Col sm={6}>
                {item !== undefined && (
                  <p>
                    <b>DNI: </b>
                    {item.nroDocument}
                  </p>
                )}
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col sm={12}>
                {item !== undefined && (
                  <div style={{ textAlign: "center", margin: "30px" }}>
                    <MainView idUser={item.id}></MainView>
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={(e) => this.handleClose(e)}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>*/}

        {/* Modal show Flyer */}
        <Modal
          show={showModalFlyer}
          aria-labelledby="contained-modal-title-vcenter"
          onHide={this.handleCloseFlyer}
        >
          <Modal.Header closeButton>
            <Modal.Title>Flyer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                {flyerItem !== undefined && (
                  <div style={{ textAlign: "center", margin: "10px" }}>
                    <FlyerView user={flyerItem}></FlyerView>
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={(e) => this.handleCloseFlyer(e)}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Modal to show schedule */}
        <Modal
          size="lg"
          show={this.state.showModalSchedule}
          onHide={this.handleCloseSchedule}
          style={{ fontSize: 12 }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cronograma</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loadingSchedule && (
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border" variant="dark"></Spinner>
                <p>Cargando cronograma...</p>
              </div>
            )}
            {!this.state.loadingSchedule &&
              this.state.scheduleModal.length <= 0 && (
                <div>
                  <Form.Label>no hay registros para mostrar.</Form.Label>
                </div>
              )}
            <br></br>
            <Row>
              <Col>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th>Descripción</th>
                      <th>Fecha de vencimiento</th>
                      <th>Capital</th>
                      <th>Amortización</th>
                      <th>Ínteres</th>
                      <th>Mora</th>
                      {/* <th>Puntaje de Mora</th> */}
                      <th>Cuota</th>
                      <th>Fecha de Pago</th>
                      <th>Estado</th>
                      {/* <th></th>
                                        <th>Observación</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.scheduleModal.length > 0 &&
                      this.state.scheduleModal.map((item, idx) => {
                        let amortization =
                          item.amortization / item.dollarExchange;
                        amortization =
                          Math.round((amortization + Number.EPSILON) * 100) /
                          100;

                        let capitalBalance =
                          item.capitalBalance / item.dollarExchange;
                        capitalBalance =
                          Math.round((capitalBalance + Number.EPSILON) * 100) /
                          100;

                        return (
                          <tr key={idx}>
                            <td>{item.quoteDescription}</td>
                            <td>
                              {Validation.convertDateToStringEx(
                                item.nextExpiration
                              )}
                            </td>
                            <td>{capitalBalance} USD</td>
                            <td>{amortization} USD</td>
                            <td>{item.interested} USD</td>
                            <td>-</td>
                            <td>{item.quoteUsd} USD</td>
                            <td>
                              {Validation.convertDateToStringEx(item.payDate)}
                            </td>
                            <td>
                              {item.verif === 1 && "Pagado"}
                              {(item.verif === 2 || item.verif === 10) &&
                                "Pendiente de validación"}
                              {item.verif === 3 && "Rechazado"}
                              {item.verif === 4 && "Pagar después"}
                              {item.verif > 4 && item.verif < 1 && ""}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseSchedule}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
