import React, { Component } from "react";
import ImageUploader from "react-images-upload";
import {
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Container,
  InputGroup,
} from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import Avatar from "react-avatar-edit";

import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import FlyerService from "../../services/flyer.service";
import Validation from "../utils/Validation";
import PhotoDni from "./PhotoDni";
import { responsiveFontSizes } from "@material-ui/core";
export default class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      user: {
        username: "",
        /*  loginname: "", */
      },
      userMod: {},
      username: "",
      password: "",
      newpassword: "",
      repassword: "",
      message: "",
      messageAlert: "",
      newMessage: "",
      isAvailable: false,
      isAvailableMessage: "",
      preview: null,
      defaultPreview: null,
      base64: null,
      sponsorNames: "",
      isShow: false,
      isShowNew: false,
      isShowRew: false,
      imgFlyer: "",
      imgFlyerPre: "",
      countFlyer: "#",
      loadingFlyer: true,
      colorFlyer: "green",
      messageFlyer: "",
      imagenEnBase64: null,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onCropDefault = this.onCropDefault.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCloseDefault = this.onCloseDefault.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
  }

  componentDidMount() {
    let user = AuthService.getCurrentUserInfo();
    if (user !== undefined) {
      let userInfo = {
        username: user.username,
        /*   loginname: user.loginname, */
      };
      this.setState({
        user: (this.state.user = userInfo),
        id: (this.state.id = user.id),
        loaded: true,
      });
    } else {
      this.setState({
        user: (this.state.user = {}),
        id: (this.state.id = 0),
        loaded: false,
      });
    }
    this.getUserProfilePicture();
    this.getSponsor();

    if (sessionStorage.getItem("range") === null) {
      this.setState({
        isAvailable: false,
        isAvailableMessage: "Ocurrió un problema. Inténtelo más tarde.",
      });
    } else {
      if (
        sessionStorage.getItem("range").toUpperCase() === "SOCIO" ||
        sessionStorage.getItem("range").toUpperCase() === "BRONCE"
      ) {
        this.setState({
          isAvailable: false,
          isAvailableMessage:
            "Podrás subir tu foto de Flyer a partir del rango Plata.",
        });
      } else {
        this.getFlyerImage();
        this.setState({
          isAvailable: true,
          isAvailableMessage: "",
        });
      }
    }
  }

  async getFlyerImage() {
    let id = AuthService.getCurrentIdUser();

    let response = await FlyerService.getInformationFlyer(id);

    if (response === undefined) {
      this.setState({
        loadingFlyer: false,
      });
      // alert("Ocurrió un problema. Inténtelo más tarde.");
    } else {
      if (response.status === 1 && response.objModel) {
        this.setState({
          infoFlyer: (this.state.infoFlyer = response.objModel),
          imgFlyer: (this.state.imgFlyer =
            response.objModel.pictureUnEditedFlyer.base64),
          imgFlyerPre: (this.state.imgFlyerPre =
            response.objModel.pictureUnEditedFlyer.base64),
          loadingFlyer: false,
        });
      } else {
        // TODO uncomment and show message when endpoint has state === 2
        const dataTemp = {
          editingRequestDate: "",
          idFlyer: 0,
          idFlyerEditingStatus: 1,
          idUser: id,
          lastEditionDate: null,
          listReasonsRejection: null,
          nameEditingStatus: "",
          nameFlyerFile: null,
          nameUnEditedFlyerFile: "",
          numberFlyerUpload: 0,
          observation: "",
          pictureEditedFlyer: "",
        };
        this.setState({
          infoFlyer: (this.state.infoFlyer = dataTemp),
          imgFlyer: (this.state.imgFlyer = ""),
          imgFlyerPre: (this.state.imgFlyerPre = ""),
          loadingFlyer: false,
        });
      }
    }
  }

  async getUserProfilePicture() {
    let id = AuthService.getCurrentIdUser();

    let response = await AuthService.getProfilePicture(id);
    console.log(
      "haber response en getUserProfilePicture , aqui ponen el  defaultPreview =>",
      response
    );
    if (response === undefined) {
      alert("Ocurrió un problema. Inténtelo más tarde.");
    } else {
      if (response.status === 1) {
        this.setState({
          defaultPreview: (this.state.defaultPreview =
            "data:image/png;base64," + response.objModel),
        });
      }
      console.log("haber defaultPreview", this.state.defaultPreview);
    }
  }

  async getSponsor() {
    let response = await UserService.getSponsorbyUser();

    if (response === undefined) {
      alert("Ocurrió un problema. Inténtelo más tarde.");
    } else {
      if (response.status === 0) {
        this.setState({
          sponsorNames: (this.state.sponsorNames = "No se registra Sponsor"),
        });
      } else {
        this.setState({
          sponsorNames: (this.state.sponsorNames =
            response.objModel.name + " " + response.objModel.lastname),
        });
      }
    }
  }

  /**
   * Method to handle the change  of properties and send it to parent
   * @param {*} e
   * @param {*} field
   */

  handleChange = (e, field) => {
    let value = e.target.value;
    this.setState({
      [field]: value,
      newMessage: "",
      message: "",
      messageAlert: "",
    });
  };

  handleChangeLoginName = (e) => {
    let value = e.target.value;
    this.setState({
      user: {
        ...this.state.user,
        /*   loginname: value, */
      },
    });
  };

  validation = () => {
    if (this.state.password.length === 0) {
      this.setState({
        message: "Ingrese su contraseña.",
      });
      return false;
    }
    if (this.state.password !== AuthService.getCurrentKey()) {
      this.setState({
        message: "Contraseña anterior no coincide.",
      });
      return false;
    } else if (this.state.newpassword.length === 0) {
      this.setState({
        newMessage: "Ingrese su nueva contraseña.",
      });
      return false;
    } else if (this.state.repassword.length === 0) {
      this.setState({
        messageAlert: "Vuelva a ingresar su contraseña.",
      });
      return false;
    } else if (this.state.newpassword !== this.state.repassword) {
      this.setState({
        messageAlert: "Las nuevas contraseñas no coinciden.",
      });
      return false;
    }
    return true;
  };
  validatePicture = () => {
    /* console.log("haber defaultPreview =>", this.state.defaultPreview); */
    if (this.state.defaultPreview === null) {
      alert("Seleccione primero una foto de perfil antes de guardar");

      return false;
    }
    return true;
  };

  onClicked = async (e) => {
    e.preventDefault();

    if (this.validation()) {
      let flag = false;
      let data = {};
      let password = "";
      let newpassword = "";

      password = this.state.password;
      newpassword = this.state.newpassword;

      data.password = password;
      data.newpassword = newpassword;
      data.repassword = this.state.repassword;
      console.log(data);
      let response = await AuthService.updatePassword(data);

      if (response !== undefined && response !== null) {
        if (response.status === 0) {
          alert(response.description);
        } else {
          alert("Su contraseña ha sido modificada.");
          flag = true;
        }
      } else {
        alert("Ocurrió un error, inténtelo más tarde.");
      }
      if (flag) {
        window.location.reload();
      }
    }
  };

  onSave = async (e) => {
    if (this.validatePicture()) {
      console.log("haber defaultPreview", this.state.defaultPreview);
      var base64result = this.state.defaultPreview.split(",")[1];
      console.log("haber base64result", base64result);
      let flag = false;

      let data = {};
      let idUser = "";
      let picture = "";

      idUser = this.state.id;
      picture = base64result;

      data.IdRefeferenciaClass = idUser;
      data.Base64 = picture;
      console.log("haber data =>", data);
      //console.log("haber picture", picture);

      let response = await UserService.updateProfilePicture(data);

      if (response === undefined) {
        alert("Ocurrió un problema. Inténtelo más tarde.");
      } else {
        if (response.status === 0) {
          alert(
            "Ocurrió un error al momento de subir la foto de perfil. "
          );
        } else {
          flag = true;

          alert("Imagen actualizada con éxito.");
        }
      }

      if (flag) {
        window.location.reload();
      }
    }
  };

  onCropDefault(preview) {
    this.setState({ defaultPreview: preview });
  }

  onCrop(preview) {
    this.setState({ preview });
  }

  onCloseDefault() {
    this.setState({ defaultPreview: null });
  }

  onClose() {
    this.setState({ preview: null });
  }
  ////////////////////////////////////////////////////////////////
  onBeforeFileLoad(elem) {
    console.log("CARGASTE IMAGEN //////////////////");

    if (elem.target.files[0].size > 100000000) {
      alert("Este archivo es demasiado pesado!");
      elem.target.value = "";
    }
    {
      const file = elem.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        // return reader.result.toString();
        /* this.setState({
          imagenEnBase64: reader.result.toString(),
        }); */
        this.setState({
          defaultPreview: reader.result.toString(),
        });
      };
      reader.readAsDataURL(file);
    }
  }

  showPassword = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  showNewPassword = () => {
    this.setState({
      isShowNew: !this.state.isShowNew,
    });
  };
  showRewPassword = () => {
    this.setState({
      isShowRew: !this.state.isShowRew,
    });
  };

  /**
   * update image
   */
  onFileChange = (event) => {
    let image = event.target.files[0];

    if (image !== undefined) {
      this.getBase64(image, (result) => {
        this.setState({
          imgFlyer: result.split(",")[1],
        });
      });
    } else {
      this.setState({
        imgFlyer: this.state.imgFlyerPre,
      });
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      //console.log('Error: ', error);
    };
  }

  // Save img flyer
  saveFlyer = async () => {
    if (this.state.imgFlyer.length > 0) {
      // && this.state.imgFlyer != this.state.imgFlyerPre) {
      let data = {
        IdRefeferenciaClass: Number(AuthService.getCurrentIdUser()),
        Base64: this.state.imgFlyer,
      };

      let response = await FlyerService.saveFlyer(data);
      if (response !== undefined) {
        if (response.status === 1) {
          this.setState({
            imgFlyerPre: this.state.imgFlyer,
            messageFlyer: "Su imagen se ha guardado correctamente.",
          });
        } else {
          alert(`Error: \n Detalle: ${response.description}`);
        }
      } else {
        alert("Ocurrió un error, Inténtelo más tarde.");
      }
    } else {
      alert("Seleccione una imagen.");
    }
  };
  UpdateLoginName = () => {
    // regex de letras numeros y caracteres especiales
    const regex = /^[a-zA-Z0-9_]*$/;
    const data = {
      id: this.state.id,
      newLoginName: this.state.user.loginname,
    };
    if (regex.test(data.newLoginName)) {
      alert(
        "El nombre de usuario debe contener solo letras y números y caracteres especiales [@,_,-]"
      );
      return;
    }
    if (data.newLoginName.length === 10) {
      const res = UserService.ChangeLoginName(data);
      res.then(async (response) => {
        if (response.status === 1) {
          alert(
            response.objModel === -1
              ? "El nombre de usuario se ha actualizado correctamente."
              : response.objModel
          );
          await AuthService.getUserInformation(data.id);
          window.location.reload();
        }
      });
    } else {
      alert("El nombre de usuario debe contener 10 digitos.");
      return;
    }
    // que solo contenga letras y numeros y caracteres especiales [@,_,-]
  };

  render() {
    const { user, loaded, sponsorNames, infoFlyer } = this.state;
    const fontSize = this.props.fontSize;
    return (
      <div>
        <div class="form-style" style={{ margin: "0 auto" }}>
          <Form>
            <Form.Group>
              <Container>
                <Form.Group>
                  <h4>Cambio de Contraseña</h4>
                </Form.Group>

                <Row className="mb-2">
                  <Col sm={4} className="tw-flex">
                    {/*  <Form.Group>
                      <Form.Label>Inicio de sesion: </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="loginName"
                        defaultValue={user.loginname}
                        onChange={(e) => this.handleChangeLoginName(e)}
                      />
                    </Form.Group> */}
                    {/* <Button
                      style={{
                        fontSize: "0.8rem",
                        height: "2rem",
                        marginTop: "1.85rem",
                        marginLeft: "0.3rem",
                      }}
                      onClick={this.UpdateLoginName}
                    >
                      Cambiar
                    </Button> */}
                  </Col>
                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label>Nombre de usuario:</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Usuario"
                        defaultValue={user.username}
                        onChange={(e) => this.handleChange(e, "username")}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form>
                      <Form.Group>
                        <Form.Label>Patrocinador</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Patrocinador"
                          defaultValue={sponsorNames}
                          onChange={(e) => this.handleChange(e, "patrocinador")}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Form controlid="validationPassword">
                      <Form.Group>
                        <Form.Label>Ingrese su contraseña anterior</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            required
                            type={this.state.isShow ? "text" : "password"}
                            placeholder="Contraseña anterior"
                            onChange={(e) => this.handleChange(e, "password")}
                          ></Form.Control>

                          <InputGroup.Text onClick={(e) => this.showPassword()}>
                            {this.state.isShow && <FaEyeSlash></FaEyeSlash>}
                            {!this.state.isShow && <FaEye></FaEye>}
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Label className="textAlert">
                          {this.state.message}
                        </Form.Label>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col sm={4}>
                    <Form>
                      <Form.Group>
                        <Form.Label>Ingrese su nueva contraseña</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            required
                            type={this.state.isShowNew ? "text" : "password"}
                            placeholder="Nueva contraseña"
                            onChange={(e) =>
                              this.handleChange(e, "newpassword")
                            }
                          ></Form.Control>

                          <InputGroup.Text
                            onClick={(e) => this.showNewPassword()}
                          >
                            {this.state.isShowNew && <FaEyeSlash></FaEyeSlash>}
                            {!this.state.isShowNew && <FaEye></FaEye>}
                          </InputGroup.Text>
                        </InputGroup>

                        <Form.Label className="textAlert">
                          {this.state.newMessage}
                        </Form.Label>
                      </Form.Group>
                    </Form>
                  </Col>

                  <Col sm={4}>
                    <Form>
                      <Form.Group>
                        <Form.Label>Repetir su nueva contraseña</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            required
                            type={this.state.isShowRew ? "text" : "password"}
                            placeholder="Repetir contraseña"
                            onChange={(e) => this.handleChange(e, "repassword")}
                            isValid={this.state.isSame}
                          ></Form.Control>

                          <InputGroup.Text
                            onClick={(e) => this.showRewPassword()}
                          >
                            {this.state.isShowRew && <FaEyeSlash></FaEyeSlash>}
                            {!this.state.isShowRew && <FaEye></FaEye>}
                          </InputGroup.Text>
                        </InputGroup>

                        <Form.Label className="textAlert">
                          {this.state.messageAlert}
                        </Form.Label>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                <Form.Group>
                  <Container>
                    <Row>
                      <Col sm={12} style={{ textAlign: "center" }}>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={(e) => this.onClicked(e)}
                        >
                          Guardar
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Form.Group>
                <Form.Group style={{ paddingTop: "40px" }}>
                  <h4>Foto de Perfil</h4>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <Col sm={1} />

                    <Col sm={5} style={{ textAlign: "center" }}>
                      <h5 style={{ textAlign: "left" }}>
                        Presione el recuadro y eliga una foto
                      </h5>
                      <Avatar
                        width={200}
                        height={200}
                        onCrop={this.onCropDefault}
                        onClose={this.onCloseDefault}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                      />
                    </Col>

                    <Col sm={4} style={{ textAlign: "right" }}>
                      <h5>Vista Actual de la foto</h5>
                      <img
                        alt=""
                        style={{
                          width: "200px",
                          height: "200px",
                          borderRadius: "50%",
                        }}
                        src={this.state.defaultPreview}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <br></br>
                <br></br>

                <Row className="row justify-content-between">
                  <Col style={{ textAlign: "center" }}>
                    <Button variant="primary" size="sm" onClick={this.onSave}>
                      Actualizar foto de perfil
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form.Group>

            <div style={{ paddingTop: "40px" }}>
              <Container>
                <Form.Group>
                  <h4>Actualizar foto para Flyer</h4>
                </Form.Group>
                <Form.Group>
                  {/* FOTO DNI */}
                  {/* <PhotoDni /> */}
                  <Row style={{ paddingTop: "12px", textAlign: "left" }}>
                    <Col sm={12}>
                      <Form.Label>{`NOTA:`}</Form.Label>
                    </Col>
                  </Row>
                  <Row style={{ textAlign: "left" }}>
                    <Col sm={12}>
                      <Form.Label>{`- Esta imagen sera utilizada para los generar los flyer cuando usted obtenga un nivel. Asegurese de subir la imagen correcta.`}</Form.Label>
                    </Col>
                  </Row>
                  <Row style={{ textAlign: "left" }}>
                    <Col sm={12}>
                      <Form.Label>{`- Suba una foto de frente, no de perfil.`}</Form.Label>
                    </Col>
                  </Row>
                  <Row style={{ textAlign: "left" }}>
                    <Col sm={12}>
                      <Form.Label>{`- La foto debe ser INDIVIDUAL, solo usted.`}</Form.Label>
                    </Col>
                  </Row>
                  <Row style={{ textAlign: "left" }}>
                    <Col sm={12}>
                      <Form.Label>{`- La foto debe ser a partir del altura del tórax medio. (NO una foto de cuerpo completo)`}</Form.Label>
                    </Col>
                  </Row>
                  <Row
                    style={{ textAlign: "left", color: "red", fontSize: 14 }}
                  >
                    <Col sm={12}>
                      <Form.Label>{`* Si la foto NO cumple con las indicaciones anteriores será RECHAZADA.`}</Form.Label>
                    </Col>
                  </Row>
                  <Row
                    style={{ textAlign: "left", color: "red", fontSize: 14 }}
                  >
                    <Col sm={12}>
                      <Form.Label>{`* Máximo podrá actualizar su FOTO dos (2) veces. Luego no se le permitira actulizar su foto.`}</Form.Label>
                    </Col>
                  </Row>
                </Form.Group>

                <hr />
                {!this.stateisAvailable && (
                  <div style={{ fontSize: 15 }}>
                    <Form.Label>{this.state.isAvailableMessage}</Form.Label>
                  </div>
                )}
                {this.state.isAvailable && this.state.loadingFlyer && (
                  <div>
                    <Spinner animation="border" variant="dark"></Spinner>
                    <p>Cargando imagen...</p>
                  </div>
                )}
                {this.state.isAvailable &&
                  !this.state.loadingFlyer &&
                  infoFlyer === undefined && (
                    <div>
                      <Form.Label>
                        Ocurrió un error al cargar información de flyer.
                      </Form.Label>
                    </div>
                  )}
                {this.state.isAvailable &&
                  !this.state.loadingFlyer &&
                  infoFlyer && (
                    <>
                      <Form.Group>
                        <Row style={{ textAlign: "left" }}>
                          <Col sm={12}>
                            <Form.Label>{`Fecha de subida de foto: ${Validation.convertDateToString(
                              infoFlyer.editingRequestDate
                            )}`}</Form.Label>
                          </Col>
                        </Row>
                        <Row style={{ textAlign: "left" }}>
                          <Col sm={12}>
                            <Form.Label>{`Numero de veces que ha subido una foto: ${infoFlyer.numberFlyerUpload}`}</Form.Label>
                          </Col>
                        </Row>
                        <Row style={{ textAlign: "left" }}>
                          <Col sm={12}>
                            <Form.Label>{`Estado: ${infoFlyer.nameEditingStatus}`}</Form.Label>
                          </Col>
                        </Row>
                        <Row style={{ textAlign: "left" }}>
                          <Col sm={12}>
                            <Form.Label>{`Observación: ${infoFlyer.observation}`}</Form.Label>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group>
                        <Row>
                          <Col>
                            {/* <Form.Label>{`Usted ha cambiado su foto ${this.state.countFlyer} veces.`} </Form.Label> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Row>
                              <Col sm={12}>
                                <Form.Label style={{ fontSize: 14 }}>
                                  Foto original
                                </Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <img
                                  alt=""
                                  style={{ width: "250px", height: "250px" }}
                                  src={`data:image/png;base64,${this.state.imgFlyer}`}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <Form.Text
                                  style={{ color: this.state.colorFlyer }}
                                >
                                  {this.state.messageFlyer}
                                </Form.Text>
                              </Col>
                            </Row>
                            <Row style={{ paddingTop: "10px" }}>
                              <Col sm={12}>
                                <input
                                  type="file"
                                  accept="image/x-png,image/jpeg"
                                  onChange={(e) => this.onFileChange(e)}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <Button
                                  className="mt-4"
                                  size="sm"
                                  onClick={(e) => this.saveFlyer()}
                                >
                                  Guardar
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm={6}>
                            <Row>
                              <Col sm={12}>
                                <Form.Label style={{ fontSize: 14 }}>
                                  Foto editada
                                </Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <img
                                  alt=""
                                  style={{ width: "250px", height: "250px" }}
                                  src={`data:image/png;base64,${
                                    infoFlyer.pictureEditedFlyer
                                      ? infoFlyer.pictureEditedFlyer.base64
                                      : ""
                                  }`}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form.Group>
                    </>
                  )}
              </Container>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
