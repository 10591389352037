import React, { Component } from 'react';

import logo from '../../images/home/logowhite.png'

import history from '../../views/navigation/history';

export default class FooterBanner extends Component {


    OnClicked = (e, path) => {
        history.push(path);
    
    }

    render(){
        return(
            <section id="footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <p className="cta-text">Empoderar a nuestros miembros para ser líderes en la industria de redes en línea.</p>
                            <h3 className="cta-title">Dirige tu negocio sin limitaciones</h3>
                            <p className="cta-text">Los miembros son recompensados por el éxito de los conceptos internos y externos gracias a la masa de los usuarios.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 ">
                            <img src={logo} width="200"></img>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}