import { Calendar, globalizeLocalizer } from "react-big-calendar";
import globalize from "globalize";
import { useEffect, useState } from "react";
import "./MonthEvent.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { OverlayTrigger, Popover } from "react-bootstrap";

require("globalize/lib/cultures/globalize.culture.es");

export default function MonthEvent(props) {
  const [myEventsList, setmyEventsList] = useState([]);
  const [isloadedapidata, setisloadedapidata] = useState(true);
  const localizer = globalizeLocalizer(globalize);
  const listofmonths = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const enterMeeting = (item) => {
    if (item.showpopover === false) {
      if (item.recordedLink !== null) window.open(item.recordedLink);
      else {
        const urlapi = item.subMeetingType.landing.url;
        window.location = urlapi.split("/")[3] + "/" + urlapi.split("/")[4];
        sessionStorage.setItem("idmeeting", item.id);
      }
    } else {
      alert("Esta reunión no está habilitada. Revise su horario");
    }
  };

  const CustomEvent = (propsEvent) => {
    const popover = (
      <Popover
        id="popover-basic"
        onClick={() => enterMeeting(propsEvent.event)}
        style={{ cursor: "pointer" }}
      >
        <Popover.Header as="h3">{propsEvent.title}</Popover.Header>
        <Popover.Body className="alignpopovercontainer">
          <div>Presentador:{" " + propsEvent.event.speaker}</div>
          <div>
            <p className="tw-m-0">
              Inicio: {propsEvent.event.startDatePeru} <span>Perú </span>
              <img
                src={`https://flagcdn.com/16x12/pe.png`}
                srcSet={`https://flagcdn.com/32x24/pe.png 2x, https://flagcdn.com/48x36/pe.png 3x`}
                width="16"
                height="12"
                alt="bandera"
              />
            </p>

            <p className="tw-m-0">
              Fin: {propsEvent.event.endDatePeru} <span>Perú </span>
              <img
                src={`https://flagcdn.com/16x12/pe.png`}
                srcSet={`https://flagcdn.com/32x24/pe.png 2x, https://flagcdn.com/48x36/pe.png 3x`}
                width="16"
                height="12"
                alt="bandera"
              />
            </p>
          </div>

          {props.ipInfo?.country !== "PE" && (
            <div>
              <p className="tw-m-0">
                Inicio: {propsEvent.event.startDateLocale}{" "}
                <span>
                  {props.ipInfo?.country}/{props.ipInfo?.city}{" "}
                </span>
                <img
                  src={`https://flagcdn.com/16x12/${props.ipInfo?.country.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/32x24/${props.ipInfo?.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${props.ipInfo?.country.toLowerCase()}.png 3x`}
                  width="16"
                  height="12"
                  alt="bandera"
                />
              </p>

              <p className="tw-m-0">
                Fin: {propsEvent.event.endDateLocale}{" "}
                <span>
                  {props.ipInfo?.country}/{props.ipInfo?.city}{" "}
                </span>
                <img
                  src={`https://flagcdn.com/16x12/${props.ipInfo?.country.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/32x24/${props.ipInfo?.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${props.ipInfo?.country.toLowerCase()}.png 3x`}
                  width="16"
                  height="12"
                  alt="bandera"
                />
              </p>
            </div>
          )}
        </Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose
        overlay={popover}
      >
        <div>{propsEvent.title}</div>
      </OverlayTrigger>
    );
  };

  const CustomToolbar = (props) => {
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={() => {
              props.onNavigate("PREV");
              setisloadedapidata(false);
            }}
          >
            {"<"}
          </button>
          <button
            type="button"
            onClick={() => {
              props.onNavigate("TODAY");
              setisloadedapidata(false);
            }}
          >
            Hoy
          </button>
          <button
            type="button"
            onClick={() => {
              props.onNavigate("NEXT");
              setisloadedapidata(false);
            }}
          >
            {">"}
          </button>
        </span>
        <span className="rbc-toolbar-label">{props.label}</span>
      </div>
    );
  };

  useEffect(() => {
    setmyEventsList(props.data);
  }, [props.data]);

  useEffect(() => {
    if (!isloadedapidata) {
      const htmlelement = document.querySelector(".rbc-toolbar-label");
      const textmonthselected = htmlelement.textContent.split(" ")[0];
      const monthselected =
        listofmonths.findIndex((month) => month === textmonthselected) + 1;
      const yearselected = Number(htmlelement.textContent.split(" ")[2]);

      Promise.all([
        fetch(
          `${props.API_URL}meetings/year/${yearselected}/month/${monthselected}`
        ).then((res) => res.json()),
        fetch(`${props.API_URL}meetings/current`).then((res) => res.json()),
      ])
        .then(([result1, result2]) => {
          setmyEventsList(
            props.getdatamodifiedfromfetch(result1.objModel, result2)
          );
          setisloadedapidata(true);
        })
        .catch(() => {
          setmyEventsList([]);
          setisloadedapidata(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isloadedapidata]);

  return (
    <div className="py-3">
      {!isloadedapidata && (
        <div
          className="spinner-border text-warning spinner-calendar-month"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      <Calendar
        localizer={localizer}
        events={myEventsList}
        onSelectEvent={(event) => console.log(event)}
        startAccessor="startDate"
        endAccessor="endDate"
        views={{ month: true }}
        style={{ height: 500 }}
        popup
        culture="es"
        components={{
          event: CustomEvent,
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
