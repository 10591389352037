import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { Button, Col, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import RegisterMain from "../../components/register/RegisterMain";
import UserService from "../../services/user.service";
import utilsService from "../../services/utils.service";
import RegisterEndView from "./RegisterEndView";

//const linkMail = `${process.env.REACT_APP_APP_URL}/register-payment`;
const salert = (title = "", icon = "", time = 1600, showloading = true) => {
  let timer;
  time === "infinito" ? (timer = undefined) : (timer = time);
  Swal.fire({
    icon: icon,
    title: title,
    timer: timer,
    showConfirmButton: false,
    allowOutsideClick: false,
    didOpen: () => {
      if (showloading === true) {
        Swal.showLoading();
      } else {
        Swal.hideLoading();
      }
    },
  });
};
export default class RegisterMainView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWallet: props.showWallet,
      idSocio: props.idSocio,
      reload: props.reload,
      user: {
        name: "",
        lastname: "",
        birthdate: "",
        gender: "",
        idNationality: -1,
        nroDocument: "",
        civilState: "",
        email: "",
        idResidenceCountry: -1,
        idDocumentTypeCountry: -1,
        desDocument: "",
        districtAddress: "",
        address: "",
        phone: "",
        username: "",
        password: "",
        coafiliate: false,
        coname: "",
        colastname: "",
        cobirthdate: "",
        conroDocument: "",
        codesDocument: "",
        coidDocumentTypeCountry: -1,
        packages: [],
        kitInit: [],
        bonos: [],
      },
      tempuser: {},
      displayFooter: "none",
      currentTab: "",
      isComplete: false,
      payment: {
        scheduleJSON: "",
        idMethodPayment: 0,
        mountPaid: 0,
        isPaid: false,
        typePay: 0,
        quotesPay: 0,
        vouchers: [],
        amountPay: 0,
        totalInitial: 1,
      },
      voucher: {
        bank: "",
        isPaid: false,
      },
      observerPaypal: {
        isPaid: undefined,
        mountPaid: undefined,
        creationDate: undefined,
        modificationDate: undefined,
        idTransaction: undefined,
        intent: undefined,
        status: undefined,
      },
      amountWallet: 0,
      emailCC: "",
      typeExchange: "",
      promotionalCode: "",
      flagresetamountwallet: false,
    };

    // Create reference to Paypal
    this.childPaypal = React.createRef();
  }

  eventhandler = (data, field) => {
    // this.state.tempuser = data;
    console.log('dsds aqui ', data, field )
    var temp = this.state.user;
    temp[field] = data;
    this.setState({ user: temp });
  };
  /**
   * Event to handle if the footer is shown or hidden
   * @param {*} value
   * @param {*} field
   */
  eventFooter = (value, field) => {
    this.setState({ [field]: value });
  };

  /**
   * Validate form for step 1, step  and step 3
   */
  eventValidate = () => {
    // Step 1
    if (this.state.user.name.length === 0) {
      alert("Ingrese sus nombres.");
      return false;
    }
    if (this.state.user.lastname.length === 0) {
      alert("Ingrese sus apellidos.");
      return false;
    }
    if (this.state.user.birthdate.length === 0) {
      alert("Ingrese su fecha de nacimiento");
      return false;
    }
    if (this.state.user.gender.length === 0) {
      alert("Seleccione su sexo.");
      return false;
    }
    if (this.state.user.idNationality <= 0) {
      alert("Seleccione su nacionalidad.");
      return false;
    }

    if (this.state.user.idDocumentTypeCountry <= 0) {
      alert("Seleccione un tipo de documento.");
      return false;
    }
    if (this.state.user.nroDocument.length === 0) {
      alert("Ingrese su número de documento.");
      return false;
    }
    if (this.state.user.civilState.length === 0) {
      alert("Seleccione su estado civil.");
      return false;
    }

    if (this.state.user.coafiliate) {
      if (this.state.user.coname.length === 0) {
        alert("Ingrese los nombres de su coafiliado.");
        return false;
      }
      if (this.state.user.colastname.length === 0) {
        alert("Ingrese los apellidos de su coafiliado.");
        return false;
      }
      if (this.state.user.coidDocumentTypeCountry <= 0) {
        alert("Seleccione el tipo de documento de su coafiliado.");
        return false;
      }
      if (this.state.user.conroDocument.length === 0) {
        alert("Ingrese el numero de documento de su coafiliado.");
        return false;
      }
      if (this.state.user.cobirthdate.length === 0) {
        alert("Ingrese la fecha de nacimiento  de su coafiliado.");
        return false;
      }
    }

    //Step 2
    if (this.state.user.email.length === 0) {
      alert("Ingrese su correo electrónico.");
      return false;
    }
    if (this.state.user.idResidenceCountry <= 0) {
      alert("Seleccione su país de residencia.");
      return false;
    }
    if (this.state.user.districtAddress.length === 0) {
      alert("Ingrese su ciudad.");
      return false;
    }
    if (this.state.user.address.length === 0) {
      alert("Ingrese su dirección.");
      return false;
    }
    if (this.state.user.phone.length === 0) {
      alert("Ingrese su número de celular.");
      return false;
    }

    // Step 3
    if (this.state.user.packages.length === 0) {
      alert("Selecione un paquete.");
      return false;
    }
    

    return true;
  };

  eventText = (value, field) => {
    this.setState({ [field]: value });
  };
  /**
   * To handle the event  on payment step
   */
  eventPayment = (value, field) => {
    var temp = this.state.payment;
    temp[field] = value;
    this.setState({ payment: temp });

    // TODO change by object verify if the field of paypal are filled
    if (field === "isPaid") {
      temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "mountPaid") {
      temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "creationDate") {
      temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "modificationDate") {
      temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "idTransaction") {
      temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "intent") {
      temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "status") {
      temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }

    //verify if all field are not undefined
    this.verifyObjetPaypal(this.state.observerPaypal);
  };

  /** verify observer paypal */
  verifyObjetPaypal = (obj) => {
    if (
      obj.isPaid !== undefined &&
      obj.mountPaid !== undefined &&
      obj.creationDate !== undefined &&
      obj.modificationDate !== undefined &&
      obj.idTransaction !== undefined &&
      obj.intent !== undefined &&
      obj.status !== undefined
    ) {
      if (obj.isPaid) {
        // save paypal and complete payment
        this.sendData();
      }
    } else {
    }
  };

  eventVoucher = (value, field) => {
    var temp = this.state.voucher;
    temp[field] = value;
    this.setState({ voucher: temp });
  };
  componentDidMount() {
    this.getExchange();
  }
  async getExchange() {
    let response = await utilsService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: response.objModel,
        });
      }
    }
  }
  validation = () => {
    console.log("entraste a validacion");
    if (this.state.user.name.length === 0) {
      alert("Ingrese sus nombres.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.lastname.length === 0) {
      alert("Ingrese sus apellidos.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.birthdate.length === 0) {
      alert("Ingrese su fecha de nacimiento");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.gender.length === 0) {
      alert("Seleccione su sexo.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.idNationality <= 0) {
      alert("Seleccione su nacionalidad.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }

    if (this.state.user.idDocumentTypeCountry <= 0) {
      alert("Seleccione un tipo de documento.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.nroDocument.length === 0) {
      alert("Ingrese su número de documento.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.civilState.length === 0) {
      alert("Seleccione su estado civil.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.email.length === 0) {
      alert("Ingrese su correo electrónico.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.idResidenceCountry <= 0) {
      alert("Seleccione su país de residencia.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.districtAddress.length === 0) {
      alert("Ingrese su ciudad.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.address.length === 0) {
      alert("Ingrese su dirección.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.phone.length === 0) {
      alert("Ingrese su número de celular.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.packages.length === 0) {
      alert("Selecione un paquete.");
      this.setState({
        currentTab: "Step3",
      });
      return false;
    }
    if (this.state.user.coafiliate) {
      if (this.state.user.coname.length === 0) {
        alert("Ingrese los nombres de su coafiliado.");
        this.setState({
          currentTab: "Step1",
        });
        return false;
      }
      if (this.state.user.colastname.length === 0) {
        alert("Ingrese los apellidos de su coafiliado.");
        this.setState({
          currentTab: "Step1",
        });
        return false;
      }
      if (this.state.user.coidDocumentTypeCountry <= 0) {
        alert("Seleccione el tipo de documento de su coafiliado.");
        this.setState({
          currentTab: "Step1",
        });
        return false;
      }
      if (this.state.user.conroDocument.length === 0) {
        alert("Ingrese el numero de documento de su coafiliado.");
        this.setState({
          currentTab: "Step1",
        });
        return false;
      }
      if (this.state.user.cobirthdate.length === 0) {
        alert("Ingrese la fecha de nacimiento  de su coafiliado.");
        this.setState({
          currentTab: "Step1",
        });
        return false;
      }
    }

    if (this.state.payment.typePay === 1) {
      if (!this.state.payment.isPaid) {
        if (
          !(
            this.state.payment.vouchers.length > 0 ||
            this.state.amountWallet > 0
          )
        ) {
          alert("Tiene que registrar el pago de su membresía.");
          return false;
        }
      }
    } else if (this.state.payment.typePay === 0) {
      alert("Seleccione un método de pago.");
      return false;
    }
    let vouchersUpload = this.state.payment.vouchers;
    let cambio = Number(this.state.typeExchange.venta);
    let totalamount = Number(0);
    let amountpay = Number(this.state.payment.amountPay);
    let comision = Number(0);

    if (vouchersUpload.length > 0) {
      for (let i = 0; i < vouchersUpload.length; i++) {
        // Split base64 code type and string
        let moneda = Number(vouchersUpload[i].typeExchange);
        let cantidad = Number(vouchersUpload[i].amount);

        if (moneda === 1) {
          //dolar
          totalamount += cantidad;
          comision = comision + Number(vouchersUpload[i].comision); //comision dolares
        } else {
          //moneda=2 sol
          cantidad /= cambio; //convertir sol a dolar
          totalamount += cantidad;
          comision =
            comision +
            Number(Number(vouchersUpload[i].comisionExchange) / cambio); //comision soles
        }
      }
      let montowallet = Number(this.state.amountWallet);
      if (montowallet !== undefined) {
        totalamount += montowallet;
      }
      //agregando comision al monto total

      amountpay += comision;
      totalamount = Number(totalamount);
      console.log(totalamount, comision, amountpay);
      if (comision > 0) {
        if (totalamount + comision < amountpay || totalamount + comision > amountpay  ) {
          alert("El monto total ingresado no coincide con el monto a pagar");
          return false;
        }

        /*if (totalamount < amountpay - 1 || totalamount > amountpay + 3) {
          alert("El monto total ingresado no coincide con el monto a pagar");
          return false;
        }*/
      }
    }

    return true;
  };

  // event wallet part of payment
  eventWallet = (amount) => {
    this.setState({
      amountWallet: (this.state.amountWallet = amount),
    });
  };

  // Register data
  sendData = async () => {
    // e.preventDefault();
    console.log("hiciste click en registrar para registrar usuario");
    localStorage.removeItem("idPais")
    if (this.validation()) {
      salert("Registrando usuario...", "info", "infinito", true);
      let data = {};
      let user = {};

      user.name = this.state.user.name;
      user.lastname = this.state.user.lastname;
      user.birthdate = this.state.user.birthdate;
      user.gender = this.state.user.gender;
      user.idNationality = Number(this.state.user.idNationality);
      user.idDocument = Number(this.state.user.idDocumentTypeCountry);
      if (this.state.user.desDocument.length > 0) {
        user.otherDocument = this.state.user.desDocument;
        user.nroDocument = this.state.user.nroDocument;
      } else {
        user.nroDocument = this.state.user.nroDocument;
      }
      user.civilState = this.state.user.civilState;
      user.email = this.state.user.email;
      user.idResidenceCountry = Number(this.state.user.idResidenceCountry);
      user.districtAddress = this.state.user.districtAddress;
      user.address = this.state.user.address;
      user.nroTelf = this.state.user.codephone + " " + this.state.user.phone;
      user.username = "";
      user.password = "";
      user.boolDelete = 0;
      let date = new Date();
      user.createDate = date;
      let suscription = {};
      suscription.idUser = this.state.idSocio;
      suscription.idPackage = Number(this.state.user.packages[0].id);
      suscription.PackageDetailId = Number(
        this.state.user.packages[0].listPackageDetails[0].idPackageDetail
      );
      //suscription.PackageDetailId=Number(this.state.packages[0].listPackageDetails[0].idPackageDetail)
      // let datString = "2020-07-14T00:00:00";// date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay();
      suscription.creationDate = date;
      suscription.observation = "";
      suscription.status = 0;
      suscription.boolMigration = 0;
      data.user = user;
      data.suscription = suscription;
      data.promotionalCode = {};
      let coafiliate = {};
      // Coafiliate
      if (this.state.user.coafiliate) {
        coafiliate.name = this.state.user.coname;
        coafiliate.lastname = this.state.user.colastname;
        coafiliate.idDocument = Number(this.state.user.coidDocumentTypeCountry);
        coafiliate.birthdate = this.state.user.cobirthdate;
        if (this.state.user.codesDocument.length > 0) {
          coafiliate.otherDocument = this.state.user.codesDocument;
          coafiliate.nroDocument = this.state.user.conroDocument;
        } else {
          coafiliate.nroDocument = this.state.user.conroDocument;
        }
        coafiliate.idUser = 1;
        data.CoAffiliate = coafiliate;
      }

      // Type method to pay
      let idTypeMethod = 0;
      let MeansPayment = {};
      /*promotional code */
      if (this.state.promotionalCode !== "") {
        data.promotionalCode = {
          code: this.state.promotionalCode,
        };
      } else {
        data.promotionalCode = {};
      }
      if (this.state.payment.typePay === 1) {
        // Type payment
        // wallet, mixto Y otros -> services v2
        // Vouchers and wallet -> services v1
        let payment = {};

        // was with vouchers
        if (!this.state.payment.isPaid) {
          payment.scheduleJSON = "";
          payment.idMethodPayment = 1; // TODO what are method of payments preguntar
          payment.creationDate = new Date();
          payment.modificationDate = new Date();
          payment.idSuscription = suscription.idPackage;

          let voucher = {};
          let userVoucher = {
            name: this.state.user.name,
            lastname: this.state.user.lastname,
            nroDocument: this.state.user.nroDocument,
          };
          voucher.User = userVoucher;
          voucher.Caption = "";

          //add vouchers => bytesMap: "" nroOperation":"20554545","PayType" : "2"
          let vouchers = [];
          // Vouchers
          let vouchersUpload = this.state.payment.vouchers;
          if (vouchersUpload.length > 0) {
            idTypeMethod = 2;
            for (let i = 0; i < vouchersUpload.length; i++) {
              // Split base64 code type and string
              let contenType = vouchersUpload[i].voucherBase.split(",");

              let temp = {
                MethodSubTipoPagoId: Number(vouchersUpload[i].type),
                OperationNumber: vouchersUpload[i].code,
                CompanyOperationNumber: vouchersUpload[i].code,
                TotalAmount: Number(vouchersUpload[i].amount),
                PaymentCoinCurrencyId: Number(vouchersUpload[i].typeExchange),
                Note: vouchersUpload[i].nota,
                Base64: contenType[1],
                /*NroOperacion: vouchersUpload[i].code,
                IdPayMethod: Number(vouchersUpload[i].type),
                Base64: contenType[1],*/
              };
              vouchers.push(temp);
            }

            // voucher.Base64Images = vouchers;
            MeansPayment.Vouchers = vouchers;
            MeansPayment.NotePay = vouchers
              .map((item) => item.NotePay)
              .join(", ");
            MeansPayment.AmountPaidPayment = this.state.payment.amountPay; //this.state.payment.mountPaid;
            MeansPayment.TypeMethodPayment = 2;

            // TODO remove information
            MeansPayment.PaypalDTO = {
              nroOperation: "",
            };

            data.link = "";
            // TODO not neccesary to save data but it is necesary for services  work
            MeansPayment.WalletTransaction = {
              Amount: 0,
              ReferenceData: "",
            };
            // if (this.state.payment.ispalcontado){
            //   MeansPayment.Iscontado=1
            // }
            // else{
            //   MeansPayment.Iscontado=0
            // }
          }

          // Verify payment with wallet
          if (this.state.amountWallet > 0) {
            let walletItem = {
              Amount: this.state.amountWallet,
              referencedata:
                "Pago de registro: " +
                user.name.toUpperCase() +
                " " +
                user.lastname.toUpperCase(),
            };

            MeansPayment.WalletTransaction = walletItem;

            // -> wallet and vouchers
            // Wallet = 3,  Mixto = 4,
            if (vouchersUpload.length > 0) {
              idTypeMethod = 4;
            } else {
              idTypeMethod = 3;
            }
          }
        } else {
          // was by paypal
          // Close modal of paypal modal
          this.childPaypal.current.handleClosePaypal();
          idTypeMethod = 1;
          // Version 2
          MeansPayment.PaypalDTO = {
            nroOperation: this.state.payment.idTransaction,
          };

          MeansPayment.AmountPaidPayment = this.state.payment.mountPaid;

          data.link = "";
          MeansPayment.TypeMethodPayment = 1;

          // TODO not neccesary to save data but it is necesary for services  work
          MeansPayment.WalletTransaction = {
            Amount: 0,
            ReferenceData: "",
          };
          MeansPayment.Vouchers = [];
        }
        MeansPayment.NumberAdvancePaymentPaid = this.state.payment.quotesPay; // numero de quotes
        data.IsPayLater = 0;
      } else {
        // The pay was without payment
        //Version 1
        MeansPayment.PaypalDTO = { nroOperation: "" };
        MeansPayment.NumberPaymentInitials = this.state.payment.totalInitial;
        MeansPayment.Vouchers = [];
        MeansPayment.WalletTransaction = {
          Amount: 0,
          ReferenceData: "",
        };

        MeansPayment.NumberAdvancePaymentPaid = 0;
        MeansPayment.AmountPaidPayment = 0;
        data.IsPayLater = 1;
      }

      // Add user affiliate
      let affiliate = {};
      // affiliate.idSponsor = Number(AuthService.getCurrentIdUser());
      affiliate.idSponsor = this.state.idSocio;
      affiliate.idSon = 0;
      data.affiliate = affiliate;

      let response = undefined;

      if (idTypeMethod === 0) {
        data.EmailRequest = {
          OtroEmail: this.state.emailCC,
        };

        MeansPayment.TypeMethodPayment = 1;
        MeansPayment.NumberPaymentInitials = this.state.payment.totalInitial;

        data.MeansPayment = MeansPayment;
      } else {
        // "wallet, mixto y otros");

        MeansPayment.TypeMethodPayment = idTypeMethod;
        MeansPayment.NumberPaymentInitials = this.state.payment.totalInitial;

        data.MeansPayment = MeansPayment;
        data.EmailRequest = {
          OtroEmail: this.state.emailCC,
        };
      }

      response = await UserService.registerUserv2(data);
      //response=undefined;
      Swal.close();
      this.setState({ flagresetamountwallet: false });
      if (response !== undefined) {
        if (response.status === 1) {
          this.setState({ isComplete: true });
        } else {
          if (
            response.description ===
            "Este socio no puede pagar con wallet ya que no tiene el rango suficiente"
          ) {
            this.setState({
              amountWallet: 0,
              flagresetamountwallet: true,
            });
          }
          salert(
            `Ocurrió un error al momento de registrar.\r\nMensaje: ${response.description}`,
            "error",
            3000,
            false
          );
        }
      } else {
        salert(
          "Tuvimos un problema. Inténtalo más tarde.",
          "error",
          3000,
          false
        );
      }
    }
  };

  render() {
    return (
      // <div className="auth-wrapper">
      <div>
        <Form.Label className="content-title">
          Registro de Nuevo socio
        </Form.Label>

        <div
          className="register-inner"
          style={{ display: this.state.isComplete ? "none" : "block" }}
        >
          <RegisterMain
            idSocio={this.state.idSocio}
            onChange={this.eventhandler}
            eventFooter={this.eventFooter}
            eventValidate={this.eventValidate}
            eventPay={this.eventPayment}
            eventText={this.eventText}
            eventVoucher={this.eventVoucher}
            eventWallet={this.eventWallet}
            packages={this.state.user.packages}
            bonos={this.state.user.bonos}
            kitInit={this.state.user.kitInit}
            currentTab={this.state.currentTab}
            ref={this.childPaypal}
            eventRegister={this.sendData}
            showWallet={this.state.showWallet}
            flagresetamountwallet={this.state.flagresetamountwallet}
          ></RegisterMain>
          <hr></hr>

          <div
            className="row justify-content-between"
            style={{ display: this.state.displayFooter }}
          >
            <Col ms={4}>
              <Button variant="danger">Cancelar</Button>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Button variant="primary" onClick={this.sendData}>
                Registrar
              </Button>
            </Col>
          </div>
        </div>
        <div style={{ display: this.state.isComplete ? "block" : "none" }}>
          <RegisterEndView reload={this.state.reload}></RegisterEndView>
        </div>
      </div>
    );
  }
}
