import React, { Component, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import DatePicker from 'react-bootstrap-date-picker
import UtilService from '../../services/utils.service';
import UserService from '../../services/user.service';
import "./StepOne.css";
const civilStateKey = "Casado"; // casado

// Datos personales
export default class StepOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gender: "",
            idNationality: -1,
            idDocumentType: -1,
            desDocument: "",
            codesDocument: "",
            showOthersC: 'none',
            showOthers: 'none',
            nroDocument : '',
            conroDocument : '',
            civilState : '',
            nationalities: [],
            typeDocuments: [],
            tempDocuments: [],
            showRegister: 'none',
            messageDate: "",
            messageDoc: "",
            messageDocCo: "",
            name: "",
            lastname:"",
            isdonebirthday: false,
            birthdate: "",
        }

        this.getCountries = this.getCountries.bind(this);
        this.getDefaultDocument = this.getDefaultDocument.bind(this);
    }

    componentDidMount() {
        
        this.getDefaultDocument();
        this.getCountries();
    }

    async getDefaultDocument () {
        let response = await UtilService.getTypeDocDefault();
        if(response !== null && response !== undefined){
            if(response.status === 1){
                let data = response.objModel;
                let items = [];
                data.forEach( elem => {
                    items.push(<option key={elem.id} value={elem.id}>{elem.name}</option>);
                        
                });
                
                this.setState({ 
                    tempDocuments: items 
                });
                
            }
        }
    }

    // Get list of countries
    async getCountries() {

        let response = await UtilService.getResidences();

        if(response !== null && response !== undefined){
            
            let  objs = response.objModel
                let residences = [];
                objs.forEach( elem => {
                    residences.push(<option key={elem.idCountry} value={elem.idCountry}>{elem.name}</option>);
                });

                this.setState({ 
                    nationalities: residences
                });
        }

    }


    async createItemTypes() {
        let id = this.state.idNationality;
        if (id > 0) {
            let response = await UtilService.getTypeDocByNat(id);
            if(response !== null && response !== undefined){
                
                let items = [];
                if(response.status === 1 && response.objModel.length > 0){
                    response.objModel.forEach( elem => {
                        
                        items.push(<option key={elem.id} value={elem.id}>{elem.name}</option>);

                    });
                } else {
                    items = this.state.tempDocuments;
                }
                                              
                this.setState({ typeDocuments: items });
            }

        }

    }

    /**
     * Method to handle the change  of properties and send it to parent 
     * @param {*} e 
     * @param {*} field 
     */
    handleChange = (e, field) => {

        let value = e.target.value.trim();
        if (this.props.onChange) {
            this.props.eventStyleValidation(false,field)
            this.props.onChange(value, field);
            this.setState({
                [field]: value,
                messageDoc: ""
            });
        }
        // })
    };

    /**
     * Method to handle the selected item  send it to parent 
     * @param {*} e 
     * @param {*} field 
     */
    handleSelect = (e, field) => {
        
        var value = e.target.value;
        this.setState({ [field]: value }, () => {this.props.onChange(value, field)})
      
        
        if(field === "idNationality") {
            this.setState({
                idNationality: value
            },()=>{
                this.createItemTypes();
                this.props.eventStyleValidation(false,field)
            });
            
        }

        if(field === "idDocumentType") {
            
            let text = e.target.options[e.target.selectedIndex].text.toUpperCase();
            this.props.eventStyleValidation(false,field)
            if(text.includes("OTRO")) {
                this.setState({
                    showOthers: 'inline'
                });
            } else {
                this.setState({
                    showOthers: 'none',
                    desDocument: ""
                });
                if (this.props.onChange) {
                    this.props.onChange("", "desDocument");
                }
            }

        }
    };

    handleCivilState = (e, field) => {
        
        var value = e.target.value;
        this.setState({ [field]: value }, () => {

            this.props.onChange(value, field);
            this.props.eventStyleValidation(false,field);

        });
        if (value === civilStateKey) {
            this.setState({
                showRegister: 'block'
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(true, 'coafiliate');
                }
            });
        } else {
            this.setState({
                showRegister: 'none',
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(false, 'coafiliate');
                }
            });
        }

    };
    /**
     * Method to handle the change  of properties and send it to parent 
     * @param {*} e 
     * @param {*} field 
     */
    handleRadio = (e, field) => {

        this.setState({[field]:e.target.value})
        this.props.onChange(e.target.value, field); 
        this.props.eventStyleValidation(false,field)      
    };

    /**
     * Method to handle the change  of properties and send it to parent 
     * @param {*} e 
     * @param {*} field 
     */
    handledate = (e, field) => {

        this.setState({
            messageDate: "",
            field: e.target.value
        });
        if (this.props.onChange) {
            this.props.eventStyleValidation(false,field)
            this.props.onChange(e.target.value, field);
        }
    };

    onBlurDate = (e, field, fieldMessage) => {
        // Validate date
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        let date = e.target.value;
        let correct = date.match(regEx);
        if (correct) {
            this.setState({[fieldMessage]: "",});
            this.props.eventStyleValidation(false,field);
        } else {
            this.setState({
                [fieldMessage]: "Ingrese una fecha válida."
            });
            if (this.props.onChange) {
                this.props.onChange("", field);
            }
        }

    }

    // Verify nro Document
    onBlurDocument = (e, field, fieldMessage) => {

        let nroDocument = this.state[field];
        if (nroDocument.length > 0) {
            this.verifyDocument(field, fieldMessage);
        }
    }

    async verifyDocument(field, fieldMessage) {

        let data = {};
        data.nroDocument = this.state[field];

        let isRegister = await UserService.isDocumentRegistered(data);

        if (!isRegister) {
            this.setState({ [fieldMessage]: "" });
            this.props.eventStyleValidation(false,field)
        } else {
            this.setState({ [fieldMessage]: "Este documento ya ha sido registrado." });
            this.props.onChange('', field);
            this.props.eventStyleValidation(true,field)
        }
    }

    render() {
        console.log(this.props)
        const { desDocument, codesDocument} = this.state;
        return (
            <section>
                <Form>
                    <Form.Label className="content-subtitle mt-3">Datos personales</Form.Label>

                    <Form.Group controlId='formStepOne'>
                        <Row>
                            <Col sm={6} className="mb-2">
                                <Form.Group >
                                    <Form.Label>Nombres *</Form.Label>
                                    <Form.Control isInvalid={this.props.arraystylevalidation.name} type="text" placeholder="Nombres"
                                        onChange={(e) => this.handleChange(e, "name")} />
                                    <Form.Control.Feedback type="invalid">Ingrese su nombre</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label>Apellidos *</Form.Label>
                                    <Form.Control isInvalid={this.props.arraystylevalidation.lastname} type="text" placeholder="Apellidos"
                                        onChange={(e) => this.handleChange(e, "lastname")} />
                                    <Form.Control.Feedback type="invalid">Ingrese su apellido</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label>Nacionalidad *</Form.Label>
                                    <Form.Select defaultValue={''} size="sm" 
                                        isInvalid={this.props.arraystylevalidation.idNationality}
                                        onChange={(e) => this.handleSelect(e, "idNationality")}>
                                        <option value="" disabled>Seleccione una opción</option>
                                        {this.state.nationalities}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Ingrese su nacionalidad</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label>Tipo de documento *</Form.Label>
                                    <Form.Select defaultValue={''} size="sm" 
                                        isInvalid={this.props.arraystylevalidation.idDocumentType}
                                        onChange={(e) => this.handleSelect(e, "idDocumentType")}>
                                        <option value="" disabled>Seleccione una opción</option>
                                        {this.state.typeDocuments}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Ingrese tipo de documento</Form.Control.Feedback>
                                    <br></br>
                                    <Form.Control style={{display: this.state.showOthers, paddingTop: 6}} type="text" 
                                        isInvalid={this.props.arraystylevalidation.desDocument}
                                        placeholder="Ingrese tipo de documento"
                                        value={desDocument}
                                        onChange={(e) => this.handleChange(e, "desDocument")}>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{this.props.arraystylevalidation.desDocument?"Ingrese tipo de documento":""}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row >
                            <Col sm={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label>Nro. de documento *</Form.Label>
                                    <Form.Control type="text" placeholder="Nro. documento"
                                        isInvalid={this.props.arraystylevalidation.nroDocument}
                                        onChange={(e) => this.handleChange(e, "nroDocument")}
                                        onBlur={(e) => this.onBlurDocument(e, "nroDocument", "messageDoc")}></Form.Control>
                                    <Form.Control.Feedback type="invalid">{this.state.nroDocument===""?
                                    "Ingrese el número de documento":this.state.messageDoc}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label>Fecha de nacimiento *</Form.Label>
                                    <Form.Control type="date"
                                        isInvalid={this.props.arraystylevalidation.birthdate}
                                        onChange={e => this.handledate(e, "birthdate")}
                                        onBlur={e => this.onBlurDate(e, "birthdate", "messageDate")}>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{this.state.birthdate===""?
                                    "Ingrese su fecha de nacimiento":this.state.messageDate}</Form.Control.Feedback>
                                    
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label className="mr-3">Sexo *</Form.Label>
                                    <div  className="mb-3">
                                        <Form.Check type='radio' inline id={`check-api-radio`}>
                                            <Form.Check.Input
                                                type='radio'
                                                name="gender"
                                                value="M"
                                                onChange={(e) => this.handleRadio(e, "gender")}
                                                isInvalid={this.props.arraystylevalidation.gender}
                                                />
                                            
                                            <Form.Check.Label className="ml-3" >
                                                Masculino
                                            </Form.Check.Label>
                                            
                                        </Form.Check>
                                        <Form.Check type='radio' inline id={`check-api-radio1`}>
                                            <Form.Check.Input
                                                type='radio'
                                                name="gender"
                                                value="F"
                                                onChange={(e) => this.handleRadio(e, "gender")}
                                                isInvalid={this.props.arraystylevalidation.gender}
                                                />
                                            
                                            <Form.Check.Label className="ml-3">
                                            Femenino
                                            </Form.Check.Label>
                                            
                                        </Form.Check>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col sm={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label>Estado Civil *</Form.Label>
                                    <Form.Select size="sm" defaultValue="" 
                                        isInvalid={this.props.arraystylevalidation.civilState}
                                        onChange={(e) => this.handleCivilState(e, "civilState")}>
                                        <option value="" disabled>Seleccione una opción</option>
                                        <option value="Soltero" >Soltero</option>
                                        <option value="Casado">Casado</option>
                                        <option value="Viudo">Viudo</option>
                                        <option value="Divorciado">Divorciado</option>
                                        <option value="Convivencia">Convivencia</option>
                                        <option value="Union libre">Union libre</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Ingrese su estado civil</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </section>
        );
    }
}