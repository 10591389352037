import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import './MEKeolaShopOrderDetail.css'
import watsapImg from '../../images/WhatsApp.svg.webp'
// EPS
const urlCodeOrder = "https://api-dev.keola.club:2083/api/Orders/DeliveryConfirmation/1286/1" // 1286
const urlOrderData = "https://api-dev.keola.club:2083/api/OrdersDetail/order/1286" // 1286
// https://api.keola.club:2096/api/typeofrating/5
// https://maps.googleapis.com/maps/api/directions/json?origin=-12.0486660003662,-77.0428771972656&waypoints=optimize:true|&destination=-12.117294,-77.0205524&key=AIzaSyDgaon21FSC2AynLJiYkgDn0lyVjp-75PQ&mode=walking&language=es&region=undefined
const MEKeolaShopOrderDetail = () => {
    const idOrder = window.location.pathname.split('/')[3]
    const [getCode, setGetCode] = React.useState('')
    const [dataOrder, setDataOrder] = React.useState('')
    useEffect(() => {
        fetch(urlCodeOrder.replace('1286', idOrder))
            .then(response => response.json())  
            .then(data => setGetCode(data.objModel))
        fetch(urlOrderData.replace('1286', idOrder))
            .then(response => response.json())
            .then(data => setDataOrder(data.objModel))
    }, [idOrder])
    return (
        <div className="kdetails-order">
            <div className="kdetails-card">
                <h3>
                    Su pedido está listo, lo puede recoger en Jr. Inca 312,Surquillo
                </h3>
                <div className="mini-card">
                    <p>
                        Entrega este código al personal de la tienda para recibir su pedido.
                    </p>
                    <p className='fw-bold h5' style={{color:'black'}}>
                       id Pedido: {idOrder}
                    </p>
                    <span>
                        {getCode}
                    </span>
                </div>
            </div>
            <div className="kdetails-card">
                <div className="storeinfo">
                    <img src={
                        dataOrder?.storeDTO?.photoStore
                    } alt="" />
                    <p>
                        {dataOrder?.storeDTO?.nameStore}
                    </p>
                </div>
                <hr />
                <div className="payment">
                    <p>
                        Medio de pago: {dataOrder?.paymentMethod?.name}
                    </p>
                </div>
                <hr />
                <div className="pay">
                    <p>
                        Tu cobro
                    </p>
                    <span>
                        S/. {dataOrder?.payTotal?.toFixed(2)}
                    </span>
                </div>
            </div>
            <div className="kdetails-card">
                <div className="proinfo">
                    <h4>Tus Productos</h4>
                    <span>
                        {
                            dataOrder?.storeDTO?.productSalesDTOList?.length || 0
                        }
                        &nbsp;
                        Productos
                    </span>
                    <div className="products">
                        {
                            dataOrder?.storeDTO?.productSalesDTOList?.map((prod, key) => {
                                return (
                                    <div className="product">
                                        <img src={prod?.photos[0]?.photoSales} alt="" />
                                        <div className="info">
                                            <p>
                                                {prod.name}
                                            </p>
                                            {/* <span>
                                                S/. {prod.priceProduct}
                                            </span> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <hr />
                    <p
                        style={{
                            color: '#000',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            alignSelf: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        Ya no puedes realizar modificaciones a tu pedido. Si, necesitas ayuda por favor comunícate con soporte de KeOla.
                        {/* link to watsap soporte */}
                        <a 
                        href={`https://wa.me/51936676600?text=Hola%20soy%20cliente%20de%20KeOla%20y%20necesito%20ayuda%20con%20mi%20pedido%20nro%20#${idOrder}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        >
                            <img src={watsapImg} alt="soporte" 
                                style={{width: '20px', height: '20px', marginLeft: '5px'}} 
                            />
                            +51 936 676 600
                        </a>

                    </p>
                </div>
            </div>
        </div>
    )
}

export default MEKeolaShopOrderDetail
