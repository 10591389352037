import React, { Component } from "react";
import { Button, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import PackageMain from "../../components/shop/PackageMain";
import PackageEndView from "./PackageEndView";
import AuthService from "../../services/auth.service";
import history from "../navigation/history";
import utilsService from "../../services/utils.service";
import shopService from "../../services/shop.service";
import Swal from "sweetalert2";
import ErrorApi from "components/validators/error/ErrorApi";
const salert = (title = "", icon = "", time = 1600, showloading = true) => {
  let timer;
  time === "infinito" ? (timer = undefined) : (timer = time);
  Swal.fire({
    icon: icon,
    title: title,
    timer: timer,
    showConfirmButton: false,
    allowOutsideClick: false,
    didOpen: () => {
      if (showloading === true) {
        Swal.showLoading();
      } else {
        Swal.hideLoading();
      }
    },
  });
};
export default class PackageMainView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packageStatus: "",
      packages: [],
      packagesm: [],
      kitInit: [],
      amountPaid: 0,
      quotesPaid: 0,
      displayFooter: "none",
      currentTab: "",
      isComplete: false,
      payment: {
        scheduleJSON: "",
        idMethodPayment: 0,
        mountPaid: 0,
        isPaid: false,
        typePay: 0,
        vouchers: [],
        amountPaid: 0,
        quotesPaid: 0,
        totalInitial: 1,
        amountPay: 0,
      },
      voucher: {
        bank: "",
        isPaid: false,
      },
      amountWallet: 0,
      typeExchange: "",
      isErrorApiData: null,
      accumulatedsalary: 0,
      upgradeExperience: null,
      promotionalCode: "",
    };
  }

  componentDidMount() {
    this.getExchange();
  }
  async getExchange() {
    let response = await utilsService.getExchanges(); //utilsService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: (this.state.typeExchange = response.objModel),
        });
      }
    }
  }

  OnClicked = (e, path) => {
    history.push(path);
  };

  eventhandler = (data, field) => {
    this.setState({ [field]: data });
  };
  /**
   * Event to handle if the footer is shown or hidden
   * @param {*} value
   * @param {*} field
   */
  eventFooter = (value, field) => {
    this.setState({ [field]: value });
  };

  /**
   * To handle the event  on payment step
   */
  eventPayment = (value, field) => {
    var temp = this.state.payment;
     console.log("haber temp donde sale payment", temp);
    temp[field] = value;
    this.setState({ payment: temp });
  };

  eventVoucher = (value, field) => {
    var temp = this.state.voucher;
    temp[field] = value;
    this.setState({ voucher: temp });
  };

  
  //Event wallet
  eventWallet = (amount) => {
    console.log("haber el amount", amount);
    this.setState({
      amountWallet: (this.state.amountWallet = amount),
    });
  };

  validationAfterPay = () => {
    if (this.state.packageStatus.length === 0) {
      alert("Seleccione una modalidad de compra.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.packages.length === 0 && this.state.packagesm.length === 0) {
      alert("Selecione un paquete.");
      console.log("Seleccione un paquete validationafterpay");
      this.setState({
        currentTab: "Step3",
      });
      return false;
    }

    return true;
  };

  validation = () => {
    console.log("entraste a validation");

    if (this.state.packageStatus.length === 0) {
      alert("Seleccione una modalidad de compra.");
      return false;
    } else if (
      this.state.packageStatus === "U" &&
      this.state.upgradeExperience === 0
    ) {
      alert("Seleccione la membresia a migrar.");
      return false;
    }
    ///
    if (
      (this.state.packages.length === 0 && this.state.packageStatus === "N") ||
      (this.state.packagesm.length === 0 && this.state.packageStatus === "U")
    ) {
      alert("Selecione un paquete.");
      console.log("Seleccione paquete validation");
      this.setState({
        currentTab: "Step3",
      });
      return false;
    }

    if (this.state.payment.typePay === 1) {
      if (!this.state.payment.isPaid) {
        if (this.state.payment.vouchers.length <= 0) {
          alert("Tiene que registrar el pago de su compra.");
          return false;
        }
      }
    } else if (this.state.payment.typePay === 0) {
      if (this.state.payment.vouchers.length <= 0) {
        alert("Seleccione un método de pago.");
        return false;
      }
    }
    let vouchersUpload = this.state.payment.vouchers;

    console.log("haber vouchersUpload ", vouchersUpload);//AQUI LLEGA LO QUE ESCRIBES DE PESOS

    let cambio = Number(this.state.typeExchange.venta);
    console.log("haber cambio ", cambio);
    let totalamount = Number(0);
    //let amountpay=Number(sessionStorage.getItem('amountPay2'))
    let amountpay = Number(this.state.payment.amountPay);
    console.log("haber amountpay ", amountpay);

    let comision = Number(0);

    if (vouchersUpload.length > 0) {
      for (let i = 0; i < vouchersUpload.length; i++) {
        // Split base64 code type and string
        let moneda = Number(vouchersUpload[i].typeExchange);
        console.log('MONEDA ->',moneda);
        let cantidad = Number(vouchersUpload[i].amount);

        if (moneda === 1) {
          //dolar
          totalamount += cantidad;
          comision = comision + Number(vouchersUpload[i].comision); //comision dolares
        } else if (moneda === 2) {
          //moneda=2 sol
          cantidad /= cambio; //convertir sol a dolar
          totalamount += cantidad;
          comision =
            comision +
            Number(Number(vouchersUpload[i].comisionExchange) / cambio); //comision soles
        } else if (moneda === 3) {
          //pesos colombianos
          cantidad /= 4802.97; //convertir peso a dolar
          totalamount += cantidad;
          comision =
            comision +
            Number(Number(vouchersUpload[i].comisionExchange) / 4802.97); //comision pesos
        }
      }

      let montowallet = Number(this.state.amountWallet);
     // console.log('montowallet?',montowallet);
      if (montowallet !== undefined) {
        totalamount += montowallet;
      }

      //quitando comision al monto total
      totalamount -= comision;


      if (totalamount < amountpay - 1 || totalamount > amountpay + 3) {
        alert("El monto total ingresado no coincide con el monto a pagar");
        return false;
      }
    }
    return true;
  };
  //AQUI MUERE VALIDATION

  // TypeMethodPayment
  // {
  //     Paypal = 1,
  //     Vouchers = 2,
  //     Wallet = 3,
  //     Mixto = 4,
  //     Otros = 5,
  // }
  sendData = async () => {
    console.log("mensaje al hacer click en finalizar", this.state.amountWallet);

    if (this.validation()) {
      console.log("SE VALIDO");
      // Loading

      salert("Guardando información...", "info", "infinito", true);
      let data = {};
      // Set properties
      console.log('HABER PAYMENT',this.state.payment);
      data.IdUserPayment = Number(AuthService.getCurrentIdUser());
      data.AmountPaidPayment = this.state.payment.amountPay;
      data.QuotesPaid = this.state.payment.quotesPay
        ? this.state.payment.quotesPay
        : 0;
      data.NumberPaymentInitials = 1;

      //////////////////////////////////////////////////////////// Pay with paypal
      if (this.state.payment.typePay === 1) {
        data.TypeMethodPayment = 1;
        data.PaypalDTO = {
          nroOperation: this.state.payment.idTransaction,
        };
        data.WalletTransaction = {
          Amount: 0,
        };
        data.Vouchers = [];
      } else if (this.state.payment.typePay === 0) {
        let typeMethod = 0;
        let vouchers = [];
        if (this.state.payment.vouchers.length > 0) {
          typeMethod = 2;
          console.log('this.state.payment.vouchers =>',this.state.payment.vouchers);
          for (let i = 0; i < this.state.payment.vouchers.length; i++) {
            let contenType = this.state.payment.vouchers[i].voucherBase.split(
              ","
            );
              console.log('amount =>',this.state.payment.vouchers[i].amount);
              console.log('amount replace =>',this.state.payment.vouchers[i].amount?.replaceAll(",",""));
              console.log('amount number =>',Number(this.state.payment.vouchers[i].amount));
              console.log('this.state.payment.vouchers[i].typeExchange =>',this.state.payment.vouchers[i].typeExchange);
            let temp = {
              MethodSubTipoPagoId: Number(this.state.payment.vouchers[i].type),
              OperationNumber: this.state.payment.vouchers[i].code,
              //TotalAmount: Number(this.state.payment.vouchers[i].amount),
              TotalAmount: Number(
                this.state.payment.vouchers[i].typeExchange ===3
                ? this.state.payment.vouchers[i].amount?.replaceAll(",", "")
                :this.state.payment.vouchers[i].amount
                )
              ,
              PaymentCoinCurrencyId: Number(
                this.state.payment.vouchers[i].typeExchange
              ),
              Note: this.state.payment.vouchers[i].nota,
              Base64: contenType[1],
            };
            console.log('ver temp =>',temp);
            
            vouchers.push(temp);
          }

          data.Vouchers = vouchers;
          data.NotePay = vouchers.map((item) => item.NotePay).join(", ");
        }
        console.log('DATA HASTA AHORA =>',data);
        ///////////////////////////////////////////////////////////wallet
        if (this.state.amountWallet > 0) {
          data.WalletTransaction = {
            Amount: this.state.amountWallet,
            ReferenceData:
              this.state.packageStatus === "N"
                ? "Compra de suscripcion adicional."
                : "Upgrade de membresia",
          };

          // TypeMethodPayment
          typeMethod = typeMethod > 0 ? 4 : 3;
        }

        data.TypeMethodPayment = typeMethod;
      } else if (this.state.payment.typePay === 3) {
        data.WalletTransaction = {
          Amount: this.state.amountWallet,
          ReferenceData:
            this.state.packageStatus === "N"
              ? "Compra de suscripcion adicional."
              : "Upgrade de membresia",
        };

        data.TypeMethodPayment = 3;
      }

      //verify if migration
      if (this.state.packageStatus === "N") {
        if (this.state.promotionalCode !== "") {
          data.promotionalCode = {
            code: this.state.promotionalCode,
          };
        } else {
          data.promotionalCode = {};
        }

        data.IdPackageDetailPayment = this.state.packages[0].listPackageDetails[0].idPackageDetail;
        if (this.state.payment.ispalcontado) {
          data.Iscontado = 1;
        } else {
          data.Iscontado = 0;
        }
        data.NumberPaymentInitials = this.state.payment.totalInitial;
        if (data.NumberPaymentInitials > 1) {
          data.NumberAdvancePaymentPaid = 0;
        } else {
          data.NumberAdvancePaymentPaid =
            data.QuotesPaid > 0 ? data.QuotesPaid : 0;
        }
        console.log('haber Data que se envia TIENDA =>',data);

        let response = await shopService.registerNewSuscription(data);
        Swal.close();
        if (response !== undefined && response.status === 1) {
          this.setState({ isComplete: true });
        } else {
          salert(
            "Tuvimos problemas al registrar su compra",
            "error",
            1600,
            false
          );
        }
      } else if (this.state.packageStatus === "U") {
        data.IdSuscriptionPayment = this.state.upgradeExperience;
        data.IdPackageDetailPayment = this.state.packagesm[0].idPackageDetail;
        data.NumberPaymentInitials = this.state.payment.totalInitial;
        if (data.NumberPaymentInitials > 1) {
          data.NumberAdvancePaymentPaid = 0;
        } else {
          data.NumberAdvancePaymentPaid =
            data.QuotesPaid > 0 ? data.QuotesPaid : 0;
        }

        let response = await shopService.registerUpgradeSuscription(data);
        Swal.close();
        if (response !== undefined && response.status === 1) {
          this.setState({ isComplete: true });
        } else {
          salert(
            "Tuvimos problemas al registrar su compra",
            "error",
            1600,
            false
          );
        }
      }
    }
  };

  // Clean values
  clean = () => {
    this.setState({
      isComplete: false,
      packageStatus: "",
      packages: [],
      packagesm: [],
      kitInit: [],
      amountPaid: 0,
      quotesPaid: 0,
      displayFooter: "none",
      currentTab: "",
      payment: {
        scheduleJSON: "",
        idMethodPayment: 0,
        mountPaid: 0,
        isPaid: false,
        typePay: 0,
        vouchers: [],
        amountPaid: 0,
        quotesPaid: 0,
      },
      voucher: {
        bank: "",
        isPaid: false,
      },
      amountWallet: 0,
    });
  };

  render() {
    return (
      // <div className="auth-wrapper">
      <div>
        {!this.state.isComplete && (
          <ErrorApi errorData={this.state.isErrorApiData}>
            <div className="register-inner">
              {/* <Form.Label className="content-title">Registro de nuevo socio</Form.Label> */}

              <PackageMain
                onChange={this.eventhandler}
                eventFooter={this.eventFooter}
                eventPay={this.eventPayment}
                eventWallet={this.eventWallet}
                packages={this.state.packages}
                currentTab={this.state.currentTab}
                validateToPay={this.validationAfterPay}
                registerBuy={this.sendData}
              ></PackageMain>
              {(this.state.packages.length > 0 ||
                this.state.kitInit.length > 0 ||
                this.state.packagesm.length > 0) && (
                <div className="row justify-content-between">
                  <Col ms={4}>
                    <Button
                      variant="danger"
                      onClick={(e) => this.OnClicked(e, "/home")}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <Button variant="primary" onClick={this.sendData}>
                      Finalizar
                    </Button>
                  </Col>
                </div>
              )}
            </div>
          </ErrorApi>
        )}
        {this.state.isComplete && (
          <div>
            <PackageEndView refresh={this.clean}></PackageEndView>
          </div>
        )}
      </div>
    );
  }
}
