import React from 'react';
import { FcLightAtTheEndOfTunnel } from 'react-icons/fc';


class  Validation {


    convertDateToStringComplete(dateString){
       
        let dformat = "";
        if(dateString !== null){
            let date = new Date(dateString);
            if(!isNaN(date)) {
                if(date.getFullYear() === 1 || date.getFullYear() <= 1900) {
                    dformat = "";
                } else {
                   
                    dformat = (( "00" + (date.getHours())).slice(-2) + ":"  + ("00" + date.getMinutes()).slice(-2) + ":"  +  ("00" + date.getMinutes()).slice(-2));
                    dformat += " " + (( "00" + (date.getDate())).slice(-2) + "/"  + ("00" + (date.getMonth()+1)).slice(-2) + "/"  +  date.getFullYear());
                }
            }
        }
        
        return dformat;
    }

    convertFechaHora(dateString){
        let dformat = "";
        if(dateString !== null){
            let date = new Date(dateString);
            if(!isNaN(date)) {
                if(date.getFullYear() === 1 || date.getFullYear() <= 1900) {
                    dformat = "";
                } else {
                    dformat =(( "00" + (date.getDate())).slice(-2) + "/"  + ("00" + (date.getMonth()+1)).slice(-2) + "/"  +  date.getFullYear()+"   "+("00" +date.getHours()).slice(-2)+":"+("00" +date.getMinutes()).slice(-2)+":"+("00" +date.getSeconds()).slice(-2));
                }
            }
        }
        
        return dformat;
    }

    convertDateToString(dateString){
        
        let dformat = "";
        if(dateString !== null){
            let date = new Date(dateString);
            if(!isNaN(date)) {
                if(date.getFullYear() === 1 || date.getFullYear() <= 1900) {
                    dformat = "";
                } else {
                    dformat =(( "00" + (date.getDate())).slice(-2) + "/"  + ("00" + (date.getMonth()+1)).slice(-2) + "/"  +  date.getFullYear());
                }
            }
        }
        
        return dformat;
    }

    convertDateToStringEx(dateString){
        
        let dformat = "";
        if(dateString !== null){
            let date = new Date(dateString);
            if(!isNaN(date)) {
                if(date.getFullYear() === 1 || date.getFullYear() <= 1900) {
                    dformat = "";
                } else {
                    let day = ("00" + (date.getDate())).slice(-2);
                    let month = ("00" + (date.getMonth()+1)).slice(-2) 
                    let year =  date.getFullYear();
    
                    dformat =( day + "-"  +  month+ "-"  + year);
                }
            }
        }
        
        return dformat;
    }

    formatDate(dateString){
        
        let dformat = "";
        if(dateString !== null){
            let date = new Date(dateString);
            if(!isNaN(date)) {
                if(date.getFullYear() === 1 || date.getFullYear() <= 1900) {
                    dformat = "";
                } else {
                    let day = ("00" + (date.getDate())).slice(-2);
                    let month = ("00" + (date.getMonth()+1)).slice(-2) 
                    let year =  date.getFullYear();
    
                    dformat =( day + "-"  +  month+ "-"  + year);
                }
            }
        }
        
        return dformat;
    }

    getState(idState, isQuote){
        const colorActiveNew = "#8bc34a"; // Activo(nuevo o migración)
        const colorActive = "#2c43c3"; // Activo
        const colorPending ="#ec8c1e"; // Pendiente
        const colorAfter ="#f11e45"; // Pagar después
        const colorInactive ="#666464"; //Inactivo
        const colorEndSusciption =  "#9e9e9e"
        let data = {};
        switch(idState){
            case 0: 
                // Inactivo
                data.state = (isQuote)?"": "Inactivo";
                data.colorState = colorInactive
                break;
            case 1: 
                // Patrocinados directos
                data.state = (isQuote)?"Pagado":"Activo";
                data.colorState = colorActive
                break;
            case 2: 
                // Pendiente de validación inicial
                data.state = "Pendiente validación inicial"
                data.colorState = colorPending
                break;
            case 3: 
                // Rechazo de incial";
                data.state = "Pago inicial rechazado"
                data.colorState = colorAfter
                break;
            case 4: 
                // Pagar despues"
                data.state = "Pago después"
                data.colorState = colorAfter
                break;
            case 5: 
                // Deuda 1";
                data.state = "Deuda 1"
                break;
            case 6: 
                // Deuda 2";
                data.state = "Deuda 2"
                break;
            case 7: 
                // Deuda 3";
                data.state = "Deuda 3"
                break;
            case 8: 
                // Deuda 4";
                data.state = "Deuda 4"
                break;
            case 9: 
                // Congelado";
                data.state = "Congelado"
                break;
            case 10: 
                // Pendiente de validación";
                data.state = "Pendiente validación cuota"
                data.colorState = colorPending
                break;
            case 11: 
                // Pago rechazado";
                data.state = "Pago de cuota rechazado"
                data.colorState = colorAfter
                break;
            case 12: 
                // Suscription finalizada";
                data.state = "Suscripción finalizada"
                data.colorState = colorEndSusciption
                break;
            case 13: 
                // Pendiente de validacion migracion";
                data.state = "Migracíon - Pendiente de validación"
                data.colorState = colorActiveNew
                break;
            case 14: 
                // Rechazo pago de migración";
                data.state = "Pago de migración rechazado"
                data.colorState = colorEndSusciption
                break;
            case 15: 
                // Periodo de gracia";
                data.state = "Periodo de gracia"
                data.colorState = colorEndSusciption
                break;
            default:
                data.state = "-"
                data.colorState = "-"
                break;
      
            }
          
        
          return data;
    }

}

export default new Validation();