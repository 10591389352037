import React, { Component } from "react";
import { Form, Button,Col} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import  RegisterMain  from '../../components/updateuser/RegisterMain';
import "./UpdateMainView.css";
import Swal from "sweetalert2";
const API_URL=process.env.REACT_APP_APP_URL_API;
const salert=(title="",icon="",time=1600,showloading=true)=>{
  let timer;
  time==="infinito"?
  timer=undefined:
  timer=time
  Swal.fire({
          icon: icon,
          title: title,
          timer: timer,
          showConfirmButton:false,
          allowOutsideClick: false,
          didOpen: ()=>{
            if (showloading===true){
              Swal.showLoading()
            }
            else{
              Swal.hideLoading()
            }
          }
  })
}
export default class RegisterMainView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idSocio: 0,
      user: {
        name: "",
        lastname: "",
        birthdate: "",
        gender: "",
        idNationality: -1,
        nroDocument: "",
        civilState: "",
        email: "",
        idResidenceCountry: -1,
        idDocumentType: "",
        desDocument: "",
        districtAddress: "",
        address: "",
        phone: "",
        codephone: "",
        coafiliate: false,
        mailtosend: "",
      },
      tempuser: {},
      arraystylevalidation: {},
      displayFooter: false,
      currentTab: "",
    };
  }
  componentDidUpdate(props){
    if (this.props.idSocio!==props.idSocio){

      let arraystylevalidation=Object.assign({},{ 
              name: false,
              lastname: false,
              birthdate: false,
              gender: false,
              idNationality: false,
              nroDocument: false,
              civilState: false,
              email: false,
              idResidenceCountry: false,
              idDocumentType: false,
              desDocument: false,
              districtAddress: false,
              address: false,
              phone: false,
              codephone: false,
              coafiliate: false,
              mailtosend: false,
        });
        this.setState({
          idSocio: Number(this.props.idSocio),
          arraystylevalidation: arraystylevalidation
        })
      //salert("Enviando Datos","info","infinito",true)
    }
  }
  eventhandler = (data, field) => {
    // this.state.tempuser = data;
    var temp = this.state.user;
    temp[field] = data;
    this.setState({ user: temp });
  };
  /**
   * Event to handle if the footer is shown or hidden
   * @param {*} value
   * @param {*} field
   */
  eventFooter = (value, field) => {
    this.setState({ [field]: value });
  };
  eventStyleValidation = (value, field) => {
    let arraystylevalidation=this.state.arraystylevalidation;
    arraystylevalidation[field]=value;
    this.setState({ arraystylevalidation: arraystylevalidation });
  };
  /**
   * Validate form for step 1, step  and step 3
   */
  eventValidate = () => {
    // Step 1
    if (this.state.user.name.length === 0) {
      alert("Ingrese sus nombres.");
      return false;
    }
    if (this.state.user.lastname.length === 0) {
      alert("Ingrese sus apellidos.");
      return false;
    }
    if (this.state.user.birthdate.length === 0) {
      alert("Ingrese su fecha de nacimiento");
      return false;
    }
    if (this.state.user.gender.length === 0) {
      alert("Seleccione su sexo.");
      return false;
    }
    if (this.state.user.idNationality <= 0) {
      alert("Seleccione su nacionalidad.");
      return false;
    }

    if (this.state.user.idDocumentTypeCountry <= 0) {
      alert("Seleccione un tipo de documento.");
      return false;
    }
    if (this.state.user.nroDocument.length === 0) {
      alert("Ingrese su número de documento.");
      return false;
    }
    if (this.state.user.civilState.length === 0) {
      alert("Seleccione su estado civil.");
      return false;
    }

    //Step 2
    if (this.state.user.email.length === 0) {
      alert("Ingrese su correo electrónico.");
      return false;
    }
    if (this.state.user.idResidenceCountry <= 0) {
      alert("Seleccione su país de residencia.");
      return false;
    }
    if (this.state.user.districtAddress.length === 0) {
      alert("Ingrese su ciudad.");
      return false;
    }
    if (this.state.user.address.length === 0) {
      alert("Ingrese su dirección.");
      return false;
    }
    if (this.state.user.phone.length === 0) {
      alert("Ingrese su número de celular.");
      return false;
    }

    return true;
  };

  /**
   * To handle the event  on payment step
   */
  eventPayment = (value, field) => {
    var temp = this.state.payment;
    temp[field] = value;
    this.setState({ payment: temp });

    var temp1;
    // TODO change by object verify if the field of paypal are filled
    if (field === "isPaid") {
      temp1 = this.state.observerPaypal;
      temp1[field]=value;
      this.setState({ observerPaypal: temp1 });
    }
    if (field === "mountPaid") {
      temp1 = this.state.observerPaypal;
      temp1[field]=value;
      this.setState({ observerPaypal: temp1 });
    }
    if (field === "creationDate") {
      temp1 = this.state.observerPaypal;
      temp1[field]=value;
      this.setState({ observerPaypal: temp1 });
    }
    if (field === "modificationDate") {
      temp1 = this.state.observerPaypal;
      temp1[field]=value;
      this.setState({ observerPaypal: temp1 });
    }
    if (field === "idTransaction") {
      temp1 = this.state.observerPaypal;
      temp1[field]=value;
      this.setState({ observerPaypal: temp1 });
    }
    if (field === "intent") {
      temp1 = this.state.observerPaypal;
      temp1[field]=value;
      this.setState({ observerPaypal: temp1 });
    }
    if (field === "status") {
      temp1 = this.state.observerPaypal;
      temp1[field]=value;
      this.setState({ observerPaypal: temp1 });
    }

    //verify if all field are not undefined
    this.verifyObjetPaypal(this.state.observerPaypal);
  };

  /** verify observer paypal */
  verifyObjetPaypal = (obj) => {
    if (
      obj.isPaid !== undefined &&
      obj.mountPaid !== undefined &&
      obj.creationDate !== undefined &&
      obj.modificationDate !== undefined &&
      obj.idTransaction !== undefined &&
      obj.intent !== undefined &&
      obj.status !== undefined
    ) {
      if (obj.isPaid) {
        // save paypal and complete payment
        this.sendData();
      }
    } else {
    }
  };

  eventVoucher = (value, field) => {
    var temp = this.state.voucher;
    temp[field] = value;
    this.setState({ voucher: temp });
  };
  // name: false,
  // lastname: false,
  // birthdate: false,
  // gender: false,
  // idNationality: false,
  // nroDocument: false,
  // civilState: false,
  // email: false,
  // idResidenceCountry: false,
  // idDocumentType: false,
  // desDocument: false,
  // districtAddress: false,
  // address: false,
  // phone: false,
  // codephone: false,
  // coafiliate: false,
  // mailtosend: false,
  validationstyle = ()=>{
    let asvalidation=this.state.arraystylevalidation;
    if (this.state.user.name.length === 0) {
      
      Object.assign(asvalidation,{name:true})
      
    }
    if (this.state.user.lastname.length === 0) {
      
      Object.assign(asvalidation,{lastname:true})

    }
    if (this.state.user.birthdate.length === 0) {
      Object.assign(asvalidation,{birthdate:true})
    }
    if (this.state.user.gender.length === 0) {
      Object.assign(asvalidation,{gender:true})
    }
    if (this.state.user.idNationality <= 0) {
      Object.assign(asvalidation,{idNationality:true})
    }

    if (this.state.user.idDocumentType <= 0) {
      Object.assign(asvalidation,{idDocumentType:true})
    }
    if (this.state.user.nroDocument.length === 0) {
      Object.assign(asvalidation,{nroDocument:true})
    }
    if (this.state.user.civilState.length === 0) {
      Object.assign(asvalidation,{civilState:true})
    }
    if (this.state.user.email.length === 0) {
      Object.assign(asvalidation,{email:true})
    }
    if (this.state.user.idResidenceCountry <= 0) {
      Object.assign(asvalidation,{idResidenceCountry:true})
    }
    if (this.state.user.districtAddress.length === 0) {
      Object.assign(asvalidation,{districtAddress:true})
    }
    if (this.state.user.address.length === 0) {
      Object.assign(asvalidation,{address:true})
    }
    if (this.state.user.phone.length === 0) {
      Object.assign(asvalidation,{phone:true})
    }
    console.log(asvalidation)
    this.setState({arraystylevalidation:asvalidation})
  }
  validation = () => {
    this.validationstyle();
    if (this.state.user.name.length === 0) {
      
      alert("Ingrese sus nombres.");
      this.setState({
        currentTab: "Step1",
      });

      return false;
    }
    if (this.state.user.lastname.length === 0) {
      alert("Ingrese sus apellidos.");
      this.setState({
        currentTab: "Step1",
      });

      return false;
    }
    if (this.state.user.birthdate.length === 0) {
      alert("Ingrese su fecha de nacimiento");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.gender.length === 0) {
      alert("Seleccione su sexo.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.idNationality <= 0) {
      alert("Seleccione su nacionalidad.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }

    if (this.state.user.idDocumentType <= 0) {
      alert("Seleccione un tipo de documento.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.nroDocument.length === 0) {
      alert("Ingrese su número de documento.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.civilState.length === 0) {
      alert("Seleccione su estado civil.");
      this.setState({
        currentTab: "Step1",
      });
      return false;
    }
    if (this.state.user.email.length === 0) {
      alert("Ingrese su correo electrónico.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.idResidenceCountry <= 0) {
      alert("Seleccione su país de residencia.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.districtAddress.length === 0) {
      alert("Ingrese su ciudad.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.address.length === 0) {
      alert("Ingrese su dirección.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    if (this.state.user.phone.length === 0) {
      alert("Ingrese su número de celular.");
      this.setState({
        currentTab: "Step2",
      });
      return false;
    }
    
    return true;
  };

  getParameterByName(name) {
    name = name.replace(/[[]/, "\\[")
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  closeWindow() {
    window.close();
  }

  //Para capturar el valor de la caja de texto del correo electrónico
  handleChangeEmail = (e) => {
    let id = e.target.value;
    localStorage.setItem("userEmail", id);
    var temp = this.state.user;
    temp["mailtosend"] = id;
    this.setState({ user: temp });
  };



  sendData = async (e) => {
    if (this.validation()) {
      
      let fechaactual=new Date();
      let day=("00"+fechaactual.getDate()).slice(-2);
      let month=("00" + (fechaactual.getMonth()+1)).slice(-2);
      let year =  fechaactual.getFullYear();
      let hours = ("00"+fechaactual.getHours()).slice(-2);
      let minutes=("00"+fechaactual.getMinutes()).slice(-2);
      let seconds=("00"+fechaactual.getSeconds()).slice(-2);
      let stringfecactual=year+"-"+month+"-"+day+"T"+hours+":"+minutes+":"+seconds;
      
      let jsontosend= Object.assign({
              IdUserGenerateTicket : this.state.idSocio,
              IdTypeTicket : 6,
              Observation : "",
              IdTicketCreationMedium : 2,
              TicketDetailRequestData : {
                TicketDetailRequestMembershipTransfer:{
                    UserTranfer:{
                        name: this.state.user.name,
                        lastname: this.state.user.lastname,
                        birthdate: this.state.user.birthdate,
                        gender: this.state.user.gender,
                        idNationality: Number(this.state.user.idNationality),
                        idDocument: Number(this.state.user.idDocumentType),
                        civilState: this.state.user.civilState,
                        email: this.state.user.email,
                        idResidenceCountry: Number(this.state.user.idResidenceCountry),
                        districtAddress: this.state.user.districtAddress,
                        address: this.state.user.address,
                        username: "",
                        password: "",
                        nroTelf:this.state.user.codephone + " " + this.state.user.phone,
                        boolDelete: 0,
                        createDate:stringfecactual,//"2020-07-07T17:45:47.617",
                        nroDocument: this.state.user.nroDocument,
                        otherDocument:"Habbo credential"
                    }
                }
              }
        })
      if (this.state.user.desDocument.length > 0) {
        jsontosend.TicketDetailRequestData.TicketDetailRequestMembershipTransfer.UserTranfer.otherDocument = this.state.user.desDocument;
      } 
      salert("Enviando Datos","info","infinito",true);
      const api=await fetch(`${API_URL}/Ticket/generate`,{method:"POST",headers: {'Content-Type': 'application/json'},body: JSON.stringify(jsontosend)});
      const response=await api.json();
      Swal.close();
      if (response !== undefined) {
        if (response.status === 1) {
          // alert('Usuario registrado');
          this.setState({
            nroTicket: response.objModel,
          });
          Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false,
            allowOutsideClick: false
          }).fire(
            'Se ha generado correctamente la solicitud',
            '',
            'success',
          ).then((result)=>{
            if (result.isConfirmed){
              this.closeWindow();
            }
          })

        } else {          
          salert("Ocurrió un error al momento de enviar datos:"+response.description,"error",1600,false)
        }
      } else {
        salert("Tuvimos un problema. Inténtalo más tarde","error",1600,false)
      }
    }
  };

  render() {
    return (
      // <div className="auth-wrapper">
      <section>
        
        <div
          className="register-inner nomarginbottom"
        >
          <div className="justifycenter mb-1 mt-3">
              <Form.Label className="content-title">Traspaso de Membresía</Form.Label>
          </div>
          <RegisterMain
            onChange={this.eventhandler}
            eventFooter={this.eventFooter}
            currentTab={this.state.currentTab}
            arraystylevalidation={this.state.arraystylevalidation}
            eventStyleValidation={this.eventStyleValidation}
          ></RegisterMain>
          <hr></hr>
          {this.state.displayFooter?
          <div className="row justify-content-between">
            <Col xs="12" sm="6" md="5" lg="4" className="marginauto">
              <Button variant="primary" onClick={this.sendData} className="width100">
                Enviar Datos
              </Button>
            </Col>
          </div>:<></>}
          
        </div>
      </section>
    );
  }
}
