import React, { Component } from "react";
import {
  Button,
  Form,
  Spinner,
  Table,
  Accordion,
  Card,
  InputGroup,
  FormControl,
  Col,
  Row,
  Container,
  ProgressBar,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import UtilService from "../../services/utils.service";
import authService from "../../services/auth.service";
import Swal from "sweetalert2";
import "./StepThree.css";
import LoadApi from "../validators/LoadApi";
const API_URL = process.env.REACT_APP_APP_URL_API;

const salert = (text = "", icon = "") => {
  const Tst = Swal.mixin({
    toast: true,
    position: "top-end",
    timer: 2000,
    showConfirmButton: false,
  });
  Tst.fire({
    icon: icon,
    title: text,
  });

};

const calcularProgress = (a, b) => {
  const value = ( a / b ) * 100;

  return value.toFixed(2);
}

export default class StepThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkList: [],
      packageFamily: [],
      familyInit: [],
      tempPackage: [],
      bonosFamily: [],
      loading: true,
      noData: false,
      message: "",
      promotionalCode: "",
      codeStatus: false,
      packagePromotionalcode: [],
      isLoadedApiData: true,
      isErrorApiData: false,
      showpmessage: false,
      typeSlected: '',
      idSelectType: 0,
    };

    this.getPackageFamily = this.getPackageFamily.bind(this);
    this.getBonosFamily = this.getBonosFamily.bind(this);
  }

  /**
   * Execute befores renderer
   * Get packages
   */
  componentDidMount() {
    this.getPackageFamily();
    this.getBonosFamily();
  }

  async getPackageFamily() {
    let data = await UtilService.getPackageswithdetailsbyfamily(); //await UtilService.getPackageswithdetailsbygroup();
    if (data !== undefined) {
      if (data.objModel.length > 0) {
        // Store packages in temp
        let temp = [];
        let i;
        for (i = 0; i < data.objModel.length; i++) {
          temp = temp.concat(data.objModel[i].packages);
        }
        // sepate kit package family and packages families
        // Get kit initial
        let packageFamily = [];
        let familyInit = [];
        let idInit = 27;
        let x;
        for (x = 0; x < data.objModel.length; x++) {
          if (data.objModel[x].id === idInit) {
            data.objModel[x].packages.sort(function(a, b) {
              return (
                a.listPackageDetails[0].price - b.listPackageDetails[0].price
              );
            });
            familyInit.push(data.objModel[x]);
          } else {
            data.objModel[x].packages.sort(function(a, b) {
              return (
                a.listPackageDetails[0].price - b.listPackageDetails[0].price
              );
            });
            packageFamily.push(data.objModel[x]);
          }

          console.log("packageFamily", packageFamily)
        }

        // Add
        this.setState({
          tempPackage: temp,
          packageFamily: packageFamily,
          familyInit: familyInit,
          loading: false,
          noData: false,
        });
      } else {
        this.setState({
          packageFamily: [],
          familyInit: [],
          loading: false,
          noData: true,
          message: "No hay registros para mostrar.",
        });
      }
    } else {
      this.setState({
        packageFamily: [],
        familyInit: [],
        loading: false,
        noData: true,
        message:
          "Ocurrió un error al obtener los paquetes. Vuelva a intentarlo más tarde.",
      });
    }
  }

  //get bonos
  async getBonosFamily(){
    const url = `${API_URL}/familyPackage/packageBonus/detail/version/state?value=true`;

    await fetch(url,{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
    })
    .then((res) => res.json())
    .then((respuesta) => {
      console.log("lista de bonos,", respuesta);
      if (respuesta.status === 1 && respuesta.objModel.length > 0 ) {
        this.setState({
          bonosFamily: respuesta.objModel,
          loading: false,
          noData: false,
        });
      } else {
        this.setState({
          bonosFamily: [],
          loading: false,
          noData: false,
          message: "",
        });
      }
    })
    .catch((error) => {
      this.setState({
        bonosFamily: [],
        loading: false,
        noData: true,
        message:
          "Ocurrió un error al obtener los paquetes. Vuelva a intentarlo más tarde.",
      });
    });
  }

  modifydatatosend = (e) => {
    let selectedPackage = [
      Object.assign(
        {},
        {
          id: e.packageId,
          name: e.package.name,
          codeMembership: e.package.codeMembership,
          description: e.package.description,
          idFamilyPackage: e.familyPackageId,
          estatus: e.package.estatus,
          legalDocuments: e.package.legalDocuments,
          listPackageDetails: [
            {
              idPackageDetail: e.idPackageDetail,
              packageId: e.packageId,
              monthsDuration: e.monthsDuration,
              price: e.price,
              numberQuotas: e.numberQuotas,
              initialPrice: e.initialPrice,
              quotaPrice: e.quotaPrice,
              volume: e.volume,
              numberInitialQuote: e.numberInitialQuote,
              comission: e.comission,
              numberShares: e.numberShares,
              familyPackageId: e.familyPackageId,
              membershipVersionId: e.membershipVersionId,
              package: null,
              membershipVersion: {
                idMembershipVersion: e.membershipVersion.idMembershipVersion,
                description: e.membershipVersion.description,
              },
            },
          ],
        }
      ),
    ];
    return selectedPackage;
  };
  resetDatapackage = () => {
    let selectedPackage = [
      Object.assign(
        {},
        {
          id: 0,
          name: "",
          codeMembership: "",
          description: "",
          idFamilyPackage: "",
          estatus: 0,
          legalDocuments: null,
          listPackageDetails: [
            {
              idPackageDetail: 0,
              packageId: 0,
              monthsDuration: 0,
              price: 0,
              numberQuotas: 1,
              initialPrice: 0,
              quotaPrice: 0,
              volume: 0,
              numberInitialQuote: 0,
              comission: 0,
              numberShares: 0,
              familyPackageId: 0,
              membershipVersionId: 0,
              package: null,
              membershipVersion: {
                idMembershipVersion: 0,
                description: "",
              },
            },
          ],
        }
      ),
    ];
    return selectedPackage;
  };
  // Handle teh selection of radio butoon bys package family
  // The selection by package is unique
  // Id of family represent the position in the array
  handleSelection = (e, field) => {
    console.log('handleSelection', e, field, this.state.typeSlected)
    let selectedPackage = {};
    if (field === "packages" ) {
      if( this.state.typeSlected === 'bonos' ){
        alert('No puede agregar un paquete, si ya grego un bono')
        return;
      }

      if (this.state.codeStatus) {
        //poner formato codeStatus=false
        selectedPackage = this.modifydatatosend(e);
      } else {
        let idField = Number(e); // id package
        let tempPackages = this.state.tempPackage;
        let i;
        for (i = 0; i < tempPackages.length; i++) {
          if (tempPackages[i].id === idField) {
            selectedPackage = [tempPackages[i]];
          }
        }
      }
      this.props.onChange(selectedPackage, "packages");
      this.setState({typeSlected: 'packages'});
    } else if (field !== "kitinit" && field !== "bonos") {
      if (this.state.codeStatus) {
        // TODO implement get kit selected
        let kitInit = [this.state.familyInit[0].packages[0]];
        this.props.onChange(kitInit, "kitInit");
      } else {
      }
    }else if( field === "bonos" ){
      if( this.state.typeSlected === 'packages' ){
        alert('No puede agregar un bono, si ya grego un paquete');
        return;
      }
      const dataBonus = this.state.bonosFamily;
      const idBond = Number(e);
      let bond = {};

      dataBonus.map(value => {
        if( value.packages.length > 0 ){
          value.packages.map(item => {
              if( item.id == idBond ){
                bond = item;
              }
          })
        }
      });

      this.props.onChange([bond], "packages");
      this.setState({typeSlected: 'bonos'});
      
    }

    this.setState({idSelectType: Number(e)})
  };
  handlepromotionalcode = (e) => {
    this.setState({ promotionalCode: e });
  };
  ValidateFilter = () => {
    this.props.onChange(this.resetDatapackage(), "packages");
    let data = Object.assign(
      {},
      { userId: this.props.idSocio, code: this.state.promotionalCode }
    );
    this.setState({ isLoadedApiData: false });

    fetch(API_URL + "/promotionals/codes/family/version/code/validate", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          let familyPackageId = data.objModel.familyPackageId;
          let membershipVersionId = data.objModel.membershipVersionId;
          fetch(
            API_URL +
              "/package/details/family/" +
              familyPackageId +
              "/version/" +
              membershipVersionId
          )
            .then((res) => res.json())
            .then((result) => {
              if (result.objModel.length > 0) {
                let packagespromcode = result.objModel;
                packagespromcode.sort((a, b) => a.price - b.price);
                this.setState({
                  codeStatus: true,
                  showpmessage: true,
                  packagePromotionalcode: result.objModel,
                  isLoadedApiData: true,
                });
                this.props.eventText(
                  this.state.promotionalCode,
                  "promotionalCode"
                );
              } else {
                this.setState({
                  isLoadedApiData: true,
                  codeStatus: false,
                  showpmessage: true,
                });
              }
            })
            .catch((error) => {
              salert(error, "error");
              this.setState({
                codeStatus: false,
                isLoadedApiData: true,
              });
            });
        } else {
          fetch(
            API_URL + "/familyPackage/package/detail/version/state?value=true"
          )
            .then((res) => res.json())
            .then((pckavailable) => {
              if (pckavailable.objModel.length > 0) {
                // Store packages in temp
                let temp = [];
                let i;
                for (i = 0; i < pckavailable.objModel.length; i++) {
                  temp = temp.concat(pckavailable.objModel[i].packages);
                }
                // sepate kit package family and packages families
                // Get kit initial
                let packageFamily = [];
                let familyInit = [];
                let idInit = 27;
                let x;
                for (x = 0; x < pckavailable.objModel.length; x++) {
                  if (pckavailable.objModel[x].id === idInit) {
                    pckavailable.objModel[x].packages.sort(function(a, b) {
                      return (
                        a.listPackageDetails[0].price -
                        b.listPackageDetails[0].price
                      );
                    });
                    familyInit.push(pckavailable.objModel[x]);
                  } else {
                    pckavailable.objModel[x].packages.sort(function(a, b) {
                      return (
                        a.listPackageDetails[0].price -
                        b.listPackageDetails[0].price
                      );
                    });
                    packageFamily.push(pckavailable.objModel[x]);
                  }
                }

                // Add
                this.setState({
                  tempPackage: temp,
                  packageFamily: packageFamily,
                  familyInit: familyInit,
                });
              } else {
                this.setState({
                  packageFamily: [],
                  familyInit: [],
                });
              }
            })
            .catch((error) => {
              salert(error, "error");
            });

          this.setState({
            codeStatus: false,
            isLoadedApiData: true,
            showpmessage: true,
          });
        }
      })
      .catch((error) => {
        salert(error, "error");
        this.setState({
          codeStatus: false,
          isLoadedApiData: true,
        });
      });
  };
  render() {
    const {
      codeStatus,
      message,
      noData,
      loading,
      familyInit,
      packageFamily,
      packagePromotionalcode,
      isLoadedApiData,
      bonosFamily,
      idSelectType
    } = this.state;
    const userName = sessionStorage.getItem("info") ? JSON.parse(sessionStorage.getItem("info")).username : "" ; ;
 //   console.log("haber usuario StepThree =>", userName);
    return (
      <div>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <Form.Label>Cargando información de paquetes...</Form.Label>
          </div>
        )}
        {!loading && !noData && (
          <div>
            <Form.Group style={{ marginTop: "20px" }}>
              <Row>
                <Col xs={10} sm={7} md={6} lg={3}>
                  <Form className="form-style-1">
                    <Form.Group as={Row}>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Label>Código promocional</Form.Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={7} sm={8} md={8} className="lg8">
                            <Form.Control
                              as="input"
                              isValid={false}
                              className="form-control-style"
                              onChange={(e) =>
                                this.handlepromotionalcode(e.target.value)
                              }
                              type="text"
                              defaultValue=""
                              inline
                            ></Form.Control>
                          </Col>
                          <Col xs={5} sm={4} md={4} className="lg4">
                            <Button
                              size="sm"
                              variant="primary"
                              type="button"
                              className="btncodeval"
                              onClick={
                                isLoadedApiData
                                  ? () => this.ValidateFilter()
                                  : null
                              }
                              disabled={!isLoadedApiData}
                            >
                              {!isLoadedApiData ? "Espere..." : "Aplicar"}
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={8} sm={8} md={8} className="lg8">
                            <Form.Text className="text-muted">
                              {this.state.showpmessage ? (
                                <>
                                  {codeStatus ? (
                                    <p className="txtgreenpromcode">
                                      Código Válido
                                    </p>
                                  ) : (
                                    <p className="txtredpromcode">
                                      Código Inválido
                                    </p>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </Form.Text>
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Form.Group>
            <LoadApi indicatorLoad={!this.state.isLoadedApiData}>
              <Form.Label className="content-subtitle">
                Kit de inicio
              </Form.Label>
              <Form.Group>
                <Accordion>
                  {familyInit.map((item) => {
                    return item.packages.length > 0 ? (
                      <Accordion.Item eventKey={item.id} key={item.id}>
                        <Accordion.Header>{item.name}</Accordion.Header>
                        <Accordion.Body>
                          <Table
                            striped
                            bordered
                            hover
                            responsive="md"
                            size="sm"
                          >
                            <thead className="table-head">
                              <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th colSpan={3}>Descripción</th>
                                <th>Precio</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!codeStatus &&
                                item.packages.map((itempck) => (
                                  <tr key={itempck.id}>
                                    <td>
                                      <Form.Check
                                        inline
                                        type="radio"
                                        name="kitinit"
                                        onChange={() =>
                                          this.handleSelection(
                                            itempck.id,
                                            "kitinit"
                                          )
                                        }
                                        disabled
                                      />
                                    </td>
                                    <td>{itempck.name}</td>
                                    <td colSpan={3}>
                                      {itempck.description}
                                      <br></br>
                                      Duración:{" "}
                                      {itempck.listPackageDetails[0]
                                        .monthsDuration > 1 ? (
                                        <div>
                                          {
                                            itempck.listPackageDetails[0]
                                              .monthsDuration
                                          }{" "}
                                          meses
                                        </div>
                                      ) : (
                                        <div>
                                          {
                                            itempck.listPackageDetails[0]
                                              .monthsDuration
                                          }{" "}
                                          mes
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      $ {itempck.listPackageDetails[0].price}
                                    </td>
                                  </tr>
                                ))}
                              {codeStatus &&
                                packagePromotionalcode.map((itempckPc, idx) => {
                                  if (itempckPc.familyPackageId === item.id) {
                                    return (
                                      <tr key={idx}>
                                        <td>
                                          <Form.Check
                                            inline
                                            type="radio"
                                            name="packages"
                                            onChange={() =>
                                              this.handleSelection(
                                                itempckPc,
                                                "kitinit"
                                              )
                                            }
                                            disabled
                                          />
                                        </td>
                                        <td>{itempckPc.package.name}</td>
                                        <td colSpan={3}>
                                          {itempckPc.package.description}
                                          <br></br>
                                          Duración:{" "}
                                          {itempckPc.monthsDuration > 1 ? (
                                            <div>
                                              {itempckPc.monthsDuration} meses
                                            </div>
                                          ) : (
                                            <div>
                                              {itempckPc.monthsDuration} mes
                                            </div>
                                          )}
                                        </td>
                                        <td>$ {itempckPc.price}</td>
                                      </tr>
                                    );
                                  }
                                })}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null;
                  })}
                </Accordion>
              </Form.Group>
              <Form.Group controlId="formStepThree" className="mb-3">
                <Form.Label className="content-subtitle">
                  Paquetes de inscripción
                </Form.Label>
                <Form.Group>
                  <Accordion>
                 {/*    {console.log("haber packageFamily paquetes de inscripcion ===>", packageFamily)}  */}
                    {/*  AQUI ESTAN LOS PAQUETES */}
                    {packageFamily.map((item, i) => {
                      return item.packages.length > 0 &&
                        item.name !== "RIBERA DEL RÍO "  ? (
                        <Accordion.Item eventKey={item.id} key={item.id}>
                          <Accordion.Header>{item.name}</Accordion.Header>
                          <Accordion.Body>
                            <Table
                              striped
                              bordered
                              hover
                              responsive="md"
                              size="sm"
                            >
                              <thead className="table-head">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Descripción</th>
                                  <th>Precio</th>
                                  <th>Reservación</th>
                                  <th>Número de cuotas</th>
                                  <th>Cuota inicial</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/*  AQUI ESTAN LAS SUBCRIPCIONES */}
                                {
                                  userName == 'master' ?
                                  (<> {!codeStatus &&
                                    item.packages.map((itempck) => {
                                      console.log("itempck", itempck)
                                      return itempck.estatus === 1 ? (
                                        <tr key={itempck.id}>
                                          <td>
                                            <Form.Check
                                              inline
                                              type="radio"
                                              name="packages"
                                              checked={idSelectType == itempck.id ? true : false}
                                              onChange={() =>
                                                this.handleSelection(
                                                  itempck.id,
                                                  "packages"
                                                )
                                              }
                                            />
                                          </td>
                                          <td>{itempck.name}</td>
                                          <td>
                                            {itempck.description}
                                            <br></br>
                                            Duración:{" "}
                                            {itempck.listPackageDetails[0]
                                              .monthsDuration > 1 ? (
                                              <div>
                                                {
                                                  itempck.listPackageDetails[0]
                                                    .monthsDuration
                                                }{" "}
                                                meses
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  itempck.listPackageDetails[0]
                                                    .monthsDuration
                                                }{" "}
                                                mes
                                              </div>
                                            )}
                                            {/* <ul>
                                                                          {
                                                                              itempck.descriptions.map((label, idxLabel) => (
                                                                              <li>{label}</li>
                                                                              ))
                                                                          }
                                                                      </ul> */}
                                          </td>
                                          <td>
                                            ${" "}
                                            {itempck.listPackageDetails[0].price}
                                          </td>
                                          <td>
                                            { itempck.listPackageDetails[0].reservationLimit !== null &&  `${itempck.listPackageDetails[0].numberReservation}/${itempck.listPackageDetails[0].reservationLimit}` }
                                          </td>
                                          <td>
                                            {
                                              itempck.listPackageDetails[0]
                                                .numberQuotas
                                            }
                                          </td>
                                          <td>
                                            ${" "}
                                            {
                                              itempck.listPackageDetails[0]
                                                .initialPrice
                                            }
                                          </td>
                                          {/* <td>{itempck.duration}</td> */}
                                        </tr>
                                      ) : null;
                                    })}</> )
                                  : 
                                  (<>
                                   {!codeStatus &&
                                  item.packages.map((itempck) => {
                                    return itempck.estatus === 1 &&
                                      itempck.name !== "Mini"? (
                                      <tr key={itempck.id}>
                                        <td>
                                          <Form.Check
                                            inline
                                            type="radio"
                                            name="packages"
                                            checked={idSelectType == itempck.id ? true : false}
                                            onChange={() =>
                                              this.handleSelection(
                                                itempck.id,
                                                "packages"
                                              )
                                            }
                                          />
                                        </td>
                                        <td>{itempck.name}</td>
                                        <td>
                                          {itempck.description}
                                          <br></br>
                                          Duración:{" "}
                                          {itempck.listPackageDetails[0]
                                            .monthsDuration > 1 ? (
                                            <div>
                                              {
                                                itempck.listPackageDetails[0]
                                                  .monthsDuration
                                              }{" "}
                                              meses
                                            </div>
                                          ) : (
                                            <div>
                                              {
                                                itempck.listPackageDetails[0]
                                                  .monthsDuration
                                              }{" "}
                                              mes
                                            </div>
                                          )}
                                          {/* <ul>
                                                                        {
                                                                            itempck.descriptions.map((label, idxLabel) => (
                                                                            <li>{label}</li>
                                                                            ))
                                                                        }
                                                                    </ul> */}
                                        </td>
                                        <td>
                                          ${" "}
                                          {itempck.listPackageDetails[0].price}
                                        </td>
                                        <td>
                                          {
                                            itempck.listPackageDetails[0]
                                              .numberQuotas
                                          }
                                        </td>
                                        <td>
                                          ${" "}
                                          {
                                            itempck.listPackageDetails[0]
                                              .initialPrice
                                          }
                                        </td>
                                        {/* <td>{itempck.duration}</td> */}
                                      </tr>
                                    ) : null;
                                  })}
                                  </>)
                                }
                               


                                {codeStatus &&
                                  packagePromotionalcode.map(
                                    (itempckPc, idx) => {
                                      if (
                                        itempckPc.familyPackageId === item.id
                                      ) {
                                        return (
                                          <tr key={idx}>
                                            <td>
                                              <Form.Check
                                                inline
                                                type="radio"
                                                name="packages"
                                                checked={idSelectType == itempckPc ? true : false}
                                                onChange={() =>
                                                  this.handleSelection(
                                                    itempckPc,
                                                    "packages"
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>{itempckPc.package.name}</td>
                                            <td>
                                              {itempckPc.package.description}
                                              <br></br>
                                              Duración:{" "}
                                              {itempckPc.monthsDuration > 1 ? (
                                                <div>
                                                  {itempckPc.monthsDuration}{" "}
                                                  meses
                                                </div>
                                              ) : (
                                                <div>
                                                  {itempckPc.monthsDuration} mes
                                                </div>
                                              )}
                                            </td>
                                            <td>$ {itempckPc.price}</td>
                                            <td>{itempckPc.numberQuotas}</td>
                                            <td>{itempckPc.initialPrice}</td>
                                          </tr>
                                        );
                                      }
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : null;
                    })}
                  </Accordion>
                </Form.Group>
              </Form.Group>
              <Form.Group controlId="formStepThree" className="mb-3">
                <Form.Label className="content-subtitle">
                 Bonos
                </Form.Label>
                <Accordion>
                  {
                    bonosFamily.map((item, index) => {
                        if( item.packages.length > 0  && item.serie != null ){
                          const series = item.serie;
                          const avance = item.serie.advance;
                          const amount = item.serie.amount;
                          const difRest = amount - avance;
                            return (
                                <Accordion.Item eventKey={item.id} key={item.id}>
                                    <Accordion.Header
                                        style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        }}
                                    >
                                      <div className="d-flex flex-row justify-content-between w-100 align-items-center"
                                        style={{paddingRight: '10px'}}
                                    >
                                        <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px',
                                                fontWeight: 'normal'
                                            }}
                                        >
                                         <span className="me-2">{item.name}</span>
                                         <span className="me-1">Fecha Inicio:</span>
                                         <span style={{fontWeight: '300'}}>{
                                                series.startDate
                                            }</span>
                                        </div>
                                        <div
                                            style={{
                                                width: 'calc(100% - 380px)'
                                            }}
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                                                <div className="d-flex">
                                                    <span className="me-2">
                                                        Avance:
                                                    </span>
                                                    <span>
                                                        ${ avance }
                                                    </span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="me-2">
                                                        ${ amount }
                                                    </span>
                                                    <span>
                                                        :Monto
                                                    </span>
                                                </div>
                                            </div>
                                            <ProgressBar 
                                                now={calcularProgress(avance, amount)} 
                                                style={{
                                                    minWidth: '100%'
                                                }}
                                            />
                                        </div>
                                        <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px',
                                                fontWeight: 'normal'
                                            }}
                                        >
                                         <span className="me-1">Fecha Fin:</span>
                                         <span style={{fontWeight: '300'}}>{
                                                series.endDate
                                            }</span>
                                        </div>
                                    </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Table
                                            striped
                                            bordered
                                            hover
                                            responsive="md"
                                            size="sm"
                                        >
                                            <thead className="table-head">
                                                <tr>
                                                    <th></th>
                                                    <th>Nombre</th>
                                                    <th>Descripción</th>
                                                    <th>Precio</th>
                                                    <th>Número de cuotas</th>
                                                    <th>Cuota inicial</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    item.packages.map((value, index) => {
                                                        if( value.estatus == 1 && value.listPackageDetails[0].price >= 3000){
                                                            return (
                                                                <tr key={value.id}>
                                                                    <td>
                                                                      <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        name="bonos"
                                                                        checked={idSelectType == value.id ? true : false}
                                                                        onChange={() =>
                                                                          this.handleSelection(
                                                                            value.id,
                                                                            "bonos"
                                                                          )
                                                                        }
                                                                        />
                                                                    </td>
                                                                    <td style={{textTransform: 'uppercase'}}>{value.name}</td>
                                                                    <td>{value.description}</td>
                                                                    <td>$ {value.listPackageDetails[0].price}</td>
                                                                    <td>{value.listPackageDetails[0].numberQuotas}</td>
                                                                    <td>$ { value.listPackageDetails[0].initialPrice }</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        }
                    })
                  }
                    
                </Accordion>
              </Form.Group>
            </LoadApi>
          </div>
        )}
        {noData && (
          <div>
            <Form.Label>{message}</Form.Label>
          </div>
        )}
      </div>
    );
  }
}
