import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class MyPurchaseView extends Component {
    render() {
        return(
            <div>
              

            </div>
        );
    }
}