import React, { Component } from 'react';
import { Form, Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

import MyPayment from '../../components/comissions/MyPayment';
import Comission from '../../components/comissions/Comission';
// import Activation from '../../components/comissions/Activation';
import Wallet from '../../components/comissions/Wallet';
import { Relations } from 'components/comissions/relations/Index';
import { Exoneration } from 'components/comissions/exoneration/Index';

export default class PayView extends Component {
    
    render(){
        return(
            <div>
                <Form.Label className="content-title">Comisiones y pagos</Form.Label>
                <Tabs className="custom-tabs-main" defaultActiveKey="comission" >
                        <Tab eventKey="comission" title="Mis comisiones">
                            <Comission></Comission>
                        </Tab>
                        <Tab eventKey="mypayment" title="Mis pagos">
                            <MyPayment></MyPayment>
                        </Tab>
                        <Tab eventKey="wallet" title="Wallet">
                            <Wallet></Wallet>
                        </Tab>
                        <Tab eventKey="Relations" title="Conciliaciones">
                            <Relations />
                        </Tab>
                        <Tab eventKey="Exoneration" title="Exoneración de renta">
                            <Exoneration />
                        </Tab>
                </Tabs>
            </div>
        );
    }
}
