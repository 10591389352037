import Validation from "components/utils/Validation";
import React from "react";
import { Table } from "react-bootstrap";

const MEProductos = ({ listMarcasExclusivas }) => {
  console.log("ver listMarcasExclusivas =>", listMarcasExclusivas);
  return (
    <section className="mt-2">
      <Table responsive size="sm">
        <thead className="table-head-comision">
          <tr>
            <th>Nombre</th>
            <th>Tipo de Comisión</th>
            <th>Nivel</th>
            <th>Fecha</th>
            <th>Puntaje</th>
            <th>Porcentaje (%)</th>
            <th>Monto</th>
            <th>Por Tipo de Monto</th>
            {/* <th>Por Nivel</th> */}
          </tr>
        </thead>
        <tbody>
          {listMarcasExclusivas.length > 0 &&
            listMarcasExclusivas.map((item, i) => {
              let amount =
                Math.round((item.amount + Number.EPSILON) * 100) / 100;
              return (
                <tr>
                  <td>
                    {" "}
                    {item.nameSlave} {item.lastNameSlave}
                  </td>
                  <td>
                    {item.typebonus &&
                      (item.typebonus == "30" ? (
                        <span>Comision ME {item.type}</span>
                      ) : (
                        <p>{item.typeBonusDescription}</p>
                      ))}
                  </td>
                  <td>{item.levelsponsor}</td>
                  <td>{Validation.formatDate(item.dateregister)}</td>
                  <td>{item.points}</td>
                  <td>{item.percents}</td>
                  <td>$ {amount}</td>
                  <td>Por Tipo de Monto</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </section>
  );
};

export default MEProductos;
