import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Svg,
  Rect,
} from "@react-pdf/renderer";

Font.register({
  family: "Arial",
  fonts: [
    { src: "/pdf/fonts/arial.ttf" },
    {
      src: "/pdf/fonts/arial-black.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {},
  body: {
    fontSize: 10,
    fontFamily: "Arial",
    paddingTop: 10,
    paddingHorizontal: 70,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 25,
    paddingHorizontal: 50,
    paddingBottom: 15,
  },
  imgHeader: {
    maxWidth: "180px",
    opacity: 0.5,
  },
  title: {
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 20,
  },
  subtitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  text: {
    textAlign: "justify",
    paddingBottom: 10,
    //lineHeight: 1.5,
  },
  titleList: {
    textAlign: "justify",
    paddingLeft: 15,
    paddingBottom: 10,
  },
  list: {
    textAlign: "justify",
    paddingLeft: 20,
    paddingBottom: 2,
  },
  image: {
    width: "100%",
    marginVertical: 15,
    marginHorizontal: 90,
  },
  selected: {
    width: 20,
    height: 15,
    backgroundColor: "black",
    borderRadius: 1,
    border: "1 solid black",
  },
  noSelected: {
    width: 20,
    height: 15,
    backgroundColor: "white",
    borderRadius: 1,
    border: "1 solid black",
  },
});

export default function SemillaPDF(props) {
  
  console.log("props SemillaPDF =>", props);
  let membershipmaintenanceletter = props.dataPDF.membershipmaintenanceletter;
  membershipmaintenanceletter = membershipmaintenanceletter.replace(
    "DOLARES",
    " "
  );
  membershipmaintenanceletter = membershipmaintenanceletter.replaceAll(
    "M.N.",
    ""
  );
  membershipmaintenanceletter = membershipmaintenanceletter.trim();

  const nacionalidadUsuario = sessionStorage.getItem("info")
      ? JSON.parse(sessionStorage.getItem("info")).idResidenceCountry
      : "";

  return (
    
    <Document>
      <Page wrap size="A4">
        {/*   1 */}
        <View style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.title}>
              CONTRATO DE TRANSFERENCIA DE ACCIONES SEMILLA
            </Text>

            <Text style={styles.text}>
              Conste por el presente documento el Contrato de transferencia de
              acciones Semilla, que celebran de una parte{" "}
              <Text style={{ fontWeight: "bold" }}>VALLE ENCANTADO S.A.C.</Text>
              , identificada con RUC N° 20601460271, constituida y regida bajo
              las leyes de la República del Perú, con domicilio legal en Mz.{" "}
              {"<<B>>"} Lote 72, Tercera Etapa Cieneguilla, Provincia de Lima y
              Departamento de Lima, debidamente representada por El(La) Señor(a) Omar
              Fernando Urteaga Cabrera , identificado con D.N.I. N° 41958311; a
              quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“VALLE ENCANTADO”</Text>; y
              de otra parte El(La) Señor(a) {props.dataPDF.user.name.toUpperCase()}{" "}
              {props.dataPDF.user.lastname.toUpperCase()}, de nacionalidad{" "}
              {props.dataPDF.user.courtesy}, identificado con

              {(() => {
              switch (nacionalidadUsuario) {
                case 45:
                  return (
                    <>
                     'CEDULA'
                    </>
                  );
                case 167:
                  return (
                    <>
                      'D.N.I'
                    </>
                  );
                default:
                  return (
                    <>
                      'D.N.I'
                    </>
                  );
              }
            })()}

         {/*      {localStorage.getItem("idPais") == 167
                ? "D.N.I"
                : " CEDULA "}{" "}
 */}

              {" "}{props.dataPDF.user.nroDocument}, con domicilio en{" "}
              {props.dataPDF.user.address}, a quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“EL COMPRADOR”</Text>; en los
              términos contenidos en las cláusulas siguientes:
            </Text>

            <View style={styles.text}>
              <Text style={styles.subtitle}>PRIMERO: ANTECEDENTES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.1{"  "}VALLE ENCANTADO, empresa encargada de gestionar la
                  venta de acciones de RIBERA DEL
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  RIO CLUB RESORT S.A, identificada con RUC N° 20608720911,
                  titular de 600,000,000 de acciones ordinarias, que se componen
                  por acciones Clase C: acciones con derecho a voto y sin
                  derecho a usufructo y Clase B: acciones sin derecho a voto con
                  derecho a usufructo, debidamente inscritas en la Partida
                  Electrónica N° 14809461 del Registro de Personas Jurídicas de
                  Registro de Personas Jurídicas de Lima.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.2{"  "}VALLE ENCANTADO deja constancia que las acciones
                  referidas en la cláusula anterior se
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  encuentran libres de cargas o gravámenes.
                </Text>
              </View>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>SEGUNDO: OBJETO DEL CONTRATO</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.1.{"  "}Por el presente contrato, VALLE ENCANTADO se obliga
                  a transferir la titularidad de{" "}
                  {
                    props.dataPDF.packagesavailable.find(
                      (e) => e.package.name === props.dataPDF.userPackage
                    ).numberShares
                  }
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  acciones Clase C, correspondientes a RIBERA DEL RIO CLUB
                  RESORT S.A, a favor de EL COMPRADOR, por la adquisición del
                  Paquete de acciones:
                  {props.dataPDF.userPackage.toUpperCase()} conforme al
                  siguiente detalle:
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 20,
                  paddingTop: 10,
                  marginLeft: 10,
                }}
              >
                {props.dataPDF.packagesavailable.map((e, index) => {
                  if (e.package.name === props.dataPDF.userPackage) {
                    return (
                      <View
                        key={index}
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text>{e.package.name.substring(0, 7)}</Text>
                        <Text> {e.package.name.substring(7, 11)}</Text>
                        <View style={styles.selected}></View>
                      </View>
                    );
                  } else {
                    return (
                      <View
                        key={index}
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text>{e.package.name.substring(0, 7)}</Text>
                        <Text> {e.package.name.substring(7, 11)}</Text>
                        <View style={styles.noSelected}></View>
                      </View>
                    );
                  }
                })}
              </View>

              <Text style={[styles.text, { marginLeft: 16.5 }]}>
                Las acciones Clase C, corresponden a ser acciones con derecho a
                voto y sin derecho a usufructo, con sujeción al “Código de Ética
                y Conducta” – Anexo 1.
              </Text>
              <Text style={[styles.text, { marginLeft: 16.5 }]}>
                Por su parte, EL COMPRADOR se obliga a pagar a VALLE ENCANTADO
                el monto total del precio pactado en la cláusula tercera, en la
                forma y oportunidad convenidas, en adelante el PRECIO.
              </Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.2. La transferencia de acciones que es objeto del contrato,
                  incluye todos los derechos, prerrogativas y obligaciones
                  inherentes a ellas, actuales y futuros que posea RIBERA DEL
                  RIO CLUB RESORT S.A.
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* 2 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <Text style={styles.subtitle}>TERCERO: PRECIO Y FORMA DE PAGO</Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.1.{"  "}El PRECIO del Paquete de acciones{" "}
                {props.dataPDF.userPackage.toUpperCase()}, asciende a la suma de{" "}
                {`$${props.dataPDF.price}`},
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                US. {props.dataPDF.pricetotalLetter.replaceAll("M.N.", "")}, el
                cual le otorga a EL COMPRADOR el número de acciones y beneficios
                mencionados en el numeral 2.1 del presente contrato.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.2.{"  "}Las partes acuerdan que el pago del PRECIO se
                efectuará en dólares de los Estados Unidos de
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                América, al amparo de lo dispuesto por el Artículo 1237 del
                Código Civil.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.3.{"  "}EL PRECIO, será pagado en una sola cuota de{" "}
                {`$${props.dataPDF.price}`}, que podrán ser canceladas en las
                siguientes cuentas bancarias a elección de EL COMPRADOR:
              </Text>
            </View>
            <Image
              style={[{ width: "100%", height: "105px", paddingBottom: 10 }]}
              src="/pdf/inresorts/precios.png"
            />
            <Text
              style={{
                textAlign: "justify",
                marginLeft: 20,
                paddingBottom: 10,
              }}
            >
              En caso de cambio o incrementos de cuentas bancarias, VALLE
              ENCANTADO informará oportunamente a través del correo registrado
              de EL COMPRADOR, dentro de los primeros 7 (siete) días del cambio.
            </Text>

            <Text style={styles.subtitle}>
              CUARTO: OBLIGACIONES DE LAS PARTES
            </Text>

            <Text style={styles.text}>
              4.1. VALLE ENCANTADO tiene las siguientes obligaciones:
            </Text>
            <Text style={styles.titleList}>- Acciones:</Text>

            <View style={styles.text}>
              <Text style={styles.list}>
                • VALLE ENCANTADO se obliga a transferir las acciones materia de
                este contrato a EL COMPRADOR, libres de gravámenes, cargas y
                cualquier obligación que limite o restrinja su dominio o libre
                disposición, las cuales tienen derecho a usufructo en beneficio
                del socio o accionista.
              </Text>
              <Text style={styles.list}>
                • Suscribir todos los documentos o contratos necesarios para
                perfeccionar la transferencia
              </Text>
            </View>

            <Text style={styles.text}>
              4.2. EL COMPRADOR tiene las siguientes obligaciones:
            </Text>
            <Text style={styles.titleList}>- Acciones:</Text>

            <View style={styles.text}>
              <Text style={styles.list}>
                • EL COMPRADOR se obliga a efectuar el pago en el momento y
                forma pactados conforme a lo dispuesto en la cláusula tercera de
                este documento. En caso contrario, EL COMPRADOR recaerá en
                incumplimiento según lo dispuesto en la cláusula sexta.
              </Text>
              <Text style={styles.list}>
                • Suscribir los documentos y contratos que sean necesarios para
                perfeccionar la transferencia de las acciones adquiridas.
              </Text>
            </View>
          </View>
        </View>
        {/* 3 */}

        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <Text style={styles.subtitle}>QUINTO: DERECHOS DE LAS PARTES</Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                5.1 VALLE ENCANTADO tiene derecho a percibir el pago que EL
                COMPRADOR deberá efectuar según lo dispuesto en la cláusula
                tercera.
              </Text>
              <Text style={{ textAlign: "justify" }}>
                5.2. EL COMPRADOR tiene los siguientes derechos:
              </Text>
            </View>

            <Text style={[styles.text, { paddingLeft: 10 }]}>- Acciones</Text>

            <View style={styles.text}>
              <Text style={styles.list}>
                •En cuanto EL COMPRADOR, tendrá derecho a recibir un determinado
                número de acciones libres de gravámenes, cargas y cualquier
                obligación que limite o restrinja su dominio o libre
                disposición.
              </Text>
              <Text style={styles.list}>
                • El cumplimiento del pago harán acreedor a EL COMPRADOR de un
                Certificado de Acciones Clase C, de acuerdo a su Paquete de
                acciones adquirido.
              </Text>
              <Text style={styles.list}>
                • La adquisición de acciones NO otorga el derecho a EL COMPRADOR
                a usufructuar el Hotel Club Resort.
              </Text>
              <Text style={styles.list}>
                • Las acciones Clase C corresponden a ser acciones con derecho a
                voto y sin derecho a usufructo, estas confieren a su titular la
                calidad de accionista y le atribuye, entre ellos, los siguientes
                derechos:
              </Text>

              <View
                style={{ marginLeft: 10, paddingTop: 15, paddingBottom: 15 }}
              >
                <Text style={styles.list}>
                  1. Participar en el reparto de utilidades y del patrimonio
                  neto resultante de la liquidación.
                </Text>
                <Text style={styles.list}>
                  2. Posibilidad de participar en el Directorio, a través de un
                  representante elegido por todos los accionistas semilla del
                  Sistema Red.
                </Text>
                <Text style={styles.list}>
                  3. Ser informado cuando menos semestralmente de las
                  actividades y gestión de la sociedad.
                </Text>
                <Text style={styles.list}>
                  Asimismo, le confiere a EL COMPRADOR los demás derechos
                  establecidos en el Estatuto de RIBERA DEL RIO CLUB RESORT
                  S.A., conforme a la Ley General de Sociedades.
                </Text>
              </View>
              <Text style={styles.list}>
                • La entrega del Certificado de Acciones Vitalicias, se
                realizará a la fecha del cumplimiento del pago total de las
                acciones. Dichos certificados, serán legalizados Notarialmente.
              </Text>
              <Text style={styles.list}>
                • EL COMPRADOR tiene derecho a adquirir nuevas acciones,
                teniendo en consideración las siguientes condiciones:
              </Text>

              <View
                style={{ paddingLeft: 20, paddingTop: 15, paddingBottom: 15 }}
              >
                <Text>
                  o El precio de las acciones corresponderá al valor de mercado
                  vigente en el momento de la compra.
                </Text>
                <Text>
                  o EL COMPRADOR al optar por la compra de nuevas acciones,
                  acepta las condiciones y normas vigentes a dicha fecha.
                </Text>
              </View>

              <Text style={styles.subtitle}>SEXTO: INCUMPLIMIENTO</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  6.1. El COMPRADOR renuncia irrevocablemente a invocar hechos
                  de terceros o causas no imputables a él, en caso de
                  incumplimiento de cualesquiera de las obligaciones asumidas en
                  el CONTRATO.
                </Text>
              </View>

              <Text style={styles.subtitle}>
                SETIMO: PAGO DE TRÁMITES LEGALES
              </Text>

              <Text
                style={{
                  textAlign: "justify",
                  marginLeft: 20,
                  paddingBottom: 10,
                }}
              >
                Las partes establecen que los gastos notariales y registrales
                correspondiente a la transferencia de acciones, queda a cargo de
                EL COMPRADOR.
              </Text>
            </View>
          </View>

          {/*  */}
        </View>

        {/* 4 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <Text style={styles.subtitle}>
              OCTAVO: DOMICILIO Y NOTIFICACIONES LEGALES
            </Text>
            <Text style={{ textAlign: "justify" }}>
              Las partes señalan como sus domicilios los consignados en la
              introducción del presente contrato. Cualquier comunicación o
              notificación deberá ser cursada a dichos domicilios por escrito,
              con cargo de recepción. En caso de cambio de domicilio, para su
              efecto o validez legal deberá comunicarse a la otra parte, con
              cargo de recepción y a la brevedad posible. En tanto no recepcione
              la otra parte la citada comunicación se entenderán válidamente
              efectuadas las comunicaciones remitidas al anterior domicilio y
              surtirán todos sus efectos legales.
            </Text>
          </View>

          <View style={styles.body}>
            <Text style={styles.subtitle}>NOVENO: LEGISLACION APLICABLE </Text>
            <Text style={{ textAlign: "justify" }}>
              El contrato se regirá por la Legislación Peruana. En todo lo no
              previsto por las partes en el presente contrato, ambas se someten
              a lo establecido por las normas del Código Civil, la Ley General
              de Sociedades, los principios de buena fe contractuales y demás
              del sistema jurídico que resulten aplicables.
            </Text>
          </View>

          <View style={styles.body}>
            <Text style={styles.subtitle}>
              DÉCIMO: SOLUCIÓN DE CONTROVERSIAS{" "}
            </Text>
            <Text style={{ textAlign: "justify" }}>
              Queda expresamente establecido, que, ante toda divergencia o
              reclamo vinculados con la celebración, ejecución y/o
              interpretación del presente Contrato, las partes se someten
              expresamente a la jurisdicción de los jueces y tribunales del
              distrito judicial del Cercado de Lima, renunciando al fuero de sus
              domicilios.
            </Text>
            <Text style={{ textAlign: "justify" }}>
              En señal de conformidad, las partes suscriben este documento en
              dos (2) ejemplares de igual tenor y valor, en la ciudad de Lima,{" "}
              {props.dataPDF.creationDate}.
            </Text>
          </View>

          <View style={styles.body}>
            <View
              style={{
                paddingTop: 50,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <View style={{ flexDirection: "column", alignItems: "center" }}>
                <Image
                  src="/pdf/inresorts/firmaOmarUrteaga.jpg"
                  style={{ width: "100px", height: "50px" }}
                />
                <Text>____________________________</Text>
                <Text>VALLE ENCANTADO S.A.C.</Text>
                <Text>Omar Fernando Urteaga Cabrera</Text>
                <Text>Gerente General</Text>
                <Text>VALLE ENCANTADO</Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: 10,
                }}
              >
                <Text>____________________________</Text>
                <Text>{`${props.dataPDF.user.name} ${props.dataPDF.user.lastname}`}</Text>
                <Text>
                  Documento de identidad N°{props.dataPDF.user.nroDocument}
                </Text>
                <Text>EL COMPRADOR</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
