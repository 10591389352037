import React, { Component } from "react";
import { Form, Row, Col, Button, Table, Spinner, Alert } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";

export default class PayPagoEfectivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: "PEN",
      tasa: 0,
      comsion: 0,
      total: 0,
      amount: 0,
      loading: false,
      codeCIP: "",
      typePays: [
        {
          dolares: 0,
          porcentajeTasa: 0,
          soles: 0,
        },
      ],
    };
  }
  componentDidMount() {}

  // Handle exchange
  handleExchange = (e) => {
    let value = e.target.value;
    // 'USD -> dolar
    // 2 -> soles
    if (value === "USD") {
      let comision = this.state.typePays[0].dolares;
      // Values default monen is dolars
      let temp = this.state.typePays[0].dolares + this.state.mount;
      let total = temp / (1 - this.state.typePays[0].porcentajeTasa / 100);
      total = Math.round((total + Number.EPSILON) * 100) / 100;

      let tasa = (total * this.state.typePays[0].porcentajeTasa) / 100;
      //round
      tasa = Math.round((tasa + Number.EPSILON) * 100) / 100;

      this.setState({
        currency: (this.state.currency = value),
        typeExchangeDes: "Dólares",
        type_code: "USD",
        comision: (this.state.comision = comision),
        tasa: tasa,
        total: total,
        showButtons: true,
      });
    } else if (value === "PEN") {
      let comision = this.state.typePays[0].soles;
      let total = this.state.mount * this.state.exchange + comision;
      this.setState({
        currency: (this.state.currency = value),
        typeExchangeDes: "Soles",
        type_code: "PEN",
        comision: (this.state.comision = comision),
        total: (this.state.total = total),
        tasa: 0,
        showButtons: true,
      });
    }
  };

  generateCip = () => {
    this.setState({ loading: true, codeCIP: "d" });
    this.setState({ loading: false, codeCIP: "d" });
  };
  render() {
    const {
      currency,
      typeExchange,
      tasa,
      comision,
      total,
      loading,
      codeCIP,
    } = this.state;
    return (
      <div>
        <Form>
          <Row>
            <Col sm={12}>
              <Form.Label>Tipo de moneda</Form.Label>
              <Form.Control
                as="select"
                style={{ fontSize: 12 }}
                defaultValue={currency}
                onChange={(e) => this.handleExchange(e)}
              >
                <option value={"PEN"}>PEN(Soles)</option>
                <option value={"USD"}>USD(Dolares</option>
              </Form.Control>
              <Row>
                {comision > 0 && (
                  <Col sm={12}>
                    <Form.Label className="textAlert" style={{ fontSize: 10 }}>
                      * Los pagos a través de este medio tienen una COMISIÓN.
                    </Form.Label>
                  </Col>
                )}
              </Row>
              <hr />
              {/* Dolares */}
              {currency === "USD" && (
                <>
                <Row style={{ paddingTop: 15, fontSize:12 }}>
                  <Col sm={4}>
                    <Form.Label>
                      <b>Monto $:</b> {this.state.amount}
                    </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>
                      <b>Tasa $ :</b> {tasa}
                    </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>
                      <b>Comisión $ :</b> {comision}
                    </Form.Label>
                  </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, fontSize:13 }}>
                  <Col sm={12}>
                    <Form.Label>
                      <b>TOTAL a pagar $ :</b> {total}
                    </Form.Label>
                  </Col>
                </Row>
                </>
              )}
              {/* Soles */}
              {currency === "PEN" && (
                <>
                  <Row style={{ paddingTop: 15, fontSize:12 }}>
                    <Col sm={12}>
                      <Form.Label>
                        <b>Tipo de Cambio:</b> {this.state.exchange}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, fontSize:12  }}>
                    <Col sm={3}>
                      <Form.Label>
                        <b>Monto S/ :</b>{" "}
                        {this.state.amount * this.state.exchange}
                      </Form.Label>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>
                        <b>Tasa S/ :</b> {tasa}
                      </Form.Label>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>
                        <b>Comisión S/ :</b> {comision}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, fontSize:13 }}>
                    <Col sm={12}>
                      <Form.Label>
                        <b>TOTAL a pagar S/ :</b> {total}
                      </Form.Label>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ textAlign: " center", paddingTop: "30px" }}>
              <Button
                variant="secondary"
                disabled={loading || codeCIP.length > 0}
                onClick={(e) => {
                  this.generateCip();
                }}
              >
                Generar código de pago
              </Button>
            </Col>
          </Row>
          <Row style={{ textAlign: "center", paddingTop: "30px" }}>
            <Col sm={12}>
              {loading && (
                <div>
                  <Spinner animation="border" role="status"></Spinner>
                  <p>Generando codigo ...</p>
                </div>
              )}
            </Col>
          </Row>
          {!loading && codeCIP.length > 0 && (
            <>
              <Row>
                <Col sm={12}>
                  <Form.Label>
                    Realice el pago en cualquier agente con el siguiente codigo
                    de pago:{" "}
                  </Form.Label>
                </Col>
              </Row>
              <Row style={{ textAlign: "center", paddingTop: "30px" }}>
                <Col sm={12}>
                  <Alert key={1} variant="success">
                    Codigo de pago: {codeCIP}
                  </Alert>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </div>
    );
  }
}
