import axios from "axios";
import KCardInfo from "components/KCardInfo";
import KFloat from "components/KFloat";
import KHeader from "components/KHeader";
import ModalContent from "components/ModalContent";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import Factura from "views/MEKeolaShopDelivery/ModalFactura/Factura";
import "./MEKeolaShopPayment.css";
/* Trae ni idea pero es un numero*/
// https://api.keola.club:2083/api/Orders/getbyuser/13558
/* trae la informacion del usuario */
const urlUser = "https://api.keola.club:2083/api/User/15709";
/*Trae la info de dirección de la tienda */
const urlMaps =
  "https://maps.googleapis.com/maps/api/directions/json?origin=-12.048709869384766,-77.04287719726562&waypoints=&destination=-12.1172221,-77.0206521&key=AIzaSyDgaon21FSC2AynLJiYkgDn0lyVjp-75PQ&mode=driving&language=es&region=undefined";
// 2. envia los datos del pedido
const url_order =
  "https://api-dev.keola.club:2083/api/Orders/InsertShoppingCart/BackOffice";
// 1. Envia los datos al socket
const url_paid_order =
  "https://api.keola.club:2087/api/KdriversOrders/paidOrder";
// calculator
/* const urlCalCost =
  "https://servicios.inclubtest.online:2053/api/commissionME/getDiscount/"; */
  
  //el de abajo es produccion
const urlCalCost =
  "https://backoffice.keola.club/api/commissionME/getDiscount/";
const MEKeolaShopPayment = () => {
  const history = useHistory();
  const [form, setForm] = useState(null);
  const [cash, setCash] = useState(0);
  const [paymentType, setPaymentType] = useState(0);
  const [MEfectivo, setMEfectivo] = useState(false);
  const [MPayment, setMPayment] = useState(false);
  const [MLoadingPayment, setMLoadingPayment] = useState(false);
  const [user, setUser] = useState(null);
  const [total, setTotal] = useState(0);
  const [getDiscount, setGetDiscount] = useState(null);
const [discountAmountPurchase, setdiscountAmountPurchase] = useState(null)

const [partnerPercentage, setPartnerPercentage] = useState(null)



  const { mekproducts, cart, deliveryType, scheduleSelect } = useSelector(
    (state) => state.shop
  );

console.log('ver cart =>',cart);
const sumatoriaDeProductosPrecioOriginal = cart.reduce((suma,item) => suma + item.price.originalPrice,0)
const conPorcentaje = Number(sumatoriaDeProductosPrecioOriginal*partnerPercentage/100).toFixed(2)
console.log('ver sumatoriaDeProductosPrecioOriginal =>',conPorcentaje)
 // const [partnerDiscount, setPartnerDiscount] = useState(conPorcentaje)

  const [modalFactura, setModalFactura] = useState(false);
  // Modals functions
  const handleCloseMEfe = () => {
    setMEfectivo(false);
  };
  const handleOpenMEfe = () => {
    setMEfectivo(true);
  };
  const handleCloseMPay = () => {
    setMPayment(false);
  };
  const handleOpenMPay = () => {
    if (!cash > 0) {
      Swal.fire({
        title: "Error",
        text: "Debe ingresar un monto",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    // if (cash < cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0)) {
    if (cash < total) {
      Swal.fire({
        title: "Error",
        text: "El monto a pagar no puede ser menor al total de la compra",
        icon: "error",
        confirmButtonText: "Ok",
      });

      return;
    }
    if (paymentType === 0) {
      Swal.fire({
        title: "Error",
        text: "Debe seleccionar una forma de pago",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    setMPayment(true);
  };
  const toggleLoadingPayment = (x) => {
    setMLoadingPayment(x);
  };
  // const getDirection = async () => {
  //     // get urlMaps in promise
  //     // strict-origin-when-cross-origin
  //     await fetch(urlMaps,
  //         {
  //             method: 'GET',
  //         })
  //         .then(response => response.json())
  //         .then(res => {
  //             console.log(res)

  //         })
  // }
  const getUser = async () => {
    await axios
      .get(urlUser)
      .then((response) => {
        setUser(response.data.objModel);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCalculatorCost = async () => {
    await axios
      .get(
        `${urlCalCost}${cart.reduce(
          (a, b) => a + b.price?.backOfficePrice * b.quantity,
          0
        )}`
      )
      .then((response) => {
        console.log('getDiscount =>',response.data.objModel)
        setdiscountAmountPurchase((response.data.objModel.discountAmount).toFixed(2))
        setPartnerPercentage(response.data.objModel.percentage)
        setGetDiscount(response.data.objModel);
        setTotal(response.data.objModel.totalAmount);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // getDirection()
    getUser();
    getCalculatorCost();
  }, []);

  if (cart.length === 0) {
    return <Redirect to="/home" />;
  }
  if (deliveryType === 0) {
    return <Redirect to="/kshop/delivery" />;
  }

  //esta es la funcion para pagar
  const postOrder = async(orderPayment) => {
    setMPayment(false);
    //console.log('discountAmountPurchase =>',Number(discountAmountPurchase))
    console.log('conPorcentaje ===========>',conPorcentaje);
    const idSocio = await sessionStorage.getItem("id");
    const infoUsuario = await JSON.parse(sessionStorage.getItem("info"));
   // console.log('nombre =>',infoUsuario.name)
    //console.log('correo =>',infoUsuario.email)
    const today = new Date();
    today.setMinutes(today.getMinutes() + 30);
    const formData = {
      cash: Number(cash) ?? 0,
      deliveryCost: 0, //deliveryCost,
      ordersDetailToppings: null,
      idMethodPayment: 4, //wallet o cash
      idCurrency: 16,
      idUserClient: Number(user.id),
      idKdriverUsers: 0,
      idOrderType: scheduleSelect ? 2 : 1,
      tip: 0,
      idDeliveryType: deliveryType,
      discountAmountPurchase: Number(discountAmountPurchase),
      partnerDiscount:Number(conPorcentaje),
      partnerPercentage:Number(partnerPercentage),
      ///////////////////////////////////////////////////////////////////////
      userClientName:infoUsuario.name,
      userClientEmail:infoUsuario.email.trim(),
      ordersLocation: {
        home: "772",
        street: "Avenida Alfonso Ugarte",
        city: "Cercado de Lima",
        region: "Provincia de Lima",
        country: "Perú",
        country_code: "PE",
        postal_code: "15082",
        formatted_address:
          "Avenida Alfonso Ugarte 772, Cercado de Lima, Provincia de Lima, Perú",
        latitude: -12.048709869384766,
        longitude: -77.04287719726562,
      },
      idOrderStatus: 1, //init status order
      idWalletUser: 11438, //idWalletUser no encuentra wallet,
      shoppingCart: cart,
      // change: Number((cash - cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0)).toFixed(2)) ?? 0,
      change: Number((cash - total).toFixed(2)) ?? 0,
      toDeliver: scheduleSelect
        ? `${scheduleSelect.year}-${scheduleSelect.n_mount}-${scheduleSelect.n_day}T${scheduleSelect.finalHour}:00Z`
        : today,
      deliveryTime: today,
      idTransportationType: 0,
      idOwnWorker: 0,
      // total: `${cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0)}`,
      total: `${total}`,
      registerDate: new Date(),
      utcDate: new Date(),
      //business: null,
      utcRegisterDate: new Date(),
      orderPayment: null,
      idTransportationType: 0,
      discountCoupon: 0,
      discount: 0,
      discountPromotion: 0,
      business: form ? form : null,
    };
    /*   {
        idDocument: 0,
        document: "document",
        businessName: "businessName",
        industry: "industry",
        address: "address",
        email: "email",
        phone: "phone",
        nameLegalRepresent: "nameLegalRepresent",
        phoneLegalRepresent: "phoneLegalRepresent",
        idCountry: 333
        }, */
    toggleLoadingPayment(true);
    console.log('haber data que se va a enviar =>',formData)
     setTimeout(async () => {
      try {
        const username = infoUsuario.username;
        console.log(idSocio, 'idSocio');
        console.log(username, 'username');
        
        //console.log(formData, 'form data orden');
        
        const res = await axios.post(
          `${url_order}/${idSocio}/${username}`,
          formData
        );
        toggleLoadingPayment(false);
        console.log('resp orden', res.data)
        window.location.href = `/kshop/orderdetail/${res.data.objModel}`;
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Error al realizar la compra",
          icon: "error",
          confirmButtonText: "Ok",
        });
        // toggleLoadingPayment(false);
      }
    }, 3000); 
  };
  const abrirModalFactura = () => {
    setModalFactura(true);
  };
  return (
    <>
      {cart.length === 0 ? (
        <Redirect to="/home" />
      ) : (
        <main className="shopkeola-main container ">
          <hr />
          <KHeader title="Confirmar y pagar" />
          <br />
          <div className="payment-main">
            <KCardInfo title="Dirección de la tienda" extra="">
              <h5 className="text-center">Jr. Inca 312.Surquillo</h5>
            </KCardInfo>
            {scheduleSelect ? (
              <KCardInfo
                title="Entrega programada"
                extra={`
                                            ${scheduleSelect.nameday}
                                            ${moment(
                                              `${scheduleSelect.year}-${scheduleSelect.n_mount}-${scheduleSelect.n_day}T${scheduleSelect.startTime}`
                                            ).format("DD/MM hh:mm A")}
                                            a 
                                            ${
                                              scheduleSelect.finalHour?.split(
                                                ":"
                                              )[0] > 12
                                                ? `${scheduleSelect.finalHour?.split(
                                                    ":"
                                                  )[0] - 12}:${
                                                    scheduleSelect.finalHour?.split(
                                                      ":"
                                                    )[1]
                                                  } PM`
                                                : `${scheduleSelect.finalHour} AM`
                                            }
                                            `}
              ></KCardInfo>
            ) : null}
            <br />
            <KCardInfo title="Pedido" extra="">
              <div className="kpedido">
                {console.log('ver cart ====>',cart)}
                {cart.map((item, index) => (
                  <div className="info">
                    <p>
                      {item.name}
                      <span> ( {item.quantity} )</span>
                    </p>
                    <span>
                      <p>
                        S/.{" "}
                        {(item.price?.originalPrice * item.quantity).toFixed(2)} 
                      </p>
                      <p>
                        S/.{" "}
                        {(item.price?.backOfficePrice * item.quantity).toFixed(
                          2
                        )}
                      </p>
                    </span>
                  </div>
                ))}
                <hr />
                <div className="info">
                  <p>Comisión de primera linea:</p>
                  <span>{getDiscount?.percentage ?? 0}%</span>
                </div>
                <div className="info">
                  <p>Descuento por monto de compras</p>
                  <span>
                    - S/. {getDiscount?.discountAmount?.toFixed(2) ?? 0}
                  </span>
                </div>

                <div className="total">
                  <p>Total</p>
                  <span>
                    {/* S/. {cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0)} */}
                    S/. {total?.toFixed(2)}
                  </span>
                </div>
              </div>
            </KCardInfo>
            <br />
            <div className="row">
              <div className="col-6 col-md-6">
                {" "}
                <h4 className="">Elige tu medio de pago </h4>
              </div>
              <div className="col-6 col-md-6">
                {" "}
                <Button
                  variant="link"
                  style={{ color: "orange", textDecoration: "none" }}
                  onClick={() => abrirModalFactura()}
                >
                  ¿Necesitas factura?
                </Button>
              </div>
            </div>

            <br />
            <KCardInfo title="Pago en efectivo" extra="">
              <div className="kpefec">
                <div className="checkefe">
                  <input
                    type="radio"
                    name="4"
                    id="efectivo"
                    onChange={(e) => {
                      setPaymentType(4);
                    }}
                    defaultValue={4}
                  />
                  <label htmlFor="4">
                    <span>&nbsp; Medio de pago: Efectivo: ({cash})</span>
                  </label>
                </div>
                <div className="checkefe-cash" onClick={handleOpenMEfe}>
                  <p>Necesitará cambio de</p>
                  <p>
                    S/.
                    <span>{cash}</span>
                  </p>
                </div>
              </div>
            </KCardInfo>
          </div>
          <KFloat onClick={handleOpenMPay} name="Confirmar pedido y pagar" />
          <ModalContent
            isOpen={MEfectivo}
            title="¿Con cuanto vas a pagar?"
            handleClose={() => {
              setCash(0);
              handleCloseMEfe();
            }}
          >
            <div className="efectpay">
              <label htmlFor="">Voy a pagar con:</label>
              <input
                type="text"
                name="cash"
                id="cash"
                value={cash}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    setCash(0);
                  } else {
                    if (e.target.value.split("")[0] === "0") {
                      setCash(e.target.value.split("")[1]);
                    } else {
                      setCash(e.target.value);
                    }
                    // setCash(e.target.value)
                  }
                }}
              />
              <label htmlFor="">Recibire</label>
              <input
                type="text"
                disabled
                // cambio de dinero
                // value={cash && (cash - cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0)).toFixed(2)}
                value={cash && (cash - total).toFixed(2)}
              />
              <button
                onClick={() => {
                  // if (!cash || cash - cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0) < 0) {
                  if (!cash || cash - total < 0) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Por favor ingrese una cantidad válida",
                    });
                    return;
                  } else if (cash?.split("")[0] === "0") {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Por favor ingrese una cantidad válida",
                    });
                    return;
                  } else {
                    handleCloseMEfe();
                  }
                }}
              >
                Aceptar
              </button>
            </div>
          </ModalContent>

          {/*     MODAL PARA CONFIRMAR PAGO */}
          <ModalContent
            isOpen={MPayment}
            title="Realizar pedido"
            handleClose={handleCloseMPay}
          >
            <div className="confirmPayment">
              <p>¿Estás seguro de realizar el pedido?</p>
              <button
                onClick={() => {
                  postOrder();
                }}
              >
                Pagar
              </button>
              <button
                onClick={() => {
                  handleCloseMPay();
                  history.push("/kshop/cart");
                }}
              >
                Volver a revisar mi pedido
              </button>
            </div>
          </ModalContent>
          <ModalContent
            isOpen={MLoadingPayment}
            title="Realizando Pedido"
            handleClose={() => {}}
          >
            loading...
          </ModalContent>
          <Factura
            modalFactura={modalFactura}
            setModalFactura={setModalFactura}
            setForm={setForm}
          />
        </main>
      )}
    </>
  );
};

export default MEKeolaShopPayment;
