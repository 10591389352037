import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
    Image,
    Svg,
    Rect,
  } from "@react-pdf/renderer";
  
  Font.register({
    family: "Arial",
    fonts: [
      { src: "/pdf/fonts/arial.ttf" },
      {
        src: "/pdf/fonts/arial-black.ttf",
        fontWeight: 700,
      },
    ],
  });
  
  const styles = StyleSheet.create({
    page: {},
    body: {
      fontSize: 10,
      fontFamily: "Arial",
      paddingTop: 10,
      paddingHorizontal: 70,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: 25,
      paddingHorizontal: 50,
      paddingBottom: 15,
    },
    imgHeader: {
      maxWidth: "180px",
      opacity: 0.5,
    },
    title: {
      textAlign: "center",
      textDecoration: "underline",
      fontWeight: "bold",
      paddingBottom: 20,
    },
    subtitle: {
      textDecoration: "underline",
      fontWeight: "bold",
      paddingBottom: 10,
    },
    text: {
      textAlign: "justify",
      paddingBottom: 10,
      //lineHeight: 1.5,
    },
    titleList: {
      textAlign: "justify",
      paddingLeft: 15,
      paddingBottom: 10,
    },
    list: {
      textAlign: "justify",
      paddingLeft: 20,
      paddingBottom: 2,
    },
    image: {
      width: "100%",
      marginVertical: 15,
      marginHorizontal: 90,
    },
    selected: {
      width: 20,
      height: 15,
      backgroundColor: "black",
      borderRadius: 1,
      border: "1 solid black",
    },
    noSelected: {
      width: 20,
      height: 15,
      backgroundColor: "white",
      borderRadius: 1,
      border: "1 solid black",
    },
});

const ContratoBonosPDF = (props) => {
    console.log("props ContratoBonosPDF =>", props);
    
    return (
      <Document>
        <Page wrap size="A4">
           {/*1 */}
          <View style={styles.page}>
            <View style={styles.header}>
              <Image
                style={[styles.imgHeader, { height: "80px" }]}
                src="/pdf/inresorts/1.png"
              />
              <Image
                style={[styles.imgHeader, { height: "50px" }]}
                src="/pdf/inresorts/2.png"
              />
              <Image
                style={[styles.imgHeader, { height: "50px" }]}
                src="/pdf/inresorts/3.png"
              />
            </View>
            <View style={styles.body}>
              <Text style={styles.title}>
                CONTRATO DE RESERVA DE ADQUISICIÓN DE BONOS
              </Text>

              <Text style={styles.text}>
                Conste por el presente Contrato de Reserva de adquisición de bonos, que celebran de una parte <Text style={{ fontWeight: "bold" }}>VALLE ENCANTADO S.A.C.</Text>, identificado con RUC N° 20601460271, constituida y regida bajo las leyes de la República del Perú, con domicilio legal en Mz. {"<<B>>"} Lote 72, Tercera Etapa Cieneguilla, Provincia de Lima y Departamento de Lima, debidamente representada por el Sr. Omar Fernando Urteaga Cabrera, identificado con D.N.I. N° 41958311; a quien en lo sucesivo se denominará “EL EMISOR”; y de otra parte el(la) Sr(a). {props.dataPDF.user.name.toUpperCase()}{" "}
                {props.dataPDF.user.lastname.toUpperCase()}, de nacionalidad{" "}
                {props.dataPDF.user.courtesy}, identificado con D.N.I. N°{" "}
                {props.dataPDF.user.nroDocument}, con domicilio en{" "}
                {props.dataPDF.user.address}, a quien en lo sucesivo se denominará{" "}
                <Text style={{ fontWeight: "bold" }}>“EL TENEDOR FUTURO”</Text>; en los
                términos contenidos en las cláusulas siguientes:
              </Text>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA PRIMERA: ANTECEDENTES</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    1.{"  "}El emisor es una persona jurídica identificada con RUC N° 20601460271, inscrita en la Partida Electrónica N° 13685312 y regida bajo las leyes de la República de Perú, con un plazo de duración indefinido.
                  </Text>
                </View>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    2.{"  "}EL EMISOR aprobó la emisión futura de bonos denominado { props.dataPDF.nameFamilyPackage.toUpperCase() }, hasta por un monto de <Text style={{ fontWeight: "bold" }}>${ props.dataPDF.price }</Text> bonos { props.dataPDF.nameFamilyPackage.toUpperCase() }, ascendente a { props.dataPDF.desiredAmount } ({ props.dataPDF.desiredAmountletter }), valorizado en <Text style={{ fontWeight: "bold" }}>${ props.dataPDF.price }</Text>  (<Text style={{ fontWeight: "bold" }}>{ props.dataPDF.pricetotalLetter }</Text>) cada uno. 
                  </Text>
                </View>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    3.{"  "}EL EMISOR tiene la intención de emitir la cantidad de bonos antes mencionados con la finalidad de desarrollar el Apart Hotel de RIBERA DEL RIO CLUB RESORT S.A., ubicado en Cieneguilla {"–"} Lima, Perú.  
                  </Text>
                </View>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    4.{"  "}EL TENEDOR FUTURO es una persona natural que tiene interés en adquirir un Bono de la { props.dataPDF.nameFamilyPackage.toUpperCase() } de la empresa VALLE ENCANTADO, a fin de generar rentabilidad.  
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA SEGUNDA: OBJETO DEL CONTRATO</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    El objeto del presente Contrato es reservar la adquisición de bonos por parte de EL TENEDOR FUTURO y establecer los términos, condiciones y características generales de la emisión de los valores a ser realizada, así como los derechos y obligaciones de EL EMISOR, de EL TENEDOR FUTURO, con arreglo a lo establecido por la normativa peruana.
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA TERCERA: RESERVA DE ADQUISICIÓN DE BONOS</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    Por el presente contrato, EL TENEDOR FUTURO reserva la adquisición de { props.dataPDF.userPackage.toUpperCase() } bonos ascendentes a <Text style={{ fontWeight: "bold" }}>${ props.dataPDF.price }</Text> (<Text style={{ fontWeight: "bold" }}>{props.dataPDF.pricetotalLetter}</Text>), la cual se configurará en la emisión de los bonos { props.dataPDF.nameFamilyPackage.toUpperCase() }, con fecha {`${props.dataPDF.creationDate.split("/")[0]}`} del mes {`${props.dataPDF.creationDate.split("/")[1]}`} del {`${props.dataPDF.creationDate.split("/")[2]}`}.
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA CUARTA: MONTO Y FORMA DE PAGO</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    1.{"  "}El precio de la reserva del { props.dataPDF.userPackage.toUpperCase() }, asciende a la suma de <Text style={{ fontWeight: "bold" }}>${ props.dataPDF.price }</Text> (<Text style={{ fontWeight: "bold" }}>{props.dataPDF.pricetotalLetter}</Text>), con el cual le otorga a EL TENEDOR FUTURO un retorno del 8% anual, conforme a los términos y condiciones generales de emisión que hace referencia la cláusula quinta.
                  </Text>
                </View>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    2.{"  "}Las partes acuerdan que el pago del PRECIO se efectuará en dólares de los Estados Unidos de América, al amparo de lo dispuesto por el Artículo 1237 del Código Civil.  
                  </Text>
                </View>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    3.{"  "}El precio de reserva será pagado en su totalidad, en la fecha de la suscripción del presente contrato. 
                  </Text>
                </View>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    4.{"  "}El PRECIO de la Reserva, podrá ser cancelada en las siguientes cuentas bancarias a elección de EL TENEDOR FUTURO. 
                  </Text>
                </View>
              </View>

            </View>

          </View>
          {/*2 */}
          <View wrap={false} style={styles.page}>
            <View style={styles.header}>
              <Image
                style={[styles.imgHeader, { height: "80px" }]}
                src="/pdf/inresorts/1.png"
              />
              <Image
                style={[styles.imgHeader, { height: "50px" }]}
                src="/pdf/inresorts/2.png"
              />
              <Image
                style={[styles.imgHeader, { height: "50px" }]}
                src="/pdf/inresorts/3.png"
              />
            </View>

            <View style={styles.body}>

              <Image
                style={[{ width: "100%", height: "105px", paddingBottom: 10 }]}
                src="/pdf/inresorts/precios.png"
              />

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLAUSULA QUINTA: TÉRMINOS Y CONDICIONES GENERALES DE LA EMSIÓN</Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Emisor: VALLE ENCANTADO S.A.C.  
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Tenedor: <Text style={{ fontWeight: "bold" }}>{props.dataPDF.user.name.toUpperCase()}{" "} {props.dataPDF.user.lastname.toUpperCase()}</Text>
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Monto total de la Emisión: Hasta por un importe total emitido de { props.dataPDF.desiredAmount } ({ props.dataPDF.desiredAmountletter }).
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Plazo: La Emisión de obligaciones tendrán un plazo de 3 meses hasta 36 meses.
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Serie: { props.dataPDF.nameFamilyPackage.toUpperCase() }
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Clase: La Emisión de obligaciones serán nominativos, indivisibles, libremente negociables
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Valor nominal: El valor nominal de cada bono será de ${ props.dataPDF.price } ({ props.dataPDF.pricetotalLetter }). 
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Fecha de colocación: {`${props.dataPDF.creationDate.split("/")[0]}`} del mes {`${props.dataPDF.creationDate.split("/")[1]}`} del {`${props.dataPDF.creationDate.split("/")[2]}`}.
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Fecha de emisión: 01 dic 2023
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Tasa de Interés anual: 8%
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Cupones: Se realizarán pagos de cupones trimestrales.
                </Text>
                <Text style={{ textAlign: "justify" }}>
                  •{"  "}Garantía: Se garantiza la emisión de bonos { props.dataPDF.nameFamilyPackage.toUpperCase() }, con las acciones clase A (Semilla 2x) de RIBERA DEL RIO CLUB RESORT S.A., identificada con RUC N° 20608720911, inscrita en la Partida Electrónica N° 14809461.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA SEXTA: GARANTIA</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                   Mediante el presente contrato, EL EMISOR garantiza la emisión de bonos { props.dataPDF.nameFamilyPackage.toUpperCase() }, con las acciones Clase A de RIBERA DEL RIO CLUB RESORT S.A., identificada con RUC N° 20608720911, inscrita en la Partida Electrónica N° 14809461. Las acciones Clase A objeto de garantía corresponden a ser acciones con derecho a voto y sin derecho a usufructo, bajo el esquema de Paquete de acciones Semilla 2X, valorizada en ${ props.dataPDF.price } ({ props.dataPDF.pricetotalLetter }) por Paquete, equivalente al valor de un bono, el cual otorgaría la titularidad de 23,566 acciones Clase A, en caso de no cumplirse con el pago de la obligación. 
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA SÉTIMA: CONDICIÓN PARA LA EMISIÓN DE BONOS</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    La emisión de bonos { props.dataPDF.nameFamilyPackage.toUpperCase() } se llevará a cabo en caso EL EMISOR confirme la culminación de la etapa de estructuración de bonos con la verificación del cumplimiento de la cantidad de reservas de adquisición de bonos necesarias para iniciar el desarrollo del proyecto, con lo que se tendría que asegurar la reserva de por lo menos la mitad de la emisión total, equivalente a 100 bonos { props.dataPDF.nameFamilyPackage.toUpperCase() }, ascendente a US$ 100,000.00 (Cien mil con 00/100 dólares). Para ello, el plazo de confirmación de la emisión será máximo hasta el 30 de noviembre de 2023. En caso, no se lleve a cabo la emisión mencionada, se le devolverá a EL TENEDOR FUTURO el dinero integro dado en reserva.
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA OCTAVA: EMISIÓN DE PAGARÉ</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    Con la finalidad de asegurar la devolución del dinero dado en reserva por parte de EL TENEDOR FUTURO, a la firma del presente contrato, se le emite un Pagaré con vencimiento de 30 días calendario posterior a la fecha de confirmación de emisión de bono, por el monto total del dinero dado en reserva para la adquisición de bono. En caso la emisión no logre el monto mínimo estipulado en la cláusula sétima, EL EMISOR tendrá hasta 5 días hábiles posteriores a la notificación de no ejecución de emisión de bonos, para realizar la devolución del dinero dado en reserva por EL TENEDOR FUTURO, a través de la cuenta bancaria de EL TENEDOR FUTURO y este último se obliga a devolver el Pagaré en físico original, máximo 3 días hábiles posteriores de realizada la transferencia.   
                  </Text>
                </View>
              </View>

            </View>

          </View>
          {/*3 */}
          <View wrap={false} style={styles.page}>
            <View style={styles.header}>
              <Image
                style={[styles.imgHeader, { height: "80px" }]}
                src="/pdf/inresorts/1.png"
              />
              <Image
                style={[styles.imgHeader, { height: "50px" }]}
                src="/pdf/inresorts/2.png"
              />
              <Image
                style={[styles.imgHeader, { height: "50px" }]}
                src="/pdf/inresorts/3.png"
              />
            </View>
            <View style={styles.body}>
              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA NOVENA: LA NO OTORGACIÓN DE RETRIBUCIÓN POR LA RESERVA</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    Dado que los fondos serán reservados en una cuenta inamovible, ya que el dinero no será utilizado para ningún fin hasta la fecha de emisión de bonos, el monto reservado por el TENEDOR FUTURO, no acarreará ningún tipo de interés compensatorio, interés moratorio, contraprestación, remuneración, u otro concepto que pueda ser equivalente.   
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA DÉCIMA: PENALIDAD POR RETRACTO</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    En caso de que EL TENEDOR FUTURO se retracte de la adquisición futura de bonos y solicite la devolución del monto dado en reserva como máximo el 20 de noviembre de 2023, EL EMISOR aplicará el 10% restante del monto cancelado por EL TENEDOR FUTURO como pago de penalidad por retracto, correspondiente a gastos administrativos. En ese sentido, se le procederá a devolver el 90% del monto dado en reserva.   
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA DÉCIMO PRIMERA: OBLIGACIONES DE LAS PARTES</Text>
                <Text style={{ textAlign: "justify", paddingBottom: 10 }}>
                  •{"  "}EL Emisor tiene las siguientes obligaciones:
                </Text>
                <View style={[styles.text, { paddingLeft: 0 }]}>
                  <Text style={styles.list}>
                    • EL EMISOR se obliga a emitir los bonos dados en reserva a EL TENEDOR FUTURO, en caso se confirme el cumplimiento de las condiciones para la respectiva emisión de bonos { props.dataPDF.nameFamilyPackage.toUpperCase() }, establecidas en la cláusula sétima.
                  </Text>
                  <Text style={styles.list}>
                    • Emitir el pagaré correspondiente por el monto total del dinero dado en reserva por EL TENEDOR FUTURO para la adquisición de bono, a la firma del presente contrato.
                  </Text>
                </View>

                <Text style={{ textAlign: "justify", paddingBottom: 10 }}>
                  •{"  "}EL TENEDOR FUTURO tiene las siguientes obligaciones:
                </Text>
                <View style={[styles.text, { paddingLeft: 0 }]}>
                  <Text style={styles.list}>
                    • EL TENEDOR FUTURO se obliga a efectuar el pago de reserva en el momento y forma pactados conforme a lo dispuesto en la cláusula cuarta de este documento. En caso contrario, no se celebra el contrato.
                  </Text>
                  <Text style={styles.list}>
                    • EL TENEDOR FUTURO tiene la obligación de devolver el Pagaré en caso se le devuelva el monto dado en reserva mediante transferencia, con un plazo máximo de 3 días hábiles desde la transferencia, o se realice la emisión de bonos respectiva, con un plazo máximo de 3 días hábiles posteriores a la emisión, esto el 01 de diciembre de 2023.
                  </Text>
                </View>

              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA DÉCIMO SEGUNDA: DERECHOS DE LAS PARTES</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    • EL EMISOR tiene derecho a percibir el pago que EL TENEDOR FUTURO deberá efectuar según lo dispuesto en la cláusula cuarta 
                  </Text>
                </View>

                <Text style={{ textAlign: "justify", paddingBottom: 10 }}>
                  •{"  "}EL TENEDOR FUTURO cuenta con los siguientes derechos:
                </Text>
                <View style={[styles.text, { paddingLeft: 0 }]}>
                  <Text style={styles.list}>
                    • EL TENEDOR FUTURO tiene el derecho a que se le emita la cantidad de bonos reservados en la fecha de la emisión de bonos, con fecha 01 de diciembre de 2023, en caso se confirme el cumplimiento de las condiciones para la respectiva emisión de bonos { props.dataPDF.nameFamilyPackage.toUpperCase() }, establecidas en la cláusula sétima.
                  </Text>
                  <Text style={styles.list}>
                    • EL TENEDOR FUTURO tiene derecho a que se le emita el respectivo Pagará a su favor para garantizar la devolución del dinero en caso no se lleve a cabo la emisión de bonos { props.dataPDF.nameFamilyPackage.toUpperCase() }.
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA DÉCIMO TERCERA: PAGO DE TRÁMITES NOTARIALES</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    Las partes establecen que los gastos notariales de legalización del presente contrato, queda a cargo de EL TENEDOR FUTURO.  
                  </Text>
                </View>
              </View>

            </View>
          </View>
          {/*4 */}
          <View wrap={false} style={styles.page}>
            <View style={styles.header}>
              <Image
                style={[styles.imgHeader, { height: "80px" }]}
                src="/pdf/inresorts/1.png"
              />
              <Image
                style={[styles.imgHeader, { height: "50px" }]}
                src="/pdf/inresorts/2.png"
              />
              <Image
                style={[styles.imgHeader, { height: "50px" }]}
                src="/pdf/inresorts/3.png"
              />
            </View>
            <View style={styles.body}>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA DÉCIMO CUARTA: DOMICILIO Y NOTIFICACIONES LEGALES</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    Las partes señalan como sus domicilios los consignados en la introducción del presente contrato. Cualquier comunicación o notificación deberá ser cursada a dichos domicilios por escrito, con cargo de recepción. En caso de cambio de domicilio, para su efecto o validez legal deberá comunicarse a la otra parte, con cargo de recepción y a la brevedad posible. En tanto no recepcione la otra parte la citada comunicación se entenderán válidamente efectuadas las comunicaciones remitidas al anterior domicilio y surtirán todos sus efectos legales.  
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA DÉCIMO QUINTA: LEGISLACION APLICABLE</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    El contrato se regirá por la Legislación Peruana. En todo lo no previsto por las partes en el presente contrato, ambas se someten a lo establecido por las normas del Código Civil, los principios de buena fe contractuales y demás del sistema jurídico que resulten aplicables.
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>CLÁUSULA DÉCIMO SEXTA: SOLUCIÓN DE CONTROVERSIAS</Text>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    Queda expresamente establecido, que, ante toda divergencia o reclamo vinculados con la celebración, ejecución y/o interpretación del presente Contrato, las partes se someten expresamente a la jurisdicción de los jueces y tribunales del distrito judicial del Cercado de Lima, renunciando al fuero de sus domicilios. 
                  </Text>
                </View>
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    En señal de conformidad las partes suscriben este documento en la ciudad de Lima, a Los
                    {`${props.dataPDF.creationDate.split("/")[0]}`} días del mes de{" "}
                    {`${props.dataPDF.creationDate.split("/")[1]}`} de{" "}
                    {`${props.dataPDF.creationDate.split("/")[2]}`} 
                  </Text>
                </View>
                <Text></Text>
                <Text></Text>
              </View>

              <View
                style={{
                  paddingTop: 50,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Image
                    src="/pdf/inresorts/firmaOmarUrteaga.jpg"
                    style={{ width: "100px", height: "50px" }}
                  />
                  <Text>____________________________</Text>
                  <Text>VALLE ENCANTADO S.A.C.</Text>
                  <Text>Omar Fernando Urteaga Cabrera</Text>
                  <Text>Gerente General</Text>
                  <Text>VALLE ENCANTADO</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    paddingBottom: 10,
                  }}
                >
                  <Text>____________________________</Text>
                  <Text>{`${props.dataPDF.user.name} ${props.dataPDF.user.lastname}`}</Text>
                  <Text>
                    Documento de identidad N°{props.dataPDF.user.nroDocument}
                  </Text>
                  <Text>EL TENEDOR FUTURO</Text>
                </View>
              </View>

            </View>
          </View>
        </Page>
      </Document>
    )
}


export default ContratoBonosPDF;