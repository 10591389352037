import React, { useEffect, useState } from "react";
const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;
function Days({ idDeLaSubscripcion, hijo }) {
  const [dias, setDias] = useState({});

  const obtenerDias = async (idDeLaSubscripcion) => {
    try {
      let url = API_URL + `graceperiod/suscription/${idDeLaSubscripcion}`;
      const resp = await fetch(url);
      const result = await resp.json();
      /* console.log("resultado", result); */
      setDias(result.objModel);
      hijo = 100;
      localStorage.setItem("diasPeriodoGracia", result.objModel);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    obtenerDias(idDeLaSubscripcion);
  }, []);
  console.log("haber dias", dias);
  /* if (!dias.daysLeft) {
    return null;
  } */

  return (
    <div className="text-uppercase">
      <>
        {dias ? (
          <div>
            <p>
              Dias totales de periodo de gracia :{" "}
              <span className="text-danger">{dias.daysTotal}</span>
            </p>
            <p>
              Le quedan estos dias de periodo de gracia :{" "}
              <span className="text-danger">{dias.daysFree}</span>
            </p>
            <p>
              Cantidad de dias de periodo de gracia usados :{" "}
              <span className="text-danger">{dias.daysUsed}</span>
            </p>
           {/*  {dias.extraOrdinaryDaysLeft >= 1 && (
              <p>
                Cantidad de dias de periodo de gracia extraordinario :{" "}
                <span className="text-danger">
                  {dias.extraOrdinaryDaysLeft}
                </span>
              </p>
            )} */}
          </div>
        ) : null}
      </>
    </div>
  );
}

export default Days;
