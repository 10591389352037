import React, { Component } from "react";
import { Form, Button, Row, Col, Spinner, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import UserService from "../../services/user.service";
import history from "../navigation/history";
import PayMultipleQuote from "../../components/payment/PayMultipleQuote";
import utilsService from "../../services/utils.service";

/**
 * Component to register payments usign the link from the email
 */
export default class RegisterMulQuoteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: undefined,
      overdue: 0,
      idSuscription: 0,
      suscription: {},
      loading: true,
      noData: false,
      vouchers: [],
      isPaid: false,
      loadSenData: false,
      isComplete: false,
      objPayPal: {
        isPaid: undefined,
        mountPaid: undefined,
        creationDate: undefined,
        modificationDate: undefined,
        idTransaction: undefined,
        intent: undefined,
        status: undefined,
        quotesPay: 1,
      },
      walletAmount: 0,
      messageError: "",
      typeExchange: {
        venta: 1,
      },
      pay: 0,
      // Periodo de gracia
      ApplyGracePeriod: 0,
      GracePeriodDays: 0,
      totalPay2: 0,
      // Cupon
      ApplyCoupon: 0,
      OverdueCoupon: "",
      // mora
      isMora: false,
      // days
      NewExpirationDate: "0001-01-01T00:00:00",
    };

    this.quoteToPay = this.quoteToPay.bind(this);

    // Create reference to Paypal
    this.childPaypal = React.createRef();
  }

  componentDidMount() {
    this.getInfoURL();
    this.getExchange();
  }

  async getExchange() {
    let response = await utilsService.getExchanges(); //UtilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: (this.state.typeExchange = response.objModel),
        });
      }
    }
  }

  getInfoURL() {
    let url = window.location;
    let params = url.search;
    let listParams = params.split("="); //queryString.parse(params);

    // Get information from url
    if (listParams.length === 7) {
      // Load services to get information of payment
      let idSuscription = listParams[4];
      let numQuotes = listParams[2];
      let idMemberShip = listParams[6];

      this.getSuscription(idSuscription, numQuotes, idMemberShip);
    } else {
      this.setState({
        loading: false,
        quote: undefined,
        noData: true,
        noDataMessage: "No pudimos obtener información.",
      });
    }
  }

  async getSuscription(idSuscription, numQuotes, idMemberShip) {
    // get suscription information
    let response = await UserService.getSuscriptionById(idSuscription);
    //console.log('VER RESPONSE ->',response);
    if (response !== undefined && response.status === 1) {
      console.log("haber RESPONSE ====>", response.objModel);
      let quote = {
        idMembershipDetail: Number(idMemberShip),
        idSuscription: Number(idSuscription),
        idPaquete: response.objModel.package.id,
        idUser: response.objModel.idUser,
        namePackage: response.objModel.package.description,
        quoteUsd: response.objModel.payment.totalPay,
        quoteDescription: response.objModel.quotaDescription,
        isQuoteInitial: 0,
        numQuotes: Number(numQuotes),
      };
      let form = {
        IdPayment: quote.idMembershipDetail,
        NumberQuotesPaid: 0,
        SuscriptionId: quote.idSuscription,
        TypeTotal: 1,
        OverdueCode: "",
      };
     /*  await utilsService.PostCalculeGraciaDays(form).then((res) => {
        quote.totalPay = res.objModel.totalPay;
        console.log("haber PostCalculeGraciaDays", res.objModel);
        this.setState({
          isMora: res.objModel.withOverdue,
          overdue: res.objModel.overdue,
        });
      }); */
      this.setState({
        quote: (this.state.quote = quote),
        overdue: this.state.overdue,
        loading: (this.state.loading = false),
      });
      //console.log(this.state.quote);
    } else {
      this.setState({
        loading: (this.state.loading = false),
        quote: (this.state.quote = undefined),
        noData: (this.state.noData = true),
        noDataMessage: (this.state.noDataMessage =
          "No pudimos obtener información."),
      });
    }
  }

  // verify local storage  TODO change form calll services with
  quoteToPay() {
    if (
      localStorage.length > 0 &&
      localStorage.getItem("currentQuote") !== null
    ) {
      if (localStorage.getItem("currentQuote").length > 0) {
        let quote = JSON.parse(localStorage.getItem("currentQuote"));
        // console.log("quote", quote);
        this.setState({
          loading: false,
          quote: quote,
          noData: false,
        });

        return quote;
      } else {
        this.setState({
          loading: false,
          quote: undefined,
          noData: true,
        });
        return undefined;
      }
    } else {
      this.setState({
        loading: false,
        quote: undefined,
        noData: true,
      });

      return undefined;
    }
  }

  // TODO change for only call send dato from paypal is payment is proccesed
  /**
   * Event to handle the paypal's payment and vouchers
   * @param {*} data
   * @param {*} field
   */
  eventPaypal = (data, field) => {
    if (field === "vouchers") {
      this.setState({
        [field]: data,
      });
    } else {
      let obj = this.state.objPayPal;

      obj[field] = data;
      this.setState({
        objPayPal: obj,
      });
      //verify if all field are not undefined
      this.verifyObjetPaypal(obj);
    }
  };

  verifyObjetPaypal = (obj) => {
    if (
      obj.isPaid !== undefined &&
      obj.mountPaid !== undefined &&
      obj.creationDate !== undefined &&
      obj.modificationDate !== undefined &&
      obj.idTransaction !== undefined &&
      obj.intent !== undefined &&
      obj.status !== undefined
    ) {
      if (obj.isPaid) {
        // save paypal and complete payment
        this.sendData();
      }
    } else {
    }
  };

  validate = () => {
    console.log("entraste a validate");
    // verify if the payment was by paypal
    if (this.state.objPayPal.isPaid) {
      if (this.state.objPayPal.status === "COMPLETED") {
        return true;
      } else {
        alert(
          "Su pago con PayPal ha sido rechazado. Seleccione otro medio de pago."
        );
        return false;
      }
    } else if (this.state.vouchers.length > 0) {
      let vouchersUpload = this.state.vouchers;
      let cambio = Number(this.state.typeExchange.venta);
      let totalamount = Number(0);
      console.log('this.state.objPayPal.totalPay en multicuotas =>',this.state.objPayPal)
      let amountpay = Number(this.state.objPayPal.totalPay); //Number(sessionStorage.getItem("amountPayC"));
      let comision = Number(0);
      console.log('ver vouchersUpload =>',vouchersUpload)

      if (vouchersUpload.length > 0) {
        amountpay = vouchersUpload[0].totalPagarVoucher;
        for (let i = 0; i < vouchersUpload.length; i++) {
          // Split base64 code type and string
          let moneda = Number(vouchersUpload[i].typeExchange);
          let cantidad = Number(vouchersUpload[i].amount);
          console.log(vouchersUpload[i].amount,  Number(vouchersUpload[i].amount), 'cantidad', cantidad);

          if (moneda === 1) {
            //dolar
            totalamount += cantidad;
            comision = comision + Number(vouchersUpload[i].comision); //comision dolares
            amountpay = vouchersUpload[0].totalPagarVoucher;
          } else {
            //moneda=2 sol
            //convertir sol a dolar
            cantidad /= cambio;
            console.log('cantidad caculado', cantidad)
            totalamount += cantidad;
            
            comision =
              comision +
              Number(Number(vouchersUpload[i].comisionExchange) / cambio); //comision soles
            console.log('ver que pasa aqui', cantidad, totalamount, comision, cambio)
          }
        }

        console.log('ver en general', totalamount, comision)

        let montowallet = Number(this.state.walletAmount);
        if (montowallet !== undefined) {
          totalamount += montowallet;
        }
        //agregando comision al monto total
        //amountpay+=comision

        totalamount = Number(totalamount);
        totalamount -= comision;
        // console.log(vouchersUpload)
        // console.log(cambio)
        // console.log(comision)
         console.log('totalamount lo que escribes ==>',totalamount)
         console.log('amountpay lo que tienes que pagar =>',amountpay)

         console.log(totalamount, comision, amountpay);
          if (comision > 0) {
            if (totalamount + comision < amountpay || totalamount + comision > amountpay ) {
              alert("El monto total ingresado no coincide con el monto a pagar");
              return false;
            }
            
          }

        /*if (totalamount < amountpay - 1 || totalamount > amountpay + 3) {
          // comision
          if (comision > 0) {
            alert(
              `(Tienes una comisión de $${comision.toFixed(
                2
              )} dolares ) , por favor eliminar y volver a registrar su pago correctamente`
            );
            return false;
          }
          alert("El monto total ingresado no coincide con el monto a pagar sdsd");
          return false;
        }*/
      }

      return true;
    } else {
      alert("Seleccione un medio de pago...");
      return false;
    }
  };

  eventAddWallet = (monto) => {
    this.setState({
      walletAmount: monto,
    });
  };
  /**
   * Register pay by tickets or paypal
   */
  // Paypal = 1,
  // Vouchers = 2,
  // Wallet = 3,
  // Mixto = 4,
  // Otros = 5,
  
  sendData = async () => {
    console.log("Hiciste click en registrar");
    if (this.validate()) {
      this.setState({
        loadSenData: true,
      });

      // By paypal register
      if (this.state.objPayPal.isPaid) {
        // Close paypal modal
        this.childPaypal.current.handleClose();

        //send data
        let data = {
          TypeMethodPayment: 1,
          PaypalDTO: {
            nroOperation: this.state.objPayPal.idTransaction,
          },
          IdUserPayment: this.state.quote.idUser,
          IdSuscriptionPayment: this.state.quote.idSuscription,
          IdPayment: this.state.quote.idMembershipDetail,
          AmountPaidPayment: this.state.objPayPal.mountPaid,
          NumberAdvancePaymentPaid: this.state.objPayPal.quotesPay,
          IsQuoteInitial: this.state.quote.isQuoteInitial,
          // Periodo de gracia
          ApplyGracePeriod: this.state.ApplyGracePeriod,
          GracePeriodDays: this.state.GracePeriodDays,
          // Cupon
          ApplyCoupon: this.state.ApplyCoupon,
          OverdueCoupon: this.state.OverdueCoupon,
          // days
          NewExpirationDate: this.state.NewExpirationDate,
        };
        console.log("haber data a enviar paypal", data);
        let response = await UserService.registerPaymentQuoteVouhersv2(data);

        if (response !== undefined) {
          if (response.status === 1) {
            // Delete local storage item
            localStorage.removeItem("currentQuote");

            this.setState({
              isComplete: true,
              loadSenData: false,
            });
          } else {
            this.setState({
              loadSenData: false,
            });
            alert("Ocurrió un error al momento de registrar su pago.");
          }
        } else {
          this.setState({
            loadSenData: false,
            messageError: "Ocurrió un error.",
          });
          alert("Tuvimos un problema. Inténtalo más tarde.");
        }
      } else if (
        this.state.vouchers.length > 0 &&
        this.state.walletAmount <= 0
      ) {
        //Vouchers

        let data = {
          TypeMethodPayment: 2,
          WalletTransaction: {
            Amount: this.state.walletAmount,
          },
          IdUserPayment: this.state.quote.idUser,
          IdSuscriptionPayment: this.state.quote.idSuscription,
          IdPayment: this.state.quote.idMembershipDetail,
          AmountPaidPayment: this.state.objPayPal.totalPay,
          NumberAdvancePaymentPaid: this.state.objPayPal.quotesPay,
          IsQuoteInitial: this.state.quote.isQuoteInitial,
          // Periodo de gracia
          ApplyGracePeriod: this.state.ApplyGracePeriod,
          GracePeriodDays: this.state.GracePeriodDays,
          // Cupon
          ApplyCoupon: this.state.ApplyCoupon,
          OverdueCoupon: this.state.OverdueCoupon,
          // days
          NewExpirationDate: this.state.NewExpirationDate,
        };
        console.log("haber data a enviar Vouchers", data);
        let vouchers = [];
        let vouchersUpload = this.state.vouchers;

        for (let i = 0; i < vouchersUpload.length; i++) {
          // Split base64 code type and string
          let contenType = vouchersUpload[i].voucherBase.split(",");
          let temp = {
            MethodSubTipoPagoId: Number(vouchersUpload[i].type),
            CompanyOperationNumber: vouchersUpload[i].code,
            OperationNumber: vouchersUpload[i].code,
            TotalAmount: Number(vouchersUpload[i].amount),
            PaymentCoinCurrencyId: Number(vouchersUpload[i].typeExchange),
            Note: vouchersUpload[i].nota,
            Base64: contenType[1],
          };
          vouchers.push(temp);
        }
        data.Vouchers = vouchers;
        data.NotePay = vouchers.map((item) => item.NotePay).join(", ");
        // console.log(data)
        let response = await UserService.registerPaymentQuoteVouhersv2(data);
        //let response =undefined
        if (response !== undefined) {
          if (response.status === 1) {
            localStorage.removeItem("currentQuote");
            this.setState({
              isComplete: true,
              loadSenData: false,
            });
          } else {
            this.setState({
              loadSenData: false,
              messageError: "Ocurrió un error.",
            });
            alert("Ocurrió un error al momento de registrar su pago.");
          }
        } else {
          this.setState({
            loadSenData: false,
          });
          alert("Tuvimos un problema. Inténtalo más tarde.");
        }
      } else if (
        this.state.vouchers.length === 0 &&
        this.state.walletAmount > 0
      ) {
        // Pay with only wallet
        // console.log("wallet");
        // Implement into eventPayWallet
      } else if (
        this.state.vouchers.length > 0 &&
        this.state.walletAmount > 0
      ) {
        // Pay with vouchers and wallet

        let data = {
          TypeMethodPayment: 4,
          WalletTransaction: {
            Amount: this.state.walletAmount,
            ReferenceData: `Pago parcial de ${this.state.quote.quoteDescription}`,
          },
          IdUserPayment: this.state.quote.idUser,
          IdSuscriptionPayment: this.state.quote.idSuscription,
          IdPayment: this.state.quote.idMembershipDetail,
          AmountPaidPayment: this.state.objPayPal.totalPay,
          NumberAdvancePaymentPaid: this.state.objPayPal.quotesPay - 1,
          IsQuoteInitial: this.state.quote.isQuoteInitial,
          // Periodo de gracia
          ApplyGracePeriod: this.state.ApplyGracePeriod,
          GracePeriodDays: this.state.GracePeriodDays,
          // Cupon
          ApplyCoupon: this.state.ApplyCoupon,
          OverdueCoupon: this.state.OverdueCoupon,
          // days
          NewExpirationDate: this.state.NewExpirationDate,
        };

        let vouchers = [];
        let vouchersUpload = this.state.vouchers;

        for (let i = 0; i < vouchersUpload.length; i++) {
          // Split base64 code type and string
          let contenType = vouchersUpload[i].voucherBase.split(",");
          let temp = {
            MethodSubTipoPagoId: Number(vouchersUpload[i].type),
            CompanyOperationNumber: vouchersUpload[i].code,
            OperationNumber: vouchersUpload[i].code,
            TotalAmount: Number(vouchersUpload[i].amount),
            PaymentCoinCurrencyId: Number(vouchersUpload[i].typeExchange),
            Note: vouchersUpload[i].nota,
            Base64: contenType[1],
          };

          vouchers.push(temp);
        }
        data.Vouchers = vouchers;
        data.NotePay = vouchers.map((item) => item.NotePay).join(", ");
        // console.log(data);
        let response = await UserService.registerPaymentQuoteVouhersv2(data);
        if (response !== undefined) {
          if (response.status === 1) {
            localStorage.removeItem("currentQuote");
            this.setState({
              isComplete: true,
              loadSenData: false,
            });
          } else {
            this.setState({
              loadSenData: false,
              messageError: "Ocurrió un error.",
            });
            alert("Ocurrió un error al momento de registrar su pago.");
          }
        } else {
          this.setState({
            loadSenData: false,
            messageError: "Ocurrió un error.",
          });
          alert("Tuvimos un problema. Inténtalo más tarde.");
        }
      }
    }
  };

  cancelar = () => {};

  returnLogin = () => {
    history.push("/home");
  };

  // Register payment only wallet /> acction from wallet
  eventPayWallet = async (amount) => {
    this.setState({
      loadSenData: true,
    });
    let cantPay = this.state.objPayPal.quotesPay == 0 ? 1 : this.state.objPayPal.quotesPay;
    let data = {
      TypeMethodPayment: 3,
      WalletTransaction: {
        Amount: amount,
        ReferenceData: `Pago de ${
          this.state.quote.quoteDescription === null
            ? `${cantPay > 1 ? cantPay + 1 : cantPay} cuotas.`
            : this.state.quote.quoteDescription
        }`, //"Pago de suscripción envia null en la description"
      },
      IdUserPayment: this.state.quote.idUser,
      IdSuscriptionPayment: this.state.quote.idSuscription,
      IdPayment: this.state.quote.idMembershipDetail,
      AmountPaidPayment: amount,
      NumberAdvancePaymentPaid: this.state.objPayPal.quotesPay,
      IsQuoteInitial: this.state.quote.isQuoteInitial,
      // Periodo de gracia
      ApplyGracePeriod: this.state.ApplyGracePeriod,
      GracePeriodDays: this.state.GracePeriodDays,
      // Cupon
      ApplyCoupon: this.state.ApplyCoupon,
      OverdueCoupon: this.state.OverdueCoupon,
      // days
      NewExpirationDate: this.state.NewExpirationDate,
    };

    let response = await UserService.registerPaymentQuoteVouhersv2(data);

    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          isComplete: true,
          loadSenData: false,
        });
        // Delete local storage item
        localStorage.removeItem("currentQuote");
      } else {
        this.setState({
          loadSenData: false,
          messageError: "Ocurrió un error. Intentelo más tarde",
        });
        alert(
          "Ocurrió un error al momento de registrar su pago. \n" +
            response.description
        );
      }
    } else {
      this.setState({
        loadSenData: false,
      });
      alert("Tuvimos un problema. Inténtalo más tarde.");
    }
  };
  // Periodo de gracia
  ApplyGracePeriodAndCupon = async (
    ApplyGracePeriod = 0,
    GracePeriodDays = 0,
    totalPay = 0,
    ApplyCoupon = 0,
    OverdueCoupon = "",
    newExpirationDate = ""
  ) => {
    if (ApplyGracePeriod === 1) {
      this.setState({
        ApplyGracePeriod: ApplyGracePeriod,
        GracePeriodDays: GracePeriodDays,
        // quote: {
        //   ...this.state.quote,
        //   totalPay: totalPay,
        // },
        ApplyCoupon: 0,
        OverdueCoupon: "",
        NewExpirationDate: newExpirationDate,
      });
      // this.state.quote.totalPay = totalPay;
    } else if (ApplyCoupon === 1) {
      this.setState({
        ApplyGracePeriod: 0,
        GracePeriodDays: 0,
        // quote: {
        //   ...this.state.quote,
        //   totalPay: totalPay,
        // },
        // Cupon
        ApplyCoupon: ApplyCoupon,
        OverdueCoupon: OverdueCoupon,
      });
    } else {
      this.setState({
        ApplyGracePeriod: 0,
        GracePeriodDays: 0,
        totalPay2: 0,
        // Cupon
        ApplyCoupon: 0,
        OverdueCoupon: "",
      });
    }
  };
  render() {
    const {
      quote,
      loading,
      noData,
      noDataMessage,
      isComplete,
      walletAmount,
    } = this.state;
    return (
      <div>
        {!isComplete && (
          <div className="panel-form">
            {loading && (
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border" variant="dark"></Spinner>
                <p>Obteniendo información ...</p>
              </div>
            )}
            {!loading && !noData && (
              <div>
                <PayMultipleQuote
                  quote={quote}
                  eventPay={this.eventPaypal}
                  registerPayWallet={this.eventPayWallet}
                  eventWallet={this.eventAddWallet}
                  ref={this.childPaypal}
                  mora={this.state.isMora}
                  ApplyGracePeriodAndCupon={this.ApplyGracePeriodAndCupon}
                ></PayMultipleQuote>
                {walletAmount > 0 && (
                  <div>
                    <p>
                      <b>Monto pagado con Mi Wallet $ :</b> {walletAmount}
                    </p>
                  </div>
                )}

                <div
                  className="row justify-content-between"
                  style={{ display: this.state.displayFooter }}
                >
                  <Col style={{ textAlign: "right" }}>
                    <Button variant="primary" onClick={this.sendData}>
                      Registrar
                    </Button>
                  </Col>
                </div>
              </div>
            )}
            {/* {messageError.length > 0 &&
                            <Alert variant="danger">{messageError}</Alert>
                         } */}
            {noData && (
              <div style={{ textAlign: "center" }}>
                <Form.Label>{noDataMessage}</Form.Label>
              </div>
            )}
            <Modal
              show={this.state.loadSenData}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              centered
            >
              <Modal.Body>
                <div style={{ textAlign: "center" }}>
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="dark"
                  ></Spinner>
                  <Form.Label>&nbsp; Guardando información...</Form.Label>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
        {isComplete && (
          <div className="panel-form">
            <Row style={{ textAlign: "center" }}>
              <Col sm={12}>
                <Form.Label>Registro exitoso!</Form.Label>
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col sm={12}>
                <Button onClick={this.returnLogin}>Volver</Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
