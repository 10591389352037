import React, { Component } from "react";

import { Form, Row, Col, Button, Table } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
// import PaymentStripe from "../../services/paymentStripe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PUBLIC_KEY, KEY_STRIPE } from "../../services/constans";
import StripeForm from "./StripeForm";


export default class TestPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

//   handlePayment = async () => {
//     const response = await PaymentStripe.paymentIntent();
//     ;
//   };
  render() {
    const stripePromise = loadStripe(KEY_STRIPE);

    return (
      <Elements stripe={stripePromise}>
        <StripeForm />
      </Elements>
    );
  }
}
