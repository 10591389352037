import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import ChatBot, { Loading } from 'react-simple-chatbot';
import ChatBotService from "../../services/chatbot.service";
import Validation from "../utils/Validation";

export default class SuscriptionUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: true,
        result: '',
        err: '',
        members: [],
        error: true
    };
  }

  componentDidMount(){
    this.SuscriptionUpdate(localStorage.getItem("idUser"));
  }

  async SuscriptionUpdate(id){ 

    

    let response = await ChatBotService.getMembersUser(id);
    
 	    if(response !== undefined){
            if(response.status == 1){
                this.setState({
                    loading: false,
                    error: false,
                    result: 'Indique la membresía a actualizar.',
	                members: response.objModel
                });
            }else{
                this.setState({
                    loading: true,
                    error: false,
                    err: 'Error al obtener las membresías del usuario, realice nuevamente la solicitud.',
                    members: []
                })
                this.triggertNextError();
            }
	    }else{
            this.setState({
                loading: true,
                error: false,
                err: 'Error al obtener las membresías del usuario, realice nuevamente la solicitud.',
                members: []
            })
            this.triggertNextError();
        }

    /// end
  }

  triggertNext() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "4a1"});
    });
  }

  triggertNextError() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "2"});
    });
  }

  handleSelectPackagesUser = async (e) => {
    let id = e.target.value;
    localStorage.setItem("membresia_actual", id)
    
    this.setState({
        idPackagesUser: id
    });
    this.triggertNext();
}


  render() {
      const {loading, result, error, err} = this.state;

    return (
      <div className="dbpedia" style={{color: '#fff'}}>
        { error ? <Loading /> : err }
        { loading ? <Loading /> : result }
        { !loading &&
            <Form.Group style={{paddingTop: '6px'}}>
            <Form.Control as="select" defaultValue={'DEFAULT'}
            onChange={e => this.handleSelectPackagesUser(e)}>
                <option value="DEFAULT" disabled>Seleccione una opción</option>
            {this.state.members.map((elem) => (
            <option key={elem.id} value={elem.packageName}>{elem.packageName}</option> 
            ))} </Form.Control>  
            </Form.Group>}

      </div>
    );
  }

}