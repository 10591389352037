import React, { Component } from "react";
import {
  Navbar,
  Image,
  Nav,
  Button,
  Container,
  Form,
  Modal,
  Spinner,
  Col,
  Card,
  CardDeck,
  InputGroup,
  Carousel,
} from "react-bootstrap";
import facebook from "../../images/icons/facebook.png";
import youtube from "../../images/icons/youtube.png";
import register1 from "../../images/sections/1.png";
import register2 from "../../images/sections/C.png";
import register3 from "../../images/sections/banner2.png";
import register4 from "../../images/sections/banner1.png";
import inresorts1 from "../../images/sections/inresorts1.png";
import keola1 from "../../images/sections/keola1.png";
import unete1 from "../../images/sections/unete1.png";
import InTech from "../../images/LogoIntech.png";
import Logo from "../../images/LogoInresorts.png";
import RegisterMain from "../newregister/RegisterMain";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../views/styles/Custom.css";
import './RegisterIn.css'
import pngegg from './pngegg.png'
import { FaEyeSlash, FaEye } from "react-icons/fa";

import history from "../../views/navigation/history";
import UserService from "../../services/user.service";
import RegisterEndViewPre from "./RegisterEndViewPre";
const linkMail = `${process.env.REACT_APP_APP_URL}/register-payment`;

export default class RegisterIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      username: "",
      password: "",
      isShow: false,
      validate: false,
      showModal: false,
      showWallet: props.showWallet,
      idSocio: props.idSocio,
      reload: props.reload,
      user: {
        name: "",
        lastname: "",
        birthdate: "",
        gender: "",
        idFamilyPackage: "",
        idNationality: -1,
        nroDocument: "",
        civilState: "",
        email: "",
        idResidenceCountry: -1,
        idDocumentTypeCountry: -1,
        desDocument: "",
        districtAddress: "",
        Comments: "",
        estado: "",
        address: "",
        phone: "",
        username: "",
        password: "",
        coafiliate: false,
        coname: "",
        colastname: "",
        cobirthdate: "",
        conroDocument: "",
        codesDocument: "",
        coidDocumentTypeCountry: -1,
        packages: [],
        kitInit: [],
      },
      tempuser: {},
      displayFooter: "block",
      currentTab: "",
      isComplete: false,
      payment: {
        scheduleJSON: "",
        idMethodPayment: 0,
        mountPaid: 0,
        isPaid: false,
        typePay: 0,
        quotesPay: 0,
        vouchers: [],
        quotesPay: 0,
        amountPay: 0,
      },
      voucher: {
        bank: "",
        isPaid: false,
      },
      loadSenData: false,
      observerPaypal: {
        isPaid: undefined,
        mountPaid: undefined,
        creationDate: undefined,
        modificationDate: undefined,
        idTransaction: undefined,
        intent: undefined,
        status: undefined,
      },
      amountWallet: 0,
      emailCC: "",
    };
  }

  eventText = (value, field) => {
    this.setState({ field: value });
  };

  OnClicked = (e, path) => {
    history.push(path);
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ validate: true });
  };
  handleChange = (e, name) => {
    let label = e.target.name;
    let value = e.target.value;
    this.setState({
      [label]: (this.state[label] = value),
    });
  };

  eventhandler = (data, field) => {
    var temp = this.state.user;
    temp[field] = data;
    this.setState({ user: temp });
  };

  eventFooter = (value, field) => {
    this.setState({ [field]: value });
  };

  eventWallet = (amount) => {
    this.setState({
      amountWallet: (this.state.amountWallet = amount),
    });

    // // verify payment
    // if(amount > 0 || this.state.voucher.length > 0) {
    //     let payment = this.state.payment;
    //     payment.isPaid = true;
    //     this.setState({
    //         payment: payment
    //     });
    // }
  };

  eventValidate = () => {
    // Step 1
    if (this.state.user.idFamilyPackage.length === 0) {
      alert("Seleccione en que está interesado.");
      return false;
    }
    if (this.state.user.name.length === 0) {
      alert("Ingrese sus nombres.");
      return false;
    }
    if (this.state.user.lastname.length === 0) {
      alert("Ingrese sus apellidos.");
      return false;
    }
    if (this.state.user.idNationality <= 0) {
      alert("Seleccione de donde nos contactas.");
      return false;
    }
    if (this.state.user.idDocumentTypeCountry <= 0) {
      alert("Seleccione un tipo de documento.");
      return false;
    }
    if (this.state.user.nroDocument.length === 0) {
      alert("Ingrese su número de documento.");
      return false;
    }
    if (this.state.user.gender.length === 0) {
      alert("Seleccione su sexo.");
      return false;
    }

    //Step 2
    if (this.state.user.email.length === 0) {
      alert("Ingrese su correo electrónico.");
      return false;
    }
    if (this.state.user.idResidenceCountry <= 0) {
      alert("Seleccione su país de residencia.");
      return false;
    }
    if (this.state.user.districtAddress.length === 0) {
      alert("Ingrese su ciudad.");
      return false;
    }
    if (this.state.user.estado.length === 0) {
      alert("Ingrese su estado / estado de residencia.");
      return false;
    }
    if (this.state.user.phone.length === 0) {
      alert("Ingrese su número de celular.");
      return false;
    }
    if (this.state.user.Comments.length === 0) {
      alert("Ingrese su consulta.");
      return false;
    }

    return true;
  };

  /**
   * To handle the event  on payment step
   */
  eventPayment = (value, field) => {
    var temp = this.state.payment;
    temp[field] = value;
    this.setState({ payment: temp });

    // TODO change by object verify if the field of paypal are filled
    if (field === "isPaid") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "mountPaid") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "creationDate") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "modificationDate") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "idTransaction") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "intent") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "status") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }

    //verify if all field are not undefined
    this.verifyObjetPaypal(this.state.observerPaypal);
  };

  /** verify observer paypal */
  verifyObjetPaypal = (obj) => {
    if (
      obj.isPaid !== undefined &&
      obj.mountPaid !== undefined &&
      obj.creationDate !== undefined &&
      obj.modificationDate !== undefined &&
      obj.idTransaction !== undefined &&
      obj.intent !== undefined &&
      obj.status !== undefined
    ) {
      if (obj.isPaid) {
        // save paypal and complete payment
        this.sendData();
      }
    } else {
    }
  };

  eventVoucher = (value, field) => {
    var temp = this.state.voucher;
    temp[field] = value;
    this.setState({ voucher: temp });
  };

  eventPayment = (value, field) => {
    var temp = this.state.payment;
    temp[field] = value;
    this.setState({ payment: temp });

    // TODO change by object verify if the field of paypal are filled
    if (field === "isPaid") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "mountPaid") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "creationDate") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "modificationDate") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "idTransaction") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "intent") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }
    if (field === "status") {
      var temp = this.state.observerPaypal;
      temp[field] = value;
      this.setState({ observerPaypal: temp });
    }

    //verify if all field are not undefined
    this.verifyObjetPaypal(this.state.observerPaypal);
  };

  validation = () => {
    if (this.state.user.name.length === 0) {
      alert("Ingrese sus nombres.");
      this.setState({
        currentTab: (this.state.currentTab = "Step1"),
      });
      return false;
    }
    if (this.state.user.lastname.length === 0) {
      alert("Ingrese sus apellidos.");
      this.setState({
        currentTab: (this.state.currentTab = "Step1"),
      });
      return false;
    }
    if (this.state.user.birthdate.length === 0) {
      alert("Ingrese su fecha de nacimiento");
      this.setState({
        currentTab: (this.state.currentTab = "Step1"),
      });
      return false;
    }
    if (this.state.user.gender.length === 0) {
      alert("Seleccione su sexo.");
      this.setState({
        currentTab: (this.state.currentTab = "Step1"),
      });
      return false;
    }
    if (this.state.user.idNationality <= 0) {
      alert("Seleccione su nacionalidad.");
      this.setState({
        currentTab: (this.state.currentTab = "Step1"),
      });
      return false;
    }

    if (this.state.user.idDocumentTypeCountry <= 0) {
      alert("Seleccione un tipo de documento.");
      this.setState({
        currentTab: (this.state.currentTab = "Step1"),
      });
      return false;
    }
    if (this.state.user.nroDocument.length === 0) {
      alert("Ingrese su número de documento.");
      this.setState({
        currentTab: (this.state.currentTab = "Step1"),
      });
      return false;
    }
    if (this.state.user.civilState.length === 0) {
      alert("Seleccione su estado civil.");
      this.setState({
        currentTab: (this.state.currentTab = "Step1"),
      });
      return false;
    }
    if (this.state.user.email.length == 0) {
      alert("Ingrese su correo electrónico.");
      this.setState({
        currentTab: (this.state.currentTab = "Step2"),
      });
      return false;
    }
    if (this.state.user.idResidenceCountry <= 0) {
      alert("Seleccione su país de residencia.");
      this.setState({
        currentTab: (this.state.currentTab = "Step2"),
      });
      return false;
    }
    if (this.state.user.districtAddress.length === 0) {
      alert("Ingrese su ciudad.");
      this.setState({
        currentTab: (this.state.currentTab = "Step2"),
      });
      return false;
    }
    if (this.state.user.address.length === 0) {
      alert("Ingrese su dirección.");
      this.setState({
        currentTab: (this.state.currentTab = "Step2"),
      });
      return false;
    }
    if (this.state.user.phone.length === 0) {
      alert("Ingrese su número de celular.");
      this.setState({
        currentTab: (this.state.currentTab = "Step2"),
      });
      return false;
    }

    return true;
  };

  // Register data
  sendData = async () => {
    // e.preventDefault();
    if (this.eventValidate()) {
      this.setState({
        loadSenData: (this.state.loadSenData = true),
      });

      let user = {};
      user.name = this.state.user.name;
      user.lastname = this.state.user.lastname;
      user.gender = this.state.user.gender;
      user.idFamilyPackage = parseInt(this.state.user.idFamilyPackage);
      user.idContactCountry = Number(this.state.user.idNationality);
      user.idDocumentType = Number(this.state.user.idDocumentTypeCountry);
      if (this.state.user.desDocument.length > 0) {
        user.otherDocument = this.state.user.desDocument;
        user.nroDocument = this.state.user.nroDocument;
      } else {
        user.nroDocument = this.state.user.nroDocument;
        user.otherDocument = null;
      }
      user.email = this.state.user.email;
      user.idResidenceCountry = Number(this.state.user.idResidenceCountry);
      user.City = this.state.user.districtAddress;
      user.StateDistrict = this.state.user.estado;
      user.Comments = this.state.user.Comments;
      user.nroTelef = this.state.user.phone;

      let response = await UserService.registerpreuser(user);

      if (response !== undefined) {
        if (response.status === 1) {
          // alert('Usuario registrado');
          this.setState({
            isComplete: (this.state.isComplete = true),
            loadSenData: (this.state.loadSenData = false),
          });
        } else {
          this.setState({
            loadSenData: (this.state.loadSenData = false),
          });
          alert("Tuvimos un problema. Inténtalo más tarde.");
        }
      } else {
        this.setState({
          loadSenData: (this.state.loadSenData = false),
        });
        alert("Tuvimos un problema. Inténtalo más tarde.");
      }
    }
  };

  render() {
    return (
      <div style={{ background: "white" }}>
        <Card className="bg-dark text-white">
          <Card.Img src={register1} alt="Card image" />
          <Card.ImgOverlay></Card.ImgOverlay>
        </Card>
        {/* <br></br>
        <br></br> */}

        {/* <div className="tw-hidden" >
          <Form.Label className="content-title">PRE-REGISTRO</Form.Label>
          <div
            className="register-inner"
            style={{ display: this.state.isComplete ? "none" : "block" }}
          > */}
        {/* <Form.Label className="content-title">Registro de nuevo socio</Form.Label> */}

        {/* <RegisterMain
              onChange={this.eventhandler}
              eventText={this.eventText}
              eventFooter={this.eventFooter}
              packages={this.state.user.packages}
              kitInit={this.state.user.kitInit}
              currentTab={this.state.currentTab}
              ref={this.childPaypal}
            ></RegisterMain>
            <hr></hr>

            <div className="row justify-content-between">
              <Col style={{ textAlign: "right" }}>
                <Button variant="primary" onClick={this.sendData}>
                  Pre-Registrar
                </Button>
              </Col>
            </div>
          </div>
          <div style={{ display: this.state.isComplete ? "block" : "none" }}>
            <RegisterEndViewPre reload={this.state.reload}></RegisterEndViewPre>
          </div>
          <Modal
            show={this.state.loadSenData}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
            <Modal.Body>
              <div style={{ textAlign: "center" }}>
                <Spinner size="sm" animation="border" variant="dark"></Spinner>
                <Form.Label>&nbsp; Enviando Datos...</Form.Label>
              </div>
            </Modal.Body>
          </Modal>
        </div> */}
        <br></br>
        <br></br>
        <div className="tw-flex tw-flex-col tw-items-center" >
          <div class="linea-tiempo">
            <div class="momento">
              <h3>Paso N°1</h3>
              <div class="descripcion ">
                Contactarte con la persona que te compartió la oportunidad.
                {/* <button className="tw-button tw-bg-green-500 tw-border-none tw-rounded-full tw-px-4 tw-py-2 tw-text-white tw-text-sm tw-w-full tw-text-center">
                  Siguiente
                </button> */}
              </div>
            </div>
            <div class="momento">
              <h3>Paso N°2</h3>
              <div class="descripcion">
                Conectarte a la presentación de negocio por zoom en el horario indicado.
              </div>
            </div>
            <div class="momento">
              <h3>Paso N°3</h3>
              <div class="descripcion">
                Si tienes dudas que no pudiste resolver en el zoom, consúltalo con la persona que te compartió la oportunidad.
              </div>
            </div>
            <div class="momento">
              <h3>Paso N°4</h3>
              <div class="descripcion">
                Elije la opción que más te convenga.
              </div>
            </div>
            <div class="momento">
              <h3>Paso N°5</h3>
              <div class="descripcion">
                Coordina tu registro y pasos a seguir con la persona que te compartió la oportunidad.
              </div>
            </div>
            <div class="momento">
              <h3>¡Bienvenido!</h3>
              <div class="descripcion">
                <img src={pngegg}
                  className="
                    tw-h-44
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>

        {/* <Carousel>
          <Carousel.Item>
            <Image className="d-block w-100" src={register3} />
          </Carousel.Item>
          <Carousel.Item>
            <Image className="d-block w-100" src={register4} />
          </Carousel.Item>
        </Carousel> */}
      </div>
    );
  }
}
