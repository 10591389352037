import ModalContent from "components/ModalContent";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;
function ModalPeriodoDeGracia({
  idsuscription,
  daysavailable,
  idpayment,
  amountoverdue,
  totalPagar,
  funcionTotalPagar,
  funcionNuevaMora,
  diasDisponibles,
  funcionCambioDeFecha,
  funcionDiasUsados,
  funcionAplicaDiasDePG,
  funcionRespuestaPG,
  funcionDataPG,
  daysOverdue,
  changeStateNew
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [days, setDays] = useState(0);
  const [estadoCheckbox, setEstadoCheckbox] = useState(true);
  const [aplicaPeriodoDeGracia, setAplicaPeriodoDeGracia] = useState(0);
  const [flag, setFlag] = useState(0);

  const changeCheckBox = () => {
    setEstadoCheckbox(!estadoCheckbox);
  };
  /* useEffect(() => {
    if (estadoCheckbox === false) {
      console.log("alerta");

      Swal.fire({
        position: "center",
        icon: "info",
        title: "",
        text:
          "No se modificara su fecha de pago lo tendra que realizar segun la fecha de su cronograma",
        showConfirmButton: true,
      });

      //setEstadoCheckbox(true);
      return;
    }
  }, [estadoCheckbox]); */

  const aplicarPeriodoDeGracia = async () => {
    //|| Number(days) >= daysOverdue + 1
    console.log(Number(days), diasDisponibles, daysOverdue, 'qlq');
    if (Number(days) >= diasDisponibles + 1 || Number(days) >= daysOverdue + 1 ) {
      console.log(Number(days), diasDisponibles);
      alert("NO PUEDE PONER MAS DIAS DE LOS QUE LE QUEDAN");
      return;
    }
    if (Number(days) < 0) {
      alert("NO PUEDE INTRODUCIR NUMEROS NEGATIVOS");
      return;
    }
    if (days == "0") {
      setAplicaPeriodoDeGracia(false);
    }

    console.log('daysOverdue', daysOverdue)
    const data = {
      idsuscription,
      days: Number(days),
      flagschedule: estadoCheckbox === true ? 1 : 2,
      daysavailable,
      idpayment,
      amountoverdue,
    };
    let dias = Number(days);
    const { flagschedule } = data;
    //console.log("dias usados =>", dias, "Cronograma?", flagschedule);
    console.log("DATA ====>", data);

    funcionCambioDeFecha(flagschedule);
    funcionDiasUsados(dias);
    try {
      const respuesta = await fetch(`${API_URL}GracePeriod/Normal`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // await respuesta.json();
     // const ver = await respuesta.json();
      //console.log('ver', ver);
      const { objModel } = await respuesta.json();
      console.log('objModel', objModel, 'flagschedule', flagschedule, 'days', days);
      funcionRespuestaPG(objModel);
      funcionDataPG({ ...objModel, dias, flagschedule, aplicaPE: 1 });
      const { totalPay, overdue, percentOverduedetailId } = objModel;
      //console.log('ver objModel',objModel);
      //totalPagar = objModel.totalPay;
      console.log(objModel, 'sds')
      funcionTotalPagar(totalPay);
      funcionNuevaMora(overdue);

      setShow(false);
      funcionAplicaDiasDePG(1);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Aplicado",
        showConfirmButton: false,
        timer: 1500,
      });
      setDays(0);
      setAplicaPeriodoDeGracia(true);
      setEstadoCheckbox(true);
      changeStateNew(dias, flagschedule, percentOverduedetailId, overdue);
    } catch (error) {
      console.log("HAY UN ERROR", error);
    }
  };
  return (
    <>
      <Col sm={8} className="mb-2">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setShow(true)}
        >
          Aplicar periodo de gracia
        </button>
      </Col>

      <ModalContent
        title="Usar perido de gracia"
        isOpen={show}
        handleClose={handleClose}
        centered
      >
        <div>
          <Form inline>
            <div>
              <Form.Group className="mb-3">
                <Form.Label className="text-start">Dias a Aplicar:</Form.Label>
                <Form.Control
                  id="txtDays"
                  type="number"
                  min="1"
                  className="w-100"
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  defaultChecked={estadoCheckbox}
                  onChange={() => changeCheckBox()}
                  label={`Cambiar fecha de cronograma`}
                />
                {estadoCheckbox === false && (
                  <div>
                    <span className="text-danger">
                      No se modificará su fecha de pago. El próximo pago lo
                      tendrás que realizar según la fecha que indica cronograma.
                      Ten en cuenta que ello implica que puedas quedarte
                      inactivo pese a haber pagado la cuota y la mora.
                    </span>
                  </div>
                )}
              </Form.Group>
            </div>

            <Button
              size="sm"
              variant="primary"
              onClick={() => aplicarPeriodoDeGracia()}
              style={{ marginTop: "10px" }}
            >
              <b>Aplicar</b>
            </Button>
          </Form>
        </div>
      </ModalContent>
    </>
  );
}

export default ModalPeriodoDeGracia;
