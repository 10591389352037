import {
    mekADD_TO_CART,
    mekGET_PRODUCTS,
    mekGET_DATA_STORE,
    mekGET_DATA_SCHEDULE_STORE,
    mekGET_ORDERS,
} from './types'

/* Setting the initial state of the reducer. */
const initialState = {
    mekproducts: [],
    cart: [],
    deliveryType: 0,
    total: 0,
    schedule: [],
    store: [],
    scheduleSelect: null,
    orders: [],
}

/* A reducer. */
export default (state=initialState, action) => {
    /* A switch statement. */
    switch(action.type) {
        /* Returning the state and the payload. */
        case mekGET_PRODUCTS.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                mekproducts: action.payload,
            }
        /* Adding the product to the cart. */
        case mekADD_TO_CART.ADD_TO_CART:
            return {
                ...state,
                cart: [...state.cart,action.payload],
            }
        /* Updating the cart. */
        case mekADD_TO_CART.UPDATE_CART:
            return {
                ...state,
                cart: state.cart.map(product => product.id === action.payload.id ? action.payload : product),
            }
        /* Removing the product from the cart. */
        case mekADD_TO_CART.REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter(product => product.id !== action.payload.id),
            }
        case mekADD_TO_CART.SET_DELIVERY_TYPE:
            return {
                ...state,
                deliveryType: action.payload,
            }
        case mekGET_DATA_STORE.GET_DATA_STORE_SUCCESS:
            return {
                ...state,
                store: action.payload,
            }
        case mekGET_DATA_SCHEDULE_STORE.GET_DATA_SCHEDULE_SUCCESS:
            return {
                ...state,
                schedule: action.payload,
            }
            // schedule
        case mekGET_DATA_SCHEDULE_STORE.SET_SCHEDULE_STORE:
            return {
                ...state,
                scheduleSelect: action.payload,
            }
            
        // orders
        case mekGET_ORDERS.GET_ORDERS_REQUEST:
            return {
                ...state,
                orders: [],
            }
        case mekGET_ORDERS.GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload,
            }
        case mekGET_ORDERS.GET_ORDERS_FAILURE:
            return {
                ...state,
                orders: [],
            }
        /* Returning the state. */
        default:
            return state
    }
}