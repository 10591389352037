/* Exporting the object mekGET_PRODUCTS. */
export const mekGET_PRODUCTS = {
    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
}

/* Exporting the object mekADD_TO_CART. */
export const mekADD_TO_CART = {
    ADD_TO_CART: 'ADD_TO_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    UPDATE_CART: 'UPDATE_CART',
    LOAD_CART: 'LOAD_CART',
    SET_DELIVERY_TYPE: 'SET_DELIVERY_TYPE',
}

export const mekGET_DATA_STORE = {
    GET_DATA_STORE_REQUEST: 'GET_DATA_STORE_REQUEST',
    GET_DATA_STORE_SUCCESS: 'GET_DATA_STORE_SUCCESS',
    GET_DATA_STORE_FAILURE: 'GET_DATA_STORE_FAILURE',
}

export const mekGET_DATA_SCHEDULE_STORE = {
    GET_DATA_SCHEDULE_REQUEST: 'GET_DATA_SCHEDULE_REQUEST',
    GET_DATA_SCHEDULE_SUCCESS: 'GET_DATA_SCHEDULE_SUCCESS',
    GET_DATA_SCHEDULE_FAILURE: 'GET_DATA_SCHEDULE_FAILURE',

    SET_SCHEDULE_STORE: 'SET_SCHEDULE_STORE',
}

export const mekGET_ORDERS = {
    GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
    GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',
}