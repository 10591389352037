import authHeader from "./auth-header";
import AuthService from "./auth.service";

const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;

class WalletService {
  // Electronic
  async registerTransferElectronic(data) {
    let url = API_URL + "withdrawalrequest/electronicpurse/solicit";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  async registerTransferAccount(data) {
    let url = API_URL + "withdrawalrequest/accountBank/solicit";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  // history
  async getHistoryTransferElectronic(data) {
    let url = API_URL + "withdrawalrequest/electronicpurse/user";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  async getHistoryTransferBank(data) {
    let url = API_URL + "withdrawalrequest/accountBank/user";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }
  // Get wallet
  async getWalletInformation(id) {
    let url = API_URL + "wallet/user/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getWalletRegisters(data, token) {
    const currentUser = AuthService.getCurrentUser();

    let url = API_URL + "walletTransaction/list";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUser.access_Token}`,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  // Validate transaction
  async validateTransaccion(data) {
    let url = API_URL + "wallet/transaction/validate";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  // Get token
  async getToken(idUser) {
    let url = API_URL + "wallet/token/create/" + idUser;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  //generate token
  async generateToken(IdUserSender, IdUserReceiving) {
    let data = {
      IdUserSender: IdUserSender,
      IdUserReceiving: IdUserReceiving,
    };
    let url = API_URL + "wallet/token/create";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }
  // Verify token
  async verifyToken(idUser, data) {
    let url = API_URL + "wallet/token/validate/" + idUser;
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  // Process transaction
  async registerTransaction(data, user) {
    let url = API_URL + "walletTransaction/transfer";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access_Token}`,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  // Get electronic types
  async getElectronic() {
    let url = API_URL + "electronicpursecompany";
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // get electronic
  async getElectronicbyUser(idUser) {
    let url = API_URL + "electronicpurse/user/" + idUser;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  // get electronic
  async getAccountBanks(idUser) {
    let url = API_URL + "AccountBank/" + idUser;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  // register Aelectroni company user
  async registerElectronicPurse(data) {
    let url = API_URL + "electronicpurse/register";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  // Update electroni company user
  async updateElectronicPurse(data) {
    let url = API_URL + "electronicpurse/update";
    return await fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataJson) {
        return dataJson;
      })
      .catch(function (err) {
        console.error(err);
        return undefined;
      });
  }

  // delete electronic
  async deleteElectronicPurse(idUser) {
    let url = API_URL + "electronicpurse/delete/" + idUser;
    return await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // get transaction by user ME
  async getTransactionByUserME(idUser) {
    let url = API_URL + "withdrawalrequest/accountBank/user";
    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sizeList: 100,
        NroPage: 1,
        ParametersSearched: {
          IdSocioSearch: Number(idUser),
          typeWallet: 31
        },
      }),
    }).then((res) => res.json())
      .then((response) => {
        return response;
      }
      )
      .catch((error) => {
        return undefined;
      });
    }
  }

export default new WalletService();
