import moment from "moment";
import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import authService from "../../services/auth.service";

const linkServer = `${process.env.REACT_APP_APP_URL}`;

export default class CountDownSession extends Component {
  constructor() {
    super();
    this.state = { time: {}, seconds: 600, showAlert: false };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    if (
      sessionStorage.length > 0 &&
      sessionStorage.getItem("sessiontoken") !== null &&
      sessionStorage.getItem("sessiontoken") !== undefined
    ) {
      const dateExpired = new Date(sessionStorage.getItem("sessiontoken"));
      const momentDay = moment(dateExpired);
      const diffMoment = momentDay.diff(moment(new Date()));
      const momentSeconds = diffMoment / 1000;
      this.setState({ seconds: momentSeconds });
    }

    this.startTimer();
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds <= 0) {
      // // clearInterval(this.timer);
      this.setState({ seconds: 0 });
      // Logout
      this.logOut();
    }

    if (seconds <= 30) {
      this.setState({
        showAlert: true,
      });
    }
  }
  logOut = () => {
    authService.logout();
    window.open(linkServer, "_self");
  };

  handleRefresh = async () => {
    let response = await authService.refreshtoken();
    if (response && response.status === 1) {
    } else {
      alert("Ocurrio un problema al actualizar su sesion.");
      this.logOut();
    }
  };

  render() {
    return (
      <div>
        <Modal show={this.state.showAlert}>
          <Modal.Body>
            {" "}
            <Form.Label>Su sesion expirará en: </Form.Label>
            <br />
            <div style={{ textAlign: "center" }}>
              <Form.Text style={{ fontSize: 24 }}>
                {`${this.state.time.h} H ${this.state.time.m} m ${this.state.time.s} s`}{" "}
              </Form.Text>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="primary"
              onClick={(e) => this.handleRefresh()}
            >
              Mantener sesión
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
