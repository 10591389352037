import React, { useState } from 'react';
import { Form, Modal } from "react-bootstrap";
import { TextField, 
    Radio, 
    RadioGroup, 
    FormControl, 
    FormLabel, 
    FormControlLabel, 
    Grid, 
    Typography, 
    Box,
    ListItemButton,
    CircularProgress
} from "@mui/material";
import userService from "../../services/user.service";
import Buttonmui from "@mui/material/Button";
import { ReactComponent as Imagenlogo } from "../../assets/svg/logoinclub.svg";

export const SearchPartner = ({showModalSearchPartner, showSearchPartner, result}) => {
    const [typeSearchPatners, setTypeSearchPatners] = useState(1);
    const [resultPartners, setResultPartners] = useState([]);
    const [currentPartner, setCurrentPartner] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loadingSearch, setLoadingSearch] = useState(false)

    
    const onChangeText = async(value, type) => {
        setInputValue(value);

       if( value && type ){
            setLoadingSearch(true)
            const { objModel } = await userService.onFetchSearchPartners(value, 1);
        
            if( objModel.length == 0 ){
                alert('No se encontraron resultados');
            }

            setTimeout(() => {
                setLoadingSearch(false)
                setResultPartners(objModel);
            }, 500);
       }
    }

    const onShowDetailsPartner = async(id) => {
        const searchCurretPartner = resultPartners.filter(item => item.id == id);
        const currentParnert = searchCurretPartner.length > 0 ? searchCurretPartner[0] : null;
        let type = typeSearchPatners;
        if( currentParnert != null && typeSearchPatners == 2 ){
          type = 1;
        } 


        if( currentParnert !== null ){
            const { objModel } = await userService.onFetchSearchImagePartner(currentParnert.id);
            
            if( objModel != null ){
              currentParnert.profilePicture = `data:image/png;base64,${objModel}`;
            }
        }
          
        setCurrentPartner(currentParnert);
        onChangeText(currentParnert.username, type);

    }

    return (
        <>
            <Modal
                show={showModalSearchPartner}
                backdrop="static"
                centered
            >
                <Modal.Body>
                <Form className="text-center">
                    <section className="container-logo">
                    <Imagenlogo></Imagenlogo>
                    </section>
                    <h3 className="pb-2">Inclub</h3>
                    <Form.Group className="mb-3">
                    <FormLabel id="demo-row-radio-buttons-group-label">Buscar por:</FormLabel>
                    <FormControl style={{width: '100%'}}>
                        <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        >
                        <FormControlLabel 
                            value="1"  
                            checked={typeSearchPatners === 1} 
                            onChange={() => setTypeSearchPatners(1)} 
                            control={<Radio />} label="User Name" 
                        />
                        <FormControlLabel 
                            value="2" 
                            checked={typeSearchPatners === 2}  
                            onChange={() => setTypeSearchPatners(2)} 
                            control={<Radio />} 
                            label="N° Documento" 
                        />
                        </RadioGroup>
                    </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-5 position-relative">
                        <TextField 
                            id="partners"
                            label={`Busca por ${typeSearchPatners == 1 ? 'User Name' : 'N° Documento'}`} 
                            variant="outlined" 
                            fullWidth
                            value={inputValue}
                            onChange={(e) => {
                                if( e.target.value == '' ){
                                    setResultPartners([]);
                                }

                                setInputValue(e.target.value)
                            }}
                            InputProps={{
                            type: 'search',
                            }}
                        />
                        <div style={{
                            position: 'absolute',
                            zIndex: 1024,
                            backgroundColor: '#fff',
                            width: '100%',
                            padding: '15px 0',
                            display: resultPartners.length > 0 && inputValue != '' ? 'block' : 'none',
                            flexDirection:'column',
                            boxShadow: '0px 7px 12px rgba(0, 0, 0, 0.25)',
                            overflowY: 'scroll',
                            height: resultPartners.length > 3 ? '220px' : 'auto'
                            
                        }}>
                            {
                                !loadingSearch && resultPartners.length > 0 &&
                                resultPartners.map((option, index) => (
                                    <ListItemButton style={{width: '100%'}} key={index} onClick={() => onShowDetailsPartner(option.id)}>
                                        <Grid sx={{ width: '100%', wordWrap: 'break-word' }}>
                                            <Box>
                                                { option.name } { option.lastname }
                                            </Box>
                                            <Typography variant="body2" color="text.secondary">
                                                { `username: ${option.username}, nroDoc: ${option.nrdocumento}` }
                                            </Typography>
                                        </Grid>
                                    </ListItemButton>
                                ))
                            }
                            {
                                loadingSearch &&
                                <CircularProgress />
                            }
                            {
                                !loadingSearch && resultPartners.length == 0 &&
                                <Typography variant="body2" color="text.secondary">
                                    No se encontraron resultados
                                </Typography>
                            }
                        </div>
                    </Form.Group>
                    <Buttonmui
                    variant="contained"
                    size="large"
                    fullWidth
                    className="mt-5"
                    type='button'
                    onClick={() => {
                        if( !loadingSearch ){
                            onChangeText(inputValue, typeSearchPatners)
                        }
                    }}
                    >
                        {loadingSearch ? (
                            <div style={{ margin: "auto" }}>
                            <div className="spinnerinicio">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            </div>
                        ) : (
                            "Buscar Socio"
                        )}
                        
                    </Buttonmui>
                    <Buttonmui
                    variant="outlined"
                    size="large"
                    fullWidth
                    className="mt-3"
                    type='button'
                    onClick={() => showSearchPartner()}
                    >
                    Cerrar Modal
                    </Buttonmui>
                </Form>
                </Modal.Body>
            </Modal>
            <Modal
                show={currentPartner !== null}
                backdrop="static"
                centered
                className="content-details_Partner"
            >
                <Modal.Body>
                {
                    currentPartner != null && 
                    <div className="row">
                    <div className="col-sm-5 col-12">
                        <div className="ps-2 w-100 h-100">
                            <div className="bg-image-partner" style={{backgroundImage: `url(${currentPartner.profilePicture})`}}>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7 col-12">
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <Box>
                        Nombres:
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                        { currentPartner.name  }
                        </Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <Box>
                        Apellidos:
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                        { currentPartner.lastname }
                        </Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <Box>
                        Fecha registro:
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                        { currentPartner.createDate }
                        </Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <Box>
                        Teléfono:
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                         { currentPartner.nroTelf }
                        </Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <Box>
                        País de residencia:
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                        { currentPartner.countryName }
                        </Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <Box>
                        Estado:
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                        { `${ currentPartner.idState == 1 ? 'Activo' : 'Inactivo'}` }
                        </Typography>
                    </div>
                    </div>
                </div>
                }
                <div className="mt-5 d-flex flex-row justify-content-center align-items-center">
                <Buttonmui
                    variant="outlined"
                    size="large"
                    onClick={() => setCurrentPartner(null)}
                    >
                    Buscar de nuevo
                    </Buttonmui>
                    <Buttonmui
                    variant="contained"
                    size="large"
                    className="ms-3"
                    onClick={() => {
                        const { username } = {...currentPartner};

                        showSearchPartner();
                        result(username);
                        setCurrentPartner(null);
                    }}
                    >
                     Registrarme Ahora
                    </Buttonmui>
                </div>

                </Modal.Body>
            </Modal>
            </>
    )
}
