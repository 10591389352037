import React, { useEffect, useState } from 'react'
import { LoadProducts } from 'services/meKeola.service'
import { useDispatch, useSelector } from 'react-redux'
import CardProductK from './CardProductK';
import './MEKeolaShop.css'
import { Link } from 'react-router-dom';
import KFloat from 'components/KFloat';
/* A function that returns a JSX element. */
const MEKeolaShop = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { mekproducts, cart } = useSelector((state) => state.shop);
  console.log('ver PRODUCTOS =>',mekproducts);
  // load products
  useEffect(() => {
    dispatch(LoadProducts())
  }, [])
  /**
   * When the user types in the search bar, the value of the search bar is set to the state of search.
   */
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }
  return (
      <main className="shopkeola-main">
        {/* search product */}
        <hr />
        <div className="shopkeola-search">
          <input type="text" placeholder="Search"
            value={search} onChange={handleSearch}
          />
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>
          </button>
        </div>
        {/* line */}
        <hr />
        <div className="content">
          { /* A ternary operator. It is a shorthand way of writing an if/else statement. */}
          {search === '' ?
            /* Mapping through the products and returning a card for each product. */
            mekproducts.map((product, key) => {
              return (
                <CardProductK key={key} product={product} cart={cart} />
              )
            }) :
            /* Mapping through the products and returning a card for each product. */
            mekproducts.map((product, key) => {
              /* Checking if the product name includes the search. If it does, it returns the card. */
              if (product.name.toLowerCase().includes(search.toLowerCase()) || product.description.toLowerCase().includes(search.toLowerCase()) ) {
                return (
                  <CardProductK key={key} product={product} cart={cart} />
                )
              }
            }
            )
          }
        </div>
        {/* boton flotante */}
        {
          cart.length > 0 &&
          <KFloat link="/kshop/cart" name="Carrito" extra={
            `S/. ${cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0)?.toFixed(2)}`
          } />
          // <div className="btn-fixed">
          //   <Link to="/kshop">
          //       Ir a pagar
          //       &nbsp;
          //       <span>
          //         S/. {cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0)}
          //       </span>
          //   </Link>
          // </div>
        }
      </main>
  )
}

export default MEKeolaShop
