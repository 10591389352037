const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register() {
  console.log(process.env.NODE_ENV);
  const swUrl = process.env.NODE_ENV==="production-test" || process.env.NODE_ENV==="development"?'./sw-test.js':'./sw-production.js';
  if (isLocalhost) {
    console.log("El Service Worker, esta configurado en localhost con fines de desarrollo")
  }
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register(swUrl)
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}