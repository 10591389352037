import React, { Component } from "react";
import ImageUploader from "react-images-upload";
import {
  Form,
  Row,
  Col,
  Image,
  Button,
  Table,
  BreadcrumbItem,
} from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";

import MediaQuery from "react-responsive";
import logoBcp from "../../images/bcp-logo.jpg";
import { type } from "jquery";

export default class PayVoucherDaVivienda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nacionalidadUsuario: this.props.nacionalidadUsuario,
      type: 0,
      code: "",
      titular: "",
      comission: 0,
      name: "",
      voucherbase: "",
      mount: this.props.totalPagar === null || this.props.totalPagar ===undefined ? this.props.total : this.props.totalPagar,
      typeExchange: 1,
      typeExchangeDes: "Dólares",
      exchange: props.exchange,
      pictures: [],
      vouchersTable: [
        {
          comision: 0,
          comisionExchange: 0,
          type: "0",
          typeDescription: "",
          code: "",
          titular: "",
          voucherBase: "",
          imageRaw: {},
          name: "",
          bank: "DAVIVIENDA",
          amount: "0.00",
          totalPagarVoucher: "0.00"
        },
      ],
      bank: "BCP",
      idBank: 6,
      typePays: [],
      typeDolars: [],
      typeSoles: [],
    };
  }

  componentDidMount() {
    if (this.props.typeBank != undefined) {
      let types = this.props.typeBank;
      //  console.log('ver types',types)
      types.forEach((item, idx) => {
        if (item.idTipoPago === this.state.idBank) {
          this.setState({
            typePays: (this.state.typePays = item.subTipoPagos),
          });

          // create idsDolars and Soles
          let idSoles = [];
          let idDolars = [];
          item.subTipoPagos.forEach((item) => {
            if (item.statusDolares === 1) {
              idDolars.push(item.idSubTipoPago);
            }
            if (item.statusSoles === 1) {
              idSoles.push(item.idSubTipoPago);
            }
          });
          this.setState({
            typeDolars: (this.state.typeDolars = idDolars),
            typeSoles: (this.state.typeSoles = idSoles),
          });
        }
      });
    }
  }

  validation(item) {
    if (Number(item.type) <= 0) {
      alert("Seleccione un tipo de operación.");
      return false;
    }
    if (item.code.length <= 0) {
      alert("Ingrese el código de operación.");
      return false;
    }
    if (item.voucherBase.length <= 0) {
      alert("Seleccione una imagen.");
      return false;
    } else {
      //validar que solo sea imagen jpg
      var extension = String(item.name);
      var extension1 = extension.toLowerCase();
      var e1 = extension1.substring(extension.length - 3, extension.length);
      var e2 = extension1.substring(extension.length - 4, extension.length);
      // console.log(extension1)
      // console.log(e1)
      // console.log(e2)
      if (
        e1 !== "jpg" &&
        e1 !== "png" &&
        e2 !== "jfif" &&
        e2 !== "pjpeg" &&
        e2 !== "jpeg" &&
        e1 !== "pjp"
      ) {
        //          peg                      peg                     pjp
        alert("Seleccione un archivo de imagen");
        return false;
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AQUI ES LA VALIDACION
    if (item.amount == 0) {
      alert("El monto no puede ser 0");
      return false;
    }
    /*  if (this.state.typeExchange == 2) {
      console.log("estas en soles");
      if (
        item.amount == 0 ||
        item.amount > this.state.mount * this.state.exchange + 18 ||
        item.amount < this.state.mount * this.state.exchange - 18
      ) {
        alert("A puesto un valor mas alto o mas bajo de lo establecido.");
        return false;
      }
    }
    if (this.state.typeExchange == 1) {
      console.log("estas en dolares");
      if (
        item.amount == 0 ||
        item.amount > this.state.mount + 10 ||
        item.amount < this.state.mount - 10
      ) {
        alert("A puesto un valor mas alto o mas bajo de lo establecido.");
        return false;
      }
    } */

    return true;
  }

  onSave = (e) => {
    //AQUI SE GUARDA LOS DATOS DE LA OPERACION
    // Verify
    let vouchers = this.state.vouchersTable;
    let i = 0;
    let flag = true;
    for (i = 0; i < vouchers.length; i++) {
      // Validate each voucher
      vouchers[i].typeExchange = this.state.typeExchange;
      vouchers[i].typeExchangeDes = this.state.typeExchangeDes;
      //vouchers[i].amount = this.state.vouchersTable.amount;
      if (!this.validation(vouchers[i])) {
        flag = false;
        break;
      }
    }
    if (flag) {
      this.props.addVoucher(vouchers);
      //console.log(this.state.vouchersTable);
      this.props.close();
    }
  };

  handleItem = (e, field, id) => {
    let vouchers = this.state.vouchersTable;
    let i = 0;
    for (i = 0; i < vouchers.length; i++) {
      if (i === id) {
        vouchers[i][field] = e.target.value;

        if( field == 'amount' ){
          const comissionSum = this.state.typeExchange == 1 ? vouchers[i]['comision'] : vouchers[i]['comisionExchange'];
          vouchers[i]['totalPagarVoucher'] = Number(e.target.value) + Number(comissionSum);
        }
      }
    }
    this.setState({
      vouchersTable: (this.state.vouchersTable = vouchers),
    });
  };

  // Handle exchange
  handleExchange = (e) => {
    let value = Number(e.target.value);
    // 1 -> dolar
    // 2 -> soles
    console.log("TIPO DE MONEDA =>", value);
    if (value === 1) {
      this.setState({
        typeExchange: (this.state.typeExchange = 1),
        typeExchangeDes: "Dólares",
      });
    } else if (value === 2) {
      this.setState({
        typeExchange: (this.state.typeExchange = 2),
        typeExchangeDes: "Soles",
      });
    } else if (value === 3) {
      this.setState({
        typeExchange: (this.state.typeExchange = 3),
        typeExchangeDes: "Pesos",
      });
    }

    //Verify tupe operation
    let isnotAllow = false;
    let vouchers = this.state.vouchersTable;
    let idSoles = this.state.typeSoles;
    let idDolares = this.state.typeDolars;

    for (let i = 0; i < vouchers.length; i++) {
      //verfy is selected type operation is allowed
      if (Number(vouchers[i].type) > 0) {
        if (value === 1 && idDolares.includes(Number(vouchers[i].type))) {
          continue;
        } else if (value === 2 && idSoles.includes(Number(vouchers[i].type))) {
          continue;
        } else {
          isnotAllow = true;
          vouchers[i].type = 0;
          vouchers[i].comision = 0;
          vouchers[i].comisionExchange = 0;
        }
      }
    }
    this.setState({
      vouchersTable: (this.state.vouchersTable = vouchers),
    });

    if (isnotAllow) {
      alert("Verifique el tipo de operación de cada registro.");
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      //console.log('Error: ', error);
    };
  }

  // Handle table voucher
  onFileChange = (event, id) => {
    let image = event.target.files[0];
    let voucher = "";
    if (image !== undefined) {
      this.getBase64(image, (result) => {
        let vouchers = this.state.vouchersTable;
        let i = 0;
        for (i = 0; i < vouchers.length; i++) {
          if (i === id) {
            vouchers[i].voucherBase = result;
            vouchers[i].name = image.name;
            vouchers[i].imageRaw = image;
          }
        }
        this.setState({
          vouchersTable: (this.state.vouchersTable = vouchers),
        });

        //console.log(vouchers);
      });
    } else {
      let vouchers = this.state.vouchersTable;
      let i = 0;
      for (i = 0; i < vouchers.length; i++) {
        if (i === id) {
          vouchers[i].voucherBase = "";
          vouchers[i].name = "";
          vouchers[i].imageRaw = {};
        }
      }
      this.setState({
        vouchersTable: (this.state.vouchersTable = vouchers),
      });
    }
  };

  // handle selected type operation
  handleSelect = (e, id) => {
    let textDescription = e.target.options[e.target.selectedIndex].text;
    console.log(
      "textDescription,id,e.target.value =>",
      textDescription,
      id,
      e.target.value
    );

    let vouchers = this.state.vouchersTable;
    console.log("vouchers,vouchers.length =>", vouchers, vouchers.length);
    let i = 0;
    for (i = 0; i < vouchers.length; i++) {
      if (i === id) {
        // Verfidy is selected item is avaible accorgin type chage
        //typeExchange == 1 -> dolar
        let typeOper = undefined;
        console.log("this.state.typePays =>", this.state.typePays);
        this.state.typePays.forEach((item) => {
          if (item.idSubTipoPago === Number(e.target.value)) {
            typeOper = item;
            // vouchers[i].comision = item.dolares;
            // vouchers[i].comisionExchange = item.soles;
          }
        });
        if (typeOper !== undefined) {
          console.log("typeOper =>", typeOper);
          console.log("this.state.typeExchange =>", this.state.typeExchange);
          console.log("typeOper.statusDolares =>", typeOper.statusDolares);
          if (this.state.typeExchange === 1 && typeOper.statusDolares === 1) {
            // Dolares
            vouchers[i].comision = typeOper.dolares;
            vouchers[i].comisionExchange = typeOper.soles;
            vouchers[i].type = e.target.value;
            vouchers[i].typeDescription = textDescription;
          } else if (
            this.state.typeExchange === 2 &&
            typeOper.statusSoles === 1
          ) {
            // soles
            // Dolares

            vouchers[i].comision = typeOper.dolares;
            vouchers[i].comisionExchange = typeOper.soles;
            vouchers[i].type = e.target.value;
            vouchers[i].typeDescription = textDescription;
          } else if (
            this.state.typeExchange === 3 &&
            typeOper.statusSoles === 1
          ) {
            // soles
            // Dolares
            //pesos colombianos

            vouchers[i].comision = typeOper.dolares;
            vouchers[i].comisionExchange = typeOper.soles;
            vouchers[i].type = e.target.value;
            vouchers[i].typeDescription = textDescription;
          } else {
            vouchers[i].type = 0;
            vouchers[i].comision = 0;
            vouchers[i].comisionExchange = 0;

            alert(
              textDescription +
                ", no está permitido para este tipo de moneda. Seleccione otro tipo de operación."
            );
          }
        } else {
        }

        break;
      }
    }
    this.setState({
      vouchersTable: (this.state.vouchersTable = vouchers),
    });
  };

  addRegister = () => {
    let register = {
      comision: 0,
      comisionExchange: 0,
      type: 0,
      typeDescription: "",
      code: "",
      titular: "",
      voucherBase: "",
      name: "",
      bank: "DAVIVIENDA",
      nota: "",
      amount: "0.00",
      imageRaw: "",
      typeExchange: this.state.typeExchange,
    };
    let list = this.state.vouchersTable;
    list.push(register);
    this.setState({
      vouchersTable: (this.state.vouchersTable = list),
    });
    //console.log(this.state.vouchersTable)
  };

  deleteVoucher = (idx) => () => {
    const vouchersTable = [...this.state.vouchersTable];
    vouchersTable.splice(idx, 1);
    this.setState({ vouchersTable });
  };

  roundCalculate = (value) => {
    let round = Math.round((value + Number.EPSILON) * 100) / 100;
    return round;
  };

  render() {
    const {
      vouchersTable,
      typePays,
      typeExchange,
      nacionalidadUsuario,
    } = this.state;
    const fontSize = this.props.fontSize;
   // console.log("ver typePays render vaucherDaVivienda=>", typePays);
    return (
      <div>
        <Row>
          <Col sm={12}>
            <Form.Label>
              <b>Valle Encantado SAC </b>
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Label>
              <b>Cuenta Corriente en pesos: </b>
            </Form.Label>
            <br></br>
            <Form.Label>006890315630</Form.Label>

            <br></br>
          </Col>
          <Col sm={6}>
            <Form.Label>
              <b>Cuenta Corriente en dólares: </b>
            </Form.Label>
            <br></br>
            <Form.Label>000-000000-0-00</Form.Label>

            <br></br>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Label className="textAlert">
              * Los pagos a través de este medio tienen una COMISIÓN de acuerdo
              al tipo de operación.
            </Form.Label>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col sm={8}>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                <b>Moneda:</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Select
                  size="sm"
                  defaultValue={"1"}
                  style={{ fontSize: fontSize }}
                  onChange={(e) => this.handleExchange(e)}
                >
                  {/* <option value="0" disabled>Seleccione</option> */}
                  <option value="1">Dólares</option>
                  <option value="3">Pesos colombianos</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        {/*////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// BCP SOLES */}
        <Row>
          <Col
            sm={4}
            style={{ display: typeExchange === 3 ? "inline" : "none" }}
          >
            <Form.Label>
              <b>Tipo de Cambio:</b> {4802.97}
            </Form.Label>
          </Col>
          <Col sm={4}>

          <Form.Label>
            <b>Monto DaVivienda $:</b> {this.state.mount}
          </Form.Label>

          </Col>
        
          <Col
            sm={4}
            style={{ display: typeExchange === 3 ? "inline" : "none" }}
          >
            <Form.Label>
              <b>Monto DaVivienda :</b>{" "}
              {(this.state.mount * 4802.97).toLocaleString("en-US", {
                style: "currency",
                currency: "COL",
              })}
            </Form.Label>
          </Col>
        </Row>
        {/*<Row>
          <Form.Label>
            <b>Comision $:</b> {vouchersTable[0].comision}
          </Form.Label>
            </Row>*/}
        <Row>
          {typeExchange == 3 ? (
            <Form.Label>
              <b>Monto total a pagar :</b>{" "}
              {(this.state.mount * 4802.97).toLocaleString("en-US", {
                style: "currency",
                currency: "COL",
              })}
            </Form.Label>
          ) : (
            <Form.Label>
              <b>Monto total a pagar $:</b>{" "}
              {this.state.mount}
            </Form.Label>
          )}
        </Row>

        {}

        <hr></hr>
        <Row>
          <Col sm={12}>
            <div className="main" style={{ fontSize: 12, color: "green" }}>
              (*) Después de agregar los comprobantes de pago no olvide
              REGISTRAR el formulario.
            </div>
          </Col>
        </Row>

        <Row>
          {/*modificandoooooooooooooooooooo*/}
          <MediaQuery maxWidth={800}>
            <Table responsive>
              {vouchersTable.map((item, idx) => (
                <div
                  key={idx}
                  style={{ display: "inline-table", width: "100%" }}
                >
                  <tr>
                    <th style={{ padding: "15px" }}>Tipo de operación </th>

                    <td>
                      <Form.Control
                        as="select"
                        defaultValue="0"
                        value={Number(item.type)}
                        style={{ fontSize: fontSize }}
                        onChange={(e) => this.handleSelect(e, idx)}
                      >
                        <option value="0" disabled>
                          Operación
                        </option>
                        {/*       {console.log('typePays =>',typePays)} */}
                        {typePays.map((item, id) => (
                          <option key={id} value={item.idSubTipoPago}>
                            {item.descripcion}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "15px" }}>Commision</th>
                    <td style={{ verticalAlign: "middle" }}>
                      {typeExchange === 2
                        ? "S/ " + item.comisionExchange
                        : "$ " + item.comision}
                    </td>
                  </tr>

                  <tr>
                    <th style={{ padding: "15px" }}>(*)Código de operación</th>
                    <td>
                      <Form.Control
                        value={item.code}
                        maxLength={200}
                        onChange={(e) => this.handleItem(e, "code", idx)}
                        style={{ fontSize: fontSize }}
                      ></Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "15px" }}>Nota</th>
                    <td>
                      <Form.Control
                        value={item.nota}
                        maxLength={200}
                        onChange={(e) => this.handleItem(e, "nota", idx)}
                        style={{ fontSize: fontSize }}
                      ></Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "15px" }}>Monto</th>
                    <td>
                      <Form.Control
                        value={item.amount}
                        type="number"
                        min="0"
                        defaultValue="0.00"
                        style={{ fontSize: fontSize }}
                        onChange={(e) => this.handleItem(e, "amount", idx)}
                        step="0.01"
                        inputmode="decimal"
                      ></Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "15px" }}>Imagen</th>
                    <td style={{ verticalAlign: "middle" }}>
                      {/* {item.name.length > 0 &&
                                                    <input type="file"
                                                        value={item.imageRaw}
                                                        accept="image/png, image/jpeg"
                                                        onChange={e => this.onFileChange(e, idx)}/>
                                                    }
                                                    {item.name.length == 0 && */}
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => this.onFileChange(e, idx)}
                      />
                      {/* } */}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ fontSize: 15 }}
                      onClick={this.deleteVoucher(idx)}
                    >
                      <div>
                        <RiDeleteBinLine></RiDeleteBinLine>
                      </div>
                    </td>
                  </tr>
                </div>
              ))}
            </Table>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {" "}
              <Button
                size="sm"
                variant="dark"
                onClick={(e) => {
                  this.addRegister();
                }}
              >
                +
              </Button>
            </div>
          </MediaQuery>
        </Row>
        <Row>
          <MediaQuery minWidth={800}>
            <Table responsive>
              <thead>
                <tr>
                  <th>Tipo de operación </th>
                  <th>Comisión</th>
                  <th>(*)Código de operación</th>
                  <th>Nota</th>
                  <th>Monto a pagar</th>
                  <th>Total a pagar</th>
                  <th>Imagen</th>
                  <th style={{ textAlign: "center" }}>
                    {" "}
                    <Button
                      size="sm"
                      variant="dark"
                      onClick={(e) => {
                        this.addRegister();
                      }}
                    >
                      +
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {vouchersTable.map((item, idx) => (
                  <tr key={idx}>
                    {/* TIPO DE OPERACION */}
                    <td>
                      <Form.Select
                        size="sm"
                        value={Number(item.type)}
                        style={{ fontSize: fontSize }}
                        onChange={(e) => this.handleSelect(e, idx)}
                      >
                        <option value="0" disabled>
                          Operación
                        </option>
                        {/*   {console.log('ver typePays ==>',typePays)} */}
                        {typePays.map((item, id) => (
                          <option key={id} value={item.idSubTipoPago}>
                            {item.descripcion}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    {/* TIPO DE OPERACION */}
                    <td style={{ verticalAlign: "middle" }}>
                      {typeExchange === 2
                        ? "S/ " + item.comisionExchange
                        : "$ " + item.comision}
                    </td>
                    <td>
                      <Form.Control
                        value={item.code}
                        maxLength={200}
                        onChange={(e) => this.handleItem(e, "code", idx)}
                        style={{ fontSize: fontSize }}
                      ></Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        value={item.nota}
                        maxLength={200}
                        onChange={(e) => this.handleItem(e, "nota", idx)}
                        style={{ fontSize: fontSize }}
                      ></Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        value={(item.amount = 0 ? "0.00" : item.amount)}
                        type="text"
                        min="0"
                        style={{ fontSize: fontSize }}
                        onChange={(e) => this.handleItem(e, "amount", idx)}
                        step="0.01"
                      ></Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        value={item.totalPagarVoucher}
                        type="text"
                        min="0"
                        style={{ fontSize: fontSize }}
                        step="0.01"
                        disabled
                      ></Form.Control>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      {/* {item.name.length > 0 &&
                                                    <input type="file"
                                                        value={item.imageRaw}
                                                        accept="image/png, image/jpeg"
                                                        onChange={e => this.onFileChange(e, idx)}/>
                                                    }
                                                    {item.name.length == 0 && */}
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => this.onFileChange(e, idx)}
                      />
                      {/* } */}
                    </td>

                    <td
                      style={{ fontSize: 15 }}
                      onClick={this.deleteVoucher(idx)}
                    >
                      <RiDeleteBinLine></RiDeleteBinLine>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </MediaQuery>
        </Row>

        <Row className="row justify-content-between">
          <Col ms={4}>
            <Button variant="secondary" size="sm" onClick={this.props.close}>
              Cancelar
            </Button>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button variant="primary" size="sm" onClick={this.onSave}>
              Agregar
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
