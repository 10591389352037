import { useEffect, useState } from "react";
import Validation from "components/utils/Validation";
import { ReactComponent as IconCarouselArrowLeft } from "./resources/arrow-left.svg";
import { ReactComponent as IconCarouselArrowRight } from "./resources/arrow-right.svg";
import LoadApi from "components/validators/LoadApi";
import "./CarouselTrips.css";

export default function CarouselTrips(props) {
  const [listcarouseltrips, setlistcarouseltrips] = useState([]);
  const [isloadedapidata, setisloadedapidata] = useState(true);
  useEffect(() => {
    setisloadedapidata(false);
    fetch(`${props.API_URL}meetings/travel`)
      .then((res) => res.json())
      .then((result) => {
        setlistcarouseltrips(result.objModel);
        setisloadedapidata(true);
      })
      .catch(() => {
        setisloadedapidata(true);
      });
  }, []);

  return (
    <div style={{ backgroundColor: "#f5eeee" }}>
      {listcarouseltrips.length > 0 && (
        <div className="container px-0">
          <div
            id="eventscarouselCaptions"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              {listcarouseltrips.map((item, idx) => {
                return (
                  <button
                    key={item.id}
                    type="button"
                    data-bs-target="#eventscarouselCaptions"
                    data-bs-slide-to={idx}
                    className={idx === 0 ? "active" : ""}
                    aria-label={"carousel indicador de viajes " + idx + 1}
                  ></button>
                );
              })}
            </div>
            <div className="carousel-inner">
              {listcarouseltrips.map((item, idx) => (
                <div
                  key={idx}
                  className={
                    idx === 0 ? "carousel-item active" : "carousel-item"
                  }
                  id={"event-carousel-" + item.idx}
                >
                  <img
                    src={`data:image/jpeg;base64,${item.picture.base64}`}
                    className="imgcarouselevent"
                    alt={item.picture.namePicture}
                  />
                  <div className="carousel-caption d-md-block">
                    {/* <h5>{item.destiny}</h5> */}
                    <p className="datetrip text-light fw-bold fs-6">
                      {Validation.convertDateToString(item.date)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev arrow-carousel"
              type="button"
              data-bs-target="#eventscarouselCaptions"
              data-bs-slide="prev"
            >
              <IconCarouselArrowLeft />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next arrow-carousel"
              type="button"
              data-bs-target="#eventscarouselCaptions"
              data-bs-slide="next"
            >
              <IconCarouselArrowRight />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
