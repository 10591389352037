import React, { Component } from "react";
import {Row,  Col} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import history from "../navigation/history";
// import TreeView from '../tree/TreeView';
import UpdateMainView from "../updateuser/UpdateMainView";
import Swal from "sweetalert2";

export default class HomeView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idSocio: 0,
      loadSenData: false,
      eventState: {
        showMenuHome: true,
        showMenuSocio: false,
        showMenuTool: false,
        showMenuNet: false,
        showMenuPay: false,
        showMenuShop: false,
      },
    };
  }

  componentDidMount() {
    let urlsearch = window.location.search.substring(1);
    let CryptoJS = require("crypto-js");
    let desencrypt  = CryptoJS.AES.decrypt(urlsearch, 'transfermem');
    let iduser = desencrypt.toString(CryptoJS.enc.Utf8);
    this.setState({
      idSocio: iduser
    },()=>this.verifyCurrentUser())
  }

  verifyCurrentUser() {
    if (this.state.idSocio <= 0) {
      Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success'
        },
        buttonsStyling: false,
        allowOutsideClick: false
      }).fire(
        'No pudimos obtener informacion de usuario',
        'Inicie sesión nuevamente.',
        'error',
      ).then((result)=>{
        if (result.isConfirmed){
          history.push("sign-in");
        }
      })
    }
  }


  /**
   * Event of menu's component
   * @param {*} eventMenuState stat of menu component to show Views
   */

  render() {
    const {idSocio } = this.state;

    return (

        <section>
          {/* Menu home */}
          <Row className="m-0">
            <Col          sm="1" md="2" className="mt-3"></Col>
            <Col xs="12" sm="10" md="8" className="mt-3">
            {/* Content */}
            <UpdateMainView idSocio={idSocio}></UpdateMainView>
            </Col>
            <Col          sm="1" md="2" className="mt-3"></Col>
          </Row>
        </section>

    );
  }
}
