import React, { Component } from "react";
import LoadApi from "validators/LoadApi";
import { Form, Row, Col, Button, Table, Spinner, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import TreeResidual from "../network/netcomp/TreeResidual";
import "react-sortable-tree/style.css";

import { BsFillSquareFill } from "react-icons/bs";

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import "../../views/styles/Custom.css";
import { RiTempColdFill } from "react-icons/ri";
import { TextsmsSharp } from "@material-ui/icons";

const colorActive = "#8bc34a";
const colorInactive = "#666464"; // Inactivo
const colorEndSusciption = "#aeaeae"; // "#9e9e9e"; //
// ------------------------------
const colorPendingVal = "#ffe924";
const colorPendingCuota = "#fffc4d";
const colorPendingMigracion = "#fffd84";
// -------------------------------
const colorRejectInicial = "#FF6000";
const colorRejectCuota = "#FF7900";
const colorRejectMigracion = "#FF8500";
// ------------------------------------
const colorDeuda1 = "#E01E37";
const colorDeuda2 = "#C1192C";
const colorDeuda3 = "#B71D32";
// --------------------------------
const colorPreliquidacion = "#075396";
const colorLiquidacion = "#104167";
// ------------------------------------
const colorCongelado =
  "linear-gradient(90deg, rgba(1,139,232,1) 0%, rgba(129,196,251,1) 50%, rgba(178,238,252,1) 100%)";

export default class NetResidual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: [],
      stateTree: false,
      loading: false,
      noDataTree: false,
      treeData: [],
      message: "",
      loadUpliners: false,
    };
  }

  componentDidMount() {
    this.getTree();
  }

  getChildren = (temp, item, nivel) => {
    let children = [];
    let arrayLevel = temp[nivel];
    let team = "SI";
    let pointsInvididuales = 0;
    let pointsCompuesto = 0;
    let range = item.range;
    let levelChild = nivel + 1;

    if (arrayLevel !== undefined) {
      for (let i = 0; i < arrayLevel.length; i++) {
        let register = arrayLevel[i];
        if (register.idsocio === item.idsocio) {
          if (register.idHijo > 0) {
            let status = this.getState(register.estadoHijo);

            if (register.team.toUpperCase().includes("NO")) {
              team = "NO";
            }

            if (register.pointsIndividual > pointsInvididuales) {
              pointsInvididuales = register.pointsIndividual;
            }

            if (register.pointscompuesto > pointsCompuesto) {
              pointsCompuesto = register.pointscompuesto;
            }
            // if(register.rangeCompuesto && register.rangeCompuesto.length > range ) {
            range = register.rangeCompuesto;
            // }

            children.push({
              id: register.idHijo,
              idsocio: register.idHijo,
              title: register.nombreHijo,
              state: status.state,
              colorState: status.colorState,
              range: register.rangeCompuesto,
              pointsIndividual: register.pointsIndividual,
              pointscompuesto: register.pointscompuesto,
              pointsIndividualTemp: register.pointsIndividual,
              pointscompuestoTemp: register.pointscompuesto,
              rama: register.rama,
              showChild: false,
              nivel: levelChild,
              children: [],
              team: register.team,
            });
          } else if (register.idHijo === 0) {
            pointsInvididuales = register.pointsIndividual;
            pointsCompuesto = register.pointscompuesto;
            range = register.range;
          }
        }
      }
    }

    // Get each children
    let x;
    for (x = 0; x < children.length; x++) {
      let values = this.getChildren(temp, children[x], nivel + 1);
      children[x].children = values[0];
      children[x].team = values[1];
      children[x].pointsIndividual = values[2];
      children[x].pointscompuesto = values[3];
      children[x].range = values[4];
    }

    return [children, team, pointsInvididuales, pointsCompuesto, range];
  };

  getMainChildren = (temp, nivel) => {
    let children = [];
    let idsChildren = [];
    let idsNodeNo = [];

    //Arreglo por niveles:
    let arrayLevel = [];

    for (let i = 0; i < temp.length; i++) {
      if (
        temp[i].idsocioramaprincipal === temp[i].idsocio &&
        temp[i].nivel === nivel
      ) {
        if (!idsChildren.includes(temp[i].idsocio)) {
          // add to child array
          idsChildren.push(temp[i].idsocio);

          temp[i].id = temp[i].idsocio;
          temp[i].title = temp[i].nombreSocio;
          let state = this.getState(temp[i].estadoSocio);
          temp[i].state = state.state;
          temp[i].colorState = state.colorState;
          // temp[i].pointsIndividualTemp = temp[i].pointsIndividual;
          // temp[i].pointscompuestoTemp = temp[i].pointscompuesto;
          temp[i].pointsIndividual = temp[i].pointsIndividual;
          temp[i].pointscompuesto = temp[i].pointscompuesto;
          temp[i].range = temp[i].rangeCompuesto;
          temp[i].rama = temp[i].rama;

          temp[i].showChild = false;
          temp[i].team = temp[i].team;
          temp[i].nivel = temp[i].nivel;
          if (
            temp[i].team !== null &&
            temp[i].team.toUpperCase().includes("NO")
          ) {
            idsNodeNo.push(temp[i].idsocio);
          }
          // temp[i].children = [];
          children.push(temp[i]);
        } else {
          //Validate team
          if (
            temp[i].team.toUpperCase().includes("NO") &&
            !idsNodeNo.includes(temp[i].id)
          ) {
            // find child and set NO
            for (let j = 0; j < children.length; j++) {
              if (temp[i].id === children[j].id) {
                children[j].team = "NO";
              }
            }
          }
        }
      }

      // Add to array list
      if (arrayLevel[temp[i].nivel] === undefined) {
        arrayLevel[temp[i].nivel] = [temp[i]];
      } else {
        arrayLevel[temp[i].nivel].push(temp[i]);
      }
    }

    // Get each children
    let x;
    for (x = 0; x < children.length; x++) {
      let values = this.getChildren(arrayLevel, children[x], nivel);
      children[x].children = values[0];
      children[x].team = values[1];
      children[x].pointsInvididual = values[2];
      children[x].pointscompuesto = values[3];
      children[x].range = values[4];
    }

    return children;
  };

  getState = (idState) => {
    // Verify state
    let data = {
      state: "",
      colorState: "",
    };

    switch (idState) {
      case 0:
        // Inactivo
        data.state = "Inactivo";
        data.colorState = colorInactive;
        break;
      case 1:
        // Patrocinados directos
        data.state = "Activo";
        data.colorState = colorActive;
        break;
      case 2:
        // Pendiente de validación inicial
        data.state = "Pendiente";
        data.colorState = colorPendingVal;
        break;
      case 3:
        // Rechazo de incial";
        data.state = "Inicial rechazado";
        data.colorState = colorRejectInicial;
        break;
      case 4:
        // Pagar despues"
        data.state = "Pago después";
        data.colorState = colorActive;
        break;
      case 5:
        // Deuda 1";
        // Pagar despues"
        data.state = "Deuda 1";
        data.colorState = colorDeuda1;
        break;
      case 6:
        // Deuda 2";
        data.state = "Deuda 2";
        data.colorState = colorDeuda2;
        break;
      case 7:
        // Deuda 3";
        data.state = "Deuda 3";
        data.colorState = colorDeuda3;
      case 8:
        data.state = "Preliquidacion";
        data.colorState = colorPreliquidacion;
        break;
      case 9:
        // Congelado";
        data.state = "Congelado";
        data.colorState = colorCongelado;
        break;
      case 10:
        // Pendiente de validación";
        data.state = "Pendiente";
        data.colorState = colorPendingCuota;
        break;
      case 16:
        // Pendiente de validación";
        data.state = "Pendiente";
        data.colorState = colorPendingCuota;
        break;
      case 11:
        // Pago rechazado";
        data.state = "Cuota rechazado";
        data.colorState = colorRejectCuota;
        break;
      case 12:
        // Suscription finalizada";
        data.state = "Finalizado";
        data.colorState = colorEndSusciption;
        break;
      case 13:
        data.state = "Pendiente";
        data.colorState = colorPendingMigracion;
        break;
      case 14:
        // Suscription finalizada";
        data.state = "Rechazo";
        data.colorState = colorRejectMigracion;
        break;
      case 15:
        // Suscription finalizada";
        data.state = "Liquidacion";
        data.colorState = colorLiquidacion;
        break;
    }

    return data;
  };

  async getTree() {
    if (this.props.type === "residual") {
      this.setState({ loading: true });
      let treeResponse = await UserService.getResidualTree();
      if (treeResponse !== null && treeResponse !== undefined) {
        if (treeResponse.status === 1) {
          // Add root
          let data = treeResponse.objModel;
          let i;
          let temp = data;

          let idSocio = Number(AuthService.getCurrentIdUser());
          let infoUser = AuthService.getCurrentUserInfo();
          let nivel = 1;

          let tree = {
            id: idSocio,
            title: infoUser.name + " " + infoUser.lastname,
            state: "Activo",
            colorState: colorActive,
          };

          let mainChildren = this.getMainChildren(temp, nivel);
          tree.children = mainChildren;

          this.setState({
            treeData: (this.state.treeData = [tree]),
            stateTree: true,
            loading: false,
            noDataTree: false,
            message: "",
            loadUpliners: false,
          });

          // Set tree to parent usin props
          if (this.props.setTree) {
            this.props.setTree([tree], "treeResidual");
          }
        } else {
          this.setState({
            tree: [],
            stateTree: false,
            loading: false,
            noDataTree: true,
            message: "",
            loadUpliners: false,
          });
        }
      } else {
        this.setState({
          tree: [],
          stateTree: false,
          loading: false,
          noDataTree: true,
          message: "",
          loadUpliners: false,
        });
      }
    }
  }

  /**
   * Update data
   */
  updateTree = (message, loaded) => {
    this.setState({
      loading: (this.state.loading = true),
      message: message,
      loadUpliners: (this.state.loadUpliners = loaded),
    });
    this.getTree();
  };

  render() {
    const { treeData, stateTree, noDataTree, loading } = this.state;

    return (
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <hr></hr>
        <Row>
          <Col sm={12}>
            <Form.Label className="content-subtitle">Árbol Residual</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorActive}></BsFillSquareFill>{" "}
              <Form.Label>Activo</Form.Label>
              <br />
              <BsFillSquareFill color={colorInactive}></BsFillSquareFill>{" "}
              <Form.Label>Inactivo</Form.Label>
              <br />
              <BsFillSquareFill color="#9e9e9e"></BsFillSquareFill>{" "}
              <Form.Label>Suscripción finalizada</Form.Label>
              <br />
            </Form>
          </Col>
          <Col sm={3}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorPendingVal}></BsFillSquareFill>{" "}
              <Form.Label>Pte. Validación Inicial</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorPendingCuota}
              ></BsFillSquareFill>{" "}
              <Form.Label>Pte. Validación Cuota</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorPendingMigracion}
              ></BsFillSquareFill>{" "}
              <Form.Label>Pte. Validación Migración</Form.Label>
            </Form>
          </Col>
          <Col sm={2}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorRejectInicial}></BsFillSquareFill>{" "}
              <Form.Label>Rechazo Inicial</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorRejectCuota}
              ></BsFillSquareFill>{" "}
              <Form.Label>Rechazo Cuota</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorRejectMigracion}
              ></BsFillSquareFill>{" "}
              <Form.Label>Rechazo Migración</Form.Label>
            </Form>
          </Col>
          <Col sm={2}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorDeuda1}></BsFillSquareFill>{" "}
              <Form.Label>Deuda 1</Form.Label>
              <br />
              <BsFillSquareFill color={colorDeuda2}></BsFillSquareFill>{" "}
              <Form.Label>Deuda 2</Form.Label>
              <br />
              <BsFillSquareFill color={colorDeuda3}></BsFillSquareFill>{" "}
              <Form.Label>Deuda 3</Form.Label>
            </Form>
          </Col>
          <Col sm={2}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorPreliquidacion}></BsFillSquareFill>{" "}
              <Form.Label>Preliquidación</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorLiquidacion}
              ></BsFillSquareFill>{" "}
              <Form.Label>Liquidación</Form.Label>
              <br />
              <BsFillSquareFill color="#018be8"></BsFillSquareFill>{" "}
              <Form.Label>Congelado</Form.Label>
            </Form>
          </Col>
        </Row>
        <br />
        <br />
        <Row className="box-legend">
          <Col sm={12}>
            <Button
              size="sm"
              variant="secondary"
              onClick={(e) => this.getTree()}
            >
              Actualizar árbol
            </Button>
          </Col>
        </Row>
        {loading && (
          <LoadApi
            indicatorLoad={true}
            message="Cargando árbol residual."
            messageWarning="Este proceso puede demorar unos minutos."
          ></LoadApi>
        )}
        {!loading && stateTree && (
          <div>
            {/* Load tree */}
            <Row style={{ paddingTop: 30 }}>
              <Col sm={12} style={{ textAlign: "left" }}>
                <div>
                  <TreeResidual
                    tree={treeData}
                    updateTree={this.updateTree}
                    showPoints={true}
                  ></TreeResidual>
                </div>
              </Col>
            </Row>
          </div>
        )}

        {!loading && noDataTree && (
          <Form.Label>
            No podemos mostrar su red. Consulte acerca de su red.
          </Form.Label>
        )}
        <Modal
          show={this.state.loadUpliners}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Spinner size="sm" animation="border" variant="dark"></Spinner>
              <Form.Label>&nbsp; {this.state.message}...</Form.Label>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
