import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const ModalContent = ({
    isOpen,
    handleClose,
    title,
    children,
    size="sm"
}) => {
    return (
        <>
            <Modal
                size={size}
                show={isOpen}
                onHide={handleClose}
                style={{ fontSize: 12 }}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default ModalContent