
import register1 from "../../images/sections/1.png";
import { ReactComponent as Imagenlogo } from "../../assets/svg/logoinclub.svg";
import RegisterEndViewPre from "./RegisterEndViewPre";
import { TextField, FormHelperText, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Grid, Typography, Box } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import "bootstrap/dist/css/bootstrap.min.css";
import Buttonmui from "@mui/material/Button";

import { Form, Modal, Spinner, Card } from "react-bootstrap";

import React, { Component, useEffect } from "react";
import userService from "../../services/user.service";
import { SearchPartner } from "./SearchPartner";
const url = `${process.env.REACT_APP_APP_URL}/register`;
class FormPreRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //RegisterinView RegisterIn
      codpartner: "",
      emailpartner: "",
      isloadedapis: true,
      showModalSearchPartner: false,
      resultPartners: [],
      typeSearchPatners: 1,
      currentPartner: null,
    };
  }

  handleChange1 = (e, field) => {
    let value = e.target.value;
    this.setState({
      [field]: value,
    });
  };

  validation = () => {
    if (this.state.codpartner === "" && this.state.emailpartner === "") {
      alert("Ingrese el código o correo de la persona quien te invitó");
      return false;
    }
    if (this.state.codpartner !== "" && this.state.emailpartner !== "") {
      alert("Debe ingresar solamente una opción");
      return false;
    }

    return true;
  };

  // Register data
  sendData = async (e) => {
    e.preventDefault();
    if (this.validation()) {
      let email = this.state.emailpartner;
      let codigo = this.state.codpartner;
      console.log(email, codigo);
      this.setState({ isloadedapis: false });
      if (email !== "" && codigo === "") {
        let response = await userService.getdatauserbyemail(email);
        this.setState({ isloadedapis: true });
        if (response.objModel !== null) {
          let code = response.objModel.code;
          window.location = url + `?token=${code}`;
        } else {
          alert("Este correo no está registrado");
        }
      } else if (codigo !== "" && email === "") {
        let response = await userService.getdatauserbyid(codigo);
        this.setState({ isloadedapis: true });

        if (response.objModel !== null) {
          let code = response.objModel.code;
          window.location = url + `?token=${code}`;
        } else {
          alert("Este usuario no esta registrado");
        }
      } else {
        //ingreso ambos datos
        let response = await userService.getdatauserbyemail(email);
        let response1 = await userService.getdatauserbyid(codigo);
        this.setState({ isloadedapis: true });
        if (response.objModel == null && response1.objModel == null) {
          alert("No son datos válidos");
          return false;
        }

        if (response.objModel !== null) {
          let code = response.objModel.code;
          window.location = url + `?token=${code}`;
        }

        if (response1.objModel !== null) {
          let code = response1.objModel.code;
          window.location = url + `?token=${code}`;
        }
      }
    }
  };


  

  
  render() {
    return (
      <div>
        {/** */}
        <Card className="bg-dark text-white">
          <Card.Img src={register1} alt="Card image" />
          <Card.ImgOverlay></Card.ImgOverlay>
        </Card>

        <div>
          <div className="tw-flex tw-flex-row tw-items-center tw-my-20">
            <div className="auth-inner-autoregister req-new-j my-4">
            <div className="container-btn-search-partners">
              <button type="button" style={{boxShadow: '0px 7px 12px rgba(0, 0, 0, 0.25)'}} onClick={() => this.setState({showModalSearchPartner: true})}>
                Socios/Embajadores - Autorizados
              </button>
            </div>
              <Form className="formautoregistercontainer">
                <section className="container-logo">
                  <Imagenlogo></Imagenlogo>
                </section>
                <h3 className="pb-2">Inclub</h3>
                <Form.Group className="mb-3">
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 50,
                      },
                    }}
                    fullWidth
                    id="outlined-required"
                    size="small"
                    label="Mi patrocinador será:"
                    variant="outlined"
                    value={this.state.codpartner}
                    onChange={(e) => this.handleChange1(e, "codpartner")}
                  />
                </Form.Group>
                {/*<Form.Group className="mb-3">
                  <FormHelperText sx={{ marginBottom: 1 }}>
                    (Opción 2)
                  </FormHelperText>
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 50,
                      },
                    }}
                    fullWidth
                    id="outlined-required"
                    size="small"
                    label="Correo de quien te invitó"
                    variant="outlined"
                    onChange={(e) => this.handleChange1(e, "emailpartner")}
                  />
                  </Form.Group>*/}
                <Buttonmui
                  variant="contained"
                  size="medium"
                  fullWidth
                  onClick={this.state.isloadedapis ? this.sendData : null}
                >
                  {!this.state.isloadedapis ? (
                    <div style={{ margin: "auto" }}>
                      <div className="spinnerinicio">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    "Continuar"
                  )}
                </Buttonmui>
                {/* <Form.Label className="content-title" style={{display:"flex",justifyContent:"center"}}>Inclub</Form.Label>
                        <Row>
                            <Col xs={6} style={{display:"flex",justifyContent:"center"}}>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <small style={{fontSize:"12px"}}>(Opción 1)</small><br></br>
                                    <Form.Label style={{fontSize:"15px"}}>Código de quien te invitó</Form.Label>
                                    <Form.Control style={{width:"300px"}} onChange={(e)=>this.handleChange1(e,"codpartner")}
                                    type="text" placeholder="Ingrese el código de la persona que te invitó" />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <small style={{fontSize:"12px"}}>(Opción 2)</small><br></br>
                                    <Form.Label style={{fontSize:"15px"}}>Correo de quien te invitó</Form.Label>
                                    <Form.Control style={{width:"300px"}} onChange={(e)=>this.handleChange1(e,"emailpartner")}
                                    type="email" placeholder="Ingrese el correo de la persona que te invito" />
                                </Form.Group>
                                

                                <Button variant="primary" type="submit" onClick={this.sendData} style={{width:"100%"}}>
                                    Continuar
                                </Button>
                            </Form>
                            </Col>
                        </Row> */}
              </Form>
            </div>
          </div>
          <SearchPartner 
            showModalSearchPartner={this.state.showModalSearchPartner}
            showSearchPartner={() => this.setState({showModalSearchPartner: false})}
            result={(value) => this.setState({codpartner: value})}
          />
        

          <div style={{ display: this.state.isComplete ? "block" : "none" }}>
            <RegisterEndViewPre reload={this.state.reload}></RegisterEndViewPre>
          </div>
          <Modal
            show={this.state.loadSenData}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
            <Modal.Body>
              <div style={{ textAlign: "center" }}>
                <Spinner size="sm" animation="border" variant="dark"></Spinner>
                <Form.Label>&nbsp; Enviando Datos...</Form.Label>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default FormPreRegister;
