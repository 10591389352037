import React, { Component } from 'react';
import ElectronicPurse from '../../components/user/ElectronicPurse';

export default class ElectronicPurseView extends Component {
    

    render() {
        return(
            <div>
                <ElectronicPurse></ElectronicPurse>
            </div>
            
        );
    }
}