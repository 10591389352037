import Validation from "components/utils/Validation";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Tabs, Tab,  Accordion, Table, Button, Modal, Row, Col } from "react-bootstrap";
import userService from "services/user.service";

export const DirectRating = () => {
  const [dataVigent, setDataVigent] = useState([]);
  const [dataCron, setDataCron] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    
    getDirectCycleQualificationByUser();

  }, [])
  

  const getDirectCycleQualificationByUser = async() => {
    const resp = await userService.getDirectCycleQualificationByUser();


    if( resp.status  && resp.objModel !== null ){
      setDataVigent(resp.objModel);
    }
    
  }

  const getScheduleWithImages = async(id) => {
    const resp = await userService.getScheduleWithImages(id);

    if( resp.status == 1 && resp.objModel !== null ){
      setDataCron(resp.objModel);
    }
  }

  const storeSuscriptionAdminsendemail = async(suscriptionId) => {
    Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Enviando correo...",
      showConfirmButton: false,
      timer: 2500,
      toast: true,
    });

    const data = {
      typeShippingEmail: 1,
      flagEnvioMaster: 0,
      flagEnvioPatrocinador: 0,
      otroEmail: "",
      idSuscripcion: suscriptionId
    }

    const resp = await userService.suscriptionAdminsendemail(data);

    if( resp.status == 1 ){
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: resp.description,
        showConfirmButton: false,
        timer: 1500,
        toast: true,
      });

      return;
    }
    Swal.fire({
      position: "top-end",
      icon: "error",
      title: resp.description,
      showConfirmButton: false,
      timer: 1500,
      toast: true,
    });
  }

  return (
    <>
    <Tabs className="custom-tabs-main" defaultActiveKey="current">
        <Tab eventKey="current" title="Calificación directa vigente">
            <Accordion>
              {
                dataVigent.map((item, index) => {
                  const qualificationBySuscriptions = item.qualificationBySuscriptions;

                  if( item.isCurrent ){
                    return (
                      <Accordion.Item eventKey={item.id} key={item.id}>
                        <Accordion.Header
                          style={{
                          display: "flex",
                          justifyContent: "center",
                          }}
                        >
                          <div 
                            className="d-flex flex-row justify-content-between w-100 align-items-center"
                            style={{paddingRight: '10px'}}
                          >
                            <div 
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '14px',
                                fontWeight: 'normal'
                              }}
                            >
                              <span className="me-2">{item.directCycleQualificationTypeName}</span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                                <span className="me-1">Inicio:</span>
                                <span className="me-3" style={{fontWeight: '300'}}>{ item.dateStart }</span>
                                <span className="me-1">Fin:</span>
                                <span style={{fontWeight: '300'}}>{ item.dateEnd }</span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span className="me-1">Puntos:</span>
                              <span style={{fontWeight: '300'}}>{ item.pointsCurrent }/{ item.pointsExpected }</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table
                            striped
                            bordered
                            hover
                            responsive="md"
                            size="sm"
                          >
                            <thead className="table-head">
                                <tr>
                                    <th></th>
                                    <th>Usuario</th>
                                    <th>Nombres y Apellidos</th>
                                    <th>Membresia</th>
                                    <th>Puntos</th>
                                    <th>Estado</th>
                                    <th>Cronograma</th>
                                    <th>Not. Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                              {
                                qualificationBySuscriptions.map((list, i) => (
                                  <tr key={list.suscriptionId}>
                                    <td>
                                      { i + 1 }
                                    </td>
                                    <td>
                                      { list.username }
                                    </td>
                                    <td>
                                      { list.name } { list.lastName }
                                    </td>
                                    <td>
                                      { list.packageName }
                                    </td>
                                    <td>
                                      { list.volume }
                                    </td>
                                    <td>
                                      { list.nameState }
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="primary"
                                        onClick={() => {
                                          setShowModal(true)
                                          getScheduleWithImages(list.suscriptionId)
                                        }}
                                      >
                                        ver
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="secondary"
                                        onClick={() => {
                                          storeSuscriptionAdminsendemail(list.suscriptionId)
                                        }}
                                      >
                                        notificar
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                  
                })
              }
            </Accordion>
        </Tab>
        <Tab eventKey="history" title="Historial de calificaciones">
        <Accordion>
              {
                dataVigent.map((item, index) => {
                  const qualificationBySuscriptions = item.qualificationBySuscriptions;

                  if( !item.isCurrent ){
                    return (
                      <Accordion.Item eventKey={item.id} key={item.id}>
                        <Accordion.Header
                          style={{
                          display: "flex",
                          justifyContent: "center",
                          }}
                        >
                          <div 
                            className="d-flex flex-row justify-content-between w-100 align-items-center"
                            style={{paddingRight: '10px'}}
                          >
                            <div 
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '14px',
                                fontWeight: 'normal'
                              }}
                            >
                              <span className="me-2">{item.directCycleQualificationTypeName}</span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                                <span className="me-1">Inicio:</span>
                                <span className="me-3" style={{fontWeight: '300'}}>{ item.dateStart }</span>
                                <span className="me-1">Fin:</span>
                                <span style={{fontWeight: '300'}}>{ item.dateEnd }</span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span className="me-1">Puntos:</span>
                              <span style={{fontWeight: '300'}}>{ item.pointsCurrent }/{ item.pointsExpected }</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table
                            striped
                            bordered
                            hover
                            responsive="md"
                            size="sm"
                          >
                            <thead className="table-head">
                                <tr>
                                    <th></th>
                                    <th>Usuario</th>
                                    <th>Nombres y Apellidos</th>
                                    <th>Membresia</th>
                                    <th>Puntos</th>
                                    <th>Estado</th>
                                    <th>Cronograma</th>
                                    <th>Not. Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                              {
                                qualificationBySuscriptions.map((list, i) => (
                                  <tr key={list.suscriptionId}>
                                    <td>
                                      { i + 1 }
                                    </td>
                                    <td>
                                      { list.username }
                                    </td>
                                    <td>
                                      { list.name } { list.lastName }
                                    </td>
                                    <td>
                                      { list.packageName }
                                    </td>
                                    <td>
                                      { list.volume }
                                    </td>
                                    <td>
                                      { list.nameState }
                                    </td>
                                    <td>
                                      ----
                                    </td>
                                    <td>
                                      ------
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                  
                })
              }
            </Accordion>
        </Tab>
    </Tabs>
    {/* Modal */}
    <Modal
          size="lg"
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          style={{ fontSize: 12 }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Detalle de cronograma</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th>Descripción</th>
                      <th>Fecha de vencimiento</th>
                      <th>Capital</th>
                      <th>Amortización</th>
                      <th>Ínteres</th>
                      <th>Mora</th>
                      <th>Cuota</th>
                      <th>Total</th>
                      <th>Fecha de Pago</th>
                      <th>Estado</th>
                      <th></th>
                      <th>Observación</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataCron.map((item, idx) => {
                        let descriptionState =
                        item.verif == 0
                          ? ""
                          : Validation.getState(item.verif, true).state;

                        return(
                          <tr key={idx + 1}>
                            <td>{item.quoteDescription}</td>
                            <td>
                              {Validation.convertDateToStringEx(
                                item.nextExpiration
                              )}
                            </td>
                            <td>{`${Math.round( (item.capitalBalance + Number.EPSILON) * 100) / 100}`} USD</td>
                            <td>{`${Math.round( (item.amortization + Number.EPSILON) * 100) / 100}`} USD</td>
                            <td>{item.interested} USD</td>
                            <td>{item.totalOverdue} USD</td>
                            <td>{item.quoteUsd} USD</td>
                            <td>{item.totalPay} USD</td>
                            <td>
                              {Validation.convertDateToStringEx(
                                item.payDate
                              )}
                            </td>
                            <td>{descriptionState}</td>
                            <td></td>
                            <td>{item.obs}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cerrar
            </Button>
            
          </Modal.Footer>
        </Modal>
    </>
  )
}
