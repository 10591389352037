import Validation from "components/utils/Validation";
import { 
    Form,
    Table,
    FormCheck,
    Accordion,
    Button,
    ProgressBar,
} from "react-bootstrap";

const calcularProgress = (a, b) => {
    const value = ( a / b ) * 100;

    return value.toFixed(2);
}

export const CurrentBonds = ({
    dataBonus = [],
    bondSelectId,
    onSelectBond
}) => {
    
    return (
        <Accordion>
            {
                dataBonus.map((item, index) => {
                    if( item.packages.length > 0 && item.serie != null  ){
                        const series = item.serie;
                        const avance = item.serie.advance;
                        const amount = item.serie.amount;
                        const difRest = amount - avance;
                        return (
                            <Accordion.Item eventKey={item.id} key={item.id}>
                                <Accordion.Header
                                    style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    }}
                                >
                                    <div className="d-flex flex-row justify-content-between w-100 align-items-center"
                                        style={{paddingRight: '10px'}}
                                    >
                                        <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px',
                                                fontWeight: 'normal'
                                            }}
                                        >
                                         <span className="me-2">{item.name}</span>
                                         <span className="me-1">Fecha Inicio:</span>
                                         <span style={{fontWeight: '300'}}>{
                                                series.startDate
                                            }</span>
                                        </div>
                                        <div
                                            style={{
                                                width: 'calc(100% - 380px)'
                                            }}
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                                                <div className="d-flex">
                                                    <span className="me-2">
                                                        Avance:
                                                    </span>
                                                    <span>
                                                        ${ avance }
                                                    </span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="me-2">
                                                        ${ amount }
                                                    </span>
                                                    <span>
                                                        :Monto
                                                    </span>
                                                </div>
                                            </div>
                                            <ProgressBar 
                                                now={calcularProgress(avance, amount)} 
                                                style={{
                                                    minWidth: '100%'
                                                }}
                                            />
                                        </div>
                                        <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px',
                                                fontWeight: 'normal'
                                            }}
                                        >
                                         <span className="me-1">Fecha Fin:</span>
                                         <span style={{fontWeight: '300'}}>{
                                                series.endDate
                                            }</span>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Table
                                        striped
                                        bordered
                                        hover
                                        responsive="md"
                                        size="sm"
                                    >
                                        <thead className="table-head">
                                            <tr>
                                                <th></th>
                                                <th>Nombre</th>
                                                <th>Descripción</th>
                                                <th>Precio</th>
                                                <th>Número de cuotas</th>
                                                <th>Cuota inicial</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item.packages.map((value, index) => {
                                                    if( value.estatus == 1 ){
                                                        return (
                                                            <tr key={value.id}>
                                                                <td>
                                                                    <Form.Check
                                                                    type="radio"
                                                                    name="kitBonu"
                                                                    >
                                                                        <FormCheck.Input
                                                                            checked={bondSelectId == undefined ? false : bondSelectId == value.id ? true : false}
                                                                            type="radio"
                                                                            name="kitBonu"
                                                                            id={value.id}
                                                                            value={value.id}
                                                                            onChange={(e) =>
                                                                                onSelectBond(e.target.value)
                                                                            }
                                                                        ></FormCheck.Input>
                                                                    </Form.Check>
                                                                </td>
                                                                <td style={{textTransform: 'uppercase'}}>{value.name}</td>
                                                                <td>{value.description}</td>
                                                                <td>$ {value.listPackageDetails[0].price}</td>
                                                                <td>{value.listPackageDetails[0].numberQuotas}</td>
                                                                <td>$ { value.listPackageDetails[0].initialPrice }</td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }
                })
            }
             
        </Accordion>
    )
}
