import React, { Component } from "react";
import { Form, Tabs, Tab, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import RangeManagement from "../management/RangeManagement";
import RangeManagementLevel from "../management/RangeManagementLevel";

export default class ManagementRange extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Form.Label className="content-subtitle">Gestión de Rango</Form.Label>
        <Tabs className="mb-3">
          <Tab eventKey="range" title="Primer Nivel">
            <RangeManagement></RangeManagement>
          </Tab>
          <Tab eventKey="level" title="Nivel 2 al 7">
            <RangeManagementLevel></RangeManagementLevel>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
