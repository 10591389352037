import { Loading } from "@nextui-org/react";

export default function LoadApi(props) {
  if (props.indicatorLoad) {
    return (
      <section className="tw-h-80 tw-flex tw-flex-row tw-items-center tw-justify-center">
        <section className="tw-flex tw-flex-col tw-items-center tw-gap-3">
          <Loading
            loadingCss={{ $$loadingSize: "120px", $$loadingBorder: "4px" }}
            type="default"
          ></Loading>
          <h2>{props?.message ?? "Cargando"}</h2>
          <span className="tw-text-red-400 tw-text-sm">
            {props?.messageWarning ?? ""}
          </span>
        </section>
      </section>
    );
  } else {
    return <>{props.children}</>;
  }
}
