import { Calendar, globalizeLocalizer } from "react-big-calendar";
import globalize from "globalize";
import { useEffect, useState } from "react";
import "./YearEvent.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { OverlayTrigger, Popover } from "react-bootstrap";

require("globalize/lib/cultures/globalize.culture.es");
export default function YearEvent(
  /*{ API_URL, getdatamodifiedfromfetch, data }*/ props
) {
  const [myEventsList, setmyEventsList] = useState([]);
  const localizer = globalizeLocalizer(globalize);
  const [isloadedapidata, setisloadedapidata] = useState(true);
  const [yearselected, setyearselected] = useState(new Date().getFullYear());
  const [monthselected, setmonthselected] = useState(new Date().getMonth());
  const listofmonths = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Setiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  function enterMeeting(item) {
    if (item.showpopover === false) {
      if (item.recordedLink !== null) window.open(item.recordedLink);
      else {
        const urlapi = item.subMeetingType.landing.url;
        window.location = urlapi.split("/")[3] + "/" + urlapi.split("/")[4];
        sessionStorage.setItem("idmeeting", item.id);
      }
    } else {
      alert("Esta reunión no está habilitada. Revise su horario");
    }
  }

  const CustomEvent = (propsEvent) => {
    const popover = (
      <Popover
        id="popover-basic"
        onClick={() => enterMeeting(propsEvent.event)}
        style={{ cursor: "pointer" }}
      >
        <Popover.Header as="h3">{propsEvent.title}</Popover.Header>
        <Popover.Body className="alignpopovercontainer">
          <div>Presentador:{" " + propsEvent.event.speaker}</div>
          <div>
            <p className="tw-m-0">
              Inicio: {propsEvent.event.startDatePeru} <span>Perú </span>
              <img
                src={`https://flagcdn.com/16x12/pe.png`}
                srcSet={`https://flagcdn.com/32x24/pe.png 2x, https://flagcdn.com/48x36/pe.png 3x`}
                width="16"
                height="12"
                alt="bandera"
              />
            </p>

            <p className="tw-m-0">
              Fin: {propsEvent.event.endDatePeru} <span>Perú </span>
              <img
                src={`https://flagcdn.com/16x12/pe.png`}
                srcSet={`https://flagcdn.com/32x24/pe.png 2x, https://flagcdn.com/48x36/pe.png 3x`}
                width="16"
                height="12"
                alt="bandera"
              />
            </p>
          </div>

          {props.ipInfo?.country !== "PE" && (
            <div>
              <p className="tw-m-0">
                Inicio: {propsEvent.event.startDateLocale}{" "}
                <span>
                  {props.ipInfo?.country}/{props.ipInfo?.city}{" "}
                </span>
                <img
                  src={`https://flagcdn.com/16x12/${props.ipInfo?.country.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/32x24/${props.ipInfo?.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${props.ipInfo?.country.toLowerCase()}.png 3x`}
                  width="16"
                  height="12"
                  alt="bandera"
                />
              </p>

              <p className="tw-m-0">
                Fin: {propsEvent.event.endDateLocale}{" "}
                <span>
                  {props.ipInfo?.country}/{props.ipInfo?.city}{" "}
                </span>
                <img
                  src={`https://flagcdn.com/16x12/${props.ipInfo?.country.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/32x24/${props.ipInfo?.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${props.ipInfo?.country.toLowerCase()}.png 3x`}
                  width="16"
                  height="12"
                  alt="bandera"
                />
              </p>
            </div>
          )}
        </Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose
        overlay={popover}
      >
        <div>{propsEvent.title}</div>
      </OverlayTrigger>
    );
  };

  /*const CustomEvent = (props) => {
    const popover = (
      <Popover
        id="popover-basic"
        onClick={() => enterMeeting(props.event)}
        style={{ cursor: "pointer" }}
      >
        <Popover.Header as="h3">{props.title}</Popover.Header>
        <Popover.Body className="alignpopovercontainer">
          <div>Presentador:{" " + props.event.speaker}</div>
          {!props.event.isUTCminus5 && (
            <div>
              <div>
                Inicio:{" "}
                {" " +
                  Validation.convertFechaHora(props.event.startDate).split(
                    "   "
                  )[0] +
                  " " +
                  Validation.convertFechaHora(props.event.startDate).split(
                    "   "
                  )[1]}{" "}
                (UTC-5)
              </div>
              <div>
                Fin:{" "}
                {" " +
                  Validation.convertFechaHora(props.event.endDate).split(
                    "   "
                  )[0] +
                  " " +
                  Validation.convertFechaHora(props.event.endDate).split(
                    "   "
                  )[1]}{" "}
                (UTC-5)
              </div>
            </div>
          )}
          <div>Inicio: {props.event.startDateLocale}</div>
          <div>Fin: {props.event.endDateLocale}</div>
        </Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose
        overlay={popover}
      >
        <div>{props.title}</div>
      </OverlayTrigger>
    );
  };*/

  useEffect(() => {
    setmyEventsList(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    if (!isloadedapidata) {
      Promise.all([
        fetch(
          `${props.API_URL}meetings/year/${yearselected}/month/${monthselected +
            1}`
        ).then((res) => res.json()),
        fetch(`${props.API_URL}meetings/current`).then((res) => res.json()),
      ])
        .then(([result1, result2]) => {
          setmyEventsList(
            props.getdatamodifiedfromfetch(result1.objModel, result2)
          );
          setisloadedapidata(true);
        })
        .catch(() => {
          setmyEventsList([]);
          setisloadedapidata(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isloadedapidata]);

  const CustomToolbar = (props) => {
    return (
      <div className="rbc-toolbar">
        <span className="rbc-toolbar-label">{props.label}</span>
      </div>
    );
  };
  return (
    <section className="my-3">
      <div className="btn-group-select-year">
        <button
          type="button"
          className="buttonselectyear"
          onClick={() => {
            setyearselected(yearselected - 1);
            setisloadedapidata(false);
          }}
        >
          <h5>{"<"}</h5>
        </button>
        <button
          type="button"
          className="buttonselectyear"
          onClick={() => {
            setyearselected(new Date().getFullYear());
            setisloadedapidata(false);
          }}
        >
          <h5>{yearselected}</h5>
        </button>
        <button
          type="button"
          className="buttonselectyear"
          onClick={() => {
            setyearselected(yearselected + 1);
            setisloadedapidata(false);
          }}
        >
          <h5>{">"}</h5>
        </button>
      </div>
      <div className="d-flex justify-content-center gap-3">
        <div
          className="btn-group d-flex justify-content-center mt-3 flex-wrap"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          {listofmonths.map((r, idx) => (
            <div key={idx} className="radiobutton-month">
              <input
                type="radio"
                className="btn-check"
                name="btnradioevent-year"
                id={"yearview-month-" + idx}
                autoComplete="off"
                value={idx}
                defaultChecked={idx === monthselected ? true : false}
                onChange={() => {
                  setmonthselected(idx);
                  setisloadedapidata(false);
                }}
              />
              <label
                className="btn btn-outline-primary"
                htmlFor={"yearview-month-" + idx}
              >
                {r}
              </label>
            </div>
          ))}
        </div>
      </div>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        onSelectEvent={(event) => console.log(event.title)}
        startAccessor="startDate"
        endAccessor="endDate"
        views={{ month: true }}
        style={{ height: 500 }}
        date={new Date(yearselected, monthselected)}
        popup
        culture="es"
        components={{
          event: CustomEvent,
          toolbar: CustomToolbar, // descomentar este luego
        }}
      />
      {!isloadedapidata && (
        <div
          className="spinner-border text-warning spinner-calendar-year"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </section>
  );
}
