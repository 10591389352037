import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import share from "../../images/home/iconshare.png";
import dartboard from "../../images/home/icontarget.png";
import planet from "../../images/home/iconworld.png";
import logo from "../../images/home/logowhite.png";
import history from "../../views/navigation/history";
import Footer from "./Footer";
import FooterBanner from "./FooterBanner";
import FooterMenu from "./FooterMenu";

export default class HomeIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      username: "",
      password: "",
      isShow: false,
      validate: false,
      showModal: false,
    };
  }

  OnClicked = (e, path) => {
    history.push(path);
  };

  render() {
    return (
      <section className="tw-bg-white">
        <section id="intro">
          <div className="intro-content">
            <div className="tw-text-left tw-pt-4 lg:tw-pt-24">
              <h6>INCLUB CLUB DE INVERSIÓN</h6>
              <h1>EL CIELO NO ES</h1>
              <h1 className="text-color">EL LÍMITE</h1>
              <h3>
                Ahora puedes acceder a proyectos de inversión con muchos
                beneficios 100% online
              </h3>
            </div>

            <div>
              <NavLink className="btn-get-started" to="/portfolio">
                Proyectos
              </NavLink>
              <NavLink className="btn-get-started" to="/register-in">
                ¿Cómo puedo afiliarme?
              </NavLink>
            </div>
            <div id="oportunities">
              <div className="container">
                <div className="information">
                  <div className="card-info">
                    <div className="box white wow">
                      {/* <div className="icon"><i className="fa fa-bar-chart"></i></div> */}
                      <img src={dartboard}></img>
                      <p className="description fontblue">
                        Impulsa el comercio y elimina intermediarios
                        innecesarios.
                      </p>
                    </div>
                  </div>

                  <div className="card-info">
                    <div className="box wow">
                      {/* <div className="icon"><i className="fa fa-bar-chart"></i></div> */}
                      <img src={share}></img>
                      <p className="description fontwhite">
                        Una creciente presencia en la red con miembros en los
                        siete continentes.
                      </p>
                    </div>
                  </div>

                  <div className="card-info">
                    <div className="box wow">
                      {/* <div className="icon"><i className="fa fa-bar-chart"></i></div> */}
                      <img src={planet}></img>
                      <p className="description fontwhite">
                        Asociaciones de afiliados asegurados en todo el mundo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <main className="tw-block">
          <section className="wow fadeInUp">
            <div className="section-header">
              <h2>¿Quiénes somos?</h2>
              <p>
                Ayudamos a la gente a crear una cartera de inversión inteligente
                y alcanzar la tan anhelada libertad financiera.
              </p>
              <br></br>
              <p>
                Inspirados por el deseo de hacer que el networking sea más
                rentable para todos, InClub toma lo mejor de los mercados de
                inversión y ofrece una plataforma para invertir en los más
                rentables y modernos proyectos.
              </p>
              <br></br>
              <p>
                InClub te da oportunidades de invertir en muchos rubros como
                immobiliario, técnologico, transporte, etc. Con una amplia gama
                de beneficios como ningún otro club de inversión.
              </p>
              <img alt="" src={logo} width="200"></img>
            </div>
          </section>

          <img
            alt="banner inclub home"
            src="/resources/pages/home/banner-inclub.jpg"
            className="tw-w-full"
          />

          <section id="about" className="wow fadeInUp">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 about-img tw-p-0">
                  <img src="/resources/pages/home/power.png" alt="logo pro" />
                </div>

                <div className="col-lg-6 content">
                  <h2>Más Poder</h2>
                  <h2>Más Rendimiento</h2>
                  <h2>Más PRO</h2>
                  <p>
                    Creemos que la clave del éxito es utilizar la última
                    tecnología para construir un negocio que todos adoren.
                  </p>
                  <NavLink className="btn-get-info" to="/portfolio">
                    ENTÉRATE MÁS AQUÍ
                  </NavLink>
                </div>
              </div>
            </div>
          </section>

          {/* Footer menu */}
          <FooterBanner></FooterBanner>
          {/* <FooterMenu></FooterMenu> */}
        </main>

        <Footer></Footer>
      </section>
    );
  }
}
