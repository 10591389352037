import React, { Component } from "react";
import { Form, Row, Col, Table, Spinner, Button } from "react-bootstrap";
import LoadApi from "validators/LoadApi";

import Validation from "../../utils/Validation";
import WalletService from "../../../services/wallet.service";

export default class Movement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      registers: [],
      emptyList: true,
      message: "No hay registros para mostrar.",
      loading: false,
      totalPages: 1,
      page: 1,
      size: 10,
    };
  }

  componentDidMount() {
    if (this.state.id > 0) {
      this.getWalletRegisters();
    }
  }

  componentDidUpdate(props) {
    if (this.props.id !== props.id && props.id > 0) {
      this.setState({
        id: props.id,
      });
      this.getWalletRegisters();
    }
  }

  getWalletRegisters = async () => {
    let data = {
      SizeList: this.state.size,
      NroPage: this.state.page,
      IdReference: Number(this.state.id),
    };

    let response = await WalletService.getWalletRegisters(data);

    if (response !== undefined) {
      if (response.listElements.length > 0) {
        this.setState({
          registers: (this.state.registers = response.listElements),
          totalPages: (this.state.totalPages = response.totalPages),
          emptyList: (this.state.emptyList = false),
          message: (this.state.message = ""),
          loading: (this.state.loading = false),
        });
      } else {
        this.setState({
          emptyList: (this.state.emptyList = true),
          message: (this.state.message = "No hay registros para mostrar."),
          loading: (this.state.loading = false),
        });
      }
    } else {
      this.setState({
        emptyList: (this.state.emptyList = true),
        message: (this.state.message =
          "Ocurrió un problema al obtener sus movimientos. Inténtelo más tarde."),
        loading: (this.state.loading = false),
      });
    }
  };

  handlePageChange = (e, field) => {
    let value = Number(e.target.value);
    this.setState({
      [field]: (this.state[field] = value),
      loading: (this.state.loading = true),
      registers: (this.state.registers = []),
      emptyList: (this.state.emptyList = true),
    });

    // Call to  services
    this.getWalletRegisters();
  };

  handleSizeChange = (e, field) => {
    let value = Number(e.target.value);

    this.setState({
      size: (this.state.size = value),
      page: (this.state.page = 1),
      loading: true,
      registers: [],
      emptyList: true,
    });

    // Call to  services
    this.getWalletRegisters();
  };

  updateRegister = () => {
    this.setState({
      size: (this.state.size = 10),
      page: (this.state.page = 1),
      loading: true,
      registers: [],
      emptyList: true,
    });

    // Call to  services
    this.getWalletRegisters();
  };

  render() {
    const {
      registers,
      emptyList,
      loading,
      page,
      totalPages,
      size,
    } = this.state;

    let optionPages = [];
    for (let i = 0; i < totalPages; i++) {
      optionPages.push(
        <option key={`page${i}`} value={i + 1}>
          {i + 1}
        </option>
      );
    }

    return (
      <div>
        {this.state.loading && (
          <LoadApi
            indicatorLoad={true}
            message="Cargando información de socios."
          ></LoadApi>
        )}
        {this.state.emptyList && !this.state.loading && (
          <Form.Label>{this.state.message}</Form.Label>
        )}
        {!this.state.emptyList && (
          <div>
            {/* Paginador */}
            <Row className="my-3">
              <Form.Label column xs="auto" className="pe-0">
                Registros:
              </Form.Label>
              <Col xs="auto" className="pe-0">
                <Form.Select
                  id="selectCount"
                  defaultValue={size}
                  size="sm"
                  onChange={(e) => {
                    this.handleSizeChange(e, "size");
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                </Form.Select>
              </Col>
              <Form.Label column xs="auto" className="pe-0">
                Página:
              </Form.Label>
              <Col xs="auto" className="pe-0">
                <Form.Select
                  id="selecPagesAf"
                  defaultValue={"1"}
                  size="sm"
                  value={page}
                  onChange={(e) => {
                    this.handlePageChange(e, "page");
                  }}
                >
                  {optionPages}
                </Form.Select>
              </Col>
              <Form.Label column xs="auto" className="pe-0">
                de {totalPages}
              </Form.Label>
              {/* <Form.Label column xs={12} sm={8} md={7} xl={4}>
                            Registros: {" "}
                            <Form.Select
                                id="selectCount"
                                defaultValue={size}
                                size="sm"
                                style={{display:"inline",width:"auto"}}
                                onChange={e => {this.handleSizeChange(e, 'size')}} 
                                >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                            </Form.Select>
                            {" "} Página: {" "}
                            <Form.Select
                                id="selecPagesAf"
                                defaultValue={"1"}
                                size="sm"
                                style={{display:"inline",width:"auto"}}
                                value={page}
                                onChange={e => {this.handlePageChange(e, 'page')}} 
                            >
                                {optionPages}
                            </Form.Select>
                            {" "} de {totalPages} 
                        </Form.Label> */}
            </Row>
            <Row>
              <Col sm={12}>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th colSpan={5} style={{ textAlign: "left" }}>
                        <Button
                          onClick={(e) => this.updateRegister()}
                          variant="secondary"
                          size="sm"
                        >
                          Actualizar
                        </Button>
                      </th>
                    </tr>
                    <tr>
                      <th>Nº </th>
                      <th>Fecha</th>
                      <th>Tipo de operación</th>
                      <th>Descripción</th>
                      <th>Monto </th>
                    </tr>
                  </thead>
                  <tbody>
                    {registers.map(function(item, idx) {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>
                            {Validation.convertDateToStringComplete(
                              item.availabitilyDate
                            )}
                          </td>
                          <td>{item.typeWalletTransaction.description}</td>
                          <td>{item.referenceData}</td>
                          <td>$ {item.amount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
