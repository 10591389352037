import React, { Component, useState } from "react";
import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Forms from "react-bootstrap/Form";
// import DatePicker from 'react-bootstrap-date-picker
import UtilService from "../../services/utils.service";
import UserService from "../../services/user.service";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RichText.css";

const civilStateKey = "Casado"; // casado

// Datos personales
export default class StepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      idResidenceCountry: -1,
      residenceList: [],
      gender: "",
      idFamilyPackage: -1,
      idNationality: -1,
      idDocumentType: -1,
      desDocument: "",
      codesDocument: "",
      showOthersC: "none",
      showOthers: "none",
      nroDocument: "",
      conroDocument: "",
      civilState: "",
      nationalities: [],
      typeDocuments: [],
      tempDocuments: [],
      showRegister: "none",
      messageDate: "",
      messageDoc: "",
      messageDocCo: "",
    };

    this.getCountries = this.getCountries.bind(this);
    this.getDefaultDocument = this.getDefaultDocument.bind(this);
  }

  componentDidMount() {
    this.getDefaultDocument();
    this.getCountries();
    this.getResidences();
  }

  eventhandler = (data, field) => {
    var temp = this.state.user;
    temp[field] = data;
    this.setState({ user: temp });
  };

  onEditorStateChange = (editorState, field) => {
    let value = this.state.editorState.getCurrentContent().getPlainText();
    this.setState({
      editorState,
    });
    if (this.props.onChange) {
      this.props.onChange(value, field);
      this.setState({
        [field]: (this.state[field] = value),
      });
    }
  };

  async getResidences() {
    let response = await UtilService.getResidences();
    if (response !== null && response !== undefined) {
      let objs = response.objModel;
      this.setState({
        residenceList: (this.state.residenceList = objs),
      });
    }
  }

  async getDefaultDocument() {
    let response = await UtilService.getTypeDocDefault();
    if (response !== null && response !== undefined) {
      if (response.status === 1) {
        let data = response.objModel;
        let items = [];
        data.forEach((elem) => {
          items.push(
            <option key={elem.id} value={elem.id}>
              {elem.name}
            </option>
          );
        });

        this.setState({
          tempDocuments: (this.state.tempDocuments = items),
        });
      }
    }
  }

  // Get list of countries
  async getCountries() {
    let response = await UtilService.getResidences();

    if (response !== null && response !== undefined) {
      let objs = response.objModel;
      let residences = [];
      objs.forEach((elem) => {
        residences.push(
          <option key={elem.idCountry} value={elem.idCountry}>
            {elem.name}
          </option>
        );
      });

      this.setState({
        nationalities: (this.state.nationalities = residences),
      });
    }
  }

  async createItemTypes() {
    var id = this.state.idNationality;

    if (id > 0) {
      let response = await UtilService.getTypeDocByNat(id);
      if (response !== null && response !== undefined) {
        let items = [];
        if (response.status === 1 && response.objModel.length > 0) {
          response.objModel.forEach((elem) => {
            items.push(
              <option key={elem.id} value={elem.id}>
                {elem.name}
              </option>
            );
          });
        } else {
          items = this.state.tempDocuments;
        }

        this.setState({ typeDocuments: items });
        this.forceUpdate();
      }
    }
  }

  /**
   * Method to handle the change  of properties and send it to parent
   * @param {*} e
   * @param {*} field
   */
  handleChange = (e, field) => {
    let value = e.target.value.trim();
    if (this.props.onChange) {
      this.props.onChange(value, field);
      this.setState({
        [field]: (this.state[field] = value),
        messageDoc: "",
      });
    }
    // })
  };

  handleChange2 = (e, field) => {
    // this.setState({ [field]: e.target.value }, () => {
    var value = e.target.value.trim();
    // get code number phone
    if (field === "email") {
      this.setState({
        messageEmail: (this.state.messageEmail = ""),
      });
    }
    if (this.props.onChange) {
      this.props.onChange(value, field);
      this.setState({
        [field]: (this.state[field] = value),
      });
    }
    // })
  };

  /**
   * Method to handle the selected item  send it to parent
   * @param {*} e
   * @param {*} field
   */
  handleSelect = (e, field) => {
    var value = e.target.value;
    this.setState({ [field]: value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value, field);
      }
    });

    if (field === "idNationality") {
      this.setState({
        idNationality: (this.state.idNationality = value),
      });
      this.createItemTypes();
    }

    if (field === "idDocumentTypeCountry") {
      let text = e.target.options[e.target.selectedIndex].text.toUpperCase();
      if (text.includes("OTRO")) {
        this.setState({
          showOthers: (this.state.showOthers = "inline"),
        });
      } else {
        this.setState({
          showOthers: (this.state.showOthers = "none"),
          desDocument: (this.state.desDocument = ""),
        });
        if (this.props.onChange) {
          this.props.onChange("", "desDocument");
        }
      }

      this.forceUpdate();
    }

    if (field === "coidDocumentTypeCountry") {
      let text = e.target.options[e.target.selectedIndex].text.toUpperCase();
      if (text.includes("OTRO")) {
        this.setState({
          showOthersC: (this.state.showOthersC = "inline"),
        });
      } else {
        this.setState({
          showOthersC: (this.state.showOthersC = "none"),
          codesDocument: (this.state.codesDocument = ""),
        });
        if (this.props.onChange) {
          this.props.onChange("", "codesDocument");
        }
      }

      this.forceUpdate();
    }
  };

  handleSelectResidence = (e, field) => {
    var value = e.target.value;
    // get code number phone
    let obj = this.state.residenceList.find(
      (elem) => elem.idCountry === Number(value)
    );

    let codePhone = obj.symbol + obj.phonecode;

    this.setState({
      codephone: (this.state.codephone = codePhone),
    });

    this.setState({ [field]: value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value, field);
        this.props.onChange(codePhone, "codephone");
      }
    });
  };

  handleCivilState = (e, field) => {
    var value = e.target.value;
    this.setState({ [field]: value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value, field);
      }
    });
    if (value === civilStateKey) {
      this.setState(
        {
          showRegister: (this.state.showRegister = "block"),
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(true, "coafiliate");
          }
        }
      );
    } else {
      this.setState(
        {
          showRegister: (this.state.showRegister = "none"),
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(false, "coafiliate");
          }
        }
      );
    }
  };
  /**
   * Method to handle the change  of properties and send it to parent
   * @param {*} e
   * @param {*} field
   */
  handleRadio = (e, field) => {
    var value = e.target.value;
    this.setState({ [field]: value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value, field);
      }
    });
  };

  handleRadio2 = (e, field) => {
    // if (this.props.onChange) {
    //     this.props.onChange(e.target.value, field);
    //   }
    var value = e.target.value;
    this.setState({ [field]: value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value, field);
      }
    });
  };

  /**
   * Method to handle the change  of properties and send it to parent
   * @param {*} e
   * @param {*} field
   */
  handledate = (e, field) => {
    this.setState({
      messageDate: (this.state.messageDate = ""),
    });
    if (this.props.onChange) {
      this.props.onChange(e.target.value, field);
    }
  };

  onBlurDate = (e, field, fieldMessage) => {
    // Validate date
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    let date = e.target.value;
    let correct = date.match(regEx);
    if (correct) {
      this.setState({
        [fieldMessage]: (this.state[fieldMessage] = ""),
      });
    } else {
      this.setState({
        [fieldMessage]: (this.state[fieldMessage] =
          "Ingrese una fecha válida."),
      });
      if (this.props.onChange) {
        this.props.onChange("", field);
      }
    }
  };

  // Verify email
  async verifyEmail(email, field, fieldMessage) {
    let data = {};
    data.email = email;

    let isRegistered = await UserService.isEmailRegistered(data);
    if (isRegistered) {
      this.setState({
        [field]: (this.state[field] = ""),
        [fieldMessage]: (this.state[fieldMessage] =
          "Este correo ya está registrado."),
      });
      if (this.props.onChange) {
        this.props.onChange("", "email");
      }
    }
  }

  onBlurEmail = (e, field, fieldMessage) => {
    let value = e.target.value.trim();
    if (value.length > 0) {
      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
        this.setState({
          [fieldMessage]: (this.state[fieldMessage] = ""),
        });

        this.verifyEmail(value, field, fieldMessage);
      } else {
        this.setState({
          [field]: (this.state[field] = ""),
          [fieldMessage]: (this.state[fieldMessage] =
            "Ingrese un correo válido."),
        });
        if (this.props.onChange) {
          this.props.onChange("", "email");
        }
      }
    }
  };

  // Verify nro Document
  onBlurDocument = (e, field, fieldMessage) => {
    let nroDocument = this.state[field];
    if (nroDocument.length > 0) {
      this.verifyDocument(field, fieldMessage);
    }
  };

  async verifyDocument(field, fieldMessage) {
    let data = {};
    data.nroDocument = this.state[field];

    let isRegister = await UserService.isDocumentRegistered(data);

    if (!isRegister) {
      this.setState({ [fieldMessage]: "" });
      this.forceUpdate();
    } else {
      this.setState({
        [fieldMessage]: "Este documento ya ha sido registrado.",
      });
      this.props.onChange("", field);
      this.forceUpdate();
    }
  }

  render() {
    const { desDocument, codesDocument, residenceList, codephone } = this.state;
    return (
      <div>
        <Form.Group controlId="formStepOne">
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Estoy interesado(a) en: *</Form.Label>
                <div key={"inline"} className="mb-3">
                  <Form.Check
                    inline
                    label="Keola"
                    type="radio"
                    id={`inline3`}
                    value="1"
                    onChange={(e) => this.handleRadio2(e, "idFamilyPackage")}
                    checked={this.state.idFamilyPackage === "1"}
                  />
                  <Form.Check
                    inline
                    label="InResorts"
                    type="radio"
                    id={`inline4`}
                    value="2"
                    onChange={(e) => this.handleRadio2(e, "idFamilyPackage")}
                    checked={this.state.idFamilyPackage === "2"}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Nombres *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Nombres"
                  onChange={(e) => this.handleChange(e, "name")}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Apellidos *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Apellidos"
                  onChange={(e) => this.handleChange(e, "lastname")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>¿De donde nos contactas? *</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={"DEFAULT"}
                  onChange={(e) => this.handleSelect(e, "idNationality")}
                >
                  <option value="DEFAULT" disabled>
                    Seleccione una opción
                  </option>
                  {this.state.nationalities}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Tipo de documento *</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={"DEFAULT"}
                  onChange={(e) =>
                    this.handleSelect(e, "idDocumentTypeCountry")
                  }
                >
                  <option value="DEFAULT" disabled>
                    Seleccione una opción
                  </option>
                  {this.state.typeDocuments}
                </Form.Control>
                <br></br>
                <Form.Control
                  style={{ display: this.state.showOthers, paddingTop: 6 }}
                  type="text"
                  placeholder="Ingrese tipo de documento"
                  value={desDocument}
                  onChange={(e) => this.handleChange(e, "desDocument")}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Nro. de documento *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Nro. documento"
                  onChange={(e) => this.handleChange(e, "nroDocument")}
                  onBlur={(e) =>
                    this.onBlurDocument(e, "nroDocument", "messageDoc")
                  }
                ></Form.Control>
                <Form.Text className="textAlert">
                  {this.state.messageDoc}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Sexo *</Form.Label>
                <div key={"inline-radio"} className="mb-3">
                  <Form.Check
                    inline
                    label="Masculino"
                    type="radio"
                    id={`inline-radio`}
                    value="M"
                    onChange={(e) => this.handleRadio(e, "gender")}
                    checked={this.state.gender === "M"}
                  />
                  <Form.Check
                    inline
                    label="Femenino"
                    type="radio"
                    id={`inline-radio2`}
                    value="F"
                    onChange={(e) => this.handleRadio(e, "gender")}
                    checked={this.state.gender === "F"}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Correo electrónico *</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Correo electrónico"
                  onChange={(e) => this.handleChange2(e, "email")}
                  onBlur={(e) => this.onBlurEmail(e, "email", "messageEmail")}
                ></Form.Control>
                <Form.Text className="textAlert">
                  {this.state.messageEmail}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>País de residencia *</Form.Label>
                <Form.Select
                  size="sm"
                  defaultValue={"DEFAULT"}
                  onChange={(e) =>
                    this.handleSelectResidence(e, "idResidenceCountry")
                  }
                >
                  <option value="DEFAULT" disabled>
                    Seleccione una opción
                  </option>
                  {residenceList.map((elem) => (
                    <option key={elem.idCountry} value={elem.idCountry}>
                      {elem.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Ciudad *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Ciudad"
                  onChange={(e) => this.handleChange2(e, "districtAddress")}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Ingrese su ciudad.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Estado / Distrito de residencia *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Estado / Distrito de residencia"
                  onChange={(e) => this.handleChange(e, "estado")}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Ingrese su estado / distrito.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Nro. Celular *</Form.Label>
                <InputGroup>
                  <InputGroup.Text style={{ fontSize: 12 }}>
                    {codephone}
                  </InputGroup.Text>
                  <Form.Control
                    id="txtphone"
                    required
                    type="text"
                    placeholder="Nro. celular"
                    onChange={(e) => this.handleChange2(e, "phone")}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Ingrese un número de celular válido.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Consulta</Form.Label>
                <Editor
                  editorState={this.state.editorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onEditorStateChange={(e) =>
                    this.onEditorStateChange(e, "Comments")
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
      </div>
    );
  }
}
