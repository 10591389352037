import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { HiArrowLeft } from "react-icons/hi";

import Login from "../../components/login/Login";



export default class LoginView extends Component {
  constructor(props) {
    super(props);
  }
  /*handleLogged = (isLogged) => {
    if (this.props.isLogged) {
      this.props.isLogged(isLogged);
    }
  };*/

  render() {
    return (
      <div className="container-fluid h-100">
        <Helmet>
          <title>Login Inclub</title>
          <meta name="description" content="Login de inclub"></meta>
        </Helmet>
        <div className="row login-new_view h-100">
          <div className="col-md-6 img-auth-new h-100 d-none d-md-block"
            style={{
              backgroundImage: `url('/img/bg-login-new.png')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom center',
              backgroundSize: 'contain'
            }}
          >
          </div>
          <div className="col-md-6 col-12 h-100">
            <div className="d-flex justify-content-center flex-column align-items-center h-100">
              <div
                className="formloggincontainerVolver"
              >
                <NavLink to="/">
                  < HiArrowLeft />
                  Volver a inicio
                </NavLink>
              </div>*
              <img src="/img/inclub-new.png" height={90} className="mb-5" />
              <Login />
            </div>
          </div>
        </div>
      </div>
    );
  }

     
}

/*<div className="tw-w-full tw-h-[calc(100%_-_73.641px)] tw-flex tw-flex-row tw-items-center">
        <Helmet>
          <title>Login Inclub</title>
          <meta name="description" content="Login de inclub"></meta>
        </Helmet>
        <div className="auth-inner">
          <Login isLogged={this.handleLogged}></Login>
        </div>
    </div>*/
