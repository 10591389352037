import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class ServiceView extends Component {

    render() {
        return(
            <div>
               <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                        <Accordion.Header style={{display:"flex",justifyContent:"center"}}>Agregar</Accordion.Header>
                        <Accordion.Body>
                        Servicio 1
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Agregar</Accordion.Header>
                        <Accordion.Body>
                        Servicio 2
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Card>
                        <Card.Header>
                        <z.Toggle as={Button} variant="link" eventKey="0">
                            Agregar
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>Servicio 1</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            Agregar
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>Servicio 2</Card.Body>
                        </Accordion.Collapse>
                    </Card> */}
                </Accordion>
            </div>
        );
    }
}