import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrdersIdSocio } from "services/meKeola.service";
import "./MEKeolaListOrders.css";
const MEKeolaListOrders = () => {
  const dispatch = useDispatch();
  const idSocio = sessionStorage.getItem("id") ? sessionStorage.getItem("id") : null;
  //console.log("HABER idSocio ===>", idSocio);
  const { orders } = useSelector((state) => state.shop);
  console.log('ver orders ======>',orders);
  useEffect(() => {
    dispatch(getOrdersIdSocio(idSocio));
  }, [dispatch, idSocio]);
  return (
    <div className="korderlist-content">
        {console.log('orders =>',orders)}
      {orders.length >0 &&
        orders.map((order, key) => {
          return (
            <div className="card-order" key={key}>
              <div className="header-status">
                <span
                  className={`status ${
                    order?.ordersStatusDTO?.id === 8 ||
                    order?.ordersStatusDTO?.id === 12 ||
                    order?.ordersStatusDTO?.id === 13 ||
                    order?.ordersStatusDTO?.id === 14
                      ? "cancel"
                      : order?.ordersStatusDTO?.id === 1
                      ? "pending"
                      : order?.ordersStatusDTO?.id === 7
                      ? "recived"
                      : null
                  }`}
                >
                  {order?.ordersStatusDTO?.description}
                </span>
              </div>
              <div className="body-order">
                <div className="order-info">
                  <div className="store">
                    <img src={order?.storeDTO?.photoStore} alt="" />
                    <p>
                      {order?.storeDTO?.nameStore}
                      <span>
                        #{order?.id} &nbsp; {order?.dateOrder}
                      </span>
                    </p>
                  </div>
                  <div className="btn-details">
                      <Link to={`kshop/orderdetail/${order.id}`}>
                        Ver Detalles
                      </Link>
                    </div>
                </div>
              </div>
            </div>
          );
        })
        
        }
    </div>
  );
};

export default MEKeolaListOrders;
