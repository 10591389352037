import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Svg,
  Rect,
} from "@react-pdf/renderer";

Font.register({
  family: "Arial",
  fonts: [
    { src: "/pdf/fonts/arial.ttf" },
    {
      src: "/pdf/fonts/arial-black.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {},
  body: {
    fontSize: 10,
    fontFamily: "Arial",
    paddingTop: 10,
    paddingHorizontal: 70,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 25,
    paddingHorizontal: 50,
    paddingBottom: 15,
  },
  imgHeader: {
    maxWidth: "180px",
    opacity: 0.5,
  },
  title: {
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 20,
  },
  subtitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  text: {
    textAlign: "justify",
    paddingBottom: 10,
    //lineHeight: 1.5,
  },
  titleList: {
    textAlign: "justify",
    paddingLeft: 15,
    paddingBottom: 10,
  },
  list: {
    textAlign: "justify",
    paddingLeft: 20,
    paddingBottom: 2,
  },
  image: {
    width: "100%",
    marginVertical: 15,
    marginHorizontal: 90,
  },
  selected: {
    width: 20,
    height: 15,
    backgroundColor: "black",
    borderRadius: 1,
    border: "1 solid black",
  },
  noSelected: {
    width: 20,
    height: 15,
    backgroundColor: "white",
    borderRadius: 1,
    border: "1 solid black",
  },
});

export default function LaJoyaStandByPDF(props) {
  console.log("props LaJoyaStandByPDF =>", props);
  let membershipmaintenanceletter = props.dataPDF.membershipmaintenanceletter;
  membershipmaintenanceletter = membershipmaintenanceletter.replace(
    "DOLARES",
    " "
  );
  membershipmaintenanceletter = membershipmaintenanceletter.replace(
    "M.N.",
    " "
  );
  membershipmaintenanceletter = membershipmaintenanceletter.trim();

  return (
    <Document>
      <Page wrap size="A4">
        {/*   1 */}
        <View style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.title}>
              CONTRATO STAND BY DE TRANSFERENCIA DE ACCIONES DE USUFRACTO
            </Text>

            <Text style={styles.text}>
            Conste por el presente documento el Contrato Stand By de transferencia de acciones con usufructo, que celebran de una parte {" "}
              <Text style={{ fontWeight: "bold" }}>VALLE ENCANTADO S.A.C.</Text>
              , identificada con RUC N° 20601460271, constituida y regida bajo
              las leyes de la República del Perú, con domicilio legal en Mz.{" "}
              {"<<B>>"} Lote 72, Tercera Etapa Cieneguilla, Provincia de Lima y
              Departamento de Lima, debidamente representada por el Señor Omar
              Fernando Urteaga Cabrera , identificado con D.N.I. N° 41958311; a
              quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“VALLE ENCANTADO”</Text>; y
              de otra parte El(La) Señor(a) {props.dataPDF.user.name.toUpperCase()}{" "}
              {props.dataPDF.user.lastname.toUpperCase()}, de nacionalidad{" "}
              {props.dataPDF.user.courtesy}, identificado con D.N.I. N°{" "}
              {props.dataPDF.user.nroDocument}, con domicilio en{" "}
              {props.dataPDF.user.address}, a quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“EL COMPRADOR”</Text>; en los
              términos contenidos en las cláusulas siguientes:
            </Text>

            <View style={styles.text}>
              <Text style={styles.subtitle}>PRIMERO: ANTECEDENTES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.1{"  "}VALLE ENCANTADO indica que se encuentra en proceso de creación una nueva Razón Social: LA JOYA PARK RESORT S.A., empresa donde se constituirán los socios del hotel LA JOYA PARK RESORT. 
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.2{"  "}VALLE ENCANTADO es la empresa encargada de gestionar la venta de acciones de LA JOYA PARK RESORT S.A, quien será titular de 600,000,000 de acciones ordinarias, que se compondrán por acciones Clase A: acciones con derecho a voto y sin derecho a usufructo y Clase B: acciones con derecho a voto y con derecho a usufructo, debidamente inscritas en el Registro de Personas Jurídicas de Arequipa. 
                </Text>
              </View>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>SEGUNDO: OBJETO DEL CONTRATO</Text>
              <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                  2.1{"  "}Por el presente contrato, se realiza la Reserva y Congelamiento de todos los Beneficios y Precio del Paquete de acciones escogido por EL COMPRADOR, el cual será válido por el plazo de seis (6) meses. Asimismo, se le enviara vía correo electrónico toda la información respecto a la afiliación y los beneficios que esta le brinda. 
                </Text>

                <Text style={{ textAlign: "justify" }}>
                  2.2.{"  "}Por el presente contrato, EL COMPRADOR reserva la compra del Paquete de acciones: {" "}
                 {/*  {
                    props.dataPDF.packagesavailable.find(
                      (e) => e.package.name === props.dataPDF.userPackage
                    ).numberShares
                  } */}
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  
                  {props.dataPDF.userPackage.toUpperCase()} conforme al
                  siguiente detalle:
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 20,
                  paddingTop: 10,
                  marginLeft: 10,
                }}
              >
                {console.log('ver paquetes ==>',props.dataPDF.packagesavailable)}
                {props.dataPDF.packagesavailable.map((e) => {
                  if (e.package.name === props.dataPDF.userPackage) {
                    return (
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text >
                          {(e.package.name)}
                        </Text>
                        <Text >
                          US$ {(e.price)}
                        </Text>
                        <View style={styles.selected}></View>
                      </View>
                    );
                  } else {
                    return (
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text >
                          {(e.package.name)}
                        </Text>
                        <Text >
                          US$ {(e.price)}
                        </Text>
                        <View style={styles.noSelected}></View>
                      </View>
                    );
                  }
                })}
              </View>

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.3.Una vez cumplido el objetivo de Socios Fundadores, esta reserva tendrá una vigencia de 3 días, para que el cliente reafirme su decisión de convertirse en SOCIO FUNDADOR, con los beneficios que ello implica, una vez cumplido ese plazo de no hacerse efectiva la adquisición de un Paquete de acciones, se pierde el Beneficio, tomando este Documento el valor de "Vale de Consumo", el cual podrá hacerse efectivo por el 100% del importe cancelado en el Restaurante del Club. Este vale puede ser usado y es válido por 1 año a partir de dicho momento (Fecha de Constitución de Socios Fundadores). Asimismo, si el Afiliado deseara desistir de su interés de ser Socio Fundador, este documento toma el valor de "Vale de Consumo".
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* 2 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <Text style={styles.subtitle}>TERCERO: PRECIO Y FORMA DE PAGO</Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.1.{"  "}El PRECIO del Paquete de acciones{" "}
                {props.dataPDF.userPackage.toUpperCase()}, asciende a la suma de{" "}
                {`$${props.dataPDF.price}`},US. {props.dataPDF.pricetotalLetter} el cual le otorga a EL
                COMPRADOR el precio y beneficios que hace referencia la cláusula anterior.
              </Text>
             
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.2.{"  "}El PRECIO de la Reserva, podrá ser canceladas en las siguientes cuentas bancarias a elección de EL COMPRADOR
              </Text>
            </View>
            <Image
              style={[{ width: "100%", height: "105px", paddingBottom: 10 }]}
              src="/pdf/inresorts/precios.png"
            />
            <Text
              style={{
                textAlign: "justify",
                marginLeft: 20,
                paddingBottom: 10,
              }}
            >
              En señal de conformidad, las partes suscriben este documento en dos (2) ejemplares de igual tenor y valor, en la ciudad de Lima, {props.dataPDF.creationDate}.
            </Text>
{/* Aumentar los caracteres */}


            <View
              style={{
                paddingTop: 50,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <View style={{ flexDirection: "column", alignItems: "center" }}>
                <Image
                  src="/pdf/inresorts/firmaOmarUrteaga.jpg"
                  style={{ width: "100px", height: "50px" }}
                />
                <Text>____________________________</Text>
                <Text>VALLE ENCANTADO S.A.C.</Text>
                <Text>Omar Fernando Urteaga Cabrera</Text>
                <Text>Gerente General</Text>
                <Text>VALLE ENCANTADO</Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: 10,
                }}
              >
                <Text>____________________________</Text>
                <Text>{`${props.dataPDF.user.name} ${props.dataPDF.user.lastname}`}</Text>
                <Text>
                  Documento de identidad N°{props.dataPDF.user.nroDocument}
                </Text>
                <Text>EL COMPRADOR</Text>
              </View>
            </View>
          </View>

          

        </View>
      </Page>
    </Document>
  );
}
