import { Button, Loading } from "@nextui-org/react";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { ReactComponent as Imagenlogo } from "../../assets/svg/logoinclub.svg";
import history from "../../views/navigation/history";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";

function Login() {
  const { handleSubmit, control } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  function sweetAlert({
    nameAlert = "😎 Guardado con éxito",
    typeAlert = "info",
    time,
  }) {
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: typeAlert,
      title: nameAlert,
      showConfirmButton: false,
      timer: time,
    });
  }

  const submit = async (dataForm) => {
    setLoadingButton(true);
    let response = await AuthService.login(dataForm);
    if (response === undefined) {
      sweetAlert({
        nameAlert: "Ocurrió un problema, inténtelo más tarde.",
        typeAlert: "error",
      });
      setLoadingButton(false);
    } else {
      if (response.access_Token !== null) {
        history.push("/home");
      } else {
        sweetAlert({
          nameAlert: "Usuario y/o contraseña incorrectos.",
          typeAlert: "warning",
          time: 1800,
        });
        setLoadingButton(false);
      }
    }
  };


  return (
    <form className="formloggincontainer"  onSubmit={handleSubmit(submit)}>
      <h3 className="tw-pb-1">Iniciar sesión</h3>

      <Controller
        control={control}
        name="username"
        rules={{
          required: { value: true, message: "*Ingresa tú usuario." },
        }}
        render={({ field, fieldState }) => (
          <div className="tw-flex tw-flex-col tw-items-start tw-mb-4 w-100">
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: '4px',
                },
              }}
              fullWidth
              size="small"
              autoComplete="on"
              label="Nombre de usuario"
              variant="outlined"
              error={fieldState?.error ? true : false}
              value={field.value}
              onChange={field.onChange}
            />
            {fieldState.error && (
              <span className="tw-text-red-400 tw-m-1 tw-text-xs">
                {fieldState.error.message}
              </span>
            )}
          </div>
        )}
      />

      <Controller
        control={control}
        name="password"
        rules={{
          required: { value: true, message: "*Ingresa tú contraseña." },
        }}
        render={({ field, fieldState }) => (
          <div className="tw-flex tw-flex-col tw-items-start tw-mb-4 w-100">
            <FormControl
               sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: '4px',
                },
              }}
              variant="outlined"
              className="w-100"
            >
              <InputLabel size="small" htmlFor="outlined-login-password">
                Contraseña
              </InputLabel>
              <OutlinedInput
                id="outlined-login-password"
                error={fieldState?.error ? true : false}
                fullWidth
                size="small"
                autoComplete="on"
                type={showPassword ? "text" : "password"}
                label="Contraseña"
                variant="outlined"
                value={field.value}
                onChange={field.onChange}
                className="w-100"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {fieldState.error && (
              <span className="tw-text-red-400 tw-m-1 tw-text-xs">
                {fieldState.error.message}
              </span>
            )}
          </div>
        )}
      />

      <Button
        className="tw-w-[100%_!important]"
        disabled={loadingButton}
        type="submit"
        auto
        style={{backgroundColor: '#FE7C04', borderRadius: '2px'}}
      >
        {loadingButton ? (
          <Loading color="currentColor" size="sm" />
        ) : (
          "INICIAR SESIÓN"
        )}
      </Button>
      <p className="forgot-password text-right">
        <NavLink to="/reset">¿Olvidaste tu contraseña?</NavLink>
      </p>
    </form>
  );
}

export default Login;
