import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";
import ChatBot, { Loading } from "react-simple-chatbot";
import GenerateTicketGeneral from "../chatbot/GenerateTicketGeneral";
import GetLink from "./GetLink";
import AvailableUserGeneral from "./AvailableUserGeneral";
import AvailableUserChangeData from "./AvailableUserChangeData";
import AvailableUserInactive from "./AvailableUserInactive";
import AffiliateDateGeneral from "./AffiliateDateGeneral";
import QuoteDescriptionGeneral from "./QuoteDescriptionGeneral";
import AvailableUserBO from "./AvailableUserBO";
import PatrocinadorAvailable from "./PatrocinadorAvailable";
import PatrocinadorAvailableOther from "./PatrocinadorAvailableOther";
import InfoUser from "./InfoUser";
import AvailableUser from "./AvailableUser";
import AvailableUserInfo from "./AvailableUserInfo";
import ValidateSponsor from "./ValidateSponsor";

export default class ChatBotGeneral extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClear = () => {
    this.setState({ clear: true }, () => {
      this.setState({ clear: false });
    });
  };

  render() {
    const emailIsValid = (email) => {
      return /\S+@\S+\.\S+/.test(email);
    };
    const theme = {
      background: "#f7f7f7",
      // fontFamily: "Roboto",
      headerBgColor: "#142d47",
      headerFontColor: "#fff",
      headerFontSize: "15px",
      botBubbleColor: "#ee7521",
      botFontColor: "#fff",
      userBubbleColor: "#8eddea",
      userFontColor: "#4a4a4a",
    };

    const firstClean = (val) => {
      localStorage.setItem("userDNI", "");
      localStorage.setItem("idUser", "");
      localStorage.setItem("userOption", "");
      localStorage.setItem("userEmail", "");
      localStorage.setItem("inicialPago", "");
      localStorage.setItem("changeName", "");
      localStorage.setItem("changeLastName", "");
      localStorage.setItem("changeNationality", "");
      localStorage.setItem("changeTypeOfDocument", "");
      localStorage.setItem("changeDNI", "");
      localStorage.setItem("changeDateOfBirthdate", "");
      localStorage.setItem("changeSex", "");
      localStorage.setItem("changeEmail", "");
      localStorage.setItem("changeCivilState", "");
      localStorage.setItem("changeAddress", "");
      localStorage.setItem("changeCity", "");
      localStorage.setItem("changeCountry", "");
      localStorage.setItem("changeTelef", "");
      localStorage.setItem("dateAffiliate", "");
      localStorage.setItem("tipo", "");
      localStorage.setItem("dato", "");
      localStorage.setItem("dniPatrocinador", "");
      i = 0;

      return val;
    };

    const cleanCount = (val) => {
      localStorage.setItem("changeName", "");
      localStorage.setItem("changeLastName", "");
      localStorage.setItem("changeNationality", "");
      localStorage.setItem("changeTypeOfDocument", "");
      localStorage.setItem("changeDNI", "");
      localStorage.setItem("changeDateOfBirthdate", "");
      localStorage.setItem("changeSex", "");
      localStorage.setItem("changeEmail", "");
      localStorage.setItem("changeCivilState", "");
      localStorage.setItem("changeAddress", "");
      localStorage.setItem("changeCity", "");
      localStorage.setItem("changeCountry", "");
      localStorage.setItem("changeTelef", "");
      i = 0;
      return val;
    };

    const rest = (val) => {
      i--;
      return val;
    };

    var i = 0;

    const userOption = (val, id) => {
      localStorage.setItem("userOption", id);

      return val;
    };

    const ChangeData = (val, id) => {
      localStorage.setItem("tipo", id);
      return val;
    };

    const avatar =
      "https://image.freepik.com/vector-gratis/perfil-avatar-hombre-icono-redondo_24640-14044.jpg";

    const steps = [
      {
        id: "1",
        delay: 3000,
        message: `Bienvenido a tu asistente virtual.`,
        trigger: "2continue",
      },
      {
        id: "2continue",
        delay: 3000,
        message: `En que podemos ayudarte?`,
        trigger: () => firstClean("2"),
      },
      /*{
        id: "1",
        delay: 3000,
        message: `Ingresa tu DNI.`,
        trigger: "2aD"
      },
      {
          id: "2aD",
          user: true,
          validator: (value) => {
              if (isNaN(value)) {
                return 'El valor debe ser un número';
              } else if( value.length !=  8){
                return 'El valor debe contener 8 digitos'
              } else{
              return true;
              }
            },
          trigger: "2",
        },*/
      {
        id: "2",
        options: [
          {
            value: 1,
            label: "No puedo acceder a mi back office",
            trigger: "2a",
          },
          { value: 2, label: "Como recupero mi contraseña", trigger: "2b" },
          {
            value: 4,
            label: "Como subir varias fotos en un pago",
            trigger: "2d",
          },
          {
            value: 7,
            label: "Como puedo realizar un pago con paypal",
            trigger: "2g",
          },
          {
            value: 3,
            label:
              "Como puedo realizar un pago con tarjeta no asociada a paypal",
            trigger: "2c",
          },
          /*{ value: 5, label: "Como puedo realizar un registro con pagar después", trigger: "2e" },*/
          { value: 8, label: "Cambiar datos", trigger: "3aDNI" },
          { value: 11, label: "Aparezco como inactivo", trigger: "ACI" },
          /*{ value: 10, label: "Como puedo realizar un registro con pagar después", trigger: "5" },*/
          { value: 6, label: "No me llegó el correo de pago", trigger: "2f" },
        ],
      },
      {
        id: "2a",
        delay: 3000,
        message: `Intenta reestablecer tu contraseña realizando los siguientes pasos.`,
        trigger: () => userOption("2a-1", "12"),
      },
      {
        id: "2a-1",
        delay: 3000,
        message: `1. Ingresar a https://inclub.world/.`,
        trigger: "2a-2",
      },
      {
        id: "2a-2",
        delay: 3000,
        message: `2. Dar click en iniciar sesión.`,
        trigger: "2a-3",
      },
      {
        id: "2a-3",
        delay: 3000,
        message: `3. Dar click en olvidé mi contraseña.`,
        trigger: "2a-4",
      },
      {
        id: "2a-4",
        delay: 3000,
        message: `4. Colocar tu ID de usuario o correo electrónico.`,
        trigger: "2a-5",
      },
      {
        id: "2a-5",
        delay: 3000,
        message: `5. Revisar la bandeja de entrada de tu correo electrónico y dar click en link para restablecer contraseña.`,
        trigger: "2a-6",
      },
      {
        id: "2a-6",
        delay: 3000,
        message: `6. Escribir tu nueva clave.`,
        trigger: "2a-7",
      },
      {
        id: "2a-7",
        delay: 3000,
        message: `7. Volver a escribir tu nueva clave para confirmar.`,
        trigger: "2a-8",
      },
      {
        id: "2a-8",
        delay: 3000,
        message: `8. Dar click en restablecer/aceptar.`,
        trigger: "2a-9",
      },
      {
        id: "2a-9",
        delay: 3000,
        message: `LISTO!, Al terminar este proceso ya habrás cambiado tu contraseña.`,
        trigger: "2a6",
      },
      {
        id: "2a6",
        asMessage: true,
        delay: 3000,
        component: (
          <div>
            <a
              href="https://www.youtube.com/watch?v=-Wer5jODWew"
              target="_blank"
              style={{ color: "#FFFF" }}
            >
              Video Tutorial Click Aqui
            </a>
          </div>
        ),
        trigger: "2aOP",
      },
      {
        id: "2aOP",
        delay: 1000,
        message: `Has intentado reestablecer tu contraseña y el error persiste?`,
        trigger: "2a8OP",
      },
      {
        id: "2a8OP",
        options: [
          { value: 1, label: "Si", trigger: "5OP" },
          { value: 2, label: "No", trigger: "final2" },
        ],
      },
      {
        id: "2a7",
        delay: 1000,
        message: `Has intentado hacer el proceso y el error persiste?`,
        trigger: "2a8",
      },
      {
        id: "2a8",
        options: [
          { value: 1, label: "Si", trigger: "5" },
          { value: 2, label: "No", trigger: "final2" },
        ],
      },
      {
        id: "2b",
        message: `Para recuperar/restablecer tu contraseña debes realizar lo siguiente.`,
        trigger: () => userOption("2ba", "13"),
      },
      {
        id: "2ba",
        delay: 3000,
        message: `1. Ingresar a https://inclub.world/.`,
        trigger: "2bb",
      },
      {
        id: "2bb",
        delay: 3000,
        message: `2. Dar click en iniciar sesión.`,
        trigger: "2bc",
      },
      {
        id: "2bc",
        delay: 3000,
        message: `3. Dar click en olvidé mi contraseña.`,
        trigger: "2bd",
      },
      {
        id: "2bd",
        delay: 3000,
        message: `4. Colocar tu ID de usuario o correo electrónico.`,
        trigger: "2be",
      },
      {
        id: "2be",
        delay: 3000,
        message: `5. Revisar la bandeja de entrada de tu correo electrónico y dar click en link para restablecer contraseña.`,
        trigger: "2bf",
      },
      {
        id: "2bf",
        delay: 3000,
        message: `6. Escribir tu nueva clave.`,
        trigger: "2bg",
      },
      {
        id: "2bg",
        delay: 3000,
        message: `7. Volver a escribir tu nueva clave para confirmar.`,
        trigger: "2bh",
      },
      {
        id: "2bh",
        delay: 3000,
        message: `8. Dar click en restablecer/aceptar.`,
        trigger: "2bi",
      },
      {
        id: "2bi",
        delay: 3000,
        message: `LISTO!, Al terminar este proceso ya habrás cambiado tu contraseña.`,
        trigger: "2b1",
      },
      {
        id: "2b1",
        asMessage: true,
        delay: 2000,
        component: <GetLink id={1} />,
        waitAction: true,
      },
      {
        id: "2c",
        message: `Para realizar el pago de tu afiliación debes realizar lo siguiente.`,
        trigger: () => userOption("2ca", "25"),
      },
      {
        id: "2ca",
        delay: 3000,
        message: `1. Ingresar a tu correo (Revisar bandeja de entrada como correos SPAM).`,
        trigger: "2cb",
      },
      {
        id: "2cb",
        delay: 3000,
        message: `2. Dar click en el botón validar pago.`,
        trigger: "2cc",
      },
      {
        id: "2cc",
        delay: 3000,
        message: `3. Seleccionar el logo de BCP o INTERBANK (Según el banco desde que hayas realizado el pago).`,
        trigger: "2cd",
      },
      {
        id: "2cd",
        delay: 3000,
        message: `4. Seleccionar tipo de moneda soles o dólares (Según el tipo de moneda con el que hayas pagado).`,
        trigger: "2ce",
      },
      {
        id: "2ce",
        delay: 3000,
        message: `5. En la opción tipo de pago debes seleecionar el medio desde el cual realizaste el pago (AGENTE, VENTANILLA DEL BANCO, CAJERO, YAPE, BANCA MÓVIL O BANCA POR INTERNET).`,
        trigger: "2cf",
      },
      {
        id: "2cf",
        delay: 3000,
        message: `6. Colocar el código de operación.`,
        trigger: "2cg",
      },
      {
        id: "2cg",
        delay: 3000,
        message: `7. En la opción titular debes colocar tu nombre y apellido.`,
        trigger: "2ch",
      },
      {
        id: "2ch",
        delay: 3000,
        message: `8. Selecciona la foto de tu pago.`,
        trigger: "2ci",
      },
      {
        id: "2ci",
        delay: 3000,
        message: `9. Da click en guardar y verás el detalle de tu pago en el sistema.`,
        trigger: "2cj",
      },
      {
        id: "2cj",
        delay: 3000,
        message: `10. Da click en guardar nuevamente.`,
        trigger: "2c1",
      },
      /*{
          id: "2c",
          message: `Debes realizar los siguientes pasos.`,
          trigger: "2c1"
        },*/
      {
        id: "2c1",
        asMessage: true,
        delay: 3000,
        component: <GetLink id={4} />,
        waitAction: true,
      },
      {
        id: "2d",
        message: `Para subir varias fotos en una sola cuota debes:`,
        trigger: () => userOption("2da", "15"),
      },
      {
        id: "2da",
        delay: 3000,
        message: `1. Ingresar a https://inclub.world/`,
        trigger: "2db",
      },
      {
        id: "2db",
        delay: 3000,
        message: `2. Dar click en comisiones y pagos.`,
        trigger: "2dc",
      },
      {
        id: "2dc",
        delay: 3000,
        message: `3. Dar click en mis pagos.`,
        trigger: "2dd",
      },
      {
        id: "2dd",
        delay: 3000,
        message: `4. Dar click en el botón VERDE "PAGAR"`,
        trigger: "2de",
      },
      {
        id: "2de",
        delay: 3000,
        message: `5. Dar click en el logo BCP o INTERBANK (Según el banco donde hayas realizado el pago).`,
        trigger: "2df",
      },
      {
        id: "2df",
        delay: 3000,
        message: `6. Selecciona tipo de moneda soles o dólares (Según el tipo de moneda con el que hayas realizado el pago).`,
        trigger: "2dg",
      },
      {
        id: "2dg",
        delay: 3000,
        message: `7. Selecciona tipo de pago (Ventanilla, BCP, YAPE, etc. Según corresponda).`,
        trigger: "2dh",
      },
      {
        id: "2dh",
        delay: 3000,
        message: `8. Coloca el código de operación del primer voucher.`,
        trigger: "2di",
      },
      {
        id: "2di",
        delay: 3000,
        message: `9. En titular (DEJA EN BLANCO).`,
        trigger: "2dj",
      },
      {
        id: "2dj",
        delay: 3000,
        message: `10. Sube la foto de tu primer voucher.`,
        trigger: "2dk",
      },
      {
        id: "2dk",
        delay: 3000,
        message: `11. Da click en guardar (Hasta aquí habrás subido un solo voucher, aún no termina el proceso).`,
        trigger: "2dl",
      },
      {
        id: "2dl",
        delay: 3000,
        message: `12. Volver a dar click en el logo BCP o INTERBANK según el banco donde hayas realizado el 2do pago (2do voucher).`,
        trigger: "2dm",
      },
      {
        id: "2dm",
        delay: 3000,
        message: `13. Selecciona tipo de moneda soles o dólares (Según el tipo de moneda con el que hayas realizado el pago).`,
        trigger: "2dn",
      },
      {
        id: "2dn",
        delay: 3000,
        message: `14. Selecciona tipo de pago (Ventanilla, BCP, YAPE, etc. Según corresponda).`,
        trigger: "2do",
      },
      {
        id: "2do",
        delay: 3000,
        message: `15. Coloca el código de operación del primer voucher.`,
        trigger: "2dp",
      },
      {
        id: "2dp",
        delay: 3000,
        message: `16. En titular (DEJA EN BLANCO).`,
        trigger: "2dq",
      },
      {
        id: "2dq",
        delay: 3000,
        message: `17. Sube la foto de tu segundo voucher.`,
        trigger: "2dr",
      },
      {
        id: "2dr",
        delay: 3000,
        message: `18. Da click en GUARDAR.`,
        trigger: "2ds",
      },
      {
        id: "2ds",
        delay: 3000,
        message: `19. Da click en el botón REGISTRAR.`,
        trigger: "2dt",
      },
      {
        id: "2dt",
        delay: 3000,
        message: `20. LISTO! Ya habrás subido los dos vouchers en un solo pago.`,
        trigger: "2d1",
      },
      {
        id: "2d1",
        asMessage: true,
        delay: 2000,
        component: <GetLink id={2} />,
        waitAction: true,
      },
      {
        id: "2e",
        message: `Debes realizar lo siguiente.`,
        trigger: () => userOption("2e1", "17"),
      },
      {
        id: "2e1",
        asMessage: true,
        delay: 2000,
        component: <GetLink id={5} />,
        waitAction: true,
      },
      {
        id: "2f",
        message: "Indica tu documento de identidad.",
        trigger: "2fa",
      },
      {
        id: "2fa",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Indique el documento de identidad";
          } else {
            localStorage.setItem("userDNI", value);
            return true;
          }
        },
        trigger: "2fb",
      },
      {
        id: "2fb",
        asMessage: true,
        delay: 2000,
        component: <AvailableUserInfo />,
        waitAction: true,
      },
      {
        id: "2f1",
        delay: 3000,
        asMessage: true,
        component: <InfoUser />,
        waitAction: true,
      },
      {
        id: "2foptions",
        options: [
          { value: 1, label: "Si, es correcto.", trigger: "2f2" },
          { value: 2, label: "No, no es correcto.", trigger: "2fother" },
        ],
      },
      {
        id: "2f2",
        delay: 1000,
        message: `Indica que correo no te ha llegado.`,
        trigger: "2f3",
      },
      {
        id: "2f3",
        options: [
          {
            value: 1,
            label: "Correo de bienvenida con usuario y clave",
            trigger: "2f4",
          },
          {
            value: 2,
            label: "Correo para que realices tu pago",
            trigger: "2f4",
          },
        ],
      },
      {
        id: "2fother",
        delay: 1000,
        message: `Indica a que correo quieres que te llegue.`,
        trigger: "2fother-a",
      },
      {
        id: "2fother-a",
        user: true,
        validator: (value) => {
          if (emailIsValid(value)) {
            localStorage.setItem("userEmailOther", value);
            return true;
          } else {
            return "Correo inválido";
          }
        },
        trigger: "2f2",
      },
      {
        id: "2f4",
        delay: 1000,
        message: `Si el socio no está registrado debes consultar con tu patrocinador para verificar que el patrocinador te haya registrado correctamente.`,
        trigger: "5email",
      },
      {
        id: "2g",
        message: `Para realizar el pago de tu afiliación debes realizar lo siguiente.`,
        trigger: () => userOption("2ga", "16"),
      },
      {
        id: "2ga",
        delay: 3000,
        message: `1. Ingresar a tu correo (Revisar bandeja de entrada como correos SPAM).`,
        trigger: "2gb",
      },
      {
        id: "2gb",
        delay: 3000,
        message: `2. Dar click en el botón validar pago.`,
        trigger: "2gc",
      },
      {
        id: "2gc",
        delay: 3000,
        message: `3. Seleccionar el logo de PAYPAL.`,
        trigger: "2gd",
      },
      {
        id: "2gd",
        delay: 3000,
        message: `4. Seleccionar tipo de moneda dólares.`,
        trigger: "2ge",
      },
      {
        id: "2ge",
        delay: 3000,
        message: `5. Dar click en el botón amarillo "PAYPAL".`,
        trigger: "2gf",
      },
      {
        id: "2gf",
        delay: 3000,
        message: `6. Acceder a tu cuenta de PAYPAL.`,
        trigger: "2gg",
      },
      {
        id: "2gg",
        delay: 3000,
        message: `7. Dar click en pagar.`,
        trigger: "2g1",
      },
      {
        id: "2g1",
        asMessage: true,
        delay: 2000,
        component: <GetLink id={3} />,
        waitAction: true,
      },
      {
        id: "3a",
        message: "Por favor indica tu nombre completo",
        trigger: "3-a",
      },
      {
        id: "3-a",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            return true;
          }
        },
        trigger: "3b",
      },
      {
        id: "3aDNI",
        message: "Indica tu documento de identidad",
        trigger: "3-aDNI",
      },
      {
        id: "3-aDNI",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Indique el documento de identidad";
          } else {
            localStorage.setItem("userDNI", value);
            return true;
          }
        },
        trigger: "3aComponente",
      },
      {
        id: "3aComponente",
        asMessage: true,
        delay: 2000,
        component: <AvailableUserChangeData />,
        waitAction: true,
      },
      {
        id: "3a",
        message:
          "Por favor indica el número de documento de identidad del socio a modificar",
        trigger: "3b",
      },
      {
        id: "3b",
        user: true,
        validator: (value) => {
          return true;
        },
        trigger: "3c",
      },
      {
        id: "3c",
        message:
          "El número de documento que ingresaste es {previousValue}, ahora por favor seleciona el dato que deseas modificar: ",
        trigger: "3adv",
      },
      {
        id: "3adv",
        message: `Selecciona los datos que quiere modificar, COMO MÁXIMO 3 DATOS.`,
        trigger: "3d",
      },
      {
        id: "3d",
        options: [
          { value: 1, label: "Nombre", trigger: "3eNombre" },
          { value: 2, label: "Apellido", trigger: "3eApellido" },
          { value: 3, label: "Nacionalidad", trigger: "3eNacionalidad" },
          {
            value: 4,
            label: "Tipo de documento de identidad (ej. DNI)",
            trigger: "3eTipodocumento",
          },
          {
            value: 5,
            label: "Número de documento de identidad",
            trigger: "3eNroDocumento",
          },
          {
            value: 6,
            label: "Fecha de nacimiento",
            trigger: "3eFechaNacimiento",
          },
          { value: 13, label: "Sexo", trigger: "3eSexo" },
          { value: 8, label: "Estado civil", trigger: "3eEstadoCivil" },
          {
            value: 7,
            label: "Correo electrónico",
            trigger: "3eCorreoElectronico",
          },
          { value: 11, label: "País", trigger: "3ePais" },
          { value: 10, label: "Ciudad", trigger: "3eCiudad" },
          { value: 9, label: "Dirección", trigger: "3eDireccion" },
          { value: 12, label: "Número de celular", trigger: "3eNroCelular" },
        ],
      },
      {
        id: "3eNombre",
        message: "Por favor indica a continuación el nuevo nombre",
        trigger: "3fNombre",
      },
      {
        id: "3fNombre",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeName", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gNombre",
      },
      {
        id: "3eApellido",
        message: "Por favor indica a continuación el nuevo apellido",
        trigger: "3fApellido",
      },
      {
        id: "3fApellido",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeLastName", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gApellido",
      },
      {
        id: "3eNacionalidad",
        message: "Por favor indica a continuación la nueva nacionalidad",
        trigger: "3fNacionalidad",
      },
      {
        id: "3fNacionalidad",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeNationality", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gNacionalidad",
      },
      {
        id: "3eTipodocumento",
        message:
          "Por favor indica a continuación el nuevo tipo de documento (ej DNI)",
        trigger: "3fTipodocumento",
      },
      {
        id: "3fTipodocumento",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeTypeOfDocument", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gTipo",
      },
      {
        id: "3eNroDocumento",
        message:
          "Por favor indica a continuación el nuevo número del documento de identidad",
        trigger: "3fNroDocumento",
      },
      {
        id: "3fNroDocumento",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeDNI", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gDocumento",
      },

      {
        id: "3eFechaNacimiento",
        message: "Por favor indica a continuación la nueva fecha de nacimiento",
        trigger: "3fFechaNacimiento",
      },
      {
        id: "3fFechaNacimiento",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeDateOfBirthdate", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gFecha",
      },

      {
        id: "3eCorreoElectronico",
        message: "Por favor indica a continuación el nuevo correo electrónico",
        trigger: "3fCorreoElectronico",
      },
      {
        id: "3fCorreoElectronico",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeEmail", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gEmail",
      },
      {
        id: "3eSexo",
        message: "Por favor indica a continuación el nuevo sexo",
        trigger: "3fSexo",
      },
      {
        id: "3fSexo",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeSex", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gSexo",
      },

      {
        id: "3eEstadoCivil",
        message: "Por favor indica a continuación el nuevo Estado civil",
        trigger: "3fEstadoCivil",
      },
      {
        id: "3fEstadoCivil",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeCivilState", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gEstado",
      },
      {
        id: "3eDireccion",
        message: "Por favor indica a continuación la nueva dirección",
        trigger: "3fDireccion",
      },
      {
        id: "3fDireccion",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeAddress", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gDireccion",
      },

      {
        id: "3eCiudad",
        message: "Por favor indica a continuación la nueva Ciudad",
        trigger: "3fCiudad",
      },
      {
        id: "3fCiudad",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeCity", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gCiudad",
      },

      {
        id: "3ePais",
        message: "Por favor indica a continuación el nuevo Pais",
        trigger: "3fPais",
      },
      {
        id: "3fPais",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeCountry", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gPais",
      },
      {
        id: "3eNroCelular",
        message: "Por favor indica a continuación el nuevo número de celular",
        trigger: "3fNroCelular",
      },
      {
        id: "3fNroCelular",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeTelef", value);
          localStorage.setItem("userOption", 3);
          i++;
          return true;
        },
        trigger: "3gTelefono",
      },
      {
        id: "3gNombre",
        message:
          "Has indicado el nombre {previousValue}, ¿es correcta esta información?",
        trigger: "3hNombre",
      },
      {
        id: "3gApellido",
        message:
          "Has indicado el apellido {previousValue}, ¿es correcta esta información?",
        trigger: "3hApellido",
      },
      {
        id: "3gNacionalidad",
        message:
          "Has indicado la nacionalidad {previousValue}, ¿es correcta esta información?",
        trigger: "3hNacionalidad",
      },
      {
        id: "3gTipo",
        message:
          "Has indicado el tipo de documento {previousValue}, ¿es correcta esta información?",
        trigger: "3hTipo",
      },
      {
        id: "3gDocumento",
        message:
          "Has indicado el número de documento {previousValue}, ¿es correcta esta información?",
        trigger: "3hDocumento",
      },
      {
        id: "3gFecha",
        message:
          "Has indicado la fecha de nacimiento {previousValue}, ¿es correcta esta información?",
        trigger: "3hFecha",
      },
      {
        id: "3gSexo",
        message:
          "Has indicado el sexo {previousValue}, ¿es correcta esta información?",
        trigger: "3hSexo",
      },
      {
        id: "3gEstado",
        message:
          "Has indicado el estado civil {previousValue}, ¿es correcta esta información?",
        trigger: "3hEstado",
      },
      {
        id: "3gEmail",
        message:
          "Has indicado el correo electrónico {previousValue}, ¿es correcta esta información?",
        trigger: "3hEmail",
      },
      {
        id: "3gPais",
        message:
          "Has indicado el país de residencia {previousValue}, ¿es correcta esta información?",
        trigger: "3hPais",
      },
      {
        id: "3gCiudad",
        message:
          "Has indicado la ciudad {previousValue}, ¿es correcta esta información?",
        trigger: "3hCiudad",
      },
      {
        id: "3gDireccion",
        message:
          "Has indicado la dirección {previousValue}, ¿es correcta esta información?",
        trigger: "3hDireccion",
      },
      {
        id: "3gTelefono",
        message:
          "Has indicado el número de teléfono {previousValue}, ¿es correcta esta información?",
        trigger: "3hTelefono",
      },
      {
        id: "3hNombre",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el nombre",
            trigger: () => rest("3eNombre"),
          },
        ],
      },
      {
        id: "3hApellido",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el apellido",
            trigger: () => rest("3eApellido"),
          },
        ],
      },
      {
        id: "3hNacionalidad",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir la nacionalidad",
            trigger: () => rest("3eNacionalidad"),
          },
        ],
      },
      {
        id: "3hTipo",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el tipo de documento",
            trigger: () => rest("3eTipodocumento"),
          },
        ],
      },
      {
        id: "3hDocumento",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el número de documento",
            trigger: () => rest("3eNroDocumento"),
          },
        ],
      },
      {
        id: "3hFecha",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir la fecha de nacimiento",
            trigger: () => rest("3eFechaNacimiento"),
          },
        ],
      },
      {
        id: "3hSexo",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el sexo",
            trigger: () => rest("3eSexo"),
          },
        ],
      },
      {
        id: "3hEstado",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el estado civil",
            trigger: () => rest("3eEstadoCivil"),
          },
        ],
      },
      {
        id: "3hEmail",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el correo electrónico",
            trigger: () => rest("3eCorreoElectronico"),
          },
        ],
      },
      {
        id: "3hPais",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el país de residencia",
            trigger: () => rest("3ePais"),
          },
        ],
      },
      {
        id: "3hCiudad",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir la ciudad",
            trigger: () => rest("3eCiudad"),
          },
        ],
      },
      {
        id: "3hDireccion",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir la dirección",
            trigger: () => rest("3eDireccion"),
          },
        ],
      },
      {
        id: "3hTelefono",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el número de teléfono",
            trigger: () => rest("3eNroCelular"),
          },
        ],
      },
      {
        id: "3i",
        message: "¿Deseas modificar otro dato?",
        trigger: "3j",
      },
      {
        id: "3null",
        message: "Usted ya modificó 3 datos.",
        trigger: "3optiona",
      },
      {
        id: "3optiona",
        message: "Son correctos los datos a cambiar?.",
        trigger: "3options",
      },
      {
        id: "3options",
        options: [
          { value: 1, label: "Si, son correctos.", trigger: "5email" },
          {
            value: 2,
            label: "No, deseo cambiar mis datos nuevamente.",
            trigger: () => cleanCount("3adv"),
          },
        ],
      },
      {
        id: "3j",
        options: [
          { value: 1, label: "Si, quiero corregir otro dato", trigger: "3d" },
          {
            value: 2,
            label: "No, ya he terminado con los datos a corregir",
            trigger: "5email",
          },
        ],
      },
      {
        id: "5email",
        message:
          "Se generará una solicitud a soporte, por favor indica a que correo deseas recibir la respuesta de su solicitud.",
        trigger: "finalA2",
      },
      {
        id: "5",
        message:
          "Se generará una solicitud a soporte, por favor indica el número de documento de identidad del usuario",
        trigger: "5-a",
      },
      {
        id: "5OP",
        message:
          "Se generará una solicitud a soporte, por favor indica el número de documento de identidad del usuario",
        trigger: "5-aOP",
      },
      {
        id: "5-aOP",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Indique el documento de identidad";
          } else {
            localStorage.setItem("userDNI", value);

            return true;
          }
        },
        trigger: "5-aComponenteOP",
      },
      {
        id: "5-a",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Indique el documento de identidad";
          } else {
            localStorage.setItem("userDNI", value);

            return true;
          }
        },
        trigger: "5-aComponente",
      },
      {
        id: "5-aComponente",
        asMessage: true,
        delay: 2000,
        component: <AvailableUserGeneral />,
        waitAction: true,
      },
      {
        id: "5-aComponenteOP",
        asMessage: true,
        delay: 2000,
        component: <AvailableUserBO />,
        waitAction: true,
      },
      {
        id: "dateAffiliate",
        message: "Indica la fecha en que se afilió.",
        trigger: "dateAffiliate-a",
      },
      {
        id: "dateAffiliate-a",
        user: true,
        validator: (value) => {
          localStorage.setItem("dateAffiliate", value);
          return true;
        },
        trigger: "dniPatrocinador",
      },
      {
        id: "dniPatrocinador",
        message: "Indica documento de identidad del patrocinador.",
        trigger: "dniPatrocinador-a",
      },
      {
        id: "dniPatrocinador-a",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Indique el documento de identidad";
          } else {
            localStorage.setItem("dniPatrocinador", value);
            return true;
          }
        },
        trigger: "PatrocinadorComponente",
      },
      {
        id: "PatrocinadorComponente",
        asMessage: true,
        delay: 2000,
        component: <ValidateSponsor />,
        waitAction: true,
      },
      {
        id: "dateAffiliateOther",
        message: "Indica la fecha en que se afilió.",
        trigger: "dateAffiliateOther-a",
      },
      {
        id: "dateAffiliateOther-a",
        user: true,
        validator: (value) => {
          localStorage.setItem("dateAffiliate", value);
          return true;
        },
        trigger: "patrocinadorOther",
      },
      {
        id: "patrocinadorOther",
        message: "Indica documento de identidad del patrocinador.",
        trigger: "patrocinadorOther-a",
      },
      {
        id: "patrocinadorOther-a",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Indique el documento de identidad";
          } else {
            localStorage.setItem("dniPatrocinador", value);
            return true;
          }
        },
        trigger: "PatrocinadorComponenteOther",
      },
      {
        id: "PatrocinadorComponenteOther",
        asMessage: true,
        delay: 2000,
        component: <PatrocinadorAvailableOther />,
        waitAction: true,
      },
      {
        id: "changedato",
        delay: 5000,
        message: `¿Has cambiado algun dato en tu back office (dni/nombre/apellido)?`,
        trigger: "changedato-a",
      },
      {
        id: "changedato-a",
        options: [
          { value: 1, label: "Si", trigger: "changedato-o" },
          { value: 2, label: "No", trigger: "final4" },
        ],
      },
      {
        id: "changedato-o",
        delay: 5000,
        message: `Cual es el dato que has cambiado?`,
        trigger: "changedato-o1",
      },
      {
        id: "changedato-o1",
        options: [
          {
            value: 1,
            label: "DNI",
            trigger: () => ChangeData("datachange", "DNI"),
          },
          {
            value: 2,
            label: "NOMBRE",
            trigger: () => ChangeData("datachange", "Nombre"),
          },
          {
            value: 3,
            label: "APELLIDO",
            trigger: () => ChangeData("datachange", "Apellido"),
          },
        ],
      },
      {
        id: "datachange",
        message: "Indica el dato que colocaste cuando hiciste el cambio.",
        trigger: "datachange-a",
      },
      {
        id: "datachange-a",
        user: true,
        validator: (value) => {
          localStorage.setItem("dato", value);
          return true;
        },
        trigger: "finalA",
      },
      {
        id: "ACI",
        message: "Indica tu documento de identidad",
        trigger: "aci-aDNI",
      },
      {
        id: "aci-aDNI",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Indique el documento de identidad";
          } else {
            localStorage.setItem("userDNI", value);
            return true;
          }
        },
        trigger: "aciComponente",
      },
      {
        id: "aciComponente",
        asMessage: true,
        delay: 5000,
        component: <AvailableUserInactive />,
        waitAction: true,
      },
      {
        id: "7Componente",
        asMessage: true,
        delay: 5000,
        component: <AffiliateDateGeneral />,
        waitAction: true,
      },
      {
        id: "7b",
        message: "¿Es correcto?",
        trigger: "7c",
      },
      {
        id: "7c",
        options: [
          { value: 1, label: "Si", trigger: "7c1" },
          { value: 2, label: "No", trigger: "7c2" },
        ],
      },
      {
        id: "7c1",
        asMessage: true,
        delay: 5000,
        component: <QuoteDescriptionGeneral />,
        waitAction: true,
      },
      {
        id: "7c1a",
        delay: 3000,
        message:
          "Si has pagado esa cuota y no se ve en tu cronograma intenta subirla nuevamente.",
        trigger: "final4",
      },
      {
        id: "7c2",
        message: "Indica la fecha de pago de tu última cuota: ",
        trigger: "7d",
      },
      {
        id: "7d",
        user: true,
        validator: (value) => {
          localStorage.setItem("fecha_pago_ultima_cuota", value);
          localStorage.setItem("userOption", 7);
          return true;
        },
        trigger: "5email",
      },
      {
        id: "finalA",
        message:
          "Indica a que correo deseas recibir la respuesta de tu solicitud.",
        trigger: "finalA2",
      },
      {
        id: "finalA2",
        user: true,
        validator: (value) => {
          if (emailIsValid(value)) {
            localStorage.setItem("userEmail", value);
            return true;
          } else {
            return "Correo incorrecto, por favor verifica";
          }
        },
        trigger: "finalSend",
      },
      {
        id: "finalSend",
        asMessage: true,
        component: <GenerateTicketGeneral />,
        waitAction: true,
        trigger: "final2",
      },
      {
        id: "final2",
        delay: 5000,
        message: `¿Deseas realizar alguna otra consulta?`,
        trigger: "final3",
      },
      {
        id: "final3",
        options: [
          {
            value: 1,
            label: "Si, deseo realizar otra consulta",
            trigger: "2continue",
          },
          { value: 2, label: "No, ya he terminado", trigger: "final4" },
        ],
      },
      {
        id: "final4",
        delay: 3000,
        message:
          "Gracias por usar nuestro asistente virtual, esperamos haberte ayudado.",
        trigger: "final5",
      },
      {
        id: "final5",
        options: [
          {
            value: 1,
            label: "si tienes alguna otra consulta, click aquí",
            trigger: "2continue",
          },
          {
            value: 2,
            label: "Cerrar",
            end: true,
          },
        ],
      },
    ];

    return this.state.clear ? (
      <div />
    ) : (
      <div>
        <ThemeProvider theme={theme}>
          {/*   <ChatBot
            handleEnd={this.handleClear}
            floating={true}
            botAvatar={avatar}
            headerTitle={"Asistente Inclub"}
            botDelay={1100}
            steps={steps}
          /> */}
        </ThemeProvider>
      </div>
    );
  }
}
