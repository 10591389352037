import React from 'react'
import './KHeader.css'
const KHeader = ({
    link = () => window.history.back(),
    title,
}) => {
    return (
        <div className="kcart-header">
            <div className="icon-back"
                onClick={link}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" /></svg>
            </div>
            <div className="title">
                <h1>{title}</h1>
            </div>
        </div>
    )
}

export default KHeader
