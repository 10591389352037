import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import ChatBot, { Loading } from 'react-simple-chatbot';
import ChatBotService from "../../services/chatbot.service";
import Validation from "../utils/Validation";

export default class AffiliateDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: true,
        result: '',
    };
  }

  componentDidMount(){
    this.AffiliateDate();
  }

  async AffiliateDate(){

    let data = {
        nroDocument: localStorage.getItem("userDNI")
    }

    ;

    let response = await ChatBotService.DateAffiliation(data);
    
    if(response != undefined){
        if(response.status == 1){
          if(response.objModel[0].payDate != null){
            this.setState({
                loading: false,
                result: 'Tu fecha de afiliación fue el: ' + Validation.convertDateToString(response.objModel[0].fechaDeAfiliacion) + ' y la fecha de pago de tu ultima cuota fue el: ' + Validation.convertDateToString(response.objModel[0].payDate)
            })
            this.triggertNext();
          }else{
            this.setState({
              loading: false,
              result: 'En estos momentos el equipo de desarrolo se encuentra validando tu pago.'
            })
            this.triggertNextFalse();
          }
        }else{
            this.setState({
                loading: false,
                result: 'Error al consultar la fecha de afiliacion y ultima fecha de pago de cuota, realice la solicitud nuevamente.'
            })
            this.triggertNextError();
        }
    }else{
        this.setState({
            loading: false,
            result: 'Error al consultar la fecha de afiliacion y ultima fecha de pago de cuota, realice la solicitud nuevamente.'
        })
        this.triggertNextError();
    }

    /// end
  }

  triggertNext() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "7b"});
    });
  }

  triggertNextFalse() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "final4"});
    });
  }

  triggertNextError() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "2"});
    });
  }


  render() {
      const {loading, result} = this.state;

    return (
      <div className="dbpedia" style={{color: '#fff'}}>
        { loading ? <Loading /> : result }

      </div>
    );
  }

}