import { OverlayTrigger, Popover } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./WeekEvent.css";

function CardWeekEvent(props) {
  return (
    <section className="card week-card-container h-100 text-dark tw-cursor-pointer hminevent">
      <img
        src={`data:image/*;base64,${props.item.picture?.base64}`}
        className="card-img-top event-card-img"
        alt={"flyer-" + props.item.id}
      />

      <div className="week-card-body-title">
        <h5>{props.item.subMeetingType.name}</h5>
      </div>

      <div className="style-body-card-event pb-3 pt-1">
        <div>Presentador:{" " + props.item.speaker}</div>

        <div>
          <p className="tw-m-0">
            Inicio: {props.item.startDatePeru} <span>Perú </span>
            <img
              src={`https://flagcdn.com/16x12/pe.png`}
              srcSet={`https://flagcdn.com/32x24/pe.png 2x, https://flagcdn.com/48x36/pe.png 3x`}
              width="16"
              height="12"
              alt="bandera"
            />
          </p>

          <p className="tw-m-0">
            Fin: {props.item.endDatePeru} <span>Perú </span>
            <img
              src={`https://flagcdn.com/16x12/pe.png`}
              srcSet={`https://flagcdn.com/32x24/pe.png 2x, https://flagcdn.com/48x36/pe.png 3x`}
              width="16"
              height="12"
              alt="bandera"
            />
          </p>
        </div>

        {props.ipInfo?.country !== "PE" && (
          <div>
            <p className="tw-m-0">
              Inicio: {props.item.startDateLocale}{" "}
              <span>
                {props.ipInfo?.country}/{props.ipInfo?.city}{" "}
              </span>
              <img
                src={`https://flagcdn.com/16x12/${props.ipInfo.country.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/32x24/${props.ipInfo.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${props.ipInfo.country.toLowerCase()}.png 3x`}
                width="16"
                height="12"
                alt="bandera"
              />
            </p>

            <p className="tw-m-0">
              Fin: {props.item.endDateLocale}{" "}
              <span>
                {props.ipInfo?.country}/{props.ipInfo?.city}{" "}
              </span>
              <img
                src={`https://flagcdn.com/16x12/${props.ipInfo.country.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/32x24/${props.ipInfo.country.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${props.ipInfo.country.toLowerCase()}.png 3x`}
                width="16"
                height="12"
                alt="bandera"
              />
            </p>
          </div>
        )}
      </div>
    </section>
  );
}

export default function WeekEvent(props) {
  const [datamonday, setdatamonday] = useState([]);
  const [datatuesday, setdatatuesday] = useState([]);
  const [datawednesday, setdatawednesday] = useState([]);
  const [datathursday, setdatathursday] = useState([]);
  const [datafriday, setdatafriday] = useState([]);
  const [datasaturday, setdatasaturday] = useState([]);
  const [datasunday, setdatasunday] = useState([]);

  function enterMeeting(item) {
    //
    if (item.showpopover === false) {
      if (item.recordedLink !== null) window.open(item.recordedLink);
      else {
        const urlapi = item.subMeetingType.landing.url;
        window.location = urlapi.split("/")[3] + "/" + urlapi.split("/")[4];
        sessionStorage.setItem("idmeeting", item.id);
      }
    } else {
      alert("Esta reunión no está habilitada. Revise su horario");
    }
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Esta reunión no está habilitada. Revise su horario
      </Popover.Body>
    </Popover>
  );

  const arrayWeek = [
    { name: "Lunes", data: datamonday },
    { name: "Martes", data: datatuesday },
    { name: "Miércoles", data: datawednesday },
    { name: "Jueves", data: datathursday },
    { name: "Viernes", data: datafriday },
    { name: "Sábado", data: datasaturday },
    { name: "Domingo", data: datasunday },
  ];

  const startOfWeek = (dirtyOptions) => {
    //https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date
    const weekStartsOn = dirtyOptions?.weekStartsOn ?? 0;
    const date = new Date();
    const day = date.getDay();
    const diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;

    date.setDate(date.getDate() - diff);
    date.setHours(0, 0, 0, 0);

    return date;
  };

  useEffect(() => {
    const startThisWeek = startOfWeek({ weekStartsOn: 1 });
    setdatamonday(
      props.data
        .filter(
          (item) =>
            item.dayofweekstart === "lunes" &&
            new Date(item.startDate) >= startThisWeek
        )
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    );
    setdatatuesday(
      props.data
        .filter(
          (item) =>
            item.dayofweekstart === "martes" &&
            new Date(item.startDate) >= startThisWeek
        )
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    );
    setdatawednesday(
      props.data
        .filter(
          (item) =>
            item.dayofweekstart === "miércoles" &&
            new Date(item.startDate) >= startThisWeek
        )
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    );
    setdatathursday(
      props.data
        .filter(
          (item) =>
            item.dayofweekstart === "jueves" &&
            new Date(item.startDate) >= startThisWeek
        )
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    );
    setdatafriday(
      props.data
        .filter(
          (item) =>
            item.dayofweekstart === "viernes" &&
            new Date(item.startDate) >= startThisWeek
        )
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    );
    setdatasaturday(
      props.data
        .filter(
          (item) =>
            item.dayofweekstart === "sábado" &&
            new Date(item.startDate) >= startThisWeek
        )
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    );
    setdatasunday(
      props.data
        .filter(
          (item) =>
            item.dayofweekstart === "domingo" &&
            new Date(item.startDate) >= startThisWeek
        )
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <div className="weekcalendarcontainer">
      {arrayWeek.map((week) => (
        <div className="dayweekcontainer">
          <div className="dayweeklabelcard">
            <div className="dayweeklabel">
              <h5>{week.name}</h5>
            </div>
            <div className="dayweekcard">
              {week.data.map((item, id) => (
                <div className="p-2" key={id}>
                  {item.showpopover === true ? (
                    <OverlayTrigger
                      trigger="click"
                      placement="auto"
                      rootClose
                      overlay={popover}
                    >
                      <aside>
                        <CardWeekEvent item={item} ipInfo={props.ipInfo} />
                      </aside>
                    </OverlayTrigger>
                  ) : (
                    <aside onClick={() => enterMeeting(item)}>
                      <CardWeekEvent item={item} ipInfo={props.ipInfo} />
                    </aside>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
