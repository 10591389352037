import './error.css'
import { ReactComponent as Error404 } from './svg/error-404.svg'
import { ReactComponent as ErrorServer } from './svg/error-server.svg'
import { ReactComponent as ErrorInternet } from './svg/no-conection.svg'

function ErrorApi(props) {
    //console.log(props)
    if (props.errorData) {
        return (
            <section className="error-component">
                <h2 className="title-error">🚨 ¡Error {props.errorData.status}!</h2>
                <p className="text-error message-error">{props.errorData.message}</p>
                <p className="text-error status-text-error">{props.errorData.statusText}</p>
                <p className="text-error url-error">{props.errorData.url}</p>
                <section className="section-svg-error my-4">
                    {
                        (props.errorData.status) === 404 ?
                            <Error404 />
                            :
                            (props.errorData.status) === "ERR_INTERNET_DISCONNECTED" ?
                                <ErrorInternet />
                                :
                                <ErrorServer />
                    }
                </section>
                <button type="button" className="btn btn-primary" onClick={() => window.location.reload(true)}>Actualizar</button>
            </section >
        )
    } else {
        return (
            <>
                {props.children}
            </>
        )

    }
}
export default ErrorApi