import React, { Component } from 'react';
import { Form, Spinner, Row, Col } from 'react-bootstrap';
import RegisterMainView from '../login/RegisterMainView';
import UserService from '../../services/user.service';

export default class RegisterExView extends Component{

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            empty: true,
            userSponsor: undefined
        }

        this.getDataUrl = this.getDataUrl.bind(this);
    }

    componentDidMount() {
        this.getDataUrl();
    }
    async getToken(token) {
    if (token !== undefined) {
        let response = await UserService.Registergetdatabytoken(token);
        //
        if (response !== undefined) {
                if (response.objModel !== null) {
                    this.setState({
                        loading: false,
                        empty: false,
                        userSponsor: response.objModel
                    });
                    
                    
                } 
                 else {
                    this.setState({
                        loading: false,
                        empty: true
                    });
                }
        } else {
            this.setState({
                loading: false,
                empty: true
            });
        }
    } else {
        this.setState({
        loading: false,
        noData: true,
        noDataMessage: "No pudimos obtener información.",
        });
    }
    }
    async getDataUrl() {
        let url = window.location;
        let params  = url.search;
        let listParams = params.split('=');
        // [1] is idsocio
        if (listParams[1]!==undefined){
            if (listParams.length === 2) {
                let token = listParams[1];
                this.getToken(token);
                
            } 
            else {
                this.setState({
                loading: false,
                empty: true
                });
            }
        }
        else {
            this.setState({
            loading: false,
            empty: true
            });
        }
    
    }

    render() {
        const { loading, empty, userSponsor } = this.state;

        return(
            <div className="register-form">
                {loading && 
                    <div style={{textAlign: 'center'}}>
                        <Spinner animation="border" variant="dark">
                        </Spinner>
                        <p>Obteniendo información ...</p>
                    </div>
                }
                {!loading && empty && 
                    <div style={{textAlign: 'center'}}>
                        <Form.Label>Ocurrió un problema al obtener información de patrocinador</Form.Label>
                    </div>
                }
                {!loading && !empty && userSponsor !== undefined &&
                    <div>
                        <Row>
                            <Col sm={12}>
                                <h3>Bienvenido a InClub</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Label>Has sido invitado por {userSponsor.name} {userSponsor.lastname}</Form.Label>
                            </Col>
                        </Row>
                        <hr></hr>
                        <RegisterMainView idSocio={userSponsor.id} reload={false} showWallet={false}></RegisterMainView>
                    </div>
                    
                }
            </div>
        )
    }
}