import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

function ProfileDni(props) {
  const [dniImgs, setDniImgs] = useState([]);
  const refInputImgFrontal = useRef();
  const refInputImgPosterior = useRef();

  const imageToBase64 = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        console.log(reader.result);
        resolve(reader.result.split("base64,")[1]);
      };
    });
  };

  const getImgsFetch = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_APP_URL_API
      }/user/documentidentity/${window.localStorage.getItem("idUser")}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const resp = await response.json();
    console.log(resp);
    const filterFilePath = resp.objModel
      .sort((a, b) => new Date(a.dateModified) - new Date(b.dateModified))
      .slice(resp.objModel.length - 2, resp.objModel.length);
    console.log(filterFilePath);
    setDniImgs(filterFilePath);
  };

  const postImgsFetch = async () => {
    if (!refInputImgFrontal.current.files[0]) {
      alert("Selecciona una foto de la cara frontal");
    } else if (!refInputImgPosterior.current.files[0]) {
      alert("Selecciona una foto de la cara posterior");
    } else {
      try {
        const dataJsonPostFrontal = {
          UserId: JSON.parse(window.sessionStorage.getItem("info")).id,
          username: `${JSON.parse(window.sessionStorage.getItem("info")).username
            }_1`,
          Base64: await imageToBase64(refInputImgFrontal.current.files[0]),
        };
        const dataJsonPostPosterior = {
          UserId: JSON.parse(window.sessionStorage.getItem("info")).id,
          username: `${JSON.parse(window.sessionStorage.getItem("info")).username
            }_2`,
          Base64: await imageToBase64(refInputImgPosterior.current.files[0]),
        };

        const responseFrontal = await fetch(
          `${process.env.REACT_APP_APP_URL_API}/user/documentidentity`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataJsonPostFrontal),
          }
        );
        const respFrontal = await responseFrontal.json();
        const responsePosterior = await fetch(
          `${process.env.REACT_APP_APP_URL_API}/user/documentidentity`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataJsonPostPosterior),
          }
        );
        const respPosterior = await responsePosterior.json();
        alert("se subio correctamente");
        getImgsFetch();
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    }
  };

  useEffect(() => {
    getImgsFetch();
  }, []);

  return (
    <section className="dni-section tw-mb-20">
      <Form.Group>
        <h4>Documento de identidad</h4>
      </Form.Group>

      {dniImgs[1]?.base64 ? (
        <p className="tw-text-center tw-text-blue-500 tw-text-base">
          Ya subiste tu dni
        </p>
      ) : (
        <p className="tw-text-center tw-text-red-500 tw-text-base">
          ¡Aún no has subido tu DNI!
        </p>
      )}

      <section className="tw-flex tw-flex-col tw-justify-between tw-gap-6 md:tw-flex-row md:tw-gap-0">
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
          <span className="tw-text-sm">Cara frontal</span>
          <img
            className="tw-w-40 tw-h-24"
            src={dniImgs[0]?.base64 ?
              `data:image/jpeg;base64,${dniImgs[0]?.base64}` :
              "https://imgmedia.libero.pe/670x396/libero/original/2021/12/07/61af73f0dd38ec6a5526d811.webp"
            }
            alt={dniImgs[0]?.filePath}
          />
          <input
            ref={refInputImgFrontal}
            class="form-control"
            type="file"
          ></input>
        </div>

        <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
          <span className="tw-text-sm">Cara posterior</span>
          <img
            className="tw-w-40 tw-h-24"
            src={
              dniImgs[1]?.base64?
              `data:image/jpeg;base64,${dniImgs[1]?.base64}` :
              "https://cloudfront-eu-central-1.images.arcpublishing.com/prisaradio/EL4YFVVQIVNTVKEXOMOXYJLM6Y.jpg"
            }
            alt={dniImgs[1]?.filePath}
          />
          <input
            ref={refInputImgPosterior}
            class="form-control"
            type="file"
          ></input>
        </div>
      </section>

      <div className="tw-flex tw-flex-row tw-justify-center tw-pt-4">
        {
          /*!dniImgs[1]?.base64 && (*/
          <button
            type="button"
            className="btn btn-primary"
            onClick={postImgsFetch}
          >
            Subir DNI
          </button>
          /*)*/
        }
      </div>
    </section>
  );
}

export default ProfileDni;
