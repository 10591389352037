import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Image,
  Modal,
  Table,
  Alert,
  Container,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { RiDeleteBinLine } from "react-icons/ri";

import PayPalButton from "../payment/PaypalButton";
import PayVoucher from "../payment/PayVoucher";
// import { PayPalButton } from 'react-paypal-button-v2'
import logo from "../../images/paypal-logo.png";
import logoBcp from "../../images/bcp-logo.jpg";
import logoInter from "../../images/interbank-logo.png";
import walletImge from "../../images/wallet.png";
import logoOther from "../../images/other-logo.png";

import BanColombia from "../../images/colombia/bancolombia.jpg";
import DaVivienda from "../../images/colombia/davivienda.jpg";
import logoPagoEfecivo from "../../images/pagoefectivo-logo.png";

import UtilService from "../../services/utils.service";
import PayVoucherInter from "../payment/PayVoucherInter";
import PayWallet from "../payment/PayWallet";
import PayOther from "../payment/PayOther";
import PayPagoEfectivo from "../payment/PayPagoEfectivo";
import PayVoucherBanCol from "components/payment/PayVoucherBanCol";
import PayVoucherDaVivienda from "components/payment/PayVoucherDaVivienda";

const RANGE_WALLET_MIN = 3;
const RANGE_WALLET_MAX = 11;
/**
 * Class
 */
export default class Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idPackage: 0,
      total: "0",
      totalBuy: "0",
      initial: "0",
      initialToPay: 0,
      numInitial: 1,
      totalInitial: 1,
      totalCuotas: 0,
      discount: 0,
      kitInit: [
        {
          price: 0,
          name: "Kit de inicio",
        },
      ],
      packages: [],
      name: "",
      description: "",
      price: "",
      quotes: 0,
      perQuotes: 0,
      countQuotes: 0,
      initialPrice: "",
      duration: "",
      message: "",
      codeMessage: "",
      isOkQuote: true,
      showPayPal: false,
      showVoucher: false,
      isPaid: false,
      mount: 0,
      typePaidMethod: 0,
      method: "",
      bank: "",
      vouchers: [],
      typePay: 0,
      typeBank: {},
      typeExchange: {
        venta: 1,
      },
      showWallet: false,
      amountWallet: 0,
      idUser: 0,
      showWalletSection: props.showWallet,
      assignedUser: "",
      assignedResult: "",
      showOther: false,
      showModalPagoEfectivo: false,
      checkpalcontado: false,
      valcuotas: 0,
      arraynuminitial: [],
      paisSeleccionado: this.props.paisSeleccionado,
    };

    this.getTypeBank = this.getTypeBank.bind(this);
  }

  //--- typePaidMethod
  // 1 -> paypal, 2 --> vouchers , 3 ->after pay(send email)
  //--- voucher body
  // type
  // bank
  // code operation
  // titular cuenta
  // Comision
  // voucher64
  // name

  componentDidUpdate(props) {
    if (this.props.packages[0] !== props.packages[0]) {
      // From register step 3: select suscription
      this.setState({ packages: this.props.packages });
      let item = this.props.packages;
      let kit = this.props.kitInit;
      let total = 0;
      if (kit.length === 0) {
        Object.assign(kit, [{ price: 0, name: "Kit de inicio" }]);
      }

      this.setState({
        packages: item,
        kitInit: kit,
      });

      let initial = Number(item[0].listPackageDetails[0].initialPrice);
      let priceAll = Number(item[0].listPackageDetails[0].price);
      let quotesAll = priceAll - initial;
      let quotes = Number(item[0].listPackageDetails[0].numberQuotas);
      let perQuotes = priceAll > 0 && quotes > 0 ? quotesAll / quotes : 0;
      let initialToPay = initial;
      let totalInitial = 1;
      let arraynuminitial = [];

      console.log('ver que pasa aqui',priceAll, initial, quotesAll, quotes, perQuotes);

      for (
        let i = 0;
        i < item[0].listPackageDetails[0].numberInitialQuote;
        i++
      ) {
        arraynuminitial[i] = Object.assign({}, { value: i + 1, label: i + 1 });
      }
      total = kit[0].price + initialToPay + perQuotes * 0 - this.state.discount;
      this.setState({
        idPackage: item[0].id,
        name: item[0].name,
        description: item[0].description,
        price: item[0].listPackageDetails[0].price,
        initialPrice: item[0].listPackageDetails[0].initialPrice,
        duration: item[0].listPackageDetails[0].duration,
        initial: initial,
        numInitial: item[0].listPackageDetails[0].numberInitialQuote,
        totalInitial: totalInitial,
        initialToPay: initialToPay,
        totalCuotas: perQuotes * 0,
        perQuotes: perQuotes,
        quotes: item[0].listPackageDetails[0].numberQuotas,
        isLoaded: true,
        code: "",
        checkpalcontado: false,
        total: total,
        totalBuy: total,
        isOkQuote: true,
        countQuotes: 0,
        arraynuminitial: arraynuminitial,
      });

      if (document.getElementById("txtQuotes")) {
        document.getElementById("txtQuotes").value = 0;
      }
      this.props.eventPay(0, "quotesPay");
      this.props.eventPay(total, "amountPay");
      this.props.eventPay(false, "ispalcontado");
    }
    if (this.props.flagresetamountwallet !== props.flagresetamountwallet) {
      if (this.props.flagresetamountwallet === true) {
        this.setState({ amountWallet: 0 });
      }
    }
  }

  componentDidMount() {
    this.setState({ idUser: sessionStorage.getItem("id") });
    this.getTypeBank();
    this.getExchange();
  }

  // Get exchange dolar
  async getExchange() {
    let response = await UtilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: response.objModel,
        });
      }
    }
  }
  /**
   * Method to get packages information
   */
  async getPackages(id) {
    if (this.state.idPackage !== id) {
      let item = await UtilService.getPackageById(id);

      if (item !== undefined) {
        let initial = Number(item.initialPrice);
        let priceAll = Number(item.price);
        let quotesAll = priceAll - initial;
        let quotes = Number(item.quotes);
        let perQuotes = quotesAll / quotes;
        let temp = initial + perQuotes;

        this.setState({
          idPackage: id,
          name: item.name,
          description: item.description,
          price: item.price,
          initialPrice: item.initialPrice,
          duration: item.duration,
          total: temp,
          totalBuy: temp,
          initial: initial,
          totalCuotas: perQuotes,
          perQuotes: perQuotes,
          quotes: item.quotes,
          isLoaded: true,
          code: "",
        });
      }

      // this.forceUpdate();
    }
  }

  async getTypeBank() {
    let response = await UtilService.getTypeBank();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeBank: (this.state.typeBank = response.objModel),
        });
      }
    }
  }

  onEventCode = (e) => {
    e.preventDefault();
    if (this.state.code.length > 0) {
      this.validateCode(this.state.code);
    }
  };

  async validateCode(code) {
    let codePromotion = await UtilService.verifyCode(code);

    if (codePromotion !== undefined && codePromotion.status > 0) {
      this.setState({
        codeMessage: "",
        discount: 1,
      });
    } else {
      this.setState({
        codeMessage: "El código no es válido o ya expiró.",
        discount: 0,
      });
    }
  }

  handleChange = (e) => {
    let value = Number(e.target.value);
    if (value >= 0 && value <= this.state.quotes) {
      let kitCost;
      console.log(this.state.kitInit);
      if (this.state.kitInit[0].price > 0) {
        kitCost = this.state.kitInit.price;
      } else {
        kitCost = 0;
      }
      let totalQuote = Number((this.state.perQuotes * value).toFixed(2));
      let total = Number(
        (
          this.state.initial +
          totalQuote -
          this.state.discount +
          kitCost
        ).toFixed(2)
      );
      console.log(
        total,
        this.state.initial,
        totalQuote,
        this.state.discount,
        kitCost
      );
      this.setState({
        total: total,
        totalBuy: total,
        totalCuotas: totalQuote,
        isOkQuote: true,
        countQuotes: value,
      });

      if (this.props.eventPay) {
        this.props.eventPay(value, "quotesPay");
        this.props.eventPay(total, "amountPay");
        if (value < this.state.quotes) {
          this.props.eventPay(false, "ispalcontado");
        } else {
          this.props.eventPay(true, "ispalcontado");
        }
      }
    } else {
      let totalQuote = this.state.perQuotes * 0;
      let total = this.state.initial + totalQuote - this.state.discount;
      //sessionStorage.setItem('amountPay',total )
      this.setState({
        total: total,
        totalBuy: total,
        totalCuotas: totalQuote,
        isOkQuote: false,
        countQuotes: 0,
      });

      if (this.props.eventPay) {
        this.props.eventPay(0, "quotesPay");
        this.props.eventPay(total, "amountPay");
      }

      // var el = document.getElementById("txtQuotes");
      // el.value = "0";
    }
  };

  handleChangeInitial = (e) => {
    let value = Number(e.target.value);
    let valuequota = Number(0);
    let kitCost = this.state.kitInit[0].price;
    let totalQuote = this.state.perQuotes * valuequota;
    let total =
      this.state.initial / value + totalQuote - this.state.discount + kitCost;
    let initialToPay = this.state.initial / value;

    this.setState({
      total: total,
      totalBuy: total,
      totalCuotas: totalQuote,
      isOkQuote: true,
      countQuotes: valuequota,
      totalInitial: value,
      initialToPay: initialToPay,
      valcuotas: 0,
    });

    if (document.getElementById("txtQuotes")) {
      document.getElementById("txtQuotes").value = 0;
    }
    if (this.props.eventPay) {
      this.props.eventPay(valuequota, "quotesPay");
      this.props.eventPay(value, "totalInitial");
      this.props.eventPay(total, "amountPay"); //
    }
  };

  /**
   * Event paypal
   */
  eventPay = (value, field) => {
    // idMethodPayment: 0,
    // mountPaid : 0,
    // isPaid: false

    this.setState({ [field]: value });
    if (this.props.eventPay) {
      this.props.eventPay(value, field);
    }
  };

  /**
   * Connect with paypal
   * @param {*} e
   */
  onEventPayPal = (e) => {
    // Verify  if the form in completed
    if (this.props.validateStePaypal() === true) {
      if (this.state.idPackage > 0) {
        if (this.state.isPaid) {
          alert("Su pago con PayPal ha registrado exitosamente.");
        } else {
          if (this.state.vouchers.length > 0) {
            alert("Usted ha subido comprobantes de pago.");
          } else {
            this.handleShow();
          }
        }
      } else {
        alert("Debes seleccionar un paquete de suscripción.");
      }
    } else {
      alert("Para pagar con PAYPAL es necesario que complete el formulario.");
    }
  };

  // Handle modal
  handleClose = () => {
    this.setState({
      showPayPal: false,
    });
    this.forceUpdate();
  };
  handleShow = () => {
    this.setState({
      showPayPal: true,
    });
  };

  handleRadio = (e, id) => {
    this.setState({
      typePay: (this.state.typePay = id),
    });
    // Send type selected vouchers - paypal or after
    this.props.eventPay(id, "typePay");
  };

  // Mail CC
  handleChangeText = (e, field) => {
    let value = e.target.value;
    this.props.eventText(value, field);
  };

  // Add list of vouchers
  addVoucher = (voucherList) => {
    let vouchers = this.state.vouchers;
    vouchers = vouchers.concat(voucherList);
    this.setState({
      vouchers: (this.state.vouchers = vouchers),
    });
    this.props.eventPay(vouchers, "vouchers");
    //sessionStorage.setItem('amountPay',this.state.total)
  };

  deleteVoucher = (e, idx) => {
    let temp = [];
    let vouchers = this.state.vouchers;
    let i = 0;
    for (i = 0; i < vouchers.length; i++) {
      if (i !== idx) {
        temp.push(vouchers[i]);
      }
    }
    this.setState({
      vouchers: temp,
    });
    this.props.eventPay(temp, "vouchers");
    //sessionStorage.setItem('amountPay',this.state.total)
  };

  // Change of voucher
  onchangePayVoucher = (value, field) => {
    this.setState({ [field]: value });
    if (this.props.onchangePayVoucher) {
      this.props.onchangePayVoucher(value, field);
    }
  };

  // handleSave = (e) => {
  //     if(Number(this.state.type) > 0 && this.state.voucher.length > 0){
  //         this.setState({isPaid: true});
  //         if(this.props.onchangePayVoucher){
  //             this.props.onchangePayVoucher('bcp', 'bank');
  //             this.props.onchangePayVoucher(true, 'isPaid');
  //         }
  //         this.handleCloseVoucher();
  //     } else {

  //     }

  // }
  // Handle modal voucher
  handleShowVoucher = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        if (bank === "BCP") {
          this.setState({
            showVoucher: true,
            bank: "BCP",
            isBcp: true,
            isInter: false,
            isDavi: false,
            isBancol: false,
          });
        } else if (bank === "Interbank") {
          this.setState({
            showVoucher: true,
            bank: "Interbank",
            isBcp: false,
            isInter: true,
            isDavi: false,
            isBancol: false,
          });
        } else if (bank === "Davivienda") {
          this.setState({
            showVoucher: true,
            bank: "Davivienda",
            isBcp: false,
            isInter: false,
            isDavi: true,
            isBancol: false,
          });
        } else if (bank === "Bancolombia") {
          this.setState({
            showVoucher: true,
            bank: "Bancolombia",
            isBcp: false,
            isInter: false,
            isDavi: false,
            isBancol: true,
          });
        }
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };

  handleCloseVoucher = () => {
    this.setState({
      showVoucher: false,
    });
  };

  // Handle show modal wallet
  handleShowWallet = (e) => {
    this.setState({
      showWallet: this.props.showWallet,
    });

    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showWallet: true,
        });
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };

  handleCloseWallet = () => {
    this.setState({
      showWallet: false,
    });
  };

  // Register Payment only with wallet
  registerPayWallet = (amount) => {
    if (this.props.eventWallet) {
      this.props.eventWallet(amount);
    }

    //Verify if all fields are completed
    // Verify  if the form in completed
    if (this.props.validateStePaypal() === true) {
      if (this.props.eventRegister) {
        this.props.eventRegister();
      }
    } else {
      alert("Para pagar con WALLET es necesario que complete el formulario.");
    }
  };

  eventWallet = (amount) => {
    // Register wallet
    this.setState({
      amountWallet: (this.state.amountWallet = amount),
    });
    if (this.props.eventWallet) {
      this.props.eventWallet(amount);
    }
  };

  // validate user to assign payment
  validateUser = async (e) => {
    if (this.state.assignedUser.length > 0) {
      this.setState({
        assignedLoading: true,
      });
      let response = await UtilService.getRangeByUsername(
        this.state.assignedUser
      );
      if (response !== undefined && response.status === 1) {
        // verify is > oro
        if (response.objModel.position >= RANGE_WALLET_MIN) {
          this.setState({
            assignedResult: "Rango: " + response.objModel.name,
            assignedLoading: false,
          });
        } else {
          this.setState({
            assignedResult:
              "Rango: " + response.objModel.name + " - No permitido.",
            assignedLoading: false,
          });
        }
      } else {
        this.setState({
          assignedResult: "Usuario no existe.",
          assignedLoading: false,
        });
      }
    } else {
      alert("Ingrese usuario");
      this.setState({
        assignedLoading: false,
      });
    }
  };

  // Payment other
  handleShowOther = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showOther: true,
          bank: "Otros",
          isOther: true,
          isBcp: false,
          isInter: false,
        });
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };

  handleCloseOther = () => {
    this.setState({
      showOther: false,
    });
  };

  /****************
   * Handle pago efectivo
   */
  onEventPagoEfectivo = (e) => {
    // Verify  if the form in completed
    // if (this.props.validateStePaypal() === true){
    if (true) {
      if (this.state.idPackage > 0) {
        this.handleShowPagoEfectivo();
      } else {
        alert("Debes seleccionar un paquete de suscripción.");
      }
    } else {
      alert(
        "Para pagar con Pago Efectivo es necesario que complete el formulario."
      );
    }
  };
  handleShowPagoEfectivo = () => {
    this.setState({
      showModalPagoEfectivo: true,
    });
  };

  handleClosePagoEfectivo = () => {
    this.setState({
      showModalPagoEfectivo: false,
    });
  };

  handleckclicked = (e, field) => {
    this.setState({
      [field]: !this.state[field],
    });

    if (field === true) {
      let value = Number(this.state.quotes);
      if (value >= 0 && value <= this.state.quotes) {
        let kitCost;
        if (this.state.kitInit) {
          kitCost = this.state.kitInit.price ? this.state.kitInit.price : 0;
        } else {
          kitCost = 0;
        }
        let totalQuote = this.state.perQuotes * value;
        let total =
          this.state.initial + totalQuote - this.state.discount + kitCost;

        this.setState({
          total: (this.state.total = total),
          totalBuy: ( this.state.totalBuy = total),
          totalCuotas: (this.state.totalCuotas = totalQuote),
          isOkQuote: (this.state.isOkQuote = true),
          countQuotes: (this.state.countQuotes = value),
          valcuotas: (this.state.valcuotas = value),
        });

        if (this.props.eventPay) {
          this.props.eventPay(value, "quotesPay");
          this.props.eventPay(total, "amountPay");
          this.props.eventPay(this.state[field], "ispalcontado");
        }
      }
    } else {
      let value = Number(0);
      let kitCost;
      if (this.state.kitInit) {
        kitCost = this.state.kitInit.price ? this.state.kitInit.price : 0;
      } else {
        kitCost = 0;
      }

      let totalQuote = this.state.perQuotes * value;
      let total =
        this.state.initial + totalQuote - this.state.discount + kitCost;
      //sessionStorage.setItem('amountPay',total )

      this.setState({
        total: (this.state.total = total),
        totalBuy: ( this.state.totalBuy = total),
        totalCuotas: (this.state.totalCuotas = totalQuote),
        isOkQuote: (this.state.isOkQuote = true),
        countQuotes: (this.state.countQuotes = value),
        valcuotas: (this.state.valcuotas = value),
      });

      if (this.props.eventPay) {
        this.props.eventPay(value, "quotesPay");
        this.props.eventPay(total, "amountPay");
        this.props.eventPay(this.state[field], "ispalcontado");
      }
    }
  };
  sumartotalvoucher = () => {
    let vouchers = this.state.vouchers;
    let totalamount = Number(0);
    let cambio = Number(this.state.typeExchange.venta);
    let cantidad = 0;
    let moneda = "";

    for (let i = 0; i < vouchers.length; i++) {
      // Split base64 code type and string
      moneda = vouchers[i].typeExchangeDes;
      cantidad = Number(vouchers[i].amount);
      if (moneda == "Dólares") {
        //dolar
        totalamount += cantidad;
      } else {
        //moneda=2 sol
        //convertir sol a dolar
        cantidad /= cambio;
        totalamount += cantidad;
      }
    }
    return totalamount.toFixed(2);
  };
  render() {
    const {
      vouchers,
      typePay,
      typeExchange,
      isPaid,
      idUser,
      amountWallet,
      checkpalcontado,
      valcuotas,
      totalInitial,
      quotes,
      numInitial,
    } = this.state;
    const nacionalidadUsuario = sessionStorage.getItem("info")
      ? JSON.parse(sessionStorage.getItem("info")).idResidenceCountry
      : "";

    const idPais = localStorage.getItem("idPais")
      ? localStorage.getItem("idPais")
      : null;
  //  console.log("haber idPais", idPais);
   // console.log("ver nacionalidadUsuario MediosDePago =>", nacionalidadUsuario);
    return (
      <div>
        <Form.Label className="content-subtitle">Métodos de pagos</Form.Label>

        <div>
          <Form.Group className="mb-3">
            <Row>
              <Col sm={12}>
                <Form.Label>Paquete seleccionado</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Table responsive>
                  <thead>
                    <tr>
                      <td>
                        <Form.Label>Descripción</Form.Label>
                      </td>
                      <td>
                        <Form.Label>Precio Total</Form.Label>
                      </td>
                      <td>
                        <Form.Label>Inicial</Form.Label>
                      </td>
                      <td>
                        <Form.Label>Nro. de cuotas</Form.Label>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.kitInit !== undefined && (
                      <tr>
                        <td>
                          <Form.Text>{this.state.kitInit[0].name} </Form.Text>
                        </td>
                        <td>
                          <Form.Text>$ {this.state.kitInit[0].price}</Form.Text>
                        </td>
                        <td>
                          <Form.Text>0</Form.Text>
                        </td>
                        <td>
                          <Form.Text>0</Form.Text>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <Form.Text>{this.state.description} </Form.Text>
                        <Form.Text>Duración: {this.state.duration} </Form.Text>
                      </td>
                      <td>
                        <Form.Text>$ {this.state.price}</Form.Text>
                      </td>
                      <td>
                        <Form.Text>$ {this.state.initialPrice}</Form.Text>
                      </td>
                      <td>
                        <Form.Text>{this.state.quotes}</Form.Text>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Form.Group>
          {numInitial > 1 && (
            <Form.Group className="mb-3">
              {checkpalcontado === false ? (
                <Form.Group as={Row} className="aligns">
                  <Form.Label
                    className="sizetext"
                    column
                    xs={9}
                    sm={4}
                    md={3}
                    lg={2}
                  >
                    Fraccionar inicial en (max.{numInitial}):
                  </Form.Label>
                  <Col xs={3} sm={2} md={2} lg={1}>
                    <Form.Select
                      size="sm"
                      value={totalInitial}
                      onChange={(e) => this.handleChangeInitial(e)}
                    >
                      {this.state.arraynuminitial.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {item.value}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              ) : (
                <Form.Label className="sizetext">
                  Fraccionar inicial en (max. {numInitial}) : &nbsp;{" "}
                  <small>{totalInitial}</small>
                </Form.Label>
              )}
            </Form.Group>
          )}

          <div className="justify-content-between">
            <Row>
              <Col sm={6}></Col>
              <Col sm={6}>
                <Row>
                  <Col>
                    <Form.Label>Detalle</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="8">
                    <Form inline>
                      <Form.Text style={{ fontSize: "14px" }}>
                        Kit de inicio : &nbsp;{" "}
                      </Form.Text>
                    </Form>
                  </Col>

                  <Col sm="4" style={{ textAlign: "right" }}>
                    <Form.Text style={{ fontSize: "14px" }}>
                      {this.state.kitInit ? this.state.kitInit.price : "$0"}
                    </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs="8" sm="8" md="6" lg="8">
                    <Form inline>
                      <Form.Text style={{ fontSize: "14px" }}>
                        Inicial : &nbsp;{" "}
                      </Form.Text>
                    </Form>
                  </Col>

                  <Col
                    xs="4"
                    sm="4"
                    md="6"
                    lg="4"
                    style={{ textAlign: "right" }}
                  >
                    <Form.Text style={{ fontSize: "14px" }}>
                      $ {this.state.initialToPay}
                    </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs="9" sm="10" md="8" lg="8">
                    <Form.Text className="sizetext">
                      Total de cuotas a pagar : &nbsp;{" "}
                      {totalInitial == 1 && checkpalcontado == false ? (
                        <Form.Control
                          id="txtQuotes"
                          size="sm"
                          type="number"
                          min="0"
                          max={quotes}
                          defaultValue="0"
                          onChange={(e) => this.handleChange(e)}
                        ></Form.Control>
                      ) : (
                        <Form.Text className="sizetext">{valcuotas}</Form.Text>
                      )}
                    </Form.Text>
                  </Col>
                  <Col
                    xs="3"
                    sm="2"
                    md="4"
                    lg="4"
                    style={{ textAlign: "right" }}
                  >
                    <Form.Text style={{ fontSize: "14px" }} id="totalCuota">
                      $ {this.state.totalCuotas}
                    </Form.Text>
                  </Col>
                </Row>
                <Row
                  style={{ display: this.state.isOkQuote ? "none" : "block" }}
                >
                  <Col>
                    <Form.Text className="textAlert">
                      El número máximo de cuotas a pagar es :{" "}
                      {this.state.quotes}
                    </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs="8" sm="8" md="6" lg="8">
                    <Form.Text style={{ fontSize: "14px" }}>
                      Descuento : &nbsp;{" "}
                    </Form.Text>
                  </Col>
                  <Col
                    xs="4"
                    sm="4"
                    md="6"
                    lg="4"
                    style={{ textAlign: "right" }}
                  >
                    <Form.Text style={{ fontSize: "14px" }} id="idDiscount">
                      $ {this.state.discount}
                    </Form.Text>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col xs="8" sm="8" md="6" lg="8">
                    <Form.Label>Total: </Form.Label>
                  </Col>

                  <Col
                    xs="4"
                    sm="4"
                    md="6"
                    lg="4"
                    style={{ textAlign: "right" }}
                  >
                    <Form.Label id="total">$ {this.state.totalBuy}</Form.Label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <Form.Group>
            <Row>
              <Col>
                <Form.Check
                  type="radio"
                  id="1"
                  name="typePay"
                  onChange={(e) => {
                    this.handleRadio(e, 1);
                  }}
                  label="Seleccione un medio de pago."
                />
              </Col>
            </Row>
            {/* Tipos de pago */}
            {/*  AQUI SON LAS IMAGENES DE MEDIO DE PAGO */}

            {(() => {
              switch (idPais) {
                case "45":
                  return (
                    <Row
                      style={
                        typePay === 1
                          ? { padding: 16 }
                          : { padding: 16, pointerEvents: "none", opacity: 0.5 }
                      }
                    >
                      <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={DaVivienda}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowVoucher(e, "Davivienda");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>
                      {this.state.showWalletSection && (
                        <Col sm={2}>
                          <div
                            onClick={(e) => {
                              this.handleShowWallet(e);
                            }}
                          >
                            <p style={{ margin: 2, fontWeight: "bold" }}>
                              <Image
                                src={walletImge}
                                style={{ width: "32px", marginRight: "10px" }}
                              ></Image>
                              Mi Wallet
                            </p>
                          </div>
                        </Col>
                      )}
                    </Row>
                  );
                case "167":
                  return (
                    <Row
                      style={
                        typePay === 1
                          ? { padding: 16 }
                          : { padding: 16, pointerEvents: "none", opacity: 0.5 }
                      }
                    >
                      <>
                        <Col sm={2}>
                          <Image
                            src={logo}
                            rounded
                            style={{
                              width: "100%",
                              maxWidth: 136,
                              cursor: "pointer",
                            }}
                            onClick={this.onEventPayPal}
                          ></Image>
                        </Col>
                        <Col sm={2}>
                          <Image
                            src={logoBcp}
                            rounded
                            style={{
                              width: "100%",
                              maxWidth: 136,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              this.handleShowVoucher(e, "BCP");
                            }}
                          ></Image>
                        </Col>
                        <Col sm={2}>
                          <Image
                            src={logoInter}
                            rounded
                            style={{
                              width: "120%",
                              maxWidth: 136,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              this.handleShowVoucher(e, "Interbank");
                            }}
                          ></Image>
                        </Col>
                        <Col sm={2}>
                          <Image
                            src={logoOther}
                            rounded
                            style={{
                              width: "120%",
                              maxWidth: 136,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              this.handleShowOther(e, "Otros");
                            }}
                          ></Image>
                        </Col>

                        {this.state.showWalletSection && (
                          <Col sm={2}>
                            <div
                              onClick={(e) => {
                                this.handleShowWallet(e);
                              }}
                            >
                              <p style={{ margin: 2, fontWeight: "bold" }}>
                                <Image
                                  src={walletImge}
                                  style={{ width: "32px", marginRight: "10px" }}
                                ></Image>
                                Mi Wallet
                              </p>
                            </div>
                          </Col>
                        )}
                      </>
                    </Row>
                  );
                default:
                  return (
                    <Row
                      style={
                        typePay === 1
                          ? { padding: 16 }
                          : { padding: 16, pointerEvents: "none", opacity: 0.5 }
                      }
                    >
                      <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>
                      </Col>

                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>

                      {this.state.showWalletSection && (
                        <Col sm={2}>
                          <div
                            onClick={(e) => {
                              this.handleShowWallet(e);
                            }}
                          >
                            <p style={{ margin: 2, fontWeight: "bold" }}>
                              <Image
                                src={walletImge}
                                style={{ width: "32px", marginRight: "10px" }}
                              ></Image>
                              Mi Wallet
                            </p>
                          </div>
                        </Col>
                      )}
                    </Row>
                  );
              }
            })()}
                  {/*   <Row
                      style={
                        typePay === 1
                          ? { padding: 16 }
                          : { padding: 16, pointerEvents: "none", opacity: 0.5 }
                      }
                    >
                      <>
                        <Col sm={2}>
                          <Image
                            src={logo}
                            rounded
                            style={{
                              width: "100%",
                              maxWidth: 136,
                              cursor: "pointer",
                            }}
                            onClick={this.onEventPayPal}
                          ></Image>
                        </Col>
                        <Col sm={2}>
                          <Image
                            src={logoBcp}
                            rounded
                            style={{
                              width: "100%",
                              maxWidth: 136,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              this.handleShowVoucher(e, "BCP");
                            }}
                          ></Image>
                        </Col>
                        <Col sm={2}>
                          <Image
                            src={logoInter}
                            rounded
                            style={{
                              width: "120%",
                              maxWidth: 136,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              this.handleShowVoucher(e, "Interbank");
                            }}
                          ></Image>
                        </Col>
                        <Col sm={2}>
                          <Image
                            src={logoOther}
                            rounded
                            style={{
                              width: "120%",
                              maxWidth: 136,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              this.handleShowOther(e, "Otros");
                            }}
                          ></Image>
                        </Col>

                        {this.state.showWalletSection && (
                          <Col sm={2}>
                            <div
                              onClick={(e) => {
                                this.handleShowWallet(e);
                              }}
                            >
                              <p style={{ margin: 2, fontWeight: "bold" }}>
                                <Image
                                  src={walletImge}
                                  style={{ width: "32px", marginRight: "10px" }}
                                ></Image>
                                Mi Wallet
                              </p>
                            </div>
                          </Col>
                        )}
                      </>
                    </Row> */}
            <Row
              style={
                typePay === 1
                  ? { padding: 16 }
                  : { padding: 16, pointerEvents: "none", opacity: 0.5 }
              }
            >
              <Col sm={12}>
                <Form.Label>
                  (*) Puede adjuntar comprobantes de pago de BCP, Intebank y
                  Otros.
                </Form.Label>
              </Col>
            </Row>

            {/* show the table if has register */}
            {vouchers.length > 0 && (
              <Row style={{ padding: 16 }}>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th>Banco</th>
                      <th>Operación</th>
                      <th>Código</th>
                      <th>Nota</th>
                      <th>Archivo</th>
                      <th>Moneda</th>
                      <th>Monto</th>
                      <th>Comisión</th>
                      <th>Monto Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {vouchers.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.bank}</td>
                        <td>{item.typeDescription}</td>
                        <td>{item.code}</td>
                        <td>{item.nota}</td>
                        <td>{item.name}</td>
                        <td>{item.typeExchangeDes}</td>
                        <td>{item.amount}</td>
                        <td>{item.comisionExchange}</td>
                        <td>{item.totalPagarVoucher}</td>
                        <td
                          style={{ fontSize: 15 }}
                          onClick={(e) => {
                            this.deleteVoucher(e, idx);
                          }}
                        >
                          <RiDeleteBinLine></RiDeleteBinLine>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            )}
            {/* Show amount payed with wallet */}
            {amountWallet > 0 && (
              <Row style={{ padding: 16, paddingRight: 16, paddingBottom: 10 }}>
                <Col sm={12}>
                  <Alert variant="success">
                    <b>Monto pagado con Wallet $ : </b>
                    {amountWallet}
                  </Alert>
                </Col>
              </Row>
            )}
            {/* show the PayPal payment if it was successful */}

            {isPaid && (
              <Form.Group>
                <Form.Label>Su pago ha sido registrado con PAYPAL.</Form.Label>
              </Form.Group>
            )}
            <Row style={{ paddingBottom: 16 }}>
              <Col>
                <Form.Check
                  type="radio"
                  id="2"
                  name="typePay"
                  onChange={(e) => {
                    this.handleRadio(e, 2);
                  }}
                  label="Subir comprobante de pago despues."
                />
                <div
                  style={
                    typePay === 2
                      ? { paddingLeft: 16 }
                      : { paddingLeft: 16, opacity: 0.5 }
                  }
                >
                  <Form.Label className="textAlert">
                    Se le enviará un correo con el detalle de los medios de
                    pago.
                  </Form.Label>
                  <Col sm={6} style={{ paddingLeft: 0 }}>
                    <Form.Group>
                      <Form.Label>Correo CC:</Form.Label>
                      <Form.Control
                        placeholder="Ingreso correo"
                        onChange={(e) => this.handleChangeText(e, "emailCC")}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </div>
              </Col>
            </Row>
          </Form.Group>
          <hr></hr>
        </div>

        {/* Modal */}
        <Modal
          show={this.state.showPayPal}
          onHide={this.handleClose}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>PayPal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayPalButton
                mount={this.state.total}
                description={this.state.name}
                eventPay={this.eventPay}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
              ></PayPalButton>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal upload voucher*/}
        <Modal
          show={this.state.showVoucher}
          onHide={this.handleCloseVoucher}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de {this.state.bank}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.isBcp && (
              <Form.Group>
                <PayVoucher
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                ></PayVoucher>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
            {this.state.isDavi && (
              <Form.Group>
                <PayVoucherDaVivienda
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                ></PayVoucherDaVivienda>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}

            {this.state.isInter && (
              <Form.Group>
                <PayVoucherInter
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  fontSize={10}
                ></PayVoucherInter>
              </Form.Group>
            )}
          </Modal.Body>
        </Modal>

        {/* Modal pay with wallet*/}
        <Modal
          show={this.state.showWallet}
          onHide={this.handleCloseWallet}
          style={{ fontSize: 10 }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago con Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayWallet
                idUser={idUser}
                total={this.state.total}
                registerPayWallet={this.registerPayWallet}
                eventWallet={this.eventWallet}
                close={this.handleCloseWallet}
                fontSize={10}
              ></PayWallet>
            </Form.Group>
          </Modal.Body>
        </Modal>

        {/* Modal upload voucher other tickets*/}
        <Modal
          show={this.state.showOther}
          onHide={this.handleCloseOther}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de otros medios de pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayOther
                total={this.state.total}
                addVoucher={this.addVoucher}
                close={this.handleCloseOther}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
                fontSize={10}
              ></PayOther>
            </Form.Group>
          </Modal.Body>
        </Modal>
        {/* Modal pago efectivo*/}
        <Modal
          show={this.state.showModalPagoEfectivo}
          onHide={this.handleClosePagoEfectivo}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={logoPagoEfecivo} height={35}></img>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayPagoEfectivo
                total={this.state.total}
                addVoucher={this.addVoucher}
                close={this.handleClosePagoEfectivo}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
                fontSize={10}
              ></PayPagoEfectivo>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
