import React from "react";
import Swal from "sweetalert2";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";

const CLIENT_ID = process.env.REACT_APP_TOKEN_PAYPAL;

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false,
      error: false,
      exchange: props.exchange,
      typeExchange: 0,
      typeExchangeDes: "Dólares",
      type_code: "USD",
      idBank: 3,
      typePays: [],
      mount: props.mount,
      comision: 0,
      total: 0,
      loadingPayment: false,
      tasa: 0,
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    console.log(this.props);
    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      // this.setState({ loading: false, showButtons: true });
      console.log(PayPalButton, 'payPalButton');
    }

    if (this.props.typeBank != undefined) {
      let types = this.props.typeBank;
      types.forEach((item, idx) => {
        if (item.idTipoPago === this.state.idBank) {
          console.log('sdsd', item.subTipoPagos);
          this.setState({
            typePays: (this.state.typePays = item.subTipoPagos),
          });
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        //
      }
    }
    this.forceUpdate();
  }

  createOrder = (data, actions) => {
    if (!window.navigator.onLine) {
      Swal.fire("The Internet?", "Parece que te desconectaste.", "question");
    } else {
      if (this.state.typeExchange !== 0) {
        let objItem = {
          purchase_units: [
            {
              description: this.props.description,
              amount: {
                currency_code: this.state.type_code,
                value: this.state.total,
              },
            },
          ],
        };

        console.log('sdsd samsak', objItem);

        return actions.order.create(objItem);
      } else {
        alert("Seleccione un tipo de moneda.");
      }
    }
  };

  onApprove = (data, actions) => {
    console.log("onApprove");
    this.setState({ loadingPayment: true });
    actions.order.capture().then((details) => {
      if (details.error !== undefined) {
        this.setState({
          showButtons: false,
          paid: false,
          error: true,
          loadingPayment: false,
        });

        if (this.props.eventPay) {
          this.props.eventPay(false, "isPaid");
        }
      } else {
        const paymentData = {
          payerID: data.payerID,
          orderID: data.orderID,
          details: details,
        };
        // Get paypal idTransaccion
        let idTransaccion = details.purchase_units[0].payments.captures[0].id;

        this.setState({
          showButtons: false,
          paid: true,
          error: false,
          loadingPayment: false,
        });
        if (this.props.eventPay) {
          // if(paymentData.details.status === "COMPLETED") {
          // https://developer.paypal.com/docs/api/orders/v2/
          // CREATED. The order was created with the specified context.
          // SAVED. The order was saved and persisted. The order status continues to be in progress until a capture is made with final_capture = true for all purchase units within the order.
          // APPROVED. The customer approved the payment through the PayPal wallet or another form of guest or unbranded payment. For example, a card, bank account, or so on.
          // VOIDED. All purchase units in the order are voided.
          // COMPLETED. The payment was authorized or the authorized payment was captured for the order.
          console.log('this.state.mount', this.state.mount, 'total', this.props.total);
          this.props.eventPay(true, "isPaid");
          this.props.eventPay(this.state.mount, "mountPaid");
          this.props.eventPay(paymentData.details.create_time, "creationDate");
          this.props.eventPay(
            paymentData.details.update_time,
            "modificationDate"
          );
          // this.props.eventPay(paymentData.details.id, "idTransaction");
          this.props.eventPay(idTransaccion, "idTransaction");
          this.props.eventPay(paymentData.details.intent, "intent");
          this.props.eventPay(paymentData.details.status, "status");

          // Save paypal paymente
          if (this.props.savePaypal) {
            this.props.savePaypal();
          }

          //save from shop
          if (this.props.registerBuy) {
            this.props.eventPay(this.state.mount, "amountPaid");
            this.props.eventPay(1, "typePay");
            this.props.registerBuy();
            this.props.close();
          }
          // }
          // else {

          // }
        }
      }
    });
  };

  onError = (err) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "error",
      title: "Ocurrio un error.",
    });

    this.setState({
      showButtons: false,
      paid: false,
      error: true,
      loadingPayment: false,
    });
    if (this.props.eventPay) {
      this.props.eventPay(false, "isPaid");
    }
  };

  onCancel = (data) => {
    console.log('data cancel for paypal', data)
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "info",
      title: "Se cancelo el pago.",
    });

    this.setState({
      loadingPayment: false,
      showButtons: true,
    });
    if (this.props.eventPay) {
      this.props.eventPay(false, "isPaid");
    }
  };

  // Handle exchange
  handleExchange = (e) => {
    let value = Number(e.target.value);
    console.log('this.state.mount',this.state.mount);
    // 1 -> dolar
    // 2 -> soles
    if (value === 1) {
      let comision = this.state.typePays[0].dolares;
      // Values default monen is dolars
      let temp = this.state.typePays[0].dolares + this.state.mount;
      console.log('suma ver',  this.state.typePays[0].dolares + this.state.mount, this.state.typePays[0].dolares)
      let total = temp / (1 - this.state.typePays[0].porcentajeTasa / 100);
      total = Math.round((total + Number.EPSILON) * 100) / 100;

      let tasa = (total * this.state.typePays[0].porcentajeTasa) / 100;
      //round
      tasa = Math.round((tasa + Number.EPSILON) * 100) / 100;
      this.setState({
        typeExchange: 1,
        typeExchangeDes: "Dólares",
        type_code: "USD",
        comision: comision,
        tasa: tasa,
        total: total,
        showButtons: true,
      });
    } else if (value === 2) {
      let comision = this.state.typePays[0].soles;
      let total = this.state.mount * this.state.exchange + comision;
      this.setState({
        typeExchange: 2,
        typeExchangeDes: "Soles",
        type_code: "PEN",
        comision: comision,
        total: total,
        tasa: 0,
        showButtons: true,
      });
    }
  };

  // register event
  registerBuy = () => {
    if (this.props.registerBuy) {
      this.props.registerBuy();
    }
  };

  render() {
    const {
      showButtons,
      paid,
      error,
      typeExchange,
      tasa,
      comision,
      total,
      loadingPayment,
    } = this.state;

    return (
      <div className="main" style={{ fontSize: 12 }}>
        <Row>
          <Col sm={12}>
            <Form.Label>1.- Seleccione el tipo de maneda</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Label className="textAlert" style={{ fontSize: 10 }}>
              * Los pagos a través de este medio tienen una COMISIÓN.
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form.Label column sm={4}>
              <b>Moneda:</b>
            </Form.Label>
          </Col>
          <Col sm={6}>
            <Form.Select
              size="sm"
              style={{ fontSize: 12 }}
              defaultValue={"0"}
              onChange={(e) => this.handleExchange(e)}
            >
              <option value="0" disabled>
                Seleccione
              </option>
              <option value="1">Dólares</option>
              {/* <option value="2" >Soles</option> */}
            </Form.Select>
          </Col>
        </Row>
        {/* Dolares */}
        {typeExchange === 1 && (
          <Row style={{ paddingTop: 15 }}>
            <Col sm={3}>
              <Form.Label>
                <b>Monto $:</b> {this.state.mount}
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Label>
                <b>Tasa $ :</b> {tasa}
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Label>
                <b>Comisión $ :</b> {comision}
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Label>
                <b>TOTAL $ :</b> {total}
              </Form.Label>
            </Col>
          </Row>
        )}
        {/* Soles */}
        {typeExchange === 2 && (
          <Row style={{ paddingTop: 15 }}>
            <Col sm={3}>
              <Form.Label>
                <b>Tipo de Cambio:</b> {this.state.exchange}
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Label>
                <b>Monto S/ :</b> {this.state.mount * this.state.exchange}
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Label>
                <b>Tasa S/ :</b> {tasa}
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Label>
                <b>Comisión S/ :</b> {comision}
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Label>
                <b>TOTAL S/ :</b> {total}
              </Form.Label>
            </Col>
          </Row>
        )}
        <hr></hr>
        <Row>
          <Col sm={12}>
            <Form.Label>2.- Seleccione una opción: </Form.Label>
          </Col>
        </Row>
        {loadingPayment && (
          <div style={{ textAlign: "center" }}>
            <Spinner size="sm" animation="border" variant="dark"></Spinner>
            <Form.Label>&nbsp; Espere ...</Form.Label>
          </div>
        )}
        {showButtons && (
          <div>
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
              onError={(err) => this.onError(err)}
              onCancel={(data) => this.onCancel(data)}
            />
          </div>
        )}

        {paid && (
          <div className="main" style={{ fontSize: 14, color: "green" }}>
            Su pago ha sido registrado con éxito.
          </div>
        )}

        {error && (
          <div className="main" style={{ fontSize: 14, color: "main" }}>
            Su pago no ha sido procesado. Inténtelo más tarde.
          </div>
        )}
      </div>
    );
  }
}
// CLIENT_ID
export default scriptLoader(
  `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&enable-funding=card,paylater&disable-funding=mercadopago`
)(PaypalButton);

// export default scriptLoader(
// `https://www.paypal.com/sdk/js?client-id=AWScTOyxA48uP7Rw4n11UPT6jE5UjVvbl_SIAH3KrxdwIVdONQPkmor1m2Qvl7_Rz2bLXAogxw72ePHQ&enable-funding=card,paylater&disable-funding=mercadopago`)(PaypalButton);
