import React, { Component } from "react";
import { Form, Tabs, Tab, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Affiliate from "../management/Affiliate";
import AffiliateLevel from "../management/AffiliatteLevel";

export default class ManageActivation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <hr></hr>
        <Row>
          <Col sm={12}>
            <Form.Label className="content-subtitle">
              Gestión de Activación
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tabs className="mb-3">
              <Tab eventKey="activacion" title="Primer nivel">
                <Affiliate></Affiliate>
              </Tab>
              <Tab eventKey="levelact" title="Niveles 2 al 7">
                <AffiliateLevel></AffiliateLevel>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}
