import React, { Component } from "react";
import userService from "../../services/user.service";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./CertificateView.css";
import CertificateListTable from "./CertificateListTable";

//import CertificateRequest from './CertificateRequest';
class CertificateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loading1: true,
      loading2: true,
      count: 0,
      user: "",
      obj: {
        id: 0,
        package: {
          name: "Kit de Inicio",
          idFamilyPackage: 50,
          nameFamilyPackage: "INCLUB",
          creationDate: "",
        },
        packageDetailResponse: {
          idPackageDetail: 0,
          price: 0,
          numberQuotas: 0,
          initialPrice: 0,
          quotaPrice: 0,
          priceLetters: "",
          namePackage: "Kit de Inicio",
          nameFamilyPackage: "INCLUB",
        },
      },
      array: [],
      email: "",
      suscription: "",
      listcertificate: [],
    };
  }
  componentDidMount() {
    this.getCertificatebyUser();
    this.getUserInformation();
  }
  setcertificatepersomeusers(userdatacertificate) {
    userdatacertificate.forEach((certificate) => {
      switch (certificate.idCertificateShare) {
        case 1107:
          certificate.numberShares = 30000;
          userdatacertificate.pop();
          break;
      }
    });
    return userdatacertificate;
  }
  async getCertificatebyUser() {
    let response = await userService.getCertificatesbyUser();
    if (response !== undefined && response !== null) {
      if (response.objModel.length > 0) {
        this.setState({
          listcertificate: this.setcertificatepersomeusers(response.objModel),
          loading2: false,
        });
      } else {
        this.setState({
          listcertificate: [],
          loading2: false,
        });
      }
    } else {
      alert("Ha ocurrido un error, vuelva a intentarlo");
    }
  }
  async getsuscription() {
    let suscription = await userService.getSuscription(); //getSuscription();
    if (suscription !== undefined && suscription !== null) {
      if (suscription.status !== 1) {
        this.setState({
          suscription: [],
          loading: false,
        });
      } else {
        this.setState({
          suscription: suscription.objModel,
          loading: false,
          count: this.state.suscription.length,
        });
      }
    }
  }
  async getUserInformation() {
    let response = await userService.getUserInformation(); //UserService.getUserInformation();
    //console.log(response)
    if (response !== undefined && response.status === 1) {
      let userInfo = {
        idNationality: response.objModel.idResidenceCountry,
        name: response.objModel.name,
        lastname: response.objModel.lastname,
        birthdate: response.objModel.birthdate,
        gender: response.objModel.gender,
        nroDocument: response.objModel.nroDocument,
        districtAddress: response.objModel.districtAddress,
        address: response.objModel.address,
        username: response.objModel.username,
        civilState: response.objModel.civilState,
        email: response.objModel.email,
        phone: response.objModel.nroTelf,
        typeDoc: response.objModel.documentType.name,
        nationality: response.objModel.nationality,
        courtesy: response.objModel.courtesy,
      };

      this.setState({
        noUser: false,
        user: userInfo,
        id: userInfo.id,
        loading1: false,
      });
    } else {
      this.setState({
        noUser: true,
        user: undefined,
        id: 0,
        loading1: false,
      });
    }
  }

  render() {
    const { loading1, loading2 } = this.state;
    return (
      <section>
        {loading1 && loading2 && (
          <Spinner animation="border" variant="primary" />
        )}
        {!loading1 && !loading2 && (
          <div style={{ marginTop: "40px" }}>
            <Tabs
              className="custom-tabs-main"
              defaultActiveKey="CertificateListTable"
            >
              <Tab eventKey="CertificateListTable" title="Certificados">
                <CertificateListTable
                  listcertificate={this.state.listcertificate}
                  user={this.state.user}
                ></CertificateListTable>
              </Tab>
              {/*<Tab eventKey="CertificateRequest" title="Solicitud de certificados">
                           <CertificateRequest
                            suscription={this.state.suscription}>
                          </CertificateRequest> 
                        </Tab>*/}
            </Tabs>
          </div>
        )}
      </section>
    );
  }
}

export default CertificateView;
