import FormCalendarEvents from './FormCalendarEvents/FormCalendarEvents'
import CarouselTrips from './Carousel/CarouselTrips'

export default function Header(props) {
  return (
    <>
        <CarouselTrips API_URL={props.API_URL}/>        
        <FormCalendarEvents
            setfilter={props.setfilter}
            setidsubtype={props.setidsubtype}
            idsubtype={props.idsubtype}
            API_URL={props.API_URL}
        />
    </>
  )
}
