import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Svg,
  Rect,
} from "@react-pdf/renderer";

Font.register({
  family: "Arial",
  fonts: [
    { src: "/pdf/fonts/arial.ttf" },
    {
      src: "/pdf/fonts/arial-black.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {},
  body: {
    fontSize: 10,
    fontFamily: "Arial",
    paddingTop: 10,
    paddingHorizontal: 70,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 25,
    paddingHorizontal: 50,
    paddingBottom: 15,
  },
  imgHeader: {
    maxWidth: "180px",
    opacity: 0.5,
  },
  title: {
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 20,
  },
  subtitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  text: {
    textAlign: "justify",
    paddingBottom: 10,
    //lineHeight: 1.5,
  },
  titleList: {
    textAlign: "justify",
    paddingLeft: 15,
    paddingBottom: 10,
  },
  list: {
    textAlign: "justify",
    paddingLeft: 20,
    paddingBottom: 2,
  },
  image: {
    width: "100%",
    marginVertical: 15,
    marginHorizontal: 90,
  },
  selected: {
    width: 20,
    height: 15,
    backgroundColor: "black",
    borderRadius: 1,
    border: "1 solid black",
  },
  noSelected: {
    width: 20,
    height: 15,
    backgroundColor: "white",
    borderRadius: 1,
    border: "1 solid black",
  },
});

const fechaYear = (fecha) => {
  let fechaArray = fecha.split('/');

  return fechaArray[2];
}

function InresortsPDFNewOne(props) {
  console.log("props InresortsPDF =>", props);
  let membershipmaintenanceletter = props.dataPDF.membershipmaintenanceletter;
  membershipmaintenanceletter = membershipmaintenanceletter.replace(
    "DOLARES",
    " "
  );
  membershipmaintenanceletter = membershipmaintenanceletter.replace(
    "M.N.",
    " "
  );
  membershipmaintenanceletter = membershipmaintenanceletter.trim();

  return (
    <Document>
      <Page wrap size="A4">
        {/*1 */}
        <View style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.title}>
            CONTRATO DE ASOCIACIÓN EN PARTICIPACIÓN PARA EL DESARROLLO DE
            NEGOCIOS N° {props.dataPDF.user.username.toUpperCase()}-{props.dataPDF.codeComany}{props.dataPDF.countSuscriptionByFamily}-{props.dataPDF.typeShares}-{fechaYear(props.dataPDF.creationDate)}
            </Text>

            <Text style={styles.text}>
              Conste por el presente documento, el Contrato de Asociación en Participación para el
              Desarrollo de Negocios, que celebran de una parte{" "}
              <Text style={{ fontWeight: "bold" }}>VALLE ENCANTADO S.A.C.</Text>
              , identificada con RUC N° 20601460271, constituida y regida bajo
              las leyes de la República del Perú, con domicilio legal en Mz.{" "}
              {"<<B>>"} Lote 72, Tercera Etapa Cieneguilla, Provincia de Lima y
              Departamento de Lima, debidamente representada por el Señor Omar
              Fernando Urteaga Cabrera , identificado con D.N.I. N° 41958311; a
              quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“VALLE ENCANTADO”</Text>; y
              de otra parte El(La) Señor(a) {props.dataPDF.user.name.toUpperCase()}{" "}
              {props.dataPDF.user.lastname.toUpperCase()}, de nacionalidad{" "}
              {props.dataPDF.user.courtesy}, identificado con D.N.I. N°{" "}
              {props.dataPDF.user.nroDocument}, con domicilio en{" "}
              {props.dataPDF.user.address}, a quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“EL COMPRADOR”</Text>; en los
              términos contenidos en las cláusulas siguientes:
            </Text>

            <View style={styles.text}>
              <Text style={styles.subtitle}>PRIMERO: ANTECEDENTES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.1{"  "}VALLE ENCANTADO, es una empresa encargada de gestionar y desarrollar el proyecto
                  inmobiliario de carácter recreacional y hotelero de
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  RIBERA DEL RIO CLUB RESORT S.A., identificada con RUC N° 20608720911,
                  debidamente inscritas en la Partida Electrónica N° 14809461 del Registro de Personas Jurídicas de
                  Registro de Personas Jurídicas de Lima y ubicada en Mz. {"<<B>>"} Lote 72, Tercera Etapa Cieneguilla, Provincia y Departamento de Lima. Por lo que, VALLE ENCANTADO requiere contar con recursos y EL ASOCIADO tiene interés en participar en el negocio conforme a las condiciones establecidas en este instrumento.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.2{"  "}EL ASOCIADO es una persona natural que, en calidad de asociado-inversionista,
manifiesta, por el presente acto, su voluntad expresa de participar en el proyecto inmobiliario
desarrollado por VALLE ENCANTADO en los términos establecidos en este documento.
Asimismo, declara haber recibido la presentación del proyecto y con base en ello, y luego
de las consultas del caso, ha decidido invertir en el desarrollo del mismo.
                </Text>
              </View>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>SEGUNDO: OBJETO DEL CONTRATO</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.1.{"  "}Por el presente contrato, las partes acuerdan participar en el negocio descrito en la cláusula
                    siguiente bajo la modalidad de asociación en participación.VALLE ENCANTADO ha puesto
                    a disposición de EL ASOCIADO {" "}{ props.dataPDF.numberShares } acciones inversión Clase {props.dataPDF.typeShares} de RIBERA DEL RIO CLUB RESORT S.A, por el Paquete de acciones de inversión: {" "} {props.dataPDF.userPackage.toUpperCase()} conforme al siguiente detalle:    
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 20,
                  paddingTop: 10,
                  marginLeft: 10,
                }}
              >
                {props.dataPDF.packagesavailable.map((e) => {
                  if (e.package.name === props.dataPDF.userPackage) {
                    return (
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text>{e.package.name}</Text>
                        <Text>{e.numberShares} acc</Text>
                        <View style={styles.selected}></View>
                      </View>
                    );
                  } else {
                    return (
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text>{e.package.name}</Text>
                        <Text>{e.numberShares} acc</Text>
                        <View style={styles.noSelected}></View>
                      </View>
                    );
                  }
                })}
              </View>
              <Text style={[styles.text, { marginLeft: 16.5 }]}>
                Estas acciones de inversión son Acciones Preferentes de segundo orden sin derecho a voto
                y con derecho a usufructo, referente a la facultad de disfrutar los espacios y recibir beneficios
                en los servicios de RIBERA DEL RIO CLUB RESORT S.A, con sujeción al “Programa de Beneficios”, y al “Código de Ética y Conducta” – Anexo 1 que es parte integral del presente contrato.
              </Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.2. Por su parte, EL ASOCIADO se obliga a transferir a VALLE ENCANTADO como capital de
                negocio, la suma pactada en la cláusula tercera, en la forma y oportunidad convenidas.
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/*2 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.subtitle}>TERCERO: PRECIO Y FORMA DE PAGO</Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.1.{"  "}El capital de negocio por el Paquete de acciones de inversión {" "}
                {props.dataPDF.userPackage.toUpperCase()}, asciende a la suma de{" "}
                {`$${props.dataPDF.price}`},
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                US. {props.dataPDF.pricetotalLetter}, el cual le otorga a EL
                COMPRADOR el número de acciones y beneficios mencionados en el
                numeral 2.1 del presente contrato.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.2.{"  "}Las partes acuerdan que el abono del capital de negocio, se
                efectuará en dólares de los Estados Unidos de
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                América, al amparo de lo dispuesto por el Artículo 1237 del
                Código Civil.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.3.{"  "}El capital de negocio será abonado por EL ASOCIADO, en el plazo de (
                {props.dataPDF.numberQuotas}) meses, mediante la cancelación de
                una cuota inicial
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                de {`$${props.dataPDF.initialPrice}`} y{" "}
                {props.dataPDF.numberQuotas} cuotas mensuales consecutivas de{" "}
                {`$${props.dataPDF.quotaPrice}`} cada una. La cuota inicial se
                cancelará en la fecha de la suscripción del contrato, mientras
                que las cuotas subsiguientes en la misma fecha del mes siguiente correspondientemente, de acuerdo al
Cronograma de Pago de Inversión constado en el Anexo 2 del presente documento
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.4.{"  "}La cuota inicial y las cuotas mensuales
                correspondientes, podrán ser canceladas en las siguientes
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                cuentas bancarias a elección de EL COMPRADOR:
              </Text>
            </View>
            <Image
              style={[{ width: "100%", height: "105px", paddingBottom: 10 }]}
              src="/pdf/inresorts/precios.png"
            />
            <Text
              style={{
                textAlign: "justify",
                marginLeft: 10,
                paddingBottom: 10,
              }}
            >
              En caso de cambio o incrementos de cuentas bancarias, VALLE
              ENCANTADO informará oportunamente a través del correo registrado
              de EL COMPRADOR, dentro de los primeros 7 (siete) días del cambio.
            </Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.5.{"  "}EL ASOCIADO podrá abonar por adelantado una o más
                cuotas
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                mensuales no vencidas; sin embargo, no se admitirá en ningún
                caso el pago parcial de estas cuotas.
              </Text>
            </View>
            <Text style={styles.subtitle}>
              CUARTA: RÉGIMEN DE RENDIMIENTO Y RETORNO DE LA INVERSIÓN
            </Text>
            <Text style={styles.text}>
               Por su parte, en contraprestación, VALLE ENCANTADO se obliga a retribuir a EL ASOCIADO
              con Acciones Clase {props.dataPDF.typeShares} (Acciones Preferentes de segundo orden sin derecho a voto y con derecho
              a usufructo), contando con los siguientes beneficios:
            </Text>
            <Text style={styles.titleList}>- Acciones:</Text>
          </View>
        </View>

        {/*3 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View style={styles.text}>
              <Text style={styles.list}>
                • En cuanto EL ASOCIADO concluya con el abono de la totalidad de las cuotas pactadas,
                  del Cronograma de Pagos de Inversión - Anexo 2 que es parte integral del presente
                  contrato, tendrá derecho a recibir {props.dataPDF.numberShares}{" "} acciones Clase
                  B, libre de gravámenes, cargas y cualquier obligación que limite o restrinja su dominio o
                  libre disposición, las cuales tienen derecho a la participación de las utilidades de la
                  empresa cuando estas se produzcan en proporción del número de sus acciones de
                  inversión que en merito al presente contrato se encuentra representando. Asimismo, como
                  parte del presente contrato EL ASOCIADO por la representación de las acciones tendrá
                  derecho al paquete de beneficios que dichas acciones representan de acuerdo al monto
                  de la inversión realizada por este, que se encuentran detallados en el Programa de
                  Beneficios. Queda entendido que EL ASOCIADO participa de los resultados, tanto de las
                  utilidades como de las pérdidas de RIBERA DEL RIO CLUB RESORT S.A., así como
                  queda entendido que los beneficios mencionados en esta cláusula son de carácter
                  perpetuo, conforme a su Programa de Beneficios.
              </Text>
              <Text style={styles.list}>
                • El cumplimiento del 100% de la inversión, hara acreedor a EL ASOCIADO de un
                  Certificado de Acciones Clase {props.dataPDF.typeShares}, de acuerdo al Paquete de acciones elegido por EL
                  ASOCIADO.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 30 }}>
                • La inversión otorga el derecho a EL ASOCIADO a usufructuar el Hotel Club Resort, según
                  su tipo de Programa de Beneficios. El tipo de Programa varía de acuerdo con el número
                  de acciones de inversión seleccionada por EL ASOCIADO
              </Text>
             
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 30 }}>
                •  Las acciones Clase {props.dataPDF.typeShares} corresponden ser acciones preferentes de segundo orden sin
                    derecho a voto y con derecho a usufructo, estas confieren a su titular la calidad de socio y
                    le atribuye, entre ellos, los siguientes derechos:
              </Text>
             
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 35 }}>
                  Participar en el reparto de utilidades y del patrimonio neto resultante de la liquidación.
                  2. Derecho de usufructuar los espacios y beneficios en los servicios de RIBERA DEL RIO
                  CLUB RESORT S.A., según el tipo de Programa de Beneficios adquirido.
                  3. Posibilidad de participar en el Directorio, a través de un representante elegido por todos
                  los accionistas del Sistema Red.
                  4. Ser informado cuando menos semestralmente de las actividades y gestión de RIBERA
                  DEL RIO CLUB RESORT S.A.
              </Text>
              <Text style={{ marginLeft: 30, marginTop: 10 }}>
                  Asimismo, le confiere a EL ASOCIADO los demás derechos establecidos en el Estatuto
                  de RIBERA DEL RIO CLUB RESORT S.A., conforme a la Ley General de Sociedades
              </Text>
             
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 30 }}>
                •   La entrega del Certificado de Acciones, el cual tiene como característica ser vitalicio y
                    heredable, se realizará a la fecha del cumplimiento del abono total de la inversión. En el
                    caso del Programa de Beneficios Light en adelante, se irán entregando Certificados
                    Parciales, en función al cumplimiento de los segmentos anuales, salvo expresión escrita
                    formal o electrónica por parte de EL ASOCIADO. Cabe indicar que, el último certificado
                    parcial correspondiente a la duplicación de acciones, será entregado a partir del primer día
                    útil de enero del año 2027. En caso que EL ASOCIADO haya cancelado el 100% del capital de negocio en una fecha anterior a la indicada, VALLE ENCANTADO podrá disponer la
                    posibilidad de hacer la entrega anticipada de dicho certificado bajo ese contexto u otro que
                    considere pertinente. Dichos certificados, serán legalizados Notarialmente. 
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 30 }}>
                •   Las acciones son heredables, por lo que, el heredero o legatario se convierte en accionista
                    de la sociedad en aplicación del artículo 660 del Código Civil, en tanto que, la muerte pone
                    fin a la persona, produce la apertura de su sucesión y origina la transmisión de su
                    patrimonio, constituido por activos y pasivos, es decir, por los bienes, derechos y
                    obligaciones.  
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 30 }}>
                •  EL ASOCIADO tiene derecho a invertir en nuevas acciones de inversión, teniendo en
                  consideración las siguientes condiciones:
              </Text>
             
            </View>
          </View>
        </View>
        {/* 4 */}
        <View wrap={false} style={styles.page}> 
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View  style={styles.body}>
            <View style={styles.text}>
              <Text style={{ marginLeft: 35 }}>
                •   El monto del capital de negocio corresponderá al valor de mercado vigente en el
                    momento de la inversión.
              </Text>
             
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 35 }}>
                •   EL ASOCIADO al optar por la inversión en nuevas acciones de inversión, acepta las
                    condiciones y normas vigentes a dicha fecha.
              </Text>
             
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 35 }}>
                •   EL ASOCIADO durante los primeros 12 meses puede ir migrando entre Paquetes,
                    desde la MINI hasta VITALICIA ULTRA PREMIUM, siempre y cuando exista
                    disponibilidad. Para la cual, lo que deberá de abonar es la diferencia del valor entre
                    los Paquetes más la tasa de UPGRADE vigente en el momento.
              </Text>
             
            </View>
            <View style={styles.text}>
              <Text style={{ marginLeft: 35 }}>
                •   El número de Paquete de acciones de inversión será determinado en función a la
                    ocupabilidad, poniendo el Hotel Club Resort a disposición de sus socios el 25% de su
                    capacidad instalada anual
              </Text>
             
            </View>
            <Text style={styles.titleList}>- Usufructo (Programa de Beneficios):</Text>
            <View style={styles.text}>
              <Text style={{marginLeft: 30}}>
                • El derecho de usufructo de EL ASOCIADO se aplica conforme al siguiente detalle:
              </Text>
              <Text style={{marginLeft: 30, marginTop: 10}}>
                • El Plan {props.dataPDF.userPackage} incluye para el socio y sus beneficiarios:{" "}{props.dataPDF.beneficiaries}{""},
                  disfrutar ilimitadamente nuestras instalaciones los 365 días del año, desde la
                  inauguración de la primera etapa del Hotel Club Resort
              </Text>
              <View style={[styles.text, { paddingLeft: 10 }]}>
                <Text style={styles.list}>
                 - Piscinas: 1 piscina bebes, 1 para niños de 6 a 12 años y 1 para adultos
                </Text>
                <Text style={styles.list}>
                 - Áreas deportivas: 2 canchitas de fulbito y 2 de vóley multiusos.
                </Text>
                <Text style={styles.list}>
                 - Área de Juegos Infantiles: Inflables, camas elásticas, resbaladeras, columpios,
                  laberintos, muros de escalar, entre otros.
                </Text>
                <Text style={styles.list}>
                 - Sala de Juegos: Fulbito de mano, tenis de mesa, billas, tiro al sapo y más.
                </Text>
                <Text style={styles.list}>
                 - Salones de Recepciones: 4 salones de recepciones para eventos con
                  capacidades que van desde 80 personas hasta 450 personas. 
                </Text>
                <Text style={styles.list}>
                 - Restaurantes: 1 Buffet y 1 A la Carta. 
                </Text>
                <Text style={styles.list}>
                 - Bar y Karaoke. Spa, Sauna y Gimnasio 
                </Text>
                <Text style={styles.list}>
                 - Áreas Verdes y Áreas de Reposo: Mas de 2500 m2 de área verde 
                </Text>
                <Text style={styles.list}>
                 - Estacionamiento Privado: Amplias playas de estacionamiento con capacidad para
                  más de 200 vehículos.
                </Text>
              </View>
              <Text style={{marginLeft: 30, marginTop: 10}}>
                • Invitados: {props.dataPDF.numberGuests} Pases libres para invitados por mes (acumulable x2, 2 veces por
                año en día de semana {"–"} No aplica para feriado, ni feriado largo). Los invitados
                adicionales deberán pagar por su ingreso según tarifario vigente. En el caso de eventos y reservas, la cantidad de invitados puede variar de acuerdo a las condiciones
                convenidas con EL ASOCIADO. 
              </Text>
            </View>
            <View style={styles.text}>
              <View style={styles.text}>
                <Text>
                  • Alojamiento incluido (No aplica para feriado, ni feriado largo): Según el Paquete
                  de acciones adquirido, como se muestra en la siguiente tabla: 
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 20,
                  paddingTop: 10,
                  marginLeft: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>Mini:</Text>
                  <Text>160 puntos</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>Experience:</Text>
                  <Text>160 puntos</Text>
                </View>

              </View>
            </View>

            <View style={styles.text}>
              <Text style={styles.list}>
                Habitación Estándar Resort:
              </Text>
              <Text style={[styles.text, { paddingLeft: 10 }]}>
                - 1 noche de alojamiento en Fin de semana: Viernes y Sábado 3pm-12 pm. Equivalente a 30 puntos
              </Text>
              <Text style={[styles.text, { paddingLeft: 10, paddingTop: 5 }]}>
                - 1 noche de alojamiento en Semana: Domingo a Jueves 3pm- 12pm. Equivalente a 20 puntos. 
              </Text>
            </View>

            
          </View>
        </View>

        {/* 5 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            
          <View style={styles.text}>
              <Text style={styles.list}>
                Apartamento Estándar Resort:
              </Text>
              <Text style={[styles.text, { paddingLeft: 10 }]}>
                - 1 noche de alojamiento en Fin de semana: Viernes y Sábado 3pm-12 pm. Equivalente a 60 puntos.
              </Text>
              <Text style={[styles.text, { paddingLeft: 10, paddingTop: 5 }]}>
                - 1 noche de alojamiento en Semana: Domingo a Jueves 3pm- 12pm. Equivalente a 40 puntos.  
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.list}>
                Suite Resort:
              </Text>
              <Text style={[styles.text, { paddingLeft: 10 }]}>
                - 1 noche de alojamiento en Fin de semana: Viernes y Sábado 3pm-12 pm. Equivalente a 120 puntos.
              </Text>
              <Text style={[styles.text, { paddingLeft: 10, paddingTop: 5 }]}>
                - 1 noche de alojamiento en Semana: Domingo a Jueves 3pm- 12pm. Equivalente a 80 puntos.   
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.list}>
                • Descuentos: Restaurante y Bar: 20%, Servicios Spa, Salas de Cumpleaños Infantiles
                  y Salas de Recepciones: 25%, Alojamiento Adicional: 30% e Invitados Adicionales:
                  50%. Para otros servicios que adquiera el socio, aplicarán los precios según el tarifario
                  vigente
              </Text>
              <Text style={styles.list}>
                • Descuentos vacacionales: Los asociados que hayan pagado al 100% su programa
                  Vitalicio, Vitalicio Premium y Vitalicio Ultra Premium, tendrán beneficios vacacionales a
                  través de:
              </Text>
              <Text style={styles.list}>
                • Descuentos vacacionales: Los asociados que hayan pagado al 100% su programa
                  Vitalicio, Vitalicio Premium y Vitalicio Ultra Premium, tendrán beneficios vacacionales a
                  través de:
              </Text>
              <Text style={[styles.text, { paddingLeft: 10, paddingTop: 5 }]}>
                • Vive Ahora Travel. Agencia de Viaje EXCLUSIVA PARA SOCIOS, adquiere todo
                  para tus vacaciones Nacionales e Internacionales: Noches de hotel, Paquetes
                  armados y mucho más... directamente tomados de las Operadoras Turísticas más
                  importantes.
              </Text>
              <Text style={[styles.text, { paddingLeft: 10, paddingTop: 5 }]}>
                • RCI: Compañía Internacional de intercambio vacacional, la cual le permite al afiliado disfrutar de los siguientes beneficios: 
              </Text>
              <Text style={[styles.text, { paddingLeft: 15, paddingTop: 5 }]}>
                - Semanas de Intercambio Vacacional desde $ 359.00 (Beneficio activo desde Inauguración del Resort) (Temporada Alta / Media / Baja). 
              </Text>
              <Text style={[styles.text, { paddingLeft: 15 }]}>
                - Semanas de Escape desde $ 299.00 (Sujeto a cambios que RCI podría realizar en el tiempo) (Temporada Media / Baja) 
              </Text>
              <Text style={[styles.text, { paddingLeft: 15 }]}>
                - Mas de 4500 Desarrollos en más de 110 Países. flexibilidad para viajar y posibilidad de traspasar, regalar o ceder beneficios a un tercero. Para participar de este programa se requiere hacer pagos por concepto de Afiliación: $ 99.00 y por mantenimiento anual: $ 59.00. - Estos conceptos son pagados por EL ASOCIADO. EL ASOCIADO puede decir participar o no en este programa.
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.list}>
                • Beneficiarios Adicionales: El socio podrá adherir a su membresía hasta 3
                  beneficiarios adicionales. Cabe indicar que, estos 3 beneficiarios adicionales
                  mencionados en este párrafo, son adicionales a los que su programa gratuitamente le
                  brinda. El costo de cada uno de ellos será USD$ 50 por año. 
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={{ paddingLeft: 20 }}>
                • Durante el Desarrollo de construcción del Hotel: El socio y sus beneficiarios podrán hacer
                  uso gratuito de las instalaciones para FULL DAY todos los fines de semana y feriados
                  (bajo reserva). También tendrán descuentos en Restaurante (10%), Servicios (20%) y
                  alquiler de alojamiento (30%). Para otros servicios que adquiera el socio, aplicarán los
                  precios según el tarifario vigente
              </Text>
              <Text style={{ paddingLeft: 20 }}>
                • Identificación: A las 48 horas de realizado el pago de la cuota de ingreso, se actualizará
                  en sistema el núcleo familiar de EL ASOCIADO. Los cuales, para poder hacer uso del
                  Programa de beneficios que otorga el Paquete de acciones seleccionado, sólo deberán
                  mostrar su Documento de Identidad (DNI, Carnet de Extranjería o Pasaporte).
              </Text>
              <Text style={{ paddingLeft: 20 }}>
                • Identificación: A las 48 horas de realizado el pago de la cuota de ingreso, se actualizará
                  en sistema el núcleo familiar de EL ASOCIADO. Los cuales, para poder hacer uso del
                  Programa de beneficios que otorga el Paquete de acciones seleccionado, sólo deberán
                  mostrar su Documento de Identidad (DNI, Carnet de Extranjería o Pasaporte).
              </Text>
            </View>

          </View>
        </View>
        {/* 6 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <View style={styles.text}>
                <Text style={{ paddingLeft: 20 }}>
                  • Kit de Bienvenida: Cada ASOCIADO, después de formalizar su sistema de pago, recibe el
                    Kit en un plazo de 15 días útiles. El Kit incluye: Carta de Bienvenida donde se consigna su
                    número de socio, Cronograma de Pagos de Inversión (caso hubiese fraccionado), y el
                    Reglamento de conducta y Código de Ética de RIBERA DEL RIO CLUB RESORT S.A.
                    Este Kit de Bienvenida únicamente será entregado en alguna de nuestras oficinas previa
                    coordinación con el titular o vía correo electrónico según prefiera EL ASOCIADO.
                </Text>
                  <Text style={{ paddingLeft: 20 }}>
                  • Desacuerdo Varios: EL ASOCIADO tendrá derecho a revocar el contrato durante el primer
                    año, de no estar de acuerdo con los avances del desarrollo de construcción del Hotel, su
                    administración, o cualquier situación por la cual no desee seguir formando parte del Hotel
                    Club Resort como Socio. Ante lo cual, VALLE ENCANTADO le devolverá a EL ASOCIADO
                    el 50% del monto de dinero que haya cancelado por las CUOTAS MENSUALES de su
                    paquete de acciones de inversión hasta la fecha de la resolución del presente contrato, en
                    servicios que brinda el Hotel Club Resort, mediante un Vale o Gift Card, dejando sin efecto
                    este contrato. El Vale o Gift Card no aplica para restaurante y bares. Esto quedará
                    constado en el Acta de Resolución del contrato y los costos involucrados en la resolución
                    del presente contrato, quedarán a cargo de EL ASOCIADO. 
                  </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ paddingLeft: 20 }}>
                  • Cesión: EL ASOCIADO puede ceder, transferir o donar, parcial o totalmente cualquier
                    beneficio y/u obligaciones bajo este contrato, con el solo llenado de los formatos
                    correspondientes, cabe señalar que el cesionario de la posición, el receptor de la donación
                    o el comprador deberá reunir los requisitos para poder ser parte de la sociedad. Estos Son:
                </Text>
              </View>
              <View style={styles.text}>
                <View style={{ paddingLeft: 30 }}>
                  <Text>
                    o No tener antecedentes penales.
                  </Text>
                  <Text>
                    o No tener acreencias judiciales civiles.
                  </Text>
                </View>
                <Text style={{ paddingLeft: 20 }}>
                  • VALLE ENCANTADO no se hace responsable por el tiempo que EL ASOCIADO no haga uso del Hotel Club Resort y sus beneficios durante la vigencia del mencionado Programa.
                </Text>
              </View>
              <Text style={styles.subtitle}>QUINTA: OBLIGACIONES DE LAS PARTES</Text>

              <Text style={styles.text}>
                5.1.VALLE ENCANTADO tiene las siguientes obligaciones:
              </Text>

              <Text style={[styles.text, { paddingLeft: 10 }]}>- Acciones</Text>

              <View style={styles.text}>
                <Text style={styles.list}>
                  • VALLE ENCANTADO se obliga la gestionar, administrar y realizar el negocio, materia del presente contrato, de manera ordenada y adecuada. En tal sentido, VALLE ENCANTADO deberá proceder con la diligencia, prudencia, buena fe y lealtad.
                </Text>
                <Text style={styles.list}>
                  • VALLE ENCANTADO se obliga a otorgar las acciones materia de este contrato a EL
                    ASOCIADO, libres de gravámenes, cargas y cualquier obligación que limite o restrinja
                    su dominio o libre disposición, las cuales tienen derecho a usufructo en beneficio del
                    socio o accionista en el momento establecido en la cláusula cuarta del presente
                    contrato.
                </Text>
              </View>
              
              <View style={styles.text}>
                <Text style={styles.list}>
                  • Suscribir todos los documentos o contratos necesarios para perfeccionar el otorgamiento de titularidad de acciones
                </Text>
                <Text style={styles.list}>
                  • VALLE ENCANTADO se compromete que RIBERA DEL RIO CLUB RESORT S.A.
                    abone las utilidades correspondientes por cada año fiscal a EL ASOCIADO, en caso
                    si hubiere, conforme a los plazos establecidos por Ley para la repartición de
                    utilidades.
                </Text>
              </View>

              <Text style={[styles.text, { paddingLeft: 10 }]}>- Usufructo:</Text>

              <View style={styles.text}>
                <Text style={styles.list}>
                  • En virtud del presente contrato, VALLE ENCANTADO deberá suministrar a EL
                    ASOCIADO, la facultad de disfrutar los espacios y recibir beneficios en los servicios
                    de RIBERA DEL RÍO CLUB RESORT S.A., con sujeción al “Programa de Beneficios”
                    y al “Código de Ética y Conducta” que EL ASOCIADO tiene derecho conforme al
                    número de acciones de acuerdo a su inversión y que ambos documentos son parte
                    integral del presente contrato.
                </Text>
              </View>
              
            </View>
            
          </View>
        </View>

        {/* 7 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>

              <Text style={styles.text}>
                5.2. EL ASOCIADO tiene las siguientes obligaciones:
              </Text>

              <Text style={[styles.text, { paddingLeft: 10 }]}>- Acciones</Text>

              <View style={styles.text}>
                <Text style={styles.list}>
                  • EL ASOCIADO se obliga a efectuar el abono de las cuotas mensuales en el momento
                    y forma pactados, conforme a lo dispuesto en la cláusula tercera de este documento.
                    En caso contrario, EL ASOCIADO recaerá en incumplimiento según lo dispuesto en
                    la cláusula sétima
                </Text>
                <Text style={styles.list}>
                  • Suscribir los documentos y contratos que sean necesarios para perfeccionar el otorgamiento de titularidad de las acciones seleccionadas de acuerdo a su inversión.
                </Text>
              </View>

              <Text style={[styles.text, { paddingLeft: 10 }]}>- Usufructo</Text>
              
              <View style={styles.text}>
                <Text style={styles.list}>
                  {"a)"} Mantenimiento
                </Text>
                <View style={{ marginLeft: 10 }}>
                  <Text style={styles.list}>
                    • El pago por concepto de mantenimiento corresponde a: US$ { props.dataPDF.membershipmaintenance }{" "}
                      ({props.dataPDF.membershipmaintenanceletter}) anuales. Cabe indicar que, este concepto se descontará
                      directamente de los ingresos que genere RIBERA DEL RIO CLUB RESORT S.A.
                      cada año. En caso dichos ingresos no sean suficientes para cubrir el mencionado
                      concepto, este pago será asumido por EL ASOCIADO. El pago de mantenimiento
                      aplica a partir de que el Hotel Club Resort realiza su apertura conforme a sus
                      etapas de lanzamiento.
                  </Text>
                  <Text style={styles.list}>
                    • En caso el pago por mantenimiento deba ser asumido por EL ASOCIADO, éste
                      deberá cancelar el este concepto hasta el último día calendario del mes de enero
                      del año respectivo. Este concepto podrá variar, según lo determine y justifique la
                      administración (incremento costo vida, inflación, valor dinero en el tiempo).
                  </Text>
                  <Text style={styles.list}>
                    • El no uso del Programa de Beneficios de Ribera del Río Club Resort S.A., no exonera de este pago al titular.
                  </Text>
                  <Text style={styles.list}>
                    • En caso de incumplimiento de pago de la cuota del mantenimiento anual, EL
                      ASOCIADO quedará inhabilitado temporalmente hasta regularizar el pago, es
                      decir, se procederá a la suspensión de los beneficios según el Programa de
                      Beneficios y no se habilitará el USO, hasta la subsanación del pago o pagos
                      pendientes.
                  </Text>
                  <Text style={styles.list}>
                    • Esta operación no requiere estado de cuenta previo para su cancelación. Puede
                      ser abonado en las cuentas corrientes que serán informadas por RIBERA DEL
                      RIO CLUB RESORT S.A. en su oportunidad, identificándose con su número de
                      Documento de Identidad y/o pago directo en alguna oficina del Hotel Club Resort.
                      o en las cuentas corrientes que para tal fin se le proporcionaran a EL ASOCIADO
                      con la finalidad que abone dichos mantenimientos, asimismo una vez realizado
                      estos, bastará una comunicación electrónica con la copia del depósito
                      correspondiente para dar por cancelada la obligación. RIBREA DEL RIO RESORT
                      S.A.; emitirá los documentos contables de acuerdo a Ley dentro de las 48 horas
                      siguientes a la confirmación del abono.
                  </Text>

                </View>
              </View>

              <View style={styles.text}>

                <Text style={styles.list}>
                  {"b)"} Términos de Conducta
                </Text>
                <View style={{ marginLeft: 10 }}>
                  <Text style={styles.list}>
                    • EL ASOCIADO deberá responsabilizarse por los daños que éste, su cónyuge,
                      hijos, hijas e invitados pudieran causar a las instalaciones de RIBERA DEL RÍO
                      CLUB RESORT S.A., ya sea por dolo, culpa leve o culpa inexcusable.
                  </Text>
                  <Text style={styles.list}>
                    • EL ASOCIADO deberá cancelar las sanciones que RIBERA DEL RÍO CLUB
                      RESORT S.A., a través de su administración, podrá imponer a los socios que
                      constan en el Código de Ética y Conducta del Hotel Club Resort.
                  </Text>
                </View>
              </View>
          </View>
        </View>
        
        {/* 8*/}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
              <View style={styles.text}>
                <View style={{ marginLeft: 10 }}>
                  <Text style={styles.list}>
                    • EL ASOCIADO declara conocer las disposiciones contenidas en el Código de
                      Ética y Conducta de RIBERA DEL RÍO CLUB RESORT S.A, establecido en el
                      Anexo 1 del presente contrato y que lo ha leído previamente a la suscripción del
                      presente documento, sin más constancia que la firma puesta al pie, quedando
                      suscrito a sus términos y condiciones aceptando los procedimientos y sanciones
                      que éste contempla.
                  </Text>
                  <Text style={styles.list}>
                    • EL ASOCIADO acepta tener conocimiento que RIBERA DEL RÍO CLUB RESORT
                      S.A. podrá modificar su Reglamento, así como podrá expedir otras normas,
                      reglamentos y políticas que tengan por finalidad mejorar el uso de las instalaciones
                      de RIBERA DEL RÍO CLUB RESORT S.A. por parte de los afiliados y público en general, los cuales están obligados a respetarlas desde la fecha en que estas sean
                      comunicadas, dichas modificaciones serán sometidas a la Junta General de
                      Accionistas obligatoria o convocada para tal fin.
                  </Text>

                </View>
              </View>

              <View style={styles.text}>
                <Text style={styles.subtitle}>
                  SEXTA: FACULTADES DE LAS PARTES
                </Text>
                <Text style={styles.list}>
                  6.1. Las partes declaran expresamente que corresponderá a VALLE ENCANTADO cualquier
                      vinculación económica que en el desarrollo del negocio se acuerde con terceros, para lo
                      cual VALLE ENCANTADO actuará en nombre propio al celebrar contratos, al asumir
                      obligaciones o al adquirir créditos. En consecuencia, queda convenido que no existirá
                      relación jurídica alguna entre los terceros y EL ASOCIADO; y, asimismo, los terceros no
                      adquirirán obligaciones frente a EL ASOCIADO ni éste ante aquellos
                </Text>
                <Text style={styles.list}>
                  6.2. Queda expresamente establecido que EL ASOCIADO no asume ni asumirá ninguna
                      responsabilidad, de ninguna naturaleza, por las acciones, decisiones, compromisos o
                      cualquier otro acto, sea verbal o escrito, que realice VALLE ENCANTADO, ni por las
                      consecuencias que ellos generen al vincularse con otros asociados-inversionistas en el
                      desarrollo del negocio referido en el presente contrato, hecho que EL ASOCIADO declara
                      conocer plenamente y acepta en el presente acto en cuanto a su naturaleza y relación
                      jurídica.
                </Text>
                <Text style={styles.list}>
                  6.3. En virtud de lo acordado en la presente cláusula, las partes convienen que VALLE
                      ENCANTADO no requiere de ningún tipo de consentimiento previo adicional de EL
                      ASOCIADO para cualquier vinculación económica celebrada en el desarrollo del negocio
                      con terceros, más allá de lo expresado y acordado en la citada cláusula. 
                </Text>
                <Text style={styles.list}>
                  6.4. EL ASOCIADO declara estar conforme en que no tendrá injerencia directa ni indirecta en
                    las actividades de VALLE ENCANTADO. 
                </Text>
              </View>

              <Text style={styles.subtitle}>SÉTIMA: INCUMPLIMIENTO</Text>  
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  El ASOCIADO renuncia irrevocablemente a invocar hechos de terceros o causas no imputables
                  a él, en caso de incumplimiento de cualesquiera de las obligaciones asumidas en el presente
                  CONTRATO
                </Text>
              </View>

              <Text style={styles.subtitle}>OCTAVA: CUMPLIMIENTO DE NORMAS SOBRE PREVENCIÓN DE LAVADO DE ACTIVOS
                Y FINANCIAMIENTO DEL TERRORISMO</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  A la suscripción del presente contrato, las partes se sujetan a las normas vigentes y aplicables
                  sobre prevención de lavado de activos y financiamiento del terrorismo del Reglamento de
                  Gestión de Riesgo de Lavado de activos, aprobado mediante Resolución SBS N° 2660-2015.
                  En ese sentido, las partes declaran que sus actividades, así como los valores materia de la
                  presente transferencia o transferencias no se encuentran relacionados con actividades ilícitas,
                  particularmente de lavado de activos o del financiamiento del terrorismo.
                </Text>
              </View>

          </View>
        </View>

        {/* 9 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
          <View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  Si alguna de las partes resultase implicada o involucrada en una investigación relacionada con
                  actividades ilícitas de lavado de activos y/o financiamiento del terrorismo o fuese incluida en
                  Listas de control como las de la ONU, OFAC, Listas de terroristas de la Unión Europea, Listas
                  de Países y Territorios No Cooperantes y otras que señale la SBS; esta se compromete a
                  indemnizar y/o pagar los daños y perjuicios directos, multas, sanciones, costas, honorarios de
                  abogados, en los que la parte involucrada haya incurrido tras un procedimiento en el que  hubiera sido finalmente condenado por resolución administrativa o judicial firme, y que pudiera
                  sufrir sin que medie responsabilidad suya, esto como consecuencia directa de los hechos o
                  actos dolosos o mediando culpa grave de la parte involucrada y que generen demandas,
                  denuncias de todo tipo, medidas cautelares fuera y dentro del proceso, reclamos interpuestos
                  por cualquier causa y ante cualquier fuero judicial, arbitral o administrativo. La parte involucrada
                  deberá asumir los gastos mencionados en la presente cláusula hasta que la parte afectada se
                  vea eximida del proceso
                </Text>
              </View>
                
              <Text style={styles.subtitle}>NOVENA: ASOCIACIÓN EN PARTICIPACIÓN</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  En armonía con lo establecido por los artículos 438 y 441 de la Ley General de Sociedades, las
                  partes dejan constancia de que el presente contrato de asociación en participación no genera la
                  creación de una persona jurídica y tampoco tiene razón social ni denominación alguna. En
                  consecuencia, VALLE ENCANTADO actuará en nombre propio en las relaciones comerciales
                  que se originen a propósito del presente contrato.
                </Text>
              </View>
              
              <Text style={styles.subtitle}>DÉCIMA: CONFIDENCIALIDAD</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  Las partes acuerdan que, si no cuentan con el consentimiento previo de la otra parte, no
                  revelarán el contenido de este contrato a ningún tercero, a no ser que alguna de las partes tenga
                  que revelar dicha información: (a) para implementar o hacer cumplir este contrato; (b) si se ve
                  obligada a hacerlo por una ley, tribunal o norma; (c) si sus abogados le informan que está
                  obligada legalmente a hacerlo o que el hecho de que no cumpla con hacerlo pueda dar lugar a que asuma alguna responsabilidad frente a cualquier otra Entidad o se genere el riesgo de la
                  imposición de una sanción administrativa de cualquier tipo a sus asesores y auditores
                  profesionales de ser el caso.
                </Text>
              </View>

              <Text style={styles.subtitle}>DÉCIMO PRIMERA: PAGO DE TRÁMITES LEGALES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  Las partes establecen que los gastos notariales correspondientes a la legalización del presente
                  contrato, queda a cargo de EL ASOCIADO.
                </Text>
              </View>

              <Text style={styles.subtitle}>DÉCIMO SEGUNDA: ADICIONALES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  El presente contrato aplica a las siguientes promociones:
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 10, marginTop: 10 }}>
                  Duplicar el número de acciones del Paquete adquirido como socio fundador, siempre y
                  cuando cumpla con abonar el capital de negocio, conforme a la cláusula tercera del
                  presente contrato. Esto solo aplica para planes vitalicios
                </Text>
              </View>

              <Text style={styles.subtitle}>DÉCIMO TERCERA: DOMICILIO Y NOTIFICACIONES LEGALES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  Las partes señalan como sus domicilios los consignados en la introducción del presente contrato.
                  Cualquier comunicación o notificación deberá ser cursada a dichos domicilios por escrito, con
                  cargo de recepción. En caso de cambio de domicilio, para su efecto o validez legal deberá
                  comunicarse a la otra parte, con cargo de recepción y a la brevedad posible. En tanto no
                  recepcione la otra parte la citada comunicación se entenderán válidamente efectuadas las
                  comunicaciones remitidas al anterior domicilio y surtirán todos sus efectos legales. Cualquier
                  variación del mismo deberá ser comunicada mediante carta notarial con una anticipación de
                  treinta (30) días naturales, sin cuyo requisito no tendrá efecto la variación del domicilio.
                </Text>
              </View>

            </View>
          </View>
        </View>

         {/* 10 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <Text style={styles.subtitle}>DÉCIMO CUARTA: LEGISLACION APLICABLE</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  El contrato se regirá por la Legislación Peruana. En todo lo no previsto por las partes en el
                  presente contrato, ambas se someten a lo establecido por las normas del Código Civil, la Ley
                  General de Sociedades, los principios de buena fe contractuales y demás del sistema jurídico
                  que resulten aplicables.
                </Text>
              </View>
              <Text style={styles.subtitle}>DÉCIMO QUINTA: COMPETENCIA ARIBITRAL</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  En caso de cualquier discrepancia o conflicto que surgiera entre las partes, en relación con la
                  celebración, interpretación, ejecución y/o efectos jurídicos, resolución o cualquier otro vinculado
                  con el presente contrato, éste contrato será sometido a las disposiciones del Centro de Arbitraje
                  Nacional e Internacional de la Cámara de Comercio de Lima.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  Dicho Tribunal Arbitral estará constituido por tres (3) miembros. Cada parte escoge a un (1)
                  árbitro, siendo el tercer miembro escogido por los árbitros de cada una de las partes. En caso
                  este tercer miembro no se escoja dentro del plazo máximo de diez (10) días naturales, dicho
                  tercer miembro será escogido por el mismo Centro de Arbitraje de la Cámara de Comercio de
                  Lima. Este tercer miembro será quien presida el Tribunal Arbitral. 
                </Text>
                <Text
                  style={{
                    textAlign: "justify",
                    marginTop: 10,
                  }}
                >
                  El laudo del Tribunal será definitivo e inapelable, así como de obligatorio cumplimiento y ejecución para las partes. 
                </Text>
                <Text
                  style={{
                    textAlign: "justify",
                    marginTop: 10,
                  }}
                >
                  Cabe señalar que la parte que no se vea favorecida con el laudo expedido por el Tribunal Arbitral, será la que asuma el costo del procedimiento invocado, en cuanto a las costas y los costos
                </Text>
                <Text
                  style={{
                    textAlign: "justify",
                    marginTop: 10,
                  }}
                >
                  En señal de conformidad las partes suscriben este documento en
                  la ciudad de Lima, a los{" "}
                  {`${props.dataPDF.creationDate.split("/")[0]}`} días del mes de{" "}
                  {`${props.dataPDF.creationDate.split("/")[1]}`} de{" "}
                  {`${props.dataPDF.creationDate.split("/")[2]}`}
                </Text>
                <Text></Text>
                <Text></Text>
              </View>
              <View
                style={{
                  paddingTop: 50,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Image
                    src="/pdf/inresorts/firmaOmarUrteaga.jpg"
                    style={{ width: "100px", height: "50px" }}
                  />
                  <Text>____________________________</Text>
                  <Text>VALLE ENCANTADO S.A.C.</Text>
                  <Text>Omar Fernando Urteaga Cabrera</Text>
                  <Text>Gerente General</Text>
                  <Text>VALLE ENCANTADO</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    paddingBottom: 10,
                  }}
                >
                  <Text>____________________________</Text>
                  <Text>{`${props.dataPDF.user.name} ${props.dataPDF.user.lastname}`}</Text>
                  <Text>
                    Documento de identidad N°{props.dataPDF.user.nroDocument}
                  </Text>
                  <Text>EL COMPRADOR</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
       {/*} 
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              
              
            

            </View>
          </View>
        </View>*/}
        
      </Page>
    </Document>
  );
}

export default InresortsPDFNewOne;
