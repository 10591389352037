import { useState, useEffect, useRef } from 'react';
import { 
    Tabs, 
    Tab
} from "react-bootstrap";
import Swal from "sweetalert2";
import { 
    CurrentBonds, 
    HistoricalBonds,
    PaymentMethodBond 
} from 'components/bonus';


import AuthService from "../../services/auth.service";
import shopService from "../../services/shop.service";
import PackageEndView from 'views/shop/PackageEndView';

const API_URL = process.env.REACT_APP_APP_URL_API;


const salert = (title = "", icon = "", time = 1600, showloading = true) => {
    let timer;
    time === "infinito" ? (timer = undefined) : (timer = time);
    Swal.fire({
      icon: icon,
      title: title,
      timer: timer,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        if (showloading === true) {
          Swal.showLoading();
        } else {
          Swal.hideLoading();
        }
      },
    });
  };

export const BonusList = () => {
    const [dataBonus, setDataBonus] = useState([]);
    const [dataBonusNot, setDataBonusNot] = useState([]);
    const bondForBuy = useRef(null);
    const [bondIdForBuy, setBondIdForBuy] = useState();
    const [isComplete, setIsComplete] = useState(false);

    

    const onSelectForBuyBond = (idBond) => {
        let bond = null;
        console.log('sdsds', idBond, dataBonus)
        dataBonus.map(value => {
            if( value.packages.length > 0 ){
                value.packages.map(item => {
                    console.log('sds 6161', value.packages, item.id)
                    if( item.id == idBond ){
                        console.log('item de select para pagar', item)
                        bond = item;
                        setBondIdForBuy(item.id);
                    }
                })
            }
        })

        bondForBuy.current = bond;
    }

   const getDataBonos = async(type = true) => {
    const url = `${API_URL}/familyPackage/packageBonus/detail/version/state?value=${type}`
    await fetch(url,{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
    })
    .then((res) => res.json())
    .then((respuesta) => {
      console.log("lista de bonos,", respuesta);
      if (respuesta.status === 1) {
            setDataBonus(respuesta.objModel);

      } else {
        
            setDataBonus([]);
    
      }
    })
    .catch((error) => {
      console.log('error fetch', error)
    });
   }

   const getDataBonosHist = async() => {
    const url = `${API_URL}/Series/getHistorical`
    await fetch(url,{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
    })
    .then((res) => res.json())
    .then((respuesta) => {
      console.log("lista de bonos historical", respuesta);
      if (respuesta.status === 1) {
        setDataBonusNot(respuesta.objModel);
      } else {
        setDataBonusNot([]);
      }
    })
    .catch((error) => {
      console.log('error fetch', error)
    });
   }
   
   const buyBonusFetch = async(data) => {
        salert("Guardando información...", "info", "infinito", true);
        data.IdUserPayment = Number(AuthService.getCurrentIdUser());

        let response = await shopService.registerNewSuscription(data);
        console.log('response', response);
        Swal.close();
        if (response !== undefined && response.status === 1) {
            bondForBuy.current = null;
            setIsComplete(true);
        } else {
          salert(
            "Tuvimos problemas al registrar su compra",
            "error",
            1600,
            false
          );
        }
   }
   
   useEffect(() => {
        
    getDataBonos(true);
    getDataBonosHist();

   }, [])
   

    return (
        <div className="mt-3">
            <Tabs 
                className="custom-tabs-main" 
                defaultActiveKey="currentBonds" 
                onSelect={(key) => {
                    if( key == 'historicalBonds' ){
                        bondForBuy.current = null;
                        setBondIdForBuy(undefined);
                    }
                }}
            >
                <Tab eventKey="currentBonds" title="Bonos vigentes">
                    {
                        isComplete ? 
                            <div>
                                <PackageEndView refresh={() => {
                                    bondForBuy.current = null;
                                    setBondIdForBuy(undefined);
                                    setIsComplete(false)
                                }}></PackageEndView>
                            </div>
                        :
                        <CurrentBonds 
                            dataBonus={dataBonus}
                            bondSelectId={bondIdForBuy}
                            onSelectBond={(idBond) => onSelectForBuyBond(idBond)}
                        />
                    }
                  
                    
                </Tab>
                <Tab eventKey="historicalBonds" title="Bonos histórico" >
                    <HistoricalBonds
                        dataBonus={dataBonusNot}
                    />
                </Tab>
            </Tabs>

            {
                bondForBuy.current != null  &&
                <PaymentMethodBond 
                    bond={bondForBuy.current}
                    buyRegisterFetch={(data) => buyBonusFetch(data)}
                />
            }
        </div>
    )
}
