import React from 'react'
import { Link } from 'react-router-dom'
import './KFloat.css'
const KFloat = ({
    link,
    name,
    extra,
    onClick
}) => {
    return (
        <div className="btn-fixed-keola">
            {
                onClick ?
                    <a hrft="#" onClick={onClick}>
                        {name}
                        &nbsp;
                        {extra && <span>{extra}</span>}
                    </a>
                    :
                    <Link to={link}>
                        {name}
                        &nbsp;
                        <span>
                            {extra}
                        </span>
                    </Link>
            }
        </div>
    )
}

export default KFloat
