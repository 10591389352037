import React, { Component } from "react";
import FooterMenu from "../../components/web/FooterMenu";
import Footer from "../../components/web/Footer";
import FormPreRegister from "../../components/register/FormPreRegister";

class FormPreRegisterinView extends Component {
  render() {
    return (
      <div>
        <FormPreRegister></FormPreRegister>
        {/* <FooterMenu></FooterMenu> */}
        <Footer></Footer>
      </div>
    );
  }
}

export default FormPreRegisterinView;
