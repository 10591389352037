//import firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

//import 'firebase/messaging';

const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
}

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

function getUserAgentNavigator() {
    let sBrowser, sUsrAg = navigator.userAgent;

    let positionInicialEncontrada, positionFinalEncontrada

    if (sUsrAg.indexOf("Firefox") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("Firefox")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("SamsungBrowser")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else if (sUsrAg.indexOf("Opera") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("Opera")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else if (sUsrAg.indexOf("OPR") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("OPR")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else if (sUsrAg.indexOf("Trident") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("Trident")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else if (sUsrAg.indexOf("Edge") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("Edge")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else if (sUsrAg.indexOf("Edg") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("Edg")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("Chrome")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else if (sUsrAg.indexOf("Safari") > -1) {
        positionInicialEncontrada = sUsrAg.indexOf("Safari")
        positionFinalEncontrada = sUsrAg.indexOf(" ", positionInicialEncontrada)
        sBrowser = sUsrAg.slice(positionInicialEncontrada, positionFinalEncontrada);
    } else {
        sBrowser = "unknown";
    }

    return sBrowser
}

function changePermissionInNavigator(queryName) {
    if ('permissions' in navigator) {
        navigator.permissions.query({ name: queryName }).then(function (notificationPerm) {
            notificationPerm.onchange = function () {
                //console.log("User decided to change his seettings. New permission: " + notificationPerm.state);
                //setIsPermissionNotification(notificationPerm.state)
            };
        });
    }
}

function askNotificationPermission() {
    function checkNotificationPromise() {
        try {
            Notification.requestPermission().then();
        } catch (e) {
            return false;
        }
        return true;
    }

    if ("!Notification" in window) {
        alert("Este Navegador no soporta las notificaciones :(");
    } else {
        if (checkNotificationPromise()) {
            return Notification.requestPermission()
        } else {
            Notification.requestPermission(function () {
                alert("Aun no hay soporte en Safari con firebase");
            });
        }
    }
}

function getTokenFirebase() {
    console.log("getTokenFirebase");
    console.log(process.env.REACT_APP_FIREBASE_KEY_PUSH_WEB);
    const messaging = getMessaging(firebaseApp);
    if ("serviceWorker" in navigator) {
        console.log(navigator)
        navigator.serviceWorker.ready.then(function (getSwRegistration) {
            return getToken(messaging, { serviceWorkerRegistration: getSwRegistration, vapidKey: process.env.REACT_APP_FIREBASE_KEY_PUSH_WEB })
                .then((currentToken) => {
                    if (currentToken) {
                        let idAdminUser = sessionStorage.getItem('id');
                        const browser = getUserAgentNavigator()
                        const data = { "TokenFirebase": currentToken, "Browser": browser, "UserAdminId": parseInt(idAdminUser) }
                        console.log("xd")
                        console.log(currentToken)
                        fetch(`${API_URL}admins/devices`, {
                            method: 'POST',
                            body: JSON.stringify(data),
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(res => res.json())
                            .then(
                                (result) => {
                                    if (result.status) {
                                        //alert({ nameAlert: '😎 Detalle guardado con éxito', typeAlert: 'success' })
                                    } else {
                                        //alert({ nameAlert: `🚨 Api: ${result.description}`, typeAlert: 'error', time: 'infinito' })
                                    }
                                },
                                (error) => {
                                    //alert(error)
                                }
                            )
                    } else {
                        alert('getTokenFirebase ->No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    alert(err);
                });
        });
    }
}

function getMessagingObjectFirebase() {
    const messaging = getMessaging(firebaseApp);
    return messaging
}

function receiveMessageFirebase() {
    const messaging = getMessaging(firebaseApp);
    onMessage(messaging, (payload) => {
        console.log('Message received en primer plano. ', payload);
    });
}

function receiveMessageFirebaseBackground() {
    /*if (navigator.serviceWorker) {
        console.log("entro receiveMessageFirebaseBackground")
        navigator.serviceWorker.addEventListener('message', event => {
            console.log(`Escucho el mensaje del SW:`);
            console.log(event);
        });
        navigator.serviceWorker.ready.then(registration => {
            registration.active.postMessage("Hola Trabajador de servicio :)");
        });
    }*/
}

function deleteTokenFirebase() {
    const messaging = getMessaging(firebaseApp);
    messaging.deleteToken().then(() => {
        console.log('Token deleted.');
    }).catch((err) => {
        console.log('Unable to delete token. ', err);
    });
}

export {
    changePermissionInNavigator,
    askNotificationPermission,
    getTokenFirebase,
    getMessagingObjectFirebase,
    receiveMessageFirebaseBackground,
    receiveMessageFirebase,
    deleteTokenFirebase
};

