import React, { Component, useState } from "react";
import { ThemeProvider } from "styled-components";
import { Button, Spinner, Modal, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import ChatBot, { Loading } from "react-simple-chatbot";
import ChatBotService from "../../services/chatbot.service";
import AuthService from "../../services/auth.service";
import GenerateTicket from "../../components/chatbot/GenerateTicket";
import AffiliateDate from "../../components/chatbot/AffiliateDate";
import QuoteDescription from "./QuoteDescription";
import VerifyCoAffiliate from "./VerifyCoAffiliate";
import AvailableUser from "./AvailableUser";
import SuscriptionById from "./SuscriptionById";
import ChangeSus from "./ChangeSus";
import SuscriptionGracePeriod from "./SuscriptionGracePeriod";
import SuscriptionUpdate from "./SuscriptionUpdate";
import SuscriptionMigration from "./SuscriptionMigration";
import SuscriptionNoMigration from "./SuscriptionNoMigration";
import SuscriptionActive from "./SuscriptionActive";
import sha256 from "crypto-js/sha256";

export default class ChatBotClass extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClear = () => {
    this.setState({ clear: true }, () => {
      this.setState({ clear: false });
    });
  };

  openRequest = (e) => {
    let url = "/updateuser?";
    let urlsig = sessionStorage.getItem("id");

    let CryptoJS = require("crypto-js");
    let urlenc = CryptoJS.AES.encrypt(urlsig, "transfermem").toString();
    window.open(url + urlenc, "_blank");
  };

  render() {
    const { username, user_id } = this.props;

    let infoP = AuthService.getCurrentUserInfo();

    if (infoP != undefined) {
      localStorage.setItem("userDNI", infoP.nroDocument);
    } else {
      localStorage.setItem("userDNI", "");
    }

    var info = AuthService.getCurrentUserInfo();

    var nroDocument = "";
    if (info != undefined) {
      nroDocument = info.nroDocument;
    }

    const firstClean = (val) => {
      localStorage.setItem("userOption", "");
      localStorage.setItem("idUser", this.props.username);
      localStorage.setItem("userSponsorName", "");
      localStorage.setItem("changeUserName", "");
      localStorage.setItem("userSponsorDNI", "");
      localStorage.setItem("changeUserDNI", "");
      localStorage.setItem("changeName", "");
      localStorage.setItem("changeLastName", "");
      localStorage.setItem("changeNationality", "");
      localStorage.setItem("changeTypeOfDocument", "");
      localStorage.setItem("changeDNI", "");
      localStorage.setItem("changeDateOfBirthdate", "");
      localStorage.setItem("changeSex", "");
      localStorage.setItem("changeEmail", "");
      localStorage.setItem("changeCivilState", "");
      localStorage.setItem("changeAddress", "");
      localStorage.setItem("changeCity", "");
      localStorage.setItem("changeCountry", "");
      localStorage.setItem("changeTelef", "");
      localStorage.setItem("changeNameCo", "");
      localStorage.setItem("changeNroDocumentoCo", "");
      localStorage.setItem("changeLastNameCo", "");
      localStorage.setItem("fecha_afiliacion", "");
      localStorage.setItem("fecha_pago_ultima_cuota", "");
      localStorage.setItem("comment", "");
      localStorage.setItem("userEmail", "");
      localStorage.setItem("userDNI", nroDocument);
      localStorage.setItem("membresia_actual", "");
      localStorage.setItem("membresia_nueva", "");
      localStorage.setItem("fecha_migracion", "");
      localStorage.setItem("meses_gracia", "");
      localStorage.setItem("suscripcion", "");
      localStorage.setItem("inicialPago", "");
      localStorage.setItem("nro_cuotas", "");
      localStorage.setItem("cuota_faltante", "");
      localStorage.setItem("dni_coafiliado_eliminar", "");
      localStorage.setItem("nombre_coafiliado_eliminar", "");
      localStorage.setItem("nombre_nuevo_titular", "");
      localStorage.setItem("documento_nuevo_titular", "");
      localStorage.setItem("fecha_nacimiento_nuevo_titular", "");
      localStorage.setItem("pais_ciudad_nacionalidad_nuevo_titular", "");
      localStorage.setItem("direccion_celular_nuevo_titular", "");
      localStorage.setItem("memberSelect", "");

      return val;
    };

    const cleanCount = (val) => {
      localStorage.setItem("changeName", "");
      localStorage.setItem("changeLastName", "");
      localStorage.setItem("changeNationality", "");
      localStorage.setItem("changeTypeOfDocument", "");
      localStorage.setItem("changeDNI", "");
      localStorage.setItem("changeDateOfBirthdate", "");
      localStorage.setItem("changeSex", "");
      localStorage.setItem("changeEmail", "");
      localStorage.setItem("changeCivilState", "");
      localStorage.setItem("changeAddress", "");
      localStorage.setItem("changeCity", "");
      localStorage.setItem("changeCountry", "");
      localStorage.setItem("changeTelef", "");
      i = 0;
      return val;
    };

    const rest = (val) => {
      i--;
      return val;
    };

    var i = 0;

    var validador = "";
    var validadorUpdate = "";
    var acep = "";
    var dni = "";
    const AvailableUserForUpdate = async (value) => {
      let data = {
        nroDocument: value,
      };
      let response = await ChatBotService.getAvailableUserForUpdate(data);
      if (response != undefined) {
        if (response.objModel == 0) {
          validadorUpdate = "Puede";

          return;
        } else {
          validadorUpdate = "No Puede";

          return;
        }
      }
    };
    const avatar =
      "https://image.freepik.com/vector-gratis/perfil-avatar-hombre-icono-redondo_24640-14044.jpg";
    const theme = {
      background: "#f7f7f7",
      // fontFamily: "Roboto",
      headerBgColor: "#142d47",
      headerFontColor: "#fff",
      headerFontSize: "15px",
      botBubbleColor: "#ee7521",
      botFontColor: "#fff",
      userBubbleColor: "#8eddea",
      userFontColor: "#4a4a4a",
    };
    const emailIsValid = (email) => {
      return /\S+@\S+\.\S+/.test(email);
    };
    const steps = [
      {
        id: "1",
        delay: 2200,
        message: `Bienvenido a tu asistente virtual ${user_id}, será un gusto ayudarte.`,
        trigger: "2",
      },
      {
        id: "2",
        delay: 2000,
        message: `¿Deseas consultar datos de tu cuenta o de otro usuario?`,
        trigger: () => firstClean("3"),
      },
      {
        id: "3",
        options: [
          { value: 1, label: `Mi cuenta (${username})`, trigger: "4" },
          { value: 2, label: "Otro usuario", trigger: "5" },
        ],
      },
      {
        id: "4",
        message: "¿Genial, en que podemos ayudarte?",
        validator: () => {
          AvailableUserForUpdate(nroDocument);
          return true;
        },
        trigger: "7user",
      },
      {
        id: "5",
        message:
          "Por favor indica el número de documento de identidad del usuario",
        trigger: "5-a",
      },
      {
        id: "5-a",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Indique el documento de identidad";
          } else {
            AvailableUserForUpdate(value);
            localStorage.setItem("userDNI", value);

            dni = value;
            return true;
          }
        },
        trigger: () => (dni === nroDocument ? "dnipropio" : "5-aComponente"),
      },
      {
        id: "5-aComponente",
        asMessage: true,
        delay: 2000,
        component: <AvailableUser />,
        waitAction: true,
      },
      {
        id: "dnipropio",
        delay: 2000,
        message: "El DNI a consultar es propio, pruebe un dni diferente.",
        trigger: "5",
      },
      {
        id: "errorDNI",
        delay: 2000,
        message:
          "Hubo un error al consultar el DNI, intente de nuevo por favor",
        trigger: "5",
      },
      {
        id: "6",
        message:
          "perfecto, el número de documento es {previousValue}, en que te puedo ayudar?",
        trigger: "7other",
        end: false,
      },
      {
        id: "6no",
        message: "Lo sentimos, este usuario no existe.",
        trigger: 5,
        end: false,
      },
      {
        id: "6falseUser",
        message:
          "Lo sentimos, no se puede modificar el patrocinador porque su usuario ya ha sido validado y activado, lo cual ha generado comisiones a sus líneas ascendentes.",
        trigger: "7user",
        end: false,
      },
      {
        id: "6falseUser2",
        message:
          "Lo sentimos, no se puede modificar la membresía porque su usuario ya ha sido validado y activado, lo cual ha generado comisiones a sus líneas ascendentes.",
        trigger: "7user",
        end: false,
      },
      {
        id: "6false",
        message:
          "Lo sentimos, no se puede modificar el patrocinador de este socio porque su usuario ya ha sido validado y activado, lo cual ha generado comisiones a sus líneas ascendentes.",
        trigger: "7other",
        end: false,
      },
      {
        id: "6false2",
        message:
          "Lo sentimos, no se puede modificar la membresía de este socio porque su usuario ya ha sido validado y activado, lo cual ha generado comisiones a sus líneas ascendentes.",
        trigger: "7other",
        end: false,
      },
      {
        id: "7user",
        options: [
          {
            value: 4,
            label: "Actualizar cronogramas",
            trigger: "4a",
          },
          {
            value: 5,
            label: "Editar / Eliminar co-afiliado",
            trigger: "5aComponente",
          },
          {
            value: 6,
            label: "Traspaso de membresía",
            trigger: "6a",
          },
          {
            value: 7,
            label: "Aparezco inactivo",
            trigger: "7aComponente",
          },
          /*{
                  value: 8,
                  label: "Un socio no aparece en mi lista",
                  trigger: "8a"
                },
                {
                  value: 9,
                  label: "No se ha actualizado mi migración",
                  trigger: "9aComponente"
                },*/
          {
            value: 10,
            label: "Solicitud de periodo de gracia",
            trigger: "10aComponente",
          },
        ],
      },
      {
        id: "7other",
        options: [
          {
            value: 1,
            label: `Modificar patrocinador`,
            trigger: () =>
              validadorUpdate.toString() == "Puede" ? "1aC3" : "6false",
          },
          {
            value: 2,
            label: "Modificar membresía",
            trigger: () =>
              validadorUpdate.toString() == "Puede"
                ? "2aComponente"
                : "2aComponente",
          },
          {
            value: 3,
            label: "Corregir datos",
            trigger: "3adv",
          },
          {
            value: 4,
            label: "Actualizar cronogramas",
            trigger: "4a",
          },
          {
            value: 5,
            label: "Editar / Eliminar co-afiliado",
            trigger: "5aComponente",
          },
          {
            value: 6,
            label: "Traspaso de membresía",
            trigger: "6a",
          },
          {
            value: 7,
            label: "Aparezco inactivo",
            trigger: "7aComponente",
          },
          /*{
                  value: 8,
                  label: "Un socio no aparece en mi lista",
                  trigger: "8a"
                },
                {
                  value: 9,
                  label: "No se ha actualizado mi migración",
                  trigger: "9aComponente"
                },*/
          {
            value: 10,
            label: "Solicitud de periodo de gracia",
            trigger: "10aComponente",
          },
        ],
      },

      //FINAL FLUJO Seleccion

      {
        id: "1aC3",
        message: "Indica el DNI del patrocinador correcto",
        trigger: "1b",
      },
      {
        id: "1b",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeUserDNI", value);
            localStorage.setItem("userOption", 1);
            return true;
          } else {
            return "Indique DNI del patrocinador correcto.";
          }
        },
        trigger: "1c",
      },
      {
        id: "1c",
        message:
          "El patrocinador correcto tiene el documento de identidad: {previousValue}, indicanos también su nombre completo",
        trigger: "1d",
      },
      {
        id: "1d",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeUserName", value);
            return true;
          } else {
            return "Indique el nombre del patrocinador correcto.";
          }
        },
        trigger: "1e",
      },
      {
        id: "1e",
        message: "El patrocinador correcto es: {previousValue}, ¿es correcto?",
        trigger: "1f",
      },
      {
        id: "1f",
        options: [
          { value: 1, label: "Sí, es correcto", trigger: "final" },
          { value: 2, label: "No es correcto, corregir", trigger: "1aC3" },
        ],
      },

      //FINAL FLUJO 1

      //INICIO FLUJO 2
      {
        id: "2aComponente",
        asMessage: true,
        delay: 2000,
        component: <SuscriptionById />,
        waitAction: true,
      },
      {
        id: "2a-1",
        message: "Indica la membresía correcta",
        trigger: "2d",
      },
      {
        id: "2a",
        message:
          "Indica por favor el nombre de la membresía que deseas cambiar (actual)",
        trigger: "2b",
      },
      {
        id: "2b",
        user: true,
        validator: (value) => {
          localStorage.setItem("membresia_actual", value);
          localStorage.setItem("userOption", 2);
          return true;
        },
        trigger: "2c",
      },
      {
        id: "2c",
        message: "Indica el nombre de la membresía que deseas",
        trigger: "2d",
      },
      {
        id: "2d",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("membresia_nueva", value);
            localStorage.setItem("userOption", 2);
            return true;
          } else {
            return "Indica el nombre de la membresía que deseas.";
          }
        },
        trigger: "2aComponente-1a",
      },
      {
        id: "2aComponente-1a",
        asMessage: true,
        delay: 2000,
        component: <ChangeSus />,
        waitAction: true,
      },
      {
        id: "2e",
        message:
          `Has solicitado cambiar tu membresía ` +
          localStorage.getItem("memberSelect") +
          ` a la membresía {previousValue}, ¿es correcta esta información?`,
        trigger: "2f",
      },
      {
        id: "2f",
        options: [
          { value: 1, label: "Es correcto", trigger: "final" },
          {
            value: 2,
            label: "No es correcto, corregir",
            trigger: "2aComponente",
          },
        ],
      },

      //FINAL FLUJO 2

      {
        id: "3a",
        message:
          "Por favor indica el número de documento de identidad del socio a modificar",
        trigger: "3b",
      },
      {
        id: "3b",
        user: true,
        validator: (value) => {
          return true;
        },
        trigger: "3c",
      },
      {
        id: "3c",
        message:
          "El número de documento que ingresaste es {previousValue}, ahora por favor seleciona el dato que deseas modificar: ",
        trigger: "3adv",
      },
      {
        id: "3adv",
        message: `Selecciona los datos que quiere modificar, COMO MÁXIMO 3 DATOS.`,
        trigger: "3d",
      },
      {
        id: "3d",
        options: [
          { value: 1, label: "Nombre", trigger: "3eNombre" },
          { value: 2, label: "Apellido", trigger: "3eApellido" },
          { value: 3, label: "Nacionalidad", trigger: "3eNacionalidad" },
          {
            value: 4,
            label: "Tipo de documento de identidad (ej. DNI)",
            trigger: "3eTipodocumento",
          },
          {
            value: 5,
            label: "Número de documento de identidad",
            trigger: "3eNroDocumento",
          },
          {
            value: 6,
            label: "Fecha de nacimiento",
            trigger: "3eFechaNacimiento",
          },
          { value: 13, label: "Sexo", trigger: "3eSexo" },
          { value: 8, label: "Estado civil", trigger: "3eEstadoCivil" },
          {
            value: 7,
            label: "Correo electrónico",
            trigger: "3eCorreoElectronico",
          },
          { value: 11, label: "País", trigger: "3ePais" },
          { value: 10, label: "Ciudad", trigger: "3eCiudad" },
          { value: 9, label: "Dirección", trigger: "3eDireccion" },
          { value: 12, label: "Número de celular", trigger: "3eNroCelular" },
        ],
      },
      {
        id: "3eNombre",
        message: "Por favor indica a continuación el nuevo nombre",
        trigger: "3fNombre",
      },
      {
        id: "3fNombre",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeName", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica el nuevo nombre.";
          }
        },
        trigger: "3gNombre",
      },

      {
        id: "3eApellido",
        message: "Por favor indica a continuación el nuevo apellido",
        trigger: "3fApellido",
      },
      {
        id: "3fApellido",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeLastName", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica el nuevo apellido.";
          }
        },
        trigger: "3gApellido",
      },
      {
        id: "3eNacionalidad",
        message: "Por favor indica a continuación la nueva nacionalidad",
        trigger: "3fNacionalidad",
      },
      {
        id: "3fNacionalidad",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeNationality", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica la nueva nacionalidad.";
          }
        },
        trigger: "3gNacionalidad",
      },
      {
        id: "3eTipodocumento",
        message:
          "Por favor indica a continuación el nuevo tipo de documento (ej DNI)",
        trigger: "3fTipodocumento",
      },
      {
        id: "3fTipodocumento",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeTypeOfDocument", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica el nuevo tipo de documento.";
          }
        },
        trigger: "3gTipo",
      },
      {
        id: "3eNroDocumento",
        message:
          "Por favor indica a continuación el nuevo número del documento de identidad",
        trigger: "3fNroDocumento",
      },
      {
        id: "3fNroDocumento",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeDNI", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica el nuevo número de documento de identidad.";
          }
        },
        trigger: "3gDocumento",
      },

      {
        id: "3eFechaNacimiento",
        message: "Por favor indica a continuación la nueva fecha de nacimiento",
        trigger: "3fFechaNacimiento",
      },
      {
        id: "3fFechaNacimiento",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeDateOfBirthdate", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica la nueva fecha de nacimiento.";
          }
        },
        trigger: "3gFecha",
      },

      {
        id: "3eCorreoElectronico",
        message: "Por favor indica a continuación el nuevo correo electrónico",
        trigger: "3fCorreoElectronico",
      },
      {
        id: "3fCorreoElectronico",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeEmail", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica el nuevo correo electrónico.";
          }
        },
        trigger: "3gEmail",
      },
      {
        id: "3eSexo",
        message: "Por favor indica a continuación el nuevo sexo",
        trigger: "3fSexo",
      },
      {
        id: "3fSexo",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeSex", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica el nuevo sexo.";
          }
        },
        trigger: "3gSexo",
      },

      {
        id: "3eEstadoCivil",
        message: "Por favor indica a continuación el nuevo Estado civil",
        trigger: "3fEstadoCivil",
      },
      {
        id: "3fEstadoCivil",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeCivilState", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica el nuevo estado civil.";
          }
        },
        trigger: "3gEstado",
      },
      {
        id: "3eDireccion",
        message: "Por favor indica a continuación la nueva dirección",
        trigger: "3fDireccion",
      },
      {
        id: "3fDireccion",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeAddress", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica la nueva dirección.";
          }
        },
        trigger: "3gDireccion",
      },

      {
        id: "3eCiudad",
        message: "Por favor indica a continuación la nueva Ciudad",
        trigger: "3fCiudad",
      },
      {
        id: "3fCiudad",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeCity", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica la nueva ciudad.";
          }
        },
        trigger: "3gCiudad",
      },

      {
        id: "3ePais",
        message: "Por favor indica a continuación el nuevo Pais",
        trigger: "3fPais",
      },
      {
        id: "3fPais",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeCountry", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else {
            return "Indica el nuevo país.";
          }
        },
        trigger: "3gPais",
      },
      {
        id: "3eNroCelular",
        message: "Por favor indica a continuación el nuevo número de celular",
        trigger: "3fNroCelular",
      },
      {
        id: "3fNroCelular",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeTelef", value);
            localStorage.setItem("userOption", 3);
            i++;
            return true;
          } else if (value.includes("+")) {
            return "Solo ingrese el número de celular (9 dígitos).";
          } else {
            return "Indica el nuevo número de celular.";
          }
        },
        trigger: "3gTelefono",
      },
      {
        id: "3gNombre",
        message:
          "Has indicado el nombre {previousValue}, ¿es correcta esta información?",
        trigger: "3hNombre",
      },
      {
        id: "3gApellido",
        message:
          "Has indicado el apellido {previousValue}, ¿es correcta esta información?",
        trigger: "3hApellido",
      },
      {
        id: "3gNacionalidad",
        message:
          "Has indicado la nacionalidad {previousValue}, ¿es correcta esta información?",
        trigger: "3hNacionalidad",
      },
      {
        id: "3gTipo",
        message:
          "Has indicado el tipo de documento {previousValue}, ¿es correcta esta información?",
        trigger: "3hTipo",
      },
      {
        id: "3gDocumento",
        message:
          "Has indicado el número de documento {previousValue}, ¿es correcta esta información?",
        trigger: "3hDocumento",
      },
      {
        id: "3gFecha",
        message:
          "Has indicado la fecha de nacimiento {previousValue}, ¿es correcta esta información?",
        trigger: "3hFecha",
      },
      {
        id: "3gSexo",
        message:
          "Has indicado el sexo {previousValue}, ¿es correcta esta información?",
        trigger: "3hSexo",
      },
      {
        id: "3gEstado",
        message:
          "Has indicado el estado civil {previousValue}, ¿es correcta esta información?",
        trigger: "3hEstado",
      },
      {
        id: "3gEmail",
        message:
          "Has indicado el correo electrónico {previousValue}, ¿es correcta esta información?",
        trigger: "3hEmail",
      },
      {
        id: "3gPais",
        message:
          "Has indicado el país de residencia {previousValue}, ¿es correcta esta información?",
        trigger: "3hPais",
      },
      {
        id: "3gCiudad",
        message:
          "Has indicado la ciudad {previousValue}, ¿es correcta esta información?",
        trigger: "3hCiudad",
      },
      {
        id: "3gDireccion",
        message:
          "Has indicado la dirección {previousValue}, ¿es correcta esta información?",
        trigger: "3hDireccion",
      },
      {
        id: "3gTelefono",
        message:
          "Has indicado el número de teléfono {previousValue}, ¿es correcta esta información?",
        trigger: "3hTelefono",
      },
      {
        id: "3hNombre",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el nombre",
            trigger: () => rest("3eNombre"),
          },
        ],
      },
      {
        id: "3hApellido",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el apellido",
            trigger: () => rest("3eApellido"),
          },
        ],
      },
      {
        id: "3hNacionalidad",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir la nacionalidad",
            trigger: () => rest("3eNacionalidad"),
          },
        ],
      },
      {
        id: "3hTipo",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el tipo de documento",
            trigger: () => rest("3eTipodocumento"),
          },
        ],
      },
      {
        id: "3hDocumento",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el número de documento",
            trigger: () => rest("3eNroDocumento"),
          },
        ],
      },
      {
        id: "3hFecha",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir la fecha de nacimiento",
            trigger: () => rest("3eFechaNacimiento"),
          },
        ],
      },
      {
        id: "3hSexo",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el sexo",
            trigger: () => rest("3eSexo"),
          },
        ],
      },
      {
        id: "3hEstado",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el estado civil",
            trigger: () => rest("3eEstadoCivil"),
          },
        ],
      },
      {
        id: "3hEmail",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el correo electrónico",
            trigger: () => rest("3eCorreoElectronico"),
          },
        ],
      },
      {
        id: "3hPais",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el país de residencia",
            trigger: () => rest("3ePais"),
          },
        ],
      },
      {
        id: "3hCiudad",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir la ciudad",
            trigger: () => rest("3eCiudad"),
          },
        ],
      },
      {
        id: "3hDireccion",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir la dirección",
            trigger: () => rest("3eDireccion"),
          },
        ],
      },
      {
        id: "3hTelefono",
        options: [
          {
            value: 1,
            label: "Si, es correcto",
            trigger: () => (i < 3 ? "3i" : "3null"),
          },
          {
            value: 2,
            label: "No, deseo corregir el número de teléfono",
            trigger: () => rest("3eNroCelular"),
          },
        ],
      },
      {
        id: "3i",
        message: "¿Deseas modificar otro dato?",
        trigger: "3j",
      },
      {
        id: "3j",
        options: [
          { value: 1, label: "Si, quiero corregir otro dato", trigger: "3d" },
          {
            value: 2,
            label: "No, ya he terminado con los datos a corregir",
            trigger: "final",
          },
        ],
      },
      {
        id: "3null",
        message: "Usted ya modificó 3 datos.",
        trigger: "3optiona",
      },
      {
        id: "3optiona",
        message: "Son correctos los datos a cambiar?.",
        trigger: "3options",
      },
      {
        id: "3options",
        options: [
          { value: 1, label: "Si, son correctos.", trigger: "final" },
          {
            value: 2,
            label: "No, deseo cambiar mis datos nuevamente.",
            trigger: () => cleanCount("3adv"),
          },
        ],
      },
      {
        id: "4a",
        options: [
          {
            value: 1,
            label: "Actualizar pago de cuotas",
            trigger: "4aComponente",
          },
          { value: 2, label: "Actualizar Migración", trigger: "4a2Componente" },
        ],
      },
      {
        id: "4aComponente",
        asMessage: true,
        delay: 2000,
        component: <SuscriptionUpdate />,
        waitAction: true,
      },
      {
        id: "4aMember",
        message: "Indique la membresía a actualizar",
        trigger: "4a1aMember",
      },
      {
        id: "4a1aMember",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("suscripcion", value);
            localStorage.setItem("userOption", 4);
            return true;
          } else {
            return "Indica la membresía a actualizar.";
          }
        },
        trigger: "4a1",
      },
      {
        id: "4a1",
        message: "Indique el monto que pagó como cuota inicial.",
        trigger: "4a1a",
      },
      {
        id: "4a1a",
        user: true,
        validator: (value) => {
          if (value.length == 0) {
            return "Ingrese monto de cuota inicial.";
          } else {
            localStorage.setItem("inicialPago", value);
            localStorage.setItem("userOption", 4);
            return true;
          }
        },
        trigger: "4a1a1a",
      },
      {
        id: "4a1a1a",
        message: "Indique la cantidad de cuotas que pagó.",
        trigger: "4a1a1",
      },
      {
        id: "4a1a1",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("nro_cuotas", value);
            localStorage.setItem("userOption", 4);
            return true;
          } else {
            return "Indica la cantidad de cuotas que pagó.";
          }
        },
        trigger: "4a1b",
      },
      {
        id: "4a1b",
        message: "Indica la cuota que pagaste y no aparece en tu cronograma.",
        trigger: "4a1c",
      },
      {
        id: "4a1c",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("cuota_faltante", value);
            localStorage.setItem("userOption", 4);
            return true;
          } else {
            return "Indica la cuota que no aparece.";
          }
        },
        trigger: "final",
      },
      {
        id: "4a2Componente",
        asMessage: true,
        delay: 2000,
        component: <SuscriptionMigration />,
        waitAction: true,
      },
      {
        id: "4a2",
        message: "Indica la membresía que figura en tu Back Office: ",
        trigger: "4a2a",
      },
      {
        id: "4a2a",
        user: true,
        validator: (value) => {
          localStorage.setItem("membresia_actual", value);
          localStorage.setItem("userOption", 4);
          return true;
        },
        trigger: "4a2b",
      },
      {
        id: "4a2b",
        message: "Indica a qué membresía migraste: ",
        trigger: "4a2c",
      },
      {
        id: "4a2c",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("membresia_nueva", value);
            localStorage.setItem("userOption", 4);
            return true;
          } else {
            return "Indica a qué membresía migraste.";
          }
        },
        trigger: "4a2d",
      },
      {
        id: "4a2d",
        message: "Indica la fecha en la que solicitaste tu migración",
        trigger: "4a2e",
      },
      {
        id: "4a2e",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("fecha_migracion", value);
            localStorage.setItem("userOption", 4);
            return true;
          } else {
            return "Indica la fecha.";
          }
        },
        trigger: "final",
      },
      {
        id: "5aComponente",
        asMessage: true,
        component: <VerifyCoAffiliate />,
        waitAction: true,
      },
      {
        id: "5afalse",
        delay: 2000,
        message: "Usted no cuenta con co-afiliado.",
        trigger: "final4",
      },
      {
        id: "5a",
        message: "¿Deseas eliminar o editar? ",
        trigger: "5a1",
      },
      {
        id: "5a1",
        options: [
          { value: "Eliminar", label: "Eliminar", trigger: "5a2" },
          { value: "Editar", label: "Editar", trigger: "5b2" },
        ],
      },
      {
        id: "5a2",
        message: "Indica los datos de tu co-afiliado (DNI, Nombre)",
        trigger: "5a3",
      },
      {
        id: "5a3",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("nombre_coafiliado_eliminar", value);
            localStorage.setItem("userOption", 5);
            return true;
          } else {
            return "Indica los datos.";
          }
        },
        trigger: "5a4",
      },
      {
        id: "5a4",
        message: "Indica el motivo de la eliminación: ",
        trigger: "5a5",
      },
      {
        id: "5a5",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("motivo_eliminacion_coafiliado", value);
            return true;
          } else {
            return "Indica el motivo.";
          }
        },
        trigger: "final",
      },
      {
        id: "5b2",
        message: "Indica los datos a corregir del co-afiliado: ",
        trigger: "5b3",
      },
      {
        id: "5b3",
        options: [
          { value: "Nombre", label: "Nombre", trigger: "3CoNombre" },
          { value: "Apellidos", label: "Apellidos", trigger: "3CoApellido" },
          {
            value: "NroDocumento",
            label: "Número de Documento",
            trigger: "3CoNroDocumento",
          },
        ],
      },
      {
        id: "3CoNombre",
        message:
          "Por favor indica a continuación el nuevo nombre del co-afiliado",
        trigger: "3CofNombre",
      },
      {
        id: "3CofNombre",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeNameCo", value);
            localStorage.setItem("userOption", 5);
            return true;
          } else {
            return "Indica el nuevo nombre.";
          }
        },
        trigger: "3gNombreCo",
      },
      {
        id: "3gNombreCo",
        message:
          "Has indicado el nombre {previousValue}, ¿es correcta esta información?",
        trigger: "3hNombreCo",
      },
      {
        id: "3hNombreCo",
        options: [
          { value: 1, label: "Si, es correcto", trigger: "3iCo" },
          {
            value: 2,
            label: "No, deseo corregir el nombre",
            trigger: "3CoNombre",
          },
        ],
      },
      {
        id: "3CoApellido",
        message: "Por favor indica a continuación el nuevo apellido",
        trigger: "3CofApellido",
      },
      {
        id: "3CofApellido",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeLastNameCo", value);
            localStorage.setItem("userOption", 5);
            return true;
          } else {
            return "Indica el nuevo apellido.";
          }
        },
        trigger: "3gApellidoCo",
      },
      {
        id: "3gApellidoCo",
        message:
          "Has indicado el apellido {previousValue}, ¿es correcta esta información?",
        trigger: "3hApellidoCo",
      },
      {
        id: "3hApellidoCo",
        options: [
          { value: 1, label: "Si, es correcto", trigger: "3iCo" },
          {
            value: 2,
            label: "No, deseo corregir el apellido",
            trigger: "3CoApellido",
          },
        ],
      },
      {
        id: "3CoNroDocumento",
        message: "Por favor indica a continuación el nuevo número de documento",
        trigger: "3CofNroDocumento",
      },
      {
        id: "3CofNroDocumento",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeNroDocumentoCo", value);
            localStorage.setItem("userOption", 5);
            return true;
          } else {
            return "Indica el nuevo número de documento.";
          }
        },
        trigger: "3gNroDocumentoCo",
      },
      {
        id: "3gNroDocumentoCo",
        message:
          "Has indicado el número de documento {previousValue}, ¿es correcta esta información?",
        trigger: "3hNroDocumentoCo",
      },
      {
        id: "3hNroDocumentoCo",
        options: [
          { value: 1, label: "Si, es correcto", trigger: "3iCo" },
          {
            value: 2,
            label: "No, deseo corregir el número de documento",
            trigger: "3CoNroDocumento",
          },
        ],
      },
      {
        id: "3iCo",
        message: "¿Deseas modificar otro dato?",
        trigger: "3jCo",
      },
      {
        id: "3jCo",
        options: [
          { value: 1, label: "Si, quiero corregir otro dato", trigger: "5b3" },
          {
            value: 2,
            label: "No, ya he terminado con los datos a corregir",
            trigger: "final",
          },
        ],
      },
      {
        id: "6a",
        asMessage: true,
        delay: 3000,
        component: (
          <div>
            Complete este formulario para traspaso de membresía haciendo{" "}
            <Button
              variant="primary"
              style={{ height: "20px" }}
              onClick={(e) => this.openRequest(e)}
              style={{ color: "#FFFF" }}
            >
              <a>CLICK AQUÍ</a>
            </Button>
          </div>
        ),
        trigger: "final2",
      },
      {
        id: "6aa",
        message:
          "Indica el número de documento de identidad de la persona a la que deseas traspasar la membresía: ",
        trigger: "6a1",
      },
      {
        id: "6a1",
        user: true,
        validator: (value) => {
          localStorage.setItem("changeUserDNI", value);
          localStorage.setItem("userOption", 6);
          return true;
        },
        trigger: "6a2",
      },
      {
        id: "6a2",
        message: "Indica la membresía actual: ",
        trigger: "6a3",
      },
      {
        id: "6a3",
        user: true,
        validator: (value) => {
          localStorage.setItem("membresia_actual", value);
          return true;
        },
        trigger: "6a5",
      },
      {
        id: "6a5",
        message: "Indica nombres y apellidos completos del nuevo titular",
        trigger: "6a6",
      },
      {
        id: "6a6",
        user: true,
        validator: (value) => {
          localStorage.setItem("nombre_nuevo_titular", value);
          return true;
        },
        trigger: "6a7",
      },
      {
        id: "6a7",
        message:
          "Indica el tipo y número de documento de identidad del nuevo titular: ",
        trigger: "6a8",
      },
      {
        id: "6a8",
        user: true,
        validator: (value) => {
          localStorage.setItem("documento_nuevo_titular", value);
          return true;
        },
        trigger: "6a9",
      },
      {
        id: "6a9",
        message: "Indica la fecha de nacimiento del nuevo titular: ",
        trigger: "6a10",
      },
      {
        id: "6a10",
        user: true,
        validator: (value) => {
          localStorage.setItem("fecha_nacimiento_nuevo_titular", value);
          return true;
        },
        trigger: "6a11",
      },
      {
        id: "6a11",
        message:
          "Indique por favor la Nacionalidad, País y Ciudad de residencia (separados por comas)",
        trigger: "6a12",
      },
      {
        id: "6a12",
        user: true,
        validator: (value) => {
          localStorage.setItem("pais_ciudad_nacionalidad_nuevo_titular", value);
          return true;
        },
        trigger: "6a13",
      },
      {
        id: "6a13",
        message: "Indica la dirección y número de celular del nuevo titular: ",
        trigger: "6a14",
      },
      {
        id: "6a14",
        user: true,
        validator: (value) => {
          localStorage.setItem("direccion_celular_nuevo_titular", value);
          return true;
        },
        trigger: "final",
      },
      /*{
              id: "7a",
              message: "Indica por favor la fecha de tu afiliación:",
              trigger: "7b"
            },*/
      {
        id: "7aComponente",
        asMessage: true,
        delay: 3000,
        component: <SuscriptionActive />,
        waitAction: true,
      },
      {
        id: "7a",
        asMessage: true,
        delay: 3000,
        component: <AffiliateDate />,
        waitAction: true,
      },
      {
        id: "7b",
        message: "¿Es correcto?",
        trigger: "7c",
      },
      {
        id: "7c",
        options: [
          { value: 1, label: "Si", trigger: "7c1" },
          { value: 2, label: "No", trigger: "7c2" },
        ],
      },
      {
        id: "7c1",
        asMessage: true,
        delay: 1500,
        component: <QuoteDescription />,
        waitAction: true,
      },
      {
        id: "7c1a",
        delay: 3000,
        message:
          'Si has pagado esa cuota y no se ve en tu cronograma intenta subirla nuevamente, si el error persiste ingresa tu solicitud en la opción "Actualizar Cronogramas"',
        trigger: "final4a1",
      },
      {
        id: "7c2",
        message: "Indica la fecha de pago de tu última cuota: ",
        trigger: "7d",
      },
      /*{
              id: "7b",
              user: true,
              validator: (value) => {
                localStorage.setItem("fecha_afiliacion", value);
                localStorage.setItem("userOption", 7);
                return true;
              },
              trigger: "7c"
            },*/
      /*{
              id: "7c",
              message: "Indica la fecha de pago de tu última cuota:",
              trigger: "7d"
            },*/
      {
        id: "7d",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("fecha_pago_ultima_cuota", value);
            localStorage.setItem("userOption", 7);
            return true;
          } else {
            return "Indica fecha de pago de tu última cuota.";
          }
        },
        trigger: "final",
      },
      {
        id: "8a",
        message:
          "Indica por favor el documento de identidad del socio que no figura en tu lista: ",
        trigger: "8b",
      },
      {
        id: "8b",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeUserDNI", value);
            localStorage.setItem("userOption", 8);
            return true;
          } else {
            return "Indica el documento de identidad.";
          }
        },
        trigger: "8c",
      },
      {
        id: "8c",
        message: "Indica el nombre completo del socio: ",
        trigger: "8d",
      },
      {
        id: "8d",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("changeUserName", value);
            return true;
          } else {
            return "Indica el nombre completo.";
          }
        },
        trigger: "8e",
      },
      {
        id: "8e",
        message: "Indica la fecha en la que inscribiste al socio: ",
        trigger: "8f",
      },
      {
        id: "8f",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("fecha_afiliacion", value);
            return true;
          } else {
            return "Indica la fecha.";
          }
        },
        trigger: "final",
      },
      {
        id: "9aComponente",
        asMessage: true,
        delay: 2000,
        component: <SuscriptionNoMigration />,
        waitAction: true,
      },
      {
        id: "9a",
        message: "Indica por favor tu membresía actual: ",
        trigger: "9b",
      },
      {
        id: "9b",
        user: true,
        validator: (value) => {
          localStorage.setItem("membresia_actual", value);
          localStorage.setItem("userOption", 9);
          return true;
        },
        trigger: "9c",
      },
      {
        id: "9c",
        message: "¿A qué membresía has solicitado migrar?",
        trigger: "9d",
      },
      {
        id: "9d",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("membresia_nueva", value);
            localStorage.setItem("userOption", 9);
            return true;
          } else {
            return "Indica la membresía que solicitaste.";
          }
        },
        trigger: "9e",
      },
      {
        id: "9e",
        message: "Indica la fecha de solicitud de migración",
        trigger: "9f",
      },
      {
        id: "9f",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("fecha_migracion", value);
            localStorage.setItem("userOption", 9);
            return true;
          } else {
            return "Indica la fecha.";
          }
        },
        trigger: "final",
      },
      {
        id: "10aComponente",
        asMessage: true,
        delay: 2000,
        component: <SuscriptionGracePeriod />,
        waitAction: true,
      },
      {
        id: "10a",
        message: "Indica tu membresía actual: ",
        trigger: "10b",
      },
      {
        id: "10b",
        user: true,
        validator: (value) => {
          localStorage.setItem("membresia_actual", value);
          return true;
        },
        trigger: "10c",
      },
      {
        id: "10c",
        message: "Indica tu última fecha de pago",
        trigger: "10d",
      },
      {
        id: "10d",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            localStorage.setItem("fecha_pago_ultima_cuota", value);
            return true;
          } else {
            return "Indica la última fecha de pago.";
          }
        },
        trigger: "10e",
      },
      {
        id: "10e",
        message: "¿A cuantos meses deseas solicitar el periodo de gracia?",
        trigger: "10f",
      },
      {
        id: "10f",
        options: [
          { value: 1, label: "1 mes", trigger: "10fa1" },
          { value: 2, label: "2 meses", trigger: "10fa2" },
          { value: 3, label: "3 meses", trigger: "10fa3" },
        ],
      },
      {
        id: "10fa1",
        message:
          "Has solicitado 1 mes de gracia, escribe la palabra ´CORRECTO´ para confirmar",
        trigger: "10fa1-2",
      },
      {
        id: "10fa1-2",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            acep = value;
            localStorage.setItem("meses_gracia", "1");
            localStorage.setItem("userOption", 10);
            return true;
          } else {
            return "Ingrese una palabra.";
          }
        },
        trigger: () =>
          acep.toString() == "CORRECTO" ||
          acep.toString() == "Correcto" ||
          acep.toString() == "correcto"
            ? "final"
            : "acepNo",
      },
      {
        id: "acepNo",
        message: "Palabra incorrecta, desea continuar con la solicitud?",
        trigger: "acepNoa",
      },
      {
        id: "acepNoa",
        options: [
          { value: 1, label: "Sí", trigger: "10e" },
          { value: 2, label: "No, cerrar", end: true },
        ],
      },
      {
        id: "10fa2",
        message:
          "Has solicitado 2 meses de gracia, recuerda que para este periodo se cobrará una comisión, escribe la palabra ´CORRECTO´ para confirmar",
        trigger: "10fa2-2",
      },
      {
        id: "10fa2-2",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            acep = value;
            localStorage.setItem("meses_gracia", "2");
            localStorage.setItem("userOption", 10);
            return true;
          } else {
            return "Ingrese una palabra.";
          }
        },
        trigger: () =>
          acep.toString() == "CORRECTO" ||
          acep.toString() == "Correcto" ||
          acep.toString() == "correcto"
            ? "final"
            : "acepNo",
      },
      {
        id: "10fa3",
        message:
          "Has solicitado 3 meses de gracia, recuerda que para este periodo se cobrará una comisión, escribe la palabra ´CORRECTO´ para confirmar",
        trigger: "10fa3-2",
      },
      {
        id: "10fa3-2",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            acep = value;
            localStorage.setItem("meses_gracia", "3");
            localStorage.setItem("userOption", 10);
            return true;
          } else {
            return "Ingrese una palabra.";
          }
        },
        trigger: () =>
          acep.toString() == "CORRECTO" ||
          acep.toString() == "Correcto" ||
          acep.toString() == "correcto"
            ? "final"
            : "acepNo",
      },

      {
        id: 12,
        options: [
          /* eslint-disable */
          {
            value: 1,
            label: "Datos correctos, enviar",
            trigger: "final",
          },
          { value: 2, label: "Datos incorrectos, reiniciar", trigger: "1" },
        ],
      },
      {
        id: "final",
        message: "¿Deseas agregar una nota adicional a tu solicitud?",
        trigger: "final-note",
      },
      {
        id: "final-note",
        options: [
          { value: 1, label: "Si", trigger: "finalSendUser" },
          { value: 2, label: "no, no es necesario", trigger: "finalA" },
        ],
      },
      {
        id: "finalSendUser",
        user: true,
        validator: (value) => {
          if (value.length > 0) {
            if (value.length > 200) {
              return "El contenido de la excede los 200 caracteres, por favor escribe una nota más corta";
            } else {
              localStorage.setItem("comment", value);
              return true;
            }
          } else {
            return "Ingrese una nota.";
          }
        },
        trigger: "finalA",
      },
      {
        id: "finalSend",
        asMessage: true,
        delay: 3000,
        component: <GenerateTicket username={username} />,
        waitAction: true,
        trigger: "final2",
      },
      {
        id: "finalA",
        message:
          "Por favor indica a que correo deseas recibir la respuesta de tu solicitud",
        trigger: "finalA2",
      },
      {
        id: "finalA2",
        user: true,
        validator: (value) => {
          if (emailIsValid(value)) {
            localStorage.setItem("userEmail", value);
            return true;
          } else {
            return "Correo incorrecto, por favor verifica";
          }
        },
        trigger: "finalSend",
      },
      {
        id: "final2",
        delay: 5000,
        message: `Recuerda que los datos deben ser correctos para poder proceder correctamente con tu solicitud. ¿Deseas realizar alguna otra consulta?`,
        trigger: "final3",
      },
      {
        id: "final3",
        options: [
          {
            value: 1,
            label: "Si, deseo realizar otra solicitud",
            trigger: "2",
          },
          { value: 2, label: "No, ya he terminado", trigger: "final4" },
        ],
      },
      {
        id: "final4",
        delay: 2500,
        message:
          "Gracias por usar nuestro asistente virtual, esperamos haberte ayudado.",
        trigger: "final5",
      },
      {
        id: "final4a1",
        delay: 5000,
        message:
          "Gracias por usar nuestro asistente virtual, esperamos haberte ayudado.",
        trigger: "final5",
      },
      {
        id: "final5",
        options: [
          {
            value: 1,
            label: "si tienes alguna otra consulta, click aquí",
            trigger: "2",
          },
          {
            value: 2,
            label: "Cerrar",
            end: true,
          },
        ],
      },
    ];

    return this.state.clear ? (
      <div />
    ) : (
      <div>
        <ThemeProvider theme={theme}>
          {/* <ChatBot
            handleEnd={this.handleClear}
            floating={true}
            botAvatar={avatar}
            headerTitle={"Asistente Inclub"}
            botDelay={1100}
            steps={steps}
          /> */}
        </ThemeProvider>
      </div>
    );
  }
}
