import React, { Component } from "react";
import {
  Form,
  Tabs,
  Tab,
  Row,
  Modal,
  Button,
  Spinner,
  Card,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Movement from "./wallet/Movement";
import Transfer from "./wallet/Transfer";
import TransferWallet from "./wallet/TransferWallet";
import ReportProblem from "./wallet/ReportProblem";
import TransferAccount from "./wallet/TransferAccount";

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import WalletService from "../../services/wallet.service";

import { IoMdAlert } from "react-icons/io";
import TransferBrandExclusive from "./wallet/TransferBrandExclusive";

export default class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReport: false,
      accountBalance: 0,
      availableBalance: 0,
      registers: [],
      idWallet: 0,
      idUser: AuthService.getCurrentIdUser(),
      loadingWallet: false,
      estado: "",
      isActive: false,
      activeMessage: "",
      loadingState: false,
      availableBrandExclusive: 0,
      accountingBrandExclusive: 0,
    };
    // this.getWalletInformation = this.getWalletInformation.bind(this);
  }

  componentDidMount() {
    // Get amount available
    this.getWalletInformation();
    // Verify state
    this.getState();
  }
  getState = async () => {
    this.setState({
      loadingState: true,
      activeMessage: "Cargando información...",
    });
    let state = await UserService.getInfoState(this.state.idUser);
    if (state !== undefined && state !== null) {
      if (state.status !== 1) {
        this.setState({
          loadingState: false,
          activeMessage: state.description,
          isActive: false,
        });
      } else {
        if (state.objModel !== null) {
          this.setState({
            loadingState: false,
            activeMessage: `Su estado es ${state.objModel.stateUserName}. Solo los usuarios ACTIVOS puedes
                realizar transferencias a otros usuarios.`,
            isActive: state.objModel.stateUser === 1 ? true : false,
          });
        } else {
          this.setState({
            loadingState: false,
            activeMessage: "Ocurrió un error al consultar servicio.",
            isActive: false,
          });
        }
      }
    } else {
      this.setState({
        loadingState: false,
        activeMessage: "Ocurrió un error al consultar servicio.",
        isActive: false,
      });
    }
  };

  getWalletInformation = async () => {
    this.setState({
      loadingWallet: (this.state.loadingWallet = true),
    });

    let response = await WalletService.getWalletInformation(this.state.idUser);
    if (response !== undefined && response.status === 1) {
      // console.log("haber response", response);
      this.setState({
        idWallet: (this.state.idWallet = response.objModel.idWallet),
        accountBalance: (this.state.accountBalance =
          response.objModel.accountingBalance),
        availableBalance: (this.state.availableBalance =
          response.objModel.availableBalance),
        registers: (this.state.registers =
          response.objModel.walletTransactions),
        loadingWallet: (this.state.loadingWallet = false),
        // marcas exclusivas
        availableBrandExclusive: (this.state.availableBrandExclusive =
          response.objModel.availableBrandExclusive),
        accountingBrandExclusive: (this.state.accountingBrandExclusive =
          response.objModel.accountingBrandExclusive),
      });
    } else {
      this.setState({
        accountBalance: "No se puede mostrar informamación",
        availableBalance: "No se puede mostrar informamación",
        registers: [],
        loadingWallet: (this.state.loadingWallet = false),
      });
    }
  };

  updateWallet = async () => {
    // call services
    this.setState({
      loadingWallet: (this.state.loadingWallet = true),
    });
    this.getWalletInformation();
  };

  // Handle modal report problem
  handleClose = (e) => {
    this.setState({
      showReport: false,
    });
  };
  handleShow = (e) => {
    e.preventDefault();
    this.setState({
      showReport: true,
    });
  };
  sendReport = (e) => {
    alert("reportar problema");
  };

  render() {
    const {
      availableBalance,
      accountBalance,
      registers,
      idWallet,
      idUser,
      loadingWallet,
      estado,
      isActive,
      loadingState,
      activeMessage,
      availableBrandExclusive,
      accountingBrandExclusive,
    } = this.state;

    return (
      <div style={{ padding: 30 }}>
        <div className="card-wallet">
          {loadingWallet && (
            <div>
              <Spinner animation="border" variant="dark" size="sm"></Spinner>
              <p>Cargando saldo de wallet...</p>
            </div>
          )}
          {!loadingWallet && (
            <>
            <p className="title-wallet">
              Saldo disponible: $ {availableBalance} | Saldo contable: ${" "}
              {accountBalance}
            </p>
            <p className="title-wallet">
              Saldo disponible marcas exclusivas: $ {availableBrandExclusive} | Saldo contable marcas exclusivas: ${" "}
              {accountingBrandExclusive}
            </p>
            </>
            
          )}
          <p style={{ color: "green", fontSize: 12 }}>
            Nota: Los montos están en dólares.
          </p>
          <Button
            variant="light"
            size="sm"
            onClick={(e) => {
              this.updateWallet();
            }}
            style={{
              color: "#142d47",
              fontWeight: "bold",
              background: "#c1c1c1",
            }}
          >
            Actualizar saldo
          </Button>

          <div>
            <a
              onClick={(e) => {
                this.handleShow(e);
              }}
            >
              <p className="text-problem">
                <IoMdAlert size={16} color={"#d62323"} />
                Reportar problema
              </p>
            </a>
          </div>
        </div>

        <Tabs className="custom-tabs-main" defaultActiveKey="movement">
          <Tab eventKey="movement" title="Movimientos">
            {idWallet > 0 && <Movement id={idWallet}></Movement>}
          </Tab>
          <Tab eventKey="tranferWalltet" title="Transferencia entre Wallet">
            {!loadingState && isActive && (
              <TransferWallet
                updateBalance={this.getWalletInformation}
              ></TransferWallet>
            )}
            {!loadingState && !isActive && (
              <Card>
                <Card.Body>
                  <Form.Label>{activeMessage}</Form.Label>
                </Card.Body>
              </Card>
            )}
            {loadingState && (
              <div>
                <Spinner animation="border" variant="dark"></Spinner>
                <p>{activeMessage}</p>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="tranferElectronic"
            title="Transferencia a Billetera electrónica"
          >
            <Transfer idUser={idUser}></Transfer>
          </Tab>
          <Tab
            eventKey="tranferAccount"
            title="Transferencia a Cuenta bancaria"
          >
            <TransferAccount idUser={idUser}></TransferAccount>
          </Tab>
          <Tab  
            eventKey="tranferBrandExclusive"
            title="Transferencia a marcas exclusivas"
          >
            <TransferBrandExclusive idUser={idUser}></TransferBrandExclusive>
          </Tab>
        </Tabs>

        {/* Modal report problem */}
        <Modal
          show={this.state.showReport}
          onHide={this.handleClose}
          style={{ fontSize: 12 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reportar problema</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReportProblem></ReportProblem>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              size="sm"
              onClick={(e) => this.sendReport(e)}
            >
              Reportar
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={(e) => this.handleClose(e)}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
