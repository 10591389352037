import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import ChatBot, { Loading } from 'react-simple-chatbot';
import ChatBotService from "../../services/chatbot.service";
import Validation from "../utils/Validation";

export default class QuoteDescriptionGeneral extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: true,
        result: '',
    };
  }

  componentDidMount(){
    this.QuoteDescriptionGeneral();
  }

  async QuoteDescriptionGeneral(){

    let data = {
        nroDocument: localStorage.getItem("userDNI")
    }

    ;

    let response = await ChatBotService.DateAffiliation(data);
    
    if(response != undefined){
        if(response.status == 1){
            if(response.objModel[0].quoteDescription != null){
                this.setState({
                    loading: false,
                    result: 'Apareces inactivo porque ya venció tu ' + response.objModel[0].quoteDescription
                    })
                this.triggertNext();
            }else{
                this.setState({
                    loading: false,
                    result: 'Estás al dia en tus pagos.'
                    })
                this.triggertNextError();
        }
        }
    }

    /// end
  }

  triggertNext() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "7c1a"});
    });
  }

  triggertNextError() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "final4"});
    });
  }


  render() {
      const {loading, result} = this.state;

    return (
      <div className="dbpedia" style={{color: '#fff'}}>
        { loading ? <Loading /> : result }

      </div>
    );
  }

}