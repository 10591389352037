import CalendarEvents from "../../components/events/CalendarEvents";
import FooterMenu from "../../components/web/FooterMenu";
import Footer from "../../components/web/Footer";

export default function CalendarEventsView() {
  return (
    <div>
      <CalendarEvents />
      <main id="main">{/* <FooterMenu/> */}</main>
      <Footer></Footer>
    </div>
  );
}
