import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import ChatBot, { Loading } from 'react-simple-chatbot';
import ChatBotService from "../../services/chatbot.service";
import Validation from "../utils/Validation";

export default class GetLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: true,
        error: true,
        result: '',
    };
  }

  componentDidMount(){
    this.GetLink();
  }

  async GetLink(){

    let id = this.props.id;
    

    let response = await ChatBotService.getUrl(id);

    if(response != undefined){
                this.setState({
                    error: true,
                    loading: false,
                    result: response[0].link
                    })
                this.triggertNext();
    }else{
                this.setState({
                    error: false,
                    loading: true,
                    result: 'Ocurrio un error al obtener el link del video.'
                    })
                this.triggertNextError();
        }
        
    

    /// end
  }

  triggertNext() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "2a7"});
    });
  }

  triggertNextError() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "2"});
    });
  }


  render() {
      const {loading, result, error} = this.state;

    return (
      <div className="dbpedia" style={{color: '#fff'}}>
        { !loading &&
            <a href={result} target="_blank" style={{color: '#fff'}}>Video Tutorial Click Aqui</a>
            }

        { !error &&
            <p style={{color: '#fff'}}>{result}</p>
            }

      </div>
    );
  }

}