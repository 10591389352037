import { useState,useEffect} from 'react'
import "./Pagination.css"

export default function Pagination({nroPage:currentpage,setnroPage:setcurrentpage,isloadedapidata,totalPages}) {

    const [objarraypag, setobjarraypag] = useState([])
    const [pageinitial, setpageinitial]= useState(1)
    const [pagefin, setpagefin]= useState(0)

    useEffect(()=>{

            if (totalPages<6) setpagefin(totalPages)
            else setpagefin(5)

    },[totalPages])

    useEffect(() => {
        let isactive=false;
        let objarray=[];
        for (let i=pageinitial;i<=pagefin;i++){
            if (i===currentpage) isactive=true;
            else isactive=false;
            objarray.push(Object.assign({},{
                pag: i,
                isactive: isactive
            }));
        }
        setobjarraypag(objarray)



    }, [currentpage,pageinitial,pagefin])


    const changepage=(pagetogo)=>{

        setcurrentpage(pagetogo)

        if (pagetogo<6){
            if (totalPages>5){
                setpageinitial(1)
                setpagefin(5)
            }
            else{
                setpageinitial(1)
                setpagefin(totalPages)
            }
        }
        else{
            if (pagetogo+2<=totalPages){
                setpageinitial(pagetogo-2)
                setpagefin(pagetogo+2)
            }
            else{
                setpageinitial(pagetogo-3)
                setpagefin(totalPages)
            }
        }
    }
    const nextpage=()=>{
        window.scroll(0, 0)
        changepage(currentpage+1);
    }
    const prevpage=()=>{
        window.scroll(0, 0)
        changepage(currentpage-1)
    }
    const gotopage=(item)=>{
        window.scroll(0, 0)
        changepage(item)
    }
    return (
        // PAGINADO INICIAL
        <div>
        {(currentpage>0 && isloadedapidata===true)&&(
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
                {(totalPages > 6 && currentpage>4) &&(
                    <li className="page-item"><a className="page-link" onClick={()=>gotopage(1)}>{"<<"}</a></li>
                )}
                {(totalPages>0 && currentpage>1) &&(
                    <li className="page-item" disabled={currentpage===1?true:false}><a className="page-link" onClick={currentpage!==1?()=>prevpage():null}>{"<"}</a></li>
                )}
                {objarraypag.map((item,id)=>(
                    <li key={id} className={item.isactive===true?"page-item active":"page-item"}><a className="page-link" onClick={currentpage!==item.pag?()=>gotopage(item.pag):null}>{item.pag}</a></li>
                ))}
                {(totalPages>0 && currentpage<totalPages) &&(
                    <li className="page-item " disabled={currentpage>=totalPages?true:false}><a className="page-link" onClick={currentpage<totalPages?()=>nextpage():null}>{">"}</a></li>
                )}
                {(totalPages > 6 && currentpage<totalPages-2)&&(
                    <li className="page-item"><a className="page-link" onClick={()=>gotopage(totalPages)}>{">>"}</a></li>
                )}
            </ul>
        </nav>
        )}
        </div>
    )
}
