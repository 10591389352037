import jwt_decode from "jwt-decode";
import history from "../views/navigation/history";
import Swal from "sweetalert2";
const API_URL = process.env.REACT_APP_APP_URL_API;
const API_USR = `${process.env.REACT_APP_APP_URL_API}/User`;
//https://servicios.inclubtest.online:2053/api
console.log(API_URL);
class AuthService {
  // Login
  async login(data) {
    let url = API_URL + "/token";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      // headers: headers
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.access_Token !== null) {
          // Decoder token information
          console.log('response ===>',response)
          localStorage.setItem('usuarioData',JSON.stringify(response.userDataUpdate))
          localStorage.setItem('temporal',JSON.stringify(true))
          let decode = jwt_decode(response.access_Token);

          let idUser = decode.primarysid;
          // Saveinformation
          sessionStorage.setItem("id", idUser);
          sessionStorage.setItem("requestDataUpdate", response.requestDataUpdate);
          sessionStorage.setItem("user", JSON.stringify(response));
          sessionStorage.setItem("islogged", true);
          sessionStorage.setItem("key", data.password);
          sessionStorage.setItem("name", decode.unique_name);

          sessionStorage.setItem("sessiontoken", response.dateExpire);
          let userInfo = this.getUserInformation(idUser);
          //let pictureInfo = this.getProfilePicture(idUser);
          userInfo.then((response) => {
            if (response.status === 1) {
              if (response.objModel.loginname === response.objModel.username) {
                Swal.fire({
                  icon: "info",
                  title: "Información",
                  text:
                    "Por favor cambie su nombre de inicio de sesion en datos de la cuenta.",
                  confirmButtonColor: "#f0692c",
                }).then((result) => {
                  if (result.value) {
                    history.push("/profile");
                  }
                });
              }
            }
          });

          let url = API_USR + "/profilePicture/" + idUser;

          return fetch(url)
            .then((res) => res.json())
            .then((response) => {
              if (response.status === 1) {
                let picture = "data:image/png;base64," + response.objModel;

                sessionStorage.setItem("pictureProfile", picture);
              }

              return response;
            })
            .catch((error) => {
              return undefined;
            });
        }
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Refresh token
  async refreshtoken() {
    const user = sessionStorage.getItem("user");
    let data = {
      id: Number(this.getCurrentIdUser()),
      code: user.accessToken,
    };

    let url = API_URL + "/token/generate/new";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      // headers: headers
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        //;
        if (response.access_Token !== null) {
          // Decoder token information
          let decode = jwt_decode(response.access_Token);
          let idUser = decode.primarysid;
          // Saveinformation
          sessionStorage.setItem("id", idUser);
          sessionStorage.setItem("islogged", true);
          sessionStorage.setItem("name", decode.unique_name);

          sessionStorage.setItem("sessiontoken", response.dateExpire);
        }
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  // Login for admin see others profile
  async loginAdmin(data) {
    let url = API_URL + "/token/admin";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      // headers: headers
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        //;
        if (response.access_Token !== null) {
          // Decoder token information
          let decode = jwt_decode(response.access_Token);
          response.idUser = decode.primarysid;
        }
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async updatePassword(objUser) {
    let url_update = API_USR + "/" + "modifypassword";
    let currentUser = this.getCurrentUser();
    let currentInfo = this.getCurrentUserInfo();
    let data = {};
    let user = {};
    user.username = currentInfo.username;
    user.password = objUser.password;
    data.User = user;
    data.newPassword = objUser.newpassword;
    let u = {};
    u.p = "f";
    u.user = undefined;

    return await fetch(url_update, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUser.access_Token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          sessionStorage.setItem("key", data.newPassword);
        }
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getProfilePicture(idUser) {
    let url = API_USR + "/profilePicture/" + idUser;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          let picture = "data:image/png;base64," + response.objModel;

          sessionStorage.setItem("pictureProfile", picture);
        }

        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  getUserInformation(idUser) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (user !== null && user !== undefined) {
      let url_info = API_USR + "/" + idUser;
      return fetch(url_info)
        .then((res) => res.json())
        .then((response) => {
          sessionStorage.setItem("info", JSON.stringify(response.objModel));
          console.log(response.objModel);
          return response;
        })
        .catch((error) => {
          sessionStorage.setItem("info", "");
          return undefined;
        });
    } else {
      sessionStorage.setItem("info", "");
      return undefined;
    }
  }

  async getUserInformationAsync(idUser) {
    let url_info = API_USR + "/" + idUser;
    return await fetch(url_info)
      .then((res) => res.json())
      .then((response) => {
        sessionStorage.setItem("info", JSON.stringify(response.objModel));
        sessionStorage.setItem("id", idUser);
        return response;
      })
      .catch((error) => {
        sessionStorage.setItem("info", "");
        return undefined;
      });
  }

  async executeRecoveryPass(token) {
    console.log("haber token", token);
    let url = API_URL + "/User/ExecuteRecovery/?token=" + token;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("haber error", error);
        return undefined;
      });
  }

  logout() {
    sessionStorage.clear();
    history.push("/home");
  }

  getCurrentKey() {
    return sessionStorage.getItem("key");
  }

  getCurrentIdUser() {
    return sessionStorage.getItem("id");
  }

  getCurrentUserInfo() {
    if (sessionStorage.length > 0 && sessionStorage.getItem("info") !== null) {
      if (sessionStorage.getItem("info").length > 0) {
        return JSON.parse(sessionStorage.getItem("info"));
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem("user"));
  }

  getName() {
    return sessionStorage.getItem("name");
  }

  getPicture() {
    return sessionStorage.getItem("pictureProfile");
  }

  getIsLogged() {
    if (sessionStorage.getItem("user")) {
      return true;
    }
    return false;
  }
}

export default new AuthService();
