import KFloat from 'components/KFloat';
import KHeader from 'components/KHeader';
import React from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CardProductK from 'views/MEKeolaShop/CardProductK';
import KCartItem from './KCartItem';
import './MEKeolaShopCart.css'
const MEKeolaShopCart = () => {
    const { mekproducts, cart } = useSelector((state) => state.shop);
    if (cart.length === 0) {
        return <Redirect to="/home" />
    }
    return (
        <>
            {
                cart.length === 0 ?
                    <Redirect to="/home" />
                    :
                    <main className="shopkeola-main">
                        <hr />
                        <KHeader
                            title="Carrito de compras"
                        />
                        <hr />
                        <div className="cart-main">
                            <div className="cartItems">
                                {
                                    cart.map((product, key) => {
                                        return (
                                            <KCartItem product={product} />
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>
                        {/* boton flotante */}
                        <KFloat link="/kshop/delivery" name="Confirmar y pagar..." 
                            extra={ `S/. ${cart.reduce((a, b) => a + (b.price?.backOfficePrice * b.quantity), 0)?.toFixed(2)}`}/> 

                    </main>
            }
        </>
    )
}

export default MEKeolaShopCart
