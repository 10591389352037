import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth.service";


function RouteProtected(props) {
  const Componentview = props.component;
  const Layout = props.layout;
  const isLogged = AuthService.getIsLogged();

  if (isLogged) {
    return Layout ? (
      <Layout>
        <Componentview />
      </Layout>
    ) : (
      <Componentview />
    );
  } else {
    return <Redirect to={"/"} />;
  }
}

export default RouteProtected;
