import React, { Component } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ToolsKeola from "../../components/tools/ToolsKeola";
import ToolsInresorts from "../../components/tools/ToolsInresorts";

// import '../styles/Tools.css'

export default class ToolView extends Component {
  render() {
    return (
      <div>
        <Form.Label className="content-title">Herramientas</Form.Label>
        <Tabs defaultActiveKey="toolsKeola">
          <Tab eventKey="toolsKeola" title="Aplicativo Keola">
            <ToolsKeola></ToolsKeola>
          </Tab>
          <Tab eventKey="toolsInresorts" title="Inresorts">
            <ToolsInresorts></ToolsInresorts>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
