import { useRef, useState, useEffect } from "react";
import {
    Form,
    Row,
    Col,
    Button,
    Image,
    Modal,
    Table,
    Alert,
    Container,
} from "react-bootstrap";

// Imports Images Method Payment
import LogoPaypal from "../../images/paypal-logo.png";
import LogoBcp from "../../images/bcp-logo.jpg";
import LogoInter from "../../images/interbank-logo.png";
import WalletImge from "../../images/wallet.png";
import LogoOther from "../../images/other-logo.png";
import DaVivienda from "../../images/colombia/davivienda.jpg";


//service fetch
import UtilService from "../../services/utils.service";



//Payments
import PayPalButton from "../payment/PaypalButton";
import PayVoucher from "components/payment/PayVoucher";
import PayVoucherDaVivienda from "components/payment/PayVoucherDaVivienda";
import PayVoucherInter from "components/payment/PayVoucherInter";
import PayOther from "components/payment/PayOther";
import PayWallet from "components/payment/PayWallet";
import { RiDeleteBinLine } from "react-icons/ri";

export const PaymentMethodBond = ({
    bond = {},
    buyRegisterFetch
}) => {

  const [showPayPal, setShowPayPal] = useState(false);
  const [showVoucher, setShowVoucher] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [bank, setBank] = useState('');
  const [idUser, setIdUser] = useState(0);
  const [isPaid, setIsPaid] = useState(false);
  const [isContado, setIsContado] = useState(false);

  const typeBank = useRef({});
  const typeExchange = useRef({ venta: 1 });

  //Object Payment
  const paymentt = useRef({
    scheduleJSON: "",
    idMethodPayment: 0,
    mountPaid: 0,
    isPaid: false,
    typePay: 0,
    vouchers: [],
    amountPaid: 0,
    quotesPaid: 0,
    totalInitial: 1,
    amountPay: 0,
    ispalcontado: false
  });

  const [vouchers, setVouchers] = useState([]);

  const amountWallet = useRef(0);

  const nacionalidadUsuario = sessionStorage.getItem("info")
  ? JSON.parse(sessionStorage.getItem("info")).idResidenceCountry
  : null;

  const getTypeBank = async() => {
    let response = await UtilService.getTypeBank();
    if (response !== undefined) {
      if (response.status === 1) {
        typeBank.current = response.objModel;
      }
    }
  }

  const getExchange = async() => {
    let response = await UtilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        typeExchange.current = response.objModel;
      }
    }
  }

  const eventPay = (value, field) => {
    console.log('Data Event Payment', value, field)
    setIsPaid(true);
    let pay = paymentt.current;
      pay[field] = value
    
    paymentt.current = pay;
    console.log(paymentt.current)
  }

  const addVoucher = (voucherList) => {
   
    let vouchersAux = vouchers.concat(voucherList);
    setVouchers(vouchersAux);
    eventPay(vouchersAux, "vouchers");

    console.log('Vouchers list add', vouchers);
  }

  const eventWallet = (amount) => {
    console.log("event wallet", amount);
    amountWallet.current = amount;
   
  };

   // Register Payment only with wallet
   const registerPayWallet = (amount) => {
    console.log("register wallet", amount);
    amountWallet.current = amount;
    eventPay(3, "typePay");
    registerBuy();
   }

  const deleteVoucher = (e, idx) => {
    let temp = [];
    let i = 0;
    for (i = 0; i < vouchers.length; i++) {
      if (i !== idx) {
        temp.push(vouchers[i]);
      }
    }
    setVouchers(temp);
    eventPay(temp, "vouchers");
  };

  const registerBuy = () => {
    const payment = paymentt.current;
    let data = {};

    console.log('payment re', payment, amountWallet.current)

    if( payment.typePay == 0 && payment.vouchers.length == 0 ){
      alert('Debe pagar para continuar')
      return;
    }

    if( payment.typePay == 1 && !payment.isPaid  ){
      alert('Debe hacer el pago, en caso de pagar con paypal, la transaccion debe ser exitosa, para continuar con el resgirtro');
      return;
    }

    if( payment.typePay == 3 && amountWallet.current == 0 ){
      alert('Debe hacer el pago, en caso de pagar con Wallet, asegurese de tener saldo para una transaccion exitosa');
      return;
    }

    // Set properties
    console.log('HABER PAYMENT',payment);
    data.AmountPaidPayment = bond.listPackageDetails[0].initialPrice;
    data.QuotesPaid = payment.quotesPay ? payment.quotesPay : 0;
    data.NumberPaymentInitials = 1;

    if( payment.typePay == 1 ){
      data.TypeMethodPayment = 1;
      data.PaypalDTO = {
        nroOperation: payment.idTransaction,
      };
      data.WalletTransaction = {
        Amount: 0,
      };
      data.Vouchers = [];
    }else if( payment.typePay == 3 ){
      data.WalletTransaction = {
        Amount: amountWallet.current,
        ReferenceData: "Compra de suscripcion adicional."
      };

      data.TypeMethodPayment = 3;
    }else{
      let typeMethod = 0;
      let vouchers = [];

      if (payment.vouchers.length > 0) {
        typeMethod = 2;
        console.log('this.state.payment.vouchers =>',payment.vouchers);
        for (let i = 0; i < payment.vouchers.length; i++) {
          let contenType = payment.vouchers[i].voucherBase.split(
            ","
          );
            console.log('amount =>',payment.vouchers[i].amount);
            console.log('amount replace =>',payment.vouchers[i].amount?.replaceAll(",",""));
            console.log('amount number =>',Number(payment.vouchers[i].amount));
            console.log('this.state.payment.vouchers[i].typeExchange =>',payment.vouchers[i].typeExchange);
          let temp = {
            MethodSubTipoPagoId: Number(payment.vouchers[i].type),
            OperationNumber: payment.vouchers[i].code,
            //TotalAmount: Number(this.state.payment.vouchers[i].amount),
            TotalAmount: Number(
               payment.vouchers[i].typeExchange ===3
              ?  payment.vouchers[i].amount?.replaceAll(",", "")
              : payment.vouchers[i].amount
              )
            ,
            PaymentCoinCurrencyId: Number(
              payment.vouchers[i].typeExchange
            ),
            Note: payment.vouchers[i].nota,
            Base64: contenType[1],
          };
          console.log('ver temp =>',temp);
          
          vouchers.push(temp);
        }

        data.Vouchers = vouchers;
        data.NotePay = vouchers.map((item) => item.NotePay).join(", ");
      }

      console.log('DATA HASTA AHORA =>',data);
      ///////////////////////////////////////////////////////////wallet
      if (amountWallet.current > 0) {
        data.WalletTransaction = {
          Amount: amountWallet.current,
          ReferenceData: "Compra de suscripcion adicional."
        };

        // TypeMethodPayment
        typeMethod = typeMethod > 0 ? 4 : 3;
      }

      data.TypeMethodPayment = typeMethod;

    }

    data.promotionalCode = {};
    data.IdPackageDetailPayment = bond.listPackageDetails[0].idPackageDetail;
    data.Iscontado = payment.ispalcontado ? 1 : 0;

    data.NumberPaymentInitials = payment.totalInitial;
    if (data.NumberPaymentInitials > 1) {
      data.NumberAdvancePaymentPaid = 0;
    } else {
      data.NumberAdvancePaymentPaid =
        data.QuotesPaid > 0 ? data.QuotesPaid : 0;
    }

    console.log('haber Data que se envia TIENDA =>',data);
    buyRegisterFetch(data);
   
  };


  useEffect(() => {
    getTypeBank();
    getExchange();

    console.log('useEffect IdUser', sessionStorage.getItem("id"));

    setIdUser(sessionStorage.getItem("id"));

  }, [])
  

  return (
    <div className="mt-5">
        <div className=" text-align-left" style={{textAlign: 'left'}}>
            <Form.Label className="content-subtitle">
              Métodos de pagos
            </Form.Label>
            <Form.Group className="mb-3">
              <Row>
                <Col sm={12}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <td>
                          <Form.Label>Descripción</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Precio Total</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Inicial</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Nro. de cuotas</Form.Label>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td>
                            <Form.Text>{bond.description} </Form.Text>
                          </td>
                          <td>
                            <Form.Text>
                              $ {bond.listPackageDetails[0].price}
                            </Form.Text>
                          </td>
                          <td>
                            <Form.Text>$ { bond.listPackageDetails[0].initialPrice }</Form.Text>
                          </td>
                          <td>
                            <Form.Text>{ bond.listPackageDetails[0].numberQuotas }</Form.Text>
                          </td>
                        </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Form.Group>
            <div className="justify-content-between">
                <Row>
                    <Col sm={5} md={5} lg={6}></Col>
                    <Col sm={7} md={7} lg={6}>
                      <Row>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            label="Pago al contado"
                            onChange={(e) => {
                              let bool = true;

                              if( isContado ){
                                bool = false;
                              }

                              eventPay(bool, "ispalcontado");
                              setIsContado(bool)
                            }}
                            value={true}
                            checked={isContado ? true : false}
                            className="sizecheckpcontado"
                            style={{textAlign: 'left'}}
                          />
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col xs="8" sm="8" md="6" lg="8"   style={{textAlign: 'left'}}>
                          <Form.Label>Total: </Form.Label>
                        </Col>

                        <Col
                          xs="4"
                          sm="4"
                          md="6"
                          lg="4"
                          className="aligntextpayment"
                        >
                          <Form.Label id="total">$ {bond.listPackageDetails[0].initialPrice}</Form.Label>
                        </Col>
                      </Row>
                    </Col>
                </Row>
            </div>
            <hr></hr>
            <Form.Group>
              {/*   METODOS DE PAGO EN TIENDA IMAGENES*/}
              <Row className="paddediconpaymentmethod">
                {(() => { 
                  switch (nacionalidadUsuario) {
                    case 167:
                      return (
                        <>
                          <Col sm={2} className="mb-3">
                            <Image
                              src={LogoPaypal}
                              rounded
                              style={{
                                width: "100%",
                                maxWidth: 136,
                                cursor: "pointer",
                              }}
                              onClick={() => setShowPayPal(true)}
                            ></Image>
                          </Col>
                          <Col sm={2} className="mb-3">
                            <Image
                              src={LogoBcp}
                              rounded
                              style={{
                                width: "100%",
                                maxWidth: 136,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setBank('BCP');
                                setShowVoucher(true)
                              }}
                            ></Image>
                          </Col>
                          <Col sm={2} className="mb-3">
                            <Image
                              src={LogoInter}
                              rounded
                              style={{
                                width: "120%",
                                maxWidth: 136,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setBank('Interbank');
                                setShowVoucher(true)
                              }}
                            ></Image>
                          </Col>
                          <Col sm={2} className="mb-3">
                            <Image
                              src={LogoOther}
                              rounded
                              style={{
                                width: "120%",
                                maxWidth: 136,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowOther(true)
                              }}
                            ></Image>
                          </Col>
                          <Col sm={2}>
                            <div
                              onClick={(e) => {
                                setShowWallet(true)
                              }}
                            >
                              <p style={{ margin: 2, fontWeight: "bold" }}>
                                <Image
                                  src={WalletImge}
                                  style={{ width: "32px", marginRight: "10px" }}
                                ></Image>
                                Mi Wallet
                              </p>
                            </div>
                          </Col>
                        </>
                      )
                    case 45:
                      return(
                        <>
                          <Col sm={2} className="mb-3">
                            <Image
                              src={LogoPaypal}
                              rounded
                              style={{
                                width: "100%",
                                maxWidth: 136,
                                cursor: "pointer",
                              }}
                              onClick={() => setShowPayPal(true)}
                            ></Image>
                          </Col>
                          <Col sm={2}>
                            <Image
                              src={DaVivienda}
                              rounded
                              style={{
                                width: "100%",
                                maxWidth: 136,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setBank('Davivienda');
                                setShowVoucher(true)
                              }}
                            ></Image>
                          </Col>
                          <Col sm={2}>
                            <div
                              onClick={(e) => {
                                setShowWallet(true)
                              }}
                              style={{cursor: 'pointer'}}
                            >
                              <p style={{ margin: 2, fontWeight: "bold" }}>
                                <Image
                                  src={WalletImge}
                                  style={{ width: "32px", marginRight: "10px" }}
                                ></Image>
                                Mi Wallet
                              </p>
                            </div>
                          </Col>
                        </>
                      
                      )
                    default:
                      return (
                        <>
                          <Col sm={2} className="mb-3">
                            <Image
                              src={LogoPaypal}
                              rounded
                              style={{
                                width: "100%",
                                maxWidth: 136,
                                cursor: "pointer",
                              }}
                              onClick={() => setShowPayPal(true)}
                            ></Image>
                          </Col>
                          <Col sm={2} className="mb-3">
                            <Image
                              src={LogoOther}
                              rounded
                              style={{
                                width: "120%",
                                maxWidth: 136,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowOther(true)
                              }}
                            ></Image>
                          </Col>
                          <Col sm={2}>
                            <div
                              onClick={(e) => {
                                setShowWallet(true)
                              }}
                            >
                              <p style={{ margin: 2, fontWeight: "bold" }}>
                                <Image
                                  src={WalletImge}
                                  style={{ width: "32px", marginRight: "10px" }}
                                ></Image>
                                Mi Wallet
                              </p>
                            </div>
                          </Col>
                        </>
                      )
                  }
                })()}
                <Col sm={12} style={{textAlign: 'left', marginTop: 15, opacity: 0.5}}>
                  <Form.Label>
                    (*) Puede adjuntar comprobantes de pago de BCP, Intebank y
                    Otros.
                  </Form.Label>
                </Col>
              </Row>
                {/* show the table if has register */}
                {vouchers.length > 0 && (
                  <div className="mb-3">
                    <Table responsive>
                      <thead className="table-head">
                        <tr>
                          <th>Banco</th>
                          <th>Operación</th>
                          <th>Código</th>
                          <th>Nota</th>
                          <th>Archivo</th>
                          <th>Moneda</th>
                          <th>Monto</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {vouchers.map((item, idx) => (
                          <tr key={idx}>
                            <td>{item.bank}</td>
                            <td>{item.typeDescription}</td>
                            <td>{item.code}</td>
                            <td>{item.nota}</td>
                            <td>{item.name}</td>
                            <td>{item.typeExchangeDes}</td>
                            <td>{item.amount}</td>
                            <td
                              style={{ fontSize: 15 }}
                              onClick={(e) => {
                                deleteVoucher(e, idx);
                              }}
                            >
                              <RiDeleteBinLine></RiDeleteBinLine>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
              )}
              
              {/* Show amount payed with wallet */}
              {amountWallet.current > 0 && (
                <Row
                  style={{ padding: 16, paddingRight: 16, paddingBottom: 10 }}
                >
                  <Col sm={12}>
                    <Alert variant="success">
                      <b>Monto pagado con Wallet $ : </b>
                      {amountWallet.current}
                    </Alert>
                  </Col>
                </Row>
              )}
             
            </Form.Group>
        </div>
        <hr></hr>
        <div className="row justify-content-between py-3">
          <Col ms={4} style={{textAlign: 'left'}}>
            <Button
              variant="danger"
            >
              Cancelar
            </Button>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button variant="primary" onClick={() => registerBuy()}>
              Finalizar
            </Button>
          </Col>
        </div>

         {/* Modal  Payment Paypal */}
         <Modal show={showPayPal} onHide={() =>  setShowPayPal(false)} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>PayPal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayPalButton
                mount={bond.listPackageDetails[0].initialPrice}
                description={bond.name}
                eventPay={eventPay}
                typeBank={typeBank.current}
                exchange={typeExchange.current.venta}
                close={() =>  setShowPayPal(false)}
                registerBuy={registerBuy}
              ></PayPalButton>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() =>  setShowPayPal(false)}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal upload voucher*/}
        <Modal
          show={showVoucher}
          onHide={() => setShowVoucher(false)}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago en efectivo a través de {bank}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { bank == 'BCP' && (
              <Form.Group>
                <PayVoucher
                  total={bond.listPackageDetails[0].initialPrice}
                  addVoucher={addVoucher}
                  close={() => setShowVoucher(false)}
                  typeBank={typeBank.current}
                  exchange={typeExchange.current.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                ></PayVoucher>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
            { bank == 'Davivienda' && (
              <Form.Group>
                <PayVoucherDaVivienda
                  total={bond.listPackageDetails[0].initialPrice}
                  addVoucher={addVoucher}
                  close={() => setShowVoucher(false)}
                  typeBank={typeBank.current}
                  exchange={typeExchange.current.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                ></PayVoucherDaVivienda>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
         
            {bank == 'Interbank' && (
              <Form.Group>
                <PayVoucherInter
                  total={bond.listPackageDetails[0].initialPrice}
                  addVoucher={addVoucher}
                  close={() => setShowVoucher(false)}
                  typeBank={typeBank.current}
                  exchange={typeExchange.current.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                ></PayVoucherInter>
              </Form.Group>
            )}
          </Modal.Body>
        </Modal>
        
        {/* Modal upload voucher other tickets*/}
        <Modal
          show={showOther}
          onHide={() => setShowOther(false)}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de otros medios de pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayOther
                total={bond.listPackageDetails[0].initialPrice}
                addVoucher={addVoucher}
                close={() => setShowOther(false)}
                typeBank={typeBank.current}
                exchange={typeExchange.current.venta}
                fontSize={10}
                nacionalidadUsuario={nacionalidadUsuario}
              ></PayOther>
            </Form.Group>
          </Modal.Body>
        </Modal>
        
        {/* Modal pay with wallet*/}
        <Modal
          show={showWallet}
          onHide={() => setShowWallet(false)}
          style={{ fontSize: 10 }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago con Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayWallet
                idUser={idUser}
                total={bond.listPackageDetails[0].initialPrice}
                registerPayWallet={registerPayWallet}
                eventWallet={eventWallet}
                close={() => setShowWallet(false)}
                fontSize={10}
              ></PayWallet>
            </Form.Group>
          </Modal.Body>
        </Modal>


    </div>
  )
}
