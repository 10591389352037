import React, { Component } from 'react';
import BankAccount from '../../components/user/BankAccount';

export default class BankInfoView extends Component {
    

    render() {
        return(
            <div>
                <BankAccount></BankAccount>
            </div>
            
        );
    }
}