import React, { Component } from 'react';
import { Form, Tabs, Tab } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';


import PackageMainView from '../shop/PackageMainView';
import ServiceView from '../shop/ServiceView';
import MyPurchaseView from '../shop/MyPurchaseView';
import MEKeolaShop from 'views/MEKeolaShop';
import MEKeolaListOrders from 'views/MEKeolaListOrders';
import { PromotionalGuests } from 'views/shop/PromotionalGuests';
import { BonusList } from 'views/bonus/BonusList';

export default class ShopView extends Component {
    render() {
        return (
            <div>
                <Form.Label class="content-title">Tienda</Form.Label>
                <Tabs className="custom-tabs-main" defaultActiveKey="package" >
                    <Tab eventKey="package" title="Paquetes">
                        <PackageMainView></PackageMainView>
                    </Tab>
                    <Tab eventKey="service" title="Servicios">
                        <ServiceView></ServiceView>
                    </Tab>
                    <Tab eventKey="mypurchase" title="Mis Compras">
                        {/* <MyPurchaseView></MyPurchaseView> */}
                        <MEKeolaListOrders/>
                    </Tab>
                    {/* Rendering the component MEKeolaShop. */ }
                    <Tab eventKey="mekeola" title="Productos">
                        <MEKeolaShop/>
                    </Tab>
                    {/* Rendering the component PromotionalGuests.  */}
                    <Tab eventKey="invitadopromocional" title="Invitado Promocional">
                        <PromotionalGuests />
                    </Tab>
                    {/* Rendering the component BonusList */}
                    <Tab eventKey="bonusList" title="Bonos">
                        <BonusList />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}
