import React, { Component } from "react";
import { Form, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";

import AuthService from "../../services/auth.service";
import WalletService from "../../services/wallet.service";

export default class PayWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      idUser: this.props.idUser,
      accountBalance: 0,
      availableBalance: 0,
      total: this.props.total,
      diasUsados: this.props.diasUsados,
      totalPagar: this.props.totalPagar,
      cambioFecha: this.props.cambioFecha,
      aplicaPG: this.props.aplicaPG,
      respuestaPG: this.props.respuestaPG,
      amount: 0,
      message: "",
    };
    //console.log("props =>", this.props);
    console.log("props total =>", this.props.total);
    //console.log("props dias usados => =>", this.props.diasUsados);
    //console.log("props cambio de fecha? => =>", this.props.cambioFecha);
    //console.log("props totalPagar? => =>", this.props.totalPagar);
    console.log("props respuestaPG => =>", this.props.respuestaPG);
  }
  componentDidMount() {
    this.getWalletInformation();
  }

  getWalletInformation = async () => {
    let response = await WalletService.getWalletInformation(this.state.idUser);
    if (response !== undefined && response.status === 1) {
      this.setState({
        loading: false,
        accountBalance: response.objModel.accountingBalance,
        availableBalance: response.objModel.availableBalance,
      });
    } else {
      this.setState({
        loading: false,
        accountBalance: 0,
        availableBalance: 0,
      });
    }
  };

  handleChange = (e, field) => {
    let value = Number(e.target.value);
    if (value <= this.state.availableBalance) {
      this.setState({
        [field]: value,
        message: "",
      });
    } else {
      this.setState({
        [field]: 0,
        message: " Monto no puede exceder el saldo contable",
      });
    }
  };

  onAddWallet = (e) => {
    this.props.eventWallet(this.state.amount);
    sessionStorage.setItem("amountWallet", this.state.amount);
    this.props.close();
  };

  onSave = () => {
    /* console.log("diste click en Pagar aqui wallet");
    console.log(' amount =>',this.state.amount);
    console.log('dias usados =>',this.state.diasUsados);
    console.log('cambio fecha =>',this.state.cambioFecha);
    console.log('cambio fecha =>',this.state.respuestaPG); */
    console.log('estas enviando este monto ==>',this.state.amount)
    this.props.registerPayWallet(
      this.state.amount,
      this.state.diasUsados,
      this.state.cambioFecha,
      this.state.aplicaPG,
      this.state.respuestaPG
    );
    sessionStorage.setItem("amountWallet", this.state.amount);
    this.props.close();
  };

  render() {
    const {
      total,
      amount,
      availableBalance,
      accountBalance,
      message,
      loading,
      diasUsados,
      cambioFecha,
      totalPagar,
    } = this.state;
    console.log('haber totalPagar en render ==>',totalPagar)
    return (
      <div>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="dark"></Spinner>
            <p>
              <Form.Label>Cargando información...</Form.Label>
            </p>
          </div>
        )}
        {!loading && (
          <div>
            <Row>
              <Col sm={12}>
                <Form.Label>Saldo disponible: $ {availableBalance}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Label>Saldo contable: $ {accountBalance}</Form.Label>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col sm={12}>
                {totalPagar === undefined || totalPagar === null ? (
                  <Form.Label>Monto a pagar: $ {total}</Form.Label>
                ) : (
                  <Form.Label>
                    Monto a pagar: $ {totalPagar}
                  </Form.Label>
                )}
              </Col>
            </Row>
            <Row style={{ paddingBottom: 10 }}>
              <Col sm={12}>
                <Form.Label>Ingrese monto USD:</Form.Label>
                <Form.Control
                  placeholder="Ingrese monto"
                  type="number"
                  min="0"
                  defaultValue="0.00"
                  style={{ fontSize: 10 }}
                  onChange={(e) => this.handleChange(e, "amount")}
                  step="0.01"
                ></Form.Control>
                <Form.Text className="text-alert">{message}</Form.Text>
              </Col>
            </Row>
          </div>
        )}

        <Row className="row justify-content-between">
          <Col ms={4}>
            <Button variant="secondary" size="sm" onClick={this.props.close}>
              Cancelar
            </Button>
          </Col>

          {totalPagar === undefined || totalPagar === null ? (
            <>
              {amount > 0 && amount < total && (
                <Col style={{ textAlign: "right" }}>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={this.onAddWallet}
                  >
                    Agregar 
                  </Button>
                </Col>
              )}
              {amount > 0 && amount === total && (
                <Col style={{ textAlign: "right" }}>
                  <Button variant="primary" size="sm" onClick={this.onSave}>
                    Pagar aqui 
                  </Button>
                </Col>
              )}
            </>
          ) : (
            <>
              {amount > 0 && amount < (totalPagar) && (
                <Col style={{ textAlign: "right" }}>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={this.onAddWallet}
                  >
                    Agregar {/* amount{amount} y totalPagar{totalPagar.toFixed(2)}  */}
                  </Button>
                </Col>
              )}
              {amount > 0 && amount=== (totalPagar) && (
                <Col style={{ textAlign: "right" }}>
                  <Button variant="primary" size="sm" onClick={this.onSave}>
                    Pagar aqui {/* amount{amount} y totalPagar{totalPagar.toFixed(2)}  */}
                  </Button>
                </Col>
              )}
            </>
          )}

          {/*     {amount > 0 && amount < total && (
            <Col style={{ textAlign: "right" }}>
              <Button variant="primary" size="sm" onClick={this.onAddWallet}>
                Agregar
              </Button>
            </Col>
          )}
          {amount > 0 && amount === total && (
            <Col style={{ textAlign: "right" }}>
              <Button variant="primary" size="sm" onClick={this.onSave}>
                Pagar aqui
              </Button>
            </Col>
          )} */}
        </Row>
      </div>
    );
  }
}
