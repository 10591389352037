import "./FormCalendarEvents.css";
import { useState, useEffect } from "react";
import LoadApi from "components/validators/LoadApi";

export default function FormCalendarEvents(props) {
  const [listofsubtypes, setlistofsubtypes] = useState([]);
  const [listoffilters, setlistoffilters] = useState([]);
  const [isloadedapidatah, setisloadedapidatah] = useState(true);
  //subtype, setsubtype
  useEffect(() => {
    if (props.idsubtype === "def") {
      setisloadedapidatah(false);
      Promise.all([
        fetch(`${props.API_URL}meetings/submeetings`).then((res) => res.json()),
        fetch(`${props.API_URL}meetings/filter`).then((res) => res.json()),
      ])
        .then(([types, filte]) => {
          let listidfilterordered = [1, 3, 4, 2, 5];
          filte.objModel.map((item, idx) => {
            item.idOrder = listidfilterordered[idx];
          });
          filte.objModel.sort((a, b) => a.idOrder - b.idOrder);
          props.setidsubtype(-1);
          props.setfilter(filte.objModel[0].idFilter);
          setlistofsubtypes(types.objModel);
          setlistoffilters(filte.objModel);

          setisloadedapidatah(true);
        })
        .catch((error) => {
          console.log(error);
          setlistofsubtypes([]);
          setlistoffilters([]);
          setisloadedapidatah(true);
        });
    }
  }, [props.idsubtype]);

  return (
    <section className="envolveformsheduleevents py-3 px-4">
      <div className="container maxwithformevents">
        <h3 className="text-center">Eventos </h3>

        <LoadApi indicatorLoad={!isloadedapidatah} size="xs">
          <section className="d-flex gapcss mb-3">
            <div className="tw-flex tw-flex-row tw-items-center gap-3 ">
              <label
                htmlFor="select-type-event"
                className="col-sm-auto col-form-label alignlabelmid tw-text-base"
              >
                Nombre
              </label>
              <div className="col-sm-auto ">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={props.idsubtype}
                  onChange={(e) => props.setidsubtype(Number(e.target.value))}
                >
                  <option value={-1}>Todos</option>
                  {listofsubtypes.map((item, idx) => (
                    <option key={idx} value={item.idSubMeetingType}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <ul className="tw-flex tw-flex-row tw-items-center tw-list-none tw-gap-1 tw-m-0">
              <li>
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradioevent"
                  id={"Hoy"}
                  autoComplete="off"
                  value={1}
                  defaultChecked={true}
                  onChange={(e) => props.setfilter(Number(e.target.value))}
                />
                <label className="btn btn-outline-primary" htmlFor={"Hoy"}>
                  Hoy
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradioevent"
                  id={"Semanal"}
                  autoComplete="off"
                  value={4}
                  defaultChecked={false}
                  onChange={(e) => props.setfilter(Number(e.target.value))}
                />
                <label className="btn btn-outline-primary" htmlFor={"Semanal"}>
                  Semanal
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradioevent"
                  id={"Mensual"}
                  autoComplete="off"
                  value={3}
                  defaultChecked={false}
                  onChange={(e) => props.setfilter(Number(e.target.value))}
                />
                <label className="btn btn-outline-primary" htmlFor={"Mensual"}>
                  Mensual
                </label>
              </li>
            </ul>
          </section>
        </LoadApi>
      </div>
    </section>
  );
}
