import "./progressBarLanding.css";
function ProgressBarLanding(props) {
  return (
    <>
      <div className="progressBarL">
        <span
          className={
            props?.type === "keola"
              ? "keola-modal show animated"
              : "inresorts-modal show animated"
          }
        ></span>
      </div>
    </>
  );
}

export default ProgressBarLanding;
