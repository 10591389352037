import React, { Component } from 'react';
import  { Form, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import imgDefault from '../../images/assets/socio.png';
import imgSocio from '../../images/assets/socio.png';
import imgBronce from '../../images/assets/bronce.png';
import imgPlata from '../../images/assets/plata.png';
import imgOro from '../../images/assets/oro.png';
import imgZafiro from '../../images/assets/zafiro.png';
import imgRuby from '../../images/assets/ruby.png';
import imgEsmeralda from '../../images/assets/esmeralda.png';
import imgDiamante from '../../images/assets/diamante.png';
import imgDobleDiamante from '../../images/assets/DobleDiamante.png';
import imgTripleDiamante from '../../images/assets/TripleDiamante.png';
import imgDnegro from '../../images/assets/dnegro.png';
import imgDazul from '../../images/assets/dazul.png';
import imgDcorona from '../../images/assets/dcorona.png';
import imgDimperial from '../../images/assets/dimperial.png';
import imgPlatino from '../../images/assets/platino.png';
import imgDiamanteDorado from '../../images/assets/DiamanteDorado.png';

import imageDefault from '../../images/noimage.jpg';


const convertirLetraMayuscula = (palabras = '') => {
    let resultado = '';

   if( / /.test(palabras) ){
        let arrPalabras = palabras.split(" ");
        resultado = arrPalabras.map(palabra => {
            return palabra[0].toUpperCase() + palabra.slice(1);
        });

        resultado = resultado.join(" ");
    }else{
        resultado = palabras[0].toUpperCase() + palabras.slice(1);
    }

    return resultado;
} 

export default class RangeNext extends Component{
    constructor(props){
        super(props);
        this.state = {
            imgMax: imgDefault,
            imgRangeAct : imgDefault,
            imgRangeNext: imgDefault,
            imgMaxCom: imgDefault,
            imgRangeActCom : imgDefault,
            imgRangeNextCom: imgDefault,
            imgs: {
                Socio: imgSocio,
                Bronce: imgBronce,
                Plata: imgPlata,
                Oro: imgOro,
                Zafiro: imgZafiro,
                Ruby: imgRuby,
                Esmeralda: imgEsmeralda,
                Diamante: imgDiamante,
                DobleDiamante: imgDobleDiamante,
                TripleDiamante: imgTripleDiamante,
                DiamanteNegro: imgDnegro,
                DiamanteAzul: imgDazul,
                DiamanteCorona: imgDcorona,
                DiamanteImperial: imgDimperial,
                Platino: imgPlatino,
                DiamanteDorado: imgDiamanteDorado
            },
        }

    }
    componentDidMount(){

        let dash = this.props.dash;
        let isResidual=this.props.isResidual;

        //console.log("DIDMount")

        // Storage  range comp
        sessionStorage.setItem("range",dash.nameRangoActualCompuesto )
        dash.nameRangoActualCompuesto = convertirLetraMayuscula(dash.nameRangoActualCompuesto.toLowerCase())
        dash.nameRangoMaximoCompuesto = convertirLetraMayuscula(dash.nameRangoMaximoCompuesto.toLowerCase())
        dash.nameProxRangeCompuesto = convertirLetraMayuscula(dash.nameProxRangeCompuesto.toLowerCase());
        
        console.log(dash)

        //console.log(mayusSplitAprendeAProgramar);
        
        this.setState({
            isResidual:  this.state.isResidual = isResidual
        });
        let CurrentRangeName = dash.nameRangoActualCompuesto.replace(/\s/g, '');
        let MaxRangeName = dash.nameRangoMaximoCompuesto.replace(/\s/g, '');
        let NextRangeName = dash.nameProxRangeCompuesto.replace(/\s/g, '');

        // console.log(CurrentRangeName, 'CurrentRangeName');

        this.setState({
            imgRangeAct : this.state.imgRangeAct = this.state.imgs[CurrentRangeName],
            imgMax : this.state.imgMax = this.state.imgs[MaxRangeName],
            imgRangeNext : this.state.imgRangeNext = this.state.imgs[NextRangeName]
        });

        console.log('dash', dash);
        console.log(MaxRangeName, CurrentRangeName, NextRangeName);


        
    }

    componentDidUpdate(props){
        //console.log("DidUpdate");
        //console.log(props)
        if (props.dash!==this.props.dash || props.isResidual!==this.props.isResidual){
            
            let dash = this.props.dash;
            let isResidual=this.props.isResidual;

            // Storage  range comp
            sessionStorage.setItem("range",dash.nameRangoActualCompuesto )
            console.log('dast ss', dash)
            
            
            this.setState({
                isResidual:  this.state.isResidual = isResidual
            });
            
            let CurrentRangeName,MaxRangeName,NextRangeName;
            if (isResidual==1){
                dash.nameRangoMaximoResidual = convertirLetraMayuscula(dash.nameRangoMaximoResidual.toLowerCase())
                dash.nameRangoActualResidual = convertirLetraMayuscula(dash.nameRangoActualResidual.toLowerCase())
                dash.nameProxRangeResidual = convertirLetraMayuscula(dash.nameProxRangeResidual.toLowerCase());     

                MaxRangeName = dash.nameRangoMaximoResidual.replace(/\s/g, '');
                CurrentRangeName = dash.nameRangoActualResidual.replace(/\s/g, '');//usado para quitar espacio en blanco
                NextRangeName = dash.nameProxRangeResidual.replace(/\s/g, '');
            }
            else{

                dash.nameRangoMaximoCompuesto = convertirLetraMayuscula(dash.nameRangoMaximoCompuesto.toLowerCase())
                dash.nameRangoActualCompuesto = convertirLetraMayuscula(dash.nameRangoActualCompuesto.toLowerCase())
                dash.nameProxRangeCompuesto = convertirLetraMayuscula(dash.nameProxRangeCompuesto.toLowerCase());
                
                MaxRangeName = dash.nameRangoMaximoCompuesto.replace(/\s/g, '');
                CurrentRangeName = dash.nameRangoActualCompuesto.replace(/\s/g, '');
                NextRangeName = dash.nameProxRangeCompuesto.replace(/\s/g, '');

            }

            
            this.setState({
                imgMax : this.state.imgMax = this.state.imgs[MaxRangeName],
                imgRangeAct : this.state.imgRangeAct = this.state.imgs[CurrentRangeName],
                imgRangeNext : this.state.imgRangeNext = this.state.imgs[NextRangeName]
            });


        }
        
    }

    render(){

        const {imgMax, imgRangeAct, imgRangeNext, isResidual} = this.state;
        return(
            <div >
                <Form >
                    <Form.Label className="content-title">Avance de Rango</Form.Label>
                    <br></br>
                    <Form.Label className="range-title">{this.state.isResidual==1?"Residual":"Compuesto"}</Form.Label>
                    
                        <div className="range-img">
                            <p className="range-title"><b>MÁXIMO LOGRO: </b></p>
                            <img src={imgMax}></img>
                        </div>
                        <div  className="range-img">
                            <p className="range-title"><b>RANGO ACTUAL:</b></p>
                            <img src={imgRangeAct}></img>
                        </div>
                        <div  className="range-img">
                            <p className="range-title"><b>PRÓXIMO RANGO:</b></p>
                            <img src={imgRangeNext}></img>
                        </div>
                </Form>
                
                
            </div>
        );
    }
}