import React, { Component } from "react";
import {
  Form,
  Tabs,
  Tab,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FaEdit } from "react-icons/fa";
import { AiFillNotification } from "react-icons/ai";

import UserService from "../../services/user.service";
import Validation from "../utils/Validation";
import AuthService from "../../services/auth.service";
import MainView from "../../views/home/MainView";
import FlyerView from "../../views/message/FlyerView";

export default class RangeManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registers: [],
      emptyList: true,
      message: "No hay registros para mostrar.",
      loading: false,
      activePage: 1,
      txtSearch: "",
      idState: "0",
      totalPages: 1,
      page: 1,
      size: 20,
      totalRegister: 0,
      showModal: false,
      suscriptionModal: undefined,
      userModal: undefined,
      checkCurrent: true,
      checkSponsor: false,
      checkOther: false,
      emailOther: "",
      showModalEdit: false,
      userCurrent: undefined,
      loadingSaveUSer: false,
      saveService: false,
      messageSave: "",
      index: -1,
      id: undefined,
      name: undefined,
      lastname: undefined,
      nroDocument: undefined,
      email: undefined,
      nroTelf: undefined,
      showModalFlyer: false,
      flyerItem: undefined,
      IsStateActivePartner: "-1",
      FlagSearchState: 0,
    };
  }

  componentDidMount() {
    let parameter = this.getParameter();
    this.setState({
      loading: (this.state.loading = true),
    });
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
    this.setState({
      loading: (this.state.loading = false),
    });
  }

  handlePageChange = (e, field) => {
    let value = Number(e.target.value);
    this.setState({
      [field]: (this.state[field] = value),
      loading: true,
      registers: [],
      emptyList: true,
    });

    // Call to  services

    let parameter = this.getParameter();
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };

  handleSizeChange = (e, field) => {
    let value = Number(e.target.value);

    this.setState({
      size: (this.state.size = value),
      page: (this.state.page = 1),
      loading: true,
      registers: [],
      emptyList: true,
    });

    // Call to  services

    let parameter = this.getParameter();
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };

  handleChange = (e, field) => {
    let value = e.target.value;

    this.setState({
      [field]: (this.state[field] = value),
    });
  };

  handleChangeCheck = (e, field) => {
    let value = e.target.value;

    this.setState({
      [field]: (this.state[field] = !this.state[field]),
    });
  };

  handleSelectState = (e) => {
    const value = Number(e.target.value);
    const flag = value >= 0 ? 1 : 0;
    this.setState({
      IsStateActivePartner: (this.state.IsStateActivePartner = e.target.value),
      FlagSearchState: (this.state.FlagSearchState = flag),
    });
  };

  /**
   * Event to search
   * @param {*} e
   */
  searchAffiliate = () => {
    
    this.setState({
      loading: true,
      registers: [],
      emptyList: true,
    });
    let parameter = this.getParameter();
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };

  getParameter = () => {
    let data = {};
    let parameters = {};

    if (Number(this.state.idState) > 0) {
      parameters.FlagSearchSuscription = 1;
      parameters.StateSuscriptionSearch = Number(this.state.idState);
    }

    // State
    parameters.IsStateActivePartner = Number(this.state.IsStateActivePartner);
    parameters.FlagSearchState = Number(this.state.FlagSearchState);

    parameters.LevelTreePatrocinio = 1;
    parameters.IdSocioSearch = Number(AuthService.getCurrentIdUser());

    data.ParametersSearched = parameters;
    data.SizeList = this.state.size;
    data.NroPage = this.state.page;

    return data;
  };

  getSearchResults = async (parameter) => {
    let response = await UserService.managementRange(parameter);

    if (response !== undefined && response !== null) {
      if (response.status !== 1) {
        //;
        this.setState({
          registers: (this.state.registers = []),
          emptyList: (this.state.emptyList = true),
          loading: (this.state.loading = false),
          message: (this.state.message =
            "Se ha producido un error. Inténtelo más tarde."),
          totalRegister: (this.state.totalRegister = 0),
        });
      } else {
        let object = response.objModel;
        if (object.listElements.length > 0) {
          object.listElements.map((item) => {
            item.currentSuscription = undefined;
          });

          this.setState({
            registers: (this.state.registers = object.listElements),
            emptyList: (this.state.emptyList = false),
            loading: (this.state.loading = false),
            message: (this.state.mesagge = ""),
            totalPages: (this.state.totalPages = object.totalPages),
            totalRegister: (this.state.totalRegister = object.totalElemList),
          });
        } else {
          this.setState({
            registers: (this.state.registers = []),
            emptyList: (this.state.emptyList = true),
            loading: (this.state.loading = false),
            message: (this.state.mesagge = "No hay registros para mostrar."),
            totalPages: (this.state.totalPages = 1),
            page: (this.state.page = 1),
            totalRegister: (this.state.totalRegister = 0),
          });
        }
      }
    } else {
      this.setState({
        registers: (this.state.registers = []),
        emptyList: (this.state.emptyList = true),
        loading: (this.state.loading = false),
        message: (this.state.message =
          "Se ha producido un error. Inténtelo más tarde."),
        totalRegister: (this.state.totalRegister = 0),
      });
    }
  };

  showDashBoard = (item) => {
    ;
    this.setState({
      showModal: true,
      item: item,
    });
  };

  handleClose = () => {
    this.setState({
      item: undefined,
      showModal: false,
    });
  };

  // Handle modal lto show image
  showFlyer = (item) => {
    ;
    this.setState({
      showModalFlyer: true,
      flyerItem: item,
    });
  };

  handleCloseFlyer = () => {
    this.setState({
      item: undefined,
      showModalFlyer: false,
    });
  };

  render() {
    const {
      registers,
      page,
      size,
      totalPages,
      totalRegister,
      showModal,
      item,
      showModalFlyer,
      flyerItem,
    } = this.state;

    let optionPages = [];
    for (let i = 0; i < totalPages; i++) {
      optionPages.push(<option value={i + 1}>{i + 1}</option>);
    }

    return (
      <div>
        <Form.Group>
          {/* <p>Información actualizada al: <b>{this.state.date}</b></p> */}
        </Form.Group>
        <div className="search">
          <h1 className="search-title">Buscador</h1>
          <Row>
            <Col sm={4}>
              <Form.Label>Estados: </Form.Label>
              <Form.Select
                as="select"
                size="sm"
                defaultValue={this.state.IsStateActivePartner}
                onChange={(e) => {this.handleSelectState(e)}}
              >
                <option value="-1">Todos</option>
                <option value="1">Activos</option>
                <option value="0">Inactivos</option>
              </Form.Select>
            </Col>
          </Row>
          <Row style={{ textAlign: "right" }}>
            <Col sm={12}>
              <Form.Group>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={(e) => {
                    this.searchAffiliate(e);
                  }}
                >
                  Buscar
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <hr></hr>
        {/* Paginador */}
        <Row>
          <Form.Label column xs={12} sm={12} md={10} xl={6}>
                      Mostrando del {size * (page - 1) + 1} al&nbsp;
                      {size * page > totalRegister ? totalRegister : size * page} de&nbsp;
                      {totalRegister} registros. &nbsp;&nbsp;| Página:&nbsp;
                      <Form.Select
                        className="my-2 ml-2 mr-1"
                        id="selecPages"
                        size="sm"
                        defaultValue={"1"}
                        style={{display:"inline",width:"auto"}}
                        value={page}
                        onChange={(e) => {
                          this.handlePageChange(e, "page");
                        }}
                      >
                        {optionPages}
                      </Form.Select>
                      
                      {" "} de {totalPages}. Por página: {" "}
                      <Form.Select
                        id="selectCount"
                        defaultValue={"30"}
                        size="sm"
                        style={{display:"inline",width:"auto"}}
                        onChange={(e) => {
                          this.handleSizeChange(e, "size");
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                      </Form.Select>
          </Form.Label>
        </Row>
        
        {this.state.loading && (
          <div>
            <Spinner animation="border" variant="dark"></Spinner>
            <p>
              <Form.Label>Cargando información ...</Form.Label>
            </p>
          </div>
        )}
        {this.state.emptyList && !this.state.loading && (
          <Form.Label>{this.state.message}</Form.Label>
        )}
        {!this.state.emptyList && (
          <div>
            <Row>
              <Col sm={12}>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th>N°</th>
                      <th>F. Afiliación</th>
                      <th>Nombres y apellidos</th>
                      <th>Documento</th>
                      <th>Teléfono</th>
                      <th>Estado</th>
                      <th>Nivel Patrocinio</th>
                      <th>Rango actual</th>
                      <th>Rama 1</th>
                      <th>Rama 2</th>
                      <th>Rama 3</th>
                      <th>Directos</th>
                      <th>Puntos Placement</th>
                      <th>Dashboard</th>
                      <th>Flyer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {registers.map((item, idx) => {
                      let date = "";
                      return (
                        <tr key={item.id}>
                          <td>{size * (page - 1) + 1 + idx}</td>
                          <td>
                            {Validation.convertDateToStringEx(item.createDate)}
                          </td>
                          <td>
                            <div
                              style={{
                                width: "100px",
                                wordBreak: "break-word",
                              }}
                            >
                              {item.name} {item.lastName}
                            </div>
                          </td>
                          <td>
                            <div
                              style={{ width: "150px", wordBreak: "break-all" }}
                            >
                              {item.nroDocument}
                            </div>
                          </td>
                          <td>{item.nroTelf}</td>
                          <td>
                            <div
                              style={{ width: "60px", wordBreak: "break-all" }}
                            >
                              {item.stateText}
                            </div>
                          </td>
                          <td>{item.nivelPatrocinio}</td>
                          <td>{item.nameRangeCompuesto}</td>
                          <td>{item.dashbordScore.points1}</td>
                          <td>{item.dashbordScore.points2}</td>
                          <td>{item.dashbordScore.points3}</td>
                          <td>{item.pointsTotalDirecto}</td>
                          <td>{item.pointsPlacement}</td>
                          <td>
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={(e) => {
                                this.showDashBoard(item);
                              }}
                            >
                              Dashboard
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={(e) => {
                                this.showFlyer(item);
                              }}
                            >
                              Flyer
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        )}

        {/* Modal show dashboard */}
        <Modal
          show={showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Dashboard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                {item !== undefined && (
                  <p>
                    <b>Nombres: </b>
                    {item.name} {item.lastName}
                  </p>
                )}
              </Col>
              <Col sm={6}>
                {item !== undefined && (
                  <p>
                    <b>DNI: </b>
                    {item.nroDocument}
                  </p>
                )}
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col sm={12}>
                {item !== undefined && (
                  <div style={{ textAlign: "center", margin: "30px" }}>
                    <MainView idUser={item.id}></MainView>
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={(e) => this.handleClose(e)}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal show Flyer */}
        <Modal
          show={showModalFlyer}
          aria-labelledby="contained-modal-title-vcenter"
          onHide={this.handleCloseFlyer}
        >
          <Modal.Header closeButton>
            <Modal.Title>Flyer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                {flyerItem !== undefined && (
                  <div style={{ textAlign: "center", margin: "10px" }}>
                    <FlyerView user={flyerItem}></FlyerView>
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={(e) => this.handleCloseFlyer(e)}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
