import { ErrorRounded } from "@material-ui/icons";

const API_URL = `${process.env.REACT_APP_APP_URL_API}`;

class UtilService {
  async getResidences() {
    let url = API_URL + "/country/countrieslist";

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getTypeDocByNat(idNationality) {
    let url = API_URL + "/DocumentType/" + idNationality;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getTypeDocDefault() {
    let url = API_URL + "/DocumentType/foreign";
    console.log(url);
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getPackages() {
    let url = API_URL + "/familyPackage";

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  async getPackages() {
    let url = API_URL + "/familyPackage";

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  async getPackageswithdetailsbyfamily() {
    let url =
      API_URL + "/familyPackage/package/detail/version/state?value=true";

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getExchanges() {
    let url = API_URL + "/TipoCambio/getexchange";

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getTypeBank() {
    let url = API_URL + "/TipoPago/listTipoPago";

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getPackageById(id) {
    let url = API_URL + "/package/" + id;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Verify code promotion
  async verifyCode(code) {
    let data = {};
    data.codPromotion = code;
    let url = API_URL + "/promotion/search";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  ///Get BankNameBy Nationality
  async getBankNameByNat(idNationality) {
    let url = API_URL + "/bank/country/" + idNationality;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // To get range by username
  async getRangeByUsername(username) {
    let data = {};
    data.username = username;
    let url = API_URL + "/range/byusername";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async validateCodeWallet(username, code) {
    let data = {
      WalletTransaction: {
        IdWalletTransaction: Number(code),
      },
      Username: username,
    };
    let url = API_URL + "/walletTransaction/validate/transfer/paylater";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
/*   async PostCalculeGraciaDays(data) {
    let url = API_URL + "/payment/calculate/total";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } */

  async getDays(id) {
    let url = API_URL + `/graceperiod/suscription/${id}`;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async insertPromotionalGuest(data) {
    let url = API_URL + "/User/InsertPromotionalGuest";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
    });
  }

  async insertLegalizationRequest(data) {
    let url = API_URL + "/LegalizationRequest/insertLegalizationRequest";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
    });
  }

  async getLegalizationRequestOrigin() {
    let url = API_URL + `/LegalizationRequest/getLegalizationRequestOrigin`;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  //LegalizationRequest/getLegalizationRequestHistoricalByUser

  async getLegalizationRequestHistoricalByUser(id) {
    let url = API_URL + `/LegalizationRequest/getLegalizationRequestHistoricalByUser?userId=${id}`;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
}



export default new UtilService();
