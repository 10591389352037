import React, { Component } from 'react';
import {Form, Button, Col, InputGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import { TextField,FormHelperText } from "@mui/material";
import history from '../../views/navigation/history';
import UserService from '../../services/user.service';
import {HiOutlineEye,HiOutlineEyeOff } from "react-icons/hi";
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { IconContext } from "react-icons";
import Buttonmui from "@mui/material/Button";
export default class ResetPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            password: "",
            repassword: "",
            valPassword: false,
            valRePassword: false,
            isSame: false,
            message: "",
            isShowNew: false,
            isShowRep: false,
            isloading: false
        }
    }

    handleChange = (e, field) => {
        
        this.setState({[field]: e.target.value.trim()});
        this.setState({
            message: ""
        });

        
    };

    validatePassword = () => {
        
        if(this.state.password.length === 0) {
            this.setState({valPassword: this.state.valPassword = true});
            return false;
        } else if (this.state.repassword.length === 0){
            this.setState({
                valPassword: this.state.valPassword = false,
                valRePassword: this.state.valRePassword = true
            });
            return false;
        } else if (this.state.repassword !== this.state.password){
            this.setState({
                valPassword: this.state.valPassword = false,
                isSame: this.state.isSame = false,
                message: this.state.message = "Contraseñas no coinciden."
            });
            return false;
        }
        this.setState({
            message: this.state.message = ""
        });
        return true;
    }

    // Show password
    showPassword = (field) => {
        
        this.setState({
            [field]: !this.state[field]
        });
    }
    

    sendData = async() => {

       if(this.validatePassword()) {
            this.setState({isloading:true})
            let data = {};
            data.token = this.props.token;
            let user = {};
            user.password = this.state.password;
            data.user = user;
            
            let response = await UserService.recoverPassword(data);
            this.setState({isloading:false})
            if(response !== undefined){
                if(response.status === 1) {
                    alert("Su contraseña ha sido modificada.")
                    history.push("/");
                } else {
                    alert("Ocurrió un problema al reestablecer su contraseña.");
                }
            } else {
                alert("Tuvimos problemas en reestablecer su contraseña. Inténtalo más tarde.");
            }
       }
    };

    render() {
        
        const { password, repassword } = this.state;
        return (
                <div>
                    <h3>Reestablecer contraseña</h3>
                    <Form.Group className="my-3" >
                            <FormControl fullWidth  variant="outlined" size="small">
                                <InputLabel htmlFor="outlined-adornment-password">Nueva contraseña</InputLabel>
                                <OutlinedInput 
                                sx={{
                                    borderRadius: 50
                                }}
                                label="Nueva contraseña"
                                type={this.state.isShowNew ? "text" : "password"}
                                id="outlined-adornment-password"
                                variant="outlined"
                                onChange={(e) => this.handleChange(e, "password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => this.showPassword("isShowNew")}
                                    edge="end"
                                    >

                                    <IconContext.Provider value={{size:"0.8em"}}>
                                    {this.state.isShowNew ? <HiOutlineEyeOff/> : <HiOutlineEye/>}
                                    </IconContext.Provider>
                                    </IconButton>
                                    
                                    </InputAdornment>
                                }
                                />
                            </FormControl>
                    </Form.Group>
                    <Form.Group className="my-3" >
                        <FormControl fullWidth  variant="outlined" size="small">
                            <InputLabel htmlFor="outlined-adornment-password-1">Vuelva a ingresar su contraseña</InputLabel>
                            <OutlinedInput 
                            sx={{
                                borderRadius: 50
                            }}
                            label="Vuelva a ingresar su contraseña"
                            type={this.state.isShowRep ? "text" : "password"}
                            id="outlined-adornment-password-1"
                            variant="outlined"
                            onChange={(e) => this.handleChange(e, "repassword")}
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={e => this.showPassword("isShowRep")}
                                edge="end"
                                >

                                <IconContext.Provider value={{size:"0.8em"}}>
                                {this.state.isShowRep ? <HiOutlineEyeOff/> : <HiOutlineEye/>}
                                </IconContext.Provider>
                                </IconButton>
                                
                                </InputAdornment>
                            }
                            />
                        </FormControl>
                    </Form.Group>
                    <Buttonmui variant="contained" size="medium" fullWidth onClick={!this.state.isloading?this.sendData:null}>
                        {this.state.isloading?
                        <div style={{margin:"auto"}}>
                        <div className="spinnerinicio"> 
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        </div>
                        :"Continuar"}
                        
                    </Buttonmui>
                    {/* <Buttonmui variant="light" size="medium" fullWidth>

                    </Buttonmui> */}
                    {/* <Form controlid="validationPassword" validated={this.state.valPassword}>
                        <Form.Group >
                            <Form.Label>Nueva contraseña</Form.Label>
                            <InputGroup className="mb-2">
                                <Form.Control required 
                                    type={this.state.isShowNew? "text": "password" }
                                    placeholder="Ingresa tu nueva contraseña"
                                    value={password}
                                    onChange={e => this.handleChange(e, "password")}/>
                          
                                    <InputGroup.Text onClick={e => this.showPassword("isShowNew")}>
                                    {this.state.isShowNew && <FaEyeSlash></FaEyeSlash>}
                                    {!this.state.isShowNew && <FaEye></FaEye>}
                                    </InputGroup.Text>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">Ingrese contraseña.</Form.Control.Feedback>

                            
                        </Form.Group>
                    </Form>
                    <Form controlid="validationRePassword" validated={this.state.valRePassword}>
                        <Form.Group>
                        <Form.Label>Vuelva a ingresar su contraseña</Form.Label>
                            <InputGroup className="mb-2">
                                <Form.Control required 
                                            type={this.state.isShowRep? "text": "password" }
                                            placeholder="Vuelva a ingresa su contraseña"
                                            value={repassword}
                                            onChange={e => this.handleChange(e, "repassword")} isValid={this.state.isSame} />
                           
                                <InputGroup.Text onClick={e => this.showPassword("isShowRep")}>
                                {this.state.isShowRep && <FaEyeSlash></FaEyeSlash>}
                                {!this.state.isShowRep && <FaEye></FaEye>}
                                </InputGroup.Text>

                            </InputGroup>
                        <Form.Control.Feedback type="invalid">Vuelva a ingresar su contraseña.</Form.Control.Feedback>
                        <Form.Text className="textAlert">{this.state.message}</Form.Text>
                    </Form.Group>
                    </Form>
                    <Form.Group>
                         <div className="row justify-content-between" >
                            <Col >
                                <Button type="button" variant="primary" onClick={!this.state.isloading?this.sendData:null} 
                                className="buttonborderradius btnreestablecer"
                                disabled={this.state.isloading}>
                                    {this.state.isloading?
                                    <div className="spinnerinicio"> 
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    :"Reestablecer"}
                                    
                                </Button>
                            </Col>
                            <Col style={{textAlign: 'right'}}>
                                <Button variant="secondary"
                                    >Cancelar
                                    </Button>
                            </Col>
                        </div>
                    </Form.Group> */}
              </div>
        );
    }
    

}
