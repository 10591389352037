import React, { Component } from "react";
import { Form, Button, Row, Col, Spinner, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import UserService from "../../services/user.service";
import PayRegister from "../../components/payment/PayRegister";
import history from "../navigation/history";
import utilsService from "../../services/utils.service";

/**
 * Component to register payments usign the link from the email
 */
export default class RegisterPaymentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idSuscription: 0,
      token: "",
      suscription: {},
      loading: true,
      noData: false,
      vouchers: [],
      isPaid: false,
      loadSenData: false,
      isComplete: false,
      quotesPay: 0,
      walletAmount: 0,
      isQuotaInitialSplitting: false,
      numberQuotesInitial: 1,
      initialQuoteAmount: 0,
      objToken: {
        idPayment: null,
      },
      typeExchange: {
        venta: 1,
      },
    };

    this.getsuscription = this.getsuscription.bind(this);
  }

  componentDidMount() {
    this.getsuscription();
    this.getExchange();
  }
  async getExchange() {
    let response = await utilsService.getExchanges(); //utilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: (this.state.typeExchange = response.objModel),
        });
      }
    }
  }

  async getsuscription() {
    let url = window.location;
    let params = url.search;
    let listParams = params.split("="); //queryString.parse(params);
    // console.log(listParams)
    let id = listParams[1];
    this.setState({
      token: id,
    });

    let response = await UserService.validatetokenAfterPay(id);
    // console.log(response)
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          idSuscription: (this.state.idSuscription =
            response.objModel.idSuscriptionPay),
          objToken: response.objModel,
        });
        let suscriptionResponse = await UserService.getSuscriptionById(
          this.state.idSuscription
        );
        if (suscriptionResponse !== undefined) {
          this.setState({
            idUser: response.objModel.idUserPay,
            suscription: (this.state.suscription =
              suscriptionResponse.objModel),
            packages: (this.state.packages = [
              suscriptionResponse.objModel.packageDetailResponse,
            ]),
            packages2: (this.state.packages2 = [
              suscriptionResponse.objModel.package,
            ]),
            mountPaid:
              suscriptionResponse.objModel.packageDetailResponse.initialPrice,
            // packages: this.state.packages = [item],
            loading: (this.state.loading = false),
            noData: (this.state.noData = false),
            noDataMessage: (this.state.noDataMessage = ""),
          });
          this.getInformacionUser(response.objModel.idUserPay);
          // Si se ha particionado la inicial
          if (response.objModel.isQuotaInitialSplitting !== 0) {
            this.setState({
              isQuotaInitialSplitting: (this.state.isQuotaInitialSplitting = true),
              numberQuotesInitial: (this.state.numberQuotesInitial =
                response.objModel.numberQuotesInitial),
              initialQuoteAmount: (this.state.initialQuoteAmount =
                response.objModel.initialQuoteAmount),
            });
          }
          // TODO verify if isQuotaInitialSplitting
        } else {
          // TODO
        }
      } else {
        this.setState({
          suscription: (this.state.suscription = {}),
          loading: (this.state.loading = false),
          noData: (this.state.noData = true),
          noDataMessage: (this.state.noDataMessage =
            "Ocurrió un problema al obtener información. Regrese más tarde."),
        });
      }
    } else {
      this.setState({
        suscription: (this.state.suscription = {}),
        loading: (this.state.loading = false),
        noData: (this.state.noData = true),
        noDataMessage: (this.state.noDataMessage =
          "Ocurrió un problema al obtener información. Regrese más tarde."),
      });
    }
  }

  async getInformacionUser(id) {
    this.setState({ loading: (this.state.loading = true) });
    let response = await UserService.getUserById(id);
    if (response !== undefined && response.status === 1) {
      let suscripcion = this.state.suscription;
      suscripcion.user = response.objModel;
      this.setState({
        suscripcion: (this.state.suscription = suscripcion),
      });
    }

    this.setState({ loading: (this.state.loading = false) });
  }

  eventPay = (data, field) => {
    if (field === "vouchers") {
      // let vouchers = this.state.vouchers;
      // vouchers = vouchers.concat(data);
      this.setState({
        vouchers: data,
      });
    } else {
      this.setState({
        [field]: data,
      });
    }

    if (field === "quotesPay") {
      let total;
      if (this.state.numberQuotesInitial === 1) {
        total =
          this.state.packages[0].initialPrice +
          data * this.state.packages[0].quotaPrice;
      }
      if (this.state.numberQuotesInitial > 1) {
        let pinicial = this.state.packages[0].initialPrice;
        let fraccionini = this.state.numberQuotesInitial;
        total =
          pinicial / fraccionini + data * this.state.packages[0].quotaPrice;
        // console.log(total,data,pinicial,fraccionini)
      }
      this.setState({
        mountPaid: total,
      });
    }
  };

  eventVoucher = (data, field) => {
    // ;
    // TODO
  };

  validate = () => {
    // verify if the payment was by paypal
    if (this.state.isPaid) {
      if (this.state.status === "COMPLETED") {
        return true;
      } else {
        alert(
          "Su pago con PayPal ha sido rechazado. Seleccione otro medio de pago."
        );
        return false;
      }
    } else if (this.state.vouchers.length > 0 || this.state.walletAmount > 0) {
      let vouchersUpload = this.state.vouchers;
      let cambio = Number(this.state.typeExchange.venta);
      let totalamount = Number(0);
      let amountpay = Number(this.state.mountPaid); //Number(this.state.mountPaid)
      let comision = Number(0);

      if (vouchersUpload.length > 0) {
        for (let i = 0; i < vouchersUpload.length; i++) {
          // Split base64 code type and string
          let moneda = Number(vouchersUpload[i].typeExchange);
          let cantidad = Number(vouchersUpload[i].amount);

          if (moneda === 1) {
            //dolar
            totalamount += cantidad;
            comision = comision + Number(vouchersUpload[i].comision); //comision dolares
          } else {
            //moneda=2 sol
            cantidad /= cambio; //convertir sol a dolar
            totalamount += cantidad;
            comision =
              comision +
              Number(Number(vouchersUpload[i].comisionExchange) / cambio); //comision soles
          }
        }
        //let montowallet=Number(sessionStorage.getItem("amountWallet"));
        let montowallet = Number(this.state.walletAmount);
        if (montowallet !== undefined) {
          totalamount += montowallet;
        }
        //agregando comision al monto total

        totalamount = Number(totalamount);
        totalamount -= comision;
        // console.log(vouchersUpload)
        // console.log(cambio)
        // console.log(comision)
        // console.log(totalamount)
        // console.log(amountpay)

        /*   if (totalamount < amountpay - 1 || totalamount > amountpay + 3) {
          alert("El monto total ingresado no coincide con el monto a pagar");
          return false;
        } */
      }
      return true;
    } else {
      alert("Seleccione un medio de pago.");
      return false;
    }
  };

  /**
   * Register pay by tickets or paypal
   */
  // Paypal = 1,
  // Vouchers = 2,
  // Wallet = 3,
  // Mixto = 4,
  // Otros = 5,
  sendData = async () => {
    if (this.validate()) {
      let data = {
        // IdUserPayment: this.state.idUser,
        IdPayment: this.state.objToken.idPayment,
        IdSuscriptionPayment: this.state.idSuscription,
        // QuotesPaid: this.state.quotesPay,
        NumberAdvancePaymentPaid: this.state.quotesPay,
        AmountPaidPayment: this.state.mountPaid,
        NumberPaymentInitials: this.state.numberQuotesInitial,
      };
      // By paypal register
      if (this.state.isPaid) {
        data.TypeMethodPayment = 1;
        data.Vouchers = [];
        data.WalletTransaction = {
          Amount: 0,
        };
        data.PaypalDTO = {
          nroOperation: this.state.idTransaction,
        };
      } else if (
        this.state.vouchers.length > 0 &&
        this.state.walletAmount >= 0
      ) {
        //Vouchers
        let vouchers = [];
        let vouchersUpload = this.state.vouchers;
        let i;
        for (i = 0; i < vouchersUpload.length; i++) {
          // Split base64 code type and string
          let contenType = vouchersUpload[i].voucherBase.split(",");
          let temp = {
            MethodSubTipoPagoId: Number(vouchersUpload[i].type),
            OperationNumber: vouchersUpload[i].code,
            TotalAmount: Number(vouchersUpload[i].amount),
            PaymentCoinCurrencyId: Number(vouchersUpload[i].typeExchange),
            Note: vouchersUpload[i].nota,
            Base64: contenType[1],
          };
          vouchers.push(temp);
        }
        data.NotePay = vouchers.map((item) => item.NotePay).join(", ");

        data.TypeMethodPayment = 2;
        data.Vouchers = vouchers;
        data.WalletTransaction = {
          Amount: 0,
          ReferenceData: "",
        };
        data.PaypalDTO = {
          nroOperation: "",
        };

        // Verify wallet
        if (this.state.walletAmount > 0) {
          data.WalletTransaction = {
            Amount: this.state.walletAmount,
            ReferenceData: `Pago de inscripcion.`,
          };
          data.TypeMethodPayment = 4;
        }
      } else if (
        this.state.walletAmount > 0 &&
        this.state.mountPaid === this.state.walletAmount
      ) {
        data.WalletTransaction = {
          Amount: this.state.walletAmount,
          ReferenceData: `Pago de inscripcion.`,
        };
        data.TypeMethodPayment = 3;
      } else {
        data.TypeMethodPayment = 0;
      }
      if (data.TypeMethodPayment && data.TypeMethodPayment > 0) {
        this.setState({
          loadSenData: true,
        });
        let response = await UserService.registerPaymentLater(data);
        //  console.log(data);
        // let response= undefined;
        if (response !== undefined) {
          if (response.status === 1) {
            // sessionStorage.removeItem("amountPayLater")
            // sessionStorage.removeItem("amountPayC");
            this.setState({
              isComplete: true,
              loadSenData: false,
            });
          } else {
            this.setState({
              loadSenData: false,
            });
            alert("Ocurrió un error al momento de registrar su pago.");
          }
        } else {
          this.setState({
            loadSenData: false,
          });
          alert("Tuvimos un problema. Inténtalo más tarde.");
        }
      } else {
        alert("Asegurese se haber registrado todos sus pagos.");
      }
    }
  };

  cancelar = () => {};

  // Wallet
  eventAddWallet = (monto) => {
    this.setState({
      walletAmount: monto,
    });
  };

  eventPayWallet = async (amount) => {
    this.setState({
      loadSenData: true,
    });

    let data = {
      // IdUserPayment: this.state.idUser,
      IdPayment: this.state.objToken.idPayment,
      IdSuscriptionPayment: this.state.idSuscription,
      // QuotesPaid: this.state.quotesPay,
      NumberAdvancePaymentPaid: this.state.quotesPay,
      AmountPaidPayment: this.state.mountPaid,
      NumberPaymentInitials: this.state.numberQuotesInitial,
      WalletTransaction: {
        Amount: amount,
        ReferenceData: `Pago de inscripcion.`,
      },
      TypeMethodPayment: 3,
    };

    let response = await UserService.registerPaymentLater(data);
    //let response=undefined;
    // console.log(data);
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          isComplete: (this.state.isComplete = true),
          loadSenData: (this.state.loadSenData = false),
        });
      } else {
        this.setState({
          loadSenData: (this.state.loadSenData = false),
        });
        alert("Ocurrió un error al momento de registrar su pago.");
      }
    } else {
      this.setState({
        loadSenData: (this.state.loadSenData = false),
      });
      alert("Tuvimos un problema. Inténtalo más tarde.");
    }
  };

  returnLogin = () => {
    history.push("/home");
  };

  render() {
    const {
      suscription,
      loading,
      noData,
      noDataMessage,
      isComplete,
    } = this.state;
    return (
      <div>
        {!isComplete && (
          <div className="panel-form">
            {loading && (
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border" variant="dark"></Spinner>
                <p>Obteniendo información ...</p>
              </div>
            )}
            {!loading && !noData && (
              <div>
                {/*  AQUI ES PARA PAGAR CUANDO SE LE LLEGA POR CORREO */}
                <PayRegister
                  packages={this.state.packages}
                  packages2={this.state.packages2}
                  suscription={suscription}
                  eventPay={this.eventPay}
                  onchangePayVoucher={this.eventVoucher}
                  savePaypal={this.sendData}
                  registerPayWallet={this.eventPayWallet}
                  eventWallet={this.eventAddWallet}
                  split={{
                    isQuotaInitialSplitting: this.state.isQuotaInitialSplitting,
                    numberQuotesInitial: this.state.numberQuotesInitial,
                    initialQuoteAmount: this.state.initialQuoteAmount,
                  }}
                ></PayRegister>
                {this.state.walletAmount > 0 && (
                  <div>
                    <p>
                      <b>Monto pagado con Mi Wallet $ :</b>{" "}
                      {this.state.walletAmount}
                    </p>
                  </div>
                )}

                <div
                  className="row justify-content-between"
                  style={{ display: this.state.displayFooter }}
                >
                  {/* <Col ms={4}>
                                         <Button variant="danger"
                                             onClick={this.cancelar}
                                             >Cancelar</Button>
                                     </Col> */}
                  <Col style={{ textAlign: "right" }}>
                    <Button variant="primary" onClick={this.sendData}>
                      Guardar
                    </Button>
                  </Col>
                </div>
              </div>
            )}
            {noData && (
              <div style={{ textAlign: "center" }}>
                <Form.Label>{noDataMessage}</Form.Label>
              </div>
            )}
            <Modal
              show={this.state.loadSenData}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              centered
            >
              <Modal.Body>
                <div style={{ textAlign: "center" }}>
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="dark"
                  ></Spinner>
                  <Form.Label>&nbsp; Guardando información...</Form.Label>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
        {isComplete && (
          <div className="panel-form">
            <Row style={{ textAlign: "center" }}>
              <Col sm={12}>
                <Form.Label>Registro exitoso!</Form.Label>
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col sm={12}>
                <Button onClick={this.returnLogin}>Volver</Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
