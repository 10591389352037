import { NextUIProvider } from "@nextui-org/react";
import { NewNotificationProvider } from "context/contextNotification.js";
import React from "react";
import "./App.css";
import Routes from "./views/navigation/Routes";
import "./views/styles/HomeStyle.css";

function App() {
  //esto es una prueba de github
  return (
    <NewNotificationProvider>
      <NextUIProvider disableBaseline="false">
        <div className="App">
          <Routes />
        </div>
      </NextUIProvider>
    </NewNotificationProvider>
  );
}

export default App;
