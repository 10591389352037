import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import ChatBot, { Loading } from 'react-simple-chatbot';
import ChatBotService from "../../services/chatbot.service";
import Validation from "../utils/Validation";

export default class AvailableUserChangeData extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: true,
        result: '',
    };
  }

  componentDidMount(){
    this.AvailableUserChangeData();
  }

  async AvailableUserChangeData(){

    var nroDocument = localStorage.getItem("userDNI");
    let data = {
      nroDocument: nroDocument
    }

    ;

    let response = await ChatBotService.getUserByUsername(data);
    
    let idUser = response.objModel.idUser;
    localStorage.setItem("idUser", idUser)

    if(response != undefined){
        if(response.status == 1){
            if(response.objModel.available == 0){
                this.setState({
                    loading: false,
                    result: 'perfecto, el número de documento es ' + nroDocument +'.'
                })
                this.triggertNext();
            }else {
                this.setState({
                    loading: false,
                    result: 'Lo sentimos, este usuario no existe.'
                })
                this.triggertNextFalse();
            }
        }else{
            this.setState({
                loading: false,
                result: 'Hubo un error al consultar el DNI, intente de nuevo por favor.'
            })
            this.triggertNextError();
        }
    }else{
        this.setState({
            loading: false,
            result: 'Hubo un error al consultar el DNI, intente de nuevo por favor.'
        })
        this.triggertNextError();
    }

    /// end
  }

  triggertNext() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "3adv"});
    });
  }

  triggertNextFalse() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "3aDNI"});
    });
  }

  triggertNextError() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "3aDNI"});
    });
  }


  render() {
      const {loading, result} = this.state;

    return (
      <div className="dbpedia" style={{color: '#fff'}}>
        { loading ? <Loading /> : result }

      </div>
    );
  }

}