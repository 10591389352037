import { Loading } from "@nextui-org/react";
import "./loading.css";

export default function LoadApi(props) {
  if (props.indicatorLoad) {
    return (
      <section className="section-loading">
        <Loading size={props.size} color={props.color}></Loading>
        <h2>Cargando...</h2>
      </section>
    );
  } else {
    return <>{props.children}</>;
  }
}
