import React, { Component } from "react";
// import '../styles/Login';
import { Helmet } from "react-helmet";
import HomeIn from "../../components/web/HomeIn";

export default class HomeInView extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Inclub</title>
          <meta
            name="description"
            content="Inclub el club de inversión, ahora puedes acceder a proyectos de inversión con muchos beneficios 100% online"
          ></meta>
        </Helmet>
        <div>
          <HomeIn></HomeIn>
        </div>
      </div>
    );
  }
}
