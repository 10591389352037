import ChatBotClass from '../../components/chatbot/ChatBotClass';
import ChatBotGeneral from '../../components/chatbot/ChatBotGeneral';
import AuthService from '../../services/auth.service';
import CountDownSession from "./CountDownSession";
import NavBar from './NavBar';

function LayoutGeneral(props) {
    const logout = () => {
        // history.replace("/home-in");
        //window.open(`${process.env.REACT_APP_APP_URL}`, "_self")
    }
    const idUser = AuthService.getCurrentIdUser();
    const name = AuthService.getName();

    return (
        <section className='layout-general'>
            <NavBar logout={logout}></NavBar>
            {idUser
                ? <ChatBotClass username={idUser} user_id={name} />
                : <ChatBotGeneral />
            }
            {idUser
                ? <CountDownSession />
                : <div />
            }

            {props.children}
        </section>
    )
}

export default LayoutGeneral