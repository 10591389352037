import { useState, useRef, useEffect } from 'react';
import { Col, Form, Row, Spinner, Table, ProgressBar, Modal, Button } from 'react-bootstrap';
import { FaCheckCircle } from "react-icons/fa";

import Validation from '../utils/Validation';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';


const calcularProgress = (a, b) => {
    const value = ( a / b ) * 100;

    return value.toFixed(2);
}

export const SalesIncentives = () => {
    const [data, setData] = useState([]);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [dataPremios, setDataPremios] = useState([]);
    const [showModal, setshowModal] = useState(false);

    useEffect(() => {
        getRegisters();
        getPremios();
    }, []);

    const getRegisters = async() => {
        let id = AuthService.getCurrentIdUser();
        setLoading(true);
        setMessage(undefined);

        // Call to service
        let response = await UserService.onGetQuarterlyPeriodBonus(id);
        let ok = true;

        if(response == undefined || response == null || ( response !== null && response !== undefined && response.status !== 1 )  ){
            setMessage('Se ha producido un error. Inténtelo más tarde.');
            ok = false;
        }

        if( ok ){
            setData(response.objModel);

            if( response.objModel.length == 0 )
                setMessage('No hay registros para mostrar.')
        }

        setLoading(false);
    }


    const getPremios = async() => {
        let response = await UserService.onAwardsBonus();

        console.log('premios', response);

        if( response.objModel.length > 0 ){
            setDataPremios(response.objModel);
        }
    } 
    
    return (
        <div  style={{paddingLeft: 30, paddingRight: 30}} >
            <hr></hr>
            <Row>
                <Col sm={12}>
                    <Form.Label className="content-subtitle">Incentivo Venta</Form.Label>
                </Col>
            </Row>
            <Row style={{ textAlign: "right" }}>
                <Col sm={{ span: 4, offset: 8 }}>
                    <div
                    onClick={(e) => {
                        setshowModal(true);
                    }}
                    >
                    <Form.Label style={{ cursor: "pointer" }}>
                        Formas de calificación <FaCheckCircle />
                    </Form.Label>
                    </div>
                </Col>
            </Row>
            { loading && 
                <div>
                    <Spinner animation="border" variant="dark">
                    </Spinner>
                    <p><Form.Label>Cargando información.</Form.Label></p>
                </div>
            }
            { message && 
                <Form.Label>{message}</Form.Label>
            }
            <div>
                <Row>
                    <Col sm={12}>
                        <Table responsive>
                            <thead className="table-head">
                                <tr>
                                    <th></th>
                                    <th>Ciclo Trimestral</th>
                                    <th>Monto Vendido</th>
                                    <th>Premio</th>
                                    <th>Avances de premios</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index) => (
                                        <tr key={index} style={{ verticalAlign: 'middle' }}>
                                            <td>{ index + 1 }</td>
                                            <td> { item.initDate } AL { item.endDate }  </td>
                                            <td> { item.points } </td>
                                            <td> { item.awardsBonusName } </td>
                                            <td> { item.awardsBonusNextName } </td>
                                            <td>
                                                <div className='d-flex justify-content-between flex-row'>
                                                    <span 
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 400
                                                        }}
                                                    >
                                                        $ { item.points }
                                                    </span>
                                                    <span 
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 400
                                                        }}
                                                    >
                                                        $ { item.awardsBonusNextPoints }
                                                    </span>
                                                </div>
                                                <ProgressBar 
                                                    now={calcularProgress(item.points, item.awardsBonusNextPoints)} 
                                                    style={{
                                                        minWidth: '160px'
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
            <Modal
                dialogClassName="modal-120w"
                size="lg"
                show={showModal}
                onHide={() => {
                    setshowModal(false)
                }}
                style={{ fontSize: 12 }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} style={{textAlign: 'center'}}>
                            <Form.Label className="content-subtitle">Forma Calificación</Form.Label>
                        </Col>
                        <Col sm={12}>
                            <Table size="sm">
                                <thead>
                                    <tr>
                                        <th>Monto</th>
                                        <th>Nombre Premio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataPremios.map(item => (
                                            <tr key={item.id}>
                                                <td> { item.points } </td>
                                                <td> { item.name } </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                                setshowModal(false)
                            }}
                        >
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </div>
    )
}
