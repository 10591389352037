import React, { Component } from "react";
import LoadApi from "validators/LoadApi";
import OrganizationChart from "@dabeng/react-orgchart";
import {
  Form,
  Carousel,
  Row,
  Col,
  Card,
  Table,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../views/styles/Custom.css";
import { BsFillSquareFill } from "react-icons/bs";

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import Tree from "./netcomp/Tree";

const colorActive = "#8bc34a";
const colorInactive = "#666464"; // Inactivo
const colorEndSusciption = "#aeaeae"; // "#9e9e9e"; //
// ------------------------------
const colorPendingVal = "#ffe924";
const colorPendingCuota = "#fffc4d";
const colorPendingMigracion = "#fffd84";
// -------------------------------
const colorRejectInicial = "#FF6000";
const colorRejectCuota = "#FF7900";
const colorRejectMigracion = "#FF8500";
// ------------------------------------
const colorDeuda1 = "#E01E37";
const colorDeuda2 = "#C1192C";
const colorDeuda3 = "#B71D32";
// --------------------------------
const colorPreliquidacion = "#075396";
const colorLiquidacion = "#104167";
// ------------------------------------
const colorCongelado =
  "linear-gradient(90deg, rgba(1,139,232,1) 0%, rgba(129,196,251,1) 50%, rgba(178,238,252,1) 100%)";

export default class NetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      stateTree: false,
      loading: true,
      noDataTree: false,
      noDataMessage: "",
      showModal: false,
    };
  }

  componentDidMount() {
    this.getTree();
  }

  /**
   *
   * @param {*} temp
   * @param {*} id
   * @param {*} nivel
   */

  getChildren = (temp, item, nivel) => {
    let children = [];
    let arrayLevel = temp[nivel];

    if (arrayLevel !== undefined) {
      for (let i = 0; i < arrayLevel.length; i++) {
        let register = arrayLevel[i];
        if (register.idsocio === item.idsocio) {
          let status = this.getState(register.estadoHijo);
          if (register.idHijo > 0) {
            children.push({
              id: register.idHijo,
              idsocio: register.idHijo,
              title: register.nombreHijo,
              state: status.state,
              colorState: status.colorState,
              showChild: false,
              children: [],
              team: register.team,
            });
          }
        }
      }
    }

    // Get each children
    for (let x = 0; x < children.length; x++) {
      children[x].children = this.getChildren(temp, children[x], nivel + 1);
    }

    return children;
  };

  getMainChildren = (temp, nivel) => {
    let children = [];
    let idsChildren = [];

    //Arreglo por niveles:
    let arrayLevel = [];

    for (let i = 0; i < temp.length; i++) {
      if (
        temp[i].idsocioramaprincipal === temp[i].idsocio &&
        temp[i].nivel === nivel
      ) {
        if (!idsChildren.includes(temp[i].idsocio)) {
          // add to child array
          idsChildren.push(temp[i].idsocio);

          temp[i].id = temp[i].idsocio;
          temp[i].title = temp[i].nombreSocio;
          let state = this.getState(temp[i].estadoSocio);

          temp[i].state = state.state;
          temp[i].colorState = state.colorState;

          temp[i].showChild = false;
          // temp[i].children = [];
          children.push(temp[i]);
        }
      }

      // Add to array list
      if (arrayLevel[temp[i].nivel] === undefined) {
        arrayLevel[temp[i].nivel] = [temp[i]];
      } else {
        arrayLevel[temp[i].nivel].push(temp[i]);
      }
    }

    // Get each children
    for (let x = 0; x < children.length; x++) {
      children[x].children = this.getChildren(arrayLevel, children[x], nivel);
    }

    return children;
  };

  // get state
  getState = (idState) => {
    // Verify state
    let data = {
      state: "",
    };

    switch (idState) {
      case 0:
        // Inactivo
        data.state = "Inactivo";
        data.colorState = colorInactive;
        break;
      case 1:
        // Patrocinados directos
        data.state = "Activo";
        data.colorState = colorActive;
        break;
      case 2:
        // Pendiente de validación inicial
        data.state = "Pendiente";
        data.colorState = colorPendingVal;
        break;
      case 3:
        // Rechazo de incial";
        data.state = "Inicial rechazado";
        data.colorState = colorRejectInicial;
        break;
      case 4:
        // Pagar despues"
        data.state = "Pago después";
        data.colorState = colorActive;
        break;
      case 5:
        // Deuda 1";
        // Pagar despues"
        data.state = "Deuda 1";
        data.colorState = colorDeuda1;
        break;
      case 6:
        // Deuda 2";
        data.state = "Deuda 2";
        data.colorState = colorDeuda2;
        break;
      case 7:
        // Deuda 3";
        data.state = "Deuda 3";
        data.colorState = colorDeuda3;
      case 8:
        data.state = "Preliquidacion";
        data.colorState = colorPreliquidacion;
        break;
      case 9:
        // Congelado";
        data.state = "Congelado";
        data.colorState = colorCongelado;
        break;
      case 10:
        // Pendiente de validación";
        data.state = "Pendiente";
        data.colorState = colorPendingCuota;
        break;
      case 16:
        // Pendiente de validación";
        data.state = "Pendiente";
        data.colorState = colorPendingCuota;
      case 11:
        // Pago rechazado";
        data.state = "Cuota rechazado";
        data.colorState = colorRejectCuota;
        break;
      case 12:
        // Suscription finalizada";
        data.state = "Finalizado";
        data.colorState = colorEndSusciption;
        break;
      case 13:
        data.state = "Pendiente";
        data.colorState = colorPendingMigracion;
        break;
      case 14:
        // Suscription finalizada";
        data.state = "Rechazo";
        data.colorState = colorRejectMigracion;
        break;
      case 15:
        // Suscription finalizada";
        data.state = "Liquidacion";
        data.colorState = colorLiquidacion;
        break;
    }

    return data;
  };

  async getTree() {
    let treeResponse = await UserService.getPatrocinioTree();
    if (treeResponse !== null && treeResponse !== undefined) {
      if (treeResponse.status === 1) {
        // Add root
        let data = treeResponse.objModel;
        let i;
        let temp = data;
        let idSocio = Number(AuthService.getCurrentIdUser());
        let infoUser = AuthService.getCurrentUserInfo();
        let nivel = 1;

        let tree = {
          id: idSocio,
          title: infoUser.name + " " + infoUser.lastname,
          state: " ",
          colorState: colorActive,
        };

        let mainChildren = this.getMainChildren(temp, nivel);
        tree.children = mainChildren;
        this.setState({
          treeData: [tree],
          stateTree: true,
          loading: false,
          noDataTree: false,
          noDataMessage: "",
        });
      } else {
        this.setState({
          tree: [],
          stateTree: false,
          loading: false,
          noDataTree: true,
          noDataMessage:
            "No podemos mostrar su red. Consulte acerca de su red.",
        });
      }
    } else {
      this.setState({
        tree: [],
        stateTree: false,
        loading: false,
        noDataTree: true,
        noDataMessage: "No podemos mostrar su red. Consulte acerca de su red.",
      });
    }
  }

  updateTree = () => {
    this.setState({
      loading: true,
    });
    this.getTree();
  };

  handleShow = (e) => {
    this.setState({
      showModal: true,
    });
  };
  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  downloadExcel = async () => {
    let response = await UserService.downloadTree();
    if (response) {
      alert("Descarga exitosa.");
    } else {
      alert("Ocurrió un error. Intételo más tarde.");
    }
  };

  render() {
    const {
      treeData,
      stateTree,
      loading,
      noDataTree,
      noDataMessage,
      showModal,
    } = this.state;
    return (
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <hr></hr>
        <Row>
          <Col sm={12}>
            <Form.Label className="content-subtitle">
              Árbol de Patrocinio
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorActive}></BsFillSquareFill>{" "}
              <Form.Label>Activo</Form.Label>
              <br />
              <BsFillSquareFill color={colorInactive}></BsFillSquareFill>{" "}
              <Form.Label>Inactivo</Form.Label>
              <br />
              <BsFillSquareFill color="#9e9e9e"></BsFillSquareFill>{" "}
              <Form.Label>Suscripción finalizada</Form.Label>
              <br />
            </Form>
          </Col>
          <Col sm={3}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorPendingVal}></BsFillSquareFill>{" "}
              <Form.Label>Pte. Validación Inicial</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorPendingCuota}
              ></BsFillSquareFill>{" "}
              <Form.Label>Pte. Validación Cuota</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorPendingMigracion}
              ></BsFillSquareFill>{" "}
              <Form.Label>Pte. Validación Migración</Form.Label>
            </Form>
          </Col>
          <Col sm={2}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorRejectInicial}></BsFillSquareFill>{" "}
              <Form.Label>Rechazo Inicial</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorRejectCuota}
              ></BsFillSquareFill>{" "}
              <Form.Label>Rechazo Cuota</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorRejectMigracion}
              ></BsFillSquareFill>{" "}
              <Form.Label>Rechazo Migración</Form.Label>
            </Form>
          </Col>
          <Col sm={2}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorDeuda1}></BsFillSquareFill>{" "}
              <Form.Label>Deuda 1</Form.Label>
              <br />
              <BsFillSquareFill color={colorDeuda2}></BsFillSquareFill>{" "}
              <Form.Label>Deuda 2</Form.Label>
              <br />
              <BsFillSquareFill color={colorDeuda3}></BsFillSquareFill>{" "}
              <Form.Label>Deuda 3</Form.Label>
            </Form>
          </Col>
          <Col sm={2}>
            <Form className="box-legend">
              <BsFillSquareFill color={colorPreliquidacion}></BsFillSquareFill>{" "}
              <Form.Label>Preliquidación</Form.Label>
              <br />
              <BsFillSquareFill
                color={colorLiquidacion}
              ></BsFillSquareFill>{" "}
              <Form.Label>Liquidación</Form.Label>
              <br />
              <BsFillSquareFill color="#018be8"></BsFillSquareFill>{" "}
              <Form.Label>Congelado</Form.Label>
            </Form>
          </Col>
        </Row>
        <br />
        {loading && (
          <LoadApi
            indicatorLoad={true}
            message="Cargando árbol de patrocinio."
            messageWarning="Este proceso puede demorar unos minutos."
          ></LoadApi>
        )}
        {/* {!loading && stateTree && <Row>
          <Col sm={12}>
            <Button onClick={(e) => this.handleShow(e)}>Vista completa</Button>
          </Col>
        </Row>} */}
        {!loading && stateTree && (
          <Row style={{ paddingTop: 20 }}>
            <Col sm={12}>
              <Tree tree={treeData} updateTree={this.updateTree}></Tree>
            </Col>
          </Row>
        )}
        {!loading && noDataTree && <Form.Label>{noDataMessage}</Form.Label>}

        {/* Modal to watch  */}
        <Modal
          show={showModal}
          onHide={this.handleClose}
          size="lg"
          style={{ fontSize: 12 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Tree tree={treeData} updateTree={this.updateTree}></Tree>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size="sm" onClick={this.handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
