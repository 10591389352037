import React, { Component } from 'react';
import { Col, Form, Row, Spinner, Table } from 'react-bootstrap';

import Validation from '../utils/Validation';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';

export default class RecordRangeResidual extends Component {

  
    constructor(props){
        super(props);
        this.state = {
            registers: [],
            emptyList: true,
            message: "No hay registros para mostrar.",
            loading: false,
            activePage: 1,
            totalPages: 1,
            page: 1,
            size: 30,
            totalRegister: 0
            
        }

        this.getRegisters = this.getRegisters.bind(this);
    }

    componentDidMount() {
        this.getRegisters();

    }

   

    async getRegisters() {
        let id = AuthService.getCurrentIdUser();
        let data = {
            "SizeList": Number(this.state.size),
            "NroPage": Number(this.state.page),
            "ParametersSearched": {
                "IdSocioSearch": Number(id)
            }
        }
        // Call to service
        let response = await UserService.getResidualHistory(data);
        if(response !== undefined && response !== null){
            if(response.status !== 1) {
            
                this.setState({
                    registers: this.state.registers = [],
                    emptyList: this.state.emptyList = true,
                    loading: this.state.loading = false,
                    message: this.state.message = "Se ha producido un error. Inténtelo más tarde."
                });
            } else {
                if(response.objModel.listElements.length > 0){
                    
                    this.setState({
                        registers: this.state.registers = response.objModel.listElements,
                        totalRegister: this.state.totalRegister = response.objModel.totalElemList,
                        total: this.state.totalPages = response.objModel.totalPages,
                        emptyList: this.state.emptyList = false,
                        loading: this.state.loading = false,
                        message: this.state.mesagge = ""
                    });
                } else {
                    this.setState({
                        registers: this.state.registers = [],
                        emptyList: this.state.emptyList = true,
                        loading: this.state.loading = false,
                        message: this.state.mesagge = "No hay registros para mostrar."
                    });
                }
               
            }
        } else {
            this.setState({
                registers: this.state.registers = [],
                emptyList: this.state.emptyList = true,
                loading: this.state.loading = false,
                message: this.state.message = "Se ha producido un error. Inténtelo más tarde."
            });
        }
        
    }

    handlePageChange = (e, field) => {
        let value = Number(e.target.value);
        this.setState({
            [field]: this.state[field] = value,
            loading: true,
            registers: [],
            emptyList: true,
        });

        this.getRegisters();
        
       
    }

    handleSizeChange = (e, field) => {
        let value = Number(e.target.value);
       
        this.setState({
            size: this.state.size = value,
            page: this.state.page = 1,
            loading: true,
            registers: [],
            emptyList: true,
        });

        this.getRegisters();
        
    }

    render(){
        const { registers, activePage, page, size, totalPages,  totalRegister } = this.state;
      
        let optionPages = [];
        for(let i = 0; i < totalPages; i++) {
            optionPages.push(<option value={i+1}>{i+1}</option>)
        }

        return(
            <div style={{paddingLeft: 30, paddingRight: 30}} >
                <hr></hr>
                <Row>
                    <Col sm={12}>
                        <Form.Label className="content-subtitle">Historial de rangos residuales</Form.Label>
                    </Col>
                </Row>
                {/* Paginador */}
                <Row>
                    <Form.Label column xs={12} sm={12} md={10} xl={6}>
                        Mostrando del {size * (page - 1) + 1} al&nbsp;
                        {size * page > totalRegister ? totalRegister : size * page} de&nbsp;
                        {totalRegister} registros. &nbsp;&nbsp;| Página:&nbsp;
                        <Form.Select
                            size="sm"
                            id="selecPages"
                            defaultValue={"1"}
                            style={{display:"inline",width:"auto"}}
                            value={page}
                            onChange={e => {this.handlePageChange(e, 'page')}} 
                        >
                            {optionPages}
                        </Form.Select>
                        
                        {" "} de {totalPages}. Por página: {" "}
                        <Form.Select
                            size="sm"
                            id="selectCount"
                            defaultValue={"30"}
                            style={{display:"inline",width:"auto"}}
                            onChange={e => {this.handleSizeChange(e, 'size')}} 
                        >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                        </Form.Select>
                    </Form.Label>
                </Row>
                
                { this.state.loading && 
                    <div>
                        <Spinner animation="border" variant="dark">
                        </Spinner>
                        <p><Form.Label>Cargando información.</Form.Label></p>
                    </div>
                }
                {   this.state.emptyList && !this.state.loading && 
                    <Form.Label>{this.state.message}</Form.Label>
                }
                {  !this.state.emptyList && 
                <div>
                    <Row>
                        <Col sm={12}>
                            <Table responsive>
                                <thead className="table-head">
                                    <tr>
                                        <th></th>
                                        <th>Ciclo</th>
                                        <th>Rango</th>
                                        <th>Estado</th>
                                        <th>Puntos Directos Rama 1</th>
                                        <th>Puntos Directos Rama 2</th>
                                        <th>Puntos Directos Rama 3</th>
                                        <th>Puntos Rama 1</th>
                                        <th>Puntos Rama 2</th>
                                        <th>Puntos Rama 3</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log('ver registers =>',registers)}
                                    { registers.map(function(item, idx) {
                                            let date = "";
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{size*(page - 1) + 1 + idx}</td>
                                                        <td>Del {Validation.convertDateToString(item.initialdate)} al {Validation.convertDateToString(item.enddate)}</td>
                                                        {/* <td>Del {item.initialdate} al {item.enddate}</td> */}
                                                        <td>{item.name}</td>
                                                        <td>{item.nameStatus}</td>
                                                        <td>{item.pointsdirect1}</td>
                                                        <td>{item.pointsdirect2}</td>
                                                        <td>{item.pointsdirect3}</td>
                                                        <td>{item.points1}</td>
                                                        <td>{item.points2}</td>
                                                        <td>{item.points3}</td>
                                                    </tr>
                                                )
                                            })

                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
                }
            </div>
        );
    }
}