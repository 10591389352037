import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table, Accordion } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaEdit } from 'react-icons/fa';
import Swal from "sweetalert2";
import edit from "../../images/icons/edit.png";
import face from "../../images/icons/face.png";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import UtilService from "../../services/utils.service";
import "../../views/styles/ModalCustom.css";
import ProfileDni from "./ProfileDni";
import { FormBeneficiary } from "./FormBeneficiary";

const objectInitBeneficiary = {
  name: '',
  lastname: '',
  idDocument: '',
  nroDocument: ''
};

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.refButtonEnviarChangeName = React.createRef(null);
    this.refButtonEnviarChangeIdentification = React.createRef(null);
    this.state = {
      loadSenData: false,
      waiting: false,
      photoList: [
        {
          photoBase64: "",
        },
      ],
      nameNew: "",
      lastnameNew: "",
      documentNew: "",
      emptyDNI: true,
      emptyCorreccion: true,
      emptyName: true,
      emptyData: true,
      emptyLegal: true,
      emptyProfile: false,
      emptyList: false,
      message: "No hay registros para mostrar.",
      loading: false,
      username: "",
      hasCoafiliate: false,
      affiliateList: {},
      coaffiliateList: {},
      affiliate: {
        id: "",
        idNationality: "",
        country: "",
        name: "",
        lastname: "",
        idResidenceCountry: "",
        gender: "",
        nroTelf: "",
        birthday: "",
        idDocument: "",
        nroDocument: "",
        email: "",
        districtAddress: "",
        address: "",
        otherDocument: "",
      },
      newAffiliate: {},
      coaffiliate: {
        idcoaf: "",
        namecoaf: "",
        lastnamecoaf: "",
        idDocumentcoaf: "",
        nroDocumentcoaf: "",
        otherDocumentcoaf: "",
      },
      newCoaffiliate: {
        id: null,
        nameco: "",
        lastnameco: "",
        idDocumentco: "",
        birthdateco: "",
        nroDocumentco: "",
        idUser: "",
        otherDocumentco: null,
      },
      nationalities: [],
      typeDocuments: [],
      tempDocuments: [],
      typeDocumentsCo: [],
      tempDocumentsCo: [],
      typeDocumentsCoAdd: [],
      tempDocumentsCoAdd: [],

      showOthers: "none",
      showOthersCo: "none",
      showOthersCoAdd: "none",
      codephone: "",
      messageEmail: "",
      residenceList: [],
      residenceListCode: [],
      isCoaffiliate: false,
      addCoaffiliateMessage: "¿Deseas agregar a un Co-Afiliado?",
      addCoafiliate: false,
      messageDocCo: "",
      isRegister: false,
      isRegisterMail: false,
      messageDocCoa: "",
      showModalBeneficiary: false,
      listBeneficiaries: [],
      typeFormBeneficiary: 'store',
      beneficiary: objectInitBeneficiary
    };
    this.getCountries = this.getCountries.bind(this);
    this.getResidences = this.getResidences.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.createItemTypesAdd = this.createItemTypesAdd.bind(this);
    this.sweetAlert = this.sweetAlert.bind(this);
  }
  componentDidMount() {
    this.getUserInfo();
    this.getListBeneficiaries();
    this.createItemTypesAdd();
  }

  async getListBeneficiaries(){
    const idSponsor = Number(AuthService.getCurrentIdUser());
    const resp = await UserService.getListBeneficiaries(idSponsor);

    console.log(resp, 'lista de beneficiarios')

    if (resp !== null && resp !== undefined) {
      if (resp.status === 1) {
        this.setState({
          listBeneficiaries: resp.objModel
        });
      }
    }
  }

  async getUserInfo() {
    let response = await UserService.getInfobyUser();

    if (response !== null && response !== undefined) {
      if (response.status === 1) {
        if (response.objModel.user !== null) {
          this.setState({
            lastAffiliate: (this.state.lastAffiliate = Object.assign(
              {},
              response.objModel.user
            )),
            lastCoaffiliate: (this.state.lastCoaffiliate = Object.assign(
              {},
              response.objModel.coAffiliate
            )),
            newAffiliate: (this.state.newAffiliate = Object.assign(
              {},
              response.objModel.user
            )),
            newCoaffiliate: (this.state.newCoaffiliate = Object.assign(
              {},
              response.objModel.coAffiliate
            )),
            emptyList: (this.state.emptyList = false),
            loading: (this.state.loading = false),
            message: (this.state.mesagge = ""),
          });
          if (
            response.objModel.coAffiliate !== null &&
            response.objModel.coAffiliate.hasOwnProperty("id")
          ) {
            this.setState({
              hasCoafiliate: (this.state.hasCoafiliate = true),
            });
          }

          this.getAffiliateInfo();
          this.getCountries();
          this.getResidences();
          this.createItemTypes();
          this.createItemTypesCo();
          this.createItemTypesAdd();
        } else {
          this.setState({
            affiliateList: (this.state.affiliateList = {}),
            coaffiliateList: (this.state.coaffiliateList = {}),
            emptyList: (this.state.emptyList = true),
            loading: (this.state.loading = false),
            noData: false,
            message: "No hay registros para mostrar",
          });
        }
      } else {
        this.setState({
          affiliateList: (this.state.affiliateList = {}),
          coaffiliateList: (this.state.coaffiliateList = {}),
          emptyList: (this.state.emptyList = true),
          loading: (this.state.loading = false),
          noData: true,
          message: "No hay información para mostrar.",
        });
      }
    } else {
      this.setState({
        affiliateList: {},
        coaffiliateList: {},
        emptyList: (this.state.emptyList = true),
        loading: (this.state.loading = false),
        message: (this.state.mesagge = "No hay registros para mostrar."),
      });
    }
  }
  async createItemTypesAdd() {
    var id = this.state.affiliate.idNationality;

    if (id > 0) {
      let response = await UtilService.getTypeDocByNat(id);
      if (response !== null && response !== undefined) {
        let items = [];
        if (response.status === 1 && response.objModel.length > 0) {
          response.objModel.forEach((elem) => {
            items.push(
              <option key={elem.id} value={elem.id}>
                {elem.name}
              </option>
            );
          });
        } else {
          items = this.state.tempDocumentsCoAdd;
        }

        this.setState({ typeDocumentsCoAdd: items });
        this.forceUpdate();
      }
    }
  }

  async createItemTypes() {
    var id = this.state.newAffiliate.idNationality;

    if (id > 0) {
      let response = await UtilService.getTypeDocByNat(id);
      if (response !== null && response !== undefined) {
        let items = [];
        if (response.status === 1 && response.objModel.length > 0) {
          response.objModel.forEach((elem) => {
            items.push(
              <option key={elem.id} value={elem.id}>
                {elem.name}
              </option>
            );
          });
        } else {
          items = this.state.tempDocuments;
        }

        this.setState({ typeDocuments: items });
        this.forceUpdate();
      }
    }
  }
  async createItemTypesCo() {
    var id = this.state.affiliate.idNationality;

    if (id > 0) {
      let response = await UtilService.getTypeDocByNat(id);
      if (response !== null && response !== undefined) {
        let items = [];
        if (response.status === 1 && response.objModel.length > 0) {
          response.objModel.forEach((elem) => {
            if (elem.id == this.state.coaffiliate.idDocumentcoaf) {
              items.push(
                <option key={elem.id} value={elem.id} selected>
                  {elem.name}
                </option>
              );
            } else {
              items.push(
                <option key={elem.id} value={elem.id}>
                  {elem.name}
                </option>
              );
            }
          });
        } else {
          items = this.state.tempDocumentsCo;
        }

        this.setState({ typeDocumentsCo: items });
        this.forceUpdate();
      }
    }
  }
  async getResidences() {
    let response = await UtilService.getResidences();
    if (response !== null && response !== undefined) {
      let objs = response.objModel;
      let residences = [];
      objs.forEach((elem) => {
        if (elem.idCountry == this.state.affiliate.idResidenceCountry) {
          residences.push(
            <option key={elem.idCountry} value={elem.idCountry} selected>
              {elem.name}
            </option>
          );
        } else {
          residences.push(
            <option key={elem.idCountry} value={elem.idCountry}>
              {elem.name}
            </option>
          );
        }
      });
      this.setState({
        residenceList: (this.state.residenceList = residences),
        residenceListCode: (this.residenceListCode = objs),
      });
    }
  }

  // Get list of countries
  async getCountries() {
    let response = await UtilService.getResidences();
    if (response !== null && response !== undefined) {
      let objs = response.objModel;
      let residences = [];

      objs.forEach((elem) => {
        residences.push(
          <option key={elem.idCountry} value={elem.idCountry}>
            {elem.name}
          </option>
        );
      });

      this.setState({
        nationalities: (this.state.nationalities = residences),
        showOthers: (this.state.showOthers = "none"),
        otherDocument: (this.state.otherDocument = ""),
        showOthersCo: (this.state.showOthersCo = "none"),
        otherDocumentcoaf: (this.state.otherDocumentcoaf = ""),
      });
    }
  }

  handleRadio = (e, field) => {
    var value = e.target.value;
    let temp = this.state.newAffiliate;
    temp[field] = value;

    this.setState({
      newAffiliate: (this.state.newAffiliate = temp),
    });
  };

  async getAffiliateInfo() {
    let affiliate = this.state.affiliateList;
    let coaffiliate = this.state.coaffiliateList;

    if (affiliate !== undefined) {
      if (coaffiliate !== null) {
        let coaffiliateInfo = {
          idcoaf: coaffiliate.id,
          namecoaf: coaffiliate.name,
          lastnamecoaf: coaffiliate.lastname,
          idDocumentcoaf: coaffiliate.idDocument,
          nroDocumentcoaf: coaffiliate.nroDocument,
          otherDocumentcoaf: coaffiliate.otherDocument,
        };
        this.setState({
          coaffiliate: (this.state.coaffiliate = coaffiliateInfo),
          isCoaffiliate: (this.state.isCoaffiliate = true),
        });
      } else {
        let coaffiliateInfo = {
          idcoaf: -1,
          namecoaf: "",
          lastnamecoaf: "",
          idDocumentcoaf: -1,
          nroDocumentcoaf: "",
          otherDocumentcoaf: "",
        };
        this.setState({
          coaffiliate: (this.state.coaffiliate = coaffiliateInfo),
          isCoaffiliate: (this.state.isCoaffiliate = false),
          //addCoaffiliateMessage: this.addCoaffiliateMessage = "¿Deseas agregar a un Co-Afiliado?",
          addCoaffiliateMessage: (this.addCoaffiliateMessage = ""),
        });
      }

      let affiliateInfo = {
        id: affiliate.id,
        name: affiliate.name,
        lastname: affiliate.lastname,
        idNationality: affiliate.idNationality,
        gender: affiliate.gender,
        nroTelf: affiliate.nroTelf,
        birthdate: affiliate.birthdate,
        idDocument: affiliate.idDocument,
        nroDocument: affiliate.nroDocument,
        email: affiliate.email,
        districtAddress: affiliate.districtAddress,
        idResidenceCountry: affiliate.idResidenceCountry,
        address: affiliate.address,
        otherDocument: affiliate.otherDocument,
        civilState: affiliate.civilState,
      };
      this.setState({
        affiliate: (this.state.affiliate = affiliateInfo),
        loading: false,
        noData: false,
      });
    } else {
      this.setState({
        affiliate: (this.state.affiliate = {}),
        loaded: false,
        loading: false,
        noData: true,
        message: "No hay información para mostrar.",
      });
    }
  }

  handleChange = (e, field) => {
    let value = e.target.value;

    let temp = this.state.newAffiliate;
    temp[field] = value;
    this.setState({
      newAffiliate: (this.state.newAffiliate = temp),
      messageDoc: "",
    });
  };

  handleNewChange = (e, field) => {
    let value = e.target.value;
    let temp = this.state.newCoaffiliate;
    temp[field] = value;

    this.setState({
      newCoaffiliate: (this.state.newCoaffiliate = temp),
      messageDoc: "",
    });
  };

  /** Verify document */
  onBlurDocument = (e, field, fieldMessage) => {
    this.verifyDocument(field, fieldMessage);
  };

  async verifyDocument(field, fieldMessage) {
    if (
      this.state.newAffiliate.nroDocument.length > 0 &&
      this.state.newAffiliate.nroDocument !==
        this.state.lastAffiliate.nroDocument
    ) {
      this.setState({
        waiting: (this.state.waiting = true),
      });
      let data = {
        nroDocument: this.state.newAffiliate.nroDocument,
      };

      let isRegister = await UserService.isDocumentRegistered(data);

      if (!isRegister) {
        this.setState({
          [fieldMessage]: "",
          isRegister: false,
          waiting: (this.state.waiting = false),
        });

        this.forceUpdate();
      } else {
        this.setState({
          isRegister: true,
          [fieldMessage]: "Este documento ya ha sido registrado.",
          waiting: (this.state.waiting = false),
        });

        this.forceUpdate();
      }
    } else {
      this.setState({
        isRegister: (this.state.isRegister = false),
      });
    }
  }
  // End verify document

  onBlurDocumentCo = (e, field, fieldMessage) => {
    this.verifyDocumentCo(field, fieldMessage);
  };

  async verifyDocumentCo(field, fieldMessage) {
    // verify is the same value
    if (this.state.newCoaffiliate.nroDocumentco !== null) {
      if (
        this.state.newCoaffiliate.nroDocument.length > 0 &&
        this.state.newCoaffiliate.nroDocument !==
          this.state.lastCoaffiliate.nroDocument
      ) {
        let data = {};

        data.nroDocument = this.state.newCoaffiliate.nroDocument;

        let isRegister = await UserService.isDocumentRegistered(data);

        if (!isRegister) {
          this.setState({ [fieldMessage]: "" });
          this.forceUpdate();
        } else {
          this.setState({
            [fieldMessage]: "Este documento ya ha sido registrado.",
          });
        }
      }
    }
  }

  /**Verify document coaffiliate */
  onBlurDocumentCoAdd = (e, field, fieldMessage) => {
    this.verifyDocumentCoAdd(field, fieldMessage);
  };

  async verifyDocumentCoAdd(field, fieldMessage) {
    if (this.state.newCoaffiliate.nroDocumentco.length > 0) {
      let data = {};

      data.nroDocument = this.state.newCoaffiliate.nroDocumentco;
      let isRegister = await UserService.isDocumentRegistered(data);

      if (!isRegister) {
        this.setState({ [fieldMessage]: "" });
        this.forceUpdate();
      } else {
        this.setState({
          [fieldMessage]: "Este documento ya ha sido registrado.",
        });
        this.forceUpdate();
      }
    }
  }
  validationArchivo(item) {
    if (item.photoBase64.length <= 0) {
      alert("Cargue una imagen.");
      return false;
    }

    return true;
  }

  // end verify document affiliate

  validationAffiliate = () => {
    if (this.state.newAffiliate.name.length === 0) {
      alert("Ingrese sus nombres.");

      return false;
    }
    if (this.state.newAffiliate.lastname.length === 0) {
      alert("Ingrese sus apellidos.");

      return false;
    }

    if (this.state.newAffiliate.gender.length === 0) {
      alert("Seleccione su sexo.");

      return false;
    }
    if (this.state.newAffiliate.idNationality <= 0) {
      alert("Seleccione su nacionalidad.");

      return false;
    }

    if (this.state.newAffiliate.idDocument <= 0) {
      alert("Seleccione un tipo de documento.");

      return false;
    }
    if (this.state.newAffiliate.nroDocument.length === 0) {
      alert("Ingrese su número de documento.");

      return false;
    } else if (this.state.isRegister) {
      alert("Ingrese otro número de documento.");
      return false;
    }

    if (this.state.newAffiliate.email.length === 0) {
      alert("Ingrese su correo electrónico.");

      return false;
    } else if (this.state.isRegisterMail) {
      alert("Ingrese otro correo electrónico.");
      return false;
    }

    if (this.state.newAffiliate.idResidenceCountry <= 0) {
      alert("Seleccione su país de residencia.");

      return false;
    }
    if (this.state.newAffiliate.districtAddress.length === 0) {
      alert("Ingrese su distrito/estado.");

      return false;
    }
    if (this.state.newAffiliate.address.length === 0) {
      alert("Ingrese su dirección.");

      return false;
    }
    if (this.state.newAffiliate.nroTelf.length === 0) {
      alert("Ingrese su número de celular.");

      return false;
    }

    // Verify coaffilate
    if (this.state.hasCoafiliate) {
      if (this.state.newCoaffiliate.name.length <= 0) {
        alert("Ingrese nombres de su co-afiliado.");
        return false;
      }

      if (this.state.newCoaffiliate.lastname.length <= 0) {
        alert("Ingrese apellidos de su co-afiliado.");
        return false;
      }

      if (this.state.newCoaffiliate.idDocument === 0) {
        alert("Seleccione tipo de documento de su co-afiliado.");
        return false;
      }

      if (this.state.newCoaffiliate.nroDocument.length <= 0) {
        alert("Ingrese numero de documento de su co-afiliado.");
        return false;
      }
    }

    return true;
  };

  verifyData = () => {
    var data = this.state.affiliateMod;
    if (!data.hasOwnProperty("idNationality")) {
      data.idNationality = Number(this.state.affiliate.idNationality);
    }
    if (!data.hasOwnProperty("idResidenceCountry")) {
      data.idResidenceCountry = Number(this.state.affiliate.idResidenceCountry);
    }
    if (!data.hasOwnProperty("idDocument")) {
      data.idDocument = this.state.affiliate.idDocument;
    }
    if (!data.hasOwnProperty("name")) {
      data.name = this.state.affiliate.name;
    }
    if (!data.hasOwnProperty("lastname")) {
      data.lastname = this.state.affiliate.lastname;
    }

    if (!data.hasOwnProperty("gender")) {
      data.gender = this.state.affiliate.gender;
    }
    if (!data.hasOwnProperty("nroDocument")) {
      data.nroDocument = this.state.affiliate.nroDocument;
    }
    if (!data.hasOwnProperty("districtAddress")) {
      data.districtAddress = this.state.affiliate.districtAddress;
    }
    if (!data.hasOwnProperty("address")) {
      data.address = this.state.affiliate.address;
    }
    if (!data.hasOwnProperty("nroTelf")) {
      data.nroTelf = this.state.affiliate.nroTelf;
    }
    if (!data.hasOwnProperty("email")) {
      data.email = this.state.affiliate.email;
    }
    if (!data.hasOwnProperty("otherDocument")) {
      data.otherDocument = this.state.affiliate.otherDocument;
    }

    if (!data.hasOwnProperty("namecoaf")) {
      data.namecoaf = this.state.coaffiliate.namecoaf;
    }
    if (!data.hasOwnProperty("lastnamecoaf")) {
      data.lastnamecoaf = this.state.coaffiliate.lastnamecoaf;
    }
    if (!data.hasOwnProperty("nroDocumentcoaf")) {
      data.nroDocumentcoaf = this.state.coaffiliate.nroDocumentcoaf;
    }
    if (!data.hasOwnProperty("otherDocumentcoaf")) {
      data.otherDocumentcoaf = this.state.coaffiliate.otherDocumentcoaf;
    }
    if (!data.hasOwnProperty("idDocumentcoaf")) {
      data.idDocumentcoaf = this.state.coaffiliate.idDocumentcoaf;
    }
    data.id = this.state.affiliate.id;

    data.idcoaf = this.state.coaffiliate.idcoaf;

    return data;
  };

  // Update data
  onUpdate = async (e) => {
    e.preventDefault();

    if (this.validationAffiliate()) {
      this.setState({
        loadSenData: (this.state.loadSenData = true),
      });

      let data = this.state.newAffiliate;
      // ADD coa-afiliate
      if (this.state.hasCoafiliate) {
        data.idcoaf = this.state.newCoaffiliate.id;
        data.namecoaf = this.state.newCoaffiliate.name;
        data.lastnamecoaf = this.state.newCoaffiliate.lastname;
        data.idDocumentcoaf = this.state.newCoaffiliate.idDocument;
        data.nroDocumentcoaf = this.state.newCoaffiliate.nroDocument;
        data.otherDocumentcoaf = this.state.newCoaffiliate.otherDocument;
        data.civilState = "";
        data.birthdate = this.state.newCoaffiliate.birthdate;
      }
      let response = await UserService.updateAffiliateInfo(data);
      if (response === undefined) {
        this.setState({
          loadSenData: (this.state.loadSenData = false),
        });

        alert("Ocurrió un problema. Inténtelo más tarde.");
      } else {
        this.setState({
          loadSenData: (this.state.loadSenData = false),
        });

        if (response.status === 0) {
          alert(response.description);
        } else {
          alert("Tus datos han sido actualizados.");
        }
      }
    }
  };

  handleSelect = (e, field) => {
    let value = e.target.value;
    let temp = this.state.affiliateMod;
    temp[field] = Number(value);
    this.setState({
      affiliateMod: (this.state.affiliateMod = temp),
      messageDoc: "",
    });

    if (field === "idDocumentcoaf") {
      let text = e.target.options[e.target.selectedIndex].text.toUpperCase();
      if (text.includes("OTRO")) {
        this.setState({
          showOthersCo: (this.state.showOthersCo = "inline"),
        });
      } else {
        this.setState({
          showOthersCo: (this.state.showOthersCo = "none"),
          otherDocumentcoaf: (this.state.otherDocumentcoaf = ""),
        });
      }

      this.forceUpdate();
    }
  };
  handleSelectAdd = (e, field) => {
    let value = e.target.value;

    let temp = this.state.newCoaffiliate;
    temp[field] = Number(value);
    this.setState({
      newCoaffiliate: (this.state.newCoaffiliate = temp),
      messageDoc: "",
    });

    if (field === "idDocumentco") {
      let text = e.target.options[e.target.selectedIndex].text.toUpperCase();
      if (text.includes("OTRO")) {
        this.setState({
          showOthersCoAdd: (this.state.showOthersCoAdd = "inline"),
        });
      } else {
        this.setState({
          showOthersCoAdd: (this.state.showOthersCoAdd = "none"),
          otherDocumentco: (this.state.otherDocumentco = ""),
        });
      }

      this.forceUpdate();
    }
  };

  handleSelectList = (e, field) => {
    let value = e.target.value;
    let temp = this.state.newAffiliate;
    temp[field] = Number(value);

    this.setState({
      newAffiliate: (this.state.newAffiliate = temp),
      messageDoc: "",
    });
    if (field === "idNationality") {
      this.createItemTypes(this.state.newAffiliate.idNationality);
    }
    if (field === "idDocument") {
      let text = e.target.options[e.target.selectedIndex].text.toUpperCase();
      if (text.includes("OTRO")) {
        this.setState({
          showOthers: (this.state.showOthers = "inline"),
        });
      } else {
        this.setState({
          showOthers: (this.state.showOthers = "none"),
          otherDocument: (this.state.otherDocument = ""),
        });
      }

      this.forceUpdate();
    }
    if (field === "idDocumentcoaf") {
      let text = e.target.options[e.target.selectedIndex].text.toUpperCase();
      if (text.includes("OTRO")) {
        this.setState({
          showOthersCo: (this.state.showOthersCo = "inline"),
        });
      } else {
        this.setState({
          showOthersCo: (this.state.showOthersCo = "none"),
          otherDocumentcoaf: (this.state.otherDocumentcoaf = ""),
        });
      }

      this.forceUpdate();
    }
  };

  /** Verify eamil */
  async verifyEmail(email, field, fieldMessage) {
    let data = {};
    data.email = email;
    this.setState({
      waiting: (this.state.waiting = true),
    });
    let isRegistered = await UserService.isEmailRegistered(data);
    if (isRegistered) {
      this.setState({
        isRegisterMail: true,
        [fieldMessage]: (this.state[fieldMessage] =
          "Este correo ya está registrado."),
        waiting: (this.state.waiting = false),
      });
    } else {
      this.setState({
        isRegisterMail: false,
        [fieldMessage]: (this.state[fieldMessage] = ""),
        waiting: (this.state.waiting = false),
      });
    }
  }

  onBlurEmail = (e, field, fieldMessage) => {
    let value = e.target.value.trim().toLowerCase();

    if (
      value.length > 0 &&
      value !== this.state.lastAffiliate.email.toLowerCase()
    ) {
      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
        this.setState({
          [fieldMessage]: (this.state[fieldMessage] = ""),
        });

        this.verifyEmail(value, field, fieldMessage);
      } else {
        this.setState({
          [field]: (this.state[field] = ""),
          [fieldMessage]: (this.state[fieldMessage] =
            "Ingrese un correo válido."),
        });
      }
    } else {
      this.setState({
        isRegisterMail: (this.state.isRegisterMail = false),
      });
    }
  };
  // End verify email

  handleCloseInsert = () => {
    this.setState({
      addCoafiliate: false,
    });
  };
  handleAddAfiliate = (e) => {
    this.setState({
      addCoafiliate: true,
    });

    let temp = {
      nameco: "",
      lastnameco: "",
      idDocumentco: 0,
      nroDocumentco: "",
    };

    this.setState({
      newCoaffiliate: (this.state.newCoaffiliate = temp),
    });
  };
  validationCoAfiliate = () => {
    if (this.state.newCoaffiliate.nameco.length === 0) {
      alert("Ingrese los nombres de su coafiliado.");

      return false;
    }
    if (this.state.newCoaffiliate.lastnameco.length === 0) {
      alert("Ingrese sus apellidos.");

      return false;
    }

    if (this.state.newCoaffiliate.idDocumentco <= 0) {
      alert("Seleccione un tipo de documento.");

      return false;
    }
    if (this.state.newCoaffiliate.nroDocumentco.length === 0) {
      alert("Ingrese su número de documento.");

      return false;
    }

    return true;
  };

  // Register coaffliate
  onInsert = async (e) => {
    e.preventDefault();

    if (this.validationCoAfiliate()) {
      let data = {};
      data.id = 0;
      data.name = this.state.newCoaffiliate.nameco;
      data.lastname = this.state.newCoaffiliate.lastnameco;
      data.idDocument = this.state.newCoaffiliate.idDocumentco;
      data.birthdate = "1971-02-21";
      data.nroDocument = this.state.newCoaffiliate.nroDocumentco;
      data.idUser = Number(AuthService.getCurrentIdUser());
      data.otherDocument = this.state.newCoaffiliate.otherDocumentco;

      let response = await UserService.registerCoafiliate(data);

      if (response !== undefined) {
        if (response.status === 1) {
          this.setState({
            isComplete: (this.state.isComplete = true),
            handleCloseInsert: (this.state.handleCloseInsert = false),
            hasCoafiliate: (this.state.hasCoafiliate = true),
          });
          alert("Se agregó coafiliado.");
          window.location.reload();
        } else {
          this.setState({
            loadSenData: (this.state.loadSenData = false),
          });
          alert("Ocurrió un error al ingresar los datos del Co Afiliado.");
        }
      } else {
        this.setState({
          loadSenData: (this.state.loadSenData = false),
        });
        alert("Tuvimos un problema. Inténtalo más tarde.");
      }
    }
  };

  OnClick = (e, path) => {
    this.setState({
      emptyProfile: true,
      emptyData: false,
    });
  };

  OnClicked = async (e, path) => {
    //let idUser = AuthService.getCurrentIdUser();
    //var id = { idUser: parseInt(idUser) };
    //let respon = await UserService.BasicDataChange(id);

    this.setState({
      emptyLegal: false,
      emptyData: true,
      //idDataChange: respon.objModel.id,
    });

    // if (respon !== undefined) {
    //   if (respon.status === 1) {
    //     this.setState({
    //       emptyName: false,
    //       emptyData: true,
    //       idDataChange: respon.objModel.id,
    //     });
    //   } else {
    //     alert("Ocurrio un problema");
    //   }
    // } else {
    //   alert("Ocurrio un problema");
    // }
  };

  OnClicked2 = async (e, path) => {
    this.setState({
      emptyDNI: false,
      emptyData: true,
      idMotiveChange: 2,
    });

    /*let idUser = AuthService.getCurrentIdUser();
    var id = {
      idUser: parseInt(idUser),
    };
    let respon = await UserService.BasicDataChange(id);
    if (respon != undefined) {
      if (respon.status === 1) {
        this.setState({
          emptyDNI: false,
          emptyData: true,
          idDataChange: respon.objModel.id,
          idMotiveChange: 2,
        });
      } else {
        alert("Ocurrio un problema");
      }
    } else {
      alert("Ocurrio un problema");
    }*/
  };

  backView = (e, path) => {
    if (path === "name-data") {
      this.setState({
        emptyName: true,
        emptyDNI: true,
        emptyData: false,
        emptyLegal: true,
        emptyCorreccion: true,
      });
    }
    if (path === "profile") {
      this.setState({
        emptyProfile: false,
        emptyData: true,
      });
    }
    if (path === "data") {
      this.setState({
        emptyName: true,
        emptyDNI: true,
        emptyData: false,
      });
    }
    if (path === "name") {
      this.setState({
        emptyName: false,
        emptyLegal: true,
        emptyCorreccion: true,
      });
    }
  };

  handleClick = (e, path) => {
    if (path === "legalname") {
      this.setState({
        emptyData: true,
        emptyName: true,
        emptyLegal: false,
        idMotiveChange: 1,
      });
    }
    if (path === "correccion") {
      this.setState({
        emptyData: true,
        emptyName: true,
        emptyLegal: true,
        emptyCorreccion: false,
        idMotiveChange: 3,
      });
    }
  };

  // Handle table voucher
  onFileChange = (event, id) => {
    let image = event.target.files[0];
    let imgBytes = image.size,
      sizeActual,
      limitSize = 5242880;

    if (imgBytes < 1024) {
      sizeActual = imgBytes + "bytes";
    } else if (imgBytes >= 1024 && imgBytes < 1048576) {
      sizeActual = ((imgBytes / 1024).toFixed(1) + "KB").toString();
    } else if (imgBytes >= 1048576) {
      sizeActual = ((imgBytes / 1048576).toFixed(1) + "MB").toString();
    }

    if (image !== undefined) {
      if (imgBytes > limitSize) {
        alert("¡La imagen pesa mas de 5MB, suba otra imagen!");
        this.refButtonEnviarChangeName.current &&
          (this.refButtonEnviarChangeName.current.disabled = true);
        this.refButtonEnviarChangeIdentification.current &&
          (this.refButtonEnviarChangeIdentification.current.disabled = true);
      } else {
        this.refButtonEnviarChangeName.current &&
          (this.refButtonEnviarChangeName.current.disabled = false);
        this.refButtonEnviarChangeIdentification.current &&
          (this.refButtonEnviarChangeIdentification.current.disabled = false);
        this.getBase64(image, (result) => {
          let vouchers = this.state.photoList;
          let i = 0;
          for (i = 0; i < vouchers.length; i++) {
            if (i === id) {
              vouchers[i].photoBase64 = result.split(",")[1];
            }
          }
          this.setState({ photoList: vouchers });
        });
      }
    } else {
      if (imgBytes > limitSize) {
        alert("¡La imagen pesa mas de 5MB, suba otra imagen!");
        this.refButtonEnviarChangeName.current &&
          (this.refButtonEnviarChangeName.current.disabled = true);
        this.refButtonEnviarChangeIdentification.current &&
          (this.refButtonEnviarChangeIdentification.current.disabled = true);
      } else {
        this.refButtonEnviarChangeName.current &&
          (this.refButtonEnviarChangeName.current.disabled = false);
        this.refButtonEnviarChangeIdentification.current &&
          (this.refButtonEnviarChangeIdentification.current.disabled = false);
        let vouchers = this.state.photoList;
        let i = 0;
        for (i = 0; i < vouchers.length; i++) {
          if (i === id) {
            vouchers[i].photoBase64 = "";
          }
        }
        this.setState({ photoList: vouchers });
      }
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      //console.log('Error: ', error);
    };
  }

  sweetAlert({
    nameAlert = "😎 Guardado con éxito",
    typeAlert = "info",
    time,
  }) {
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: typeAlert,
      title: nameAlert,
      showConfirmButton: false,
      timer: time,
    });
  }

  // Save img flyer
  saveFlyer = async (type) => {
    const idUser = parseInt(AuthService.getCurrentIdUser());
    if (type === "change-number-identification") {
      const objChangeNumberDocumention = {
        IdUserGenerateTicket: idUser,
        IdTypeTicket: 28,
        Observation: "",
        IdTicketCreationMedium: 2,
        TicketDetailRequestData: {
          TicketDetailRequestChangeNroDocument: {
            IdUserChange: idUser,
            NewNroDocument: this.state.documentNew,
            PictureIdentityDocument: {
              Base64: this.state.photoList[0].photoBase64,
            },
          },
        },
      };

      if (window.navigator.onLine !== true) {
        alert("No estas conectado a internet");
      } else {
        this.sweetAlert({
          nameAlert: "📡 Generando el ticket...",
          typeAlert: "info",
        });
        const enlaceSweetAlert = this.sweetAlert.bind(this);
        fetch(`${process.env.REACT_APP_APP_URL_API}/Ticket/generate`, {
          method: "POST",
          body: JSON.stringify(objChangeNumberDocumention),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function(response) {
            if (response.ok) {
              enlaceSweetAlert({
                nameAlert:
                  "Se genero el ticket, de cambio de DNI correctamente",
                typeAlert: "success",
                time: 1800,
              });
            } else {
              async function detailError() {
                const getMessageError = await response.json();
                enlaceSweetAlert({
                  nameAlert: `¡No se genero el ticket! ${getMessageError.description}`,
                  typeAlert: "error",
                  time: 2500,
                });
              }
              let enlaceDetailError = detailError.bind(enlaceSweetAlert);
              enlaceDetailError();
            }
          })
          .catch((error) => {
            enlaceSweetAlert({
              nameAlert:
                "¡No se genero el ticket de cambio de DNI correctamente!",
              typeAlert: "error",
              time: 2500,
            });
          });
      }
    } else if (type === "change-name") {
      const objChangeName = {
        IdUserGenerateTicket: idUser,
        IdTypeTicket: 27,
        Observation: "",
        IdTicketCreationMedium: 2,
        TicketDetailRequestData: {
          TicketDetailRequestChangeName: {
            IdUserChange: idUser,
            NewName: this.state.nameNew,
            NewLastName: this.state.lastnameNew,
            PictureIdentityDocument: {
              Base64: this.state.photoList[0].photoBase64,
            },
          },
        },
      };

      if (window.navigator.onLine !== true) {
        alert("No estas conectado a internet");
      } else {
        this.sweetAlert({
          nameAlert: "📡 Generando el ticket...",
          typeAlert: "info",
        });
        const enlaceSweetAlert = this.sweetAlert.bind(this);
        fetch(`${process.env.REACT_APP_APP_URL_API}/Ticket/generate`, {
          method: "POST",
          body: JSON.stringify(objChangeName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function(response) {
            if (response.ok) {
              enlaceSweetAlert({
                nameAlert:
                  "Se genero el ticket de cambio de nombre correctamente",
                typeAlert: "success",
                time: 1800,
              });
            } else {
              async function detailError() {
                const getMessageError = await response.json();
                enlaceSweetAlert({
                  nameAlert: `¡No se genero el ticket! ${getMessageError.description}`,
                  typeAlert: "error",
                  time: 2500,
                });
              }
              let enlaceDetailError = detailError.bind(enlaceSweetAlert);
              enlaceDetailError();
            }
          })
          .catch((error) => {
            enlaceSweetAlert({
              nameAlert:
                "¡No se genero el ticket de cambio de nombre correctamente!",
              typeAlert: "error",
              time: 2500,
            });
          });
      }
    }
    /*let i = 0;
    let flag = true;
    for (i = 0; i < vouchers.length; i++) {
      if (!this.validationArchivo(vouchers[i])) {
        flag = false;
        break;
      }
    }
    if (flag) {
      // && this.state.imgFlyer != this.state.imgFlyerPre) {
      let data = {
        idDataChange: this.state.idDataChange,
        idMotiveChange: this.state.idMotiveChange,
        photoList: this.state.photoList,
        nombreEdit: this.state.nameNew,
        apellidoEdit: this.state.lastnameNew,
      };
      let response = await UserService.BasicDataChangeDetail(data);
      if (response !== undefined) {
        if (response.status === 1) {
          if (data.photoList.length === 1) {
            alert("Su imagen se ha enviado correctamente.");
          } else {
            alert("Sus imagenes se han enviado correctamente.");
          }
          this.setState({
            emptyLegal: true,
            emptyProfile: false,
            emptyDNI: true,
          });
        } else {
          alert(`Ocurrión un error. \n Detalle: ${response.description}`);
        }
      } else {
        alert("Ocurrió un error, Inténtelo más tarde.");
      }
    }*/
  };

  // Save img flyer
  saveNameComplete = async () => {
    if (this.state.nameNew.length > 0) {
      // && this.state.imgFlyer != this.state.imgFlyerPre) {
      if (this.state.lastnameNew.length > 0) {
        let data = {
          idDataChange: this.state.idDataChange,
          idMotiveChange: this.state.idMotiveChange,
          photoList: this.state.photoList,
          nombreEdit: this.state.nameNew,
          apellidoEdit: this.state.lastnameNew,
        };

        let response = await UserService.BasicDataChangeDetail(data);
        if (response !== undefined) {
          if (response.status === 1) {
            alert("Sus datos se han enviado correctamente.");
          } else {
            alert(`Ocurrión un error. \n Detalle: ${response.description}`);
          }
        } else {
          alert("Ocurrió un error, Inténtelo más tarde.");
        }
      } else {
        alert("Ingrese su corrección de apellido.");
      }
    } else {
      alert("Ingrese su corección de nombre.");
    }
  };

  addRegister = () => {
    let register = {};
    let list = this.state.photoList;
    list.push(register);
    this.setState({
      photoList: (this.state.photoList = list),
    });
  };

  deleteVoucher = (idx) => () => {
    const photoList = [...this.state.photoList];
    photoList.splice(idx, 1);
    this.setState({ photoList });
  };

  saveNewBeneficiary = async(data) => {
    const idSponsor = Number(AuthService.getCurrentIdUser());
    
    const resp = await UserService.registerBeneficiary({...data, idSponsor: idSponsor});

    if( resp.status == 1 ){
      alert('Registro exitoso');

      this.setState({
        showModalBeneficiary: false,
        beneficiary: (this.state.beneficiary = objectInitBeneficiary)
      })
      this.getListBeneficiaries();
    }else{
      alert(resp.description)
    }
  }

  updateBeneficiary = async(data) => {
    
    const resp = await UserService.updateBeneficiary(data);

    if( resp.status == 1 ){
      alert('Actualizacion exitosa');

      this.setState({
        showModalBeneficiary: false,
        beneficiary: (this.state.beneficiary = objectInitBeneficiary)
      });
      
      this.getListBeneficiaries();
    }else{
      alert(resp.description)
    }
    
  }

  render() {
    const {
      newAffiliate,
      newCoaffiliate,
      otherDocumentco,
      codesDocument,
      affiliateDetail,
      photoList,
      emptyDNI,
      emptyCorreccion,
      emptyName,
      emptyData,
      emptyLegal,
      emptyProfile,
      coAffiliateDetail,
      pendingList,
      message2,
      loadModal,
      email,
      listBeneficiaries
    } = this.state;
    const loading = this.state.loading;
    const noData = this.state.noData;
    const message = this.state.message;
    const nombreCompleto = AuthService.getName();

    const addCoaffiliateMessage = this.state.addCoaffiliateMessage;
    const {
      affiliate,
      loaded,
      codephone,
      residenceList,
      coaffiliate,
    } = this.state;

    const { suscription, loadSuscription } = this.state;
    return (
      <div className="panel-form">
        <Form>
          <Row>
            <Col sm={12}>
              {this.state.loading && (
                <div>
                  <Spinner animation="border" variant="dark"></Spinner>
                  <p>
                    <Form.Label>Cargando información de usuario.</Form.Label>
                  </p>
                </div>
              )}
              {this.state.emptyList && !this.state.loading && (
                <Form.Label>{this.state.message}</Form.Label>
              )}
              {!this.state.emptyList && (
                <div>
                  {/* <ProfileDni /> */}
                  <Form.Group>
                    <h4>Titular</h4>
                  </Form.Group>
                  <Row>
                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Nacionalidad</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={0}
                          value={newAffiliate.idNationality}
                          onChange={(e) =>
                            this.handleSelectList(e, "idNationality")
                          }
                        >
                          <option key={0} value={0} disabled>
                            Seleccione
                          </option>
                          {this.state.nationalities}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Nombres *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nombres"
                          maxLength={100}
                          defaultValue={newAffiliate.name}
                          disabled
                          onChange={(e) => this.handleChange(e, "name")}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Apellidos *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Apellidos"
                          defaultValue={newAffiliate.lastname}
                          disabled
                          onChange={(e) => this.handleChange(e, "lastname")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Sexo *</Form.Label>
                        <div key={"inline-radio"} className="mb-3">
                          <Form.Check
                            inline
                            label="Masculino"
                            type="radio"
                            id={`inline-radio`}
                            value="M"
                            onChange={(e) => this.handleRadio(e, "gender")}
                            checked={newAffiliate.gender === "M"}
                          />
                          <Form.Check
                            inline
                            label="Femenino"
                            type="radio"
                            id={`inline-radio2`}
                            value="F"
                            onChange={(e) => this.handleRadio(e, "gender")}
                            checked={newAffiliate.gender === "F"}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>País de residencia</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={0}
                          value={newAffiliate.idResidenceCountry}
                          onChange={(e) =>
                            this.handleSelectList(e, "idResidenceCountry")
                          }
                        >
                          <option key={0} value={0} disabled>
                            Seleccione
                          </option>
                          {this.state.residenceList}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Nro. Celular *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nro. celular"
                          defaultValue={newAffiliate.nroTelf}
                          onChange={(e) => this.handleChange(e, "nroTelf")}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Ingrese un número de celular válido.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={5}>
                      <Form.Group>
                        <Form.Label>Tipo de documento *</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={0}
                          value={newAffiliate.idDocument}
                          onChange={(e) =>
                            this.handleSelectList(e, "idDocument")
                          }
                        >
                          <option key={0} value={0} disabled>
                            Seleccione
                          </option>
                          {this.state.typeDocuments}
                        </Form.Control>

                        <br></br>
                        <Form.Control
                          style={{
                            display: this.state.showOthers,
                            paddingTop: 6,
                          }}
                          type="text"
                          placeholder="Ingrese tipo de documento"
                          value={newAffiliate.otherDocument}
                          maxLength={50}
                          onChange={(e) =>
                            this.handleChange(e, "otherDocument")
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={5}>
                      <Form.Group>
                        <Form.Label>Nro. de documento *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nro. documento"
                          defaultValue={newAffiliate.nroDocument}
                          maxLength={50}
                          onChange={(e) => this.handleChange(e, "nroDocument")}
                          onBlur={(e) =>
                            this.onBlurDocument(e, "nroDocument", "messageDoc")
                          }
                          disabled
                        ></Form.Control>
                        <Form.Text className="textAlert">
                          {this.state.messageDoc}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Distrito / Estado *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Distrito"
                          defaultValue={newAffiliate.districtAddress}
                          maxLength={100}
                          onChange={(e) =>
                            this.handleChange(e, "districtAddress")
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Ingrese su distrito.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Dirección"
                          defaultValue={newAffiliate.address}
                          maxLength={100}
                          onChange={(e) => this.handleChange(e, "address")}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Ingrese su dirección.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Correo eléctronico</Form.Label>
                        <Form.Control
                          disabled
                          type="mail"
                          placeholder="Correo eléctronico"
                          defaultValue={newAffiliate.email}
                          maxLength={100}
                          onChange={(e) => this.handleChange(e, "email")}
                          onBlur={(e) =>
                            this.onBlurEmail(e, "email", "messageEmail")
                          }
                        ></Form.Control>
                        <Form.Text className="textAlert">
                          {this.state.messageEmail}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/*this.state.hasCoafiliate && (
                    <div>
                      <Form.Group>
                        <h4>Co-Titular</h4>
                      </Form.Group>
                      <Row>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Nombres *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Nombres"
                              defaultValue={newCoaffiliate.name}
                              onChange={(e) => this.handleNewChange(e, "name")}
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Apellidos *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Apellidos"
                              defaultValue={newCoaffiliate.lastname}
                              onChange={(e) =>
                                this.handleNewChange(e, "lastname")
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Tipo de documento *</Form.Label>

                            <Form.Control
                              as="select"
                              defaultValue={"DEFAULT"}
                              value={newCoaffiliate.idDocument}
                              onChange={(e) =>
                                this.handleSelectAdd(e, "idDocument")
                              }
                            >
                              <option value="DEFAULT" disabled>
                                Seleccione una opción
                              </option>
                              {this.state.typeDocuments}
                            </Form.Control>
                            <br></br>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Nro. de documento *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Nro. documento"
                              defaultValue={newCoaffiliate.nroDocument}
                              onChange={(e) =>
                                this.handleNewChange(e, "nroDocument")
                              }
                              onBlur={(e) =>
                                this.onBlurDocumentCo(
                                  e,
                                  "nroDocument",
                                  "messageDocCoa"
                                )
                              }
                            ></Form.Control>
                            <Form.Text className="textAlert">
                              {this.state.messageDocCoa}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  )*/}
                  <Form.Group>
                    <Row>
                      <Col sm={12} style={{ textAlign: "center" }} className="mt-3">
                        <Form.Group>
                          <Button size="sm" onClick={this.onUpdate}>
                            Guardar Cambios{" "}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                      <Col sm={8}>
                        <Form.Group>
                          <Form.Label>
                            <h6>
                              {
                                "Deseas cambiar tu nombre, apellido o número de documento? Cambia tus datos aquí"
                              }
                            </h6>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Button
                          variant="primary"
                          size="sm"
                          disabled
                          onClick={(e) => this.OnClick(e, "/datanew")}
                        >
                          Cambiar datos
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        {!this.state.hasCoafiliate && (
                          <Form.Group>
                            <Form.Label>
                              <h6>
                                {
                                  "¿No tienes un Co Afiliado? Agrégalo ahora mismo aquí"
                                }
                              </h6>
                            </Form.Label>
                          </Form.Group>
                        )}
                      </Col>

                      <Col sm={4}>
                        {!this.state.hasCoafiliate && (
                          <Form.Group>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={this.handleAddAfiliate}
                            >
                              Agregar CoAfiliado{" "}
                            </Button>
                          </Form.Group>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group>
                          <Form.Label>
                            <h6>
                              {
                                "Agrega a tus beneficiarios ahora mismo aquí"
                              }
                            </h6>
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col sm={4}>
                        <Form.Group>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              this.setState({
                                showModalBeneficiary: true,
                                typeFormBeneficiary: 'store'
                              })
                            }}
                          >
                            Agregar Beneficiario{" "}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Modal
                    show={this.state.addCoafiliate}
                    onHide={this.handleCloseInsert}
                    style={{ fontSize: 10 }}
                    size="lg"
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Nuevo CoAfiliado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group>
                          <Form.Group>
                            <h4>Co-Afiliado</h4>
                          </Form.Group>
                          <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Nombres *</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Nombres"
                                  onChange={(e) =>
                                    this.handleNewChange(e, "nameco")
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Apellidos *</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Apellidos"
                                  onChange={(e) =>
                                    this.handleNewChange(e, "lastnameco")
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Tipo de documento *</Form.Label>
                                <Form.Control
                                  as="select"
                                  defaultValue={"DEFAULT"}
                                  onChange={(e) =>
                                    this.handleSelectAdd(e, "idDocumentco")
                                  }
                                >
                                  <option value="DEFAULT" disabled>
                                    Seleccione una opción
                                  </option>
                                  {this.state.typeDocuments}
                                </Form.Control>

                                <br></br>
                                <Form.Control
                                  style={{
                                    display: this.state.showOthersCoAdd,
                                    paddingTop: 6,
                                  }}
                                  type="text"
                                  placeholder="Ingrese tipo de documento"
                                  value={otherDocumentco}
                                  onChange={(e) =>
                                    this.handleNewChange(e, "otherDocumentco")
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Nro. de documento *</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Nro. documento"
                                  onChange={(e) =>
                                    this.handleNewChange(e, "nroDocumentco")
                                  }
                                  onBlur={(e) =>
                                    this.onBlurDocumentCoAdd(
                                      e,
                                      "nroDocumentco",
                                      "messageDoc"
                                    )
                                  }
                                ></Form.Control>
                                <Form.Text className="textAlert">
                                  {this.state.messageDocCo}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col sm={6} style={{ textAlign: "left" }}>
                              <Button
                                variant="secondary"
                                size="sm"
                                onClick={this.handleCloseInsert}
                              >
                                Cancelar
                              </Button>
                            </Col>
                            <Col sm={6} style={{ textAlign: "right" }}>
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={this.onInsert}
                              >
                                Guardar
                              </Button>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                  </Modal>
                  <FormBeneficiary 
                    show={this.state.showModalBeneficiary}
                    onCloseModal={() => 
                      this.setState({
                        showModalBeneficiary: false,
                        beneficiary: (this.state.beneficiary = objectInitBeneficiary),
                      })
                    }
                    idNationality={newAffiliate.idNationality}
                    typeFormBeneficiary={this.state.typeFormBeneficiary}
                    objectBeneficiary={this.state.beneficiary}
                    onSubmitForm={(data, type) => {
                      if( type == 'store' )
                        this.saveNewBeneficiary(data);
                      else  
                        this.updateBeneficiary(data)
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Form>
        {!emptyData && (
          <div>
            <h4>Seleccione que dato quiere cambiar</h4>
            <div className="panel-form" style={{ textAlign: "center" }}>
              <div
                onClick={(e) => this.OnClicked(e, "Nombre y Apellido")}
                className="box-container"
                style={{
                  width: "240px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                }}
              >
                <div className="box-icon-state">
                  {/* <img src={icon}></img> */}
                </div>
                <div>
                  <img alt="face" src={face}></img>
                  <div>
                    <p className="title-state">Cambiar nombre completo</p>
                  </div>
                </div>
              </div>
              <div
                onClick={(e) => this.OnClicked2(e, "Documento de identidad")}
                className="box-container"
                style={{
                  width: "240px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                }}
              >
                <div className="box-icon-state">
                  {/* <img src={icon}></img> */}
                </div>
                <div>
                  <img alt="document" src={face}></img>
                  {/* <p className="title-state">{dayPeriodN}</p>
                            <p className="subtitle-state">Ciclo de la empresa &nbsp;{periodN}</p> */}
                  <p className="title-state">Cambiar número de documento</p>
                </div>
              </div>
              <Row>
                <Button
                  onClick={(e) => this.backView(e, "profile")}
                  variant="secondary"
                >
                  Atrás
                </Button>
              </Row>
            </div>
          </div>
        )}
        {!emptyName && (
          <div>
            <h4>Seleccione el tipo de cambio de nombre</h4>
            <div className="panel-form" style={{ textAlign: "center" }}>
              <div
                onClick={(e) => this.handleClick(e, "legalname")}
                className="box-container"
                style={{
                  width: "240px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                }}
              >
                <div className="box-icon-state">
                  {/* <img src={icon}></img> */}
                </div>
                <div>
                  <img src={face}></img>
                  <div>
                    {/* <p className="title-state">{dayPeriod}</p> */}
                    <p className="title-state">Cambiar nombre</p>
                    <p style={{ fontWeight: "bold" }}>
                      Utilice su nombre en lugar de un apodo o un nombre nuevo
                      después de matrimonio, divorcio, etc.{" "}
                    </p>
                    {/* <p className="subtitle-state">Del {periodIni} al {period}</p> */}
                  </div>
                </div>
              </div>
              <div
                onClick={(e) => this.handleClick(e, "correccion")}
                className="box-container"
                style={{
                  width: "240px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                }}
              >
                <div className="box-icon-state">
                  {/* <img src={icon}></img> */}
                </div>
                <div>
                  <img src={edit}></img>
                  {/* <p className="title-state">{dayPeriodN}</p>
                            <p className="subtitle-state">Ciclo de la empresa &nbsp;{periodN}</p> */}
                  <p className="title-state">Realizar una pequeña corrección</p>
                  <p style={{ fontWeight: "bold" }}>
                    Corregir un error tipográfico de 1 o 2 caracteres
                  </p>
                </div>
              </div>
            </div>
            <Row>
              <Button
                onClick={(e) => this.backView(e, "data")}
                variant="secondary"
              >
                Atrás
              </Button>
            </Row>
          </div>
        )}
        {!emptyLegal && (
          <div>
            <h4>Actualizar su nombre</h4>
            <div
              className="panel-form"
              style={{ textAlign: "left", fontSize: "15px" }}
            >
              <Row>
                <p>Cargue:</p>
                <p>
                  1. Una copia de una identificación oficial con fotografía que
                  muestre su rostro completo y un número de identificación.
                </p>
                <p>2. Una copia de uno de estos documentos:</p>
                <ul>
                  <li>
                    Recibo de servicios públicos reciente donde aparezca su
                    nombre correcto y su dirección vigente.
                  </li>
                  <li>
                    Evaluación de impuestos reciente donde aparezca su nombre
                    correcto y su dirección vigente.
                  </li>
                  <li>
                    Recibo de seguro reciente donde aparezca su nombre correcto
                    y su dirección vigente.
                  </li>
                  <li>
                    Recibo de sueldo donde aparezca la dirección del empleador,
                    su dirección vigente y su nombre correcto.
                  </li>
                </ul>
                <p>3. Y si desea una copia de uno de estos documentos:</p>
                <ul>
                  <li>Acta de matrimonio</li>
                  <li>Acta de divorcio</li>
                  <li>Certificado de cambio de nombre</li>
                </ul>
              </Row>
              <Row>
                <Form>
                  <Form.Group className="mb-3" controlId="newNames">
                    <Form.Label>Nombres</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        this.setState({ nameNew: e.target.value })
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="newLastNames">
                    <Form.Label>Apellidos</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        this.setState({ lastnameNew: e.target.value })
                      }
                    />
                  </Form.Group>
                </Form>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        Imagen de identificación oficial (png, jpeg o jpg)
                      </th>
                      <th style={{ textAlign: "center" }}>
                        {" "}
                        {/*<Button
                          size="sm"
                          variant="dark"
                          onClick={(e) => {
                            this.addRegister();
                          }}
                        >
                          +
                        </Button>*/}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {photoList.map((item, idx) => (
                      <tr key={idx}>
                        <td style={{ verticalAlign: "middle" }}>
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => this.onFileChange(e, idx)}
                          />
                        </td>
                        {/*<td
                          style={{ fontSize: 15 }}
                          onClick={this.deleteVoucher(idx)}
                        >
                          <RiDeleteBinLine></RiDeleteBinLine>
                        </td>*/}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Button
                  ref={this.refButtonEnviarChangeName}
                  className="mt-4"
                  size="sm"
                  onClick={(e) => this.saveFlyer("change-name")}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  Enviar
                </Button>
                <Button
                  className="mt-4"
                  size="sm"
                  onClick={(e) => this.backView(e, "name-data")}
                  variant="secondary"
                  style={{ marginLeft: "auto" }}
                >
                  Atrás
                </Button>
              </Row>
            </div>
          </div>
        )}
        {!emptyCorreccion && (
          <div>
            <h4>Realizar una pequeña corrección</h4>
            <div
              className="panel-form"
              style={{
                textAlign: "left",
                fontSize: "15px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Row>
                <Col sm={3}>
                  <strong>Nombre actual:</strong>
                </Col>
                <Col sm={9}>
                  <p>{nombreCompleto}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Nombres"
                      onChange={(e) => this.handleChangeName(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Apellidos"
                      onChange={(e) => this.handleChangeLastName(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Button
                  className="mt-4"
                  size="sm"
                  onClick={(e) => this.backView(e, "name")}
                  variant="secondary"
                  style={{ marginLeft: "auto" }}
                >
                  Atrás
                </Button>
                <Button
                  className="mt-4"
                  size="sm"
                  onClick={(e) => this.saveNameComplete()}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  Actualizar Nombre
                </Button>
              </Row>
            </div>
          </div>
        )}
        {!emptyDNI && (
          <div>
            <h4>Actualizar su número de identificación</h4>
            <div
              className="panel-form"
              style={{ textAlign: "left", fontSize: "15px" }}
            >
              <Row>
                <p>Cargue:</p>
                <p>
                  1. Una copia de una identificación oficial con fotografía que
                  muestre su rostro completo y un número de identificación.
                </p>
                <p>2. Una copia de uno de estos documentos:</p>
                <ul>
                  <li>
                    Recibo de servicios públicos reciente donde aparezca su
                    número de identificación correcto y su dirección vigente.
                  </li>
                  <li>
                    Evaluación de impuestos reciente donde aparezca su número de
                    identificación correcto y su dirección vigente.
                  </li>
                  <li>
                    Recibo de seguro reciente donde aparezca su número de
                    identificación correcto y su dirección vigente.
                  </li>
                  <li>
                    Recibo de sueldo donde aparezca la dirección del empleador,
                    su dirección vigente y su número de identificación correcto.
                  </li>
                </ul>
                <p>3. Y si desea una copia de uno de estos documentos:</p>
                <ul>
                  <li>Acta de matrimonio</li>
                  <li>Acta de divorcio</li>
                  <li>Certificado de cambio de nombre</li>
                </ul>
              </Row>
              <Row>
                <Form>
                  <Form.Group className="mb-3" controlId="newDNI">
                    <Form.Label>Numero de documento</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        this.setState({ documentNew: e.target.value })
                      }
                    />
                  </Form.Group>
                </Form>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Imagen</th>
                      <th style={{ textAlign: "center" }}>
                        {" "}
                        <Button
                          size="sm"
                          variant="dark"
                          onClick={(e) => {
                            this.addRegister();
                          }}
                        >
                          +
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {photoList.map((item, idx) => (
                      <tr key={idx}>
                        <td style={{ verticalAlign: "middle" }}>
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => this.onFileChange(e, idx)}
                          />
                          {/* } */}
                        </td>
                        <td
                          style={{ fontSize: 15 }}
                          onClick={this.deleteVoucher(idx)}
                        >
                          <RiDeleteBinLine></RiDeleteBinLine>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Button
                  ref={this.refButtonEnviarChangeIdentification}
                  className="mt-4"
                  size="sm"
                  onClick={(e) =>
                    this.saveFlyer("change-number-identification")
                  }
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  Enviar
                </Button>
                <Button
                  className="mt-4"
                  size="sm"
                  onClick={(e) => this.backView(e, "data")}
                  variant="secondary"
                  style={{ marginLeft: "auto" }}
                >
                  Atrás
                </Button>
              </Row>
            </div>
          </div>
        )}

        <Modal
          show={this.state.loadSenData}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Spinner size="sm" animation="border" variant="dark"></Spinner>
              <Form.Label>&nbsp; Guardando información...</Form.Label>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.waiting}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Spinner size="sm" animation="border" variant="dark"></Spinner>
              <Form.Label>&nbsp; Espere un momento :).</Form.Label>
            </div>
          </Modal.Body>
        </Modal>
        <Accordion defaultActiveKey={this.state.hasCoafiliate ? "0" : "1"} className="mt-5">
          { this.state.hasCoafiliate && (
            <Accordion.Item eventKey="0">
              <Accordion.Header style={{display:"flex",justifyContent:"center"}}>CO-Titular</Accordion.Header>
              <Accordion.Body>
                <Form className="pt-3">
                  <Row>
                    <Col sm={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Nombres *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nombres"
                          defaultValue={newCoaffiliate.name}
                          onChange={(e) => this.handleNewChange(e, "name")}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Apellidos *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Apellidos"
                          defaultValue={newCoaffiliate.lastname}
                          onChange={(e) =>
                            this.handleNewChange(e, "lastname")
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Tipo de documento *</Form.Label>

                        <Form.Control
                          as="select"
                          defaultValue={"DEFAULT"}
                          value={newCoaffiliate.idDocument}
                          onChange={(e) =>
                            this.handleSelectAdd(e, "idDocument")
                          }
                        >
                          <option value="DEFAULT" disabled>
                            Seleccione una opción
                          </option>
                          {this.state.typeDocuments}
                        </Form.Control>
                        <br></br>
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Nro. de documento *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nro. documento"
                          defaultValue={newCoaffiliate.nroDocument}
                          onChange={(e) =>
                            this.handleNewChange(e, "nroDocument")
                          }
                          onBlur={(e) =>
                            this.onBlurDocumentCo(
                              e,
                              "nroDocument",
                              "messageDocCoa"
                            )
                          }
                        ></Form.Control>
                        <Form.Text className="textAlert">
                          {this.state.messageDocCoa}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                      <Col sm={12} style={{ textAlign: "center" }}>
                        <Form.Group>
                          <Button size="sm" onClick={this.onUpdate}>
                            Guardar Cambios{" "}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          )}
          <Accordion.Item eventKey="1">
            <Accordion.Header style={{display:"flex",justifyContent:"center"}}>Beneficiarios</Accordion.Header>
            <Accordion.Body>
              <Table responsive>
                <thead className="table-head">
                  <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Nro. Documento</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    listBeneficiaries.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.lastname}</td>
                        <td>{item.nroDocument}</td>
                        <td>
                          <div onClick={() => { 
                               this.setState({
                                showModalBeneficiary: true,
                                beneficiary: (this.state.beneficiary = item),
                                typeFormBeneficiary: 'edit'
                              })
                              console.log('get beneficiario', this.state.beneficiary);
                            }}
                          >
                            <FaEdit ></FaEdit>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }
}
