import { Loading } from "@nextui-org/react";
import { useLayoutEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";

function LandingGenerica() {
  const [loadingInitial, setLoadingInitial] = useState(true);
  const urlLanding = useRef(null);

  const getInitialFetchLanding = async () => {
    try {
      const dataFetchMeeting = await fetch(
        `${process.env.REACT_APP_APP_URL_API}/meetings/current`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const dataMeeting = await dataFetchMeeting.json();

      if (dataMeeting?.objModel?.length !== 0) {
        const url = new URL(
          dataMeeting?.objModel[0]?.subMeetingType?.landing?.url
        );
        urlLanding.current = url.pathname;
      } else {
        alert("No hay reuniones programadas, en este momento.");
      }
      setLoadingInitial(false);
    } catch (error) {
      setLoadingInitial(false);
    }
  };

  useLayoutEffect(() => {
    getInitialFetchLanding();
  }, []);

  if (loadingInitial) {
    return (
      <section className="tw-w-full tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center">
        <Loading type="gradient" color="primary" textColor="primary" size="xl">
          <p>Redirigiendo Landing...</p>
        </Loading>
      </section>
    );
  } else {
    return <Redirect to={urlLanding.current ?? "/calendar-events"} />;
  }
}

export default LandingGenerica;
