import Header from "./Header/Header.js";
import ListCalendarEvents from "./Body/ListCalendarEvents";
import { useState } from "react";
import "./CalendarEvents.css";
const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;
export default function CalendarEventsView() {
  const [filter, setfilter] = useState(null);
  const [idsubtype, setidsubtype] = useState("def");

  return (
    <section className="ScheduleEventsMainView">
      <Header
        setfilter={setfilter}
        idsubtype={idsubtype}
        setidsubtype={setidsubtype}
        API_URL={API_URL}
      />
      <ListCalendarEvents
        filter={filter}
        idsubtype={idsubtype}
        API_URL={API_URL}
      />
    </section>
  );
}
