import { ReactComponent as ImgNotifications } from "assets/svg/notification-alert.svg";
import { ReactComponent as ImgNoNotifications } from "assets/svg/sin-notificaciones.svg";
import ErrorApi from "components/validators/error/ErrorApi";
import { NewNotificationContext } from "context/contextNotification.js";
import "firebase/messaging";
import { onMessage } from "firebase/messaging";
import { useContext, useEffect, useRef, useState } from "react";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import es from "timeago.js/lib/lang/es";
import "views/styles/Notifications.css";
import LoadApi from "validators/LoadApi";
import Swal from "sweetalert2";
import {
  askNotificationPermission,
  getMessagingObjectFirebase,
  getTokenFirebase,
} from "../../firebase/firebase";

const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;
timeago.register("es", es);

function NumberNotification(props) {
  return (
    <>
      {props.numberNotifications !== 0 && (
        <section className="icon-notifications_number">
          <span>{props.numberNotifications}</span>
        </section>
      )}
    </>
  );
}

const isSupported = () =>
'Notification' in window &&
'serviceWorker' in navigator &&
'PushManager' in window

function Notifications() {
  const { isContextNewNotification, setIsContextNewNotification } = useContext(
    NewNotificationContext
  );

  const idNotifications = useRef(null);
  const idButtonEnableNotification = useRef(null);
  const idSpanInfoNotification = useRef(null);

  const [openNotifications, setOpenNotifications] = useState(false);

  //const [isSupportedPushNotifications, setIsSupportedPushNotifications] = useState("")
  const [
    isPushNotificationPermission,
    setIsPushNotificationPermission,
  ] = useState("granted");

  const [
    isLoadedApiDataNotifications,
    setIsLoadedApiDataNotifications,
  ] = useState(true);
  const [isErrorApiData, setIsErrorApiData] = useState(null);

  const [
    isDataNotificationsMessages,
    setIsDataNotificationsMessages,
  ] = useState([]);
  const [
    isNumberNotificationsMessages,
    setIsNumberNotificationsMessages,
  ] = useState(0);

  //Esto es momentaneo y de prueba
  function sweetAlert({
    nameAlert = "😎 Guardado con éxito",
    typeAlert = "info",
    time,
  }) {
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: typeAlert,
      title: nameAlert,
      showConfirmButton: false,
      timer: time,
    });
  }

  async function fetchBackgroundNotification() {
    const idAdminUser = parseInt(sessionStorage.getItem("id"));
    try {
      const response = await fetch(`${API_URL}notification/${idAdminUser}`, {
        method: "GET",
        cache: "force-cache",
        headers: { "Content-Type": "application/json" },
      });
      const resp = await response.json();
      const countNotifications = resp?.objModel.length;

      if (countNotifications > 0) {
        setIsNumberNotificationsMessages(countNotifications);
        /*sweetAlert({
          nameAlert: `🔔 Tienes ${countNotifications} notificaciones.`,
          typeAlert: "info",
        });*/
      }
    } catch (error) {}
  }
  //fin

  function getPermissionNotification() {
    idButtonEnableNotification.current.disabled = true;
    idButtonEnableNotification.current.innerText = "Activando...";
    idSpanInfoNotification.current.innerText =
      "🚨 Si no carga es posible que tu navegador este bloqueando las notificaciones...";
    askNotificationPermission()
      .then((notificationPermisssion) => {
        if (notificationPermisssion === "granted") {
          setIsPushNotificationPermission("granted");
        } else if (notificationPermisssion === "denied" || "blocked") {
          idButtonEnableNotification.current.innerText =
            "Notificaciones Bloqueadas";
          idSpanInfoNotification.current.innerText =
            "🚨 Las notificaciones estan bloqueadas por tú navegador.";
        } else {
          idSpanInfoNotification.current.innerText =
            "🚨 Las notificaciones estan en modo predeterminado, por favor habilitalas";
        }
      })
      .catch((err) => {
        alert(
          "Ocurrio un error inesperado en getPermissionNotification()" + err
        );
      });
  }

  function listenerChangeNotificationInNavigator() {
    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function(notificationPerm) {
          notificationPerm.onchange = function() {
            setIsPushNotificationPermission(notificationPerm.state);
          };
        });
    }
  }

  function getDataApiNotification() {
    const idAdminUser = parseInt(sessionStorage.getItem("id"));
    fetch(`${API_URL}notification/${idAdminUser}`, {
      method: "GET",
      cache: "default",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 0) {
            setIsErrorApiData(true);
          } else {
            setIsDataNotificationsMessages(result.objModel);
          }
          setIsLoadedApiDataNotifications(false);
        },
        (error) => {
          setIsLoadedApiDataNotifications(false);
          setIsErrorApiData(error);
        }
      );
  }

  function getDataNotificationInWindow() {
    onMessage(getMessagingObjectFirebase(), (payload) => {
      console.log("NUEVA NOTIFICACION IN WINDOWS");
      setIsContextNewNotification(true);
      setIsNumberNotificationsMessages(
        (prevIsNumberNotificationsMessages) =>
          prevIsNumberNotificationsMessages + 1
      );
      //let newNotificationInWindow = payload.data
      //setIsDataNotificationsMessages(prevNotificationsArray => [newNotificationInWindow, ...prevNotificationsArray])
    });
  }

  function getDataNotificationOutsideWindow() {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data.isFirebaseMessaging === undefined) {
          console.log(`NUEVA NOTIFICACION OUTSIDE WINDOWS`);
          setIsContextNewNotification(true);
          setIsNumberNotificationsMessages(
            (prevIsNumberNotificationsMessages) =>
              prevIsNumberNotificationsMessages + 1
          );
          //let newNotificationBackground = event.data.data
          //setIsDataNotificationsMessages(prevNotificationsArray => [newNotificationBackground, ...prevNotificationsArray])
        }
      });
      navigator.serviceWorker.ready.then((registration) => {
        registration.active.postMessage("PUSH TO SW");
      });
    }
  }

  useEffect(() => {
    function checkClickedOutsideNotificationArea(e) {
      if (openNotifications && !idNotifications.current.contains(e.target)) {
        setOpenNotifications(false);
      }
    }
    document.addEventListener("mousedown", checkClickedOutsideNotificationArea);
    return () => {
      document.removeEventListener(
        "mousedown",
        checkClickedOutsideNotificationArea
      );
    };
  }, [openNotifications]);

  useEffect(() => {
    if (isSupported()) {
      if (Notification.permission === "granted") {
        setIsPushNotificationPermission("granted");
        getTokenFirebase();
        getDataNotificationInWindow();
        getDataNotificationOutsideWindow();
      } else if (Notification.permission === "denied") {
        setIsPushNotificationPermission("denied");
      } else {
        setIsPushNotificationPermission("prompt");
      }
    }

    
  }, [isPushNotificationPermission]);

  useEffect(() => {
    if (openNotifications) {
      setIsNumberNotificationsMessages(0);
      setIsContextNewNotification(false);
      setIsLoadedApiDataNotifications(true);
    }
  }, [
    openNotifications,
    isNumberNotificationsMessages,
    setIsContextNewNotification,
  ]);

  useEffect(() => {
    if (openNotifications) {
      getDataApiNotification();
    }
  }, [openNotifications]);

  useEffect(() => {
    listenerChangeNotificationInNavigator();
    fetchBackgroundNotification();
  }, []);

  return (
    <section className="notifications" ref={idNotifications}>
      <span className="icon-notifications">
        <button
          onClick={() => setOpenNotifications(!openNotifications)}
          aria-label="Notificaciones"
        >
          <svg
            viewBox="0 0 28 28"
            alt=""
            className="a8c37x1j ms05siws hwsy1cff b7h9ocf4 fzdkajry"
            height="20"
            width="20"
          >
            <path d="M7.847 23.488C9.207 23.488 11.443 23.363 14.467 22.806 13.944 24.228 12.581 25.247 10.98 25.247 9.649 25.247 8.483 24.542 7.825 23.488L7.847 23.488ZM24.923 15.73C25.17 17.002 24.278 18.127 22.27 19.076 21.17 19.595 18.724 20.583 14.684 21.369 11.568 21.974 9.285 22.113 7.848 22.113 7.421 22.113 7.068 22.101 6.79 22.085 4.574 21.958 3.324 21.248 3.077 19.976 2.702 18.049 3.295 17.305 4.278 16.073L4.537 15.748C5.2 14.907 5.459 14.081 5.035 11.902 4.086 7.022 6.284 3.687 11.064 2.753 15.846 1.83 19.134 4.096 20.083 8.977 20.506 11.156 21.056 11.824 21.986 12.355L21.986 12.356 22.348 12.561C23.72 13.335 24.548 13.802 24.923 15.73Z"></path>
          </svg>
          <NumberNotification
            numberNotifications={isNumberNotificationsMessages}
          />
        </button>
      </span>

      {openNotifications && (
        <section className="section-notifications">
          <section className="notifications-title">
            <h2>Notificaciones</h2>
            <span>🔔</span>
          </section>

          <section className="notifications-body">
            <ErrorApi errorData={isErrorApiData}>
              <LoadApi
                message="Cargando..."
                messageWarning="Este proceso puede demorar ⌛"
                indicatorLoad={isLoadedApiDataNotifications}
              >
                {isPushNotificationPermission !== "granted" && (
                  <section className="notifications-body_push_notice">
                    <div className="push_notice-icon">
                      <ImgNotifications />
                    </div>
                    <div className="push_notice-text">
                      <h3>Notificaciones Push</h3>
                      <p>
                        Te recomendamos activar las push notifications.
                        <a
                          href="https://frizbit.com/es/blog/notificaciones-push-web/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Saber más
                        </a>
                      </p>
                      <p
                        style={{
                          lineHeight: "15px",
                          color: "#999999",
                          fontSize: "x-small",
                        }}
                        ref={idSpanInfoNotification}
                      >
                        Para una experiencia completa usa Edge, Chrome o Firefox
                      </p>
                    </div>
                    <div className="push_notice-button">
                      <button
                        ref={idButtonEnableNotification}
                        onClick={getPermissionNotification}
                      >
                        Activar
                      </button>
                    </div>
                  </section>
                )}

                {isDataNotificationsMessages.length === 0 ? (
                  <section className="notifications-body-no-notification">
                    <ImgNoNotifications className="icon-not-notification" />
                    <span>No tienes notificaciones</span>
                  </section>
                ) : (
                  isDataNotificationsMessages.map((isNotification, key) => (
                    <button key={key} className="notification">
                      <div className="notification-img">
                        <img
                          alt="img-notification"
                          src={
                            "https://media-exp1.licdn.com/dms/image/C4E0BAQF3x4f1g-AGXg/company-logo_200_200/0/1593104654972?e=2159024400&v=beta&t=vXNKxZyqPJE_YWj_MDPqtlRkYgMrvwLzasnWv1mdOEY"
                          }
                        />
                      </div>
                      <div className="notification-text">
                        <span>
                          <strong>{isNotification.title}</strong>{" "}
                          {isNotification.description}
                        </span>
                        <div>
                          {
                            <TimeAgo
                              datetime={isNotification.creationDate}
                              className="notification-time"
                              locale="es"
                            />
                          }
                        </div>
                      </div>
                    </button>
                  ))
                )}
              </LoadApi>
            </ErrorApi>
          </section>
        </section>
      )}
    </section>
  );
}

export default Notifications;
