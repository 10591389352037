import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Svg,
  Rect,
} from "@react-pdf/renderer";

Font.register({
  family: "Arial",
  fonts: [
    { src: "/pdf/fonts/arial.ttf" },
    {
      src: "/pdf/fonts/arial-black.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {},
  body: {
    fontSize: 10,
    fontFamily: "Arial",
    paddingTop: 10,
    paddingHorizontal: 70,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 25,
    paddingHorizontal: 50,
    paddingBottom: 15,
  },
  imgHeader: {
    maxWidth: "180px",
    opacity: 0.5,
  },
  title: {
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 20,
  },
  subtitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  text: {
    textAlign: "justify",
    paddingBottom: 10,
    //lineHeight: 1.5,
  },
  titleList: {
    textAlign: "justify",
    paddingLeft: 15,
    paddingBottom: 10,
  },
  list: {
    textAlign: "justify",
    paddingLeft: 20,
    paddingBottom: 2,
  },
  image: {
    width: "100%",
    marginVertical: 15,
    marginHorizontal: 90,
  },
  selected: {
    width: 20,
    height: 15,
    backgroundColor: "black",
    borderRadius: 1,
    border: "1 solid black",
  },
  noSelected: {
    width: 20,
    height: 15,
    backgroundColor: "white",
    borderRadius: 1,
    border: "1 solid black",
  },
});

function InresortsPDF(props) {
  console.log("props InresortsPDF =>", props);
  let membershipmaintenanceletter = props.dataPDF.membershipmaintenanceletter;
  membershipmaintenanceletter = membershipmaintenanceletter.replace(
    "DOLARES",
    " "
  );
  membershipmaintenanceletter = membershipmaintenanceletter.replace(
    "M.N.",
    " "
  );
  membershipmaintenanceletter = membershipmaintenanceletter.trim();

  return (
    <Document>
      <Page wrap size="A4">
        {/*1 */}
        <View style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.title}>
              CONTRATO DE TRANSFERENCIA DE ACCIONES CON USUFRUCTO { props.dataPDF.membershipmaintenance }
            </Text>

            <Text style={styles.text}>
              Conste por el presente documento el Contrato de transferencia de
              acciones con usufructo, que celebran de una parte{" "}
              <Text style={{ fontWeight: "bold" }}>VALLE ENCANTADO S.A.C.</Text>
              , identificada con RUC N° 20601460271, constituida y regida bajo
              las leyes de la República del Perú, con domicilio legal en Mz.{" "}
              {"<<B>>"} Lote 72, Tercera Etapa Cieneguilla, Provincia de Lima y
              Departamento de Lima, debidamente representada por el Señor Omar
              Fernando Urteaga Cabrera , identificado con D.N.I. N° 41958311; a
              quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“VALLE ENCANTADO”</Text>; y
              de otra parte El(La) Señor(a) {props.dataPDF.user.name.toUpperCase()}{" "}
              {props.dataPDF.user.lastname.toUpperCase()}, de nacionalidad{" "}
              {props.dataPDF.user.courtesy}, identificado con D.N.I. N°{" "}
              {props.dataPDF.user.nroDocument}, con domicilio en{" "}
              {props.dataPDF.user.address}, a quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“EL COMPRADOR”</Text>; en los
              términos contenidos en las cláusulas siguientes:
            </Text>

            <View style={styles.text}>
              <Text style={styles.subtitle}>PRIMERO: ANTECEDENTES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.1{"  "}VALLE ENCANTADO, empresa encargada de gestionar la
                  venta de acciones de RIBERA DEL
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  RIO CLUB RESORT S.A, identificada con RUC N° 20608720911,
                  titular de 600,000,000 de acciones ordinarias, que se componen
                  por acciones Clase A: acciones con derecho a voto y sin
                  derecho a usufructo y Clase B: acciones sin derecho a voto con
                  derecho a usufructo, debidamente inscritas en la Partida
                  Electrónica N° 14809461 del Registro de Personas Jurídicas de
                  Registro de Personas Jurídicas de Lima.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.2{"  "}VALLE ENCANTADO deja constancia que las acciones
                  referidas en la cláusula anterior se
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  encuentran libres de cargas o gravámenes.
                </Text>
              </View>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>SEGUNDO: OBJETO DEL CONTRATO</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.1.{"  "}Por el presente contrato, VALLE ENCANTADO se obliga
                  a transferir la titularidad de{" "}
                  {/*
                    props.dataPDF.numberShares !== undefined &&
                    props.dataPDF.packagesavailable.find(
                      (e) => {
                        console.log(e.package.name,props.dataPDF.userPackage, 'aas')
                        if(e.package.name === props.dataPDF.userPackage){
                          console.log('aui', e);
                          return  props.dataPDF.numberShares;
                        }
                        
                      }
                    )*/
                  }
                  
                  { props.dataPDF.numberShares }
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  acciones Clase B, correspondientes a RIBERA DEL RIO CLUB
                  RESORT S.A, a favor de EL COMPRADOR, por la adquisición del
                  Paquete de acciones:
                  {props.dataPDF.userPackage.toUpperCase()} conforme al
                  siguiente detalle:
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 20,
                  paddingTop: 10,
                  marginLeft: 10,
                }}
              >
                {props.dataPDF.packagesavailable.map((e) => {
                  if (e.package.name === props.dataPDF.userPackage) {
                    return (
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text>{e.package.name}</Text>
                        <Text>{e.numberShares} acc</Text>
                        <View style={styles.selected}></View>
                      </View>
                    );
                  } else {
                    return (
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text>{e.package.name}</Text>
                        <Text>{e.numberShares} acc</Text>
                        <View style={styles.noSelected}></View>
                      </View>
                    );
                  }
                })}
              </View>
              <Text style={[styles.text, { marginLeft: 16.5 }]}>
                Estas acciones son sin derecho a voto y con derecho a usufructo,
                referente a la posibilidad de disfrutar los espacios y recibir
                beneficios en los servicios de RIBERA DEL RIO CLUB RESORT S.A,
                con sujeción al “Programa de Beneficios” – Anexo 1 y al “Código
                de Ética y Conducta” – Anexo 2.
              </Text>
              <Text style={[styles.text, { marginLeft: 16.5 }]}>
                Por su parte, EL COMPRADOR se obliga a pagar a VALLE ENCANTADO
                el monto total del precio pactado en la cláusula tercera, en la
                forma y oportunidad convenidas, en adelante el PRECIO.
              </Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.2. La transferencia de acciones que es objeto del contrato,
                  incluye todos los derechos, prerrogativas
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 16.5 }}>
                  y obligaciones inherentes a ellas, actuales y futuros que
                  posea RIBERA DEL RIO CLUB RESORT S.A.
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/*2 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.subtitle}>TERCERO: PRECIO Y FORMA DE PAGO</Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.1.{"  "}El PRECIO del Paquete de acciones{" "}
                {props.dataPDF.userPackage.toUpperCase()}, asciende a la suma de{" "}
                {`$${props.dataPDF.price}`},
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                US. {props.dataPDF.pricetotalLetter}, el cual le otorga a EL
                COMPRADOR el número de acciones y beneficios mencionados en el
                numeral 2.1 del presente contrato.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.2.{"  "}Las partes acuerdan que el pago del PRECIO se
                efectuará en dólares de los Estados Unidos de
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                América, al amparo de lo dispuesto por el Artículo 1237 del
                Código Civil.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.3.{"  "}EL PRECIO, será pagado en el plazo de (
                {props.dataPDF.numberQuotas}) meses, mediante la cancelación de
                una cuota inicial
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                de {`$${props.dataPDF.initialPrice}`} y{" "}
                {props.dataPDF.numberQuotas} cuotas mensuales consecutivas de{" "}
                {`$${props.dataPDF.quotaPrice}`} cada una. La cuota inicial se
                cancelará en la fecha de la suscripción del contrato, mientras
                que las cuotas subsiguientes se cancelarán de acuerdo al
                Cronograma de pagos que consta en el Anexo 3 del presente
                documento.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.4.{"  "}La cuota inicial y las cuotas mensuales
                correspondientes, podrán ser canceladas en las siguientes
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                cuentas bancarias a elección de EL COMPRADOR:
              </Text>
            </View>
            <Image
              style={[{ width: "100%", height: "105px", paddingBottom: 10 }]}
              src="/pdf/inresorts/precios.png"
            />
            <Text
              style={{
                textAlign: "justify",
                marginLeft: 20,
                paddingBottom: 10,
              }}
            >
              En caso de cambio o incrementos de cuentas bancarias, VALLE
              ENCANTADO informará oportunamente a través del correo registrado
              de EL COMPRADOR, dentro de los primeros 7 (siete) días del cambio.
            </Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                3.5.{"  "}EL COMPRADOR podrá cancelar por adelantado una o más
                cuotas
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                mensuales no vencidas; sin embargo, no se admitirá en ningún
                caso el pago parcial de estas cuotas.
              </Text>
            </View>

            <Text style={styles.subtitle}>
              CUARTO: OBLIGACIONES DE LAS PARTES
            </Text>
            <Text style={styles.text}>
              4.1. VALLE ENCANTADO tiene las siguientes obligaciones:
            </Text>
            <Text style={styles.titleList}>- Acciones:</Text>
            <View style={styles.text}>
              <Text style={styles.list}>
                • VALLE ENCANTADO se obliga a transferir las acciones materia de
                este contrato a EL COMPRADOR, libres de gravámenes, cargas y
                cualquier obligación que limite o restrinja su dominio o libre
                disposición, las cuales tienen derecho a usufructo en beneficio
                del socio o accionista.
              </Text>
              <Text style={styles.list}>
                • Suscribir todos los documentos o contratos necesarios para
                perfeccionar la transferencia.
              </Text>
            </View>
            <Text style={styles.titleList}>- Usufructo:</Text>
          </View>
        </View>

        {/*3 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View style={styles.text}>
              <Text style={{ marginLeft: 30 }}>
                • En virtud del presente contrato, VALLE ENCANTADO deberá
                suministrar a EL COMPRADOR, la posibilidad de disfrutar los
                espacios y recibir beneficios en los servicios de RIBERA DEL RÍO
                CLUB RESORT S.A., con sujeción al “Plan de Beneficios” y al
                “Código de Ética y Conducta” que EL COMPRADOR tiene derecho
                conforme al número de acciones adquiridas.
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={{ textAlign: "justify", paddingBottom: 10 }}>
                4.2.{"  "}EL COMPRADOR tiene las siguientes obligaciones:
              </Text>
              <Text style={styles.titleList}>- Acciones </Text>
              <View style={[styles.text, { paddingLeft: 0 }]}>
                <Text style={styles.list}>
                  • EL COMPRADOR se obliga a efectuar el pago de las cuotas
                  mensuales en el momento y forma pactados conforme a lo
                  dispuesto en la cláusula tercera de este documento. En caso
                  contrario, EL COMPRADOR recaerá en incumplimiento según lo
                  dispuesto en la cláusula sexta.
                </Text>
                <Text style={styles.list}>
                  • Suscribir los documentos y contratos que sean necesarios
                  para perfeccionar la transferencia de las acciones adquiridas.
                </Text>
              </View>
            </View>

            <View style={styles.text}>
              <Text style={styles.titleList}>- Usufructo</Text>
              <View style={[styles.text, { paddingLeft: 15 }]}>
                <View style={{ paddingBottom: 10 }}>
                  <Text style={[styles.text, { paddingLeft: 5 }]}>
                    {" "}
                    a) Mantenimiento
                  </Text>
                  <Text style={styles.list}>
                    • EL COMPRADOR, deberá cancelar de manera puntual el pago
                    anual por concepto de mantenimiento, el mismo que podrá
                    variar, según lo determine y justifique la administración
                    (incremento costo vida, inflación, valor dinero en el
                    tiempo).
                  </Text>
                  <Text style={styles.list}>
                    • El no uso del Programa de Beneficios de Ribera del Río
                    Club Resort S.A. no exonera de este pago al titular. En caso
                    de incumplimiento, EL COMPRADOR quedará inhabilitado
                    temporalmente hasta regularizar el pago.
                  </Text>
                  <Text style={styles.list}>
                    • Esta operación no requiere estado de cuenta previo para su
                    cancelación. Puede ser abonado en las cuentas corrientes que
                    serán informadas por RIBERA DEL RIO CLUB RESORT S.A. en su
                    oportunidad, identificándose con su número de Documento de
                    Identidad y/o pago directo en alguna oficina del Club
                    Resort.
                  </Text>
                  <Text style={styles.list}>
                    • El pago por concepto de mantenimiento corresponde a:{" "}
                    {`$${props.dataPDF.membershipmaintenance}`} (
                    {`${membershipmaintenanceletter} DOLARES`}) anuales. Cabe
                    indicar que, este concepto se descontará directamente de los
                    ingresos que genere RIBERA DEL RIO CLUB RESORT S.A., año
                    tras año. Este pago será asumido por EL COMPRADOR, en caso
                    dichos ingresos no sean suficientes para cubrir el
                    mencionado concepto.
                  </Text>
                  <Text style={styles.list}>
                    • En caso de incumplimiento de pago de la cuota del
                    mantenimiento anual, se procederá a la suspensión de los
                    beneficios, según el Programa de Beneficios Anexo 1, y no se
                    habilitará el USO, hasta la subsanación del pago o pagos
                    pendientes.
                  </Text>
                </View>
                <View style={{ paddingBottom: 10 }}>
                  <Text style={[styles.text, { paddingLeft: 5 }]}>
                    {" "}
                    b) Términos de Conducta
                  </Text>
                  <Text style={styles.list}>
                    • EL COMPRADOR deberá responsabilizarse por los daños que
                    éste, su cónyuge, hijos, hijas e invitados pudieran causar a
                    las instalaciones de RIBERA DEL RÍO CLUB RESORT S.A., ya sea
                    por dolo, culpa leve o culpa inexcusable.
                  </Text>
                  <Text style={styles.list}>
                    • EL COMPRADOR deberá cancelar las sanciones que RIBERA DEL
                    RÍO CLUB RESORT S.A., a través de su administración, podrá
                    imponer a los socios que constan en el Código de Ética y
                    Conducta del Club.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/*4 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View style={{ paddingLeft: 40, paddingBottom: 10 }}>
              <Text>
                • EL COMPRADOR deberá conocer las disposiciones contenidas en el
                Código de Ética y Conducta – Anexo 2 de RIBERA DEL RÍO CLUB
                RESORT S.A y que lo ha leído previamente a la suscripción del
                presente documento, sin más constancia que la firma puesta al
                pie, quedando suscrito a sus términos y condiciones aceptando
                los procedimientos y sanciones que éste contempla.
              </Text>
              <Text>
                • EL COMPRADOR acepta tener conocimiento que RIBERA DEL RÍO CLUB
                RESORT S.A. podrá modificar su Reglamento, así como podrá
                expedir otras normas, reglamentos y políticas que tengan por
                finalidad mejorar el uso de las instalaciones de RIBERA DEL RÍO
                CLUB RESORT S.A. por parte de los afiliados y público en
                general, los cuales están obligados a respetarlas desde la fecha
                en que estas sean comunicadas.
              </Text>
            </View>

            <Text style={styles.subtitle}>QUINTO: DERECHOS DE LAS PARTES</Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                5.1.{"  "}VALLE ENCANTADO tiene derecho a percibir puntualmente
                lo pagos que EL COMPRADOR
              </Text>
              <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                deberá efectuar según lo dispuesto en la cláusula tercera, de lo
                contrario se realizará la liquidación con penalidad.
              </Text>
            </View>

            <Text style={styles.text}>
              5.2. EL COMPRADOR tiene los siguientes derechos:
            </Text>
            <Text style={[styles.text, { paddingLeft: 10 }]}>- Acciones</Text>
            <View style={styles.text}>
              <Text style={styles.list}>
                • En cuanto EL COMPRADOR termine de cancelar la totalidad de las
                cuotas pactadas, según el Cronograma de Pagos - Anexo 3, tendrá
                derecho a recibir un determinado número de acciones libres de
                gravámenes, cargas y cualquier obligación que limite o restrinja
                su dominio o libre disposición, las cuales tienen derecho a
                usufructo en su beneficio.
              </Text>
              <Text style={styles.list}>
                • El cumplimiento del pago del 100% de las acciones adquiridas,
                las cuales deben haber sido pagadas cumpliendo las fechas de
                pago, harán acreedor a EL COMPRADOR de un Certificado de
                Acciones Clase B, de acuerdo a su Paquete de acciones adquirido.
              </Text>
              <Text style={styles.list}>
                • La adquisición de acciones otorga el derecho a EL COMPRADOR a
                usufructuar el Hotel Club Resort, según su tipo de Programa de
                Beneficios. El tipo de Programa varía de acuerdo al número de
                acciones adquiridas por EL COMPRADOR.
              </Text>
              <View>
                <Text style={styles.list}>
                  • Las acciones Clase B corresponden a ser acciones sin derecho
                  a voto y con derecho a usufructo, estas confieren a su titular
                  la calidad de accionista y le atribuye, entre ellos, los
                  siguientes derechos:
                </Text>
                <View style={{ marginLeft: 10 }}>
                  <Text style={styles.list}>
                    1. Participar en el reparto de utilidades y del patrimonio
                    neto resultante de la liquidación.
                  </Text>
                  <Text style={styles.list}>
                    2. Derecho de usufructuar los espacios y beneficios en los
                    servicios de RIBERA DEL RIO CLUB RESORT S.A., según el tipo
                    de Programa de Beneficios adquirido.
                  </Text>
                  <Text style={styles.list}>
                    3. Posibilidad de participar en el Directorio, a través de
                    un representante elegido por todos los accionistas del
                    Sistema Red.{" "}
                  </Text>
                  <Text style={styles.list}>
                    4. Ser informado cuando menos semestralmente de las
                    actividades y gestión de la sociedad.
                  </Text>
                  <Text style={styles.list}>
                    Asimismo, le confiere a EL COMPRADOR los demás derechos
                    establecidos en el Estatuto de RIBERA DEL RIO CLUB RESORT
                    S.A., conforme a la Ley General de Sociedades.
                  </Text>
                </View>
              </View>
              <Text style={styles.list}>
                • La entrega del Certificado de Acciones Vitalicias, se
                realizará a la fecha del cumplimiento del pago total de las
                acciones. En el caso del Programa de Beneficios Light en
                adelante, se irán entregando Certificados Parciales, en función
                al cumplimiento de los segmentos. Dichos certificados, serán
                legalizados Notarialmente.{" "}
              </Text>
            </View>
          </View>
        </View>

        {/*5 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View style={styles.text}>
              <Text style={{ paddingLeft: 20 }}>
                • EL COMPRADOR tiene derecho a adquirir nuevas acciones,
                teniendo en consideración las siguientes condiciones:
              </Text>
              <View style={{ paddingLeft: 30 }}>
                <Text>
                  o El precio de las acciones corresponderá al valor de mercado
                  vigente en el momento de la compra.
                </Text>
                <Text>
                  o EL COMPRADOR al optar por la compra de nuevas acciones,
                  acepta las condiciones y normas vigentes a dicha fecha.
                </Text>
                <Text>
                  o EL COMPRADOR durante los primeros 12 meses puede ir migrando
                  entre Paquetes, desde la MINI hasta VITALICIA PREMIUM. Para la
                  cual, lo que habrá de pagar es la diferencia del valor entre
                  los Paquetes más la tasa de UPGRADE vigente en el momento.
                </Text>
                <Text>
                  o El número de paquete de acciones será determinado en función
                  a la ocupabilidad, poniendo el Hotel Club Resort a disposición
                  de sus socios el 25% de su capacidad instalada anual.
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.titleList}>- Usufructo</Text>
              <View style={{ paddingLeft: 20 }}>
                <Text>
                  • El derecho de usufructo de EL COMPRADOR tendrá una duración
                  de acuerdo al Programa de Beneficios – Anexo 1 adjunto al
                  presente Contrato. En ese sentido, VALLE ENCANTADO no se hace
                  responsable por el tiempo que EL COMPRADOR no haga uso del
                  Hotel Club Resort y sus beneficios, durante la vigencia del
                  mencionado Programa.
                </Text>
                <Text>
                  • El tipo de Programa de Beneficios que podrá disfrutar EL
                  COMPRADOR, varía según el Paquete de acciones adquirido,
                  detallado en el Anexo 1.
                </Text>
                <Text>
                  • Identificación: A las 48 horas de realizado el pago de la
                  cuota de ingreso, se actualizará en sistema el núcleo familiar
                  de EL COMPRADOR. Los cuales, para poder hacer uso del Programa
                  de beneficios que otorga el Paquete de acciones adquirido,
                  sólo deberán mostrar su Documento de Identidad (DNI, Carnet de
                  Extranjería o Pasaporte).
                </Text>
                <Text>
                  • Kit de Bienvenida: Cada COMPRADOR, después de formalizar su
                  sistema de pago, recibe el Kit en un plazo de 15 días útiles.
                  El Kit incluye: Carta de Bienvenida donde se consigna su
                  número de socio, Cronograma de Pagos (caso hubiese
                  fraccionado), y el Reglamento de conducta y Código de Ética de
                  RIBERA DEL RIO CLUB RESORT S.A. Este Kit de Bienvenida
                  únicamente será entregado en alguna de nuestras oficinas
                  previa coordinación con el titular o vía correo electrónico
                  según prefiera EL COMPRADOR.
                </Text>
                <Text>• Invitados:</Text>
                <View style={{ paddingLeft: 10 }}>
                  <Text>
                    o Cada socio tiene derecho a un número de invitados
                    mensuales (ver detalle en el Plan de Beneficios).
                  </Text>
                  <Text>
                    o Los invitados adicionales deberán pagar por su ingreso
                    según tarifario vigente. En el caso de eventos y reservas,
                    la cantidad de invitados puede variar de acuerdo a las
                    condiciones convenidas con EL COMPRADOR.
                  </Text>
                  <Text>
                    o Se aplicarán restricciones en fines de semana largos y/o
                    feriados.
                  </Text>
                </View>
                <Text>
                  • Beneficiarios Adicionales: EL COMPRADOR tendrá derecho a
                  adicionar hasta 3 personas en el núcleo familiar y/o
                  Beneficiarios directos. Realizando el pago correspondiente
                  según lo indique el plan tarifario vigente. Este pago está
                  especificado en el Anexo 1: Programa de Beneficios.{" "}
                </Text>
                <Text>
                  • Plan Vacacional: EL COMPRADOR tendrá beneficios vacacionales
                  a través de Vive Ahora Travel e incluso, a través de RCI (ID y
                  Pass) si así se indicara en el Anexo 1: Programa de
                  Beneficios. El inicio de uso de estos beneficios se indica en
                  el mencionado Anexo.
                </Text>
                <Text>
                  • Desacuerdo Varios: EL COMPRADOR tendrá derecho a revocar el
                  contrato durante el primer año, de no estar de acuerdo con los
                  avances del desarrollo, su administración, o
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/*6 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View style={{ paddingLeft: 25, paddingBottom: 10 }}>
              <Text>
                cualquier situación por la cual no desee seguir formando parte
                del Hotel Club Resort como Socio. Ante lo cual, RIBERA DEL RIO
                CLUB RESORT S.A. le devolverá el 65% de sus aportes en servicios
                en el Hotel Club Resort, mediante un Vale o Gift Card, dejando
                sin efecto este contrato. El Vale o Gift Card no aplica para
                restaurante y bares.
              </Text>
              <Text>
                • Cesión: EL COMPRADOR puede ceder, transferir o donar, parcial
                o totalmente cualquier beneficio y/u obligaciones bajo este
                contrato, con el solo llenado de los formatos correspondientes.
              </Text>
            </View>

            <View>
              <Text style={styles.subtitle}>SEXTO: INCUMPLIMIENTO</Text>

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  7.1.{"  "}El COMPRADOR renuncia irrevocablemente a invocar
                  hechos de terceros o causas no imputables
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  a él, en caso de incumplimiento de cualesquiera de las
                  obligaciones asumidas en el CONTRATO.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  7.2.{"  "}El incumplimiento de pago de las cuotas pactadas
                  conforme a los plazos estipulados en el
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  Cronograma de pagos constado en el Anexo 3 del presente
                  documento, le quitan a EL COMPRADOR el derecho de posesión de
                  las acciones que le correspondieran.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  7.3.{"  "}En caso que EL COMPRADOR se atrase en el pago de las
                  cuotas del financiamiento, según las
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  fechas estipuladas en el cronograma de pagos, se procederá a
                  la suspensión de los beneficios según Programa de Beneficio
                  Anexo 1, como sigue:
                </Text>
                <View style={{ paddingLeft: 30 }}>
                  <Text>
                    a) Atraso de UNA (1) CUOTA MENSUAL, se procederá a la
                    suspensión de los beneficios de descuentos.
                  </Text>
                  <Text>
                    b) Atraso de DOS (2) CUOTAS MENSUALES, se procederá a la
                    suspensión de los beneficios del Hotel Club Resort, Plan
                    Vacacional y Alojamiento.
                  </Text>
                  <Text>
                    c) Atraso de TRES (3) CUOTAS MENSUALES, se procederá a la
                    disolución del presente contrato y entrará a Preliquidación,
                    teniendo un (1) mes de plazo para regularizar los pagos de
                    las cuotas pendientes. Si EL COMPRADOR no cumple con
                    regularizar los pagos pendientes en el plazo adicional
                    otorgado, se resolverá el presente contrato por
                    incumplimiento contractual y entrará a Liquidación.
                  </Text>
                </View>
              </View>

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  7.4.{"  "}Aplicado el supuesto de liquidación y resolución
                  contractual mencionado en el punto anterior, EL
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  COMPRADOR pierde el derecho de adquirir las acciones
                  respectivas por haber incumplido el contrato y por en ende, no
                  se le emitirá el Certificado de acciones correspondiente.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  7.5.{"  "}En consecuencia, VALLE ENCANTADO se le devolverá a EL
                  COMPRADOR el 55% del monto de
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  dinero que haya cancelado de las CUOTAS de su membresía hasta la fecha de la resolución del presente
                  contrato en servicios que brinda el Hotel Club Resort
                  (Alojamiento, Alquiler de Espacios, Fulldays). Para ello,
                  VALLE ENCANTADO le otorgará a EL COMPRADOR un Vale equivalente
                  a dicho monto, para que pueda consumirlo contratando los
                  servicios del Hotel Club Resort. Este Vale cuenta con un plazo
                  de vigencia de 3 años como máximo, calculado a partir de la
                  fecha de su entrega a EL COMPRADOR. Cabe indicar que, el
                  mencionado vale no aplica para Bares y Restaurantes.
                </Text>
                <Text
                  style={{
                    textAlign: "justify",
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  Asimismo, VALLE ENCANTADO aplicará el 100% del monto cancelado por EL COMPRADOR en su CUOTA INICIAL 
                  y el 45% del monto cancelado por EL COMPRADOR en las CUOTAS de su membresía, como pago de Penalidad 
                  por incumplimiento contractual, correspondiente a gastos comerciales, tributarios, legales y administrativos.
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/*7 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View style={styles.text}>
              <Text style={styles.subtitle}>
                SETIMO: PAGO DE TRÁMITES LEGALES
              </Text>
              <Text style={styles.text}>
                Las partes establecen que los gastos notariales y registrales
                correspondiente a la transferencia de acciones, queda a cargo de
                EL COMPRADOR.{" "}
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>OCTAVO: ADICIONALES</Text>
              <Text style={styles.text}>
                Se adjunta a este contrato un Certificado de beneficios
                adicionales – Anexo 4, en donde se puede encontrar temas
                relacionados a promociones, extras, adendas u otros.{" "}
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>
                NOVENO: DOMICILIO Y NOTIFICACIONES LEGALES
              </Text>
              <Text style={styles.text}>
                Las partes señalan como sus domicilios los consignados en la
                introducción del presente contrato. Cualquier comunicación o
                notificación deberá ser cursada a dichos domicilios por escrito,
                con cargo de recepción. En caso de cambio de domicilio, para su
                efecto o validez legal deberá comunicarse a la otra parte, con
                cargo de recepción y a la brevedad posible. En tanto no
                recepcione la otra parte la citada comunicación se entenderán
                válidamente efectuadas las comunicaciones remitidas al anterior
                domicilio y surtirán todos sus efectos legales.
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>
                DÉCIMO: LEGISLACION APLICABLE{" "}
              </Text>
              <Text style={styles.text}>
                El contrato se regirá por la Legislación Peruana. En todo lo no
                previsto por las partes en el presente contrato, ambas se
                someten a lo establecido por las normas del Código Civil, la Ley
                General de Sociedades, los principios de buena fe contractuales
                y demás del sistema jurídico que resulten aplicables.
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>
                DÉCIMO PRIMERO: SOLUCIÓN DE CONTROVERSIAS
              </Text>
              <Text style={styles.text}>
                Queda expresamente establecido, que, ante toda divergencia o
                reclamo vinculados con la celebración, ejecución y/o
                interpretación del presente Contrato, las partes se someten
                expresamente a la jurisdicción de los jueces y tribunales del
                distrito judicial del Cercado de Lima, renunciando al fuero de
                sus domicilios.{" "}
              </Text>
              <Text style={styles.text}>
                Ambas partes declaran su conformidad con las cláusulas que
                anteceden, suscribiendo el presente documento, en dos (2)
                ejemplares de igual tenor y valor.
              </Text>
              <Text style={styles.text}>
                En señal de conformidad las partes suscriben este documento en
                la ciudad de Lima, a los{" "}
                {`${props.dataPDF.creationDate.split("/")[0]}`} días del mes de{" "}
                {`${props.dataPDF.creationDate.split("/")[1]}`} de{" "}
                {`${props.dataPDF.creationDate.split("/")[2]}`}
              </Text>
              <Text></Text>
              <Text></Text>
            </View>

            <View
              style={{
                paddingTop: 50,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <View style={{ flexDirection: "column", alignItems: "center" }}>
                <Image
                  src="/pdf/inresorts/firmaOmarUrteaga.jpg"
                  style={{ width: "100px", height: "50px" }}
                />
                <Text>____________________________</Text>
                <Text>VALLE ENCANTADO S.A.C.</Text>
                <Text>Omar Fernando Urteaga Cabrera</Text>
                <Text>Gerente General</Text>
                <Text>VALLE ENCANTADO</Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: 10,
                }}
              >
                <Text>____________________________</Text>
                <Text>{`${props.dataPDF.user.name} ${props.dataPDF.user.lastname}`}</Text>
                <Text>
                  Documento de identidad N°{props.dataPDF.user.nroDocument}
                </Text>
                <Text>EL COMPRADOR</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default InresortsPDF;
