function Footer() {
  return (
    <footer className="tw-bg-[#01173e] tw-text-white tw-text-sm tw-py-8">
      <section className="tw-flex tw-flex-col md:tw-flex-row  md:tw-justify-between tw-items-center md:tw-mx-32 tw-px-3 tw-gap-4">
        <p className="tw-m-0">
          <strong>&copy; 2021 InClub, Todos los derechos reservados.</strong>
        </p>

        <ul className="tw-flex tw-flex-row tw-gap-3 tw-p-0 tw-list-none">
          <li>
            <a
              href="https://www.facebook.com/Inclub.world"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="tw-w-8"
                src="/resources/icons-footer/facebook.png"
                alt="facebook inclub"
              ></img>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCoGUNtSjsFQjN6iSO72CK_w/videos"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="tw-w-8"
                src="/resources/icons-footer/youtube.png"
                alt="youtube inclub"
              ></img>
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
}

export default Footer;
