import React, { Component } from 'react';
//import avatar from '../../images/flyer/img_avatar.png';
import { Form, Spinner } from 'react-bootstrap';
import flyerEsmeralda from '../../images/flyer/flyer_esmeralda.jpeg';
import flyerOro from '../../images/flyer/flyer_oro.jpeg';
import flyerPlata from '../../images/flyer/flyer_plata.jpeg';
import flyerRuby from '../../images/flyer/flyer_ruby.jpeg';
import flyerSocio from '../../images/flyer/flyer_socio.jpg';
import flyerZafiro from '../../images/flyer/flyer_zafiro.jpeg';
import FlyerService from '../../services/flyer.service';
import '../styles/Flyer.css';

export default class FlyerView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idUser: props.user.id,
            user: props.user,
            loading: true,
            img: "",
            message: "",
            flyer: flyerSocio,
            styleFlyer: 'flyer',
            styleAvatar: 'avatar-flyer',
            text: ''
        };


    }

    componentDidMount() {
        this.getImage();
    }

    getImage = async () => {
        let response = await FlyerService.getImageFlyer(this.state.idUser);

        if (response !== undefined) {
            if (response.status === 1) {
                this.setState({
                    loading: false,
                    img: response.objModel
                });

                // select flyer TODO change for idrange
                if (this.state.user.nameRangeCompuesto.toUpperCase() === "SOCIO") {
                    this.setState({
                        flyer: flyerSocio,
                        styleFlyer: 'flyerSocio',
                        styleAvatar: 'avatar-flyerSocio'
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "PLATA") {
                    this.setState({
                        flyer: flyerPlata,
                        text: `${this.state.user.name} ${this.state.user.lastName}`
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "ORO") {
                    this.setState({
                        flyer: flyerOro
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "ZAFIRO") {
                    this.setState({
                        flyer: flyerZafiro
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "RUBY") {
                    this.setState({
                        flyer: flyerRuby
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "ESMERALDA") {
                    this.setState({
                        flyer: flyerEsmeralda
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "DIAMANTE") {
                    this.setState({
                        flyer: flyerSocio
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "DIAMANTE NEGRO") {
                    this.setState({
                        flyer: flyerSocio
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "DIAMANTE AZUL") {
                    this.setState({
                        flyer: flyerSocio
                    });

                } else if (this.state.user.nameRangeCompuesto.toUpperCase() === "DIAMANTE CORONA") {
                    this.setState({
                        flyer: flyerSocio
                    });

                }

            } else {
                this.setState({
                    loading: false,
                    message: response.description
                });
            }

        } else {
            this.setState({
                loading: false,
                message: "Ocurrió un error. Inténtelo más tarde."
            });
        }
    }

    render() {
        const { loading, img, message, flyer, styleAvatar, text } = this.state;

        return (
            <div>
                {loading &&
                    <div>
                        <Spinner animation="border" variant="dark">
                        </Spinner>
                        <p><Form.Label>Cargando información de socio.</Form.Label></p>
                    </div>
                }
                {!loading && img.length > 0 &&
                    <div>
                        <img className="img-fluid" src={flyer} alt=""></img>
                        <img src={`data:image/png;base64,${img}`} className={styleAvatar} alt=""></img>
                        <p className="text-flyer">{text}</p>
                    </div>
                }
                {!loading && message.length > 0 && img.length === 0 &&
                    <div>
                        <Form.Label>{message}</Form.Label>
                    </div>
                }

            </div>
        )
    }
}