import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import history from "../../views/navigation/history";

export default class FooterMenu extends Component {
  OnClicked = (e, path) => {
    history.push(path);
  };

  render() {
    return (
      <section id="footer-menu">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 content">
              <NavLink className="menu" to="/portfolio">
                Sobre nosotros
              </NavLink>
            </div>
            <div className="col-lg-4 content">
              <NavLink className="menu" to="/portfolio">
                Política de privacidad
              </NavLink>
            </div>
            <div className="col-lg-4 content">
              <NavLink className="menu" to="/portfolio">
                Términos y Condiciones
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
