import React, { Component } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import NetComponent from "../../components/network/NetComponent";
import Placement from "../../components/network/Placement";
import ManageActivation from "../../components/network/ManageActivation";
import ManagementRange from "../../components/network/ManagementRange";
import Sponsor from "../../components/network/Sponsors";
import NetResidual from "../../components/network/NetResidual";
import RecordRange from "../../components/network/RecordRange";
import RecordRangeResidual from "../../components/network/RecordRangeResidual";
import Achievement from "../../components/network/Achievement";
import ManagementOrganization from "../../components/network/ManagementOrganization";
import Deposition from "../../components/network/Deposition";
import { SalesIncentives } from "components/network/SalesIncentives";
import { DirectRating } from "components/network/DirectRating";

export default class NetworkView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: [],
    };

    //Ref
    this.childResidual = React.createRef();
  }
  setTree = (data, field) => {
    this.setState({
      tree: data,
    });
  };

  updateResidual = (message, loaded) => {
    // Call update tree of child
    this.childResidual.current.updateTree(message, loaded);
  };

  render() {
    const { tree } = this.state;

    return (
      <div>
        <Form.Label className="content-title">Mi Red</Form.Label>

        <Tabs className="custom-tabs-main" defaultActiveKey="treePat">
          <Tab eventKey="treePat" title="Árbol de Patrocinio">
            <NetComponent type="patrocinio"></NetComponent>
          </Tab>
          <Tab eventKey="treeResidual" title="Árbol Residual">
            <NetResidual
              ref={this.childResidual}
              type="residual"
              setTree={this.setTree}
            ></NetResidual>
            {/* <Form.Label>En construcción ...</Form.Label> */}
          </Tab>
          <Tab eventKey="sponsor" title="Lista de socios">
            <Sponsor></Sponsor>
          </Tab>
          <Tab eventKey="placement" title="Placement">
            <Tabs>
              <Tab eventKey="posicionamiento" title="Posicionamiento">
                <Placement
                  data={tree}
                  updateTree={this.updateResidual}
                ></Placement>
              </Tab>
              <Tab
                eventKey="desposicionamiento"
                title="Desposicionamiento"
              >
                   <Deposition
                  data={tree}
                  updateTree={this.updateResidual}
                ></Deposition>
              </Tab>
            </Tabs>
          </Tab>
          {/* <Tab eventKey="promotor" title="Promotores">
                            <Promotor></Promotor>
                        </Tab> */}
          <Tab eventKey="activations" title="Gestión de Activación">
            <ManageActivation></ManageActivation>
          </Tab>
          <Tab eventKey="ranges" title="Gestión de Rango">
            <ManagementRange></ManagementRange>
          </Tab>
          <Tab eventKey="historyRange" title="Historial de Rangos">
            <Tabs
              className="custom-tabs-main"
              defaultActiveKey="Achievement"
              style={{ paddingTop: "20px" }}
            >
              <Tab eventKey="Achievement" title="Mis logros">
                <Achievement></Achievement>
              </Tab>
              <Tab eventKey="SalesIncentives" title="Incentivo Venta">
                <SalesIncentives />
              </Tab>
              <Tab eventKey="RecordRange" title="Rangos adquiridos">
                <RecordRange></RecordRange>
              </Tab>
              <Tab eventKey="RecordResidual" title="Rangos residuales">
                <RecordRangeResidual></RecordRangeResidual>
              </Tab>
              <Tab eventKey="organizacion" title="Gestión de organización">
                <ManagementOrganization></ManagementOrganization>
              </Tab>
              <Tab eventKey="DirectRating" title="Calificación directa" >
                <DirectRating />
              </Tab>
            </Tabs>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
