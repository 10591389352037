import React, { Component } from "react";
import { Form, Tabs, Tab, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import StepOne from "./StepOne";

//Main register
export default class RegisterMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnNext: "none",
      last: false,
      tabs: ["Step1"],
      packages: [],
      kitInit: [],
      completeForm: false,
    };

    this.childPaypal = React.createRef();
  }

  componentWillReceiveProps(props) {
    this.setState({ packages: props.packages });
    this.setState({ kitInit: props.kitInit });
  }
  /**
   *
   * @param {*} data  value given for child conmponent, it associates with onChange function from child
   * @param {*} field properties send by child component
   * Send the data using props ( reference of its parents )
   */
  eventhandler = (data, field) => {
    this.props.onChange(data, field);
  };

  eventPay = (data, field) => {
    this.props.eventPay(data, field);
  };

  eventVoucher = (data, field) => {
    this.props.eventVoucher(data, field);
  };

  onNavigation = (e) => {
    e.preventDefault();
    let event = e.target.id;
    let current = this.state.currentTab;
    let idx = this.state.tabs.findIndex((element) => element === current);
    if (event === "btnBack") {
      idx = idx - 1;
    } else if (event === "btnNext") {
      idx = idx + 1;
    }
    this.updatePageNavigation(idx, current);
  };

  /**
   *
   * @param {*} e event associates navigation's buttons
   */

  handleSelect = (key) => {
    let idx = this.state.tabs.findIndex((element) => element === key);
    this.updatePageNavigation(idx, key);
  };

  updatePageNavigation = (idx, key) => {
    let back = "";
    let next = "";
    let last = false;

    if (idx === this.state.tabs.length - 1) {
      next = "none";
      back = "inline-block";
      last = true;
      this.props.eventFooter("flex", "displayFooter");
    } else if (idx === 0) {
      next = "inline-block";
      back = "none";
      last = false;
      this.props.eventFooter("none", "displayFooter");
    } else {
      next = "inline-block";
      back = "inline-block";
      last = false;
      this.props.eventFooter("none", "displayFooter");
    }
    this.setState(
      {
        currentTab: this.state.tabs[idx],
        btnNext: next,
        btnBack: back,
        last: last,
      },
      () => {}
    );
  };

  /**
   *
   */
  onValidate = (e) => {
    let isValidate = this.props.eventValidate();
    return isValidate;
    // if(isValidate) {
    // Activate footer of parent
    // this.props.eventFooter("flex", "displayFooter");
    // this.setState({
    //     completeForm: this.state.completeForm = true
    // });
    // } else {
    // this.setState({
    //     completeForm: this.state.completeForm = false
    // });
    // }
  };

  /**
   * Handle close event of paypal modal
   */
  handleClosePaypal = () => {
    this.childPaypal.current.handleClose();
  };

  render() {
    const { completeForm } = this.state;

    return (
      <div>
        <Form>
          {!completeForm && (
            <Form.Group>
              <StepOne onChange={this.eventhandler}></StepOne>
            </Form.Group>
          )}
        </Form>
      </div>
    );
  }
}
