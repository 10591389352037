import React, { Component } from "react";
import {
  Row,
  Form,
  Col,
  Button,
  InputGroup,
  Spinner,
  Modal,
} from "react-bootstrap";

import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import WalletService from "../../../services/wallet.service";
import Stepper from "../../utils/Stepper";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import chatbotService from "../../../services/chatbot.service";
import Swal from "sweetalert2";
const STATE_PAY_AFTER = 4;

export default class TransferWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      amount: 0,
      keyDigital: "",
      isShow: false,
      loading: false,
      code: "",
      stepOne: true,
      stepTwo: false,
      stepThree: false,
      completedRegister: false,
      message: "",
      numStep: 3,
      active: 1,
      loadingSend: false,
      showModalConfirm: false,
      loadSenData: false,
      messageLoad: "",
      name: "",
      lastname: "",
      infousertosend: "",
    };
  }

  handlechange = (e, field) => {
    let value = e.target.value.trim();

    this.setState({
      [field]: (this.state[field] = value),
    });
  };

  // Modal to confirm send money to user who has not completed youe registers.
  handleClose = () => {
    this.setState({
      showModalConfirm: (this.state.showModalConfirm = false),
    });
  };

  validateTransaction = async () => {
    this.handleClose();
    this.handleShowLoading("Validando transacción ...");

    let idUser = AuthService.getCurrentIdUser();
    let data = {
      IdUser: Number(idUser),
      AmountTransaction: Number(this.state.amount),
    };
    console.log('data send', data);
    let response = await WalletService.validateTransaccion(data);
    console.log(response, 'response data send')
    if (response.status > 0) {
      if (response.objModel === 1) {
        this.handleCloseLoading();
        this.sendCode();
      } else {
        this.handleCloseLoading();
        alert("No se puede realizar transacción. Saldo insuficiente.");
      }
    } else {
      this.handleCloseLoading();
      console.log("aqui epa aqui")
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: response.description,
        showConfirmButton: false,
        timer: 1500,
        toast: true,
      });
    }
  };

  // Validate account available and send code
  validateAccountAvailable = async (e) => {
    if (this.state.amount.length > 0 && this.state.username.length > 0) {
      if (!isNaN(Number(this.state.amount))) {
        if (Number(this.state.amount) > 0) {
          // Verify user
          let response = await UserService.verifyUser(this.state.username);

          if (response !== undefined && response.status === 1) {
            let idUser = AuthService.getCurrentIdUser();
            if (response.objModel.id !== Number(idUser)) {
              this.setState({
                infousertosend: response,
              }); //id user = datos.objModel.id
              //console.log(Number(idUser),this.state.infousertosend)

              if (response.objModel.idState === STATE_PAY_AFTER) {
                this.setState({
                  showModalConfirm: (this.state.showModalConfirm = true),
                });
              } else {
                this.validateTransaction();
              }
            } else {
              alert(
                "Ingrese otro usuario, no puede realizar una transferencia a su usuario."
              );
            }
          } else {
            alert(
              `Ocurrió un error al validar datos del usuario: ${this.state.username}`
            );
          }
        } else {
          alert("El monto debe ser mayor a 0 (cero).");
        }
      } else {
        alert("Ingrese un monto válido.");
      }
    } else {
      alert("Complete la informacion requerida.");
    }
  };

  sendCode = async () => {
    this.handleShowLoading("Enviando código de verificación a su correo ... ");
    let idUser = AuthService.getCurrentIdUser();
    //let response = await WalletService.getToken(idUser);//reemplazar
    let idDestino = this.state.infousertosend.objModel.id;
    let response = await WalletService.generateToken(
      Number(idUser),
      Number(idDestino)
    );
    if (response !== undefined && response.status === 1) {
      this.handleCloseLoading();
      this.setState({
        stepOne: (this.state.stepOne = false),
        stepTwo: (this.state.stepTwo = true),
        stepThree: (this.state.stepThree = false),
        active: (this.state.active = this.state.active + 1),
      });
    } else {
      this.handleCloseLoading();
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: response.description,
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    }
  };

  // Re send code
  reSendEmail = async () => {
    this.setState({
      loadingSend: (this.state.loadingSend = true),
    });

    let idUser = AuthService.getCurrentIdUser();
    //let response = await WalletService.getToken(idUser);
    let idDestino = this.state.infousertosend.objModel.id;
    let response = await WalletService.generateToken(
      Number(idUser),
      Number(idDestino)
    );
    if (response !== undefined && response.status === 1) {
      this.setState({
        loadingSend: (this.state.loadingSend = false),
      });
    } else {
      alert("Tuvimos problemas en enviar el código de validación.");
    }
  };

  validateCode = async (e) => {
    let data = {
      CodigoToken: this.state.code,
    };

    this.handleShowLoading("Validando código ... ");

    let idUser = AuthService.getCurrentIdUser();
    let response = await WalletService.verifyToken(idUser, data);
    this.handleCloseLoading();
    if (response !== undefined) {
      if (response.status === 1) {
        if (response.objModel === 1) {
          this.setState({
            stepOne: (this.state.stepOne = false),
            stepTwo: (this.state.stepTwo = false),
            stepThree: (this.state.stepThree = true),
            active: (this.state.active = this.state.active + 1),
          });
        } else {
          // this.handleCloseLoading();
          alert("Token no válido o ya expiró.");
        }
      } else {
        // this.handleCloseLoading();
        alert("Código incorrecto.");
      }
    } else {
      // this.handleCloseLoading();
      alert("Tuvimos problemas en validar tu código.");
    }
  };

  sendTransfer = async (e) => {
    if (this.state.keyDigital) {
      this.handleShowLoading("Registrando transferencia ... ");
      let idUser = Number(AuthService.getCurrentIdUser());
      let user = AuthService.getCurrentUser();
      let data = {
        IdUser: idUser,
        ClaveWeb: this.state.keyDigital,
        WalletTransaction: {
          Amount: Number(this.state.amount),
          ReferenceData: this.state.username,
          MsgData: `Transferencia de wallet al usuario: ${this.state.username}`,
        },
      };

      let response = await WalletService.registerTransaction(data, user);
      this.handleCloseLoading();
      if (response !== undefined) {
        if (response.status === 1) {
          this.reloadForm();
          alert("Su transferencia ha sido realizada correctamente.");

          if (this.props.updateBalance) {
            this.props.updateBalance();
          }
        } else {
          alert("Ocurrió un error mientras se procesaba su transferencia.");
        }
      } else {
        alert("Ocurrió un error al momento de registrar su transferencia.");
      }
    } else {
      alert("Ingrese clave.");
    }
  };

  reloadForm() {
    this.setState({
      username: (this.state.username = ""),
      amount: (this.state.amount = 0),
      keyDigital: (this.state.keyDigital = ""),
      loading: (this.state.loading = false),
      code: (this.state.code = ""),
      stepOne: (this.state.stepOne = true),
      stepTwo: (this.state.stepTwo = false),
      stepThree: (this.state.stepThree = false),
      completedRegister: (this.state.completedRegister = false),
      message: (this.state.message = ""),
      numStep: (this.state.numStep = 3),
      active: (this.state.active = 1),
    });
    this.forceUpdate();
  }

  // Show password
  showPassword = () => {
    this.setState({
      isShow: (this.state.isShow = !this.state.isShow),
    });
  };

  // Handle modal lo wait services
  handleShowLoading = (message) => {
    this.setState({
      loadSenData: (this.state.loadSenData = true),
      messageLoad: (this.state.messageLoad = message),
    });
  };
  handleCloseLoading = () => {
    this.setState({
      loadSenData: (this.state.loadSenData = false),
      messageLoad: (this.state.messageLoad = ""),
    });
  };

  render() {
    const {
      username,
      amount,
      code,
      keyDigital,
      isShow,
      loadSenData,
      messageLoad,
      loading,
      stepOne,
      stepTwo,
      stepThree,
      completedRegister,
      message,
      numStep,
      active,
      loadingSend,
    } = this.state;
    console.log(this.state);
    return (
      <div style={{ padding: 30, textAlign: "left" }}>
        {/* Paso 1 */}

        <div>
          {!completedRegister && (
            <div>
              <Row style={{ textAlign: "right", paddingBottom: 15 }}>
                <Col sm={12}>
                  <Button
                    variant="secondary"
                    onClick={(e) => this.reloadForm()}
                  >
                    Nueva transferencia
                  </Button>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Stepper numStep={numStep} active={active}></Stepper>
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <Col sm={4}>
                  <Form.Group
                    style={{
                      opacity: stepOne ? "1" : "0.5",
                      pointerEvents: stepOne ? "all" : "none",
                    }}
                  >
                    {/* <Form.Group style={{display: (stepOne)? "inline" : "none", pointerEvents: (stepOne)? "all":"none"}}> */}
                    <Form.Label className="content-subtitle">
                      Datos de la transferencia
                    </Form.Label>

                    <Form.Group className="mb-2">
                      <Form.Label>Usuario: </Form.Label>
                      <Form.Control
                        id="username"
                        value={username}
                        onChange={(e) => {
                          this.handlechange(e, "username");
                        }}
                        placeholder="Ingrese usuario destino"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Form.Label>Monto $: </Form.Label>
                      <Form.Control
                        id="amount"
                        value={amount}
                        onChange={(e) => {
                          this.handlechange(e, "amount");
                        }}
                        min="0"
                        placeholder="Ingrese monto"
                      ></Form.Control>
                    </Form.Group>
                    <Row>
                      <Col sm={12} style={{ paddingTop: 10 }}>
                        <Button
                          onClick={(e) => {
                            this.validateAccountAvailable(e);
                          }}
                        >
                          Siguiente
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group
                    style={{
                      opacity: stepTwo ? "1" : "0.5",
                      pointerEvents: stepTwo ? "all" : "none",
                    }}
                  >
                    <Form.Label className="content-subtitle">
                      Ingrese código
                    </Form.Label>
                    <Row>
                      <Col sm={12}>
                        <Form.Group style={{ margin: "0px" }}>
                          <Form.Label>
                            Se ha enviado un código de confirmación al correo
                            para transferir saldo a <br></br>
                            <h5>
                              <b style={{ color: "red" }}>
                                {this.state.infousertosend !== ""
                                  ? " " +
                                    this.state.infousertosend.objModel.name +
                                    " " +
                                    this.state.infousertosend.objModel
                                      .lastname +
                                    "."
                                  : ""}
                              </b>
                            </h5>
                            <br></br>
                            Revise su correo.
                          </Form.Label>
                        </Form.Group>
                        <Form.Group>
                          <Button
                            variant="light"
                            size="sm"
                            onClick={(e) => {
                              this.reSendEmail();
                            }}
                            style={{ color: "#142d47", fontWeight: "bold" }}
                          >
                            Reenviar correo
                          </Button>
                          {loadingSend && (
                            <Spinner
                              animation="border"
                              variant="dark"
                              size="sm"
                              className="ml-2"
                            ></Spinner>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>Ingrese código: </Form.Label>
                        <Form.Control
                          value={code}
                          onChange={(e) => {
                            this.handlechange(e, "code");
                          }}
                          placeholder="Ingrese código"
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} style={{ paddingTop: 10 }}>
                        <Button
                          onClick={(e) => {
                            this.validateCode(e);
                          }}
                        >
                          Validar código
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group
                    style={{
                      opacity: stepThree ? "1" : "0.5",
                      pointerEvents: stepThree ? "all" : "none",
                    }}
                  >
                    <Form.Label className="content-subtitle">
                      Verifique
                    </Form.Label>
                    <Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Label>Usuario: {username}</Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>Monto $ : {amount}</Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div className="form-group">
                          <label>Contraseña</label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              id="keyDigital"
                              value={keyDigital}
                              type={isShow ? "text" : "password"}
                              placeholder="Ingrese su clave digital"
                              onChange={(e) => {
                                this.handlechange(e, "keyDigital");
                              }}
                              autoComplete="new-password"
                            />
                            <InputGroup.Text
                              onClick={(e) => this.showPassword()}
                            >
                              {isShow && <FaEyeSlash></FaEyeSlash>}
                              {!isShow && <FaEye></FaEye>}
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        {/* <Form.Group>
                                                <Form.Label>Clave: </Form.Label>
                                                <Form.Control 
                                                    value={keyDigital}
                                                    onChange={e => {this.handlechange(e, 'keyDigital')}}
                                                    placeholder="Ingrese su clave digital"></Form.Control>
                                                
                                            </Form.Group> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} style={{ paddingTop: 10 }}>
                        <Button
                          onClick={(e) => {
                            this.sendTransfer(e);
                          }}
                        >
                          Transferir
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          )}
          {completedRegister && (
            <Row>
              <Col sm={12}>
                <Form.Label>{message}</Form.Label>
                <Button onClick={(e) => this.backView(e)}>Volver</Button>
              </Col>
            </Row>
          )}
        </div>

        <Modal show={this.state.showModalConfirm} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Advertencia</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Esta a punto de realizar una transferencia a un usuario que aún no
              ha completado el pago de su inscripción.
            </p>
            <p>¿Desea continuar?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={this.validateTransaction}>
              Si
            </Button>
          </Modal.Footer>
        </Modal>

        {/* loading */}
        <Modal
          show={loadSenData}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Spinner size="sm" animation="border" variant="dark"></Spinner>
              <Form.Label>&nbsp; {this.state.messageLoad}</Form.Label>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
