
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Svg,
  Rect,
} from "@react-pdf/renderer";

Font.register({
  family: "Arial",
  fonts: [
    { src: "/pdf/fonts/arial.ttf" },
    {
      src: "/pdf/fonts/arial-black.ttf",
      fontWeight: 700,
    },
  ],
});
const styles = StyleSheet.create({
  page: {},
  body: {
    fontSize: 10,
    fontFamily: "Arial",
    paddingTop: 10,
    paddingHorizontal: 70,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 25,
    paddingHorizontal: 50,
    paddingBottom: 15,
  },
  imgHeader: {
    maxWidth: "180px",
    opacity: 0.5,
  },
  title: {
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 20,
  },
  subtitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  text: {
    textAlign: "justify",
    paddingBottom: 10,
    //lineHeight: 1.5,
  },
  titleList: {
    textAlign: "justify",
    paddingLeft: 15,
    paddingBottom: 10,
  },
  list: {
    textAlign: "justify",
    paddingLeft: 20,
    paddingBottom: 2,
  },
  image: {
    width: "100%",
    marginVertical: 15,
    marginHorizontal: 90,
  },
  selected: {
    width: 20,
    height: 15,
    backgroundColor: "black",
    borderRadius: 1,
    border: "1 solid black",
  },
  noSelected: {
    width: 20,
    height: 15,
    backgroundColor: "white",
    borderRadius: 1,
    border: "1 solid black",
  },
});
const BeneficiosAdi_Inresorts = ({data}) => {
  return (
    <Document>
      <Page wrap size="A4">
        {/*1 */}
        <View style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.title}>
              CERTIFICADO DE BENEFICIOS ADICIONALES
            </Text>
            <Text style={styles.text} >
              Que el contrato N°{`${data.CONid}`} a nombre del Sr.(a) {`${data.user.name.toUpperCase()} ${data.user.lastname.toUpperCase()}`} identicado con DNI-DOCUMENTO {" "}
              NACIONAL DE IDENTIDAD: N°{`${data.user.nroDocument}`} podrá hacer uso de los beneficíos de su Paquete de acciones {data.numberShares}{" "}
              una vez cancelado el valor de la cuota inicial, según las especificaciones de su contrato.
            </Text>
            <View style={styles.text}>
              <View style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}>
                <Text style={{
                  marginRight: 40,
                }}>*Hotel CLub Resort</Text>
                <Text>Desde la inauguración de la primera etapa del Hotel Club Resort.</Text>
              </View>
              <View style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}>
                <Text style={{
                  marginRight: 45,
                }}>*Vive ahora Travel</Text>
                <Text>Desde la inauguración del Hotel Club Resort al 100%.</Text>
              </View>
              <View style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}>
                <Text style={{
                  marginRight: 105,
                }}>*RCI</Text>
                <Text>Válido para Vitalicias y Vitalicias Premium 100% pagadas.</Text>
              </View>
            </View>
            <View style={styles.text}>
              <View style={{
                display: "flex",
                alignItems: "center",
              }}>
                <View style={{
                  display: "flex",
                  width: "85%",
                  flexDirection: "row",
                }}>
                  <Text>
                    <Text style={{
                      fontWeight: "500",
                      letterSpacing: 1,
                    }}>
                      RCI{" "}
                    </Text>
                    (Afilicación por 1 año, luego de ello, el socio puede decidir permanecer en el Club
                    Internacional bajo un costo anual)
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.text}>
              <View style={{ display: "flex", alignItems: "center", }}>
                <View style={{ display: "flex", width: "85%", }}>
                  <Text style={{
                    textAlign: "justify",
                  }}>
                    -{'   '}Semanas de Intercambio Vacacional desde $ 359 (Beneficios activo desde
                  </Text>
                  <Text style={{
                    textAlign: "justify",
                    marginLeft: 10,
                  }}>
                    Inauguración del Resort) (Temporada Alta / Media / Baja).
                  </Text>
                </View>
                <View style={{ display: "flex", width: "85%", }}>
                  <Text style={{
                    textAlign: "justify",
                  }}>
                    -{'   '}Semanas de Escape desde $ 299 (Sujeto a cambios que RCI podria realizar en el
                  </Text>
                  <Text style={{
                    textAlign: "justify",
                    marginLeft: 10,
                  }}>
                    tiempo) (Temporada Media / Baja).
                  </Text>
                </View>
                <View style={{ display: "flex", width: "85%", }}>
                  <Text style={{
                    textAlign: "justify",
                  }}>
                    -{'   '}Mas de 4500 Desarrollo en más de 110 Países. Flexiblididad para viajar y
                  </Text>
                  <Text style={{
                    textAlign: "justify",
                    marginLeft: 10,
                  }}>
                    posibilidad de traspasar, regalar o ceder beneficios a un tercero.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.text}>
              <Text>Asimismo, este contrato aplica a las siguientes promociones:</Text>
              <Text>* Duplicar el número de acciones del Paquete adquirido como socio fundador.</Text>
              <Text>
                * Oportunidad de migrar a un Paquete de acciones superior, dentro de los 6 meses siguientes a la fecha de suscripción
                del contrato de transferencia de acciones con usufructo, con los mismos beneficios (Válido para Paquete de acciones
                desde Mini hasta Vitalicia).
              </Text>
            </View>
            <View
              style={{
                paddingTop: 60,
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <View style={{ flexDirection: "column", alignItems: "center" }}>
                <Image
                  src="/pdf/inresorts/firmaOmarUrteaga.jpg"
                  style={{ width: "100px", height: "50px" }}
                />
                <Text>____________________________</Text>
                <Text>VALLE ENCANTADO S.A.C.</Text>
                <Text>Omar Fernando Urteaga Cabrera</Text>
                <Text>Gerente General</Text>
                <Text>VALLE ENCANTADO</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default BeneficiosAdi_Inresorts