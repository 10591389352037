import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import ChatBot, { Loading } from 'react-simple-chatbot';
import ChatBotService from "../../services/chatbot.service";
import Validation from "../utils/Validation";

export default class InfoUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: true,
        result: '',
    };
  }

  componentDidMount(){
    this.InfoUser();
  }

  async InfoUser(){

    var nroDocument = localStorage.getItem("userDNI");
    let data = {
        nroDocument: nroDocument
    }

    ;

    let response = await ChatBotService.getInfoUser(data);
    
    if(response != undefined){
        if(response.status == 1){
            if(response.objModel != undefined){
                this.setState({
                    loading: false,
                    result: 'Confirmanos si tu correo es este : ' + response.objModel.email + ', es correcto?'
                })
                this.triggertNext();
            }else {
                this.setState({
                    loading: false,
                    result: 'Lo sentimos, no hay información de este usuario.'
                })
                this.triggertNextFalse();
            }
        }else{
            this.setState({
                loading: false,
                result: 'Hubo un error al consultar la información de este usuario.'
            })
            this.triggertNextError();
        }
    }else{
        this.setState({
            loading: false,
            result: 'Hubo un error al consultar la información de este usuario.'
        })
        this.triggertNextError();
    }

    /// end
  }

  triggertNext() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "2foptions"});
    });
  }

  triggertNextFalse() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "2f"});
    });
  }

  triggertNextError() {
    this.setState({ trigger: true }, () => {
      

      this.props.triggerNextStep({trigger: "2f"});
    });
  }


  render() {
      const {loading, result} = this.state;

    return (
      <div className="dbpedia" style={{color: '#fff'}}>
        { loading ? <Loading /> : result }

      </div>
    );
  }

}