
import { useEffect, useState, useRef, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Form, ListGroup, Row, Tab, Table, Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import LoadApi from "validators/LoadApi";
import UserService from "../../../services/user.service";
import Validation from "components/utils/Validation";
import userService from "../../../services/user.service";


const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];

const formInitDocument = {
    dateTransaction: "2024-02-21T12:16:29.468Z",
    amount: 0,
    additionalNote: "",
    conciliationId: 0,
    voucherNumber: "",
    typeDocument: -1,
    idMoney: "1",
    statementFiles: [
    ]
};

export const Relations = () => {

  const [desactiveBtnAdd, setDesactiveBtnAdd] = useState(false);
  const [dataLeft, setDataLeft] = useState([]);
  const [dataRight, setDataRight] = useState([]);
  const [isLoadingLeft, setIsLoadingLeft] = useState(false);
  const [isLoandingRight, setIsLoandingRight] = useState(false);
  const [msgErrRight, setMsgErrRight] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [documentConcilation, setDocumentConcilation] = useState(formInitDocument);
  const [id, setId] = useState(undefined);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [typeChange, setTypeChange] = useState({
    "idTipoCambio": 1458,
    "compra": 0,
    "venta": 0,
    "fecha": "2023-12-31T08:01:39.5",
    "fechaModificacion": null
  });

  useEffect(() => {
    getConciliationsByUser();
  }, []);

  const getExchangeRateLastDayOfTheMonth = async(year, month) => {
    const resp = await UserService.getExchangeRateLastDayOfTheMonth(year, month);

    if( resp.status == 1 && resp.objModel != null && resp.objModel.length > 0 ){
      setTypeChange(resp.objModel[0]);
    }else{
      setDocumentTypes(undefined);
    }
  }

  const getConciliationsByUser = async() => {
    setIsLoadingLeft(true);

    const resp = await UserService.getConciliationsByUser();
    const respDocuments = await UserService.getDocumentTypeInvoice();


    if( resp.status == 1 && resp.objModel ){
      setDataLeft(resp.objModel);
    }

    if( respDocuments.status == 1 && respDocuments.objModel ){
      setDocumentTypes(respDocuments.objModel);
    }
    
    
    setIsLoadingLeft(false);
  }


  const compareDate = (today, endDate) => {
    today = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );
    let day = "";
    let month = "";
    let year = "";

    if (today.getTime() > endDate.getTime()) {
      day = ("00" + endDate.getDate()).slice(-2);
      month = ("00" + (endDate.getMonth() + 1)).slice(-2);
      year = endDate.getFullYear();
    } else {
      day = ("00" + today.getDate()).slice(-2);
      month = ("00" + (today.getMonth() + 1)).slice(-2);
      year = today.getFullYear();
    }

    return day + "-" + month + "-" + year;
  };

  const getConciliationDetailsByConciliationId = async(id) => {
    setIsLoandingRight(true);
    setMsgErrRight("");
    setId(id);
    setDocumentConcilation(formInitDocument);

    const item = dataLeft.find(item => item.id === id);
    getExchangeRateLastDayOfTheMonth(item.year, item.monthNumber);


    const resp = await userService.getConciliationDetailsByConciliationId(id);


    if( resp.status == 1 && resp.objModel ){
      setDataRight(resp.objModel);

      if( resp.objModel.length == 0 ){
        setMsgErrRight("No hay Data")
      }
      
    }else{
      setMsgErrRight("Ocurrió un problema mientras obteniamos los registros. Inténtelo más tarde.")
    }
    
    setIsLoandingRight(false);
  }

  const handleChange = (e, field) => {
    let value = e.target.value;

    let temp = {...documentConcilation};
    temp[field] = value;

    console.log(temp)

    setDocumentConcilation(temp);
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      //console.log('Error: ', error);
    };
  }

  const  onFileChange = (event, field) => {
    let images = event.target.files;
    //console.log("image", event.target.files);
    let temp = {...documentConcilation};
    let arrayAux = [];

    console.log("images", event)

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        console.log('images[i]', images[i])

        const fileSize = images[i].size / 1024 / 1024;
        console.log("fileSize", fileSize);

        if( fileSize <= 10 ){
          arrayAux.push(images[i]);
        }else{
          alert("El tamaño del archivo debe ser menor a 10mb");
          event.target.value = "";
          return;
        }
        /*getBase64(images[i], (result) => {
          temp.statementFiles.push(result);
        });*/
        
      }
    }

    temp.statementFiles = arrayAux;
    setDocumentConcilation(temp)
  }

  const onSaveDocument = async() => {
    let data = {...documentConcilation, amountUsd: 0, currencyId:  documentConcilation.idMoney, exchangeRate: typeChange.compra  };
    data.amountUsd = Math.round((data.amount + Number.EPSILON) * 100) / 100;
    data.amount = data.idMoney == "1" ? data.amountUsd : Math.round(((typeChange.compra * data.amount) + Number.EPSILON) * 100) / 100;
    

    if( 
        data.dateTransaction == "" || 
        data.statementFiles.length == 0 ||
        data.voucherNumber == "" ||
        ( data.typeDocument == -1 || data.typeDocument == "" ) ||
        data.additionalNote == ""

      ){
      alert("Por favor debe completar el formulario");
      return;
    }

    let formData = new FormData();

    for (const key in data) {

      console.log("key", key, data[key]);
      let valor = data[key];

      if( key == 'statementFiles' ){
        data.statementFiles.map((item, idx) => {
          formData.append(`${key}`, item);

        })

        formData.append('statementFilesLength', data.statementFiles.length);
        
      }else{
        formData.append(key, valor);
      }

      
    }

    console.log('formData', formData.get("statementConcitialionDetails"), formData.get('statementFiles'));

    Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Guardando informacion...",
      showConfirmButton: false,
      timer: 2500,
      toast: true,
    });

    setDesactiveBtnAdd(true)
    const resp = await userService.conciliationInsertStatement(formData);

    console.log(resp, 'rsp')

    if( resp.status == 1 ){
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: resp.description,
        showConfirmButton: false,
        timer: 1500,
        toast: true,
      });

      setShowModal(false);
      getConciliationDetailsByConciliationId(id);

      return;
    }


    Swal.fire({
      position: "top-end",
      icon: "error",
      title: resp.description,
      showConfirmButton: false,
      timer: 1500,
      toast: true,
    });
    setDesactiveBtnAdd(true);

  }

  const onShowDocument = () => {
    const data = [...dataRight];
    let temp = {...documentConcilation};
      temp.conciliationId = id;

    let amount = 0;

    data.map(item => {
      if( item.conciliationId == temp.conciliationId ){
        amount+= item.amount;
      }
    })

    temp.amount = amount;

    setDocumentConcilation(temp)

    setShowModal(true);

  }

  const total = useMemo(() => {
    const amount = documentConcilation.amount;

    if( documentConcilation.idMoney == "2" && typeChange !== undefined && typeChange !== null ) {
      const calculo = typeChange.compra * amount;
      return Math.round((calculo + Number.EPSILON) * 100) / 100
    }

    return Math.round((amount + Number.EPSILON) * 100) / 100; 

  }, [documentConcilation])


  return (
    <div style={{ padding: 30 }}>
        <Tab.Container
          id="list-group-tabs-example"
          defaultActiveKey="#relation1"
        >
            <Row>
                <Col sm={5} md={3} lg={3}>
                  <div style={{ height: "100vh", overflow: "auto" }}>
                    <ListGroup action horizontal="xs" href="#relation1">
                      {
                        !isLoadingLeft &&
                        dataLeft.map((item, index) => {
                          console.log("ver data item conci", item);
                          let today = new Date();
                          let endDate = new Date(item.endDate);
                          let update = compareDate(today, endDate);
                          let amount = Math.round((item.amount + Number.EPSILON) * 100) / 100;


                          return (
                            <ListGroup.Item
                              onClick={() => getConciliationDetailsByConciliationId(item.id)}
                              active={
                                id &&
                                id === item.id
                                  ? true
                                  : false
                              }
                            >
                              {/*  COMISION INDIVIDUAL */}
                              <div>
                                <Row>
                                  <Form.Label column>
                                    Fecha Limit.:{" "}
                                    {Validation.formatDate(item.endDate)}
                                  </Form.Label>
                                </Row>
                                <Row>
                                  <Form.Label column>
                                    Mes: {item.monthName}
                                  </Form.Label>
                                </Row>
                                <Row>
                                  <Form.Label>Monto: ${amount}</Form.Label>
                                </Row>
                              </div>
                                  {/*  COMISION INDIVIDUAL */}
                            </ListGroup.Item>
                          );
                          
                        })
                      }
                      {isLoadingLeft && (
                        <LoadApi
                          indicatorLoad={true}
                          message="Cargando registros..."
                        ></LoadApi>
                      )}
                    </ListGroup>
                  </div>      
                </Col>
                <Col xs={10} sm={7} md={9} lg={9}>
                  {isLoandingRight && (
                    <LoadApi
                      indicatorLoad={true}
                      message="Cargando registro..."
                    ></LoadApi>
                  )}
                  {msgErrRight !== "" && (
                    <Row sty>
                      <Col sm={12}>
                        <Form.Label>{msgErrRight}</Form.Label>
                      </Col>
                    </Row>
                  )}
                  {
                    !isLoandingRight && dataRight.length > 0 &&
                    <section className="mt-2" style={{textAlign: 'justify'}}>
                      {
                        id && dataLeft.find(item => item.id == id && item.stateId != 2) 
                        && 
                        <Button 
                          type="button"
                          variant="primary"
                          className="mb-3"
                          onClick={() => onShowDocument()}


                        >
                          Subir documentos
                        </Button>
                      }
                     
                      <Table responsive size="sm">
                        <thead className="table-head-comision">
                          <tr>
                            <th>Periodo</th>
                            <th>Monto</th>
                            <th>Estado</th>
                            <th>Observaciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dataRight.map((item, index) => {
                              let date = new Date(item.createDate);
                              let mes = months[date.getMonth() + 1];
                              let amount = Math.round((item.amount + Number.EPSILON) * 100) / 100;

                              return(
                                <tr key={index}>
                                  <th>{item.periodDate}</th>
                                  <th>{amount}</th>
                                  <th>{item.name}</th>
                                  <th>{item.observation}</th>
                                </tr>
                              );

                            })
                          }
                        </tbody>
                      </Table>
                    </section>
                  }
                </Col>
            </Row>
        </Tab.Container>
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          centered
          style={{ fontSize: 12 }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>¿Deseas subir tus documentos?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Número de comprobante *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      defaultValue={documentConcilation.voucherNumber}
                      onChange={(e) => handleChange(e, "voucherNumber")}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Fecha Emisión *</Form.Label>
                    <Form.Control
                      required
                      type="date"
                      placeholder=""
                      defaultValue={documentConcilation.dateTransaction}
                      onChange={(e) => handleChange(e, "dateTransaction")}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Moneda*</Form.Label>
                    <Form.Select
                      defaultValue={"1"}
                      onChange={(e) => handleChange(e, "idMoney")}
                    >
                      <option value={"1"}>Dólares - USD</option>
                      <option value={"2"}>Soles - PEN</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}>
                      <Form.Label style={{textAlign: 'left'}}>Monto*</Form.Label>
                      { documentConcilation.idMoney == "2" && typeChange != undefined ? <Form.Label>Tipo de cambio: {typeChange.compra}</Form.Label> : <div></div> }
                    </div>
                    <div
                      style={{
                        backgroundColor: '#e9ecef',
                        padding: '.375rem .75rem',
                        fontSize: '1rem',
                        fontWeight: 400,
                        lineHeight: 1.5
                      }}    
                    >
                      { total }
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Tipo de documentos</Form.Label>
                    <Form.Select
                      defaultValue={-1}
                      onChange={(e) => handleChange(e, "typeDocument")}
                    >
                      <option value={-1}>Selecciona un documento</option>
                      {
                        documentTypes.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Nota Adicional *</Form.Label>
                    <textarea name="additionalNote" className="w-100" onChange={(e) => handleChange(e, "additionalNote")} id="" cols="20" rows="5">{documentConcilation.additionalNote}</textarea>
                  </Form.Group>
                </Col>
                <Col sm={12} className={typeChange == undefined ? "mb-5" : "mb-3"}>
                  <Form.Group>
                    <Form.Label>Tus Documentos*</Form.Label>
                      <Form.Control
                        required
                        type="file"
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={(e) => onFileChange(e, "statementFiles")}
                        multiple
                      />
                  </Form.Group>
                </Col>
                <Col sm={12} style={{textAlign: 'center'}}>
                 {
                  !desactiveBtnAdd &&
                  <Button variant="primary"  size="sm" onClick={() => onSaveDocument()}>
                    Agregar
                  </Button>
                 }
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
    </div>
  )
}
