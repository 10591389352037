const API_URL = `${process.env.REACT_APP_APP_URL_API}`;

class ToolsService {
  async getDocumentos(data) {
    let url = API_URL + "/educational/document/section";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async downloadFile(id, name) {
    let url = API_URL + "/educational/document/" + id;

    return await fetch(url)
      .then((response) => { console.log('response pdf', response); return response.blob() })
      .then((response) => {
        return response;
      })
      .then((blob) => {
        console.log('blob', blob);
        if (blob.size == 0) {
          return false;
        } else {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = name;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          return true;
        }
      })
      .catch(function(err) {
        console.error(err);
        return false;
      });
  }

  async getImageFile(data) {
    let url = API_URL + "/educational/document/picture";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
}
export default new ToolsService();
