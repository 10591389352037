
const API_URL = process.env.REACT_APP_APP_URL_API;

class ChatBotService {
    
    async generateticketgeneral(data) {
        let url = API_URL + "/Ticket/ticketfromchatbot";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });
    }

    async ValidateSponsor(data) {
        let url = API_URL + "/User/validatesponsor";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });

    }

    async getAvailableUser(data) {
        let url = API_URL + "/user/verifydocument";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });

    }
    async getUserByUsername(data) {
        let url = API_URL + "/user/search/username";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });

    }

    async getAvailableUserForUpdate(data) {
        let url = API_URL + "/User/availableuserforupdate";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });

    }

    async getInfoUser(data) {
        let url = API_URL + "/User/bynrodocument";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });

    }

    async generateticket(data) {
        let url = API_URL + "/Ticket/generate";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });

    }

    async DateAffiliation(data) {
        let url = API_URL + "/User/affiliationdate";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });

    }

    async VerifyCoAffiliate(data) {
        let url = API_URL + "/coaffiliate/verify";
        return await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type':'application/json'
            }
            })
            .then(function(response) {
                return response.json();
            })
            .then(function(dataJson) {
                
                //return state true or false in objmodel
                
                return dataJson;
            })
            .catch(function(err) {
                console.error(err);
                return undefined;
            });

    }

    async getMembersUser(id) {
        let url = API_URL + "/User/susbyid/" + id;
        
        return await fetch(url)
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            return undefined;
        });

    }

    async getUrl(id) {
        let url = API_URL + "/Ticket/url/" + id;
        
        return await fetch(url)
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            return undefined;
        });

    }

}

export default new ChatBotService();