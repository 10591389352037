import React, { Component } from 'react';
import { Form , Row, Col, Button, Spinner, Table, Modal } from 'react-bootstrap'
import { MdFileDownload } from 'react-icons/md';

import Validation from '../../utils/Validation';
import WalletService from '../../../services/wallet.service';

const EL_PAYPAL = 1;
const EL_PAYEER = 2;
export default class Transfer extends Component{

    constructor(props){
        super(props);
        this.state = {
            idUser: props.idUser,
            history : [],
            emptyList: true,
            message: "No hay registros para mostrar.",
            loading: false,
            messageMount: "",
            mount: "",
            base64Image: "",
            name: "",
            imageRaw: "",
            typesElectronic : [],
            listElectronic: [],
            currentList:[],
            idElectronicPurse: "",
            img:"",
            showModal: false,
            totalPages: 1,
            page: 1,
            size: 10,
            totalRegister: 0,
            loadSenData : false,
            messageLoad: false,
        }
    }

    componentDidMount(){
        this.getType();
        this.getAccounts();
        this.getRegisters();
    }

    // Get registers
    async getRegisters() {
        
        this.setState({
            registers: this.state.registers = [],
            loading: this.state.loading = true,
       });
        // Get by id
        let data = {
            "SizeList" : Number(this.state.size),
            "NroPage" : Number(this.state.page),
            "ParametersSearched" : {
                "IdSocioSearch" : Number(this.state.idUser)
            }
        }
        let response = await WalletService.getHistoryTransferElectronic(data);
     
        if(response !== undefined ){
            if(response.status !== 1) {
                //;
                this.setState({
                    history: this.state.history = [],
                    loading: this.state.loading = false,
                    emptyList: this.state.emptyList = true,
                    noDataMesssage : this.state.noDataMessage = "Ocurrió un problema mientras obteniamos los registros. Inténtelo más tarde.",
                });
            } else {
                if(response.objModel !== null && response.objModel.listElements.length > 0) {
                    
                 
                    this.setState({
                        history: this.state.history = response.objModel.listElements,
                        totalRegister: this.state.totalRegister = response.objModel.totalElemList,
                        totalPages: this.state.totalPages = response.objModel.totalPages,
                        loading: this.state.loading = false,
                        emptyList: this.state.emptyList = false,
                        noDataMesssage : this.state.noDataMessage = "",
                    });
                } else {
                    this.setState({
                        history: this.state.history = [],
                        totalRegister: this.state.totalRegister = 0,
                        totalPages: this.state.totalPages = 0,
                        loading: this.state.loading = false,
                        emptyList: this.state.emptyList = true,
                        noDataMesssage : this.state.noDataMessage = "No hay registros para mostrar.",
                    });
                }
               
            }
        } else {
            this.setState({
                history: this.state.history = [],
                loading: this.state.loading = false,
                emptyList: this.state.emptyList = true,
                noDataMesssage : this.state.noDataMessage = "Ocurrió un problema. Inténtelo más tarde.",
            });
        }
}

    async getAccounts() {

        let response = await WalletService.getElectronicbyUser(this.state.idUser);
        if(response !== undefined && response.status === 1) {

            for( let i = 0; i < response.objModel.length; i++) {
                response.objModel[i].description = "";

                if(EL_PAYEER === response.objModel[i].idElectronicPurseCompany){
                    response.objModel[i].description = "Payeer";
                } else if(EL_PAYPAL === response.objModel[i].idElectronicPurseCompany){
                    response.objModel[i].description = "Paypal";
                }
            }

            this.setState({
                listElectronic: this.state.listElectronic = response.objModel
            });
        }
    }

    async getType() {

        let response = await WalletService.getElectronic();
        if(response !== undefined && response.status === 1) {
           
            this.setState({
                typesElectronic: this.state.typesElectronic = response.objModel
            });
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    }

    // Handle image
    onFileChange = (event, field) => {
        
        let image = event.target.files[0];
       
        if(image !== undefined) {
            this.getBase64(image, (result) => {
                
                let base64Image = result;
                this.setState({
                    img  : base64Image,
                });

            });

        } else {
            this.setState({
                img  : ""
            });
        }
        
    }

    handleChange = (e, field) => {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
    }

    validate = () => {

        if(Number(this.state.idElectronicPurse) <= 0) {
            alert("Seleccione una cuenta")
            return false;
        }
        if(Number(this.state.mount) <= 0) {
            alert("Ingrese un monto válido.")
            return false;
        }
        if(Number(this.state.img) <= 0) {
            alert("Seleccione una imagen.")
            return false;
        }

       
        return true;
    }

    handleShowModal = () => {
     
        this.setState({
            showModal: true,
        });
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false,
        });
    }

    // Register tranfers
    registerTransfer = async(e) => {
        if(this.validate()) {
            this.setState({
                loadSenData: true
            });
            let contenType = this.state.img.split(',');
           
            let data = {
                "IdUser" : Number(this.state.idUser),
                "IdElectronicPurse" : Number(this.state.idElectronicPurse), 
                "CPicture": {
                    "Base64": contenType[1], 
                }, 
                "WalletTransaction" : {
                    "Amount" : Number(this.state.mount)
                }
            };
            let response = await WalletService.registerTransferElectronic(data);
            if(response !== undefined) {
                this.setState({
                    loadSenData: false
                });
                if(response.status === 1) {
                     // add to history list
                    
                    this.setState({
                        mount: "",
                        showModal: false,
                        size: 10,
                        page: 1 
                    });   
                    this.getRegisters();         
                    alert("Solicitud registrada exitosamente.");    

                } else {
                    alert("Ocurrió un error al momento de registrar su transferencia.");
                }

            } else {
                this.setState({
                    loadSenData: false
                });
                alert('Tuvimos un problema. Inténtalo más tarde.');
            }
        }
    }

    // ShowModal
    handleClose = () => {
        this.setState({
            showModal : false,
        });
    }

    handleShow = () => {
        this.setState({
            showModal : true
        });
    }

    // Handle select 
    handleSelect = (e, field) => {
        
        var value = Number(e.target.value);
        this.setState({
            idElectronicPurse: value,
        });
    }
    render() {

        const { loading, message, history, emptyList, messageMount, loadSenData, 
            listElectronic, size, page, totalRegister, totalPages,
            showModal } = this.state;

            let optionPages = [];
            for(let i = 0; i < totalPages; i++) {
                optionPages.push(<option key={`t${i}`} value={i+1}>{i+1}</option>)
            }
        return(
            <div style={{padding: 30}}>

              
                <Row>
                    <Col sm={12}>
                        <Form.Label className="content-subtitle" >Historial de solicitudes</Form.Label>
                    </Col>
                </Row>
                <Row style={{textAlign: 'right'}}>
                    <Col sm={12}>
                        <Button variant="success" size="sm" onClick={e => this.handleShowModal()}>Solicitar transferencia</Button>
                    </Col>
                </Row>
                 {/* Paginador */}
                 <Row>
                 <Form.Label column xs={12} sm={8} md={7} xl={6}>
                    Mostrando del {size * (page - 1) + 1} al{" "}
                    {size * page > totalRegister ? totalRegister : size * page} de{" "}
                    {totalRegister} registros. &nbsp;&nbsp;| Página:{" "}
                    <Form.Select
                            className="my-2 ml-2 mr-1"
                            id="selecPagesComision"
                            defaultValue={"1"}
                            value={page}
                            size="sm"
                            style={{display:"inline",width:"auto"}}
                            onChange={e => {this.handlePageChange(e, 'page')}} 
                        >
                            {optionPages}
                    </Form.Select>
                    {" "} de {totalPages}. Por página: {" "}
                    <Form.Select
                            className="my-2 mr-sm-4"
                            id="selectCountcomision"
                            defaultValue={size}
                            size="sm"
                            style={{display:"inline",width:"auto"}}
                            onChange={e => {this.handleSizeChange(e, 'size')}} 
                        >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                    </Form.Select>
                 </Form.Label>
                    
                </Row>
                <hr></hr>
                { loading && 
                    <div>
                        <Spinner animation="border" variant="dark">
                        </Spinner>
                        <p><Form.Label>Cargando información de socios.</Form.Label></p>
                    </div>
                }
                {   emptyList && !loading && 
                    <Form.Label>{message}</Form.Label>
                }
                {  !emptyList && 
                <div>
                    <Row>
                        <Col sm={12}>
                            <Table responsive>
                                <thead className="table-head">
                                    <tr>
                                        <th>Nº </th>
                                        <th>Fecha</th>
                                        <th>Titular</th>
                                        <th>Cuenta</th>
                                        <th>Descripción</th>
                                        <th>Monto</th>
                                        <th>Solicitud</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { history.map(function(item, idx) {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{size*(page - 1) + 1 + idx}</td>
                                                        <td>{Validation.convertDateToStringComplete(item.inicialDate)}</td>
                                                        <td>{item.nameUser} {item.lastNameUser}</td>
                                                        <td>{item.paidLink}</td>
                                                        <td >{item.referenceData}</td>
                                                        <td>{Math.abs(item.amount)}</td>
                                                        <td>{item.nameRequest}</td>
                                                    </tr>
                                                )
                                            })

                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
                 }

                 {/* Solicitar transferencia */}
                <Modal 
                    show={showModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    onHide={this.handleCloseModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Solicitar transferencia</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Seleccione cuenta de destino:</Form.Label>
                            <Form.Control as="select" 
                                defaultValue="Default"
                                onChange={e => {this.handleSelect(e, 'idElectronicPurse')}}>
                                <option value="Default" disabled>Seleccione cuenta ...</option>
                                {listElectronic.map((elem) => (
                                    <option key={elem.idElectronicPurse} value={elem.idElectronicPurse}>{elem.description} - {elem.usernameAccount}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label> Monto:</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Ingrese monto"
                                min={0}
                                onChange={e=>{this.handleChange(e, 'mount')}}
                            />
                            <Form.Label className="textAler">{messageMount}</Form.Label>
                       
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Subir comprobante y/o Declaración jurada </Form.Label>
                            <input type="file"
                                 accept="image/png, image/jpeg"
                                  onChange={e => this.onFileChange(e, "img")}/>
                            <br></br>
                            <a href="/files/template.jpeg" download><MdFileDownload></MdFileDownload>Ejemplo</a><br></br>

                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                    size="sm"
                                    onClick={e => {this.registerTransfer(e)}}>Guardar</Button>
                        <Button variant="secondary"
                                    size="sm"
                                    onClick={e => {this.handleCloseModal()}}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
              
                {/* loading */}
                <Modal
                    show={loadSenData}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered>
                    <Modal.Body>
                        <div style={{textAlign: 'center'}}>
                            <Spinner size="sm" animation="border" variant="dark">
                            </Spinner>
                            <Form.Label>&nbsp; Guardando información...</Form.Label>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}