import React, { Component } from "react";
import { Form, Button, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextField, FormHelperText } from "@mui/material";
import history from "../../views/navigation/history";
import UserService from "../../services/user.service";
import Buttonmui from "@mui/material/Button";

export default class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      mesagge: "",
      isloading: false,
    };
  }

  handleChange = (e, field) => {
    this.setState({
      [field]: e.target.value,
      message: "",
    });
  };

  sendData = async () => {
    if (this.state.email !== "") {
      this.setState({ isloading: true });
      var data = {};
      data.username = this.state.email;
      console.log("haber data que se va enviar", data);
      let startRecovery = await UserService.startRecovery(data);
      console.log("haber startRecovery", startRecovery);
      if (startRecovery !== undefined) {
        if (startRecovery.status === 1) {
          this.sendMail();
        } else {
          this.setState({ isloading: false });
          alert("Correo electrónico o usuario no esta registrado.");
          // this.setState({
          //   mesagge: "Correo electrónico o usuario no esta registrado.",
          // });
        }
      } else {
        this.setState({ isloading: false });
        alert("Ocurrió un problema. Inténtelo más tarde. sendData");
      }
    }
  };

  sendMail = async () => {
    console.log("entras sendMail");
    this.setState({ isloading: true });
    var data = {};
    data.username = this.state.email;
    console.log("haber data que se va enviar", data);
    let stateRecovery = await UserService.recoveryPassword(data);
    this.setState({ isloading: false });
    if (stateRecovery !== undefined) {
      if (stateRecovery.status === 1) {
        this.setState({ isloading: false });
        alert("Le hemos enviado un correo.");
        history.push("/");
      } else {
        alert(
          "Ocurrió un problema al enviarle el correo. Inténtelo más tarde."
        );
      }
    } else {
      alert("Ocurrió un problema. Inténtelo más tarde.");
    }
  };

  render() {
    return (
      <Form className="formrecoverpasswordcontainer">
        <h3>Reestablece tu contraseña</h3>
        <Form.Group>
          {/* <FormLabel>Ingresa tu correo electrónico o usuario</FormLabel> */}
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 50,
              },
              "& #outlined-required-label": {
                fontSize: 15.5,
              },
              "& .MuiOutlinedInput-input": {
                fontSize: 15.5,
              },
            }}
            fullWidth
            id="outlined-required"
            size="small"
            label="Ingresa tu correo electrónico o usuario "
            variant="outlined"
            onChange={(e) => this.handleChange(e, "email")}
          />
          <FormHelperText sx={{ marginBottom: 1, fontSize: 11 }}>
            Recibirás un correo para reestablecer tu contraseña.
          </FormHelperText>
          <Buttonmui
            variant="contained"
            size="medium"
            fullWidth
            // onClick={!this.state.isloading ? this.sendData : null}
            onClick={!this.state.isloading ? this.sendMail : null}
          >
            {this.state.isloading ? (
              <div style={{ margin: "auto" }}>
                <div className="spinnerinicio">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              "Continuar"
            )}
          </Buttonmui>
        </Form.Group>
        {/* <Form.Group>
          <Form.Label>Ingresa tu correo electrónico o usuario: </Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Ingrese su correo electrónico o usuario."
            onChange={(e) => this.handleChange(e, "email")}
          />
          <Form.Text className="textAlert">{this.state.message}</Form.Text>
          <Form.Text>
            Recibirás un correo para reestablecer tu contraseña.{" "}
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className="d-grid gap-2">
          <Button 
            className="buttonborderradius mt-3"
            type="button"
            variant="primary"
            onClick={!this.state.isloading?this.sendData:null}
            disabled={this.state.isloading}>
              {this.state.isloading?
              <div className="spinnerinicio"> 
                  <span className="visually-hidden">Loading...</span>
              </div>
              :"Reestablecer contraseña"}
              
            </Button>
            </div>
        </Form.Group> */}
      </Form>
    );
  }
}
