import React, { useRef, useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Container,
  InputGroup,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";

const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;

function PhotoDni() {
  const consumirFotoDni = async (data) => {
    console.log("url", API_URL);
    console.log("entraste a consumirFotoDni", data);
    try {
      let url = API_URL + "user/documentidentity";
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await resp.json();
      //const { objModel } = result;
      console.log("a ver resultado", result);
      return result;
      // setDatosObtenidosDeLaPeticionDeLaTabla(objModel.listElements);
      //return objModel.listElements;
    } catch (error) {
      console.log(error);
    }
  };

  const refaddimgflyer = useRef();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();

  const infoUsuario = sessionStorage.getItem("info");

  const validateandconvtobase64 = (target, field) => {
    const datosUsuario = JSON.parse(infoUsuario);
    console.log("datos del usuario", datosUsuario);

    let file = target.files[0]; //ESTE SERIA EL VALOR A COGER
    // console.log("soy file", file);
    let fileName = target.value;
    //console.log("soy fileName", fileName);
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile === "tif" ||
      extFile === "bmp" ||
      extFile === "svg"
    ) {
      let reader = new FileReader();
      reader.readAsDataURL(file); // hasta aqui igual q el video

      reader.onload = function() {
        var base64 = reader.result;
        // console.log("base64 primer", base64);
        console.log("soy id", datosUsuario.id);
        console.log("soy username", datosUsuario.username);
        console.log("base64 segundo", base64.split(",")[1]);
        const datos = {
          userid: datosUsuario.id,
          username: datosUsuario.username,
          Base64: base64.split(",")[1],
        };
        console.log("esto se enviara", datos);
        consumirFotoDni(datos);
        /*  field.onChange(reader.result.split(",")[1]); */
      };
      reader.onerror = function(error) {
        alert("Error:" + error);
      };
    } else {
      refaddimgflyer.current.value = "";
      alert({
        nameAlert: "😰¡Error inesperado!, solo se permite subir imágenes",
        typeAlert: "error",
      });
    }
  };

  return (
    <Row>
      <Col sm={6}>
        <Form>
          <Controller
            name="Picture.Base64"
            control={control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Form.Control
                ref={refaddimgflyer}
                type="file"
                id="ImageFlyer"
                accept="image/*"
                onChange={(e) => validateandconvtobase64(e.target, field)}
              />
            )}
          />
        </Form>
      </Col>
    </Row>
  );
}

export default PhotoDni;
