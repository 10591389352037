import { useEffect, useState, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Form, ListGroup, Row, Tab, Table, Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import LoadApi from "validators/LoadApi";
import UserService from "../../../services/user.service";
import authService from '../../../services/auth.service';


const formInitDocument = {
  userId: +authService.getCurrentIdUser(),
  year: 0,
  number: "",
  date: "",
  nroDocument: "",
  noteAditional: "",
  file: "",
  amount: 0,
  idCurrency: 0
}

export const Exoneration = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [msgErr, setMsgErr] = useState("");

  const [showModal, setShowModal] = useState(false);
  const documentExoneration = useRef(formInitDocument);

  useEffect(() => {
    getRentExemptionByUser();
  }, [])
  

  const getRentExemptionByUser = async() => {
    setIsLoading(true);
    const resp = await UserService.getRentExemptionByUser();


    if( resp.status == 1 && resp.objModel ){
      setData(resp.objModel);
    }
    
    setIsLoading(false);
  }

  const handleChange = (e, field) => {
    let value = e.target.value;

    let temp = {...documentExoneration.current};
    temp[field] = value;

    documentExoneration.current = temp;
  };

  const  onFileChange = (event, field) => {
    let image = event.target.files;
    let temp = {...documentExoneration.current};

    if( image.length > 0 ){
      const fileSize = image[0].size / 1024 / 1024;

      if( fileSize <= 10 ){
        temp.file = image[0];
      }else{
        alert("El tamaño del archivo debe ser menor a 10mb");
        event.target.value = "";
        return;
      }
    }
   
    documentExoneration.current = temp;
    console.log(temp)
   
  }

  const onSaveDocument = async() => {
    let data = {...documentExoneration.current};
    const date = new Date();
    data.year = date.getFullYear();
    data.amount = +data.amount;

    if( 
        data.date == "" || 
        data.file == "" ||
        data.nroDocument == "" ||
        data.noteAditional == "" ||
        data.number == "" ||
        data.amount == 0 ||
        data.amount == ""
      ){
      alert("Por favor debe completar el formulario");
      return;
    }

    if( data.amount <= 0 ){
      alert("El monto de declaración, debe ser un valor positivo");
      return;
    }
    
   
    data.amount = Math.round((data.amount + Number.EPSILON) * 100) / 100;

    console.log("data", data);

    let formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Guardando informacion...",
      showConfirmButton: false,
      timer: 2500,
      toast: true,
    });

    const resp = await UserService.rentExemptionInsert(formData);

    console.log("res sds", resp);

    if( resp.status == 1 ){
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: resp.description,
        showConfirmButton: false,
        timer: 1500,
        toast: true,
      });

      setShowModal(false);
      documentExoneration.current = {...formInitDocument};
      getRentExemptionByUser();
      return;
    }


    Swal.fire({
      position: "top-end",
      icon: "error",
      title: resp.description,
      showConfirmButton: false,
      timer: 1500,
      toast: true,
    });

  }

  return (
    <div style={{ padding: 30 }}>
      <Tab.Container
        id="list-group-tabs-example"
        defaultActiveKey="#exoneration1"
      >
        <Row>
          <Col sm={12} md={12} lg={12} style={{textAlign: 'left'}}>
            {isLoading && (
              <LoadApi
                indicatorLoad={true}
                message="Cargando registro..."
              ></LoadApi>
            )}
            {msgErr !== "" && (
              <Row sty>
                <Col sm={12}>
                  <Form.Label>{msgErr}</Form.Label>
                </Col>
              </Row>
            )}
            {
              !isLoading &&
              <Button 
                type="button"
                variant="primary"
                className="mb-3"
                onClick={() => setShowModal(true)}


              >
                Subir documentos
              </Button>
            }
            {
              !isLoading &&
              <Table responsive size="sm">
                <thead className="table-head-comision">
                  <tr>
                    <th>Año</th>
                    <th>Monto a recibir</th>
                    <th>Nro. de operación</th>
                    <th>RUC</th>
                    <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !isLoading &&
                    data.map((item, idx) => {
                      let date = new Date(item.date);
                      let month = `${(date.getMonth() + 1)}`.padStart(2,"0");
                      let day = `${date.getDate()}`.padStart(2, "0");
                          date = `${date.getFullYear()}/${month}/${day}`;  
                      let amount = Math.round((item.amount + Number.EPSILON) * 100) / 100;

                      return(
                        <tr key={idx}>
                          <th>{item.year}</th>
                          <th>{amount}</th>
                          <th>{item.number}</th>
                          <th>{item.nroDocument}</th>
                          <th>{item.noteAditional}</th>
                        </tr>
                      )

                    })
                  }
                </tbody>
              </Table>
            }
          </Col>
        </Row>
      </Tab.Container>
      <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          centered
          style={{ fontSize: 12 }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>¿Deseas subir tu documento?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Monto de declaración *</Form.Label>
                    <Form.Control
                      required
                      min="0"
                      type="number"
                      placeholder=""
                      defaultValue={documentExoneration.current.amount}
                      onChange={(e) => handleChange(e, "amount")}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Fecha Exoneración *</Form.Label>
                    <Form.Control
                      required
                      type="date"
                      placeholder=""
                      defaultValue={documentExoneration.current.date}
                      onChange={(e) => handleChange(e, "date")}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Número de operación *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      defaultValue={documentExoneration.current.number}
                      onChange={(e) => handleChange(e, "number")}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>RUC *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      defaultValue={documentExoneration.current.nroDocument}
                      onChange={(e) => handleChange(e, "nroDocument")}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Nota Adicional *</Form.Label>
                    <textarea name="additionalNote" required className="w-100" onChange={(e) => handleChange(e, "noteAditional")} id="" cols="20" rows="5">{documentExoneration.current.noteAditional}</textarea>
                  </Form.Group>
                </Col>
                <Col sm={12} className={"mb-5"}>
                  <Form.Group>
                    <Form.Label>Subir Documento*</Form.Label>
                      <Form.Control
                        required
                        type="file"
                        accept="application/pdf"
                        onChange={(e) => onFileChange(e, "file")}
                      />
                  </Form.Group>
                </Col>
                <Col sm={12} style={{textAlign: 'center'}}>
                  <Button variant="primary" size="sm" onClick={() => onSaveDocument()}>
                    Agregar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
    </div>
  )
}
