// Test
export const PAGO_EFECTIVO_AUTH = 'https://pre1a.services.pagoefectivo.pe/v1/authorizations';
export const PAGO_EFECTIVO_GENCIP = 'https://pre1a.services.pagoefectivo.pe/v1/cips';
export const PAGO_EFECTIVO_ACCESSKEY = ""; // 20
export const PAGO_EFECTIVO_IDSERVICE = 1; // 4
export const PAGO_EFECTIVO_SECRETKEY = "";
export const PAGO_EFECTIVO_EMAIL = "lizbethlima@gmail.com";
export const PAGO_EFECTIVO_CODE_TRANS = "";

//stripe
export const PUBLIC_KEY = "pk_test_51ILHeLFbnwyUwscb7fz9LRkCJNArWlI0dbOaSqsgKIURL7Q4SUGnfNIorRW9f8E35Dzygm3ExIFDL8YyF8xteH3R00HLTf4Vd1";
export const KEY_STRIPE ="sk_test_51ILHeLFbnwyUwscbDM7L7X6e4JLI7TBPA9AJDQjibIM9lkoV1id6Qza6CWHG9onwQ9FBATFv9eCKpYjoJTX31ASs00bNB3lLXk";


// Production