import { createContext, useState } from 'react';

const NewNotificationContext = createContext();

function NewNotificationProvider(props) {
    const [isContextNewNotification, setIsContextNewNotification] = useState(false)

    return (
        <NewNotificationContext.Provider value={{ isContextNewNotification, setIsContextNewNotification }}>
            {props.children}
        </NewNotificationContext.Provider>
    )
}
export { NewNotificationContext, NewNotificationProvider };

