import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Spinner,
  Accordion,
  Button,
  FormCheck,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import authService from "../../services/auth.service";
import LoadApi from "validators/LoadApi";
import "./PackageSelected.css";
import Swal from "sweetalert2";
import userService from "services/user.service";
import utilsService from "services/utils.service";
import Validation from "components/utils/Validation";

const API_URL = process.env.REACT_APP_APP_URL_API;

const salert = (text = "", icon = "") => {
  const Tst = Swal.mixin({
    toast: true,
    position: "top-end",
    timer: 2000,
    showConfirmButton: false,
  });
  Tst.fire({
    icon: icon,
    title: text,
  });
};
export default class PackageSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegister: false,
      package: [],
      kitInit: [], // selected kit
      packages: [],
      checkList: [],
      packageFamily: [],
      familyInit: [],
      packageUpgrade: [],
      loading: true,
      noData: false,
      message: "",
      packageStatus: "",
      packageSelected: undefined,
      upgradeOptions: [],
      suscription: [],
      schedule: [],
      loadSuscription: false,
      promotionalCode: "",
      isLoadedApiData: true,
      codeStatus: false,
      selectedPackage: [],
      insortActivos: 0,
      insortActivosConVitalicia: 0,
    };

    this.getPackageFamily = this.getPackageFamily.bind(this);
    this.getSuscription = this.getSuscription.bind(this);
    this.getUpgradeSuscription = this.getUpgradeSuscription.bind(this);
    this.ValidateFilter = this.ValidateFilter.bind(this);
  }
  componentDidMount() {
    this.getPackageFamily();
    this.getUpgradeSuscription();
    // this.getSuscription();
  }

  async getPackageFamily() {
    //let data = await UtilService.getPackages();
    let data = await utilsService.getPackageswithdetailsbyfamily(); //UtilService.getPackageswithdetailsbyfamily();
    console.log("AQUI SE VEN LOS PAQUETES =>", data);
    if (data !== undefined) {
      if (data.objModel.length > 0) {
        // Store packages in temp
        let temp = [];
        let i;
        for (i = 0; i < data.objModel.length; i++) {
          temp = temp.concat(data.objModel[i].packages);
        }
        // sepate kit package family and packages families
        // Get kit initial
        let packageFamily = [];
        let familyInit = [];
        let idInit = 27;
        let x;
        for (x = 0; x < data.objModel.length; x++) {
          if (data.objModel[x].id === idInit) {
            data.objModel[x].packages.sort(function(a, b) {
              return (
                a.listPackageDetails[0].price - b.listPackageDetails[0].price
              );
            });
            familyInit.push(data.objModel[x]);
          } else {
            data.objModel[x].packages.sort(function(a, b) {
              return (
                a.listPackageDetails[0].price - b.listPackageDetails[0].price
              );
            });
            packageFamily.push(data.objModel[x]);
          }
        }

        // Add
        this.setState({
          tempPackage: temp,
          packageFamily: packageFamily,
          familyInit: familyInit,
          loading: false,
          noData: false,
        });

        // call this.getSuscription();
        this.getSuscription();
      } else {
        this.setState({
          packageFamily: [],
          familyInit: [],
          loading: false,
          noData: true,
          message: "No hay registros para mostrar.",
        });
      }
    } else {
      this.setState({
        packageFamily: [],
        familyInit: [],
        loading: false,
        noData: true,
        message:
          "Ocurrió un error al obtener los paquetes. Vuelva a intentarlo más tarde.",
      });
    }
  }

  async getSuscription() {
    // Get currect suscription and remove fron list packageFamily
    let suscriptions = await userService.getSuscription(); //UserService.getSuscription();
    console.log("TODAS LAS SUSBSCRIPCIONES EN TIENDA ==>", suscriptions);
    if (suscriptions !== undefined && suscriptions !== null) {
      const { objModel } = suscriptions;
      //console.log("haber objModel", objModel);
      let soloInresorts = objModel.filter(
        (item) => item.packageDetailResponse.nameFamilyPackage == "INRESORTS"
      );

      //console.log("haber soloInresorts", soloInresorts);
      let soloInresortsActivos = soloInresorts.filter(
        (item) => item.statusName == "Activo"
      );
      //console.log("haber soloInresortsActivos", soloInresortsActivos);
      let inresortVitalicia = soloInresortsActivos.filter((item) => {
        if (item.package.name.includes("Vitalicia")) {
          return item;
        }
      });
      //console.log("haber inresortVitalicia", inresortVitalicia);
      let existeInsortsActivos = soloInresortsActivos.length;
      let existeInsortActivosConVitalicia = inresortVitalicia.length;
      console.log("hay socios activos de inserost?", existeInsortsActivos);
      console.log(
        "hay socios activos de inserost con vitalicia?",
        existeInsortActivosConVitalicia
      );

      if (suscriptions.status !== 1) {
        this.setState({
          suscription: [],
          loadSuscription: true,
        });
      } else {
        let susTemp = this.state.packageFamily;

        this.setState({
          suscription: suscriptions.objModel,
          loadSuscription: true,
          insortActivos: existeInsortsActivos,
          insortActivosConVitalicia: existeInsortActivosConVitalicia,
        });
      }
    }
  }

  async getUpgradeSuscription() {
    let iduser = authService.getCurrentIdUser();
    await fetch(`${API_URL}/suscription/user/${iduser}/upgrade`)
      .then((res) => res.json())
      .then((respuesta) => {
        console.log("TIENDA getUpgradeSuscription,", respuesta);
        if (respuesta.status === 1) {
          this.setState({
            packageUpgrade: respuesta.objModel,
          });
        } else {
          this.setState({
            packageUpgrade: [],
          });
        }
      })
      .catch((error) => {
        this.props.onChange(error, "isErrorApiData");
      });
  }
  modifydatatosend = (e) => {
    let selectedPackage = [
      Object.assign(
        {},
        {
          id: e.packageId,
          name: e.package.name,
          codeMembership: e.package.codeMembership,
          description: e.package.description,
          idFamilyPackage: e.familyPackageId,
          estatus: e.package.estatus,
          legalDocuments: e.package.legalDocuments,
          listPackageDetails: [
            {
              idPackageDetail: e.idPackageDetail,
              packageId: e.packageId,
              monthsDuration: e.monthsDuration,
              price: e.price,
              numberQuotas: e.numberQuotas,
              initialPrice: e.initialPrice,
              quotaPrice: e.quotaPrice,
              volume: e.volume,
              numberInitialQuote: e.numberInitialQuote,
              comission: e.comission,
              numberShares: e.numberShares,
              familyPackageId: e.familyPackageId,
              membershipVersionId: e.membershipVersionId,
              package: null,
              membershipVersion: {
                idMembershipVersion: e.membershipVersion.idMembershipVersion,
                description: e.membershipVersion.description,
              },
            },
          ],
        }
      ),
    ];
    return selectedPackage;
  };

  handleSelection = (e, field) => {
    // Only accept kit init o packages
    if (field === "packages") {
      if (this.state.codeStatus) {
        selectedPackage = this.modifydatatosend(e);
      } else {
        var idField = Number(e.target.id); // id package
        var selectedPackage = {};
        let tempPackages = this.state.tempPackage;
        let i;
        for (i = 0; i < tempPackages.length; i++) {
          if (tempPackages[i].id === idField) {
            selectedPackage = [tempPackages[i]];
          }
        }
      }
      let discount =
        selectedPackage[0] && selectedPackage[0].discount
          ? selectedPackage.discount
          : 0;
      this.setState({ selectedPackage: selectedPackage }, () => {
        if (this.props.onChange) {
          this.props.onChange(selectedPackage, "packages");
          this.props.onChange(discount, "discount");
          this.props.onChange([], "kitInit");
        }
      });
    } else if (field === "kitInit") {
      if (this.state.codeStatus) {
        selectedPackage = this.modifydatatosend(e);
      } else {
        var selectedPackage = {};
        let tempPackages = this.state.familyInit[0].packages;
        let i;
        for (i = 0; i < tempPackages.length; i++) {
          if (tempPackages[i].id === idField) {
            selectedPackage = [tempPackages[i]];
          }
        }
      }
      this.setState({ kitInit: selectedPackage }, () => {
        if (this.props.onChange) {
          this.props.onChange(selectedPackage, "kitInit");
          this.props.onChange([], "packages");
        }
      });
    }
  };
  /**
   * Method to handle the change  of properties and send it to parent
   * @param {*} e
   * @param {*} field
   */
  handleSelectionmig = (itempckm) => {
    //console.log("haber idpackage", itempckm);
    this.setState({
      packageSelected: [itempckm],
    });
    // this.props.onChangeMigration(itempckm)
    if (this.props.onChange) {
      this.props.onChange([itempckm], "packagesm");
      this.props.onChange(0, "discount");
      this.props.onChange([], "kitInit");
      // this.props.onChange(, "idsuscription");
    }
  };
  handleChange = (e, field) => {
    let value = e.target.value;
    if (this.props.onChange) {
      this.props.onChange(e.target.value, field);
      this.setState({
        [field]: (this.state[field] = value),
        messageDoc: "",
      });
    }
    // })
  };

  /**
   * Method to handle the selected item  send it to parent
   * @param {*} e
   * @param {*} field
   */

  handleSelectPackage = (e, field) => {
    var idSus = Number(e.target.value);
    //HASTA AQUI TODO BIEN
    // console.log("haber id", idSus);
    let idx = e.target.selectedIndex - 1; // idx of array in the combobox
    let upgradeOptions;
    this.props.onChange(false, "isloadedmig");
    if (this.state.packageUpgrade.length > 0 && idx >= 0) {
      let packageSelected = [];
      fetch(`${API_URL}/package/details/suscription/${idSus}/upgrade`)
        .then((rpta) => rpta.json())
        .then((response) => {
          if (response.status === 1) {
            upgradeOptions = response.objModel;
            upgradeOptions.sort((u, d) => u.price - d.price);
            upgradeOptions = upgradeOptions.filter(
              (obj) => obj.package.estatus === 1
            );
            this.setState({
              packageSelected: packageSelected, //package for migration
              upgradeOptions: upgradeOptions,
              selectedPackage: undefined, //package for buy
            });
            this.setState({ [field]: idSus }, () => {
              this.props.onChange(idSus, field);
              this.props.onChange([], "packagesm");
              this.props.onChange(true, "isloadedmig");
            });
          } else {
            this.setState(
              {
                upgradeOptions: [],
                selectedPackage: undefined, //package for buy
              },
              () => {
                this.props.onChange(true, "isloadedmig");
              }
            );
          }
        })
        .catch((error) => {
          this.props.onChange(error, "isErrorApiData");
        });
    }
  };
  /**
   * Method to handle the change  of properties and send it to parent
   * @param {*} e
   * @param {*} field
   */
  handleRadio = (e, field) => {
    // if (this.props.onChange) {
    //     this.props.onChange(e.target.value, field);
    //   }
    var value = e.target.value;
    this.setState({ [field]: value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value, field);
      }
    });

    this.setState({
      packageSelected: undefined,
      upgradeOptions: [],
      kitInit: [], // selected kit
      packages: [],
    });

    if (this.props.onChange) {
      this.props.onChange([], "kitInit");
      this.props.onChange([], "packages");
      this.props.onChange([], "packagesm");
    }
  };
  handlepromotionalcode = (code) => {
    this.setState({ promotionalCode: code });
  };
  ValidateFilter = () => {
    this.props.onChange([], "packages");
    let iduser = authService.getCurrentUserInfo();
    let data = Object.assign(
      {},
      { userId: iduser.id, code: this.state.promotionalCode }
    );
    this.setState({ isLoadedApiData: false, selectedPackage: undefined });
    //this.getPackageFamily();
    // fetch(API_URL + "/familyPackage/package/detail/version/state?value=true").then
    fetch(API_URL + "/promotionals/codes/family/version/code/validate", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          let familyPackageId = data.objModel.familyPackageId;
          let membershipVersionId = data.objModel.membershipVersionId;
          fetch(
            API_URL +
              "/package/details/family/" +
              familyPackageId +
              "/version/" +
              membershipVersionId
          )
            .then((res) => res.json())
            .then((result) => {
              if (result.objModel.length > 0) {
                let packagespromcode = result.objModel;
                packagespromcode.sort((a, b) => a.price - b.price);
                this.setState({
                  codeStatus: true,
                  showpmessage: true,
                  packagePromotionalcode: result.objModel,
                  isLoadedApiData: true,
                });
                this.props.onChange(
                  this.state.promotionalCode,
                  "promotionalCode"
                );
              } else {
                this.setState({
                  showpmessage: true,
                  isLoadedApiData: true,
                  codeStatus: false,
                });
              }
            })
            .catch((error) => {
              salert(error, "error");
              this.setState({
                codeStatus: false,
                isLoadedApiData: true,
              });
            });
        } else {
          this.setState({
            showpmessage: true,
            codeStatus: false,
            isLoadedApiData: true,
          });
        }
      })
      .catch((error) => {
        salert(error, "error");
        this.setState({
          codeStatus: false,
          isLoadedApiData: true,
        });
      });
  };

  // ValidateFilter = () => {
  //   this.props.onChange(this.state.promotionalCode, "promotionalCode")
  //   // fetch(API_URL+"/package/details/family/"+1+"/version/"+1).then(res=>res.json())
  //   //     .then((result)=>{
  //   //         if (result.objModel.length>0){
  //   //             console.log(result.objModel)
  //   //             this.setState({
  //   //                 codeStatus:true,
  //   //                 packagePromotionalcode: result.objModel
  //   //             })
  //   //             this.props.onChange(this.state.promotionalCode,"promotionalCode")
  //   //         }
  //   // })
  // }

  ////llamar suscripciones

  render() {
    const {
      packageFamily,
      loading,
      noData,
      message,
      suscription,
      loadSuscription,
      familyInit,
      packageUpgrade,
      upgradeOptions,
      isLoadedApiData,
      codeStatus,
      packagePromotionalcode,
      insortActivos,
      insortActivosConVitalicia,
    } = this.state;

    const userName = JSON.parse(sessionStorage.getItem("info")).username;
    console.log("userName en TIENDA =>", userName);
    return (
      <div>
        {loading && (
          <LoadApi
            indicatorLoad={true}
            message="Cargando información de paquetes..."
          ></LoadApi>
        )}
        {!loading && !noData && (
          <div>
            <Form.Label className="content-subtitle">
              ¿Qué Deseas Hacer?
            </Form.Label>
            <Row className="mb-3">
              <Col sm={6}>
                <Form.Check
                  inline
                  type="radio"
                  label="Comprar Nuevo Paquete"
                  id={`inline-radio`}
                  value="N"
                  style={{ marginTop: 2 }}
                  onChange={(e) => this.handleRadio(e, "packageStatus")}
                  checked={this.state.packageStatus === "N"}
                />
              </Col>
              {/* no olvidar comentar */}
              {/*  <Col sm={6}>
                <Form.Check
                  inline
                  label="Upgrade mi Membresía: "
                  type="radio"
                  id={`inline-radio2`}
                  value="U"
                  onChange={(e) => this.handleRadio(e, "packageStatus")}
                  checked={this.state.packageStatus === "U"}
                />
              </Col> */}
            </Row>
            {this.state.packageStatus === "N" && (
              <section>
                <Row>
                  <Col xs={10} sm={7} md={6} lg={3}>
                    <Form className="form-style-1">
                      <Form.Group as={Row}>
                        <Col>
                          <Row>
                            <Col>
                              <Form.Label>Código promocional</Form.Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={7} sm={8} md={8} className="lg8">
                              <Form.Control
                                as="input"
                                isValid={false}
                                className="form-control-style"
                                onChange={(e) =>
                                  this.handlepromotionalcode(e.target.value)
                                }
                                type="text"
                                defaultValue=""
                                inline
                              ></Form.Control>
                            </Col>
                            <Col xs={5} sm={4} md={4} className="lg4">
                              <Button
                                size="sm"
                                variant="primary"
                                type="button"
                                className="btncodeval"
                                onClick={
                                  isLoadedApiData
                                    ? () => this.ValidateFilter()
                                    : null
                                }
                                disabled={!isLoadedApiData}
                              >
                                {!isLoadedApiData ? "Espere..." : "Aplicar"}
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={8} sm={8} md={8} className="lg8">
                              <Form.Text className="text-muted">
                                {this.state.showpmessage ? (
                                  <>
                                    {codeStatus ? (
                                      <p className="txtgreenpromcode">
                                        Código Válido
                                      </p>
                                    ) : (
                                      <p className="txtredpromcode">
                                        Código Inválido
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Form.Text>
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                <LoadApi indicatorLoad={!this.state.isLoadedApiData}>
                  <Row>
                    <Col sm={12}>
                      <Form.Label className="content-subtitle">
                        Seleccione un paquete:{" "}
                      </Form.Label>
                    </Col>
                  </Row>
                  {/* Kit initial */}
                  <Row>
                    <Col sm={12}>
                      <Accordion>
                        {familyInit.map((item, i) => {
                          return item.packages.length > 0 ? (
                            <Accordion.Item eventKey={item.id} key={item.id}>
                              <Accordion.Header
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.name}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Table
                                  striped
                                  bordered
                                  hover
                                  responsive="md"
                                  size="sm"
                                >
                                  <thead className="table-head">
                                    <tr>
                                      {/* <th></th>
                                                            {
                                                                item.headers.map((header, id) => (
                                                                <th>{header}</th>
                                                                ))
                                                            } */}
                                      <th></th>
                                      <th>Nombre</th>
                                      <th>Descripción</th>
                                      <th>Precio</th>
                                      <th>Número de cuotas</th>
                                      <th>Cuota inicial</th>
                                      {/* <th>Duración</th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!codeStatus &&
                                      item.packages.map((itempck, idx) => {
                                        return itempck.estatus === 1 ? (
                                          <tr key={itempck.id}>
                                            <td>
                                              <Form.Check
                                                type="radio"
                                                name="kitInit"
                                              >
                                                <FormCheck.Input
                                                  disabled
                                                  type="radio"
                                                  name="kitInit"
                                                  id={itempck.id}
                                                  onChange={(e) =>
                                                    this.handleSelection(
                                                      e,
                                                      "kitInit"
                                                    )
                                                  }
                                                ></FormCheck.Input>
                                              </Form.Check>
                                            </td>
                                            <td>{itempck.name}</td>
                                            <td>
                                              {itempck.description}
                                              <br></br>
                                              {"Duración: " +
                                                itempck.listPackageDetails[0]
                                                  .monthsDuration +
                                                " meses"}
                                              {/* <ul>
                                                                                {
                                                                                    itempck.descriptions.map((label, idxLabel) => (
                                                                                    <li>{label}</li>
                                                                                    ))
                                                                                }
                                                                            </ul> */}
                                            </td>
                                            <td>
                                              ${" "}
                                              {
                                                itempck.listPackageDetails[0]
                                                  .price
                                              }
                                            </td>
                                            <td>
                                              {
                                                itempck.listPackageDetails[0]
                                                  .numberQuotas
                                              }
                                            </td>
                                            <td>
                                              ${" "}
                                              {
                                                itempck.listPackageDetails[0]
                                                  .initialPrice
                                              }
                                            </td>
                                            {/* <td>{itempck.duration}</td> */}
                                          </tr>
                                        ) : null;
                                      })}

                                    {codeStatus &&
                                      packagePromotionalcode.map(
                                        (itempckPc, idx) => {
                                          if (
                                            itempckPc.familyPackageId ===
                                            item.id
                                          ) {
                                            return (
                                              <tr key={idx}>
                                                <td>
                                                  <Form.Check
                                                    type="radio"
                                                    name="kitInit"
                                                  >
                                                    <FormCheck.Input
                                                      type="radio"
                                                      name="kitInit"
                                                      id={item.id}
                                                      onChange={(e) =>
                                                        this.handleSelection(
                                                          itempckPc,
                                                          "kitInit"
                                                        )
                                                      }
                                                      disabled
                                                    />
                                                  </Form.Check>
                                                </td>
                                                <td>
                                                  {itempckPc.package.name}
                                                </td>
                                                <td colSpan={3}>
                                                  {
                                                    itempckPc.package
                                                      .description
                                                  }
                                                  <br></br>
                                                  Duración:{" "}
                                                  {itempckPc.monthsDuration >
                                                  1 ? (
                                                    <div>
                                                      {itempckPc.monthsDuration}{" "}
                                                      meses
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      {itempckPc.monthsDuration}{" "}
                                                      mes
                                                    </div>
                                                  )}
                                                </td>
                                                <td>$ {itempckPc.price}</td>
                                                <td>
                                                  {itempckPc.numberQuotas}
                                                </td>
                                                <td>
                                                  {itempckPc.initialPrice}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        }
                                      )}
                                  </tbody>
                                </Table>
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : null;
                        })}
                      </Accordion>
                    </Col>
                  </Row>

                  {/* Suscription */}

                  <Row>
                    <Col sm={12}>
                      {console.log(
                        "packageFamily en TIENDA render",
                        packageFamily
                      )}
                      {insortActivosConVitalicia > 0 ? (
                        <Accordion>
                          {packageFamily.map((item, i) => {
                            return item.packages.length > 0 ? (
                              <Accordion.Item eventKey={item.id} key={item.id}>
                                <Accordion.Header>{item.name}</Accordion.Header>
                                <Accordion.Body>
                                  <Table
                                    striped
                                    bordered
                                    hover
                                    responsive="md"
                                    size="sm"
                                  >
                                    <thead className="table-head">
                                      <tr>
                                        {/* <th></th>
                                                              {
                                                                  item.headers.map((header, id) => (
                                                                  <th>{header}</th>
                                                                  ))
                                                              } */}
                                        <th></th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Precio</th>
                                        <th>Número de cuotas</th>
                                        <th>Cuota inicial</th>
                                        {/* <th>Duración</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/*  AQUI ESTAN LAS SUBCRIPCIONES */}
                                      {userName == "master" ? (
                                        <>
                                          {!codeStatus &&
                                            item.packages.map(
                                              (itempck, idx) => {
                                                return (
                                                  itempck.estatus === 1 && (
                                                    <tr key={itempck.id}>
                                                      <td>
                                                        <Form.Check
                                                          type="radio"
                                                          name="packages"
                                                        >
                                                          <FormCheck.Input
                                                            type="radio"
                                                            id={itempck.id}
                                                            name="packages"
                                                            onChange={(e) =>
                                                              this.handleSelection(
                                                                e,
                                                                "packages"
                                                              )
                                                            }
                                                          ></FormCheck.Input>
                                                        </Form.Check>
                                                      </td>
                                                      <td>{itempck.name}</td>
                                                      <td>
                                                        {itempck.description}
                                                        <br></br>
                                                        {"Duración: " +
                                                          itempck
                                                            .listPackageDetails[0]
                                                            .monthsDuration +
                                                          " meses"}
                                                        {/* <ul>
                                                                                {
                                                                                    itempck.descriptions.map((label, idxLabel) => (
                                                                                    <li>{label}</li>
                                                                                    ))
                                                                                }
                                                                            </ul> */}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          itempck
                                                            .listPackageDetails[0]
                                                            .price
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          itempck
                                                            .listPackageDetails[0]
                                                            .numberQuotas
                                                        }
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          itempck
                                                            .listPackageDetails[0]
                                                            .initialPrice
                                                        }
                                                      </td>
                                                      {/* <td>{itempck.duration}</td> */}
                                                    </tr>
                                                  )
                                                );
                                              }
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          {!codeStatus &&
                                            item.packages.map(
                                              (itempck, idx) => {
                                                return itempck.estatus === 1 &&
                                                  itempck.name !== "Mini" ? (
                                                  <tr key={itempck.id}>
                                                    <td>
                                                      <Form.Check
                                                        type="radio"
                                                        name="packages"
                                                      >
                                                        <FormCheck.Input
                                                          type="radio"
                                                          id={itempck.id}
                                                          name="packages"
                                                          onChange={(e) =>
                                                            this.handleSelection(
                                                              e,
                                                              "packages"
                                                            )
                                                          }
                                                        ></FormCheck.Input>
                                                      </Form.Check>
                                                    </td>
                                                    <td>{itempck.name}</td>
                                                    <td>
                                                      {itempck.description}
                                                      <br></br>
                                                      {"Duración: " +
                                                        itempck
                                                          .listPackageDetails[0]
                                                          .monthsDuration +
                                                        " meses"}
                                                      {/* <ul>
                                                                                {
                                                                                    itempck.descriptions.map((label, idxLabel) => (
                                                                                    <li>{label}</li>
                                                                                    ))
                                                                                }
                                                                            </ul> */}
                                                    </td>
                                                    <td>
                                                      ${" "}
                                                      {
                                                        itempck
                                                          .listPackageDetails[0]
                                                          .price
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        itempck
                                                          .listPackageDetails[0]
                                                          .numberQuotas
                                                      }
                                                    </td>
                                                    <td>
                                                      ${" "}
                                                      {
                                                        itempck
                                                          .listPackageDetails[0]
                                                          .initialPrice
                                                      }
                                                    </td>
                                                    {/* <td>{itempck.duration}</td> */}
                                                  </tr>
                                                ) : null;
                                              }
                                            )}
                                        </>
                                      )}

                                      {codeStatus &&
                                        packagePromotionalcode.map(
                                          (itempckPc, idx) => {
                                            if (
                                              itempckPc.familyPackageId ===
                                              item.id
                                            ) {
                                              return (
                                                <tr key={idx}>
                                                  <td>
                                                    <Form.Check type="radio">
                                                      <FormCheck.Input
                                                        type="radio"
                                                        name="packagespromotionalcode"
                                                        onChange={() =>
                                                          this.handleSelection(
                                                            itempckPc,
                                                            "packages"
                                                          )
                                                        }
                                                      ></FormCheck.Input>
                                                    </Form.Check>
                                                  </td>
                                                  <td>
                                                    {itempckPc.package.name}
                                                  </td>
                                                  <td>
                                                    {
                                                      itempckPc.package
                                                        .description
                                                    }
                                                    <br></br>
                                                    Duración:{" "}
                                                    {itempckPc.monthsDuration >
                                                    1 ? (
                                                      <div>
                                                        {
                                                          itempckPc.monthsDuration
                                                        }{" "}
                                                        meses
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        {
                                                          itempckPc.monthsDuration
                                                        }{" "}
                                                        mes
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>$ {itempckPc.price}</td>
                                                  <td>
                                                    {itempckPc.numberQuotas}
                                                  </td>
                                                  <td>
                                                    {itempckPc.initialPrice}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          }
                                        )}
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            ) : null;
                          })}
                        </Accordion>
                      ) : (
                        <Accordion>
                          {packageFamily.map((item, i) => {
                            return item.packages.length > 0 &&
                              item.name !== "RIBERA DEL RÍO " ? (
                              <Accordion.Item eventKey={item.id} key={item.id}>
                                <Accordion.Header>{item.name}</Accordion.Header>
                                <Accordion.Body>
                                  <Table
                                    striped
                                    bordered
                                    hover
                                    responsive="md"
                                    size="sm"
                                  >
                                    <thead className="table-head">
                                      <tr>
                                        {/* <th></th>
                                                              {
                                                                  item.headers.map((header, id) => (
                                                                  <th>{header}</th>
                                                                  ))
                                                              } */}
                                        <th></th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Precio</th>
                                        <th>Número de cuotas</th>
                                        <th>Cuota inicial</th>
                                        {/* <th>Duración</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/*  AQUI ESTAN LAS SUBCRIPCIONES */}
                                      {userName == "master" ? (
                                        <>
                                          {!codeStatus &&
                                            item.packages.map(
                                              (itempck, idx) => {
                                                return (
                                                  itempck.estatus === 1 && (
                                                    <tr key={itempck.id}>
                                                      <td>
                                                        <Form.Check
                                                          type="radio"
                                                          name="packages"
                                                        >
                                                          <FormCheck.Input
                                                            type="radio"
                                                            id={itempck.id}
                                                            name="packages"
                                                            onChange={(e) =>
                                                              this.handleSelection(
                                                                e,
                                                                "packages"
                                                              )
                                                            }
                                                          ></FormCheck.Input>
                                                        </Form.Check>
                                                      </td>
                                                      <td>{itempck.name}</td>
                                                      <td>
                                                        {itempck.description}
                                                        <br></br>
                                                        {"Duración: " +
                                                          itempck
                                                            .listPackageDetails[0]
                                                            .monthsDuration +
                                                          " meses"}
                                                        {/* <ul>
                                                                                {
                                                                                    itempck.descriptions.map((label, idxLabel) => (
                                                                                    <li>{label}</li>
                                                                                    ))
                                                                                }
                                                                            </ul> */}
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          itempck
                                                            .listPackageDetails[0]
                                                            .price
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          itempck
                                                            .listPackageDetails[0]
                                                            .numberQuotas
                                                        }
                                                      </td>
                                                      <td>
                                                        ${" "}
                                                        {
                                                          itempck
                                                            .listPackageDetails[0]
                                                            .initialPrice
                                                        }
                                                      </td>
                                                      {/* <td>{itempck.duration}</td> */}
                                                    </tr>
                                                  )
                                                );
                                              }
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          {!codeStatus &&
                                            item.packages.map(
                                              (itempck, idx) => {
                                                return itempck.estatus === 1 &&
                                                  itempck.name !== "Mini" ? (
                                                  <tr key={itempck.id}>
                                                    <td>
                                                      <Form.Check
                                                        type="radio"
                                                        name="packages"
                                                      >
                                                        <FormCheck.Input
                                                          type="radio"
                                                          id={itempck.id}
                                                          name="packages"
                                                          onChange={(e) =>
                                                            this.handleSelection(
                                                              e,
                                                              "packages"
                                                            )
                                                          }
                                                        ></FormCheck.Input>
                                                      </Form.Check>
                                                    </td>
                                                    <td>{itempck.name}</td>
                                                    <td>
                                                      {itempck.description}
                                                      <br></br>
                                                      {"Duración: " +
                                                        itempck
                                                          .listPackageDetails[0]
                                                          .monthsDuration +
                                                        " meses"}
                                                      {/* <ul>
                                                                                {
                                                                                    itempck.descriptions.map((label, idxLabel) => (
                                                                                    <li>{label}</li>
                                                                                    ))
                                                                                }
                                                                            </ul> */}
                                                    </td>
                                                    <td>
                                                      ${" "}
                                                      {
                                                        itempck
                                                          .listPackageDetails[0]
                                                          .price
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        itempck
                                                          .listPackageDetails[0]
                                                          .numberQuotas
                                                      }
                                                    </td>
                                                    <td>
                                                      ${" "}
                                                      {
                                                        itempck
                                                          .listPackageDetails[0]
                                                          .initialPrice
                                                      }
                                                    </td>
                                                    {/* <td>{itempck.duration}</td> */}
                                                  </tr>
                                                ) : null;
                                              }
                                            )}
                                        </>
                                      )}

                                      {codeStatus &&
                                        packagePromotionalcode.map(
                                          (itempckPc, idx) => {
                                            if (
                                              itempckPc.familyPackageId ===
                                              item.id
                                            ) {
                                              return (
                                                <tr key={idx}>
                                                  <td>
                                                    <Form.Check type="radio">
                                                      <FormCheck.Input
                                                        type="radio"
                                                        name="packagespromotionalcode"
                                                        onChange={() =>
                                                          this.handleSelection(
                                                            itempckPc,
                                                            "packages"
                                                          )
                                                        }
                                                      ></FormCheck.Input>
                                                    </Form.Check>
                                                  </td>
                                                  <td>
                                                    {itempckPc.package.name}
                                                  </td>
                                                  <td>
                                                    {
                                                      itempckPc.package
                                                        .description
                                                    }
                                                    <br></br>
                                                    Duración:{" "}
                                                    {itempckPc.monthsDuration >
                                                    1 ? (
                                                      <div>
                                                        {
                                                          itempckPc.monthsDuration
                                                        }{" "}
                                                        meses
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        {
                                                          itempckPc.monthsDuration
                                                        }{" "}
                                                        mes
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>$ {itempckPc.price}</td>
                                                  <td>
                                                    {itempckPc.numberQuotas}
                                                  </td>
                                                  <td>
                                                    {itempckPc.initialPrice}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          }
                                        )}
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            ) : null;
                          })}
                        </Accordion>
                      )}
                    </Col>
                  </Row>
                </LoadApi>
              </section>
            )}
            {this.state.packageStatus === "U" && (
              <section>
                <Row>
                  <Col sm={12}>
                    <Form.Label className="content-subtitle">
                      Migración de suscripción{" "}
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    {packageUpgrade.length > 0 && (
                      <Form inline>
                        <Form.Group as={Row}>
                          <Form.Label column sm={5} lg={2}>
                            Seleccione suscripción:{" "}
                          </Form.Label>
                          <Col sm={7} lg={3}>
                            <Form.Select
                              defaultValue={"DEFAULT"}
                              onChange={(e) =>
                                this.handleSelectPackage(e, "upgradeExperience")
                              }
                            >
                              <option value="DEFAULT" disabled>
                                Seleccione
                              </option>
                              {packageUpgrade.map((item, idx) => (
                                <option key={item.id} value={item.id}>
                                  {item.package.name} adquirida en{" "}
                                  {Validation.convertDateToString(
                                    item.creationDate
                                  )}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </Col>
                  <Col sm={3}>
                    {packageUpgrade.length === 0 && (
                      <Form.Label>
                        No tiene paquetes disponibles para realizar un upgrade.
                      </Form.Label>
                    )}
                  </Col>
                </Row>
              </section>
            )}
            {this.state.packageSelected !== undefined && (
              <div>
                <br></br>
                <Row>
                  <Col sm={12}>
                    <Form.Label>Paquetes disponibles para migrar:</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Table striped hover responsive size="sm">
                      <thead className="table-head">
                        <tr>
                          <th></th>
                          <th>Nombre</th>
                          <th colSpan={3}>Descripción</th>
                          <th>Precio</th>
                        </tr>
                      </thead>
                      <tbody>
                        {upgradeOptions.map((itempck, idx) => (
                          <tr key={idx}>
                            <td>
                              <Form.Check type="radio">
                                <FormCheck.Input
                                  type="radio"
                                  name="packagesupgrade"
                                  id={itempck.id}
                                  onChange={() =>
                                    this.handleSelectionmig(itempck)
                                  }
                                  checked={
                                    this.state.packageSelected &&
                                    this.state.packageSelected.length > 0 &&
                                    itempck.packageId ==
                                      this.state.packageSelected[0].packageId
                                  }
                                ></FormCheck.Input>
                              </Form.Check>
                            </td>
                            <td>{itempck.package.name}</td>
                            <td colSpan={3}>
                              {itempck.package.description}
                              <br></br>
                              {"Duración: " + itempck.monthsDuration + " meses"}
                              {/* <ul>
                                                                                {
                                                                                    itempck.descriptions.map((label, idxLabel) => (
                                                                                    <li>{label}</li>
                                                                                    ))
                                                                                }
                                                                            </ul> */}
                            </td>
                            <td>$ {itempck.price}</td>
                            {/* <td>{itempck.quotes}</td>
                                                                        <td>{itempck.initialPrice}</td> */}
                            {/* <td>{itempck.duration}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        )}

        {noData && (
          <div>
            <Form.Label>{message}</Form.Label>
          </div>
        )}
      </div>
    );
  }
}
