const API_URL = process.env.REACT_APP_APP_URL_API;

class FlyerService {
  async getFlyerPicture(idUser) {
    let url = API_URL + "/flyer/picture/unedited/" + idUser;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getInformationFlyer(idUser) {
    let url = API_URL + "/flyer/user/" + idUser;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async saveFlyer(data) {
    let url = API_URL + "/flyer/upload/unedited";

    return await fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getImageFlyer(idUser) {
    let url = API_URL + "/flyer/picture/edited/" + idUser;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
}

export default new FlyerService();
