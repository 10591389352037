import React, { Component } from 'react';
import { Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class AboutView extends Component {


   //insertando razon
    render() {
        return (
            <div className="modal-custom">
                <Form>
                    <Form.Group>
                        <Form.Label >Razon Social: </Form.Label>
                        <Form.Control  plaintext readOnly defaultValue="VALLE ENCANTADO SAC" style={{textAlign:"center"}}/>
                    </Form.Group>

                    <Form.Group >
                        <Form.Label>Ruc:</Form.Label>
                        <Form.Control plaintext readOnly defaultValue="20601460271" style={{textAlign:"center"}}/>
                    </Form.Group>

                    <Form.Group >
                        <Form.Label>Cuenta Corriente Soles BCP: </Form.Label>
                        <Form.Control plaintext readOnly defaultValue="191-2606708-0-82" style={{textAlign:"center"}}/>
                    </Form.Group>

                    <Form.Group >
                        <Form.Label>Cuenta Corriente Soles BCP  CCI: </Form.Label>
                        <Form.Control plaintext readOnly defaultValue="00219100260670808255" style={{textAlign:"center"}}/>
                    </Form.Group>
                    
                    <Form.Group >
                        <Form.Label>Cuenta Corriente Dólares BCP: </Form.Label>
                        <Form.Control plaintext readOnly defaultValue="191-2616687-1-90" style={{textAlign:"center"}}/>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Cuenta Corriente Dólares BCP CCI: </Form.Label>
                        <Form.Control plaintext readOnly defaultValue="00219100261668719050" style={{textAlign:"center"}}/>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Cuenta Corriente Soles INTERBANK:</Form.Label>
                        <Form.Control plaintext readOnly defaultValue="200-3002538987" style={{textAlign:"center"}}/>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Cuenta Corriente Soles INTERBANK CCI:</Form.Label>
                        <Form.Control plaintext readOnly defaultValue="003-200-003002538987-32" style={{textAlign:"center"}}/>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Cuenta Corriente Dólares INTERBANK:</Form.Label>
                        <Form.Control plaintext readOnly defaultValue="200-3002538994" style={{textAlign:"center"}}/>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Cuenta Corriente Dólares INTERBANK CCI:</Form.Label>
                        <Form.Control plaintext readOnly defaultValue="003-200-003002538994-38" style={{textAlign:"center"}}/>
                    </Form.Group>
                    </Form>                        
            </div>

        );
    }
}