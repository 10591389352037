import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Image,
  Table,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";

import PayPalButton from "../payment/PaypalButton";
import PayVoucher from "../payment/PayVoucher";
// import { PayPalButton } from 'react-paypal-button-v2'
import logo from "../../images/paypal-logo.png";
import logoBcp from "../../images/bcp-logo.jpg";
import logoInter from "../../images/interbank-logo.png";
import walletImge from "../../images/wallet.png";
import logoOther from "../../images/other-logo.png";
import BanColombia from "../../images/colombia/bancolombia.jpg";
import DaVivienda from "../../images/colombia/davivienda.jpg";
import UtilService from "../../services/utils.service";
import PayVoucherInter from "../payment/PayVoucherInter";
import PayWallet from "./PayWallet";
import PayOther from "./PayOther";
import ModalContent from "components/ModalContent";
import PayVoucherDaVivienda from "./PayVoucherDaVivienda";
import ModalPeriodoDeGracia from "./ModalPeriodoDeGracia";

const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;

export default class PayQuote extends Component {
  constructor(props) {
    super(props);
    /* console.log("haber props", props); */
    this.state = {
      quote: props.quote,
      dataPG:{},
      idPackage: props.quote.idPaquete,
      total: props.quote.totalPay,
      initial: "0",
      totalCuotas: "1",
      graceDaysUsed: 0,
      newExpirationDate: "",
      overdue: 0,
      discount: 0,
      packages: [],
      name: "",
      description: "",
      price: "",
      quotes: 1,
      perQuotes: 0,
      countQuotes: 0,
      initialPrice: "",
      duration: "",
      message: "",
      codeMessage: "",
      isOkQuote: true,
      showPayPal: false,
      showVoucher: false,
      showWallet: false,
      isPaid: false,
      typePaidMethod: 0,
      method: "",
      bank: "",
      vouchers: [],
      typePay: 0,
      typeBank: {},
      typeExchange: {
        venta: 1,
      },
      isOpen: false,
      isOpen2: false,
      amountWallet: 0,
      dataDias: {},
      // cupon
      TypeTotal: 0,
      cupon: "",
      showCupon: false,
      graceDays: 0,
      totalPagar: null,
      nuevaMora: -1,
      cambioFecha: null,
      diasUsados:null,
      aplicaPG:0,
      respuestaPG:{}
      // sin cupon
    };

    this.getTypeBank = this.getTypeBank.bind(this);
  }

  componentDidMount() {
    this.getTypeBank();
    this.getExchange();
    this.obtenerDias();
  }

  handleCloseModal = () => {
    this.setState({ isOpen: false });
  };
  handleShowModal = () => {
    this.setState({ isOpen: true });
  };
  handleCloseModal2 = () => {
    this.setState({ isOpen2: false });
  };
  handleShowModal2 = () => {
    this.setState({ isOpen2: true });
  };

  // Get exchange dolar
  async getExchange() {
    let response = await UtilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: (this.state.typeExchange = response.objModel),
        });
      }
    }
  }

  async getTypeBank() {
    let response = await UtilService.getTypeBank();
    // console.log(response)
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeBank: (this.state.typeBank = response.objModel),
        });
      }
    }
  }

  async obtenerDias() {
    let quote = this.state.quote;
    //console.log("haber quote", quote);
    // console.log("haber quote id subscription", quote.idSuscription);
    let response = await UtilService.getDays(quote.idSuscription);
    // console.log("RESPUESTA EN obtenerDias ==>", response);
    //console.log(quote);
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          dataDias: (this.state.dataDias = response.objModel),
        });
      }
    }
  }

  /**
   * Connect with paypal
   * @param {*} e
   */
  onEventPayPal = (e) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago con PayPal ha registrado exitosamente.");
      } else {
        if (this.state.vouchers.length > 0) {
          alert("Usted ha subido comprobantes de pago.");
        } else {
          this.handleShow();
        }
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };
  /**
   * Event pay
   */
  eventPay = (value, field) => {
    this.setState({ [field]: value });
    if (this.props.eventPay) {
      this.props.eventPay(value, field);
    }
  };

  // Handle modal
  handleClose = () => {
    this.setState({
      showPayPal: false,
    });
  };
  handleShow = () => {
    this.setState({
      showPayPal: true,
    });
  };

  /**Handle upload voucher */
  // Handle modal voucher
  // Add list of vouchers
  addVoucher = (voucherList) => {
    let vouchers = this.state.vouchers;
    vouchers = vouchers.concat(voucherList);
    this.setState({
      vouchers: vouchers,
    });
    this.props.eventPay(vouchers, "vouchers");
    // console.log(vouchers)
  };
  deleteVoucher = (e, idx) => {
    let temp = [];
    let vouchers = this.state.vouchers;
    let i = 0;
    for (i = 0; i < vouchers.length; i++) {
      if (i !== idx) {
        temp.push(vouchers[i]);
      }
    }
    this.setState({
      vouchers: temp,
    });
    this.props.eventPay(temp, "vouchers");
  };
  handleShowVoucher = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        if (bank === "BCP") {
          this.setState({
            showVoucher: true,
            bank: "BCP",
            isBcp: true,
            isInter: false,
            isDavi: false,
            isBancol: false,
          });
        } else if (bank === "Interbank") {
          this.setState({
            showVoucher: true,
            bank: "Interbank",
            isBcp: false,
            isInter: true,
            isDavi: false,
            isBancol: false,
          });
        } else if (bank === "Davivienda") {
          this.setState({
            showVoucher: true,
            bank: "Davivienda",
            isBcp: false,
            isInter: false,
            isDavi: true,
            isBancol: false,
          });
        } else if (bank === "Bancolombia") {
          this.setState({
            showVoucher: true,
            bank: "Bancolombia",
            isBcp: false,
            isInter: false,
            isDavi: false,
            isBancol: true,
          });
        }
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };

  handleCloseVoucher = () => {
    this.setState({
      showVoucher: false,
    });
  };

  // Handle show modal wallet
  handleShowWallet = (e) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showWallet: true,
        });
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };

  handleCloseWallet = () => {
    this.setState({
      showWallet: false,
    });
  };

  // Event Wallet
  addWallet = (amount) => {
    // console.log("event wallet")
    this.setState({
      amountWallet: amount,
    });
    // if(this.props.eventWallet) {
    //     this.props.eventWallet(amount)
    // }
  };

  // Register Payment only with wallet
  registerPayWallet = (amount,diasUsados,cambioFecha,aplicaPG,respuestaPG) => {
    this.props.registerPayWallet(amount,diasUsados,cambioFecha,aplicaPG,respuestaPG);
  };

  eventWallet = (amount) => {
    this.props.eventWallet(amount);
  };

  // Pay Other
  // Payment other
  handleShowOther = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showOther: true,
          bank: "Otros",
          isOther: true,
          isBcp: false,
          isBancol: false,
          isInter: false,
          isDavi: false,
        });
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };

  handleCloseOther = () => {
    this.setState({
      showOther: false,
    });
  };
  sumartotalvoucher = () => {
    let vouchers = this.state.vouchers;
    let totalamount = Number(0);
    let cambio = Number(this.state.typeExchange.venta);
    let cantidad = 0;
    let moneda = "";

    for (let i = 0; i < vouchers.length; i++) {
      // Split base64 code type and string
      moneda = vouchers[i].typeExchangeDes;
      cantidad = Number(vouchers[i].amount);
      if (moneda == "Dólares") {
        //dolar
        totalamount += cantidad;
      } else {
        //moneda=2 sol
        //convertir sol a dolar
        cantidad /= cambio;
        totalamount += cantidad;
      }
    }
    // console.log(totalamount)
    return totalamount.toFixed(2);
  };
  ChangeGraciaDay = async (type, cupon = "", days = 1) => {
    console.log("dio click en Aplicar dias periodo de gracia en PAGAR CUOTA");
    const form = {
      IdPayment: this.state.quote.IdPayment,
      SuscriptionId: this.state.quote.idSuscription,
      NumberQuotesPaid: 0,
      TypeTotal: type,
      GracePeriodDays: days,
      OverdueCode: cupon,
    };
    const response = await UtilService.PostCalculeGraciaDays(form);
    console.log('cver response', response);
    if (response.status === 1) {
      let newDate = new Date(response.objModel.newExpirationDate);
      this.setState({
        graceDaysUsed: response.objModel.graceDaysUsed,
        newExpirationDate: newDate.toLocaleDateString(),
        overdue: response.objModel.overdue,
        total: response.objModel.totalPay,
        TypeTotal: type,
      });
      if (type == 3) {
        // con periodo de gracia
        await this.props.ApplyGracePeriodAndCupon(
          1,
          response.objModel.graceDaysUsed,
          response.objModel.totalPay,
          0,
          "",
          response.objModel.newExpirationDate
        );
      } else if (type == 2) {
        // cupon
        await this.props.ApplyGracePeriodAndCupon(
          0,
          response.objModel.graceDaysUsed,
          response.objModel.totalPay,
          1,
          this.state.cupon
        );
      }
    } else {
      alert(response.description);
    }
  };

  displayCupon = () => {
    if (this.state.showCupon == false) {
      this.setState({
        showCupon: true,
      });
    } else if (this.state.showCupon == true) {
      this.setState({
        showCupon: false,
      });
    }
  };

  funcionTotalPagar = (totalpago) => {
   console.log("haber el totalpago", totalpago);
    this.setState({
      totalPagar: totalpago,
    });
  };
  funcionNuevaMora = (moranueva) => {
  //  console.log("haber el nuevaMora", moranueva);
    this.setState({
      nuevaMora: moranueva,
    });
  };

  funcionCambioDeFecha = (cambiodefecha) => {
   // console.log("haber el cambiodefecha", cambiodefecha);
    this.setState({
      cambioFecha: cambiodefecha,
    });
  };
  funcionDiasUsados = (dias) => {
  //  console.log("haber  dias", dias);
    this.setState({
      diasUsados: dias,
    });
  };
  funcionAplicaDiasDePG = (valor) => {
   // console.log("haber  funcionAplicaDiasDePG", valor);
    this.setState({
      aplicaPG: valor,
    });
  };
  funcionRespuestaPG = (data) => {
   // console.log("haber  funcionRespuestaPG", data);
    this.setState({
      respuestaPG: data, //objeto completo de la peticion
    });
  };
  funcionDataPG= (data) => {
  //  console.log("haber  funcionDataPG", data);
    this.props.funcionJalarDatosDePG(data)
    
  };


  render() {
    const {
      quote,
      vouchers,
      isPaid,
      typeExchange,
      amountWallet,
      dataDias,
      totalPagar,
      nuevaMora,
      cambioFecha,
      diasUsados,
      aplicaPG,
      respuestaPG,
    } = this.state;
    //console.log("haber quote en RENDER =>", quote);
    //  console.log("haber dataDias en RENDER =>", dataDias);
    const nacionalidadUsuario = sessionStorage.getItem("info")
      ? JSON.parse(sessionStorage.getItem("info")).idResidenceCountry
      : "";
    //console.log("ver nacionalidadUsuario PayQuote =>", nacionalidadUsuario);
    return (
      <div>
        {dataDias ? (
          <Row>
            <Col sm={12}>
              <p className="text-uppercase text-dark fw-bolder">
                Le quedan estos dias de periodo de gracia :{" "}
                <span className="text-danger fw-bolder">
                  {dataDias.daysFree}
                </span>
              </p>
              <p className="text-uppercase text-dark fw-bolder">
                Cantidad de dias de periodo de gracia usados :{" "}
                <span className="text-danger fw-bolder">
                  {dataDias.daysUsed}
                </span>
              </p>
            </Col>
          </Row>
        ) : null}
        <Row style={{ textAlign: "center" }}>
          <Col sm={12}>
            <Form.Label className="content-subtitle">Pagar cuota</Form.Label>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p>
              <b>Suscripción:</b> {quote.namePackage}
            </p>
          </Col>
          <Col sm={6}>
            {/*   COMENTE ESTO DESCOMENTAR LUEGO */}
            {quote.totalOverdue > 0 ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginBottom: "10px",
                  flexDirection: "column",
                }}
              >
                {/*  ESTO ESTABA COMENTADO 
                 <Form.Check
                                    inline
                                    type="checkbox"
                                    label="Usar Cupon"
                                    id={`inline-radio`}
                                    style={{ marginTop: 2 }}
                                    onChange={this.displayCupon}
                                    checked={this.state.showCupon}
                                /> 
 ESTO ESTABA COMENTADO */}

                {this.state.showCupon ? (
                  <div className="tw-flex">
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el codigo del cupon"
                      value={this.state.cupon}
                      onChange={(e) => this.setState({ cupon: e.target.value })}
                    />
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => this.ChangeGraciaDay(2, this.state.cupon)}
                    >
                      Aplicar
                    </Button>
                  </div>
                ) : dataDias.daysFree <= 0 ? (
                  <>
                    <span className="text-danger">
                      PARA PODER REGISTRAR MAS DÍAS EN PERIODO DE GRACIA DEBE DE
                      SOLICITAR SU CUPON DE PERIODO DE GRACIA.
                    </span>
                  </>
                ) : (
                  <>
                    {/* <p className="tw-mb-0">
                      Ingrese el numero de dias de periodo de gracia que desea
                      usar
                    </p>
                    <div className="tw-flex tw-column">
                      <Form.Control
                        type="number"
                        placeholder="Ingrese el numero de dias"
                        value={this.state.graceDays}
                        onChange={(e) =>
                          this.setState({ graceDays: Number(e.target.value) })
                        }
                      />
                      <Button
                        variant="primary text-uppercase"
                        size="sm"
                        onClick={() =>
                          this.ChangeGraciaDay(3, "", this.state.graceDays)
                        }
                       
                      >
                        aplicar periodo de gracia
                      </Button>
                    </div> */}
                    <ModalPeriodoDeGracia
                      idsuscription={quote.idSuscription}
                      idpayment={quote.IdPayment}
                      amountoverdue={quote.totalOverdue}
                      daysavailable={dataDias.daysFree}
                      diasDisponibles={dataDias.daysFree}
                      daysOverdue={quote.daysOverdue}
                      totalPagar={totalPagar}
                      funcionTotalPagar={this.funcionTotalPagar}
                      funcionNuevaMora={this.funcionNuevaMora}
                      funcionCambioDeFecha={this.funcionCambioDeFecha}
                      funcionDiasUsados={this.funcionDiasUsados}
                      funcionAplicaDiasDePG={this.funcionAplicaDiasDePG}
                      funcionRespuestaPG={this.funcionRespuestaPG}
                      funcionDataPG={this.funcionDataPG}
                      changeStateNew={this.props.changeStateNew}
                    />
                  </>
                )}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
        <Col sm={6}>
            <p>
              <b>Total de dias de mora :</b>{quote.daysOverdue}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <p>
              <b>Descripción:</b> {quote.quoteDescription}{" "}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <b> Cuota:</b> {quote.quoteUsd} USD
            </p>
          </Col>
        </Row>
        {/*  <Row>
          <Col>
            <p>
              <b> Mora:</b> {quote.totalOverdue} USD
            </p>
          </Col>
        </Row> */}
        {this.state.graceDaysUsed > 0 ? (
          <Row>
            <Col>
              <p>
                <b>
                  <strike>Mora:</strike>
                </b>{" "}
                <strike>{quote.totalOverdue} USD</strike>
              </p>
            </Col>
          </Row>
        ) : (

          <Row>
            {nuevaMora>=0 ? (
              <Col>
                <p>
                  <b> Nueva mora:</b> {(nuevaMora)} USD
                </p>
              </Col>
            ) : (
              <Col>
                <p>
                  <b> Mora:</b> {quote.totalOverdue} USD
                </p>
              </Col>
            )}
          </Row>
        )}
        <Row>
          <Col>
          <p><b>Total :</b>  { totalPagar ? totalPagar : quote.quoteUsd + quote.totalOverdue} USD</p>
          </Col>
        </Row>
        <Row>
          {respuestaPG.daysUsed && (
            <Col>
              <p>
                <b className="text-uppercase"> Dias que estas usando de periodo de gracia: </b>
                {respuestaPG.daysUsed} Dias
              </p>
            </Col>
          )}
        </Row>
        <Row>
          {totalPagar && (
            <Col>
              <p>
                <b className="text-uppercase"> Total a pagar con periodo de gracia:</b>
                {totalPagar} USD
              </p>
            </Col>
          )}
        </Row>
       
        {/* modificar */}
        {/*   {this.state.graceDaysUsed > 0 ? (
          <Row>
            <Col>
              <p>
                <b> Tiempo de gracia:</b> {this.state.graceDaysUsed} Dias
              </p>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <p>
              <b> Total a pagar:</b> {this.state.total} USD
            </p>
          </Col>
        </Row> */}

        {this.state.graceDaysUsed > 0 ? (
          <>
            <Row>
              <Col>
                <p>
                  <b> Nueva Mora :</b> {(this.state.overdue).toFixed(2)} USD
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <b> Nuevo fecha de pago:</b> {this.state.newExpirationDate}
                </p>
              </Col>
            </Row>
          </>
        ) : null}
        <Row>
          {/* <Col sm={6}>

            <ModalPeriodoDeGracia idsuscription ={quote.idSuscription}
            idpayment={quote.IdPayment}
            amountoverdue={quote.totalOverdue
            }
            daysavailable={dataDias.daysFree}
            />
          </Col> */}
          {/*   <Col sm={6}>
            <button
              className="btn btn-warning btn-sm"
              onClick={this.handleShowModal2}
            >
              Cupon
            </button>
          </Col> */}

          <ModalContent
            title="Solicitar Exoneracion Extraordinaria"
            isOpen={this.state.isOpen2}
            handleClose={this.handleCloseModal2}
            centered
          >
            <div>
              <Form inline>
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-start">Dias :</Form.Label>
                    <Form.Control
                      id="txtDays"
                      type="number"
                      min="1"
                      // max={this.state.numQuotes}
                      //value={this.state.days}
                      className="w-100"
                      // onChange={(e) => this.handleChangeDays(e)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-start w-100">Motivo</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                </div>

                <Button
                  size="sm"
                  variant="warning"
                  //onClick={(e) => this.useGracePeriod(e)}
                  style={{ marginTop: "10px" }}
                >
                  <b>Enviar Solicitud</b>
                </Button>
              </Form>
            </div>
          </ModalContent>
        </Row>

        <hr></hr>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Selecione un medio de pago : </Form.Label>
            </Col>
          </Row>
          {/*  AQUI SON LAS IMAGENES DE MEDIO DE PAGO */}
          <Row style={{ padding: 16 }}>
            {(() => {
              switch (nacionalidadUsuario) {
                case 45:
                  return (
                    <>
                      <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>*
                      </Col>
                      {/*      BANCO BANCOLOMBIA */}
                      {/*  <Col sm={2}>
                      <Image
                        src={BanColombia}
                        rounded
                        style={{ width: "100%", maxWidth: 136, cursor: "pointer" }}
                        onClick={(e) => {
                          this.handleShowVoucher(e, "Bancolombia");
                        }}
                      ></Image>
                    </Col> */}
                      {/*  BANCO DAVIVIENDA */}
                      <Col sm={2}>
                        <Image
                          src={DaVivienda}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowVoucher(e, "Davivienda");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={3}>
                        <div
                          onClick={(e) => {
                            this.handleShowWallet(e);
                          }}
                        >
                          <p style={{ margin: 2, fontWeight: "bold" }}>
                            <Image
                              src={walletImge}
                              style={{ width: "32px", marginRight: "10px" }}
                            ></Image>
                            Mi Wallet
                          </p>
                        </div>
                      </Col>
                    </>
                  );
                case 167:
                  return (
                    <>
                      <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoBcp}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowVoucher(e, "BCP");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoInter}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowVoucher(e, "Interbank");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={3}>
                        <div
                          onClick={(e) => {
                            this.handleShowWallet(e);
                          }}
                        >
                          <p style={{ margin: 2, fontWeight: "bold" }}>
                            <Image
                              src={walletImge}
                              style={{ width: "32px", marginRight: "10px" }}
                            ></Image>
                            Mi Wallet
                          </p>
                        </div>
                      </Col>
                    </>
                  );
                default:
                  return (
                    <>
                      <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>
                      </Col>

                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={3}>
                        <div
                          onClick={(e) => {
                            this.handleShowWallet(e);
                          }}
                        >
                          <p style={{ margin: 2, fontWeight: "bold" }}>
                            <Image
                              src={walletImge}
                              style={{ width: "32px", marginRight: "10px" }}
                            ></Image>
                            Mi Wallet
                          </p>
                        </div>
                      </Col>
                    </>
                  );
              }
            })()}
          </Row>
          {/* show the table if has register */}
          {vouchers.length > 0 && (
            <div className="mb-3">
              <Table responsive>
                <thead className="table-head">
                  <tr>
                    <th>Banco</th>
                    <th>Operación</th>
                    <th>Código</th>
                    <th>Nota</th>
                    <th>Archivo</th>
                    <th>Moneda</th>
                    <th>Monto</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {vouchers.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.bank}</td>
                      <td>{item.typeDescription}</td>
                      <td>{item.code}</td>
                      <td>{item.nota}</td>
                      <td>{item.name}</td>
                      <td>{item.typeExchangeDes}</td>
                      <td>{item.amount}</td>
                      <td
                        style={{ fontSize: 15 }}
                        onClick={(e) => {
                          this.deleteVoucher(e, idx);
                        }}
                      >
                        <RiDeleteBinLine></RiDeleteBinLine>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {/* Show amount payed with wallet */}
          {amountWallet > 0 && (
            <Row
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 10 }}
            >
              <Col sm={12}>
                <Alert variant="success">
                  <b>Monto pagado con Wallet $ : </b>
                  {amountWallet}
                </Alert>
              </Col>
            </Row>
          )}
          {/* show the PayPal payment if it was successful */}

          {isPaid && (
            <Form.Group>
              <Form.Label>Su pago ha sido registrado con PAYPAL.</Form.Label>
            </Form.Group>
          )}
        </Form.Group>
        {/* Modal PAYPAL*/}
        <Modal
          show={this.state.showPayPal}
          onHide={this.handleClose}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>PayPal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayPalButton
                mount={totalPagar ? totalPagar : quote.quoteUsd + quote.totalOverdue}
                description={this.state.name}
                eventPay={this.eventPay}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
              ></PayPalButton>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
            {/* <Button variant="primary" onClick={this.handleClose}>
                        Save Changes
                    </Button> */}
          </Modal.Footer>
        </Modal>
        {/* Modal upload voucher*/}
        <Modal
          show={this.state.showVoucher}
          onHide={this.handleCloseVoucher}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de {this.state.bank}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.isBcp && (
              <Form.Group>
                <PayVoucher
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  fontSize={10}
                  nacionalidadUsuario={nacionalidadUsuario}
                  totalPagar={totalPagar}
                ></PayVoucher>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
            {this.state.isInter && (
              <Form.Group>
                <PayVoucherInter
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  fontSize={10}
                  totalPagar={totalPagar}
                ></PayVoucherInter>
              </Form.Group>
            )}
            {this.state.isDavi && (
              <Form.Group>
                <PayVoucherDaVivienda
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                  totalPagar={totalPagar}
                ></PayVoucherDaVivienda>
              </Form.Group>
            )}
          </Modal.Body>
        </Modal>

        {/* Modal pay with wallet*/}
        <Modal
          show={this.state.showWallet}
          onHide={this.handleCloseWallet}
          style={{ fontSize: 10 }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago con Wallet </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayWallet
                idUser={quote.idUser}
                total={this.state.total}
                addWallet={this.addWallet}
                registerPayWallet={this.registerPayWallet}
                eventWallet={this.eventWallet}
                close={this.handleCloseWallet}
                nacionalidadUsuario={nacionalidadUsuario}
                cambioFecha={cambioFecha}
                diasUsados={diasUsados}
                aplicaPG={aplicaPG}
                respuestaPG={respuestaPG}
                totalPagar={totalPagar}
                fontSize={10}
              ></PayWallet>
            </Form.Group>
          </Modal.Body>
        </Modal>

        {/* Modal upload voucher other tickets*/}
        <Modal
          show={this.state.showOther}
          onHide={this.handleCloseOther}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de otros medios de pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayOther
                total={this.state.total}
                addVoucher={this.addVoucher}
                close={this.handleCloseOther}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
                nacionalidadUsuario={nacionalidadUsuario}
                fontSize={10}
                totalPagar={totalPagar}
              ></PayOther>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
