
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
    Image,
    Svg,
    Rect,
} from "@react-pdf/renderer";

Font.register({
    family: "Calibri",
    fonts: [
        { src: "/pdf/fonts/Calibri Regular.ttf" },
        { src: "/pdf/fonts/Calibri Bold.TTF" },
    ],
});
const styles = StyleSheet.create({
    page: {},
    body: {
        fontSize: 10,
        fontFamily: "Calibri",
        paddingTop: 10,
        paddingHorizontal: 70,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 25,
        paddingHorizontal: 50,
        paddingBottom: 15,
    },
    imgHeader: {
        maxWidth: "180px",
        opacity: 0.5,
    },
    title: {
        textAlign: "center",
        textDecoration: "underline",
        fontWeight: "bold",
        paddingBottom: 20,
    },
    subtitle: {
        textDecoration: "underline",
        fontWeight: "bold",
        paddingBottom: 10,
    },
    text: {
        textAlign: "justify",
        paddingBottom: 10,
        //lineHeight: 1.5,
    },
    titleList: {
        textAlign: "justify",
        paddingLeft: 15,
        paddingBottom: 10,
    },
    list: {
        textAlign: "justify",
        paddingLeft: 20,
        paddingBottom: 2,
    },
    image: {
        width: "100%",
        marginVertical: 15,
        marginHorizontal: 90,
    },
    selected: {
        width: 20,
        height: 15,
        backgroundColor: "black",
        borderRadius: 1,
        border: "1 solid black",
    },
    noSelected: {
        width: 20,
        height: 15,
        backgroundColor: "white",
        borderRadius: 1,
        border: "1 solid black",
    },
});
const CodigoEticaPDF = () => {
    return (
        <Document>
            <Page wrap size="A4">
                {/*1 */}
                <View style={styles.page}>
                    <View style={styles.header}>
                        <Image
                            style={[styles.imgHeader, { height: "80px" }]}
                            src="/pdf/inresorts/1.png"
                        />
                        {/* <Image
                            style={[styles.imgHeader, { height: "50px" }]}
                            src="/pdf/inresorts/2.png"
                        />
                        <Image
                            style={[styles.imgHeader, { height: "50px" }]}
                            src="/pdf/inresorts/3.png"
                        /> */}
                    </View>
                    <View style={styles.body}>
                        <Text style={{
                            textAlign: "center",
                            textDecoration: "underline",
                            paddingBottom: 20,
                            fontSize: 15,
                        }}>
                            CÓDIGO DE ÉTICA Y CONDUCTA
                        </Text>
                        <Text style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            textAlign: "center",
                            fontWeight: "bold",
                            marginBottom: 10,
                            fontSize: 12,
                        }}>
                            RIBERA DEL RIO CLUB RESORT S.A.
                        </Text>
                        
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default CodigoEticaPDF