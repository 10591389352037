import React, { Component } from "react";
import LoadApi from "validators/LoadApi";
import {
  Form,
  Table,
  Spinner,
  Pagination,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Validation from "../utils/Validation";
import UserService from "../../services/user.service";
import AuthService from "services/auth.service";

export default class Sponsor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: [],
      emptyList: true,
      message: "No hay registros para mostrar.",
      loading: false,
      date: "04 de agosto del 2020",
      activePage: 1,
      messageDate: "",
      txtSearch: "",
      typeSponsor: "1",
      idBranch: "-1",
      showBranch: false,
      branchCount: 5,
      numberBranch: "",
      init: "",
      end: "",
      messageDateInit: "",
      messageDateEnd: "",
      totalPages: 1,
      page: 1,
      size: 30,
      totalRegister: 0,
      FlagSearchState: 0,
      IsStateActivePartner: "-1",
      FlagSearchStateResidual: "",
      IsStateActiveResidualSearch: "",
      SearchStatePartner: "",
      isBtnExcel: false
    };

    // this.getRegister = this.getRegister.bind(this);
  }

  componentDidMount() {
    // this.getNumberBranch();
    const userId = AuthService.getCurrentIdUser();

    if( userId == 12853 ){
      this.setState({
        isBtnExcel: true,
      });
    }
    
  }

  async getNumberBranch() {
    let response = await UserService.getNumberBranch();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          branchCount: (this.state.branchCount = response.objModel),
        });
      } else {
        this.setState({
          branchCount: (this.state.branchCount = 3),
        });
      }
    } else {
      this.setState({
        branchCount: (this.state.branchCount = 3),
      });
    }
  }

  // async getRegister() {
  //     let response = await UserService.getSponsors();
  //     if(response !== undefined && response !== null){
  //         if(response.status !== 1) {
  //             //;
  //             this.setState({
  //                 sponsors: this.state.sponsors = [],
  //                 emptyList: this.state.emptyList = true,
  //                 loading: this.state.loading = false,
  //                 message: this.state.message = "Se ha producido un error. Inténtelo más tarde."
  //             });
  //         } else {
  //             if(response.objModel.length > 0){
  //                 this.setState({
  //                     sponsors: this.state.sponsors = response.objModel,
  //                     emptyList: this.state.emptyList = false,
  //                     loading: this.state.loading = false,
  //                     message: this.state.mesagge = ""
  //                 });
  //             } else {
  //                 this.setState({
  //                     sponsors: this.state.sponsors = [],
  //                     emptyList: this.state.emptyList = true,
  //                     loading: this.state.loading = false,
  //                     message: this.state.mesagge = "No hay registros para mostrar."
  //                 });
  //             }

  //         }
  //     }

  // }

  handlePageChange = (e, field) => {
    let value = Number(e.target.value);
    this.setState({
      [field]: (this.state[field] = value),
      loading: true,
      sponsors: [],
      emptyList: true,
    });

    // Call to  services

    let parameter = this.getParameter();
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };

  handleSizeChange = (e, field) => {
    let value = Number(e.target.value);

    this.setState({
      size: (this.state.size = value),
      page: (this.state.page = 1),
      loading: true,
      sponsors: [],
      emptyList: true,
    });

    // Call to  services

    let parameter = this.getParameter();
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };

  handleChange = (e, field) => {
    let value = e.target.value;

    this.setState({
      [field]: (this.state[field] = value),
      page: (this.state.page = 1),
      sponsors: (this.state.sponsors = []),
    });

    if (field === "idBranch") {
      if (value === "6") {
        this.setState({
          showBranch: (this.state.showBranch = true),
        });
      } else {
        this.setState({
          showBranch: (this.state.showBranch = false),
        });
      }
    }
  };

  /**
   * Method to handle the selected dates
   * @param {*} e
   * @param {*} field
   */
  handledate = (e, field, message) => {
    let value = e.target.value;
    this.setState({
      [message]: (this.state[message] = ""),
      [field]: (this.state[field] = value),
      page: (this.state.page = 1),
      sponsors: (this.state.sponsors = []),
    });
  };

  onBlurDate = (e, field, fieldMessage) => {
    // Validate date
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    let date = e.target.value;
    if (date.length > 0) {
      let correct = date.match(regEx);
      if (correct) {
        this.setState({
          [fieldMessage]: (this.state[fieldMessage] = ""),
        });
      } else {
        this.setState({
          [fieldMessage]: (this.state[fieldMessage] =
            "Ingrese una fecha válida."),
          [field]: (this.state[field] = ""),
        });
      }
    }
  };

  handleSelectState = (e) => {
    const value = Number(e.target.value);
    const flag = value >= 0 ? 1 : 0;

    this.setState({
      FlagSearchState: (this.state.FlagSearchState = flag),
      SearchStatePartner: (this.state.SearchStatePartner = value),
    });
  };

  /**
   * Event to search
   * @param {*} e
   */
  search = (e) => {
    this.setState({
      loading: true,
      sponsors: [],
      emptyList: true,
    });
    let parameter = this.getParameter();
    console.log(parameter);
    if (parameter !== undefined) {
      this.getSearchResults(parameter);
    }
  };
  exportExcel = (e) => {
    this.setState({
      loading: true,
      sponsors: [],
      emptyList: true,
    });
    let parameter = this.getParameter();

    if (parameter !== undefined) {
      this.getSearchResultsExcel(parameter);
    }

  }

 
  getParameter = () => {
    let data = {};
    let parameter = {};

    //Add search by name and type
    if (this.state.txtSearch.trim().length > 0) {
      parameter.FlagSearchText = 1;
      parameter.TextSearch = this.state.txtSearch;
      parameter.DataTypeSearchTextPartner = Number(this.state.typeSponsor);
    } else {
      parameter.FlagSearchText = 0;
      parameter.TextSearch = "";
      parameter.DataTypeSearchTextPartner = Number(this.state.typeSponsor);
    }

    // Add flag to search branch
    if (Number(this.state.idBranch) >= 0) {
      if (Number(this.state.idBranch) === 6) {
        if (
          !isNaN(Number(this.state.numberBranch)) &&
          Number(this.state.numberBranch) > 0
        ) {
          parameter.FlagSearchRame = 1;
          parameter.ValueFilterBranch = Number(this.state.numberBranch);
        } else {
          alert("Seleccione o ingrese un de rama.");
          return undefined;
        }
      } else {
        parameter.FlagSearchRame = 1;
        parameter.ValueFilterBranch = Number(this.state.idBranch);
      }
    } else {
      parameter.FlagSearchRame = 0;
      parameter.ValueFilterBranch = Number(this.state.idBranch);
    }

    // Add filter of date
    if (this.state.init.length > 0 && this.state.end.length > 0) {
      data.FlagSearchDate = 1;
      data.StartDate = this.state.init;
      data.EndDate = this.state.end;
    } else {
      data.FlagSearchDate = 0;
      // data.StartDate = "";
      // data.EndDate = "";
    }

    parameter.FlagSearchState = this.state.FlagSearchState;
    parameter.SearchStatePartner = Number(this.state.SearchStatePartner);

    data.ParametersSearched = parameter;
    data.SizeList = this.state.size;
    data.NroPage = this.state.page;

    return data;
  };
  
  getSearchResultsExcel = async(parameter) =>{
    let response = await UserService.getSponsorsExcelSearchV2(parameter.ParametersSearched);

    if (response !== undefined && response !== null) {
      const fileUrl = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement('a');
      fileLink.href = fileUrl;
      fileLink.setAttribute('download', 'excel.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();

      this.setState({
        sponsors: (this.state.sponsors = []),
        emptyList: (this.state.emptyList = true),
        loading: (this.state.loading = false),
      });
    }else {
      this.setState({
        sponsors: (this.state.sponsors = []),
        emptyList: (this.state.emptyList = true),
        loading: (this.state.loading = false),
        message: (this.state.message =
          "Se ha producido un error. Inténtelo más tarde."),
      });
    }
  }


  getSearchResults = async (parameter) => {
    // let response = await UserService.getSponsorsSearch(parameter);
    console.log('parameter', parameter)
    let response = await UserService.getSponsorsSearchV2(parameter);
    if (response !== undefined && response !== null) {
      if (response.status !== 1) {
        //;
        this.setState({
          sponsors: (this.state.sponsors = []),
          emptyList: (this.state.emptyList = true),
          loading: (this.state.loading = false),
          message: (this.state.message =
            "Se ha producido un error. Inténtelo más tarde."),
        });
      } else {
        let object = response.objModel;
        if (object.listElements.length > 0) {
          this.setState({
            sponsors: (this.state.sponsors = object.listElements),
            emptyList: (this.state.emptyList = false),
            loading: (this.state.loading = false),
            message: (this.state.mesagge = ""),
            totalPages: (this.state.totalPages = object.totalPages),
            totalRegister: (this.state.totalRegister = object.totalPartnerList),
          });
        } else {
          this.setStwdate({
            sponsors: (this.state.sponsors = []),
            emptyList: (this.state.emptyList = true),
            loading: (this.state.loading = false),
            message: (this.state.mesagge = "No hay registros para mostrar."),
            totalPages: (this.state.totalPages = 1),
            page: (this.state.page = 1),
            totalRegister: (this.state.totalRegister = 0),
          });
        }
      }
    } else {
      this.setState({
        sponsors: (this.state.sponsors = []),
        emptyList: (this.state.emptyList = true),
        loading: (this.state.loading = false),
        message: (this.state.message =
          "Se ha producido un error. Inténtelo más tarde."),
      });
    }
  };

  render() {
    const {
      sponsors,
      activePage,
      page,
      size,
      totalPages,
      showBranch,
      branchCount,
      numberBranch,
      totalRegister,
    } = this.state;

    let optionPages = [];
    for (let i = 0; i < totalPages; i++) {
      optionPages.push(<option value={i + 1}>{i + 1}</option>);
    }

    let branchesList = [];
    branchesList.push(<option value="-1">Todos</option>);

    for (let i = 0; i < branchCount && i < 3; i++) {
      branchesList.push(<option value={i + 1}>Rama {i + 1}</option>);
    }
    branchesList.push(<option value="0">Sin posicionar</option>);
    // if(branchCount >= 5) {
    //     branchesList.push(<option value="6">Otro</option>)
    // }

    return (
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <hr></hr>
        <Row>
          <Col sm={12}>
            <Form.Label className="content-subtitle">
              Lista de socios
            </Form.Label>
          </Col>
        </Row>
        <Form.Group>
          {/* <p>Información actualizada al: <b>{this.state.date}</b></p> */}
        </Form.Group>
        <div className="search">
          <h1 className="search-title">Buscador</h1>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label> Ingrese campo de búsqueda: </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Buscar por ..."
                  onChange={(e) => this.handleChange(e, "txtSearch")}
                />
                <Form.Label style={{ fontSize: 9 }}>
                  ( Nombre, apellido, usuario o número documento)
                </Form.Label>
              </Form.Group>
            </Col>
            <Col sm={7}>
              <Form.Group>
                <Form.Label> Buscar como:</Form.Label>
                <div style={{ paddingTop: 8 }}>
                  <Form.Check
                    inline
                    id={0}
                    value="1"
                    name="typeSponsor"
                    label="Usuario"
                    type="radio"
                    onChange={(e) => this.handleChange(e, "typeSponsor")}
                    checked={this.state.typeSponsor === "1"}
                  />
                  <Form.Check
                    inline
                    id={1}
                    value="2"
                    name="typeSponsor"
                    label="Patrocinador"
                    type="radio"
                    onChange={(e) => this.handleChange(e, "typeSponsor")}
                    checked={this.state.typeSponsor === "2"}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Label>Estados: </Form.Label>
              <Form.Select
                defaultValue={this.state.IsStateActivePartner}
                onChange={(e) => {
                  this.handleSelectState(e);
                }}
                size="sm"
              >
                <option value="-2" disabled>
                  Seleccione
                </option>
                <option value="-1">Todos</option>
                <option value="1">Activos</option>
                <option value="2">Activos Residuales</option>
                <option value="3">Inactivos</option>
                <option value="4">Inactivos Residuales</option>
              </Form.Select>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label htmlFor="selectBranch">Rama:</Form.Label>
                <Form inline>
                  <Form.Select
                    id="selectBranch"
                    defaultValue={"-1"}
                    onChange={(e) => {
                      this.handleChange(e, "idBranch");
                    }}
                    size="sm"
                  >
                    {branchesList}
                  </Form.Select>
                  <Form.Control
                    style={{
                      display: showBranch ? "inline" : "none",
                      width: 90,
                    }}
                    type="number"
                    min="0"
                    onChange={(e) => {
                      this.handleChange(e, "numberBranch");
                    }}
                    className="my-2 ml-sm-2"
                    placeholder="Rama"
                  />
                </Form>
              </Form.Group>
            </Col>
          </Row>

          <Row style={{ textAlign: "right" }}>
            <Col sm={12}>
              <Button
                variant="secondary"
                size="sm"
                onClick={(e) => {
                  this.search(e);
                }}
              >
                Buscar
              </Button>
              {
                this.state.isBtnExcel && 
                <Button
                  variant="success"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                  onClick={(e) => {
                    this.exportExcel(e);
                  }}
                >
                  Exportar
                </Button>
              }
            </Col>
          </Row>
        </div>
        {/* Paginador */}

        <Row>
          <Form.Label column xs={12} sm={12} md={8} xl={6}>
            Mostrando del {size * (page - 1) + 1} al&nbsp;
            {size * page > totalRegister ? totalRegister : size * page} de&nbsp;
            {totalRegister} registros. &nbsp;&nbsp;| Página:&nbsp;
            <Form.Select
              size="sm"
              id="selecPages"
              defaultValue={"1"}
              style={{ display: "inline", width: "auto" }}
              value={page}
              onChange={(e) => {
                this.handlePageChange(e, "page");
              }}
            >
              {optionPages}
            </Form.Select>{" "}
            de {totalPages}. Por página:{" "}
            <Form.Select
              size="sm"
              id="selectCount"
              defaultValue={"30"}
              style={{ display: "inline", width: "auto" }}
              onChange={(e) => {
                this.handleSizeChange(e, "size");
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </Form.Select>
          </Form.Label>
        </Row>
        {this.state.loading && (
          <LoadApi
            indicatorLoad={true}
            message="Cargando información de socios"
            messageWarning="Este proceso puede demorar unos minutos."
          ></LoadApi>
        )}
        {this.state.emptyList && !this.state.loading && (
          <Form.Label>{this.state.message}</Form.Label>
        )}
        {!this.state.emptyList && (
          <div>
            <Row>
              <Col sm={12}>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th></th>
                      <th>Usuario</th>
                      <th>Nombre</th>
                      <th>Fecha de registro</th>
                      <th>Estado</th>
                      <th>Rango</th>
                      <th>Nivel Patrocinio</th>
                      <th>Nivel Residual</th>
                      <th>Rama</th>
                      <th>Patrocinador</th>
                      <th>Puntos de Equipo</th>
                      <th>Punto individuales</th>
                      <th>Activo hasta</th>
                      <th>Fecha de último pago</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sponsors.map(function(item, idx) {
                      let date = "";
                      return (
                        <tr key={item.id}>
                          <td>{size * (page - 1) + 1 + idx}</td>
                          <td>{item.userName}</td>
                          <td>
                            {item.name} {item.lastName}
                          </td>
                          <td>
                            {Validation.convertDateToString(item.createDate)}
                          </td>
                          <td>{item.stateText}</td>
                          <td>{item.nameRangeCompuesto}</td>
                          <td>{item.nivelPatrocinio}</td>
                          <td>{item.nivelResidual}</td>
                          <td>{item.branch}</td>
                          <td>
                            {item.nameSponsor} {item.lastnameSponsor}
                          </td>
                          <td>{item.groupPoints}</td>
                          <td>{item.ptosIndividuales}</td>
                          <td>
                            {Validation.convertDateToString(
                              item.nextExpiration
                            )}
                          </td>
                          <td>
                            {Validation.convertDateToString(item.lastDatePaid)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
