import React, { Component } from "react";
import PropTypes from "prop-types";
import ChatBot, { Loading } from "react-simple-chatbot";
import ChatBotService from "../../services/chatbot.service";

export default class GenerateTicket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      result: "",
    };
  }

  componentDidMount() {
    this.generateTicket();
  }

  async generateTicket() {
    const obj = {
      nameUserModify: localStorage.getItem("changeUserName"),
      dniUserRequesting: localStorage.getItem("userSponsorDNI"),
      dniUserModify: localStorage.getItem("changeUserDNI"),
      memberCurrent: localStorage.getItem("membresia_actual"),
      memberNew: localStorage.getItem("membresia_nueva"),
      nameNew: localStorage.getItem("changeName"),
      lastName: localStorage.getItem("changeLastName"),
      nationalityNew: localStorage.getItem("changeNationality"),
      typeDoc: localStorage.getItem("changeTypeOfDocument"),
      DNI: localStorage.getItem("changeDNI"),
      birthdate: localStorage.getItem("changeDateOfBirthdate"),
      sex: localStorage.getItem("changeSex"),
      email: localStorage.getItem("changeEmail"),
      civilS: localStorage.getItem("changeCivilState"),
      address: localStorage.getItem("changeAddress"),
      city: localStorage.getItem("changeCity"),
      country: localStorage.getItem("changeCountry"),
      telef: localStorage.getItem("changeTelef"),
      nameNewCo: localStorage.getItem("changeNameCo"),
      lastCo: localStorage.getItem("changeLastNameCo"),
      nroDocCo: localStorage.getItem("changeNroDocumentoCo"),
      subs: localStorage.getItem("suscripcion"),
      inicialPago: localStorage.getItem("inicialPago"),
      nroCuotas: localStorage.getItem("nro_cuotas"),
      cuotaFaltante: localStorage.getItem("cuota_faltante"),
      member_act: localStorage.getItem("membresia_actual"),
      datePago: localStorage.getItem("fecha_pago_ultima_cuota"),
      meses_gracia: localStorage.getItem("meses_gracia"),
      member_new: localStorage.getItem("membresia_nueva"),
      dateMigracion: localStorage.getItem("fecha_migracion"),
    };

    const json = {
      idUser: parseInt(this.props.username),
      nroDocument: localStorage.getItem("userDNI"),
      idTypeOfTicket: parseInt(localStorage.getItem("userOption")),
      newValue: JSON.stringify(obj),
      obv: localStorage.getItem("comment"),
      email: localStorage.getItem("userEmail"),
      initial: localStorage.getItem("inicialPago"),
    };

    console.log("haber json en generateTicket", json);
    let response = await ChatBotService.generateticket(json);

    if (response != undefined) {
      if (response.status == 1) {
        this.setState({
          loading: false,
          result:
            "Se ha creado correctamente tu solicitud, tu número de ticket es " +
            response.objModel +
            " recibirás respuesta dentro de las siguientes 24 a 48 horas",
        });
        this.triggertNext();
      } else {
        this.setState({
          loading: false,
          result: "Error al generar ticket, realiza de nuevo la solicitud",
        });
        this.triggertNextError();
      }
    } else {
      this.setState({
        loading: false,
        result: "Error al generar ticket, realiza de nuevo la solicitud",
      });
      this.triggertNextError();
    }

    /// end
  }

  triggertNext() {
    this.setState({ trigger: true }, () => {
      this.props.triggerNextStep({ trigger: "final2" });
    });
  }
  triggertNextError() {
    this.setState({ trigger: true }, () => {
      this.props.triggerNextStep({ trigger: "2" });
    });
  }

  render() {
    const { loading, result } = this.state;

    return (
      <div className="dbpedia" style={{ color: "#fff" }}>
        {loading ? <Loading /> : result}
      </div>
    );
  }
}
