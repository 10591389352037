import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Validation from "../../components/utils/Validation";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./CertificateView.css";
import jsPDF from "jspdf";
//import html2canvas from 'html2canvas';
import Certificadoacciones from "../../images/certificadoacciones.png";
import CertificacionesUpdate from '../../images/certificaciones2.png';

const verifyFamilyInsorts = (value) => {
  value = value.trimEnd();
  console.log(value, "comprobe")
  switch (value) {
    case 'INRESORTS RIBERA DEL RÍO':
     return true;
    case 'SEMILLA RIBERA DEL RÍO':
     return true;
    case 'INRESORTS LA JOYA':
      return true;
    case 'SEMILLA INRESORTS LA JOYA':
      return true;
    default:
     return false;
  }
}

class CertificateListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listcertificate: props.listcertificate,
      user: props.user,
      obj: {
        id: 0,
        package: {
          name: "Kit de Inicio",
          idFamilyPackage: 50,
          nameFamilyPackage: "INCLUB",
          creationDate: "",
        },
        packageDetailResponse: {
          idPackageDetail: 0,
          price: 0,
          numberQuotas: 0,
          initialPrice: 0,
          quotaPrice: 0,
          priceLetters: "",
          namePackage: "Kit de Inicio",
          nameFamilyPackage: "INCLUB",
        },
      },
      array: [],
      email: "",
      arraypdf: [],
    };
  }

  jsPdfGeneratoracciones = async (e, certificate, send, name) => {
    console.log("certid", certificate)
    console.log(this.state.user);
    if (this.state.listcertificate !== undefined) {
      const unit = "pt";
      const size = "A4";
      const orientation = "landscape";
      const doc = new jsPDF(orientation, unit, size);

      //doc.fromHTML();
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();

      doc.addImage(CertificacionesUpdate, 0, 0, width, height);
      doc.setFont("times", "italic");

      if (certificate.isAvailable !== 1) {
        doc.setFontSize(45);
        doc.setTextColor(255, 0, 0);
        doc.saveGraphicsState();
        doc.setGState(new doc.GState({ opacity: 0.25 }));
        // doc.text("Certificado Temporal", 250, 420, 31); //ARRIBA
        doc.restoreGraphicsState();
        doc.setTextColor(0);
      }

      doc.setFontSize(15);
      doc.setTextColor(0);
      doc.text(557, 100, "Certificado N.°");
      let numceros = 8;
      let numcertificado = String(certificate.idCertificateShare);
      let lennumcertificado = numcertificado.length;
      let txtnumcertificado = "";
      for (let i = 0; i < numceros; i++) {
        if (i < numceros - lennumcertificado) txtnumcertificado += "0";
        else {
          txtnumcertificado += numcertificado;
          break;
        }
      }

      doc.text(650, 100, txtnumcertificado);
      let l = 240;
      let h = 165;
      var aL = 45;

      var compañia;
      var millacciones;
      var clases = '';

      console.log(verifyFamilyInsorts(certificate.suscriptionNameFamilyPackage));
      if (verifyFamilyInsorts(certificate.suscriptionNameFamilyPackage)) {
        compañia = "Ribera del Rio Club Resorts S.A";
        millacciones = "600";
        clases = 'Clase B';
        aL = 8;
      } else {
        compañia = "Keola Networks S.A";
        millacciones = "25 mil";
        if( certificate.suscriptionNameFamilyPackage === "KEOLA" ) clases = 'Clase C';
      }

      doc.setFont("times", "italic");
      doc.setFontSize(38);
      doc.text(l, h + 5, "Certificado de Acciones");
      doc.setFont("times", "italic");
      doc.setFontSize(19);
      doc.text(
        l + aL,
        h + 32,
        compañia+", certifica que:  ",
        { align: "justify", }
        
      );
      doc.setFontSize(27);
      let nombresyapellidos =
        this.state.user.name + " " + this.state.user.lastname;

      doc.text(l + 180, h + 61, nombresyapellidos, { align: "center" });
      doc.text(182, h + 63, "___________________________________");

      l = 157;
      h = 265;
      var numacciones = String(certificate.numberShares);
      let nacionalidad = String(this.state.user.courtesy).toLowerCase();
      
      

      doc.setFont("times", "italic");
      doc.setFontSize(15);

      //
      l = 152;
      h = 265;
      let direccion = String(this.state.user.districtAddress)+"-"+String(this.state.user.nationality);
      let typedocument = this.state.user.typeDoc.split("-")[0];
      let numdocument = this.state.user.nroDocument;
      let txtcertificate =
        "De nacionalidad " +
        nacionalidad +
        ", identificado con " +
        typedocument +
        " Nº " +
        numdocument +
        ", domiciliado en " +
        direccion +
        ", quien habiendo terminado de pagar el total de su paquete de acciones" +
        certificate.suscriptionNamePackage +
        ", se ha hecho acreedor(a) la bonificación de acciones que le otorga la titularidad de " +
        +numacciones +
        " acciones "+clases+", sobre un total de " +
        millacciones +
        " millones que componen la compañía " +
        compañia +
        "," +
        " las cuales se encuentran debidamente inscritas en el libro de matrícula de acciones," +
        " según los términos del contrato que se celebró con la compañía Valle Encantado S.A.C.           ";
      doc.text(txtcertificate, l, h, { maxWidth: 540, align: "justify" });
      /*doc.text(
        "Dichas acciones se elevarán a registros públicos a partir de abril 2022.  ",
        l,
        h + 135
      );*/

      let fecha = Validation.convertDateToString(
        new Date(certificate.createDate)
      ); //Validation
      let dia = fecha.split("/")[0];
      let mes = fecha.split("/")[1];
      let año = fecha.split("/")[2];

      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      mes = meses[Number(mes) - 1];

      doc.text("Lima, " + dia + " de " + mes + " del " + año, l, h + 135);
      doc.setLineDash([3, 3], 0);

      doc.save("certificado de acciones.pdf");
    } else {
      alert("No pudimos generar el documento. Inténtelo más tarde.");
    }
  };
  jsPdfGeneratoracciones2 = async (e, certificate, send, name) => {
    console.log("haber certificate jsPdfGeneratoracciones2", certificate);
    console.log(this.state.user);
    if (this.state.listcertificate !== undefined) {
      const unit = "pt";
      const size = "A4";
      const orientation = "landscape";
      const doc = new jsPDF(orientation, unit, size);

      //doc.fromHTML();
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();

      doc.addImage(Certificadoacciones, 0, 0, width, height);
      doc.setFont("times", "italic");

      if (certificate.isAvailable !== 1) {
        doc.setFontSize(45);
        doc.setTextColor(255, 0, 0);
        doc.saveGraphicsState();
        doc.setGState(new doc.GState({ opacity: 0.25 }));
        // doc.text("Certificado Temporal", 250, 420, 31); //ARRIBA
        doc.restoreGraphicsState();
        doc.setTextColor(0);
      }

      doc.setFontSize(15);
      doc.setTextColor(0);
      doc.text(557, 100, "Certificado N.°");
      let numceros = 8;
      let numcertificado = String(certificate.idCertificateShare);
      let lennumcertificado = numcertificado.length;
      let txtnumcertificado = "";
      for (let i = 0; i < numceros; i++) {
        if (i < numceros - lennumcertificado) txtnumcertificado += "0";
        else {
          txtnumcertificado += numcertificado;
          break;
        }
      }

      doc.text(650, 100, txtnumcertificado);
      let l = 240;
      let h = 165;
      var aL = 45;

      if (verifyFamilyInsorts(certificate.suscriptionNameFamilyPackage)) {
        compañia = "Ribera del Rio Club Resorts S.A";
        millacciones = "600";
        clases = 'Clase B';
        aL = 8;
      } else {
        compañia = "Keola Networks S.A";
        millacciones = "25 mil";
        
        if( certificate.suscriptionNameFamilyPackage === "KEOLA" )clases = 'Clase C'
      }

      doc.setFont("times", "italic");
      doc.setFontSize(38);
      doc.text(l, h + 5, "Certificado de Acciones");
      doc.setFont("times", "italic");
      doc.setFontSize(19);
      doc.text(
        l + aL,
        h + 32,
        "Valle Encantado S.A.C. certifica que:  ",
        "justify"
      );
      
      doc.setFontSize(27);
      let nombresyapellidos =
        this.state.user.name + " " + this.state.user.lastname;

      doc.text(l + 180, h + 61, nombresyapellidos, { align: "center" });
      doc.text(182, h + 63, "___________________________________");

      l = 157;
      h = 265;
      var numacciones = String(certificate.numberShares);
      var compañia;
      var millacciones;
      var clases = 'w';
      let nacionalidad = String(this.state.user.courtesy).toLowerCase();

      

      doc.setFont("times", "italic");
      doc.setFontSize(15);

      //
      l = 152;
      h = 265;
      let direccion = String(this.state.user.districtAddress)+"-"+String(this.state.user.nationality);
      let typedocument = this.state.user.typeDoc.split("-")[0];
      let numdocument = this.state.user.nroDocument;
      let txtcertificate =
        "De nacionalidad " +
        nacionalidad +
        ", identificado con " +
        typedocument +
        " Nº " +
        numdocument +
        ", domiciliado en " +
        direccion +
        ", quien habiendo terminado de pagar el total de su paquete de acciones " +
        certificate.suscriptionNamePackage +
        " se ha hecho acreedor(a) la titularidad de " +
        +numacciones +
        " acciones"+ clases+", sobre un total de 600" + /*tipo A*/
        /* millacciones + */
        " millones que componen la compañía RIBERA DEL RÍO CLUB RESORTS SA" +
        /* compañia + */
        "," +
        " las cuales se encuentran debidamente inscritas en el libro de matrícula de acciones," +
        " según los términos del contrato que se celebró con la compañía Valle Encantado S.A.C.           ";
      doc.text(txtcertificate, l, h, { maxWidth: 540, align: "justify" });
      doc.text(
        "Dichas acciones se registrarán en el libro de acciones a partir de 1 de agosto 2022.  ",
        l,
        h + 135
      );

      let fecha = Validation.convertDateToString(
        new Date(certificate.createDate)
      ); //Validation
      let dia = fecha.split("/")[0];
      let mes = fecha.split("/")[1];
      let año = fecha.split("/")[2];

      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      mes = meses[Number(mes) - 1];

      doc.text("Lima, " + dia + " de " + mes + " del " + año, l, h + 157);
      doc.setLineDash([3, 3], 0);

      doc.save("certificado de acciones.pdf");
    } else {
      alert("No pudimos generar el documento. Inténtelo más tarde.");
    }
  };
  enviarCorreo = async () => {
    //console.log(this.state.suscription);
    var is;
    let formdata = new FormData();
    if (this.state.array.length > 0) {
      for (let i = 0; i < this.state.array.length; i++) {
        is = this.state.listcertificate[this.state.array[i] - 1];
        let e;
        let a = await this.jsPdfGeneratoracciones(
          e,
          is,
          true,
          "Certificado de Acciones " + is.idCertificateShare
        );
        formdata.append("pdf-" + i + 1, a);
      }

      // Swal.fire({
      // title: 'Error',
      // text: 'No es posible ver los certificados seleccionados',
      // icon: 'error',
      // confirmButtonColor: '#3085d6',
      // confirmButtonText: 'Yes, delete it!'
      // })
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-info",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire(
        "Error!",
        "No se ha seleccionado ningun certificado",
        "error"
      );
    }
  };
  mostrarcertificados = () => {
    let tags = "";

    if (this.state.listcertificate.length > 0) {
      console.log("Certificaciones =>", this.state.listcertificate);
      tags = this.state.listcertificate.map((item, idx) => (
        <tr key={item.id}>
          {/* <td><Form.Check type="checkbox" id={"chk-"+idx} onChange={(e)=>this.handlecheck(item,idx,e)}
                        
                    /></td> */}
          <td>{idx + 1}</td>
          <td>{item.idCertificateShare}</td>
          <td>{item.suscriptionNameFamilyPackage}</td>
          <td>{item.suscriptionNamePackage} </td>
          <td>{Validation.convertDateToString(new Date(item.createDate))}</td>
          <td>{item.numberShares}</td>
          {item.suscriptionNameFamilyPackage !== "RIBERA DEL RÍO " && (
            <td>
              {item.isAvailable === 1
                ? ""
                : "Condicionado a descargas de usuario y descargas de comercio. Ver contrato"}
            </td>
          )}
          {item.suscriptionNameFamilyPackage !== "RIBERA DEL RÍO " ? (
            <td>
              <FontAwesomeIcon
                icon={faDownload}
                style={{ height: "30px", width: "30px", cursor: "pointer" }}
                onClick={(e) => this.jsPdfGeneratoracciones(e, item, false, "")}
              />
            </td>
          ) : (
            <td>
              <FontAwesomeIcon
                icon={faDownload}
                style={{ height: "30px", width: "30px", cursor: "pointer" }}
                onClick={(e) =>
                  this.jsPdfGeneratoracciones2(e, item, false, "")
                }
              />
            </td>
          )}
        </tr>
      ));
    }

    return tags;
  };
  handlecheck = (idx, e) => {
    let chk = e.target.checked;
    let arreglo = this.state.array;
    let val = idx + 1;
    document.getElementById("chkmain").checked = false;
    if (chk === true) {
      if (!arreglo.includes(val)) {
        arreglo.push(val);
      }
    } else {
      const isinlist = (element) => element === val;
      let iarray = arreglo.findIndex(isinlist);
      arreglo.splice(iarray, 1);
    }
    this.setState(() => {
      return { array: arreglo };
    });
  };
  handlecheckAll = (e) => {
    let arreglo = [];
    if (e.target.checked === true) {
      for (let i = 0; i < this.state.listcertificate.length; i++) {
        let chk = document.getElementById("chk-" + i);
        chk.checked = true;
        arreglo.push(i + 1);
      }
    } else {
      for (let i = 0; i < this.state.listcertificate.length; i++) {
        let chk = document.getElementById("chk-" + i);
        chk.checked = false;
      }
    }
    this.setState(() => {
      return { array: arreglo };
    });
  };
  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        <Table responsive>
          <thead className="table-head">
            <tr>
              {/* <th><Form.Check type="checkbox" id="chkmain" onChange={(e)=>this.handlecheckAll(e)}/>
                          </th> */}

              <th>N° Ítem</th>
              <th>N° Certificado</th>
              <th>Tipo de Paquete de Suscripción</th>
              <th>Producto o Servicio adquirido</th>
              <th>Fecha de adquisición</th>
              <th>Cantidad de acciones</th>
              <th>Observaciones</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.mostrarcertificados()}</tbody>
        </Table>
        {/* <Form.Group as={Row} style={{margin:"0"}}>
                  <Form.Label style={{marginTop: "5px",paddingRight:"10px",fontSize:"15px"}}>
                      Enviar por correo: </Form.Label>
                  
                  <Col sm={4} style={{paddingLeft: "0px",paddingRight:"0px"}}>
                      <Form.Control disabled style={{fontSize:"14px",fontWeight:"500"}} 
                      type="email" 
                      value={JSON.parse(sessionStorage.getItem('info').replaceAll('/', '')).email} />
                  </Col>
                      <Button
                      variant="info"
                      size="sm"
                      onClick={()=>this.enviarCorreo()}
                      style={{paddingRight:"5px",paddingLeft:"5px",width:"100%",
                      marginTop:"20px"}}>
                          Enviar
                      </Button>
              </Form.Group> */}
      </div>
    );
  }
}

export default CertificateListTable;
