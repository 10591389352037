import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from "react-bootstrap";
import UtilService from "../../services/utils.service";
import UserService from "../../services/user.service";
import AuthService from 'services/auth.service';

const formDataInit = {
  name: "",
  lastname: "",
  idNationality: "",
  idDocumentType: "",
  desDocument: "",
  nroDocument: "",
  email: "",
  birthdate: "",
  nroTelf: ""
};

const calcularEdad = (fecha_nacimiento) => {
  var hoy = new Date();
  var cumpleanos = new Date(fecha_nacimiento);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();
  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
  }
  return edad;
}

export const PromotionalGuests = () => {
    const [nationalities, setNationalities] = useState([]);
    const [typeDocuments, setTypeDocuments] = useState([]);
    const [tempDocuments, setTempDocuments] = useState([]);
    const [showOthers, setShowOthers] = useState("none");
    const [messageDoc, setMessageDoc] = useState("");
    const [messageDate, setMessageDate] = useState("");
    const [messageEmail, setMessageEmail] = useState("");


    // State Form
    const [formObject, setFormObject] = useState(formDataInit);

    useEffect(() => {
      

      getCountries();
      getDefaultDocument();

    }, [])
    
    

    const getCountries = async() => {
        let response = await UtilService.getResidences();
    
        if (response !== null && response !== undefined) {
          let objs = response.objModel;
          let residences = [];
          objs.forEach((elem) => {
            residences.push(
              <option key={elem.idCountry} value={elem.idCountry}>
                {elem.name}
              </option>
            );
          });
    
          setNationalities(residences);
        }
    }
    const getDefaultDocument = async() => {
      let response = await UtilService.getTypeDocDefault();
      
      if (response !== null && response !== undefined) {
        if (response.status === 1) {
          let data = response.objModel;
        
          setTempDocuments(data);
        }
      }
    }
    const createItemTypes = async(id = -1) => {
      
      console.log(id, 'aqui')
      if (id > 0) {
        let response = await UtilService.getTypeDocByNat(id);
        console.log(response)
        if (response !== null && response !== undefined) {
          let items = [];
          if (response.status === 1 && response.objModel.length > 0) {
            items = response.objModel;
          } else {
            items = tempDocuments;
          }
  
          setTypeDocuments(items)
        }
      }
    }

    /**
     * Method to handle the change  of properties and send it to parent
     * @param {*} e
     * @param {*} field
     */
    const handleChange = (e, field) => {
      let value = e.target.value.trim();
      setFormObject(values => ({...values, [field] : value }))

    
      // })
    };

    /**
     * Method to handle the selected item  send it to parent
     * @param {*} e
     * @param {*} field
     */
    const handleSelect = (e, field) => {
      var value = e.target.value;
      
      if (formObject.hasOwnProperty(field)){
        handleChange(e, field);
      }


      if (field === "idNationality") {
        createItemTypes(value);
      }

      if ( field === "idDocumentType" ){
        let text = e.target.options[e.target.selectedIndex].text.toUpperCase();
        if (text.includes("OTRO")) {
          setShowOthers("inline");
        } else {
          setShowOthers("none");
          setFormObject(values => ({...values, desDocument: ""}))
        }
      }

    }

     // Verify nro Document
    const onBlurDocument = (e, field, fieldMessage) => {
      let nroDocument = formObject[field];
      if (nroDocument.length > 0) {
        verifyDocument(field, fieldMessage);
      }
    };

    const verifyDocument = async(field, fieldMessage) => {
      let data = {};
      let  msg = "";
      data.nroDocument = formObject[field];
  
      let isRegister = await UserService.isDocumentRegistered(data);
  
      if (isRegister) {
        msg = "Este documento ya ha sido registrado." 
      }

      if( fieldMessage == "messageDoc" ) setMessageDoc(msg);

    }

    const onBlurEmail = (e, field, fieldMessage) => {
      let value = e.target.value.trim();
      if (value.length > 0) {
        if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
          this.setState({
            [fieldMessage]: (this.state[fieldMessage] = ""),
          });
  
          if (value !== formObject.email) {
            verifyEmail(value, field, fieldMessage);
          }
        } else {
          setFormObject(values => ({...values, email: ""}));
          setMessageEmail("Ingrese un correo válido.");
        }
      }
    };
    // Verify email
    const verifyEmail = async(email, field, fieldMessage) =>  {
      let data = {};
      data.email = email;
      if (this.state.userCurrent.email !== email) {
        let isRegistered = await UserService.isEmailRegistered(data);
        if (isRegistered) {
          setFormObject(values => ({...values, email: ""}));
          setMessageEmail("Este correo ya está registrado.");
        }
      }
    }

    const onBlurDate = (e, field, fieldMessage) => {
      // Validate date
      var regEx = /^\d{4}-\d{2}-\d{2}$/;
      let date = e.target.value;
      let correct = date.match(regEx);
      let msg = "";

      if (!correct) {
        msg = "Ingrese una fecha válida."
      } 
      
      setMessageDate(msg);

    };

    const onSubmit = async(e) => {
      e.preventDefault();
      const {  
        name,
        lastname,
        idNationality,
        idDocumentType,
        desDocument,
        nroDocument,
        email,
        birthdate,
        nroTelf
      } = formObject

      console.log(formObject);
      // valid form
      if( name == '' || lastname == '' || idNationality == '' || nroDocument == ''  || birthdate == '' 
        || idDocumentType == '' || ( showOthers == 'inline' && desDocument == '' || ( calcularEdad(birthdate) >= 18 && email == '' ) )
      ){
        alert("Debes completar todos los datos, para poder registrarte");
        return;
      }


      const resp = await UtilService.insertPromotionalGuest({
        name: name,
        lastname: lastname,
        birthdate: birthdate,
        idDocument: parseInt(idDocumentType),
        nroDocument: nroDocument,
        email: calcularEdad(birthdate) < 18 ? null : email,
        nroTelf: nroTelf,
        idSponsor: Number(AuthService.getCurrentIdUser())
      });

      if ( resp.hasOwnProperty("objModel") && resp.objModel != null ){
        alert("Registro exitoso");
        setFormObject(values => ({...values, ...formDataInit}));
        return;
      }

      if(  resp.hasOwnProperty("description") && resp.description != null  ){
        alert(resp.description);
        return;
      }


      alert('Ha ocurrido un error, intente mas tarde')
    }

    return (
      <Form onSubmit={(e) => onSubmit(e)}>
          <Form.Label className="content-subtitle mb-5">Datos personales</Form.Label>
          <Form.Group controlId="formStepOne" className='text-start'>
            <Row className="mb-2">
              <Col sm={6} className='mb-3'>
                <Form.Group>
                  <Form.Label>Nombres *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nombres"
                    value={formObject.name}
                    onChange={(e) => handleChange(e, "name")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} className='mb-3'>
                <Form.Group>
                  <Form.Label>Apellidos *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Apellidos"
                    value={formObject.lastname}
                    onChange={(e) => handleChange(e, "lastname")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} className='mb-3'>
                <Form.Group>
                  <Form.Label>Nacionalidad *</Form.Label>
                  <Form.Select
                    defaultValue={""}
                    value={formObject.idNationality}
                    onChange={(e) => handleSelect(e, "idNationality")}
                    size="sm"
                  >
                    <option value="" disabled>
                      Seleccione una opción
                    </option>
                    {nationalities}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6} className='mb-3'>
                <Form.Group>
                  <Form.Label>Tipo de documento *</Form.Label>
                  <Form.Select
                    defaultValue={""}
                    value={formObject.idDocumentType}
                    size="sm"
                    onChange={(e) =>
                      handleSelect(e, "idDocumentType")
                    }
                  >
                    <option value="" disabled>
                      Seleccione una opción
                    </option>
                    { 
                      typeDocuments.map((elem) => (
                        <option key={elem.id} value={elem.id}>
                          {elem.name}
                        </option>
                      ) )
                    }
                  </Form.Select>
                  <br></br>
                  <Form.Control
                    style={{ display: showOthers, paddingTop: 6 }}
                    type="text"
                    placeholder="Ingrese tipo de documento"
                    value={formObject.desDocument}
                    onChange={(e) => handleChange(e, "desDocument")}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6}  className='mb-3' >
                <Form.Group>
                  <Form.Label>Nro. de documento *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={formObject.nroDocument}
                    placeholder="Nro. documento"
                    onChange={(e) => handleChange(e, "nroDocument")}
                    onBlur={(e) =>
                      onBlurDocument(e, "nroDocument", "messageDoc")
                    }
                  ></Form.Control>
                  <Form.Text className="textAlert">
                    {messageDoc}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sm={6} className='mb-3' >
                <Form.Group>
                  <Form.Label>Correo electrónico </Form.Label>
                  <Form.Control
                    required={calcularEdad(formObject.birthdate) >= 18 ? true : false }
                    type="email"
                    placeholder="Correo electrónico"
                    value={formObject.email}
                    onChange={(e) => handleChange(e, "email")}
                    onBlur={(e) => onBlurEmail(e, "email", "messageEmail")}
                  ></Form.Control>
                  <Form.Text className="textAlert">
                    {messageEmail}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sm={6} className='mb-3' >
                <Form.Group>
                  <Form.Label>Fecha de nacimiento *</Form.Label>
                  <Form.Control
                    type="date"
                    value={formObject.birthdate}
                    onChange={(e) =>  handleChange(e, "birthdate")}
                    onBlur={(e) => onBlurDate(e, "birthdate", "messageDate")}
                  ></Form.Control>
                  <Form.Text className="textAlert">
                    {messageDate}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sm={6} className='mb-3'>
                <Form.Group>
                  <Form.Label>Nro. Celular </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nro. celular"
                    name="nroTelf"
                    value={formObject.nroTelf}
                    onChange={(e) => handleChange(e, "nroTelf")}
                    onKeyPress={(e) => {
                      let key = window.Event ? e.which : e.keyCod;
                      if ( key < 48 || key > 57  ) {
                          e.preventDefault();
                      }
                    }}

                  ></Form.Control>
                  <span className="text-danger fw-bold pb-2" style={{fontSize:'11px'}}>
                  Por favor evitar escribir el codigo del pais nuevamente ,
                  gracias!
                </span>
                  <Form.Control.Feedback type="invalid">
                    Ingrese un número de celular válido.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mt-4 text-end">
            <Button variant="primary text-uppercase" type="submit">
              Registrar
            </Button>
          </Form.Group>
      </Form>
    )
}
