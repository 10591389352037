import { Button, Loading, Modal } from "@nextui-org/react";
import ProgressBarLanding from "components/progressBar/progressBarLanding.jsx";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import "./landing-reunion-de-socios-2.css";
import { ReactComponent as ErrorIcon } from "./resources/errorIcon.svg";
import { ReactComponent as SuccessIcon } from "./resources/successIcon.svg";
import Swal from "sweetalert2";

const NAME_LANDING = "reunión de socios";

function LandingReunionDeSocios2(props) {
  const {
    register: registerSoySocio,
    reset: resetSoySocio,
    handleSubmit: handleSubmitSoySocio,
    watch: watchSoySocio,
    setError: setErrorSoySocio,
    clearErrors: clearErrorsSoySocio,
    setValue: setValueSoySocio,
    formState: { errors: errorsSoySocio },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldUseNativeValidation: false,
  });

  const {
    register: registerNoSoySocio,
    reset: resetNoSoySocio,
    handleSubmit: handleSubmitNoSoySocio,
    watch: watchNoSoySocio,
    setError: setErrorNoSoySocio,
    clearErrors: clearErrorsNoSoySocio,
    setValue: setValueNoSoySocio,
    formState: { errors: errorsNoSoySocio },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldUseNativeValidation: false,
    defaultValues: {
      selectMeeting: {
        IdTypeGuest: "5",
      },
    },
  });

  const [messageSuccess, setMessageSuccess] = useState(null);
  const [messageError, setMessageError] = useState(null);

  const [loadingInitial, setLoadingInitial] = useState(true);
  const filterExisting = useRef(null);
  const dateMeeting = useRef(null);
  const flagMeeting = useRef(null);
  const dataMeeting = useRef(null);

  const [visibleModalLoading, setVisibleModalLoading] = useState(false);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [visibleModalError, setVisibleModalError] = useState(false);

  const [changeForm, setChangeForm] = useState("soy socio");

  const refButtonSoySocio = useRef(null);
  const refButtonNoSoySocio = useRef(null);
  const controllerTimeTypingSoySocio = useRef(null);
  const controllerTimeTypingNoSoySocio = useRef(null);
  const statusValidateDniSoySocio = useRef(null);
  const statusValidatUserNameNoSoySocio = useRef(null);

  const watchShowSelectMeeting = watchNoSoySocio(
    "selectMeeting.IdTypeGuest",
    false
  );

  const getInitialFetchLanding = async () => {
    try {
      const dataFetchMeeting = await fetch(
        `${process.env.REACT_APP_APP_URL_API}/meetings/current`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const dataMeetingCurrent = await dataFetchMeeting.json();

      const dataFetchForIp = await fetch(
        `https://ipinfo.io/?token=99b920e70eaec6`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const dataForIp = await dataFetchForIp.json();

      const dataFetchCountry = await fetch(
        `${process.env.REACT_APP_APP_URL_API}/country/name`,
        {
          method: "POST",
          body: JSON.stringify({
            country: dataForIp.country,
          }),
          headers: { "Content-Type": "application/json" },
        }
      );
      const dataCountry = await dataFetchCountry.json();

      if (dataMeetingCurrent?.objModel.length !== 0) {
        const responseValidateFilter = await fetch(
          `${process.env.REACT_APP_APP_URL_API}/meetings/submeetings/detail/${dataMeetingCurrent?.objModel[0]?.subMeetingTypeId}`,
          { method: "GET", headers: { "Content-Type": "application/json" } }
        );
        const respValidateFilter = await responseValidateFilter.json();
        filterExisting.current =
          respValidateFilter?.objModel.length > 0 ? true : false;
        if (filterExisting.current) {
          setChangeForm("soy socio");
        }
        //date from localZone
        const localDateTime = new Date(
          `${dataMeetingCurrent?.objModel[0]?.startDate}-0500`
        );
        const dataTimeZoneLocal = Intl.DateTimeFormat().resolvedOptions();
        const dateOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          timeZone: dataForIp?.timezone || dataTimeZoneLocal.timeZone,
        };
        const timeOptions = {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: dataForIp?.timezone || dataTimeZoneLocal.timeZone,
        };
        const respDate = new Intl.DateTimeFormat(
          dataTimeZoneLocal.locale,
          dateOptions
        ).format(localDateTime);
        const respTime = new Intl.DateTimeFormat(
          dataTimeZoneLocal.locale,
          timeOptions
        ).format(localDateTime);
        dateMeeting.current = `La reunión empieza el día ${respDate} ${respTime}, en ${dataCountry?.objModel[0].nicename}/${dataForIp?.city}`;
      } else {
        dateMeeting.current = `Por el momento no hay reuniones programadas.`;
      }

      if (dataCountry?.objModel.length !== 0 && dataForIp.country) {
        flagMeeting.current = dataForIp.country.toLowerCase();
      }

      dataMeeting.current = dataMeetingCurrent?.objModel[0];
      setLoadingInitial(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "Verifica tu conexión ó un bloqueo(🛑AdBlock) por parte de tu navegador.",
        //footer: '<a href="">Why do I have this issue?</a>'
      });
      dateMeeting.current = "Ocurrio un error al mostrar los horarios.";
      setLoadingInitial(false);
    }
  };

  const getInfoIp = async () => {
    const response = await fetch(`https://ipinfo.io/?token=99b920e70eaec6`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const resp = await response.json();
    if (!resp) {
      setMessageError(
        "Error en el servicio de IP, verifique su conexión e intente de nuevo."
      );
      throw new Error(
        "Error en el servicio de IP, verifique su conexión e intente de nuevo."
      );
    }
    return resp;
  };

  const getSubTypeSelected = (dataForm) => {
    let respuesta;
    switch (dataForm?.selectMeeting?.IdTypeGuest) {
      case "1":
        respuesta = 9;
        break;
      case "2":
        respuesta = Number(dataForm.selectMeeting.subSelect.socialNetwork);
        break;
      case "3":
        respuesta = Number(dataForm.selectMeeting.subSelect.search);
        break;
      case "4":
        respuesta = Number(dataForm.selectMeeting.subSelect.messagingService);
        break;
      case "5":
        respuesta = 10;
        break;
      default:
        break;
    }
    return respuesta;
  };

  const getInfoUserFromUserNameSoySocio = async (userName) => {
    console.log("entro a");
    console.log(dataMeeting);
    if (!dataMeeting.current?.id) {
      setMessageError("No hay reuniones programadas.");
      throw new Error("No hay reuniones programadas.");
    }
    const response = await fetch(
      `${process.env.REACT_APP_APP_URL_API}/User/search/nrodocument/landing?username=${userName}&idMeeting=${dataMeeting.current.id}`,
      { method: "GET", headers: { "Content-Type": "application/json" } }
    );
    const resp = await response.json();
    if (!resp.objModel) {
      setMessageError(resp.description);
      throw new Error(resp.description);
    }
    return resp.objModel;
  };

  const getInfoUserFromUserName = async (userName) => {
    const response = await fetch(
      `${process.env.REACT_APP_APP_URL_API}/User/username?value=${userName}`,
      { method: "GET", headers: { "Content-Type": "application/json" } }
    );
    const resp = await response.json();
    if (!resp.objModel) {
      setMessageError(resp.description);
      throw new Error(resp.description);
    }
    return resp.objModel;
  };

  const getLinkZoom = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_APP_URL_API}/meetings/now`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const resp = await response.json();
    if (!resp.objModel) {
      setMessageError(resp.description);
      throw new Error("Error getLinkZoom");
    }
    return resp;
  };

  const submitData = async (dataJsonPOST) => {
    const response = await fetch(`${process.env.REACT_APP_APP_URL_API}/guest`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataJsonPOST),
    });
    const resp = await response.json();
    if (!resp.objModel) {
      setMessageError("No se pudo enviar los datos del formulario.");
      throw new Error("Error submitData");
    } else {
      resetNoSoySocio();
      resetSoySocio();
    }
  };

  const messageLinkZoomModal = (linkZoom) => {
    window.open(`${linkZoom?.objModel[0].links.link}`, "_blank");
    return (
      <>
        <a
          href={linkZoom?.objModel[0].links.link}
          target={"_blank"}
          rel="noreferrer"
        >
          {linkZoom?.objModel[0].links.link}
        </a>
        <p>{linkZoom?.objModel[0].startDate}</p>
      </>
    );
  };

  const dataFormSoySocio = async (data) => {
    try {
      setVisibleModalLoading(true);
      const infoIpClient = await getInfoIp();
      const infoUser = await getInfoUserFromUserNameSoySocio(data.dni);

      const dataPost = {
        IpClient: infoIpClient?.ip,
        FullNames: `${infoUser?.name} ${infoUser?.lastname}`,
        Email: `${infoUser?.email}`,
        CountryID: infoIpClient?.country,
        City: infoIpClient?.city,
        UserId: infoUser?.id,
        SubTypeGuestId: 9,
        IdTypeGuest: 1,
        SubMeetingType: {
          IdSubMeetingType: dataMeeting.current.subMeetingTypeId,
        },
      };
      setValueSoySocio("names", `${infoUser?.name} ${infoUser?.lastname}`);
      const linkZoom = await getLinkZoom();
      const submit = await submitData(dataPost);
      setMessageSuccess(messageLinkZoomModal(linkZoom));

      setVisibleModalLoading(false);
      setVisibleModalSuccess(true);
    } catch (error) {
      console.log(error);
      setVisibleModalLoading(false);
      setVisibleModalError(true);
    }
  };

  const dataFormNoSoySocio = async (data) => {
    try {
      console.log(data);
      setVisibleModalLoading(true);
      const infoIpClient = await getInfoIp();
      const subTypeSelected = getSubTypeSelected(data);
      const infoUserForNameUser =
        data.selectMeeting.IdTypeGuest === "5"
          ? await getInfoUserFromUserName(
              data?.selectMeeting.subSelect.UserNameInvitated
            )
          : null;
      const dataPost = {
        IpClient: infoIpClient?.ip,
        FullNames: data?.names,
        Email: data?.email,
        CountryID: infoIpClient?.country,
        City: infoIpClient?.city,
        UserId: infoUserForNameUser?.id,
        SubTypeGuestId: subTypeSelected,
        IdTypeGuest: Number(data?.selectMeeting?.IdTypeGuest),
        SubMeetingType: {
          IdSubMeetingType: dataMeeting.current.subMeetingTypeId,
        },
      };
      setValueNoSoySocio(
        "selectMeeting.subSelect.NameInvitated",
        `${infoUserForNameUser?.name} ${infoUserForNameUser?.lastname}`
      );

      const linkZoom = await getLinkZoom();
      const submit = await submitData(dataPost);
      setMessageSuccess(messageLinkZoomModal(linkZoom));

      setVisibleModalLoading(false);
      setVisibleModalSuccess(true);
    } catch (error) {
      console.log(error);
      setVisibleModalLoading(false);
      setVisibleModalError(true);
    }
  };

  const handleForm = (name) => {
    setChangeForm(name);
    if (name === "soy socio") {
      refButtonSoySocio.current.classList.add(
        "[background:var(--nextui-colors-gradient)_!important]",
        "[color:white_!important]"
      );
      refButtonNoSoySocio.current.classList.remove(
        "[background:var(--nextui-colors-gradient)_!important]",
        "[color:white_!important]"
      );
    } else if (name === "no soy socio") {
      refButtonSoySocio.current.classList.remove(
        "[background:var(--nextui-colors-gradient)_!important]",
        "[color:white_!important]"
      );
      refButtonNoSoySocio.current.classList.add(
        "[background:var(--nextui-colors-gradient)_!important]",
        "[color:white_!important]"
      );
    }
  };

  const detectTypingInFormSoySocio = (username) => {
    window.clearTimeout(controllerTimeTypingSoySocio.current);
    statusValidateDniSoySocio.current = false;
    controllerTimeTypingSoySocio.current = window.setTimeout(async () => {
      try {
        setErrorSoySocio("dni", {
          type: "validateDni",
          message: "👨‍💻 Validando el username, espere ...",
        });
        const infoUser = await getInfoUserFromUserNameSoySocio(username);
        statusValidateDniSoySocio.current = true;
        setValueSoySocio("names", `${infoUser?.name} ${infoUser?.lastname}`);
        clearErrorsSoySocio("dni");
      } catch (error) {
        statusValidateDniSoySocio.current = false;
        setErrorSoySocio("dni", {
          type: "ErrorValidateDni",
          message: error.message.toString(),
        });
        setValueSoySocio("names", "");
      }
    }, 1000);
  };

  const detectTypingInFormNoSoySocio = (username) => {
    window.clearTimeout(controllerTimeTypingNoSoySocio.current);
    statusValidateDniSoySocio.current = false;
    if (username.length > 0) {
      controllerTimeTypingNoSoySocio.current = window.setTimeout(async () => {
        try {
          setErrorNoSoySocio("selectMeeting.subSelect.UserNameInvitated", {
            type: "validateUserName",
            message: "👨‍💻 Validando el nombre de usuario, espere ...",
          });
          const infoUserForUserName = await getInfoUserFromUserName(username);
          statusValidatUserNameNoSoySocio.current = true;
          setValueNoSoySocio(
            "selectMeeting.subSelect.NameInvitated",
            `${infoUserForUserName?.name} ${infoUserForUserName?.lastname}`
          );
          clearErrorsNoSoySocio("selectMeeting.subSelect.UserNameInvitated");
        } catch (error) {
          statusValidatUserNameNoSoySocio.current = false;
          setErrorNoSoySocio("selectMeeting.subSelect.UserNameInvitated", {
            type: "ErrorValidateUserName",
            message: "Username no válido, intente de nuevo.",
          });
          setValueNoSoySocio("selectMeeting.subSelect.NameInvitated", "");
        }
      }, 1000);
    }
  };

  useEffect(() => {
    (async () => {
      await getInitialFetchLanding();
    })();
  }, []);

  if (loadingInitial) {
    return (
      <>
        <Helmet>
          <title>
            Landing {NAME_LANDING.charAt(0).toUpperCase()}
            {NAME_LANDING.substring(1, NAME_LANDING.length)}
          </title>
          <meta
            name="description"
            content={`Presentación de oportunidad de ${NAME_LANDING} | INCLUB`}
          />
        </Helmet>
        <section className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center">
          <Loading
            type="gradient"
            color="primary"
            textColor="primary"
            size="xl"
          >
            Cargando ...
          </Loading>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section className="landing-clase-reunionDeSocios2 tw-font-sans tw-bg-cover tw-bg-bottom tw-flex tw-flex-col tw-justify-between tw-h-full tw-text-3xl tw-font-bold tw-text-left tw-text-black">
          <nav className="lg:tw-text-[1.8vw] tw-text-xl tw-font-bold tw-pt-5 tw-pb-6 tw-px-2 lg:tw-pt-5 lg:tw-pr-7 lg:tw-pb-10 lg:tw-pl-12 tw-grid tw-grid-cols-4 tw-justify-between tw-items-center">
            <ul className="tw-col-span-3 tw-text-[#5093ab] tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-[4vw] tw-list-none tw-m-0 tw-p-0 lg:tw-gap-16">
              <li>
                <NavLink
                  className="tw-text-[#5093ab] tw-no-underline [font-size:inherit] hover:tw-text-[#b6d4ff]"
                  to="/"
                >
                  Inicio
                </NavLink>
              </li>
              <li>Nosotros</li>
              <li className="tw-text-black tw-font-extrabold">Zoom</li>
            </ul>

            <div className="tw-col-span-1 tw-text-right">
              <img
                className="tw-w-full tw-max-w-[192px]"
                src="/resources/landing-clase-reunionDeSocios2/logo.png"
                alt="logo"
              />
            </div>
          </nav>

          <section className="tw-grid tw-grid-cols-1 md:tw-grid-cols-[1.5fr_1fr] tw-py-0 tw-px-3 tw-justify-items-center md:tw-justify-items-stretch tw-items-center md:tw-px-20 tw-gap-4">
            <div className="tw-flex tw-flex-col tw-my-0 tw-mx-5 lg:tw-mx-0 tw-items-center tw-justify-center">
              <img
                className="[filter:drop-shadow(2px_4px_1px_#2c2c2c)] md:tw-filter-none tw-w-2/3"
                src="/resources/landing-clase-reunionDeSocios2/logoTitulo.svg"
                alt="logo titulo"
              />
            </div>

            <div className="tw-w-full">
              <section>
                <h2 className="tw-text-white tw-text-center tw-text-3xl tw-my-5 tw-font-bold [filter:drop-shadow(2px_3px_7px_#0000009e)]">
                  BIENVENIDO
                </h2>
              </section>
              <section className="tw-flex tw-flex-row tw-justify-around tw-flex-wrap tw-items-center tw-mb-5 tw-gap-3">
                {!filterExisting.current && (
                  <>
                    <Button
                      auto
                      color="gradient"
                      className="[display:none_!important] [background:var(--nextui-colors-gradient)_!important] [color:white_!important]"
                      ref={refButtonNoSoySocio}
                      rounded
                      bordered
                      shadow
                      onClick={() => handleForm("no soy socio")}
                    >
                      ME CONECTO POR PRIMERA VEZ
                    </Button>
                    <Button
                      ref={refButtonSoySocio}
                      auto
                      color="gradient"
                      rounded
                      bordered
                      shadow
                      onClick={() => handleForm("soy socio")}
                    >
                      SOY SOCIO
                    </Button>
                  </>
                )}
              </section>

              {changeForm === "soy socio" && (
                <form
                  className="tw-text-[#5093ab]"
                  onSubmit={handleSubmitSoySocio(dataFormSoySocio)}
                >
                  <div className="tw-text-xl md:tw-text-3xl tw-mb-5">
                    <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                      Documento de Identidad ó Usuario
                      <span className="tw-text-red-600">*</span>
                    </label>
                    <input
                      {...registerSoySocio("dni", {
                        required: {
                          value: true,
                          message: "Ingrese su nombre de usuario.",
                        },
                        validate: {
                          validateDniInput: () =>
                            statusValidateDniSoySocio.current || messageError,
                        },
                        onChange: (e) =>
                          detectTypingInFormSoySocio(e.target.value),
                      })}
                      id="dni"
                      autoComplete="on"
                      className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                    />
                    {errorsSoySocio?.dni && (
                      <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                        {errorsSoySocio.dni.message}
                      </p>
                    )}
                  </div>
                  <div
                    className={`tw-text-xl md:tw-text-3xl tw-mb-5 ${
                      statusValidateDniSoySocio.current
                        ? "tw-block"
                        : "tw-hidden"
                    }`}
                  >
                    <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                      Nombres y Apellidos
                    </label>
                    <input
                      {...registerSoySocio("names")}
                      autoComplete="on"
                      className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                    />
                    {errorsSoySocio?.names && (
                      <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                        {errorsSoySocio?.names.message}
                      </p>
                    )}
                  </div>
                  <label className="tw-flex tw-items-center tw-gap-1 tw-ml-5 tw-text-lg [filter:drop-shadow(2px_4px_6px_#818181)]">
                    <input
                      type="checkbox"
                      {...registerSoySocio("checkbox", {
                        required: {
                          value: true,
                          message: "Acepte las políticas de privacidad.",
                        },
                      })}
                    />
                    Acepto políticas y uso de datos
                    <span className="tw-text-red-600">*</span>
                  </label>
                  {errorsSoySocio?.checkbox && (
                    <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                      {errorsSoySocio?.checkbox.message}
                    </p>
                  )}
                  <br />
                  <input
                    type="submit"
                    className="tw-flex tw-m-auto tw-items-center [background-image:linear-gradient(rgb(231_65_65_/_73%),_rgb(231_65_65)_50%)] tw-text-white tw-rounded-3xl tw-py-4 tw-px-16 tw-border-none tw-cursor-pointer tw-font-bold tw-leading-4 [box-shadow:-4px_5px_0px_0_rgb(0_0_0_/_56%)] hover:[background-image:linear-gradient(rgb(203_62_62_/_73%),_rgb(203_62_62)_50%)]"
                    value="Empezar"
                  />
                </form>
              )}

              {changeForm === "no soy socio" && (
                <form
                  className="tw-text-[#5093ab]"
                  onSubmit={handleSubmitNoSoySocio(dataFormNoSoySocio)}
                >
                  <div className="tw-text-xl md:tw-text-3xl tw-mb-5">
                    <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                      Nombres y Apellidos
                      <span className="tw-text-red-600">*</span>
                    </label>
                    <input
                      {...registerNoSoySocio("names", {
                        required: {
                          value: true,
                          message: "Ingrese sus nombres completos.",
                        },
                        validate: {
                          validateEspacios: (value) =>
                            value?.match(/\w+/g)?.length > 1 ||
                            "Ingresa al menos un nombre y un apellido",
                        },
                      })}
                      autoComplete="on"
                      className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                    />
                    {errorsNoSoySocio?.names && (
                      <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                        {errorsNoSoySocio?.names.message}
                      </p>
                    )}
                  </div>
                  <div className="tw-text-xl md:tw-text-3xl tw-mb-5">
                    <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                      Correo Electrónico
                      <span className="tw-text-red-600">*</span>
                    </label>
                    <input
                      {...registerNoSoySocio("email", {
                        required: {
                          value: watchShowSelectMeeting === "1" ? false : true,
                          message: "Ingrese su email.",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Email no válido",
                        },
                      })}
                      type="text"
                      autoComplete="on"
                      className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                    />
                    {errorsNoSoySocio?.email && (
                      <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                        {errorsNoSoySocio?.email.message}
                      </p>
                    )}
                  </div>
                  <div className="tw-text-xl md:tw-text-3xl tw-mb-5 tw-hidden">
                    <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                      ¿Cómo se entero de la reunión?
                      <span className="tw-text-red-600">*</span>
                    </label>
                    <select
                      {...registerNoSoySocio("selectMeeting.IdTypeGuest", {
                        validate: {
                          validateSelectPrincipal: (value) =>
                            value !== "default" || "Seleccione una opción.",
                        },
                      })}
                      defaultValue={"default"}
                      className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                    >
                      <option value="default" hidden disabled>
                        Seleccione
                      </option>
                      <option value="2">Redes sociales</option>
                      <option value="3">Buscadores</option>
                      <option value="4">Grupos de mensajeria</option>
                      <option value="5">Me invito una persona</option>
                    </select>
                    {errorsNoSoySocio?.selectMeeting?.IdTypeGuest && (
                      <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                        {errorsNoSoySocio?.selectMeeting.IdTypeGuest.message}
                      </p>
                    )}
                  </div>

                  {watchShowSelectMeeting === "1" && (
                    <div className="tw-text-xl md:tw-text-3xl tw-mb-5">
                      <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                        Ingresa tú DNI<span className="tw-text-red-600">*</span>
                      </label>
                      <input
                        {...registerNoSoySocio(
                          "selectMeeting.subSelect.socio",
                          {
                            required: {
                              value: true,
                              message: "Ingrese su documento de identidad.",
                            },
                          }
                        )}
                        id="dni"
                        type="number"
                        autoComplete="on"
                        className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                      />
                      {errorsNoSoySocio?.selectMeeting?.subSelect?.socio && (
                        <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                          {
                            errorsNoSoySocio?.selectMeeting.subSelect.socio
                              .message
                          }
                        </p>
                      )}
                    </div>
                  )}

                  {watchShowSelectMeeting === "2" && (
                    <div className="tw-text-xl md:tw-text-3xl tw-mb-5">
                      <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                        ¿Cúal red social?
                        <span className="tw-text-red-600">*</span>
                      </label>
                      <select
                        {...registerNoSoySocio(
                          "selectMeeting.subSelect.socialNetwork",
                          {
                            validate: {
                              validateSelectSocial: (value) =>
                                value !== "default" ||
                                "Seleccione una red social.",
                            },
                          }
                        )}
                        defaultValue={"default"}
                        className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                      >
                        <option value="default" hidden disabled>
                          Seleccione
                        </option>
                        <option value="1">Facebook</option>
                        <option value="2">Instagram</option>
                        <option value="3">Twitter</option>
                      </select>
                      {errorsNoSoySocio?.selectMeeting?.subSelect
                        ?.socialNetwork && (
                        <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                          {
                            errorsNoSoySocio?.selectMeeting.subSelect
                              .socialNetwork.message
                          }
                        </p>
                      )}
                    </div>
                  )}

                  {watchShowSelectMeeting === "3" && (
                    <div className="tw-text-xl md:tw-text-3xl tw-mb-5">
                      <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                        ¿Cúal buscador?
                        <span className="tw-text-red-600">*</span>
                      </label>
                      <select
                        {...registerNoSoySocio(
                          "selectMeeting.subSelect.search",
                          {
                            validate: {
                              validateSelectSearch: (value) =>
                                value !== "default" ||
                                "Seleccione un buscador.",
                            },
                          }
                        )}
                        defaultValue={"default"}
                        className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                      >
                        <option value="default" hidden disabled>
                          Seleccione
                        </option>
                        <option value="4">Google</option>
                        <option value="5">Yahoo</option>
                        <option value="6">Bing</option>
                      </select>
                      {errorsNoSoySocio?.selectMeeting?.subSelect?.search && (
                        <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                          {
                            errorsNoSoySocio?.selectMeeting.subSelect.search
                              .message
                          }
                        </p>
                      )}
                    </div>
                  )}

                  {watchShowSelectMeeting === "4" && (
                    <div className="tw-text-xl md:tw-text-3xl tw-mb-5">
                      <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                        ¿Cúal servicio de mensajeria?
                        <span className="tw-text-red-600">*</span>
                      </label>
                      <select
                        {...registerNoSoySocio(
                          "selectMeeting.subSelect.messagingService",
                          {
                            validate: {
                              validateSelectMessage: (value) =>
                                value !== "default" ||
                                "Seleccione un servicio de mensajeria.",
                            },
                          }
                        )}
                        defaultValue={"default"}
                        className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                      >
                        <option value="default" hidden disabled>
                          Seleccione
                        </option>
                        <option value="7">Whatsapp</option>
                        <option value="8">Telegram</option>
                      </select>
                      {errorsNoSoySocio?.selectMeeting?.subSelect
                        ?.messagingService && (
                        <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                          {
                            errorsNoSoySocio?.selectMeeting.subSelect
                              .messagingService.message
                          }
                        </p>
                      )}
                    </div>
                  )}

                  {watchShowSelectMeeting === "5" && (
                    <>
                      <div className="tw-text-xl md:tw-text-3xl tw-mb-5">
                        <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                          Código de invitación - Usuario quien te invito.
                          <span className="tw-text-red-600">*</span>
                        </label>
                        <input
                          {...registerNoSoySocio(
                            "selectMeeting.subSelect.UserNameInvitated",
                            {
                              required: {
                                value: true,
                                message:
                                  "Ingrese el nombre de usuario de la persona que le invito.",
                              },
                              validate: {
                                validateUserNameInput: () =>
                                  statusValidatUserNameNoSoySocio.current ||
                                  "Nombre de usuario no válido, ingrese el correcto.",
                              },
                              onChange: (e) =>
                                detectTypingInFormNoSoySocio(e.target.value),
                            }
                          )}
                          id="dni"
                          autoComplete="on"
                          className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                        />
                        {errorsNoSoySocio?.selectMeeting?.subSelect
                          ?.UserNameInvitated && (
                          <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                            {
                              errorsNoSoySocio?.selectMeeting.subSelect
                                .UserNameInvitated.message
                            }
                          </p>
                        )}
                      </div>

                      <div
                        className={`tw-text-xl md:tw-text-3xl tw-mb-5 ${
                          statusValidatUserNameNoSoySocio.current
                            ? "tw-block"
                            : "tw-hidden"
                        }`}
                      >
                        <label className="tw-text-xl md:tw-text-2xl tw-m-0 tw-pl-5 tw-pb-2">
                          Nombre de quien te invitó
                        </label>
                        <input
                          {...registerNoSoySocio(
                            "selectMeeting.subSelect.NameInvitated"
                          )}
                          autoComplete="on"
                          className="tw-w-full tw-rounded-3xl tw-py-0 tw-px-4 tw-border-solid tw-bg-[#c4dde9] lg:tw-border-hidden"
                        />
                        {errorsNoSoySocio?.selectMeeting?.subSelect
                          ?.NameInvitated && (
                          <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                            {
                              errorsNoSoySocio?.selectMeeting.subSelect
                                .NameInvitated.message
                            }
                          </p>
                        )}
                      </div>
                    </>
                  )}

                  <label className="tw-flex tw-items-center tw-gap-1 tw-ml-5 tw-text-lg [filter:drop-shadow(2px_4px_6px_#818181)]">
                    <input
                      type="checkbox"
                      {...registerNoSoySocio("checkbox", {
                        required: {
                          value: true,
                          message: "Acepte las políticas de privacidad.",
                        },
                      })}
                    />
                    Acepto políticas y uso de datos
                    <span className="tw-text-red-600">*</span>
                  </label>
                  {errorsNoSoySocio?.checkbox && (
                    <p className="tw-font-bold tw-text-[#ff0000eb] tw-ml-4 tw-text-base tw-mb-0 md:tw-text-lg">
                      {errorsNoSoySocio?.checkbox.message}
                    </p>
                  )}
                  <br />
                  <input
                    type="submit"
                    className="tw-flex tw-m-auto tw-items-center [background-image:linear-gradient(rgb(231_65_65_/_73%),_rgb(231_65_65)_50%)] tw-text-white tw-rounded-3xl tw-py-4 tw-px-16 tw-border-none tw-cursor-pointer tw-font-bold tw-leading-4 [box-shadow:-4px_5px_0px_0_rgb(0_0_0_/_56%)] hover:[background-image:linear-gradient(rgb(203_62_62_/_73%),_rgb(203_62_62)_50%)]"
                    value="Empezar"
                  />
                </form>
              )}
            </div>
          </section>

          <section className="tw-flex tw-items-end tw-flex-row tw-flex-wrap tw-justify-between tw-pb-4 tw-px-6">
            <div className="">
              <p className="tw-text-2xl tw-m-0 tw-mt-5 tw-font-black">
                HORARIO
              </p>
              <p className="tw-text-xl">
                {dateMeeting.current}{" "}
                {flagMeeting.current && (
                  <img
                    src={`https://flagcdn.com/24x18/${flagMeeting.current}.png`}
                    srcset={`https://flagcdn.com/32x24/${flagMeeting.current}.png 2x,
    https://flagcdn.com/48x36/${flagMeeting.current}.png 3x`}
                    width="24"
                    height="18"
                    alt="bandera"
                  />
                )}
              </p>
            </div>

            <div className="tw-hidden md:tw-flex tw-flex-row tw-items-end tw-flex-nowrap tw-gap-5">
              <img
                alt="logo footer 1"
                src="/resources/landing-clase-reunionDeSocios2/footer1.png"
              />
              <img
                alt="logo footer 2"
                src="/resources/landing-clase-reunionDeSocios2/footer2.png"
              />
            </div>
          </section>

          {/*Modal loading*/}
          <Modal
            preventClose
            open={visibleModalLoading}
            onClose={() => setVisibleModalLoading(false)}
          >
            <Modal.Body>
              <section className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                <img
                  className="tw-w-full"
                  src="/resources/landing-clase-reunionDeSocios2/logoTitulo.png"
                  alt="modal de carga"
                />
                <ProgressBarLanding type={NAME_LANDING} />
                <h3>ESPERE...</h3>
              </section>
            </Modal.Body>
          </Modal>

          {/*Modal success*/}
          <Modal
            closeButton
            open={visibleModalSuccess}
            onClose={() => setVisibleModalSuccess(false)}
          >
            <Modal.Body>
              <section className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                <SuccessIcon className="tw-w-20" />
                <h3>¡LISTO!</h3>
                <p>{messageSuccess}</p>
                <button
                  className="tw-rounded-3xl"
                  onClick={() => setVisibleModalSuccess(false)}
                >
                  →
                </button>
              </section>
            </Modal.Body>
          </Modal>

          {/*Modal error*/}
          <Modal
            closeButton
            open={visibleModalError}
            onClose={() => setVisibleModalError(false)}
          >
            <Modal.Body>
              <section className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                <ErrorIcon className="tw-w-20" />
                <h3>¡ERROR!</h3>
                <p>
                  {messageError ||
                    "Error inesperado, verifica tu conexión ó un bloqueo(🛑AdBlock) por parte de tu navegador."}
                </p>
                <button
                  className="tw-rounded-3xl"
                  onClick={() => setVisibleModalError(false)}
                >
                  →
                </button>
              </section>
            </Modal.Body>
          </Modal>
        </section>
      </>
    );
  }
}

export default LandingReunionDeSocios2;
