import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import MEKeolaShopCart from "views/MEKeolaShopCart";
import MEKeolaShopDelivery from "views/MEKeolaShopDelivery";
import MEKeolaShopOrderDetail from "views/MEKeolaShopOrderDetail";
import MEKeolaShopPayment from "views/MEKeolaShopPayment";
import TestPayment from "../../components/payment/TestPayment";
import AuthService from "../../services/auth.service";
import HomeInView from "../../views/sections/HomeInView";
import KeolaView from "../../views/sections/KeolaView";
import PortfolioView from "../../views/sections/PortfolioView";
import RegisterInView from "../../views/sections/RegisterInView";
import RiberaView from "../../views/sections/RiberaView";
import UserSchedulesView from "../admin/UserSchedulesView";
import UsersView from "../admin/UsersView";
import ValidadorView from "../admin/ValidadorView";
import PasswordView from "../config/PasswordView";
import CalendarEventsView from "../events/CalendarEventsView.jsx";
import RegisterExView from "../external/RegisterExView";
import HomeGuestView from "../home/HomeGuestView";
import HomeView from "../home/HomeView";
import LandingGenerica from "../landing/generica/LandingGenerica";
import LandingInresorts from "../landing/landing-inresorts/landingInresorts";
import LandingKeola from "../landing/landing-keola/landingKeola.jsx";
import LandingReunionDeSocios2 from "../landing/landing-reunion-de-socios-2/landing-reunion-de-socios-2";
import LandingReunionDeSocios from "../landing/landing-reunion-de-socios/landing-reunion-de-socios";
import LandingSistemaDeEntrenamiento2 from "../landing/landing-sistema-de-entrenamiento-2/landing-sistema-de-entrenamiento-2";
import LandingSistemaDeEntrenamiento from "../landing/landing-sistema-de-entrenamiento/landing-sistema-de-entrenamiento";
// import RegisterEndView from "../login/RegisterEndView";
import AccountView from "../login/AccountView";
import LoginView from "../login/LoginView";
import RegisterMainView from "../login/RegisterMainView";
import ResetView from "../login/ResetView";
import RegisterMulQuoteView from "../payment/RegisterMulQuoteView";
import RegisterPaymentView from "../payment/RegisterPaymentView";
import RegisterQuoteView from "../payment/RegisterQuoteView";
import FormPreRegisterinView from "../sections/FormPreRegisterinView";
import UpdateView from "../updateuser/UpdateView";
import history from "./history";
import LayoutGeneral from "./layoutGeneral.jsx";
import RouteProtected from "./RouteProtected";

const linkServer = `${process.env.REACT_APP_APP_URL}`;

const LayoutAuth = (props) => (
  <section className="w-100 h-100">
    {props.children}
  </section>
)

export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
    };
  }
  componentDidMount() { }

  handleLogged = (isLogged) => {
    if (!isLogged) {
      let isLoggedStorage = AuthService.getIsLogged();
      if (isLoggedStorage) {
        isLogged = true;
      }
    }
    this.setState({
      isLogged: isLogged,
    });
  };

  logout = () => {
    // history.replace("/home-in");
    window.open(linkServer, "_self");
  };
  render() {
    const LoginPage = (props) => {
      return <LoginView isLogged={this.handleLogged} />;
    };
    const RouteWithLayout = ({
      component: Component,
      layout: Layout,
      ...rest
    }) => (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );

    return (
      <Router history={history}>
        <Switch>
          <RouteWithLayout
            path="/sign-in"
            layout={LayoutAuth}
            component={LoginView}
          />
          <RouteWithLayout
            path="/register-payment"
            layout={LayoutGeneral}
            component={RegisterPaymentView}
          />

          <RouteWithLayout
            path="/register-quote"
            layout={LayoutGeneral}
            component={RegisterQuoteView}
          />
          <RouteWithLayout
            path="/register-mul-quote"
            layout={LayoutGeneral}
            component={RegisterMulQuoteView}
          />
          <RouteWithLayout
            path="/payment-quote"
            layout={LayoutGeneral}
            component={RegisterQuoteView}
          />
          <RouteWithLayout
            path="/register"
            layout={LayoutGeneral}
            component={RegisterExView}
          />

          <RouteWithLayout
            path="/schedules"
            layout={LayoutGeneral}
            component={UsersView}
          />
          <RouteWithLayout
            path="/loadfile"
            layout={LayoutGeneral}
            component={ValidadorView}
          />
          <RouteWithLayout
            path="/guest"
            layout={LayoutGeneral}
            component={HomeGuestView}
          />

          <RouteWithLayout
            layout={LayoutGeneral}
            path="/user-schedules"
            component={UserSchedulesView}
          />

          <RouteProtected
            path="/sign-up"
            layout={LayoutGeneral}
            component={RegisterMainView}
          />
          <RouteWithLayout
            path="/reset"
            layout={LayoutGeneral}
            component={ResetView}
          />
          <RouteProtected
            path="/home"
            layout={LayoutGeneral}
            component={HomeView}
          />
          <RouteProtected
            path="/kshop/cart"
            layout={LayoutGeneral}
            component={MEKeolaShopCart}
          />
          <RouteProtected
            path="/kshop/delivery"
            layout={LayoutGeneral}
            component={MEKeolaShopDelivery}
          />
          <RouteProtected
            path="/kshop/payment"
            layout={LayoutGeneral}
            component={MEKeolaShopPayment}
          />
          <RouteProtected
            path="/kshop/orderdetail/:id"
            layout={LayoutGeneral}
            component={MEKeolaShopOrderDetail}
          />
          <RouteProtected
            path="/updateuser"
            layout={LayoutGeneral}
            component={UpdateView}
          />
          <RouteProtected
            path="/profile"
            layout={LayoutGeneral}
            component={AccountView}
          />
          <RouteProtected
            path="/password"
            layout={LayoutGeneral}
            component={PasswordView}
          />
          <RouteWithLayout
            path="/home-in"
            layout={LayoutGeneral}
            component={HomeInView}
          />
          <RouteWithLayout
            path="/portfolio"
            layout={LayoutGeneral}
            component={PortfolioView}
          />
          <RouteWithLayout
            path="/keola"
            layout={LayoutGeneral}
            component={KeolaView}
          />
          <RouteWithLayout
            path="/ribera"
            layout={LayoutGeneral}
            component={RiberaView}
          />
          <RouteWithLayout
            path="/form-preregister-in"
            layout={LayoutGeneral}
            component={FormPreRegisterinView}
          />
          <RouteWithLayout
            path="/register-in"
            layout={LayoutGeneral}
            component={RegisterInView}
          />
          <RouteWithLayout
            path="/pay"
            layout={LayoutGeneral}
            component={TestPayment}
          />
          <Route exact path="/landing" component={LandingGenerica} />
          <Route path="/landing/keola" component={LandingKeola} />
          <Route path="/landing/inresorts" component={LandingInresorts} />
          <Route
            path="/landing/sistema-de-entrenamiento"
            component={LandingSistemaDeEntrenamiento}
          />
          <Route
            path="/landing/sistema-de-entrenamiento-2"
            component={LandingSistemaDeEntrenamiento2}
          />
          <Route
            path="/landing/reunion-de-socios"
            component={LandingReunionDeSocios}
          />
          <Route
            path="/landing/reunion-de-socios-2"
            component={LandingReunionDeSocios2}
          />
          <RouteWithLayout
            path="/calendar-events"
            layout={LayoutGeneral}
            component={CalendarEventsView}
          />
          <RouteWithLayout
            path=""
            layout={LayoutGeneral}
            component={HomeInView}
          />
        </Switch>
      </Router>
    );
  }
}

